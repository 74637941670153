import { Fragment } from 'react';
import Classes from '../../draftOrder/css/draftOrderDetailPage.module.css';


const Aside = (props) => {

    const {
        draftOrder,
        notes,
        deliveryRemark
    } = props;

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }

    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2;
        }
    }

    function checkNullValues(obj, text) {
        try {
            for (const key in obj) {
                if (
                    key !== "createdAt" &&
                    key !== "updatedAt" &&
                    key !== "draftPurchaseOrderId" &&
                    key !== `dpo${text}DetailId`
                ) {
                    if (obj[key] !== null) {
                        return true;
                    }
                }
            }
            return false;
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Fragment>
            <div className={[Classes.Box,'box'].join(' ')}>
                {draftOrder.length !== 0 && (
                    <div>
                        {draftOrder?.DpoVendorDetails.length !== 0 && (
                            <div>
                                <div>
                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        <div className={Classes.containerBox}>
                                            <div className={Classes.asideCustomer}>
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Vendor
                                                </h6>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#2c6ecb',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <p style={{ marginRight: '5px' }}>
                                                        {draftOrder?.DpoVendorDetails[0]?.vendorName}
                                                    </p>
                                                    <p>GST - {draftOrder?.DpoVendorDetails[0]?.vendorGstNumber}</p>
                                                    <p>{draftOrder?.DpoVendorDetails[0]?.vendorCode}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        {checkNullValues(draftOrder?.DpoVendorContactDetails, "VendorContact") !== false && (
                                            <div className={Classes.containerBox}>
                                                <div className='mb-3'>
                                                    <p className={Classes.asideHeaders}>
                                                        CONTACT INFORMATION
                                                    </p>
                                                </div>
                                                {draftOrder?.DpoVendorContactDetails !== null && (
                                                    <div style={{ color:'#2c6ecb',fontSize:14 }}>
                                                        <p>{`${draftOrder?.DpoVendorContactDetails?.firstName} ${draftOrder?.DpoVendorContactDetails?.lastName ? draftOrder?.DpoVendorContactDetails?.lastName: "" }`}</p>
                                                        <p>{draftOrder?.DpoVendorContactDetails?.role}</p>
                                                        <p>{draftOrder?.DpoVendorContactDetails?.email}</p>
                                                        <p>{draftOrder?.DpoVendorContactDetails?.phoneNumber}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {draftOrder?.DpoVendorDetails !== null && (
                                    <div>
                                        <div>
                                            {draftOrder?.DpoVendorDetails?.length !== 0 ? (
                                                <div>
                                                    {draftOrder?.DpoVendorDetails?.map((vendor, index) => (
                                                        <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                            {vendor?.addressLine1 && (
                                                                <div className={Classes.containerBox}>
                                                                    <div className='mb-3'>
                                                                        <p className={Classes.asideHeaders}>{vendor?.addressType === "Shipping" ? "PICKUP" : "BILLING" } ADDRESS</p>
                                                                    </div>

                                                                    <div key={index} style={{ fontSize: '14px' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <p style={{ marginRight:5 }}>{vendor?.firstName}</p>
                                                                            <p>{vendor?.lastName ? vendor?.lastName : '' }</p>
                                                                        </div>

                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <p style={{ marginRight:5 }}>{vendor?.addressLine1}</p>
                                                                            <p>{vendor?.addressLine2 !== null ? vendor?.addressLine2 : null}</p>
                                                                        </div>

                                                                        <div style={{ display: 'flex' }}>
                                                                            <p style={{ marginRight:5 }}>{vendor?.pincode}</p>
                                                                            {vendor?.city && ( <p>{vendor?.city},</p>)}
                                                                        </div>

                                                                        <p>{vendor?.state && capitalizeState(vendor?.state)}</p>
                                                                        <p>{vendor?.phoneNumber && vendor?.phoneNumber}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}

                                        </div>

                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {draftOrder.length !== 0 && (
                    <div>
                        {draftOrder?.DpoLocationDetails.length !== 0 && (
                            <div>
                                <div>
                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        <div className={Classes.containerBox}>
                                            <div className={Classes.asideCustomer}>
                                                <h6 style={{ margin: 'auto 0px' }}>
                                                    Location
                                                </h6>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: '#2c6ecb',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <p style={{ marginRight: '5px' }}>
                                                        {draftOrder?.DpoLocationDetails[0]?.locationName}
                                                    </p>
                                                    <p>GST - {draftOrder?.DpoLocationDetails[0]?.locationGstNumber}</p>
                                                    <p>{draftOrder?.DpoLocationDetails[0]?.locationCode}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                        {checkNullValues(draftOrder.DpoLocationContactDetails, "LocationContact") !== false && (
                                            <div className={Classes.containerBox}>
                                                <div className='mb-3'>
                                                    <p className={Classes.asideHeaders}>
                                                        CONTACT INFORMATION
                                                    </p>
                                                </div>

                                                {draftOrder?.DpoLocationContactDetails !== null && (
                                                    <div style={{ color:'#2c6ecb',fontSize:14 }}>
                                                        <p>{`${draftOrder?.DpoLocationContactDetails?.firstName} ${draftOrder?.DpoLocationContactDetails?.lastName ? draftOrder?.DpoLocationContactDetails?.lastName : ''}`}</p>
                                                        <p>{draftOrder?.DpoLocationContactDetails?.role}</p>
                                                        <p>{draftOrder?.DpoLocationContactDetails?.email}</p>
                                                        <p>{draftOrder?.DpoLocationContactDetails?.phoneNumber}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div>
                                    {draftOrder?.DpoLocationDetails.length !== 0 ? (
                                        <div>
                                            {draftOrder?.DpoLocationDetails?.map((location, index) => (
                                                <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                    <div className={Classes.containerBox}>
                                                        <div>
                                                            <div className='mb-3'>
                                                                <p className={Classes.asideHeaders}>{location?.addressType?.toUpperCase()} ADDRESS</p>
                                                            </div>

                                                            <div key={index} style={{ fontSize: '14px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.firstName}</p>
                                                                    <p>{location?.lastName && location?.lastName !== "null" ? location?.lastName : ''}</p>
                                                                </div>

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.addressLine1}</p>
                                                                    <p>{location?.addressLine2 !== null && location?.addressLine2}</p>
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{location?.pincode}</p>
                                                                    {location?.city && ( <p>{location?.city},</p> )}
                                                                </div>

                                                                <p>{location?.state && capitalizeState(location?.state)}</p>
                                                                <p>{location?.phoneNumber && location?.phoneNumber}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>

            {draftOrder.length !== 0 && (
                <div className='' >
                    {checkNullValues(draftOrder.DpoShipmentDetails, "Shipment") !== false && (
                        <div className={[Classes.Box,Classes.asideMetafields,'box'].join(' ')}>
                            <h6 style={{ margin: '5px 0px' }}>
                                Shipment Details
                            </h6>
                            {draftOrder?.DpoShipmentDetails?.transporterName && (
                                <div style={{ display: 'flex',margin: '8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Transporter Name:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        {draftOrder?.DpoShipmentDetails?.transporterName}
                                    </p>
                                </div>
                            )}
                            
                            {draftOrder?.DpoShipmentDetails?.transporterType && (
                                <div style={{ display:'flex',margin:'8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Transporter Type:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        {draftOrder?.DpoShipmentDetails?.transporterType}
                                    </p>
                                </div>
                            )}

                            {draftOrder?.DpoShipmentDetails?.estimatedDispatchDate && (
                                <div style={{ display:'flex',margin:'8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Estimated Dispatch Date:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        {formatDate(draftOrder?.DpoShipmentDetails?.estimatedDispatchDate)}
                                    </p>
                                </div>
                            )}

                            {draftOrder?.DpoShipmentDetails?.estimatedReceivingDate && (
                                <div style={{ display:'flex',margin:'8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Estimated Delivery Date:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        {formatDate(draftOrder?.DpoShipmentDetails?.estimatedReceivingDate)}
                                    </p>
                                </div>
                            )}

                            {draftOrder?.DpoShipmentDetails?.freightCharges && (
                                <div style={{ display: 'flex', margin: '8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Freight Charges:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        &#8377;{draftOrder?.DpoShipmentDetails?.freightCharges}
                                    </p>
                                </div>
                            )}

                            {draftOrder?.DpoShipmentDetails?.biltyNumber && (
                                <div style={{ display: 'flex',margin: '8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Bilty Number:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        {draftOrder?.DpoShipmentDetails?.biltyNumber}
                                    </p>
                                </div>
                            )}

                            {draftOrder?.DpoShipmentDetails?.biltyDate && (
                                <div style={{ display: 'flex',margin: '8px 0px' }}>
                                    <p style={{ fontWeight:500,marginRight:7,fontSize:14,width:'50%' }}>
                                        Bilty Date:
                                    </p>
                                    <p style={{ color:'#2c6ecb',fontSize:14,width:'50%' }}>
                                        {formatDate(draftOrder?.DpoShipmentDetails?.biltyDate)}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {deliveryRemark && (
                <div className={Classes.Box} id='deliveryRemark'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Delivery Remark
                        </h6>
                        <p className={Classes.remarkBox}>
                            {deliveryRemark}
                        </p>
                    </div>
                </div>
            )}


            {notes && (
                <div className={Classes.Box} id='notes'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Notes
                        </h6>
                        <p className={Classes.remarkBox}>
                            {notes}
                        </p>
                    </div>
                </div>
            )}

            {draftOrder.length !== 0 && (
                <div id=''>
                    {draftOrder?.documentsAttached?.length !== 0 && (
                        <div className={Classes.Box} style={{ padding: '10px' }}>
                            <div className='mb-3'>
                                <h6 style={{ margin: 'auto 0px', padding: '6px' }}>
                                    Documents Attached
                                </h6>
                            </div>

                            <div>
                                <ul className="list-group mt-3">
                                    {draftOrder.documentsAttached.map((fileName, index) => (

                                        <a className="list-group-item" key={index}
                                            href={fileName}>{decodeURIComponent(fileName.substring(fileName.lastIndexOf('/') + 1, fileName.lastIndexOf('?')))}  </a>
                                    ))}
                                </ul>
                                {/* <div className={Classes.tagsBox}>
                                {draftOrder.documentsAttached.map((fileName, index) => (

                                    <a
                                        className={Classes.tags}
                                        href={fileName}>{fileName.substring(fileName.lastIndexOf('/') + 1, fileName.lastIndexOf('?'))}  </a>
                                ))}
                            </div> */}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    )
};

export default Aside;