import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { InputLabel, Select, TextField } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';



const RiderPopup = (props) => {

    const {
        showPopup,
        hidePopup,
        reRender,
        setReRender,
    } = props;

    const [name, setName] = useState('')
    const [locationCode, setLocationCode] = useState(null);
    const [locationCodes, setLocationCodes] = useState([]);

    useEffect(() => {
        const fetchLocationCodes = async () => {
            try {
                const response = await axiosInstance.get(`/location/locations?text=`)
                let locations = response?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            } catch (error) {
                const errorMsg = error?.response?.data?.message;
                console.error(`Error: ${errorMsg}`);
            }
        };
        fetchLocationCodes();
    }, []);

    const handleRiderCreate = async (e) => {
        if (name === '') {
            alert('Please enter rider name')
            return
        }
        if (locationCode === '' || locationCode === undefined || locationCode === null) {
            alert('Please enter location code')
            return
        }
        e.preventDefault()
        const riderData = {
            deliveryRider: name,
            locationCode: locationCode,
        }

        await axiosInstance
            .post(`/pgOrder/admin/createDeliveryRider`, [riderData])
            .then((res) => {
                setReRender(!reRender)
                hidePopup()
                setName('')
            })
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom: '35%', top: '26%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Create Delivery Rider</p>
                    </div>

                    <div style={{ padding: '30px 20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end'}}>
                            <TextField
                                required
                                id='name'
                                type="text"
                                label="Rider Name"
                                defaultValue={name}
                                placeholder='Enter  Rider Name'
                                onChange={(e) => setName(e.target.value)}
                                style={{ width: '48%' }}
                            />
                            <div style={{ width: '48%' }}>
                                <InputLabel htmlFor="locationCode">Location Code</InputLabel>
                                <Select
                                    required
                                    native
                                    value={locationCode}
                                    onChange={(e) => setLocationCode(e.target.value)}
                                    inputProps={{
                                        name: 'locationCode',
                                        id: 'locationCode',
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <option value="">Select Location Code</option>
                                    {locationCodes.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className={Classes.FooterBox} style={{ gap: '7px',borderTop:0 }}>
                        <button
                            type="submit"
                            className={Classes.ApplyButton}
                            style={{ background: '#fff', color: 'black' }}
                            onClick={hidePopup}
                        >
                            Close
                        </button>
                        <button
                            id='btn'
                            className={Classes.ApplyButton}
                            style={{ background: 'green', color: '#fff' }}
                            onClick={(e) => handleRiderCreate(e)}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default RiderPopup;