import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";


const DeleteOrderConfirmPopup = (props)=> {
    const { 
        showDeleteConfirmPopup, 
        hideDeleteOrderConfirmPopup, 
        draftOrderId, 
    } = props;

    return (
        <Fragment>
            {showDeleteConfirmPopup && (
                <div 
                    className={Classes.confirmModal} 
                    style={{bottom:'30%',top:'14%',borderRadius:20}}
                >
                    <div style={{ textAlign:'center',margin:'30px 0px' }}>
                        <FontAwesomeIcon 
                            icon={faCircleXmark} 
                            className={Classes.XMark}
                        />
                    </div>

                    <div style={{ color:'#000',textAlign:'center',fontSize:30 }}>
                        <p>Are you sure?</p>
                    </div>

                    <div style={{ color:'#000',textAlign:'center',padding:'10px 0px' }}>
                        <p>Do you really want to delete this order?</p>
                    </div>

                    <div 
                        className={Classes.Button} 
                        style={{
                            display:'flex',
                            justifyContent:'space-between',
                            padding:'15px',
                            margin:'0px auto',
                            width:290
                        }}
                    >
                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                onClick={(e) => hideDeleteOrderConfirmPopup(e,draftOrderId)}
                                style={{backgroundColor:'rgb(225, 45, 57)',color:'white',border:0,borderRadius:20 }}
                                className={Classes.CancelButton}
                            >
                                Yes
                            </button>
                        </div>

                        <div>
                            <button 
                                id='btn' 
                                type="submit" 
                                style={{border:0,backgroundColor:'rgb(98, 125, 152)',borderRadius:20,color:'#fff'}}
                                onClick={(e) => hideDeleteOrderConfirmPopup(e)}
                                className={Classes.CancelButton}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DeleteOrderConfirmPopup;