import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

const Caret = ({sort, headerName}) => {
    
    return (
        <FontAwesomeIcon 
            icon={faCaretUp} 
            style={{
                marginLeft:5, 
                transform: sort.keyToSort === headerName ? 
                sort?.direction === 'asc'? "rotate(0deg)" : "rotate(180deg)":
                "rotate(0deg)"
            }}
        />
    )
}

export default Caret;