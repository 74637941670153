
import { Fragment, useEffect } from 'react';
import Classes from './css/addProduct.module.css';
import axiosInstance from '../../../../utility/axios-instance';
import { useState } from 'react';

const Variants = (props) => {
    const [country,setCountry] = useState([]);

    const {
        showErrorMsg,
        productDetails,
        handleDatabase,
    }= props;

    useEffect(() => {
        axiosInstance
            .get('/others/countryOfOrigin')
            .then((res) => {
                setCountry(res.data.data);
            })
    },[]);
	
	return (
		<Fragment>
            <div className={Classes.Pricing}>
                <div className={Classes.Boxes}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <h6>Variants</h6>
                    </div>

                    <div style={{padding:'1rem 0px'}}>

                        <div className={Classes.variantField}>
                            <div style={{width:'48%', marginTop:15}}>
                                <div style={{display:'flex'}}>
                                    <p>Option Name</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>

                                {productDetails?.length !== 0 && (
                                    <div>
                                        <input
                                            type="text"
                                            id="option1Name"
                                            required={true}
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            autoComplete='off'
                                            key={
                                                productDetails[0]?.variable1Type ?
                                                productDetails[0]?.variable1Type :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.variable1Type ?
                                                productDetails[0]?.variable1Type :
                                                ''
                                            }
                                            placeholder='Size'
                                            onBlur={handleDatabase}
                                        />
                                    </div>
                                )}                       

                                <div>
                                    {showErrorMsg?.length !== 0 && (
                                        <div>
                                            {showErrorMsg?.fieldName === 'options' && (
                                                <p className={Classes.Msg}>
                                                    {showErrorMsg?.message}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{width:'48%', marginTop:15}}>
                                <div style={{display:'flex'}}>
                                    <p>Option Value</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>

                                {productDetails?.length !== 0 && (
                                    <div>
                                        <input
                                            type="text"
                                            id="option1Value"
                                            required={true}
                                            autoComplete='off'
                                            key={
                                                productDetails[0]?.variable1Value ?
                                                productDetails[0]?.variable1Value :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.variable1Value ?
                                                productDetails[0]?.variable1Value :
                                                ''
                                            }
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Medium'
                                            onBlur={handleDatabase}
                                        />
                                    </div>
                                )}
                                

                                <div>
                                    {showErrorMsg?.length !== 0 && (
                                        <div>
                                            {showErrorMsg?.fieldName === 'options' && (
                                                <p className={Classes.Msg}>
                                                    {showErrorMsg?.message}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {productDetails?.length !== 0 && (
                            <div className={Classes.variantField}>
                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Option Name</p>
                                    <div>
                                        <input
                                            type="text"
                                            id="option2Name"
                                            autoComplete='off'
                                            key={
                                                productDetails[0]?.variable2Type ?
                                                productDetails[0]?.variable2Type :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.variable2Type ?
                                                productDetails[0]?.variable2Type :
                                                ''
                                            }
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Color'
                                        />
                                    </div>
                                    
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'options' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                
                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Option Value</p>
                                        <div>
                                            <input
                                                type="text"
                                                id="option2Value"
                                                key={
                                                    productDetails[0]?.variable2Value ?
                                                    productDetails[0]?.variable2Value :
                                                    ''
                                                }
                                                defaultValue={
                                                    productDetails[0]?.variable2Value ?
                                                    productDetails[0]?.variable2Value :
                                                    ''
                                                }
                                                autoComplete='off'
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                                placeholder='Black'
                                            />
                                        </div>

                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'options' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        
                        {productDetails?.length !== 0 && (
                            <div className={Classes.variantField}>
                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Option Name</p>
                                    <div>
                                        <input
                                            type="text"
                                            id="option3Name"
                                            autoComplete='off'
                                            key={
                                                productDetails[0]?.variable3Type ?
                                                productDetails[0]?.variable3Type :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.variable3Type ?
                                                productDetails[0]?.variable3Type :
                                                ''
                                            }
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'options' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Option Value</p>
                                    <div>
                                        <input
                                            type="text"
                                            id="option3Value"
                                            key={
                                                productDetails[0]?.variable3Value ?
                                                productDetails[0]?.variable3Value :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.variable3Value ?
                                                productDetails[0]?.variable3Value :
                                                ''
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            // placeholder='Rubber'
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'options' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        
                        {productDetails?.length !== 0 && (
                            <div 
                                style={{
                                    display:'flex',
                                    justifyContent:'space-between',
                                    flexWrap:'wrap'
                                }}
                            >
                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Size</p>
                                    <div>
                                        <input
                                            type="text"
                                            id="size"
                                            name='size'
                                            key={
                                                productDetails[0]?.size ?
                                                productDetails[0]?.size :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.size ?
                                                productDetails[0]?.size :
                                                ''
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Size'
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'size' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Finish</p>
                                    <div>
                                        <input
                                            name='finish'
                                            type="text"
                                            id="finish"
                                            key={
                                                productDetails[0]?.finish ?
                                                productDetails[0]?.finish :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.finish ?
                                                productDetails[0]?.finish :
                                                ''
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Finish'
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'finish' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <div>
                                        <p>Material</p>
                                        <div>
                                            <input
                                                type="text"
                                                name="material" 
                                                key={
                                                    productDetails[0]?.materialType ?
                                                    productDetails[0]?.materialType :
                                                    ''
                                                }
                                                defaultValue={
                                                    productDetails[0]?.materialType ?
                                                    productDetails[0]?.materialType :
                                                    ''
                                                }
                                                id='materialType'
                                                autoComplete='off'
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                                placeholder='Material Type'
                                            />
                                        </div>
                                        <div>
                                            {showErrorMsg?.length !== 0 && (
                                                <div>
                                                    {showErrorMsg?.fieldName === 'material_type' && (
                                                        <p className={Classes.Msg}>
                                                            {showErrorMsg?.message}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Included Components</p>
                                    <div>
                                        <input
                                            name='includedComponents'
                                            type="text"
                                            autoComplete='off'
                                            key={
                                                productDetails[0]?.includedComponents ?
                                                productDetails[0]?.includedComponents :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.includedComponents ?
                                                productDetails[0]?.includedComponents :
                                                ''
                                            }
                                            id="includedComponents"
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Included components'
                                        />
                                    </div>

                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'included_components' && (
                                                    <p className={Classes.Msg}>{showErrorMsg?.message}</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Load Capacity</p>
                                    <div>
                                        <input
                                            name='loadCapacity'
                                            type="text"
                                            id="loadCapacity"
                                            key={
                                                productDetails[0]?.loadCapacity ?
                                                productDetails[0]?.loadCapacity :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.loadCapacity ?
                                                productDetails[0]?.loadCapacity :
                                                ''
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Load Capacity'
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'loadCapacity' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Weight (kg)</p>
                                    <div>
                                        <input
                                            name='weight'
                                            type="number"
                                            id="weight"
                                            step="0.01"
                                            onKeyDown={
                                                e => ( e.keyCode === 69) 
                                                && e.preventDefault()
                                            }
                                            key={
                                                productDetails[0]?.weight ?
                                                productDetails[0]?.weight :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.weight ?
                                                productDetails[0]?.weight :
                                                ''
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Weight'
                                        />
                                    </div>

                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'weight' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <p>Warranty (Year/s)</p>
                                    <div>
                                        <input
                                            name='warranty'
                                            type="text"
                                            id="warranty"
                                            step="0.01"
                                            key={
                                                productDetails[0]?.warranty ?
                                                productDetails[0]?.warranty :
                                                ''
                                            }
                                            defaultValue={
                                                productDetails[0]?.warranty ?
                                                productDetails[0]?.warranty :
                                                ''
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Warranty'
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg?.length !== 0 && (
                                            <div>
                                                {showErrorMsg?.fieldName === 'warranty' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg?.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <div className={Classes.Field}>  
                                        <div>
                                            <p>Marketed By</p>
                                        </div>
                                        <div>
                                            <input
                                                name='marketedBy'
                                                type="text"
                                                key={
                                                    productDetails[0]?.marketedBy ?
                                                    productDetails[0]?.marketedBy :
                                                    ''
                                                }
                                                defaultValue={
                                                    productDetails[0]?.marketedBy ?
                                                    productDetails[0]?.marketedBy :
                                                    ''
                                                }
                                                autoComplete='off'
                                                id="marketedBy"
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                            />
                                        </div>

                                        <div>
                                            {showErrorMsg?.length !== 0 && (
                                                <div>
                                                    {showErrorMsg?.fieldName === 'marketedBy' && (
                                                        <p className={Classes.Msg}>
                                                            {showErrorMsg?.message}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div style={{width:'48%', marginTop:15}}>
                                    <div className={Classes.Field}>  
                                        <div>
                                            <p>Customer Care</p>
                                        </div>
                                        <div>
                                            <input
                                                name='customerCare'
                                                type="text"
                                                key={
                                                    productDetails[0]?.customerCareAddress ?
                                                    productDetails[0]?.customerCareAddress :
                                                    ''
                                                }
                                                defaultValue={
                                                    productDetails[0]?.customerCareAddress ?
                                                    productDetails[0]?.customerCareAddress :
                                                    ''
                                                }
                                                autoComplete='off'
                                                id="customerCare"
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                            />
                                        </div>

                                        <div>
                                            {showErrorMsg?.length !== 0 && (
                                                <div>
                                                    {showErrorMsg?.fieldName === 'customer_care' && (
                                                        <p className={Classes.Msg}>
                                                            {showErrorMsg?.message}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                
                            </div>
                        )}
                    </div>
                </div>
            </div>
		</Fragment>
	);
};


export default Variants;
