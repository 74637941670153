import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utility/axios-instance";
import BarChartComponent from "../../../components/barChartComponent";
import Classes from './css/dashboard.module.css'
import Spinner from "../../../components/spinner";
import PieChartComponent from "../../../components/pieChartComponent";
import Table from "../../../components/table/table";
import moment from 'moment';
import { useSelector } from "react-redux";
import { setSkusWithOrderName } from "../../../store/dashboard";

const salesBaseUrl = '/analytics/salesAnalytics';


const SalesAnalytics = (props) => {
    const [totalOrdersCountByDate, setTotalOrdersCountByDate] = useState([]);
    const [totalOrdersCount, setTotalOrdersCount ] = useState(0);
    const [todaysOrderCount, setTodaysOrderCount] = useState(0);

    const [totalLinesCountByDate, setTotalLinesCountByDate] = useState([]);
    const [totalLinesCount, setTotalLinesCount] = useState(0);
    const [todayTotalLines, setTodayTotalLines] = useState(0);

    const [totalDistinctSkuCountByDate, setTotalDistinctSkuCountByDate] = useState([]);
    const [totalDistinctSkuCount, setTotalDistinctSkuCount] = useState(0);
    const [todayTotalDistinctSku, setTodayTotalDistinctSku] = useState(0);

    const [totalOrderAmountByDate, setTotalOrderAmountByDate] = useState([]);
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [todayTotalOrderAmount, setTodayTotalOrderAmount] = useState(0);
    const [totalOrderAmountOfAdmin, setTotalOrderAmountOfAdmin] = useState(0);

    const [topSellingSkuByQty, setTopSellingSkuByQty] = useState([]);

    const [totalFulfilledOrders, setTotalFulfilledOrders] = useState(0);
    const [totalFulfilledOrderNames, setTotalFulfilledOrderNames] = useState([]);

    const [totalPendingOrders, setTotalPendingOrders] = useState(0);
    const [totalPendingOrderNames, setTotalPendingOrderNames] = useState([]);

    const [totalPendingOrdersDueToStock, setTotalPendingOrdersDueToStock] = useState(0);
    const [totalPendingOrderNamesDueToStock, setTotalPendingOrderNamesDueToStock] = useState([]);

    const [totalSkusWithQty, setTotalSkusWithQty] = useState([]);
    const orderProcessingLabels = ['Fulfilled Orders','Pending Orders','Pending Due To Stock Out']
    const [skuStatusLabel, setskuStatusLabel] = useState(orderProcessingLabels[0] || 'Fulfilled Orders');

    const [fulfilledOrderIn4hr, setFulfilledOrderIn4hr] = useState([]);
    const [fulfilledOrderIn4hrTo8hr, setFulfilledOrderIn4hrTo8hr] = useState([]);
    const [fulfilledOrderIn8hrTo24hr, setFulfilledOrderIn8hrTo24hr] = useState([]);
    const [fulfilledOrderIn24hrTo48hr, setFulfilledOrderIn24hrTo48hr] = useState([]);
    const [fulfilledOrderMore48hr, setFulfilledOrderInMore48hr] = useState([]);
    const [reRender, setReRender] = useState(false);
    const tableRef = useRef()

    const skusWithOrderName = useSelector(state => state.dashboard)

    const [todayOrderQty, setTodayOrderQty] = useState(0);
    const [avgLinesPerOrder, setAvgLinesPerOrder] = useState(0);
    const [avgSaleOrderAmount, setAvgSaleOrderAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const {locCode, setSnackBar, startDate, endDate} = props;

    const fetchNumberOfDays = (startDate, endDate) => {
        const numOfDays = moment(endDate).diff(startDate,'days')
        setNumberOfDays(numOfDays);
    }

    const fetchTotalOrders = async() => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrders?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalOrdersCount(res?.totalOrdersCount)
                setTotalOrdersCountByDate(res?.totalOrderCountByDate)
                setTodaysOrderCount(res?.todayRecord?.orderCount || 0)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalLines = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrderLines?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalLinesCountByDate(res?.totalOrderLineCountByDate)
                setTotalLinesCount(res?.totalOrderLineItemsCount)
                setTodayTotalLines(res?.todayRecord?.lineItemCount || 0)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalOrderQuantity = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrderQuantity?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTodayOrderQty(res?.todayRecord?.lineItemTotalQty || 0)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalDistinctSkuSold = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalDistinctSkuSold?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalDistinctSkuCountByDate(res?.totalDistinctSkusSoldByDate);
                setTotalDistinctSkuCount(res?.totalDistinctSkuSoldCount);
                setTodayTotalDistinctSku(res?.todayRecord?.skuCount || 0);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAvgLinesPerOrder = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/avgLinesPerOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setAvgLinesPerOrder(res?.avgLinesPerOrder)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTotalOrderAmount = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalOrderAmount?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalOrderAmountByDate(res?.totalOrderAmountByDate);
                setTotalOrderAmount(res?.totalAmount);
                setTodayTotalOrderAmount(res?.todayRecord?.totalOrderAmount || 0);
                setTotalOrderAmountOfAdmin(res?.totalOrderAmountOfAdmin[0]?.totalOrderAmountOfAdmin || 0)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAvgOrderAmount = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/avgTotalOrderAmount?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setAvgSaleOrderAmount(res?.avgTotalAmount);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchTopSellingSku = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/topSellingSku?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTopSellingSkuByQty(res?.topSellingSkuByQuantity)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllFulfilledOrders = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalFulfilledOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalFulfilledOrders(res?.totalFulfilledOrdersCount);
                setTotalFulfilledOrderNames(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllPendingOrder = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingOrder?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalPendingOrders(res?.totalPendingOrdersCount);
                setTotalPendingOrderNames(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    const fetchAllPendingOrderDueToStockOut = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingOrdersDueToStock?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalPendingOrdersDueToStock(res?.totalPendingOrderDueToStockCount);
                setTotalPendingOrderNamesDueToStock(res?.totalOrderNames);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchAllFulfilledSkus = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalFulfilledLineItems?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalFulfilledSku)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }
    const fetchAllPendingSkus = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingLineItems?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalPendingSkus)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchAllPendingSkusDueToStock = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }
        await axiosInstance
            .get(`${salesBaseUrl}/totalPendingSkusDueToStockOut?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setTotalSkusWithQty(res?.totalPendingSkus)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const fetchFulfilledOrderTime = async () => {
        let queryPrams = `startDate=${startDate}&endDate=${endDate}`;
        if(locCode){
            queryPrams += `&locationCode=${locCode}`;
        }

        await axiosInstance
            .get(`${salesBaseUrl}/avgFulfillmentTime?${queryPrams}`)
            .then((response) => {
                const res = response?.data?.data;
                setFulfilledOrderIn4hr(res?.fulfilledOrdersUnder4hr)
                setFulfilledOrderIn4hrTo8hr(res?.fulfilledOrdersUnder4to8hr)
                setFulfilledOrderIn8hrTo24hr(res?.fulfilledOrdersUnder8to24hr)
                setFulfilledOrderIn24hrTo48hr(res?.fulfilledOrdersUnder24to48hr)
                setFulfilledOrderInMore48hr(res?.fulfilledOrdersMore48hr)
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
                console.log(error);
            })
    }

    const columns = [
        {
            field: 'orderName',
            headerName: 'OrderName',
            flex:0.5,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'sku',
            headerName: 'Sku',
            flex:1,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'createdAt',
            flex:1,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        }
    ]

    const services = async () => {
        if(startDate && endDate){
            fetchNumberOfDays(startDate, endDate);
            setLoading(true)
            await fetchTotalOrders(); 
            await fetchTotalLines();   
            await fetchTotalOrderQuantity();
            await fetchTotalDistinctSkuSold();
            await fetchAvgLinesPerOrder();
            await fetchTotalOrderAmount();
            await fetchAvgOrderAmount();
            await fetchTopSellingSku();
            await fetchAllFulfilledOrders()
            await fetchAllPendingOrder()
            await fetchAllPendingOrderDueToStockOut()
            await fetchAllFulfilledSkus()
            await fetchFulfilledOrderTime() 
            setLoading(false)
        }
    }

    useEffect(() => {
        services();
    }, [startDate, endDate, locCode]);

    const fetchSkuBasedOnLabel = async () => {
        if(skuStatusLabel === orderProcessingLabels[0]){
            await fetchAllFulfilledSkus()
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
        }
        else if(skuStatusLabel === orderProcessingLabels[1]){
            //call pending order api
            await fetchAllPendingSkus()
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
        }
        else if(skuStatusLabel === orderProcessingLabels[2]){
            await fetchAllPendingSkusDueToStock();
            tableRef?.current?.scrollIntoView({behavior : 'smooth'})
        }
        setReRender(!reRender)
    }

    useEffect(() => {
        startDate && endDate && fetchSkuBasedOnLabel();
    },[skuStatusLabel])


    return (
        <>
            {/* Main */}
            <div className={Classes.Container}>
                {/* Cards */}
                <div className={Classes.CardContainer}>
                    <Card 
                        heading={todaysOrderCount}
                        title='Today Total Orders'
                        color='#dddddd56'
                    />

                    <Card 
                        heading={todayTotalLines}
                        title='Today Total Lines'
                        color='#dddddd56'
                    />
                    
                    <Card 
                        heading={todayTotalDistinctSku}
                        title='Today Total Distinct sku Sold'
                        color='#dddddd56'
                    />

                    <Card 
                        heading={todayOrderQty}
                        title='Today Total Order Quantity'
                        color='#dddddd56'
                    />

                    <Card 
                        heading={avgLinesPerOrder}
                        title='Average LineItems Per Order'
                        color='#dddddd56'
                        ofLastDay={numberOfDays}
                    />

                    <Card 
                        heading={`Rs ${todayTotalOrderAmount}`}
                        title='Today Total Order Sale'
                        color='#dddddd56'
                    />

                    <Card 
                        heading={`Rs ${avgSaleOrderAmount}`}
                        title='Average Order Sale'
                        color='#dddddd56'
                        ofLastDay={numberOfDays}
                    />

                    <Card 
                        heading={`Rs ${totalOrderAmountOfAdmin}`}
                        title='My Total Sales'
                        color='#dddddd56'
                        ofLastDay={numberOfDays}
                    />

                </div>

                {/* Bar Chart */}
                {!loading && <div className={Classes.GraphContainer}>
                    {totalOrdersCountByDate.length ?
                        <div className={Classes.Graph}>
                            <BarChartComponent
                                label='Total Orders Per day'
                                labels={totalOrdersCountByDate.map(item => item?.createdAt)}
                                data={totalOrdersCountByDate.map(item => item?.orderCount)}
                                title= {`Total Orders of Last ${numberOfDays} days : ${totalOrdersCount}`}
                            />
                        </div>
                        : null
                    }

                    {totalLinesCountByDate.length ?
                        <div className={Classes.Graph}>
                            <BarChartComponent
                                label='Total Lines Per day'
                                labels={totalLinesCountByDate.map(item => item?.createdAt)}
                                data={totalLinesCountByDate.map(item => item?.lineItemCount)}
                                title={`Total Lines of Last ${numberOfDays} days : ${totalLinesCount}`}
                            />
                        </div>
                        : null
                    }

                    {totalDistinctSkuCountByDate.length ?
                        <div className={Classes.Graph}>
                            <BarChartComponent
                                label='Total Distinct Sku Sold Per day'
                                labels={totalDistinctSkuCountByDate.map(item => item?.createdAt)}
                                data={totalDistinctSkuCountByDate.map(item => item?.skuCount)}
                                title={`Total Distinct Sku Sold of Last ${numberOfDays} days : ${totalDistinctSkuCount}`}
                            />
                        </div>
                        : null
                    }

                    {topSellingSkuByQty.length ?
                        <div className={Classes.Graph}>
                            <BarChartComponent
                                label='Top Selling Sku'
                                labels={topSellingSkuByQty.map(item => item?.sku)}
                                data={topSellingSkuByQty.map(item => item?.skuSoldQty)}
                                title={`Top 10 Selling Skus of Last ${numberOfDays} days`}
                                options={{
                                    indexAxis : 'y'
                                }}
                                isHorizontal={true}
                            />
                        </div>
                        : null
                    }
                </div>}
            </div>

            {loading && <Spinner />}
        </>
    )
}
export default SalesAnalytics;

const Card = (props) => {
    const {heading, title, color, ofLastDay} = props;
    return (<>
        <div className={Classes.Card} style={{backgroundColor:color}}>
            <div style={{position:'relative'}}>
                <p className={Classes.CardHeading} >{heading}</p>
                {ofLastDay && <span style={{fontSize: '12px',position: 'absolute', right:-5, top:-8}}>{`Last ${ofLastDay} days`}</span>}
            </div>
            <p className={Classes.CardTitle}>{title}</p>
        </div>
    </>)
}