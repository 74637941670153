import React, { useEffect } from 'react'
import Classes from './css/addProduct.module.css';
import { IconButton, TextField, Chip, Autocomplete } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


export default function MultiPricing({ multiPriceArr, mrp, gst, details, removeSection, locations }) {
    const [listPrice, setListPrice] = React.useState({
        error: false,
        message: '',
        value: details?.listingPrice,
        discount: details?.listingDiscount
    });
    const [retailerPrice, setRetailerPrice] = React.useState({
        error: false,
        message: '',
        value: details?.retailerPrice,
        discount: details?.retailerDiscount
    });
    const [interiorPrice, setInteriorPrice] = React.useState({
        error: false,
        message: '',
        value: details?.interiorPrice,
        discount: details?.interiorDiscount
    });
    const [oemPrice, setOemPrice] = React.useState({
        error: false,
        message: '',
        value: details?.oemPrice,
        discount: details?.oemDiscount
    });
    const [endUserPrice, setEndUserPrice] = React.useState({
        error: false,
        message: '',
        value: details?.endUserPrice,
        discount: details?.endUserDiscount
    });

    const handleDiscount = (value, setPrice) => {
        if (value) {
            const MRP = mrp;
            const DP = parseFloat(value)
            const GST = parseInt(document.getElementById('gstRate').value);
            const Discount = (MRP - (DP * (1 + (GST / 100)))) / MRP
            const amount = Discount * 100;
            const result = Math.floor(amount * 100) / 100;
            const listAmount = MRP / (1 + (GST / 100));

            if (Discount < 0) {
                setPrice({ error: true, message: `Price cannot be greater than ${listAmount?.toFixed(2)}`, value: '', discount: '' });
            } else {
                setPrice({ error: false, message: '', value: value, discount: amount.toFixed(2) });
            }
        }else{
            setPrice({ error: false, message: '', value: '', discount: '' });
        }
    }


    const setLocation = (location) => {
        //check if the location already exists in the multiPriceObjArray if yes don't set and show alert
        if (multiPriceArr.length !== 0) {
            multiPriceArr.find((item) => item.locationCode === location) ? alert('Price for this Location already exists') : details.locationCode = location;
        }
    }

    useEffect(() => {
        // mrp & gst changes then re-calculating the prices
        if (!mrp || mrp === '') return
        handleDiscount(listPrice?.value, setListPrice)
        handleDiscount(retailerPrice?.value, setRetailerPrice)
        handleDiscount(interiorPrice?.value, setInteriorPrice)
        handleDiscount(oemPrice?.value, setOemPrice)
        handleDiscount(endUserPrice?.value, setEndUserPrice)
    }, [mrp, gst])

    useEffect(() => {
        // add the prices in details object
        details.listingPrice = listPrice?.value;
        details.retailerPrice = retailerPrice?.value;
        details.interiorPrice = interiorPrice?.value;
        details.oemPrice = oemPrice?.value;
        details.endUserPrice = endUserPrice?.value;


        // add the discounts in details object
        details.listingDiscount = listPrice?.discount;
        details.retailerDiscount = retailerPrice?.discount;
        details.interiorDiscount = interiorPrice?.discount;
        details.oemDiscount = oemPrice?.discount;
        details.endUserDiscount = endUserPrice?.discount;

    }, [listPrice, retailerPrice, interiorPrice, oemPrice, endUserPrice])

    return (
        <div className={Classes.Pricing}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton onClick={removeSection}><ClearIcon /></IconButton>
            </div>
            <div className={Classes.Boxes}>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                    {locations && (
                        <Autocomplete
                            size='small'
                            id="location"
                            required
                            options={locations}
                            style={{ width: "50%" }}
                            onChange={(e, newValue) => setLocation(newValue)}
                            value={details?.locationCode}
                            renderInput={(params) => <TextField required {...params} label="Location" defaultValue="" />}
                        />)}
                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '5px' }}>
                        <TextField
                            error={listPrice?.error}
                            helperText={listPrice?.message}
                            label="LISTING PRICE (Exclusive GST)"
                            name='listingPriceData'
                            type="number"
                            id="listPrice"
                            step="0.01"
                            value={listPrice?.value}
                            onChange={(e) => setListPrice({ ...listPrice, value: parseInt(e.target.value) > 0 ? e.target.value : '' })}
                            required={true}
                            autoComplete='off'
                            size='small'
                            placeholder='&#8377;  0.00'
                            onKeyDown={
                                e => (e.keyCode === 69)
                                    && e.preventDefault()
                            }
                            onBlur={
                                (e) => handleDiscount
                                    (e.target.value, setListPrice)
                            }
                        />
                        {listPrice?.discount ? <Chip label={`Discount: ${listPrice.discount}%`} size='small' color='success' /> : ''}
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px', marginTop: '18px' }}>
                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '5px' }}>
                        <TextField
                            error={retailerPrice?.error}
                            helperText={retailerPrice?.message}
                            label="RETAILER PRICE (Exclusive GST)"
                            name='retailerPriceData'
                            type="number"
                            step="0.01"
                            size='small'
                            id="retailerPrice"
                            value={retailerPrice?.value}
                            onChange={(e) => setRetailerPrice({ ...retailerPrice, value: parseInt(e.target.value) > 0 ? e.target.value : '' })}
                            autoComplete='off'
                            placeholder='&#8377;  0.00'
                            onKeyDown={
                                e => (e.keyCode === 69)
                                    && e.preventDefault()
                            }
                            onBlur={
                                (e) => handleDiscount(
                                    e.target.value, setRetailerPrice
                                )}
                        />
                        {retailerPrice?.discount ? <Chip label={`Discount: ${retailerPrice?.discount}%`} size='small' color='success' /> : ''}
                    </div>

                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '5px' }}>
                        <TextField
                            error={interiorPrice?.error}
                            helperText={interiorPrice?.message}
                            label="Interior PRICE (Exclusive GST)"
                            name='interiorPriceData'
                            type="number"
                            value={interiorPrice.value}
                            onChange={(e) => setInteriorPrice({ ...interiorPrice, value: parseInt(e.target.value) > 0 ? e.target.value : '' })}
                            id="interiorPrice"
                            step="0.01"
                            size='small'
                            autoComplete='off'
                            placeholder='&#8377;  0.00'
                            onKeyDown={
                                e => (e.keyCode === 69)
                                    && e.preventDefault()
                            }
                            onBlur={
                                (e) => handleDiscount(
                                    e.target.value, setInteriorPrice
                                )}
                        />
                        {interiorPrice?.discount ? <Chip label={`Discount: ${interiorPrice?.discount}%`} size='small' color='success' /> : ''}
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px', marginTop: '18px' }}>
                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '5px' }}>

                        <TextField
                            error={oemPrice?.error}
                            helperText={oemPrice?.message}
                            label="OEM PRICE (Exclusive GST)"
                            name='oemPriceData'
                            type="number"
                            value={oemPrice?.value}
                            onChange={(e) => setOemPrice({ ...oemPrice, value: parseInt(e.target.value) > 0 ? e.target.value : '' })}
                            id="oemPrice"
                            step="0.01"
                            size='small'
                            autoComplete='off'
                            placeholder='&#8377;  0.00'
                            onKeyDown={
                                e => (e.keyCode === 69)
                                    && e.preventDefault()
                            }
                            onBlur={
                                (e) => handleDiscount(
                                    e.target.value, setOemPrice
                                )}
                        />
                        {oemPrice?.discount ? <Chip label={`Discount: ${oemPrice?.discount}%`} size='small' color='success' /> : ''}
                    </div>

                    <div style={{ display: 'flex', width: '50%', flexDirection: 'column', gap: '5px' }}>

                        <TextField
                            error={endUserPrice?.error}
                            helperText={endUserPrice?.message}
                            label="END USER PRICE (Exclusive GST)"
                            name='endUserPriceData'
                            type="number"
                            value={endUserPrice?.value}
                            onChange={(e) => setEndUserPrice({ ...endUserPrice, value: parseInt(e.target.value) > 0 ? e.target.value : '' })}
                            id="endUserPrice"
                            step="0.01"
                            size='small'
                            autoComplete='off'
                            placeholder='&#8377;  0.00'
                            onKeyDown={
                                e => (e.keyCode === 69)
                                    && e.preventDefault()
                            }
                            onBlur={
                                (e) => handleDiscount(
                                    e.target.value, setEndUserPrice
                                )}
                        />
                        {endUserPrice?.discount ? <Chip label={`Discount: ${endUserPrice?.discount}%`} size='small' color='success' /> : ''}
                    </div>
                </div>
            </div>

        </div>
    )
}
