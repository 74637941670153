
import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';


const  GroupTitle = (props) => {
    const {
        showErrorMsg,
        productDetails
    }= props;

	
	return (
		<Fragment>
            <div className={Classes.Box}>
                <div className={Classes.Field}>  
                    
                </div>

                <div className={Classes.Field} >  
                    <div className={Classes.CommonMarginTop}>
                        <p>Description</p>
                    </div>
                    <div>
                        <textarea 
                            name='description'
                            id='description'
                            autoComplete='off'
                            key={
                                productDetails?.length !== 0 ?
                                productDetails[0]?.productDescription :
                                ''
                            }
                            defaultValue={
                                productDetails?.length !== 0 ?
                                productDetails[0]?.productDescription :
                                ''
                            }
                            className={Classes.TextArea}
                        ></textarea>
                    </div>

                    <div>
                        {showErrorMsg.length !== 0 && (
                            <div>
                                {showErrorMsg.fieldName === 'descriptionHtml' && (
                                    <p>{showErrorMsg.message}</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
		</Fragment>
	);
};

export default GroupTitle;
