import { Fragment } from 'react';
import Classes from '../../draftOrder/css/draftOrderDetailPage.module.css';
import SellerDetail from '../sellerDetail';
import Permission from '../../../auth/permissions';
import { services } from '../../../../utility/checkRoleBasedPermission';

const Aside = (props) => {

    const {
        draftOrder,
        paymentRemark,
        deliveryRemark
    } = props;


    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2;
        }
    }
    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }


    return (
        <Fragment>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                {draftOrder.length !== 0 && (
                    <div>
                        <div>
                            <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                <div className={Classes.containerBox}>
                                    <div className={Classes.asideCustomer}>
                                        <h6 style={{ margin: 'auto 0px' }}>
                                            Customer
                                        </h6>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            fontSize: '14px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#2c6ecb',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <p style={{ marginRight: '5px' }}>
                                                {draftOrder?.OCustomerDetails[0]?.firstName + ' ' + draftOrder?.OCustomerDetails[0]?.lastName}
                                            </p>
                                            {draftOrder?.OCustomerDetails[0]?.gstNumber.length ? (
                                                <p>GST - {draftOrder?.OCustomerDetails[0]?.gstNumber[0]}</p>
                                            ) : null}

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                {draftOrder?.OCustomerDetails !== null && (
                                    <div className={Classes.containerBox}>
                                        <div className='mb-3'>
                                            <p className={Classes.asideHeaders}>
                                                CONTACT INFORMATION
                                            </p>
                                        </div>

                                        <div
                                            style={{
                                                color: '#2c6ecb',
                                                fontSize: '14px'
                                            }}
                                        >
                                            <p>{draftOrder?.OCustomerDetails[0]?.role}</p>
                                            <p>{draftOrder?.OCustomerDetails[0]?.phoneNumber}</p>
                                            <p>{draftOrder?.OCustomerDetails[0]?.email}</p>

                                            {/* <p style={{ marginTop: '5px' }}>
                                        </p> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {draftOrder?.OCustomerDetails !== null && (
                            <div>
                                <div>
                                    {draftOrder?.OCustomerDetails.length !== 0 ? (
                                        <div>
                                            {draftOrder?.OCustomerAddresses.map((vendor, index) => (
                                                <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                                    <div className={Classes.containerBox}>
                                                        <div>
                                                            <div className='mb-3'>
                                                                <p className={Classes.asideHeaders}>{vendor?.addressType?.toUpperCase()} ADDRESS</p>
                                                            </div>

                                                            <div key={index} style={{ fontSize: '14px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{vendor?.firstName}</p>
                                                                    <p>{vendor?.lastName}</p>
                                                                </div>

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <p style={{ marginRight: '5px' }}>{vendor?.addressLine1}</p>
                                                                    {vendor?.addressLine2 !== null ? (
                                                                        <p>{vendor?.addressLine2}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p style={{ marginRight: '5px' }}>{vendor?.pincode}</p>
                                                                    <p>{vendor?.city},</p>
                                                                </div>

                                                                <div>
                                                                    <p>{capitalizeState(vendor?.state)},</p>
                                                                </div>

                                                                <div>
                                                                    <p>{vendor?.phoneNumber}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {(draftOrder?.OSellerDetails?.[0] && draftOrder?.OSellerAddresses) && (
                <Permission permission={"read"} service={services?.sellers}>
                    <SellerDetail SellerAddress={draftOrder?.OSellerAddresses?.[0]} SellerDetail={draftOrder?.OSellerDetails?.[0]} />
                </Permission>
            )}
            {draftOrder?.Location && (
                <div className={Classes.Box} id='orderLocation'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Location
                        </h6>
                        <p className={Classes.remarkBox}>
                            {draftOrder?.Location?.locationName} ({draftOrder?.Location?.locationCode})
                        </p>
                    </div>
                </div>
            )}

            {deliveryRemark && (
                <div className={Classes.Box} id='deliveryRemark'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Delivery Remark
                        </h6>
                        <p className={Classes.remarkBox}>
                            {deliveryRemark}
                        </p>
                    </div>
                </div>
            )}

            {draftOrder?.OAffiliateUser && (
                <div className={Classes.Box} id='AffiliateUser'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Affiliate User
                        </h6>
                        <p className={Classes.remarkBox}>
                            {draftOrder?.OAffiliateUser?.name}
                        </p>
                    </div>
                </div>
            )}
            {draftOrder?.OSalesUser && (
                <div className={Classes.Box} id='SalesUser'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Sales User
                        </h6>
                        <p className={Classes.remarkBox}>
                            {draftOrder?.OSalesUser?.name}
                        </p>
                    </div>
                </div>
            )}
            {draftOrder?.OPreSalesUser && (
                <div className={Classes.Box} id='Pre-SalesUser'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Pre-Sales User
                        </h6>
                        <p className={Classes.remarkBox}>
                            {draftOrder?.OPreSalesUser?.name}
                        </p>
                    </div>
                </div>
            )}

            {paymentRemark && (
                <div className={Classes.Box} id='paymentRemark'>
                    <div style={{ padding: '0.8rem 1.1rem' }}>
                        <h6 style={{ margin: 'auto 0px' }}>
                            Payment Remark
                        </h6>
                        <p className={Classes.remarkBox}>
                            {paymentRemark}
                        </p>
                    </div>
                </div>
            )}

            {draftOrder?.length !== 0 && (
                <div id='removeTagsPrint'>
                    {draftOrder?.tags?.length !== 0 && (
                        <div className={Classes.Box} style={{ padding: '10px' }}>
                            <div className='mb-3'>
                                <h6 style={{ margin: 'auto 0px', padding: '6px' }}>
                                    Tags
                                </h6>
                            </div>

                            <div>
                                <div className={Classes.tagsBox}>
                                    {draftOrder?.tags?.map((tag, index) => (
                                        <p
                                            key={index}
                                            className={Classes.tags}
                                        >
                                            {tag}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}


        </Fragment>
    )
};

export default Aside;