import * as React from 'react';
import Table from '../../../components/table/table';
import { Fragment, useState } from 'react';
import ExportDatas from '../../../components/exportDataDialog';
import Button from '@mui/material/Button';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { setSkuTransactions } from '../../../store/skuTransactionsSlice';
import { Switch, Tooltip } from '@mui/material';

const columnVisibilityModel = {
    receivingBinCode: false,
    fromBinCode: false,
    toBinCode: false,
} 

const columns = [
    {
        field: `sku`,
        headerName: `SKU`,
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `title`,
        headerName: `Title`,
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `txnQty`,
        headerName: `Transaction Quantity`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
        renderCell: (params) => {
            return (
                <div>
                {parseInt(params.row.txnQty) > 0  ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                <span style={{color:parseInt(params.row.txnQty) > 0  ? 'green' : 'red'}}>{params.row.txnQty}</span>
                </div>
            )
        }
    },
    {
        field: `txnType`,
        headerName: `Transaction Type`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `direction`,
        headerName: `Direction`,
        flex: 0.8,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `receivingBinCode`,
        headerName: `Receiving Bin Code`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `fromBinCode`,
        headerName: `From Bin`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `toBinCode`,
        headerName: `To Bin`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `locationCode`,
        headerName: `Location Code`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `point_in_time_inventory`,
        headerName: `Point-In-Time Inv. (Avl. Qty)`,
        flex: 1.2,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `createdBy`,
        headerName: `Created By`,
        flex: 1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: true,
    },
    {
        field: `createdAt`,
        headerName: `Created At`,
        flex: 1.6,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'left',
        align: 'left',
        editable: false,
        sortable: true,
        filterable: false,
    },
];

export default function SkuTransactions() {
    const [open,setOpen] = useState(false);
    const [isMovementWithinVisible, setIsMovementWithinVisible] = useState(true);
    const handleExportDialog = () => {
        setOpen(true);
    }
    const skuTransactions = useSelector((state) => state.skuTransactions);
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Fragment>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:-25,width:'100%',marginBottom:10}}>
                <Button  
                    variant="contained"
                    color="primary"
                    sx={{ fontSize:14,textTransform:'capitalize',padding:"4px 14px",letterSpacing:"0.5px" }}
                    onClick={handleExportDialog}
                >
                    Export
                </Button>
            </div>

            <div className={Classes.MuiTable}>
                <Table 
                    rowId={'id'} 
                    columns={columns} 
                    dataPosition={"fetchedSkuTransactions"} 
                    endpoint={`/inventory/fetchSkuTransactions?movementWithin=${isMovementWithinVisible}`} 
                    checkBoxSelection={false}
                    service={'skuTransactions'}
                    columnVisibilityModel={columnVisibilityModel}
                    tabName={'SKU Transactions'}
                    setData={setSkuTransactions}
                    data={skuTransactions}
                    isMovementWithinVisible={isMovementWithinVisible}
                    extraToolBarButtons={[
                        <Tooltip title="Filter Inventory Moved Within" placement="top">
                            <Switch defaultChecked onChange={() => { setIsMovementWithinVisible((prev) => !prev) }} />
                        </Tooltip>
                    ]}
                />
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns} 
                    dataPosition={"fetchedSkuTransactions"} 
                    endpoint={'/inventory/fetchSkuTransactions'} 
                    rowId={'id'}
                />
            </div>

            <ExportDatas 
                open={open}
                handleClose={handleClose}
                endPoint={'/exports/skuTransactions'}
                dataName="SKU Transactions"
            />
        </Fragment>
    );
}