import { Fragment } from 'react';
import Classes from './css/orders.module.css';

const OtherDetails = (props) => {
    const { draftOrder } = props;

    return (
        <Fragment>
            <div style={{width:'100%',marginBottom:20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Other Details</p>
                    <div style={{display:'flex',padding:'15px',flexWrap:'wrap',width:'100%'}}>
                        <div style={{marginBottom:15}} className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Locations:</p>
                            {
                                draftOrder?.orderLocationList?.map((locationData) => 
                                <><p>{locationData.locationName}{" "}({locationData.locationCode})</p></>)
                            }
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{ fontWeight: 500, color: '#6d7175', fontSize: 14 }}>Platform:</p>
                            <p>{draftOrder?.createdVia || 'N/A'}</p>
                        </div>

                        <div style={{marginBottom:15}} className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Delivery Remark:</p>
                            <p>{draftOrder?.deliveryRemark || 'N/A'}</p>
                        </div>

                        <div style={{marginBottom:15}} className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Payment Remark:</p>
                            <p>{draftOrder?.paymentRemark || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Affiliate User:</p>
                            <p>{draftOrder?.OAffiliateUser?.name || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Sales User:</p>
                            <p>{draftOrder?.OSalesUser?.name || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Pre-Sales User:</p>
                            <p>{draftOrder?.OPreSalesUser?.name || 'N/A'}</p>
                        </div>

                        <div className={Classes.AddressCommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14,marginTop:10}}>Tags:</p>
                            <p>{draftOrder?.tags?.length !== 0 && draftOrder?.tags?.[0]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default OtherDetails;