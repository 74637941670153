import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../utility/axios-instance';
import PutAwayPopup from './putAwayPopup';
import QcPopup from './qcPopup';
import InboundDialog from './inboundPopup';
import SnackBar from '../../../components/SnackBar';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { Typography } from '@mui/material';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';
import MobileResponsive from "./mobileResponsive";
import Classes from '../customers/css/customer.module.css';
import { useNavigate } from 'react-router-dom';
import { setInwardPurchaseOrders } from '../../../store/inwardPoOrders';
import { setInboundPO } from '../../../store/inboundPoOrders';
import { setQCPurchaseOrders } from '../../../store/qcPurchaseOrders';
import { setPutAwayPurchaseOrders } from '../../../store/putAwayPoOrders';

const PoInwards = ({ type }) => {
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const handleForceClose = (row) => {
        axiosInstance.put(`/purchaseOrders/forceClosePo/${row?.purchaseOrderName}`)
            .then(res => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    const columnVisibilityModelPOTable = {
        locationName: false,
        openQty: false,
    }
    const columns = [
        {
            field: 'purchaseOrderName',
            headerName: 'PO Name',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'vendorName',
            headerName: 'Vendor',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: true,
        },
        {
            field: 'locationName',
            headerName: 'Location Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'PO Status',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1.4,
            sortable: true,
            filterable: false,
            headerClassName: 'super-app-theme--header',
            // renderCell: (params) => ( createdAt(params?.row?.createdAt))
        },
        {
            field: 'totalAmount',
            headerName: 'Amount',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'openQty',
            headerName: 'Open Qty',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            flex: 0.7,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Inbound',
            headerName: 'Inbound',
            headerClassName: 'super-app-theme--header',
            flex: 2,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                        <Fragment>
                            {+params?.row?.openQty > 0 && 
                                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                                <Permission service={services?.inwardPurchaseOrders} permission={"create"}>
                                    <Button
                                        variant="contained"
                                        size='small'
                                        color="primary"
                                        onClick={() => handleClickOpen(params?.row)}
                                    >Inbound</Button>
                                    </Permission>

                                    <Permission service={services?.inwardPurchaseOrders} permission={"update"}>
                                    <FormDialog
                                        dialogTitle="Force Close"
                                        dialogActionType="confirmation"
                                        openDialogButtonName="Force Close"
                                        submitDialogButtonName="Submit"
                                        onConfirmation={() => { handleForceClose(params?.row) }}
                                    >
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Are you sure you want to force close this po?
                                        </Typography>
                                    </FormDialog>
                                    </Permission>
                                </div>
                            }
                        </Fragment>)
            }
        },
    ];

    const columnVisibilityModelInboundTable = {
        qcStatus: false
    }
    const inboundColumns = [
        {
            field: 'inboundNumber',
            headerName: 'Inbound No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcStatus',
            headerName: 'QC Status',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'stagingBinCode',
            headerName: 'Staging Bin Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'user',
            headerName: 'Created By',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1.4,
            sortable: true,
            filterable: false,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'QC',
            headerName: 'QC',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            flex: 0.8,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.inwardPurchaseOrders} permission={"update"}>
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={() => handleQcOpen(params?.row)}
                >QC</Button>
                </Permission>
            )
        },
    ];

    const qcColumns = [
        {
            field: 'inboundNumber',
            headerName: 'Inbound No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable: true
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            flex: 1.4,
            sortable: true,
            filterable: false,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'qcPass',
            headerName: 'Pass',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcFail',
            headerName: 'Fail',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcPerson',
            headerName: 'QC Person',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'qcStatus',
            headerName: 'QC Status',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },

        {
            field: 'PutAway',
            headerName: 'PutAway',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.inwardPurchaseOrders} permission={"update"}>
                    <Button
                        variant="contained"
                        size='small'
                        color="primary"
                        onClick={() => handlePutawayOpen(params?.row)}
                    >PutAway</Button>
                </Permission>
            )
        },

    ];

    const putAwayColumns = [
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        },
        {
            field: 'qcPass',
            headerName: 'Qc Pass Qty',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        },
        {
            field: 'passBinCode',
            headerName: 'Saleable Bin Code',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        },
        {
            field: 'qcFail',
            headerName: 'Qc Fail Qty',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        },
        {
            field: 'failBinCode',
            headerName: 'Rejected Bin Code',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        }
    ];

    const [types, setTypes] = useState(type ? type : 'new');
    const [alignment, setAlignment] = useState(type ? type : 'new');
    const [reRender, setReRender] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [binsForInbound, setBinsForInbound] = useState([]);
    const [binId, setBinId] = useState(null);
    const [binIdForInbound, setBinIdForInbound] = useState(null);
    const [locations, setLocations] = useState([]);
    const [locationCode, setLocationCode] = useState(null);
    const [locationCodeForInbound, setLocationCodeForInbound] = useState(null);
    const [returnId, setReturnId] = useState(null);
    const [inboundDetails, setInboundDetails] = useState(null);
    const [selectedSku, setSelectedSku] = useState(null);
    const [rejectBinId, setRejectBinId] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState('');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const inwardPurchaseOrders = useSelector((state) => state.inwardPurchaseOrders);
    const inboundPO = useSelector((state) => state.inboundPO);
    const qcPurchaseOrders = useSelector((state) => state.qcPurchaseOrders);
    const putAwayPurchaseOrders = useSelector((state) => state.putAwayPurchaseOrders);
    const handleQcOpen = (details) => {
        setInboundDetails(details);
        setOpen(true);
    }

    const handleQcClose = () => {
        setInboundDetails(null);
        setOpen(false);
    }

    const handleClickOpen = (purchaseOrder) => {
        setPurchaseOrder(purchaseOrder)
        setOpen(true);
        setLocationCodeForInbound(purchaseOrder?.locationCode);
    };

    const handleClose = () => {
        setReturnId(null);
        setBinId(null);
        setBinIdForInbound(null);
        setRejectBinId(null);
        setLocationCode(null);
        setLocationCodeForInbound(null);
        setQuantity('');
        setReRender(!reRender);
        setOpen(false);
    };


    const handlePutawayOpen = async (purchaseOrder) => {
        setProducts(purchaseOrder)
        setOpen(true);
        setLocationCode(purchaseOrder?.locationCode);
        setSelectedSku(purchaseOrder?.sku)
    };

    const selectedCheckBoxId = useSelector((state) => state.selectedCheckBox.ids);
    const callFunction = useSelector((state) => state.selectedCheckBox.function);


    const handleChange = (event) => {
        setTypes(event.target.value);
        setAlignment(event.target.value);
        navigate(`/inwardPurchaseOrders${event.target.value === 'new' ? '' : `/${event.target.value}`}`)
    };

    useEffect(() => {
        axiosInstance.get(`/location/locations?text=`)
            .then(res => {
                const locations = [];
                for (let location of res?.data?.data) {
                    locations.push(location?.locationCode)
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    useEffect(() => {
        if (locationCodeForInbound) {
            axiosInstance.get(`/inventory/bins?locationCode=${locationCodeForInbound}&type=Staging`)
                .then(res => {
                    let bins = res?.data?.data?.bins;
                    setBinsForInbound(bins.map((bin) => bin.code))
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [locationCodeForInbound])

    const fetchLineItemsFromPurchaseOrder = async (purchaseOrderName) => {
        try {
            await axiosInstance.get(`/purchaseOrders/purchaseOrderDetails/${purchaseOrderName}`)
                .then((res) => {
                    setProducts(res?.data?.data?.PoLineItems)
                })
                .catch((err) => {
                    console.log(err)
                })

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (purchaseOrder) {
            fetchLineItemsFromPurchaseOrder(purchaseOrder?.purchaseOrderName);
        }
    }, [purchaseOrder])

    useEffect(() => {
        const params = {
            locationCode: locationCode,
            sku: selectedSku
        }
        axiosInstance.get(`/orderAllocation/fetchOrderAllocationFromBinAndLocation`, { params })
            .then(res => {
                if (res?.data?.data?.length > 0) {
                    setBinId(res?.data?.data[0]?.Bin)
                } else {
                    setBinId(null)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }, [locationCode, selectedSku]);

    const handlePutawaySubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            saleableBinCode: binId?.code,
            rejectedBinCode: rejectBinId?.code
        }
        await axiosInstance.post(`purchaseOrders/putAwayInboundPo/${products?.id}`, data)
            .then(res => {
                const successMessage = 'Order PutAway Successfully'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleClose();
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }

    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px' }}>
                <div style={{ backgroundColor: '#EDEDF7', borderRadius: 4, padding: 4 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{
                            "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                            "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                        }}
                    >
                        <ToggleButton value="new">New</ToggleButton>
                        <ToggleButton value="inbound">Inbound</ToggleButton>
                        <ToggleButton value="qc">Q.C</ToggleButton>
                        <ToggleButton value="putaway">Put Away</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>

            {types === 'new' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={columns}
                            dataPosition={"purchaseOrders"}
                            endpoint={`/purchaseOrders/purchaseOrders`}
                            rowId={'purchaseOrderId'}
                            rowSelectionEndpoint={`/pgOrder/admin/createReturnInbound`}
                            version="version-1"
                            reRender={reRender}
                            columnVisibilityModel={columnVisibilityModelPOTable}
                            initialFilterState={{
                                items: [
                                    {
                                        field: 'openQty', operator: '>', value: 0
                                    }
                                ]
                            }}
                            service={'purchaseOrders'}
                            type={types}
                            tabName={'Inward Po'}
                            setData={setInwardPurchaseOrders}
                            data={inwardPurchaseOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={columns}
                            dataPosition={"purchaseOrders"}
                            endpoint={`/purchaseOrders/purchaseOrders`}
                            rowId={'purchaseOrderId'}
                            reRender={reRender}
                            handleClickOpen={handleClickOpen}
                            handleForceClose={handleForceClose}
                        />
                    </div>
                </div>
            )}

            {types === 'inbound' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={inboundColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsInbound?type=purchaseOrder`}
                            rowId={'id'}
                            version="version-1"
                            reRender={reRender}
                            columnVisibilityModel={columnVisibilityModelInboundTable}
                            service={'inwardPurchaseOrders'}
                            type={types}
                            tabName={'Inward Po'}
                            setData={setInboundPO}
                            data={inboundPO}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={inboundColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsInbound?type=purchaseOrder`}
                            rowId={'id'}
                            reRender={reRender}
                            handleClickOpen={handleQcOpen}
                        />
                    </div>
                </div>
            )}

            {types === 'qc' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={qcColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsQcMarked?type=purchaseOrder`}
                            rowId={'id'}
                            reRender={reRender}
                            service={'inwardPurchaseOrders'}
                            type={types}
                            tabName={'Inward Po'}
                            setData={setQCPurchaseOrders}
                            data={qcPurchaseOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={qcColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsQcMarked?type=purchaseOrder`}
                            rowId={'id'}
                            reRender={reRender}
                            handleClickOpen={handlePutawayOpen}
                        />
                    </div>
                </div>
            )}

            {types === 'putaway' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={putAwayColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsPutAway`}
                            rowId={'id'}
                            reRender={reRender}
                            service={'inwardPurchaseOrders'}
                            type={types}
                            tabName={'Inward Po'}
                            setData={setPutAwayPurchaseOrders}
                            data={putAwayPurchaseOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={putAwayColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsPutAway`}
                            rowId={'id'}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'new' && (
                <InboundDialog
                    title={`Inbound Purchase Order:- ${purchaseOrder?.purchaseOrderName}`}
                    Id={purchaseOrder?.purchaseOrderName}
                    products={products}
                    bins={binsForInbound}
                    locationCode={locationCodeForInbound}
                    open={open}
                    handleClose={handleClose}
                />
            )}

            {types === 'inbound' && (
                <QcPopup
                    open={open}
                    handleClose={handleQcClose}
                    inboundDetails={inboundDetails}
                    reRender={reRender}
                    setReRender={setReRender}
                    loading={loading}
                    setLoading={setLoading}
                    url={"/purchaseOrders/qualityCheckInboundPo"}
                />
            )}

            {types === 'qc' && (
                <PutAwayPopup
                    title={'Put Away'}
                    open={open}
                    handleClose={handleClose}
                    locationCode={locationCode}
                    returnId={products}
                />
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}
export default PoInwards;