import React from "react";
import Classes from '../draftOrder/css/draftOrderDetailPage.module.css';
import { toTitleCase } from "../../../utility/textFormatting";

const SellerDetail = (props) => {
    const {
        SellerDetail,
        SellerAddress
    } = props;
    let {
        sellerName,
        sellerCode,
        sellerGstNumber,
        phoneNumber: sellerPhoneNumber,
        email,
    } = SellerDetail
    if(!sellerGstNumber && Array.isArray(SellerDetail?.sellerGstNumbers)){
        sellerGstNumber = SellerDetail?.sellerGstNumbers[0] 
    }
    const {
        addressType,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        city,
        state,
        pincode,
        country,
        phoneNumber
    } = SellerAddress
    return (
        <>
            <div
                className={[
                    Classes.Box,
                    'box'
                ].join(' ')}
            >
                <div>
                    {SellerDetail && (
                        <div>
                            <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                <div className={Classes.containerBox}>
                                    <div className={Classes.asideCustomer}>
                                        <h6 style={{ margin: 'auto 0px' }}>
                                            Seller
                                        </h6>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            fontSize: '14px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#2c6ecb',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <p style={{ marginRight: '5px' }}>
                                                {sellerName}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                <div className={Classes.containerBox}>
                                    <div className='mb-3'>
                                        <p className={Classes.asideHeaders}>
                                            CONTACT INFORMATION
                                        </p>
                                    </div>

                                    {SellerDetail && (
                                        <div
                                            style={{
                                                color: '#2c6ecb',
                                                fontSize: '14px'
                                            }}
                                        >
                                            <p><span>Seller Code:</span>&nbsp;{sellerCode}</p>
                                            <p>{email}</p>
                                            <p>{sellerPhoneNumber}</p>
                                            <p>{sellerGstNumber}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        {SellerAddress &&
                            <div>
                                <div style={{ borderBottom: '1px solid #DBDDE0' }}>
                                    <div className={Classes.containerBox}>
                                        <div>
                                            <div className='mb-3'>
                                                <p className={Classes.asideHeaders}>{addressType} Address</p>
                                            </div>

                                            <div style={{ fontSize: '14px' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={{ marginRight: '5px' }}>{firstName}</p>
                                                    <p>{lastName}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <p style={{ marginRight: '5px' }}>{addressLine1}</p>
                                                    {addressLine2 !== null ? (
                                                        <p>{addressLine2}</p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                    <p>{city},</p>
                                                </div>
                                                <div>
                                                    <p>{toTitleCase(state)},</p>
                                                </div>
                                                <div>
                                                    <span>{pincode},</span>&nbsp;
                                                    <span>{toTitleCase(country)}</span>
                                                </div>

                                                <div>
                                                    <p>{phoneNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>)
}

export default SellerDetail;