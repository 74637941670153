import { Fragment, useState } from 'react';
import Classes from '../css/addProduct.module.css';

const  Aside2ndBox = (props) => {
	const [tagInputValue, setTagInputValue] = useState('');

    const {
        brands,
        handleSelectBrand,
        vendors,
        handleSelectVendor,
        handleCollectionModal,
        storeCollections,
        showSelectedTags,
        showTagsPopup,
		productDetails,
		setSelectedTag,
		tags,
		setTags
    }= props;

	const handleAddTag = () => {
		if (tagInputValue !== '') {
			setSelectedTag([...showSelectedTags, tagInputValue]);
			setTags([...tags, tagInputValue]);
			// setSelectedTag([]);
			setTagInputValue('');
		}
	};

	const handleTagInputChange = (event) => {
		setTagInputValue(event.target.value);
	};


	return (
		<Fragment>
            <div 
				className={Classes.Box} 
				id='removeTagsPrint'
			>
				<div>
					<div>
						<div style={{display:'flex'}}>
							<p>Brands</p>
						</div>
						<div>
							{brands.length !==0 && (
								<select 
									name='brands' 
									id='brands'
									key={
										productDetails.length !== 0 && 
										productDetails[0].brandName !== undefined ?
										productDetails[0].brandName :
										''
									}
									defaultValue={
										productDetails.length !== 0 && 
										productDetails[0].brandName !== undefined ?
										productDetails[0].brandName :
										''
									}
									onChange={(e) => handleSelectBrand(e)}
								>
									<option value='' disabled={true}>
										Select Brand
									</option>
									{brands.map((brand) => (
										<option 
											key={brand._id} 
											data-brandabr={brand.brandAbr} 
											value={brand.title}
										>
											{brand.title}
										</option>
									))}
								</select>
							)}
						</div>
					</div>

					<div style={{marginTop:15}}>
						<div style={{display:'flex'}}>
							<p>Vendors</p>
							<p className={Classes.vinculumRequiredField}>*</p>
						</div>
						<div>
							{vendors.length !== 0 && (
								<select 
									name='vendors' 
									id='vendors'
									key={
										productDetails.length !== 0 && 
										productDetails[0].vendorName !== undefined ?
										productDetails[0].vendorName :
										''
									}
									defaultValue={
										productDetails.length !== 0 && 
										productDetails[0].vendorName !== undefined ?
										productDetails[0].vendorName :
										''
									}
									onChange={(e) => handleSelectVendor(e)}
								>
									<option 
										value='' 
										disabled={true} 
										style={{ fontSize:14 }}
									>	
										Select Vendor
									</option>
									{vendors.map((vendor) => (
										<option 
											key={vendor._id} 
											data-vendorcode={vendor.vendorCode}
											value={vendor.vendorName}
											style={{ fontSize:14 }}
										>
											{vendor.vendorName}
										</option>
									))}
								</select>
							)}
						</div>
					</div>
					
					<div style={{marginTop:15}}>
						<div style={{display:'flex'}}>
							<p>Collections</p>	
							<p className={Classes.vinculumRequiredField}>*</p>																				
						</div>
					
						<div>
							<input
								type='text'
								id="collection3"
								autoComplete='off'
								className={[
									Classes.searchField, 
									Classes.VendorField
								].join(' ')}
								onClick={handleCollectionModal}
							/>
						</div>

						<div>
							{storeCollections.length !== 0 && (
								<div className={Classes.ShowCollections}>
									{storeCollections.map((collection,index) => (
										<p 
											key={index} 
											className={Classes.Collections}
										>
											{collection}
										</p>
									))}
								</div>
							)}
						</div>
					</div>

					<div 
						className='d-flex justify-content-between'  
						style={{marginTop:15}}
					>
						<p style={{margin: 'auto 0px',padding: '6px'}}>Tags</p>
						<div>
							{showSelectedTags? (
								<p
									onClick={showTagsPopup}
									className={Classes.ChangeTags}
								>
									Change Tags
								</p>
							): (
								<p
									onClick={showTagsPopup}
									className={Classes.AddNewTags}
									>
									Add New Tags
								</p>
							)}
							
						</div>
					</div>
					
					<div className={Classes.searchContainer}>
						<div className={Classes.SearchTagInput}>
							<input
								type="text"
								id="search"
								style={{width:'100%',borderRadius:4}}
								className={Classes.searchField}
								autoComplete='off'
								placeholder='Find or create tags'
								value={tagInputValue}
								onChange={handleTagInputChange}
							/>
						</div>
						<div style={{display:'flex', justifyContent:'end', margin:'2px 0px'}}>
						<div style={{border:'2px solid grey', padding:'0px 6px', cursor:'pointer', borderRadius:'4px', fontSize:'13px'}} onClick={handleAddTag}>Add Tag</div>
						</div>
					</div>

					<div>
						{tags && (
							<div className={Classes.ShowCollections}>
								{tags.length !== 0 && (
									<div className={Classes.ShowCollections}>
										{tags.map((collection,index) => (
											<p 
												key={index} 
												className={Classes.Collections}
											>
												{collection}
											</p>
										))}
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Aside2ndBox;
