import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Classes from "../customers/css/customer.module.css";
import axiosInstance from "../../../utility/axios-instance";
import NewPagination from "../../../components/newPagination";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Spinner from "../../../components/spinner";
import { services } from "../../../utility/checkRoleBasedPermission";
import Permission from "../../auth/permissions";
import moment from 'moment';
import MobileResponsive from '../orderAllocation/mobileResponsive';

const Commission = (props) => {
  const navigate = useNavigate();

  // const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    page: 1,
    total: 0,
    maxPage: 1,
    commissions: [],
  });
  const [sort, setSort] = useState({
    field: "commission.created_at",
    sort: "desc",
  });
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState();

  const columns = [
    {
      field: "orderName",
      headerName: "ORDER NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "displayName",
      headerName: "CUSTOMER",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "commission_amount",
      headerName: "COMMISSION AMOUNT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "payment_received",
      headerName: "PAYMENT RECEIVED",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "transferred",
      headerName: "TRANSFERRED",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    // {
    //   field: "affiliate",
    //   headerName: "AFFILIATE",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   sortable: false,
    // },
    {
      field: "approved_by",
      headerName: "APPROVED BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "CREATED AT",
      flex: 1.6,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];


  const getData = async (page = 1) => {
    setLoading(true);
    setError(null);

    axiosInstance
      .get(
        `/others/getCommissions?page=${page}&${
          sort ? `sortField=${sort.field}&sort=${sort.sort}` : ""
        }`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, search]);

  return (
    <Fragment>
      {loading ? <Spinner /> : null}
      <div id='color'>
        <div
          className='alert alert-primary p-2'
          data-file-upload-alert
          role='alert'
          style={{ display: "none" }}
          id='fileDownloading'
        >
          Sheet is exporting...
        </div>

        <div
          className='d-flex'
          style={{ justifyContent: "flex-end", marginBottom: ".5rem",marginTop:-25 }}
        >
          <Permission service={services?.commissions} permission={"create"}>
            <Button
              startIcon={<PersonAddIcon />}
              variant='contained'
              color='primary'
              sx={{
                fontSize: "14px",
                textTransform: "capitalize",
                marginRight: "5px",
                "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "17px" },
              }}
              onClick={() => {
                navigate("/createCommission");
              }}
            >
              Create
            </Button>
          </Permission>
        </div>

        {error ? (
          <Alert variant='danger' onClose={() => setError(false)} dismissible>
            <Alert.Heading>{error}</Alert.Heading>
            <p>Try reloading!</p>
          </Alert>
        ) : null}

        <div className={[Classes.Container,Classes.MuiTable].join(' ')}>
          <Box
            component={Paper}
            className='shadow'
            sx={{
              "& .super-app-theme--header": {
                backgroundColor: "#243750",
                color: "#ffffff",
                cursor: "default",
              },
            }}
          >
            <DataGrid
              // rowCount={estimates.estimates.length}
              slots={{ toolbar: GridToolbar }}
              filterMode='server'
              filterColumns={["name"]}
              sortingMode='server'
              paginationMode='server'
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }],
                },
              }}
              onSortModelChange={(sort) => setSort(sort[0])}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                boxShadow: 2,
                borderRadius: 2,
                cursor: "pointer",
              }}
              columns={columns}
              rows={data?.commissions?.map((commission) => ({
                id: commission?.id,
                orderName: commission?.orderName,
                displayName: commission?.displayName,
                commission_amount: commission?.commission_amount,
                payment_received: commission?.payment_received,
                transferred: commission?.transferred,
                affiliate: commission?.affiliate?.displayName,
                approved_by: commission?.approved_by?.username,
                created_at: 
                  moment(commission?.createdAt).format('MMM DD, YYYY h:mm:ss A') ?? 
                  moment(commission?.created_at).format('MMM DD, YYYY h:mm:ss A')
              }))}
              onRowClick={(params) => {
                navigate(`${params.id}`);
              }}
              sortingOrder={["asc", "desc"]}
              autoHeight
              autoPageSize
              onFilterModelChange={(filter) => {
                setFilter(filter.items[0]);
                setSearch(filter.quickFilterValues[0]);
              }}
            />
          </Box>

          <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
            <NewPagination totalPage={data} fetchData={getData} />
          </div>
        </div>

        <div className={Classes.MobileResponsive}>
          <MobileResponsive 
            columns={columns} 
            dataPosition={"commissions"} 
            endpoint={`/others/getCommissions`} 
            rowId={'id'}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Commission;
