import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../draftOrder/css/draftOrder.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";


const TaxRatesPopup = (props) => {
    const {
        showTaxRate,
        hideTaxPopup,
        draftOrder,
        type
    } = props;

    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    return (
        <Fragment>
            {showTaxRate && (
                <div className={Classes.taxModal} style={{ bottom:'34%',top:'14%' }}>
                    <div className={Classes.BoxContent} style={{boxShadow: "0 5px 5px -5px #000"}}>
                        <p>Tax rates</p>
                        <div>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={Classes.TaxRateCloseIcon}
                                onClick={hideTaxPopup}
                            />
                        </div>
                    </div>


                    {draftOrder?.length !== 0 && (
                        <div className={Classes.draftOrderModal} style={{height:'74.5%'}}>
                            {type === "draftOrders" ? (
                                <div>
                                    {draftOrder?.TaxLines?.map((taxes, index) => (
                                        <div style={{ padding:10 }} key={index}>
                                            {Object.keys(taxes).map((hsnCode) => (
                                                <div key={hsnCode}>
                                                    <p style={{ fontWeight: 500 }}>HSN code ({hsnCode})</p>
                                                    {Object.keys(taxes[hsnCode]).map((taxRate) => (
                                                        <div>
                                                            <div style={{ display: 'flex' }}>
                                                                <p style={{ visibility: 'hidden' }}>***</p>
                                                                <p>{taxRate}% Tax</p>
                                                            </div>

                                                            <div>
                                                                {taxes[hsnCode][taxRate].cgstRate !== 0 && (
                                                                    <div style={{ display:'flex',justifyContent:'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* this p tag is for indentation */}
                                                                            <p style={{ visibility: 'hidden' }}>******</p>
                                                                            <p>CGST</p>
                                                                            <p>({taxes[hsnCode][taxRate].cgstRate}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>&#8377;{formatNumber(taxes[hsnCode][taxRate]?.cgstAmount)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {taxes[hsnCode][taxRate].sgstRate !== 0 && (
                                                                    <div style={{ display:'flex',justifyContent:'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* this p tag is for indentation */}
                                                                            <p style={{ visibility: 'hidden' }}>******</p>
                                                                            <p>SGST</p>
                                                                            <p>({taxes[hsnCode][taxRate].sgstRate}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>&#8377;{formatNumber(taxes[hsnCode][taxRate]?.sgstAmount)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {taxes[hsnCode][taxRate].igstRate !== 0 && (
                                                                    <div style={{ display:'flex', justifyContent:'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* this p tag is for indentation */}
                                                                            <p style={{ visibility: 'hidden' }}>******</p>
                                                                            <p>IGST</p>
                                                                            <p>({taxes[hsnCode][taxRate].igstRate}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>&#8377;{formatNumber(taxes[hsnCode][taxRate]?.igstAmount)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>
                                    {draftOrder?.OTaxLines?.map((taxes, index) => (
                                        <div style={{ padding:10 }} key={index}>
                                            {Object.keys(taxes).map((hsnCode) => (
                                                <div key={hsnCode}>
                                                    <p style={{ fontWeight: 500 }}>HSN code ({hsnCode})</p>
                                                    {Object.keys(taxes[hsnCode]).map((taxRate) => (
                                                        <div>
                                                            <div style={{ display: 'flex' }}>
                                                                <p style={{ visibility: 'hidden' }}>***</p>
                                                                <p>{taxRate}% Tax</p>
                                                            </div>

                                                            <div>
                                                                {taxes[hsnCode][taxRate].cgstRate !== 0 && (
                                                                    <div style={{ display:'flex',justifyContent:'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* this p tag is for indentation */}
                                                                            <p style={{ visibility: 'hidden' }}>******</p>
                                                                            <p>CGST</p>
                                                                            <p>({taxes[hsnCode][taxRate].cgstRate}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>&#8377;{taxes[hsnCode][taxRate].cgstAmount}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {taxes[hsnCode][taxRate].sgstRate !== 0 && (
                                                                    <div style={{ display:'flex',justifyContent:'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* this p tag is for indentation */}
                                                                            <p style={{ visibility: 'hidden' }}>******</p>
                                                                            <p>SGST</p>
                                                                            <p>({taxes[hsnCode][taxRate].sgstRate}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>&#8377;{taxes[hsnCode][taxRate].sgstAmount}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {taxes[hsnCode][taxRate].igstRate !== 0 && (
                                                                    <div style={{ display:'flex',justifyContent:'space-between' }}>
                                                                        <div style={{ display: 'flex' }}>
                                                                            {/* this p tag is for indentation */}
                                                                            <p style={{ visibility: 'hidden' }}>******</p>
                                                                            <p>IGST</p>
                                                                            <p>({taxes[hsnCode][taxRate].igstRate}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>&#8377;{taxes[hsnCode][taxRate].igstAmount}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className={Classes.TotalTax}>
                                <div>
                                    <p>Total Tax</p>
                                </div>
                                <div>
                                    <p>&#8377;{type === "draftOrders" ? draftOrder?.DoPaymentDetail?.totalTaxPrice : draftOrder?.OPaymentDetail?.totalTaxPrice}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
}


export default TaxRatesPopup;