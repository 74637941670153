import { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField, Container, InputAdornment } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import '../createOrders/css/styles.css';
import CustomerModal from './customerModal';

const VisitCustomerModal = (props)=> {
    const [loading,setLoading] = useState(false);
    const [salesTeam,setSalesTeam] = useState([]);
    const [searchCustomer,setCustomer] = useState('');
    const [toggleButton,setToggleButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [toggle,setToggle] = useState(true);

    const handleSearchCustomer = (customer)=> {
		setToggleButton(true);
		setCustomer(customer);
	}

    const { 
        errorMessage,
        setErrorMessage,
        fetchCustomers,
        salesUser,
        setSalesUser,
        customerInfo,
        setCustomerInfo,
        modeOfCommunication,
        setModeOfCommunication
    } = props;


    useEffect(() => {
        axiosInstance
            .get('/fieldSalesVisit/allSalesUsers')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setSalesTeam(arr);
            })
    },[]);

    const showCustomerModal = (searchItem) => {
        setToggle(true)
        if (showModal) {
            hideCustomerModal();
        }else {
            setShowModal(true);
        }
	};

	const hideCustomerModal= () => {
		setShowModal(false);
	}

    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('addCustomer')
        button.innerHTML = 'Adding Task..';

        const data = {
            salesUser: salesUser ? salesUser : undefined,
            modeOfCommunication: modeOfCommunication ? modeOfCommunication : undefined,
            customers: customerInfo
        }

        axiosInstance
            .post('/fieldSalesVisit/createFieldVisit',{...data})
            .then((res) =>{
                const form = document.getElementById('form');
                form.reset();
                props.onHideModal();
                fetchCustomers();
                setLoading(false);
                setErrorMessage('');
                setSalesUser('');
                // setPreSalesUser('');
                button.innerHTML = 'Visit Customer Added';
            }).catch((err) => {
                button.innerHTML = 'Add Customer';
                setLoading(false);

                if(err.response.data.hasOwnProperty('keyPattern')){
                    const existData = Object.keys(err.response.data.keyPattern)
 
                    if(existData[0] === 'email'){
                        setErrorMessage('This email already exists, Please enter another email')
                    }else if( existData[0] === 'phone'){
                        setErrorMessage('This number already exists, Please enter another number')
                    }   
                }else{
                    if(err.response.data.hasOwnProperty('message')){
                        if(err.response.data.message === 'Error creating user on Shopify'){
                            setErrorMessage('Please try to provide different input, If Issue Still Persist Then Contact Tech Team')   
                        }else{
                            setErrorMessage(err.response.data.message)
                        }
                    }
                }
            })
    }

    const handleRemoveCustomer = (event) => {
        setCustomerInfo((customerInfo) =>
            customerInfo.filter((ele) => ele.id !== event.target.id)
		);
    }

    return (
        <Fragment>
            {props.onShowModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'6%',bottom:'11%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>Select Customers</p>
                    </div>

                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{ overflowY:'auto',height:'72%' }}
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>
                        <div style={{padding:"20px 20px 0px 20px"}}>
                            {salesTeam.length !== 0 && (
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <div style={{width:'49%'}}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="salesUser"
                                            options={salesTeam}
                                            sx={{width: '100%',"& label": {top: salesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => {
                                                setSalesUser(newValue.Id);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Sales User"/>}
                                        />
                                    </div>

                                    <div style={{width:'49%'}}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="modeOfCommunication"
                                            name="modeOfCommunication"
                                            options={[{label:'Field Visit'},{label:'On-Call'}]}
                                            sx={{width: '100%',"& label": {top: modeOfCommunication ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => {
                                                setModeOfCommunication(newValue.label);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Mode Of Communication"/>}
                                        />
                                    </div>
                                </div>
                            )}

                            <div style={{marginTop:20}}>
                                <div className={Classes.SelectedCustomers}>
                                    {customerInfo.length !==0 && customerInfo.map((customer) => (
                                        <li key={customer.id} style={{display:'flex'}}>
                                            {customer.name}
                                            <div 
                                                onClick={handleRemoveCustomer} 
                                                id={customer.id} 
                                                style={{cursor: "pointer",marginLeft:4}}
                                            >
                                                <FontAwesomeIcon 
                                                    icon={faCircleXmark} 
                                                    style={{
                                                        color: 'rgb(129, 138, 155)',
                                                        pointerEvents:'none'
                                                    }}
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </div>

                                <Container maxWidth="md" disableGutters={true} sx={{marginTop:"5px"}}>
                                    <TextField
                                        id="search"
                                        type="search"
                                        label="Select Customer"
                                        value={searchCustomer}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: searchCustomer ? "0%":"-18%"},"& .MuiOutlinedInput-input": {padding:"8.5px 14px" }}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => handleSearchCustomer(e.target.value)}
										onClick={() => showCustomerModal()}
                                    />
                                </Container>                
                            </div>
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'11%',
                                position:'fixed',
                                boxShadow: 'none',
                                borderTop: 0
                            }}
                        >
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={props.onHideModal}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                disabled={customerInfo.length === 0 ? true : false}
                            >
                                ADD TASK
                            </button>
                        </div>
                        
                        <div>
                            <CustomerModal
                                showModal={showModal}
                                onHideModal={hideCustomerModal}
                                searchCustomer={searchCustomer}
                                setSearchCustomer={setCustomer}
                                toggleButton={toggleButton}
                                setToggleButton={setToggleButton}
                                toggle={toggle}
                                setToggle={setToggle}
                                customerInfo={customerInfo}
                                setCustomerInfo={setCustomerInfo}
                            />
                        </div>
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
                
            )}
        </Fragment>
    );
}


export default VisitCustomerModal;