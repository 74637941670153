import { Fragment } from 'react';
import Classes from '../css/draftOrderDetailPage.module.css';
import SellerDetail from '../../ordersNew/sellerDetail';


const Aside = (props) => {

	const { 
		draftOrder,
		paymentRemark,
		deliveryRemark
	} = props;


	const capitalizeState = (text) => {
        const arr = text.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
		return str2;
	}


	return (
		<Fragment>
				<div 
					className={[
						Classes.Box,
						'box'
					].join(' ')}
				>
					{draftOrder?.length !== 0 && draftOrder?.user  !== null && (
						<div id='customerDetails'> 
							<div id='customerData'>
								<div style={{borderBottom: '1px solid #DBDDE0'}}>
									<div className={Classes.containerBox} id='customerBox'>
										<div className={Classes.asideCustomer} id='customerHeader'>
											<h6 style={{margin: 'auto 0px'}}>
												Customer
											</h6>
										</div>

										<div 
											style={{
												display:'flex',
												justifyContent:'space-between',
												fontSize: '14px'
											}}
										>
											<div 
												style={{
													color: '#2c6ecb',
													display:'flex'
												}}
											>
												{draftOrder?.user?.displayName ? (
													<p style={{marginRight:'5px'}}>
														{draftOrder?.user?.displayName}
													</p>
												): (
													<p style={{marginRight:'5px'}}>
														{draftOrder?.user?.firstName + ' ' + 
															(draftOrder?.user?.lastName === 'undefined'
															? ''
															: draftOrder?.user?.lastName)
														}
													</p>
												)}
											</div>
										
										</div>
									</div>
								</div>

								<div style={{borderBottom: '1px solid #DBDDE0'}}>
									<div className={Classes.containerBox} id='contactBox'>
										<div 
											className={Classes.ContactHeader}
											id='contactHeader'
										>
											<p 	className={Classes.asideHeaders}>
												CONTACT INFORMATION
											</p>
										</div>

										<div 
											style={{
												color: '#2c6ecb',
												fontSize: '14px'
											}}
										>
											<p>{draftOrder?.user?.email}</p>
											<p style={{marginTop: '5px'}}>
												{draftOrder?.user?.phone}
											</p>
										</div>
									</div>
								</div>

								{draftOrder?.metafields?.length !== 0 && (
									<div 
										className={Classes.MetafieldBox}
										id='gstBox'
									>
										<p className={Classes.GstContent}>
											GST Number
										</p>
										<p className={Classes.GstNumber}>
											{draftOrder?.metafields[0]?.value}
										</p>
									</div>
								)}
							</div>

							<div id='shippingAddress'>
								{draftOrder?.shippingAddress ? (
									<div 
										className={Classes.commonBorderBottom}
										id='shippingAddressBox'
									>
										<div className={Classes.containerBox}>
											<div className='mb-3'>
												<p className={Classes.asideHeaders}>
													SHIPPING ADDRESS
												</p>
												
											</div>

											<div style={{fontSize: '14px'}}>
												<div style={{display:'flex'}}>
													<p style={{marginRight:'5px'}}>
														{draftOrder?.shippingAddress?.firstName}
													</p>
													<p>{draftOrder?.shippingAddress?.lastName}</p>
												</div>

												<div style={{display:'flex',flexDirection:'column'}}>
													<p style={{marginRight:'5px'}}>
														{draftOrder?.shippingAddress?.address1}
													</p>
													{draftOrder?.shippingAddress?.address2 !== null 
														?<p>{draftOrder?.shippingAddress?.address2}</p>
													: ''
													}
												</div>

												<div style={{display:'flex'}}>
													<p style={{marginRight:'5px'}}>
														{draftOrder?.shippingAddress?.zip}
													</p>	
													<p>{draftOrder?.shippingAddress?.city},</p>
												</div>
												
												<div>
													<p>{capitalizeState(draftOrder?.shippingAddress?.province)},</p>
												</div>
												<div style={{display:'flex'}}>
														
													<p>{draftOrder?.shippingAddress?.country}</p>
												</div>
												<div>
													<p>{draftOrder?.shippingAddress?.phone}</p>
												</div>
											</div>
										</div>
									</div>
								):null}
							</div>
						
							{draftOrder?.customerBillingAddress ? (
								<div id='BillingAddress'>
									<div className={Classes.containerBox}>
										<div className='mb-2'>
											<p className={Classes.asideHeaders}>
												BILLING ADDRESS
											</p>
										</div>

										<div style={{fontSize: '14px'}}>
											<div style={{fontSize: '14px'}}>
												<div style={{display:'flex'}}>
													<p style={{marginRight:'5px'}}>
														{draftOrder?.customerBillingAddress?.firstName}
													</p>
													<p>{draftOrder?.customerBillingAddress?.lastName}</p>
												</div>

												<div style={{display:'flex',flexDirection:'column'}}>
													<p style={{marginRight:'5px'}}>
														{draftOrder?.customerBillingAddress?.address1}
													</p>
													{draftOrder?.customerBillingAddress?.address2 !== null 
														?<p>{draftOrder?.customerBillingAddress?.address2}</p>
													: null}
												</div>

												<div style={{display:'flex'}}>
													<p style={{marginRight:'5px'}}>
														{draftOrder?.customerBillingAddress?.zip}
													</p>	
													<p>{draftOrder?.customerBillingAddress?.city},</p>		
												</div>

												<div>
													<p>
														{capitalizeState(
															draftOrder?.customerBillingAddress?.province
														)},
													</p>
												</div>
												<div>
													<p>{draftOrder?.customerBillingAddress?.country}</p>
												</div>
												<div>
													<p>{draftOrder?.customerBillingAddress?.phone}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							):null}
							
						</div>
					)}
				</div>

			{draftOrder?.sellerBillingAddress && draftOrder?.seller &&
				<SellerDetail SellerAddress={draftOrder?.sellerBillingAddress} SellerDetail={draftOrder?.seller} />
			}
				
				{draftOrder?.length !== 0 && (
					<div id='gstNumberBox' >
						{draftOrder?.metafields?.length !== 0 && (
							<div 
								className={[
									Classes.Box,
									Classes.asideMetafields,
									'box'
								].join(' ')}
							>
								<h6 style={{margin: '5px 0px'}}>
									GST Number
								</h6>
								<p style={{ fontSize:'14px', paddingTop:5 }}>
									{draftOrder?.metafields[0]?.value}
								</p>
							</div>
						)}
					{draftOrder?.createdVia && (
						<div
							className={[
								Classes.Box,
								Classes.asideMetafields,
								'box'
							].join(' ')}
							id='platform'
						>
							<h6 style={{margin: '5px 0px'}}>
								Platform
							</h6>
							<p style={{ fontSize: '14px', paddingTop: 5 }}>
								{draftOrder?.createdVia || "N/A"}
							</p>
						</div>
					)}
					</div>
				)}

				{deliveryRemark && (
					<div className={Classes.Box} id='deliveryRemark'>
						<div style={{padding: '0.8rem 1.1rem'}}>
							<h6 style={{margin: 'auto 0px'}}>
								Delivery Remark
							</h6>
							<p className={Classes.remarkBox}>
								{deliveryRemark}
							</p>
						</div>
					</div>
				)}
                

				{paymentRemark && (
					<div className={Classes.Box} id='paymentRemark'>
						<div style={{padding: '0.8rem 1.1rem'}}>
							<h6 style={{margin: 'auto 0px'}}>
								Payment Remark
							</h6>
							<p className={Classes.remarkBox}>
								{paymentRemark}
							</p>
						</div>
					</div>
				)}

				
				{draftOrder?.length !== 0 && (
					<div className={Classes.Box} style={{padding:10}} id='affiliateDetails'>
						<div style={{display:'flex'}}>
							<h6>Affiliate:</h6>
							<p style={{fontSize:14,marginLeft:5}}>
								{draftOrder?.affiliateUser?.name}
							</p>
						</div>

						<div style={{display:'flex'}}>
							<h6>Sales User:</h6>
							<p style={{fontSize:14,marginLeft:5}}>
								{draftOrder?.salesUser?.name}
							</p>
						</div>

						<div style={{display:'flex'}}>
							<h6>Pre Sales User:</h6>
							<p style={{fontSize:14,marginLeft:5}}>
								{draftOrder?.preSalesUser?.name}
							</p>
						</div>
					</div>
				)}
				
				{draftOrder?.length !== 0 && (
					<div  id='removeTagsPrint'>
						{draftOrder?.tags?.length !== 0 && (
							<div className={Classes.Box} style={{padding:'10px'}}>
								<div className='mb-3'>
									<h6 style={{margin: 'auto 0px',padding: '6px'}}>
										Tags
									</h6>
								</div>
								
								<div>
									<div className={Classes.tagsBox}>
										{draftOrder?.tags?.map((tag,index) => (
											<p 
												key={index} 
												className={Classes.tags}
											>
												{tag}
											</p>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				)}

		</Fragment>
	)				
};

export default Aside;
