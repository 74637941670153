export const services = {
    deliveryBoy : "deliveryBoy",
    createOrder : "createOrder",
    products : "products",
    customers : "customers",
    orders : "orders",
    draftOrder : "draftOrder",
    cancels : "cancelOrder",
    returns : "returnOrder",
    refunds : "refundOrder",
    updatePricing : "updatePricing",
    reportService : "reportService",
    uploadImages : "uploadImages",
    inventory : "inventory",
    inventoryView : "inventoryView",
    inventoryMove : "inventoryMove",
    inventoryAdjustments : "inventoryAdjustments",
    skuToQRC : "skuToQRC",
    blog : "blog",
    uploadOthersMaster : "uploadOthersMaster",
    dispatch : "orderDispatches",
    draftPurchaseOrders : "draftPurchaseOrders",
    purchaseOrders : "purchaseOrders",
    createPurchaseOrder : "createPurchaseOrder",
    inboundPurchaseOrders : "inboundPurchaseOrders",
    inwardPurchaseOrders : "inwardPurchaseOrders",
    orderAllocation : 'orderAllocations',  
    sellers : "sellers",
    bin : "bins",
    inwardReturns : "inwardReturns",
    deliveryShipping : "deliveryShipping",
    pushNotifications : "pushNotifications",
    picklists : "picklists",
    packing : "packing",
    fieldSales : "fieldSales",
    salesManager : 'salesManager',
    scheme : "scheme",
    skuTransactions : "skuTransactions",
    locations : "locations",
    affiliates : "affiliates",
    commissions : "commissions",
    createCommission : "createCommission",
    affiliateCommissions : "affiliateCommissions",
    estimates : "estimates"
}

export const permission = {
    create : "create",
    read : "read",
    update : "update",
    delete : "delete"
}

export const CheckRoleBasedPermission = (userData, service , permission ) => {
    let isAuthorized = false;

    let isUserHaveGivenService = userData.services.find( item => item.service === service );
    if(isUserHaveGivenService){
        isAuthorized = isUserHaveGivenService.permissions.includes(permission);
    }

    return isAuthorized || ["super-admin","admin"].includes(userData.role);
}