import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/draftOrderDetailPage.module.css';
import Aside from './aside/aside';
import axiosInstance from '../../../utility/axios-instance';
import TaxRatesPopup from './TaxRates_popup';
import TaxRatesPrint from './TaxRatesPrint';
import ConfirmPopup from '../createOrders/confirmPopup';
import DeleteOrderConfirmPopup from './deleteOrderPopup';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch  } from 'react-redux';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';
import CapsuleChip from '../../../components/CapsuleChip';

const DraftOrderDetailPage = (props) => {
	const [error, setError] = useState(null);
    const [showTaxRate,setTaxRates] = useState(false);
	const [draftOrder,setDraftOrder] = useState([]);
	const [totalItems,setTotalItems] = useState(0);
	const [taxRates,setTaxRateArray] = useState([]);

	// store deliveryRemark & paymentRemark in State
	const [paymentRemark, setPaymentRemark] = useState('');
	const [deliveryRemark, setDeliveryRemark] = useState('');

	// sendExistOrder
	const [updateToggle, setUpdateToggle] = useState(true);
	const [completeExistOrder, setCompleteExistOrder] = useState([]);
	const [showConfirmPopup, setConfirmShowPopup] = useState(false);
	const [showDeleteConfirmPopup,setDeleteConfirmPopup] = useState(false);
	const [loading, setLoading] = useState(false);

	const auth = useSelector((state) => state.auth);
    const breadCrumb = useSelector((state) => state.breadcrumb);


	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { id } = useParams();

	// handle Discount
	const handleDiscount = (mrp, discountedPrice, value) => {
		const MRP = parseFloat(mrp);
		const DP = parseFloat(discountedPrice);
		const GST = parseInt(value);
		const result = (MRP - (DP * (1 + GST / 100))) / MRP;
		const TotalDiscount = result * 100;
		let countDecimal = countPlaces(TotalDiscount);
		let results =  countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? TotalDiscount : Math.trunc(TotalDiscount*100)/100;
		return results;
	};

	
	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

	// Open Window Print
	const handleSendPI = (event) => {
		const customerBox = document.getElementById('customerBox');
		const customerBoxHeader = document.getElementById('customerHeader');
		const contactBox = document.getElementById('contactBox');
		const contactBoxHeader = document.getElementById('contactHeader');
		const hideButtons = document.getElementById('removeCreateOrderPrint');
		const hideHeader = document.getElementById('hideHeader');
		const hideProductHeader = document.getElementById('productHeading');
		const taxRate = document.getElementById('taxRate');
		const tax = document.getElementById('tax');
		const taxRateAmount = document.getElementById('taxRateAmount');
		const taxRatesPrint = document.getElementById('TaxRatesPrint');
		
		if (draftOrder?.metafields?.length !== 0) {
			contactBox.style.padding = '0.6rem 1.1rem';
			contactBoxHeader.style.marginBottom = '0.5rem';
			customerBox.style.padding = '0.6rem 1.1rem';
			customerBoxHeader.style.marginBottom = '0.5rem';
		}
	
		taxRatesPrint.style.display = 'none';
		hideProductHeader.style.width = '60%';
		hideHeader.style.display = 'none';
		hideButtons.style.display = 'none';
		taxRate.style.display = 'none';
		tax.style.width = '50%';
		taxRateAmount.style.width = '50%';

		if (draftOrder.length !== 0) {
			onbeforeprint = document.title = draftOrder?.draftOrderName ? draftOrder?.draftOrderName : draftOrder.draftOrder.name.slice(1);
		}

		window.print();
		onafterprint = tax.style.width = '30%';
		onafterprint = document.title = 'DEPO24 PORTAL';
		onafterprint = taxRateAmount.style.width = '40%';
		onafterprint = hideHeader.style.display = 'flex';
		onafterprint = hideProductHeader.style.width = '75%';
		onafterprint = hideButtons.style.display = 'block';
		onafterprint = taxRate.style.display = 'block';	
		// onafterprint = document.getElementById('proformaInvoice').style.display = 'none';
		onafterprint = taxRatesPrint.style.display = 'block';
		onafterprint = customerBox.style.padding = '1.1rem';
		onafterprint = customerBoxHeader.style.marginBottom = '1.5rem';
		onafterprint = contactBox.style.padding = '1.1rem';
		onafterprint = contactBoxHeader.style.marginBottom = '1rem';
	}

	const handleTaxRates = () => {
		setTaxRates(true);
	};

	const hideTaxPopup = () => {
		setTaxRates(false);
	}

	// Complete Exist Order
	const handleCompleteOrder = (event) => {
		setLoading(true);
		const submitBtn = document.getElementById('completeOrder');
		submitBtn.disabled = true;
		submitBtn.innerHTML = 'Completing Order...'
		axiosInstance
			.post(`/orders/admin/draftOrderComplete/${completeExistOrder[0]}`, {
				paymentPending: true
			})
			.then((res) => {
				navigate(`/ordersNew/${res?.data?.orderName}`)
				setLoading(false);
			})
			.catch((err) => {
				alert("Error in Completing Order.. Please try again");
				submitBtn.innerHTML = 'Complete Order';
				submitBtn.disabled = false;
				setLoading(false);
			})
	}

	const handleDeleteOrder = (Id) => {
		setLoading(true);
		document.getElementById('deleteOrder').innerHTML = 'Delete Order....';

		axiosInstance
			.post(`/orders/admin/draftOrderDelete/${Id}`)
			.then((res) => {
				document.getElementById('deleteOrder').innerHTML = 'Order Deleted';;
				navigate('/draftOrder');
				setLoading(false);
			})
	}

	const handleShowPopup = () => {
		setConfirmShowPopup(true)
	}


	const hideConfirmPopup = (event) => {
		if (event.target.innerText === 'Yes') {
			handleCompleteOrder();
		}
		setConfirmShowPopup(false)
	}

	const handleShowDeleteOrderPopup = () => {
		setDeleteConfirmPopup(true);
	}


	const hideDeleteOrderConfirmPopup = (event,orderId) => {
		if (event.target.innerText === 'Yes') {
			handleDeleteOrder(orderId);
		}
		setDeleteConfirmPopup(false);
	}

	const handleCopyDraftOrder = (event) => {
		event.target.disabled = true;
		setLoading(true);
		axiosInstance
			.post(`/orders/admin/copyDraftOrder/${id}`)
			.then((res) => {
				setLoading(false);
				navigate('/draftOrder');
			})
			.catch((err) => {
				setLoading(false);
				setError("Error in Copying Draft Order.. Please try again")
			})
	}

	useEffect(() => {
		if (id) {
			setLoading(true);
			if (updateToggle) {
				axiosInstance
					.get(`/orders/admin/draftOrders/${id}`)
					.then((response) => {
						let ti = 0;
						for (
							let i = 0;
							i < response?.data?.data?.lineItems?.length;
							i++
						) {
							ti += response?.data?.data?.lineItems[i]?.quantity;
						}
						let drafOrderName = response?.data?.data?.draftOrderName ?
							response?.data?.data?.draftOrderName :
							response?.data?.data?.draftOrder?.name
						dispatch(setBreadcrumb({ ...breadCrumb, draftOrderName: drafOrderName }));

						const taxArray = [];
						for (let ele of response?.data?.data?.lineItems) {
							if (ele?.totalTax) {
								if (taxArray.length === 0) {
									taxArray.push({
										hsnCode: ele?.hsnCode,
										tax: ele?.totalTax,
										groupName: ele?.groupName,
										gst: ele?.gst
									})
								} else {
									const index = taxArray.findIndex(variantIndex => variantIndex.hsnCode === ele.hsnCode);
									if (index >= 0) {
										taxArray[index].tax += ele?.totalTax;
									} else {
										taxArray.push({
											hsnCode: ele?.hsnCode,
											tax: ele?.totalTax,
											groupName: ele?.groupName,
											gst: ele?.gst
										})
									}
									
								}
							}
						}

						if (response?.data?.data?.invoiceType) {
							if (response?.data?.data?.invoiceType === 'bill') {
								document.getElementById('bill').checked = true;
								document.getElementById('billBox').style.marginRight = '10px';
								document.getElementById('challanBox').style.display = 'none';
							} else {
								document.getElementById('challan').checked = true;
								document.getElementById('challanBox').style.marginRight = '10px';
								document.getElementById('billBox').style.display = 'none';
							}
						} else {
							document.getElementById('invoiceTypes').style.display = 'none';
						}

						setTaxRateArray(taxArray);
						setTotalItems(ti);
						setDraftOrder(response?.data?.data);
						setDeliveryRemark(response?.data?.data?.deliveryRemark);
						setPaymentRemark(response?.data?.data?.paymentRemark);
						setCompleteExistOrder([response?.data?.data?._id]);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
						setError('Error in Fetching Draft Order.. Please try again');
					});
			}
		}
	}, [id, updateToggle])

	const handleInclGstTotal = (total,gst) => {
		const result = total*(1+(gst/100));
		const countDecimal = countPlaces(result)
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? parseFloat(result).toFixed(2) : parseFloat(Math.trunc(result*100)/100).toFixed(2)
	}

	const handlePercentage = (discount,lineItems) => {
		let result = 0;
		for (let product of lineItems) {
			if (product.appliedDiscount) {
				result = result + product.appliedDiscount.value * product.quantity
			} else if (product.listingPrice) {
				result = result + product.listingPrice * product.quantity
			}
		}
		const totalAmount = (discount/100 * result)
		const countDecimal = countPlaces(totalAmount);
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? totalAmount : Math.trunc(totalAmount*100)/100
	}

	const rateInclGST = (amount,gst) => {
		const result = amount * (1+(gst/100));
		const countDecimal = countPlaces(result)
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result*100)/100
	}

	return (
		<Fragment>
			{loading ? (
				<div className={Classes.Spinner}>
					<Spinner animation="border" variant="light" />
				</div>
			): null}

			<div className={Classes.container} id="color">
				<div id='hideHeader' style={{ display:'flex',gap:'8px',justifyContent:'flex-end',marginTop:-27 }}>
					<Permission service={services?.draftOrder} permission="create">
						<div>
							<button
								type='btn'
								className={[Classes.Btn, Classes.sendPIButton].join(' ')}
								style={{ backgroundColor: 'black', color: '#fff' }}
								id='deleteOrder'
								onClick={(e) => handleCopyDraftOrder(e)}
							>
								Copy Order
							</button>
						</div>
					</Permission>
					<Permission service={services?.draftOrder} permission="delete">
						<div>
							<button
								type='btn'
								className={[Classes.Btn, Classes.sendPIButton].join(' ')}
								id='deleteOrder'
								onClick={(e) => handleShowDeleteOrderPopup(e)}
							>
								Delete Order
							</button>
						</div>
					</Permission>
				</div>

				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
						<Alert.Heading>{error}</Alert.Heading>
						<p>Try reloading!</p>
					</Alert>
				) : null}

				{draftOrder?.length !== 0 && (
					<div className={Classes.draftOrderName} id='orderNamePrint'>
						<p style={{textAlign:'center'}}>Order Summary</p>
						<div style={{ display:'flex' }}>
							<h6 style={{ marginRight:0 }}>Order Name:-</h6>
							<p style={{ marginRight:-10 }}>{draftOrder?.draftOrder?.name ? draftOrder?.draftOrder?.name : draftOrder?.draftOrderName }</p>
						</div>
					</div>
				)}

				<div className={Classes.InvoiceType} id='invoiceTypes'>
					<div style={{display:'flex'}} id='billBox'>
						<input 
							type="radio" 
							name="choose" 
							id="bill" 
							value="bill" 
						/>
						<p style={{marginLeft:5}}>Send on Bill</p>
					</div>

					<div className={Classes.ChallanBox} id='challanBox'>
						<input 
							type="radio" 
							name="choose" 
							id="challan" 
							value="challan" 
						/>
						<p style={{marginLeft:5}}>Send on Challan</p>
					</div>
				</div>

				<h6 className={Classes.draftOrderDetail}>
					{createdAt(draftOrder?.createdAt)}
				</h6>

				<div className={Classes.mainContainer} style={{ marginTop:10 }} id='container'>
					<div className={Classes.containBoxes} id='productContainer'>
						<div className={[Classes.Box,'box'].join(' ')}>
							{draftOrder?.length !== 0 ? (
								<div style={{ fontSize:14,padding:0 }}>
									<div className={Classes.Thead}>
										<div
											style={{ padding:'10px 20px',fontWeight:600 }}
											className={Classes.ProductHeading}
											id='productHeading'
										>
											<p>Product</p>
										</div>

										<div
											className={[Classes.hideResponsive,'discount'].join(' ')}
											style={{ width:'15%',padding:'10px 0px' }}
										>
											<p style={{ fontWeight:600 }}>Discount</p>
											<p style={{ fontSize:13 }} id='hideInclGst'>(Incl. GST)</p>
										</div>

										<div
											id='quantity'
											className={ Classes.hideResponsive } 
											style={{ width:'10%',padding:'10px 0px' }}
										>
											<p style={{ fontWeight:600 }}>Quantity</p>
										</div>

										<div
											className={ Classes.hideResponsive }
											id='subtotalHeader'
										>
											<p style={{ fontWeight:600,paddingLeft:5 }}>
												Subtotal
											</p>
											<p>(Excl. GST)</p>
										</div>

										<div
											id='totalHeader'
											className={ Classes.hideResponsive }  
										>
											<p style={{ fontWeight:600,paddingLeft:5 }}>
												Total
											</p>
											<p>(Incl. GST)</p>
										</div>
									</div>

									<div id='productContainer'>
										{draftOrder?.lineItems?.map((item, index) => (
											<div
												className={[Classes.containProduct,'variantProduct'].join(' ')}
												id={item?._id}
												key={index}
											>
                                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px', color:'#6d7175' }}>#{index+1}. </div>
												<div
													className={[Classes.productDetail,'draftOrderProducts'].join(' ')} 
													id={index}
												>
													<div>
														<img
															src={
																item?.image ?
																item?.image
															: process.env.REACT_APP_IMAGE_COMMING_SOON}
															alt="icon"
															className={Classes.productImage}
														/>
													</div>

													<div className={Classes.VariantDetail}>
														<div>
															<p style={{ color:'#0d6efd' }}>
																{item?.groupName}
															</p>
														</div>

														<div style={{ color:'#6d7175' }}>
															<p>
																{item?.variable1Value}{' '} 
																{item?.variable2Value && `/ ${item?.variable2Value}`}{' '}
																{item?.variable3Value && `/ ${item?.variable3Value}`}
															</p>
															
															<p>{item?.sku}</p>
														</div>

														<div 
															style={{ display:'flex' }}
															className={[Classes.rateFontSize,'rateExcl'].join(' ')} 
														>
															<p className={Classes.newRateExclGst}>
																Rate Excl. GST
															</p>

															<div className={Classes.DiscountedUnitPrice}>
																<p>&#8377;</p>
																<p>
																	{item?.appliedDiscount ? 
																	item?.appliedDiscount?.value : 
																	item?.listingPrice}
																</p>
															</div>

															<div>
																<p className={Classes.newCompareAtPrice}>
																	&#8377;{item?.mrp}
																</p>
															</div>
														</div>

														<div 
															style={{display: 'flex'}}
															className={[Classes.rateFontSize,'rateExcl'].join(' ')} 
														>
															<p className={Classes.newRateExclGst}>
																Rate Incl. GST
															</p>
															
															<div className={Classes.DiscountedUnitPrice}>
																<p>&#8377;</p>
																<p>
																	{item?.appliedDiscount ? 
																	rateInclGST(item?.appliedDiscount?.value,item?.gst) : 
																	rateInclGST(item?.listingPrice,item?.gst)}
																</p>	
															</div>

															<div>
																<p className={Classes.newCompareAtPrice}>
																	&#8377;{item?.mrp}
																</p>
															</div>
														</div>

														{item?.locationCode && <CapsuleChip capsulePart1="Prices for Location" capsulePart2={`${item?.locationCode}`} chipColor="#243750" />}

														{/* In screen is smaller than 650px then show this box */}
														<div className={Classes.ShowResponsive}>
															<div
																className='percent'
																style={{ fontSize:14,display: 'flex' }}
															>
																<div>
																	<p className={Classes.Text}>
																		Discount Incl. GST:
																	</p>
																</div>

																<div style={{ display:'flex' }}>
																	<p>
																		{handleDiscount(
																			item?.mrp,
																			item?.appliedDiscount ? 
																			item?.appliedDiscount?.value :
																			item?.listingPrice,
																			item?.gst
																		)}
																	</p>
																	<p>%</p>
																</div>
																
																{item?.appliedDiscount && (
																	<div className={[Classes.hideDiscountTitle,'hideDiscountTitle'].join(' ')}>
																		{item?.appliedDiscount?.title}
																	</div>
																)}
															</div>

															<div
																className='qty'
																style={{ display:'flex' }}
															>
																<p className={Classes.Text}>
																	Quantity:
																</p>
																<p>{item?.quantity}</p>
															</div>

															<div 
																className='total' 
																id='discTotalPrint'
																style={{ display:'flex' }}
															>
																<p className={Classes.Text}>	
																	Subtotal:
																</p>
																&#8377;{parseFloat(item?.subTotal).toFixed(2)}
															</div>


															<div
																className='total'
																id='discTotalPrint'
																style={{ display:'flex' }}
															>
																<p className={Classes.Text}>
																	Total:
																</p>
																&#8377;
																{handleInclGstTotal(item?.subTotal, item?.gst)}
															</div>
														</div>
													</div>
												</div>

												<div className={[Classes.percent,Classes.PercentBox,'percent'].join(' ')}>
													<div style={{ display:'flex',justifyContent:'center' }}>
                                                        <p>
                                                            {handleDiscount(
                                                                item?.mrp,
                                                                item?.appliedDiscount ? 
																item?.appliedDiscount?.value :
																item?.listingPrice,
                                                                item?.gst
                                                            )}
                                                        </p>
														<p>%</p>
													</div>
													
													{item?.appliedDiscount && (
														<div className={[Classes.hideDiscountNewTitle,'hideDiscountTitle'].join(' ')}>
															{item?.appliedDiscount?.title}
														</div>
													)}

													{item.appliedDiscount && (
														<div 
															className='exclGSTPrint' 
															style={{ fontSize:12,display:'none' }}
														>
															<p>(Incl. GST)</p>
														</div>
													)}
												</div>

												<div className={[Classes.percent,Classes.Quantity,'qty'].join(' ')}>
													<p>{item?.quantity}</p>
												</div>

												<div
													className={[Classes.percent,'total'].join(' ')}
													style={{ padding:'10px 0px',width:80 }}
													id='discTotalPrint'
												>
													&#8377;
													{parseFloat(item?.subTotal).toFixed(2)}
												</div>

												<div 
													className={[Classes.percent,'total'].join(' ')} 
													style={{ padding: '10px 0px',width:80 }} 
													id='discTotalPrint'
												>
													<p>
														&#8377;
														{handleInclGstTotal(item?.subTotal,item?.gst)}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>

						<div
							className={[Classes.Box,'box'].join(' ')}
							id='paymentBox'
						>
							<div className={Classes.containerBox}>
								<div className="d-flex justify-content-between mb-3">
									<h6 style={{ margin:'auto 0px' }}>
										Payment
									</h6>
								</div>

								<div className={Classes.fontSize}>
									<div className={Classes.PaymentBoxSubtotal}>
										<div style={{ width:'25%' }}>
											<p>Subtotal</p>
										</div>

										<div style={{ width:'50%',display:'flex' }}>
											<p style={{ marginRight: 3 }}>
												{totalItems}
											</p>
											<p>Items</p>
										</div>

										{draftOrder?.length !== 0 && (
											<p className={Classes.widthTextAlign}>
												&#8377;{parseFloat(draftOrder?.subTotalPrice).toFixed(2)}
											</p>
										)}
									</div>

									<div>
										{draftOrder?.length !== 0 && (
											<div style={{ marginBottom:'1rem' }}>
												{draftOrder?.appliedDiscount && (
													<div className={Classes.flexWidth}>
														<p style={{ width:'25%' }}>
															Discount
														</p>

														<div style={{ width:'50%',display:'flex' }}>
															<p style={{ marginRight:3 }}>
																{draftOrder?.appliedDiscount?.title !== '' ? 
																draftOrder?.appliedDiscount?.title :
																"Custom Discount"}
															</p>
														</div>
														
														<p className={Classes.widthTextAlign}>
															-&#8377;
															{draftOrder?.appliedDiscount?.valueType === 'PERCENTAGE' ? 
															handlePercentage(draftOrder?.appliedDiscount?.value,draftOrder?.lineItems) :
															draftOrder?.appliedDiscount?.value}
														</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}    
										
										<div style={{marginBottom:'1rem'}}>
											{draftOrder?.length !== 0 && (
												<div>
													{draftOrder?.shippingLine && (
														<div className={Classes.flexWidth}>
															<p style={{ width:'25%' }}>
																Shipping
															</p>

															<p style={{ width:'50%' }}>
																{draftOrder?.shippingLine?.title}
															</p>

															<div className={Classes.shippingAmount}>
																<p className={Classes.widthColor}>
																	+&#8377;{parseFloat(draftOrder?.shippingLine?.price).toFixed(2)}
																</p>
																
																{draftOrder?.shippingLine?.taxAmount && draftOrder?.shippingLine?.taxAmount !== 0 && (
																	<p className={Classes.widthColor} style={{marginLeft:5}}>
																		(GST: 18%)
																	</p>
																)}	
															</div>
														</div>
													)}
												</div>
											)}
										</div>

										<div style={{ marginBottom:'1rem',display:'flex' }}>
											<p style={{ width:'25%' }} id='tax'>
												Tax
											</p>

											{draftOrder?.taxLines ? (
												<p
													id='taxRate'
													className={Classes.showTaxRates}
													onClick={handleTaxRates}
												>
													Show tax rates
												</p>
											) : (
												<p style={{ width:'50%' }}></p>
											)}

											{draftOrder?.length !== 0 && (
												<p
													id='taxRateAmount'
													style={{ color: '#000' }}
													className={Classes.widthTextAlign}
												>
													&#8377;{parseFloat(draftOrder?.totalTax).toFixed(2)}
												</p>
											)}
										</div>
									</div>

									<div
										className="d-flex justify-content-between"
										style={{ fontSize:14,fontWeight:600 }}
									>
										<p>Total</p>
										<p>
											&#8377;
											{draftOrder?.length !== 0 ? 
											Math.round(draftOrder?.totalPrice) :
											0.00}
										</p>
									</div>
								</div>
							</div>

							<div
								className={Classes.containerFooter}
								id='removeCreateOrderPrint'
							>
								{draftOrder?.length !== 0 && (
									<div>
										<div className={Classes.flexBetween}>
											{
												draftOrder?.user && draftOrder?.shippingAddress &&
												(
													<Permission service={services?.draftOrder} permission="create">
														<div>
															<button
																className={[Classes.Btn, Classes.completeOrderButton].join(' ')}
																id='completeOrder'
																onClick={(e) => handleShowPopup(e)}
															>
																Complete Order
															</button>
														</div>
													</Permission>
												)
											}
											<div className={Classes.flexBetween}>
												<div style={{ marginRight:10 }}>
													<button
														className={[Classes.Button,Classes.deleteOrderButton].join(' ')}
														style={{backgroundColor:'#6c943eeb'}}
														id='submitBtn'
														onClick={(e) => handleSendPI(e)}
													>
														Send PI
													</button>
												</div>
												<Permission service={services?.draftOrder} permission="update">
													<div>
														<button
															type='btn'
															className={[Classes.Button, Classes.editOrderButton].join(' ')}
															onClick={() => navigate(`/createOrder/${completeExistOrder}`)}
														>
															Edit Order
														</button>
													</div>
												</Permission>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						{draftOrder?.length !== 0 && (
							<div id='TaxRatesPrint' className={showTaxRate ? Classes.DialogUI : undefined}>
								<TaxRatesPopup
									showTaxRate={showTaxRate}
									setTaxRates={setTaxRates}
									handleTaxRates={handleTaxRates}
									hideTaxPopup={hideTaxPopup}
									draftOrder={draftOrder}
									taxRates={taxRates}
								/>
							</div>
						)}

						{draftOrder?.length !== 0 && (
							<div
								id='TaxRatesPrint'
								className={Classes.TaxRatesPrint}
							>
								<TaxRatesPrint
									draftOrder={draftOrder}
									taxRates={taxRates}
								/>
							</div>
						)}
					</div>

					<div
						className={Classes.asideContainer}
						id='asideContainer'
					>
						<Aside
							draftOrder={draftOrder}
							deliveryRemark={deliveryRemark}
							paymentRemark={paymentRemark}
						/>
					</div>
				</div>
				<div className={showConfirmPopup ? Classes.DialogUI : undefined}> 
					<ConfirmPopup
						showConfirmPopup={showConfirmPopup}
						hideConfirmPopup={hideConfirmPopup}
					/>
				</div>

				<div className={showDeleteConfirmPopup ? Classes.DialogUI : undefined}>
					<DeleteOrderConfirmPopup
						showDeleteConfirmPopup={showDeleteConfirmPopup}
						hideDeleteOrderConfirmPopup={hideDeleteOrderConfirmPopup}
						draftOrderId={draftOrder?.length !== 0 && draftOrder?._id}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default DraftOrderDetailPage;
