import { useEffect, useState, Fragment, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider,  DatePicker } from '@mui/x-date-pickers-pro';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import Classes from '../orders/css/order.module.css';
import NewPagination from '../../../components/newPagination';
import { Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import { Paper, InputAdornment, TextField, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    DataGrid,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { setPurchaseOrders } from '../../../store/purchaseOrdersSlice';
import { useSelector, useDispatch } from 'react-redux';

function DateRange ({startDate,setStartDate,endDate,setEndDate}) {
	return (
		<div style={{display:'flex'}}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker 
					label="Start Date" 
					sx={{ 
						"& .MuiOutlinedInput-input": {padding: "9.5px 6px",fontSize:'14px'},
						"& .MuiSvgIcon-root": { fontSize:"18px" },
						"& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
						"& .Mui-error": { color:'#00000099 !important' }
						// "& .MuiInputLabel-root": { top: endDate? "0%" :"-18%" }
					}}
					value={startDate || " "}
					onChange={(newValue) => {
						setStartDate(newValue);
					}} 
				/>
				<DatePicker 
					label="End Date"
					sx={{ 
						"& .MuiOutlinedInput-input": {padding: "9.5px 6px",fontSize:'14px'},
						"& .MuiSvgIcon-root": { fontSize:"18px" },
						"& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
						"& .Mui-error": { color:'#00000099 !important' }
						// "& .MuiInputLabel-root": { top: endDate? "0%" :"-18%" }
					}}
					value={endDate || " "}
					onChange={(newValue) => {
						setEndDate(newValue);
					}} 
				/>
			</LocalizationProvider>
		</div>
	)
}

function SearchInput({ searchFor, handleSearch }) {
	return (
		<div style={{ width: '40%' }}>
			<TextField
				id="search"
				type="search"
				label="Search"
				value={searchFor}
				onChange={(e) => handleSearch(e)}
				autoComplete='off'
				sx={{ 
					width: '100%',
					"& label": { top: searchFor ? "0%" : "-18%" },
					"& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
				 }}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</div>
	)
}

function CustomToolbar({ text, handleSearch, startDate, setStartDate, endDate, setEndDate }) {
	return (
		<GridToolbarContainer style={{paddingTop:8,display:'flex',justifyContent:'space-between'}}>
			<div style={{display:'flex'}}>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
			</div>
			<div
				className={Classes.SearchBox}
				style={{ border:'none',display:'flex',justifyContent:'end',width:'60%' }}
			>
				<DateRange startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
				<SearchInput searchFor={text} handleSearch={handleSearch} />
			</div>
		</GridToolbarContainer>
	);
}

const PurchaseOrders = (props) => {
	const [text, setText] = useState('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [totalPage, setTotalPage] = useState({});
	const [filter, setFilter] = useState(null);
	const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });
	const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
	const isFirstRender = useRef(true);

	const purchaseOrders = useSelector((state) => state.purchaseOrders);
	const pinTab = useSelector((state) => state.pinTab);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const columnVisibilityModel = {
		locationName: false
	}
	const columns = [
		{ 
			field: 'purchaseOrderName', 
			headerName: 'ORDER', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdAt', 
			filterable: false, 
			headerName: 'DATE', 
			flex: 1.5, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'vendorName', 
			headerName: 'VENDOR', 
			flex: 1.5, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'locationCode', 
			headerName: 'Location Code', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'locationName', 
			headerName: 'Location Name', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{
			field: 'paymentStatus',
			headerName: 'PAY STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			flex: 1.5,
			renderCell: (params) => (
				params.value === "Unpaid" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			)
		},
		{
			field: 'status',
			headerName: 'STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			flex: 1.5,
			renderCell: (params) => (
				params.value === "Completed" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			)
		},
		{ 
			field: 'totalAmount', 
			filterable: false, 
			valueFormatter: (params) => `₹${params.value}`, 
			headerName: 'TOTAL', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdBy', 
			headerName: 'CREATED BY', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
	];

	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}


	const fetchOrders = async (type) => {
		setLoading(true);

		const params = {
			page : type? type : 1,
			startTime: startDate ? `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`: null,
            endTime: endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : null,
			searchFor:text,
			filter: `${filter?.value}` ? filter : undefined,
			sort : JSON.stringify(sort),
		};

		await axiosInstance
			.get(`/purchaseOrders/purchaseOrders`, {
				params,
			})
			.then((response) => {
				setTotalPage(response.data?.data);
				dispatch(setPurchaseOrders(response.data.data));
			})
			.catch((error) => {
				console.log(error)
				setError(error.response.data.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Purchase Orders')) {
			if (isFirstRender.current) {
                isFirstRender.current = false;
                console.log('first render')
				const urlParams = new URLSearchParams(window.location.search);
				const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
				fetchOrders(page ? page : 1);
			}

			const delay = 550;
            const debounce = setTimeout(() => {
                fetchOrders();
            }, delay);
            return () => clearTimeout(debounce);
		}
	}, [text, sort, filter, startDate, endDate]);

	const handleSearch = (e) => {
		setText(e.target.value);
	}


	return (
		<Fragment>
			<div className="mb-3" style={{ width:"100%",margin:'0px auto',textAlign:'end' }}>
				<p>
					Purchase Orders: {(purchaseOrders?.page * 10) - 10}-{Math.min((purchaseOrders?.page * 10), purchaseOrders?.total)} of {purchaseOrders?.total}
				</p>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			<div className="d-none d-sm-block">
				<Box 
					className="shadow"
					component={Paper}
					sx={{
						'& .super-app-theme--header': {
							backgroundColor: '#243750',
							color: '#ffffff',
							cursor: "default",
						},
						width: '99.5%'
					}}>
					<DataGrid
						slots={{ toolbar: CustomToolbar }}
						slotProps={{
							toolbar: {
								text,
								handleSearch,
								startDate, setStartDate, endDate, setEndDate
							}
						}}
						rows={purchaseOrders?.purchaseOrders?.map((order) => ({
							purchaseOrderName: order?.purchaseOrderName,
							createdAt: createdAt(new Date(order?.createdAt)),
							vendorName: order?.vendorName ? order?.vendorName : 'N/A',
							locationName: order?.locationName ? order?.locationName : 'N/A',
							status: order?.status,
							createdBy: order?.createdBy,
							totalAmount: order?.totalAmount,
							paymentStatus: order?.paymentStatus,
							id: order?.purchaseOrderName,
							locationCode: order?.locationCode,
						}))}
						columns={columns}
						filterMode="server"
						sortingMode="server"
						sortingOrder={['asc', 'desc']}
						paginationMode="server"
						disableRowSelectionOnClick
						autoHeight
						autoPageSize
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "none !important",
							},
							cursor: "pointer",
							".MuiDataGrid-footerContainer": {
								display: "none !important",
							},
							"& .MuiDataGrid-toolbarContainer" : {paddingTop:'10px'}
						}}
						onRowClick={(params) => {
							navigate(`${params?.id}`);
						}}
						initialState={{
							sorting : {
								sortModel: [{ field: 'createdAt', sort: 'desc' }],
							},
							columns: {
								columnVisibilityModel
							}
							}}
						onSortModelChange={(sort) => setSort(sort[0])}
						onFilterModelChange={(filter) => (setFilter(filter.items[0]))}
					/>
				</Box>
			</div>

			<div className="d-block d-sm-none">
				<div className={Classes.ordersHeader}>
					<p className={Classes.OrderName}>Order</p>
					<p style={{ marginRight:5 }}>Amount</p>
				</div>

				{purchaseOrders?.purchaseOrders?.map((order) => (
					<div
						key={order?.orderName}
						onClick={() => { navigate(`${order?.purchaseOrderName}`); }}
						style={{display:'flex',cursor:'pointer'}}
						className={Classes.MobileResponsiveBox}
					>
						<div className={Classes.orderName}>
							<h6 style={{ fontSize:14,margin:'auto 0px' }}>
								{order?.purchaseOrderName}
							</h6>

							<div>
								<div className={Classes.ResponsiveCreatedAt}>
									{createdAt(order?.createdAt)}
								</div>

								<div style={{display:'flex'}}>
									<p className='text-muted d-block d-md-none'>Vendor:</p>
									<p style={{paddingLeft:5}}>{order?.vendorName ? order?.vendorName : 'N/A'}</p>
								</div>

								<div style={{display:'flex'}}>
									<p className='text-muted d-block d-md-none'>Location:</p>
									<p style={{paddingLeft:5}}>{order?.locationName ? order?.locationName : 'N/A'}</p>
								</div>

								<div style={{display:'flex'}}>
									<p className='text-muted d-block d-md-none'>Payment Status:</p>
									<p
										className="px-2 mx-2"
										style={{
											backgroundColor:order?.paymentStatus === 'paid' ? '#e4e5e7': '#ffd79d',
											borderRadius: '1rem',
											width: 'fit-content',
										}}
									>
										{order?.paymentStatus}
									</p>
								</div>

								<div style={{display:'flex',marginTop:3}}>
									<p className='text-muted d-block d-md-none'>Order Status:</p>
									<p
										className="px-2 mx-2"
										style={{
											backgroundColor:order?.orderStatus === 'delivered' ?'#e4e5e7' :'#ffea88',
											borderRadius: '1rem',
											width: 'fit-content',
										}}
									>
										{order?.status}
									</p>
								</div>

								<div style={{display:'flex'}}>
									<p className='text-muted d-block d-md-none'>Created By:</p>
									<p style={{paddingLeft:5}}>{order?.createdBy ? order?.createdBy : 'N/A'}</p>
								</div>
							</div>
						</div>
                        <p style={{ marginRight: '6px' }}>&#8377;{order?.totalAmount}</p>
					</div>
				))}
			</div>


			{purchaseOrders?.purchaseOrders?.length > 0 ? (
				<div>
					<NewPagination totalPage={purchaseOrders} fetchData={fetchOrders} />
				</div>
			) : null}

			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default PurchaseOrders;
