import { Fragment, useState } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import '../createOrders/css/styles.css';
import { Autocomplete, TextField } from '@mui/material';

const MobileSorting = (props)=> {
    const [loading,setLoading] = useState(false);

    const { 
        showModal,
        hideDialog,
        setOpen,
        setSort,
        setCustomers,
        setSelectedColumn,
        selectedColumn,
        setSelectedOrder,
        selectedOrder
    } = props;

    // const handleDeleteUser = () => {
    //     setLoading(true);
    //     axiosInstance
	// 		.delete(`/admin/${userId}`)
	// 		.then((res) => {
    //             setLoading(false);
    //             hideDialog();
    //             fetchCustomers();
	// 		})
	// 		.catch((err) => {
	// 			console.log(err.response.data.message);
	// 		})
    // }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSelectedColumn([event.target.Column.value]);
        setSelectedOrder([event.target.Order.value]);

        const columns = {
            "Days Since(LV)" : 'lastVisitDate',
            "Days Since(LO)" : 'lastOrderDate',
            "Avg Monthly Sale": "avgMonthlyOrder",
            "Monthly Sale": "monthOrders"
        }

        const column = columns[event.target.Column.value];
        const direction = event.target.Order.value === 'ASC' ? 'asc' : 'desc';
        setSort({
            keyToSort: column,
            direction: direction
        });
        setOpen(false);

        await axiosInstance
            .get(`/fieldSalesVisit/userSpecificCustomers?sort=${direction}&fieldName=${column}`)
            .then((res) => {
                setLoading(false);
                setCustomers(res.data.data);
            });
    }

    const handleResetSorting =() => {
        console.log('hello')
        selectedColumn.splice(0,selectedColumn.length);
        selectedOrder.splice(0,selectedOrder.length);
        setSort({keyToSort:'',direction:'asc'});
    }

    return (
        <Fragment>
            {showModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'15%',bottom:'30%',left: "35%",right:"35%"}}
                >   
                    <div className={Classes.BoxContent}>
                        <p>Sort By</p>
                        {selectedColumn.length !== 0 && selectedOrder.length !== 0 && (
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleResetSorting}
                            >
                                Reset
                            </button>
                        )}
                    </div>

                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{
                            overflowY:'auto',
                            height:'72%'
                        }}
                    >
                        <div style={{padding: '15px 20px'}}>
                            <div>
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="Column"
                                    name="Column"
                                    options={['Days Since(LV)','Days Since(LO)','Avg Monthly Sale','Monthly Sale']}
                                    ListboxProps={{sx: { fontSize: 14 }}}
                                    sx={{
                                        width: '100%',
                                        "& .MuiAutocomplete-input" : {fontSize:14},
                                        "& .MuiOutlinedInput-root":{padding:'6px'},
                                    }}
                                    key={selectedColumn.length !== 0 ? selectedColumn[0] : ''}
                                    defaultValue={selectedColumn.length !== 0 ? selectedColumn[0] : ''}
                                    renderInput={(params) => <TextField {...params} label="Column" required />}
                                />
                            </div>
                        
                            <div style={{marginTop:15}}>
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="Order"
                                    name="Order"
                                    options={[{label:'ASC'},{label:'DESC'}]}
                                    ListboxProps={{sx: { fontSize: 14 }}}
                                    sx={{
                                        width: '100%',
                                        "& .MuiAutocomplete-input" : {fontSize:14},
                                        "& .MuiOutlinedInput-root":{padding:'6px'},
                                    }}
                                    key={selectedOrder.length !== 0 ? selectedOrder[0] : ''}
                                    defaultValue={selectedOrder.length !== 0 ? selectedOrder[0] : ''}
                                    renderInput={(params) => <TextField {...params} label="Order" required />}
                                />
                            </div>
                        </div>

                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'29%',
                                position:'fixed',
                                boxShadow:'none',
                                border: 'none'
                            }}
                        >
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideDialog}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                APPLY
                            </button>
                        </div>
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default MobileSorting;