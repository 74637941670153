import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Classes from '../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import {FaUserCircle} from 'react-icons/fa';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const navigate = useNavigate();
    const { endpoint, rowId, columns, dataPosition } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%',marginTop:10 }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { text:searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data;
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box} onClick={() => navigate(`/users/${row?._id}`)}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            <p style={{marginRight:5,color:'#6c757d'}}>
                                {column?.headerName === 'USERNAME' ? 
                                <FaUserCircle size='24px' color='grey'/>: 
                                <>{column?.headerName} :</> }
                            </p>
                            <p>
                                {column?.field === "createdAt" ? 
                                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                                column?.field === 'active' ? row[column?.field] === true ? 
                                <BsCheckCircleFill color="green" /> : <BsXCircleFill color="red" /> :
                                column?.field === 'services' ?  ['super-admin', 'admin'].includes(row?.role) ? (
                                    <div>
                                        <Chip label={"All"} style={{ margin: '3px' }} />
                                    </div>
                                ) : (
                                    Array.isArray(row?.services) ? (
                                        <div>
                                            {row?.services?.slice(0,2)?.map((service, index) => (
                                                <Chip key={index} label={service?.service} style={{ margin: '0px 3px' }} />
                                            ))}
                                            {row?.services?.length > 2 ? (
                                                <Tooltip title={
                                                    <div>
                                                        {row?.services?.slice(2)?.map((service, index) => (
                                                            <p>{service?.service}, </p>
                                                        ))}
                                                    </div>
                                                } arrow>
                                                <Chip size="small" label={`+ ${row?.services?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <Chip label={row?.services} style={{ margin: '0px 3px' }} />
                                    )
                                ): row[column?.field]}
                            </p>
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;