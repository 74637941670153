import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axiosInstance from '../utility/axios-instance';

const CreatedByModal = ({ onSelect, users, setUsers }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleSelect = (event, value) => {
        setSelectedUsers(value);
        onSelect(value);
    };
    
    useEffect(() => {
        axiosInstance
            .get('/admin/allPiAdmin')
            .then((res) => {
                setUsers(res?.data?.data);
            })
    }, []);


    return (
        <div>
            <Autocomplete
                multiple
                limitTags={1}
                id="multiple-limit-tags"
                size="small"
                disablePortal
                options={users ? users : [{name:"Loading...",id:0}]}
                value={selectedUsers}
                getOptionLabel={(user) => user.name}
                getOptionSelected={(option, user) => option === user}
                onChange={handleSelect}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Created By"
                        variant="outlined"
                    />
                )}
            />
        </div>
    );
};

export default CreatedByModal;