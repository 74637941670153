import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';

const SalesUsers = ({setUser, fetchData }) => {
    const [users,setUsers] = useState([]);

    const handleSelect = (user) => {
        setUser(user);
        fetchData(user)
    };
    
    useEffect(() => {
        axiosInstance
            .get('/admin/salesUsers/all')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data) {
                    arr.push({label:ele?.name,id:ele?._id})
                }
                setUsers(arr);
            })
    }, []);


    return (
        <div>
            <Autocomplete
                id="multiple-limit-tags"
                freeSolo
                size="small"
                disablePortal
                options={users ? users : [{name:"Loading...",id:0}]}
                onChange={(event,newValue) => { handleSelect(newValue ? newValue.id: '')}}
                renderInput={(params) => (
                    <TextField {...params} label="Sales User" variant="outlined"/>
                )}
            />
        </div>
    );
};

export default SalesUsers;