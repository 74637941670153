import React, { Fragment, useEffect, useState } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';
import SnackBar from '../../../../components/SnackBar';
import Spinner from '../../../../components/spinner';
import MultipleSelectChip from "../../../../components/MultiSelect/MultipleSelectChip";



const ContactPopUp = (props) => {
    const [loading, setLoading] = useState(false);
    const [districts, setDistricts] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const {
        contactModal,
        hideContactPop,
        showCustomerDetail,
        setCustomerDetail,
        setReRender,
        reRender,
        locationContactDetailId,
        setLocationContactDetailId,
        locationContactDetails,
        l1MappingsCreateUpdate
    } = props;
    const [selectedL1s, setSelectedL1s] = useState([])
    const [l1s, setL1s] = useState([]);

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();

        const data = {
            "locationName": event?.target?.locationName?.value,
            "status": event?.target?.locationStatus?.value ? event?.target?.locationStatus?.value : undefined,
            "locationType": event?.target?.locationType?.value ? event?.target?.locationType?.value : undefined,
            "district": event?.target?.district?.value ? event?.target?.district?.value : undefined,
            "locationGstNumber": event?.target?.locationGstNumber?.value ? event?.target?.locationGstNumber?.value : undefined,
            "default": event?.target?.default?.value === "true" ? true : false,
        }

        const locationContactDetailData = {
            "firstName": event?.target?.firstName?.value || undefined,
            "lastName": event?.target?.lastName?.value || undefined,
            "phoneNumber": event?.target?.phone?.value || undefined,
            "email": event?.target?.email?.value || undefined,
            "role": event?.target?.role?.value || undefined,
        }

        const initialLocationContactDetailData = {
            "firstName": showCustomerDetail[0]?.firstName || undefined,
            "lastName": showCustomerDetail[0]?.lastName || undefined,
            "phoneNumber": showCustomerDetail[0]?.phoneNumber || undefined,
            "email": showCustomerDetail[0]?.email || undefined,
            "role": showCustomerDetail[0]?.role || undefined,
        }

        if (Object?.values(initialLocationContactDetailData)?.every((value) => {
            return value === undefined
        })) {
            axiosInstance
                .post(`/location/locationContactDetail/${showCustomerDetail[0].locationCode}`, { ...locationContactDetailData })
                .then((res) => {
                    const successMessage = res?.data?.message ? res?.data?.message : 'Location Contact Detail Added Successfully'
                    setLoading(false)
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    hideContactPop();
                    if (setReRender) { setReRender(!reRender) }
                    setLocationContactDetailId('')
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                    setLoading(false)
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
        } else {
            axiosInstance
                .put(`/location/updateLocation/${showCustomerDetail[0]?.locationCode}`, { ...data })
                .then((res) => {
                    setCustomerDetail([res?.data?.data]);
                    axiosInstance
                        .put(`/location/locationContactDetail/${locationContactDetailId}`, { ...locationContactDetailData })
                        .then((res) => {
                            const successMessage = res?.data?.message ? res?.data?.message : 'Location Contact Detail Added Successfully'
                            setLoading(false)
                            setSnackBar((prevSnackBar) => {
                                return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                            });
                            hideContactPop();
                            if (setReRender) { setReRender(!reRender) }
                            setLocationContactDetailId('')
                        }).catch((err) => {
                            console.log(err)
                        })
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                    setLoading(false)
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
                createL1Mapping({
                    l1s: selectedL1s,
                    locationCode: showCustomerDetail[0]?.locationCode,
                });
        }
    }

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^(?!0)\d{10}$/;
            if (event.target.value.match(phoneno)) {
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('addCustomer').disabled = false;
            } else {
                document.getElementById('addCustomer').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('addCustomer').disabled = false;
        }
    }

    const createL1Mapping = (l1MappingData) => {
        axiosInstance
            .post(`/l1Mapping/createUpdateL1Mapping`, l1MappingData )
            .then((res) => {
                const successMessage = res?.data?.message ? res?.data?.message : 'L1 Mapping Created Successfully'
                setSelectedL1s([])
            }).catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                // setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    useEffect(() => {
        axiosInstance
            .get(`/others/districts`)
            .then((res) => {
                let districts = res?.data?.data?.map((item) => {
                    return item.district;
                });
                setDistricts(districts);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Unable to fetch districts'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        
    }, []);

    useEffect(() => {
        (showCustomerDetail?.length > 0  && l1MappingsCreateUpdate) && fetchL1sforLocation(showCustomerDetail[0]?.locationId)
        fetchL1s()
    }, [showCustomerDetail])

    const fetchL1sforLocation = async (locationId) => {
        await axiosInstance
            .get(`/l1Mapping/${locationId}`)
            .then((res) => {
                const l1s =  res?.data?.data
                const l1Values = l1s?.map((l1) => { return l1?.title })
                setSelectedL1s(l1Values)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message || 'Something went wrong'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    } 
    const fetchL1s = async() =>{
        const filter = {}
        await axiosInstance
            .get(`/others/l1s/?filter=${JSON.stringify(filter)}`)
            .then((res)=>{
                const l1s = res?.data?.data;
                setL1s(l1s)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message || 'Something went wrong'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    } 
    const OptionValues = l1s && l1s?.map((l1, index)=>{
        return {value: l1?.title, id: index}
    })

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {contactModal && (
                <div className={[Classes.modal, Classes.contactPopUp].join(' ')} style={{ bottom: '13%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Location</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{ padding: '8px 20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='locationName'
                                        name='locationName'
                                        label='Location Name'
                                        placeholder='Enter Location Name'
                                        autoComplete='off'
                                        key={"showCustomerDetail[0]?.locationName"}
                                        defaultValue={showCustomerDetail[0]?.locationName}
                                        // value={showCustomerDetail[0]?.locationName}
                                        required={true}
                                        sx={{ width: '100%' }}
                                        size="small"
                                        onChange={(event) => {
                                            const value = event?.target?.value
                                            setCustomerDetail((prevCustomerDetail) => {
                                                return [{
                                                    ...prevCustomerDetail[0],
                                                    locationName: value,
                                                }]
                                            })
                                        }}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id={'locationStatus'}
                                        name={'locationStatus'}
                                        options={["Active", "In-Active", "Dis-Continued"]}
                                        key={showCustomerDetail[0]?.status}
                                        defaultValue={showCustomerDetail[0]?.status}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={'Location Status'} />
                                        }
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='locationCode'
                                        name='locationCode'
                                        label='Location Code'
                                        placeholder='Enter Location Code'
                                        autoComplete='off'
                                        key={showCustomerDetail[0]?.locationCode}
                                        defaultValue={showCustomerDetail[0]?.locationCode}
                                        disabled={true}
                                        sx={{ width: '100%' }}
                                        size="small"
                                        onChange={(event) => {
                                            const value = event?.target?.value
                                            setCustomerDetail((prevCustomerDetail) => {
                                                return [{
                                                    ...prevCustomerDetail[0],
                                                    locationCode: value,
                                                }]
                                            })
                                        }}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id={'locationType'}
                                        name={'locationType'}
                                        options={["WH", "Store", "Head Office", "Franchise"]}
                                        key={showCustomerDetail[0]?.locationType}
                                        defaultValue={showCustomerDetail[0]?.locationType}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={'Location Type'} />
                                        }
                                        size="small"
                                    // value={showCustomerDetail[0]?.locationType}
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id={'district'}
                                        name={'district'}
                                        options={districts}
                                        key={showCustomerDetail[0]?.district}
                                        defaultValue={showCustomerDetail[0]?.district}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={'District'} required={true} />
                                        }
                                        size="small"
                                    // value={showCustomerDetail[0]?.district}
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='locationGstNumber'
                                        name='name'
                                        label='Gst Number'
                                        placeholder='Enter Gst Number'
                                        autoComplete='off'
                                        key={showCustomerDetail[0]?.locationGstNumber}
                                        defaultValue={showCustomerDetail[0]?.locationGstNumber}
                                        // value={showCustomerDetail[0]?.locationGstNumber}
                                        required={true}
                                        sx={{ width: '100%' }}
                                        size="small"
                                        onChange={(event) => {
                                            const value = event?.target?.value
                                            setCustomerDetail((prevCustomerDetail) => {
                                                return [{
                                                    ...prevCustomerDetail[0],
                                                    locationGstNumber: value,
                                                }]
                                            })
                                        }}
                                    />
                                </div>

                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        required={true}
                                        type="text"
                                        id='firstName'
                                        name='firstName'
                                        label='First Name'
                                        autoComplete='off'
                                        placeholder='First Name'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        defaultValue={locationContactDetails?.firstName}
                                        key={locationContactDetails?.firstName}
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        type="text"
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        autoComplete='off'
                                        placeholder='Last Name'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        defaultValue={locationContactDetails?.lastName}
                                        key={locationContactDetails?.lastName}
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%' }}
                                        size="small"
                                        onBlur={(e) => handleMinLength(e)}
                                        defaultValue={locationContactDetails?.phoneNumber}
                                        key={locationContactDetails?.phoneNumber}
                                    />
                                    <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        type="email"
                                        id='email'
                                        name='email'
                                        label='Email'
                                        autoComplete='off'
                                        placeholder='Enter Your Email'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        defaultValue={locationContactDetails?.email}
                                        key={locationContactDetails?.email}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id='role'
                                        name='role'          
                                        options={["Warehouse Manager", "Warehouse Executive"]}
                                        defaultValue={locationContactDetails?.role}
                                        key={locationContactDetails?.role}
                                        getOptionLabel={(option) => option || ""}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={'Role'} required={true} />}
                                        size="small"
                                    />
                                </div>

                                <div style={{width:'49%'}}>
                                    <MultipleSelectChip
                                        label={"Select L1s"}
                                        selectedOptions={selectedL1s}
                                        setSelectedOptions={setSelectedL1s}
                                        OptionValues={OptionValues}
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '40%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id='default'
                                        name='default'          
                                        options={["true", "false"]}
                                        defaultValue={showCustomerDetail[0]?.default}
                                        key={showCustomerDetail[0]?.default}
                                        getOptionLabel={(option) => option || ""}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={'Default'} required={true} />}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{ bottom: '13%' }}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button
                                id='addCustomer'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default ContactPopUp;