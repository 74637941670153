import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from '../../../utility/axios-instance';
import Button from '@mui/material/Button';
import FormDialog from './Components/formDialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MultipleSelect from './Components/multipleSelect';
import NewPagination from '../../../components/newPagination';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SyncIcon from '@mui/icons-material/Sync';
import Stack from '@mui/material/Stack';
import { downloadCSV } from '../../../utility/downloadCSV';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Roles = () => {
	const [roles, setRoles] = React.useState({})
	const [open, setOpen] = React.useState(false);
	const [openDeleteDiaglog, setOpenDeleteDialog] = React.useState(false);
	const [openEditDiaglog, setOpenEditDialog] = React.useState(false);
	const [formData, setFormData] = React.useState({})
	const [snackBar, setSnackBar] = React.useState({ display: false, type: "success", message: "default message!" })
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [toBeDeletedRole, setToBeDeletedRole] = React.useState({});
	const [openSyncDialog, setOpenSyncDialog] = React.useState(false)
	const [servicesOptions, setServicesOptions] = React.useState([])
 
	const fetchServices = async () => {
		await axios
		.get(`/admin/fetchServices`)
		.then((res) => {
			const fetchedServices = res?.data?.data
			setServicesOptions([...fetchedServices])
			return res.data
		})
		.catch((error)=>{
			const errorMessage = error?.response?.data?.message
			setSnackBar((prevSnackBar) => {
				return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
			});
		});
	};

	const fetchRoles = async (page) => {
		const limit = 10
		await axios
			.get(`/roles/fetchData?page=${page}&limit=${limit}`)
			.then((res) => {
				const fetchedData = res?.data?.data
				setRoles(fetchedData);
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const exportRoles = async () => {
		await axios
			.get(`/roles/export`)
			.then((res) => {
				const fetchedData = res?.data?.data
				const successMessage = res?.data?.message
				downloadCSV(fetchedData?.fetchedRoles, `Roles Export - ${new Date()}`)
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	const handleImport = (event) => {
        event.preventDefault();
        var formdata = new FormData();
        formdata.append(
            "roleServicesPermissions",
            document.querySelectorAll('input[type="file"]')[0].files[0]
        );

        axios
            .post("/others/roleServicesPermissions", formdata)
            .then((res) => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
            })
            .catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
    };

	const deleteRole = async ({role, service}) =>{
		await axios
			.delete(`/roles/?role=${role}&service=${service}`)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	}

	const editRole = async ({ role, service }) =>{
		const payload = {
			role: role,
			service: service,
			permissions: selectedOptions.join(","),
		}
		await axios
			.put(`/roles`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	}

	const syncAdminsWithRole = async () =>{
		await axios
			.put(`/admin/syncPermissionsFromRoles`)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	}

	const createRole = async ({ role, service }) => {
		const payload = {
			roleName: role,
			service: service,
			permissions: selectedOptions.join(","),
		}
		await axios
			.post(`/roles/createRole`, payload)
			.then((res) => {
				const successMessage = res?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			});
	};

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page');
		fetchRoles(page ? page : 1)
		fetchServices()
	}, [snackBar])

	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClickExport = () => {
		exportRoles()
	};

	const handleClickSync = () => {
		setOpenSyncDialog(true)
	};

	const handleClose = () => {
		setOpen(false);
		setFormData({})
		setSelectedOptions([])
	};

	const handleEditDialog = (event) => {
		event.preventDefault()
		setOpenEditDialog(true)
		const editButton = event?.currentTarget
		const roleToEdit = editButton?.dataset?.role
		const serviceToEdit = editButton?.dataset?.service
		const permissionToEdit = editButton?.dataset?.permissions
		permissionToEdit && setSelectedOptions(permissionToEdit?.split(","))
		setFormData({
			role: roleToEdit,
			service: serviceToEdit,
		})
	};

	const handleEdit = () => {
		editRole(formData)
		setOpenEditDialog(false)
		setFormData({})
		setSelectedOptions([])
	};

	const handleDelete = () => {
		deleteRole(toBeDeletedRole)
		setOpenDeleteDialog(false)
		setToBeDeletedRole({})
	};

	const handleSync = () => {
		syncAdminsWithRole()
		setOpenSyncDialog(false)
	};

	const handleDeleteDialog = (event) => {
		event.preventDefault()
		setOpenDeleteDialog(true)
		const deleteButton = event?.currentTarget
		const roleToDelete = deleteButton?.dataset?.role
		const serviceToDelete = deleteButton?.dataset?.service
		setToBeDeletedRole({ role: roleToDelete, service: serviceToDelete })
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false)
		setToBeDeletedRole({})
	}

	const handleCloseSyncDialog = () => {
		setOpenSyncDialog(false)
	}

	const handleCloseEditDialog = () => {
		setOpenEditDialog(false)
		setFormData({})
		setSelectedOptions([])
	}

	const handleSnackBarClose = () => {
		setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: false }
		});
	};

	const handleSubmit = () => {
		setOpen(false);
		createRole(formData)
		setFormData({})
		setSelectedOptions([])
	};

	const rows = roles?.fetchedRoles

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
            padding:"16px 10px"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            padding:"5px 10px"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


	return (
		<React.Fragment>
			<Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
				<Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
					{snackBar?.message}
				</Alert>
			</Snackbar>
			<Stack
				direction="row"
				alignItems="center"
				spacing={2}
				sx={{ margin:'15px 0px' }}
				style={{justifyContent:'space-between'}}
			> 
				<div>
					<Button variant="contained" onClick={handleClickOpen} style={{marginRight:10}}>
						Create Role
					</Button>
					
					<Button variant="contained" onClick={handleClickSync} startIcon={<SyncIcon />}>
						Sync Role Policy
					</Button>
				</div>

				<div style={{display:'flex'}}>
					<form className="d-flex import" style={{marginRight:10}}>
						<input
							className="form-control me-1 py-1"
							type="file"
							id="formFile"
							name="pricing"
							accept=".csv"
						></input>
						<Button variant="contained" onClick={handleImport} startIcon={<FileUploadIcon />}>
							Import
						</Button>
					</form>

					<Button variant="contained" onClick={handleClickExport} startIcon={<FileDownloadIcon />}>
						Export 
					</Button>
				</div>
			</Stack>
			<FormDialog open={open} handleClose={handleClose} handleSubmit={handleSubmit} dialogTitle="Create Role">
				<Grid container spacing={2}>
					<Grid xs={6}>
						<TextField
							autoFocus
							margin="dense"
							id="role"
							label="Role"
							type="text"
							size='small'
							variant="outlined"
							value={formData?.role || ""}
							onChange={(event) => {
								setFormData((prevFormData) => {
									return { ...prevFormData, role: event?.target?.value }
								});
							}}
						/>
					</Grid>
					<Grid xs={6}>
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<InputLabel id="service-select-label">Service</InputLabel>
							<Select
								labelId="Service"
								id="service-select"
								value={formData?.service || ""}
								label="Service"
								onChange={(event) => {
									setFormData((prevFormData) => {
										return { ...prevFormData, service: event?.target?.value }
									});
								}}
							>
								<MenuItem value="">
									<em>Select Service</em>
								</MenuItem>
								{servicesOptions.length > 0 && servicesOptions.map((serviceOption) => (
									<MenuItem key={serviceOption} value={serviceOption}>
										{serviceOption}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6}>
						<MultipleSelect
							label={"Permissions"}
							selectedOptions={selectedOptions}
							setSelectedOptions={setSelectedOptions}
							size='small'
							options={[
								'create',
								'read',
								'update',
								'delete',
							]} />
					</Grid>
				</Grid>
			</FormDialog>
			<FormDialog open={openDeleteDiaglog} handleClose={handleCloseDeleteDialog} handleSubmit={handleDelete} dialogTitle={`Delete Role Permanently`} dialogContent={"Are you sure you want to delete?"} />
			<FormDialog open={openSyncDialog} handleClose={handleCloseSyncDialog} handleSubmit={handleSync} dialogTitle={`Sync Admins with Permission in Roles`} dialogContent={"Are you sure you want to take this action, this will remove all extra permissions of admins?"} />
			<FormDialog open={openEditDiaglog} handleClose={handleCloseEditDialog} handleSubmit={handleEdit} dialogTitle="Edit Role">
				<Grid container spacing={2}>
					<Grid xs={6}>
						<TextField
							autoFocus
							margin="dense"
							id="role"
							label="Role"
							type="text"
							size='small'
							variant="outlined"
							value={formData?.role || ""}
							onChange={(event) => {
								setFormData((prevFormData) => {
									return { ...prevFormData, role: event?.target?.value }
								});
							}}
						/>
					</Grid>
					<Grid xs={6}>
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<InputLabel id="service-select-label">Service</InputLabel>
							<Select
								labelId="Service"
								id="service-select"
								value={formData?.service || ""}
								label="Service"
								onChange={(event) => {
									setFormData((prevFormData) => {
										return { ...prevFormData, service: event?.target?.value }
									});
								}}
							>
								<MenuItem value="">
									<em>Select Service</em>
								</MenuItem>
								{servicesOptions.length > 0 && servicesOptions.map((serviceOption) => (
									<MenuItem key={serviceOption} value={serviceOption}>
										{serviceOption}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6}>
						<MultipleSelect
							label={"Permissions"}
							selectedOptions={selectedOptions}
							setSelectedOptions={setSelectedOptions}
							size='small'
							options={[
								'create',
								'read',
								'update',
								'delete',
							]} />
					</Grid>
				</Grid>
			</FormDialog>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
					<TableHead>
						<TableRow>
                            <StyledTableCell sx={{ fontWeight: 700 }}>Role</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: 700 }} align="left">Service</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: 700 }} align="left">Permissions</StyledTableCell>
							<StyledTableCell sx={{ fontWeight: 700 }} align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.length && rows.map((row, index) => (
							<StyledTableRow
								key={row?.role + index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<StyledTableCell component="th" scope="row" sx={{ paddingTop:0, paddingBottom:0 }}>
									{row?.role}
								</StyledTableCell>
								<StyledTableCell align="left" sx={{ paddingTop:0, paddingBottom:0 }}>{row?.service}</StyledTableCell>
								<StyledTableCell align="left" sx={{ paddingTop:0, paddingBottom:0 }}>{row?.permissions}</StyledTableCell>
								<StyledTableCell align="left" sx={{ paddingTop:0, paddingBottom:0 }}>
									<IconButton
										aria-label="delete"
										sx={{ marginRight: "10px" }}
										size='small'
										data-role={row?.role}
										data-service={row?.service}
										onClick={handleDeleteDialog}
									>
										<DeleteIcon />
									</IconButton>
									<IconButton
										aria-label="edit"
										data-role={row?.role}
										size='small'
										data-service={row?.service}
										data-permission={row?.permission}
										onClick={handleEditDialog}
									>
										<EditIcon />
									</IconButton>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<NewPagination totalPage={roles} fetchData={fetchRoles}></NewPagination>
		</React.Fragment>
	);
};

export default Roles;
