import { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from './productCreated.module.css';
import Search from '../../../components/search';
import NewPagination from '../../../components/newPagination';
import { useSelector, useDispatch } from 'react-redux';
import { setReportServiceData } from '../../../store/reportServiceSlice';

const ProductsCreated = () => {
	const [text, setText] = useState('');
	const navigate = useNavigate();

	const reportServiceData = useSelector((state) => state.reportServiceData);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();
	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}

	const fetchProduct = async (type, searchText) => {
		const page = type;
		searchText = searchText ? searchText : text

		// window.sessionStorage.getItem("searchDetails");
		await axiosInstance
			.get(`/productCreated/group?page=${page}&text=${searchText}`)
			.then((res) => {
				dispatch(setReportServiceData(res.data.data));
				window.sessionStorage.setItem("searchDetails",JSON.stringify(text))
			});
	};

	useEffect(() => {
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Report Service')) {
			// fetch productCreated
			const searchValue = window.sessionStorage.getItem("searchDetails");
			let value;
			if (searchValue) {
				value = JSON.parse(searchValue)
				setText(JSON.parse(searchValue))
			}
			
			const urlParams = new URLSearchParams(window.location.search);
			const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
			fetchProduct(page? page : 1, value ? value : undefined);
		}
	}, []);

	return (
		<Fragment>
			<div style={{marginTop:10}}>
				<Search func={fetchProduct} text={text} setText={setText} />
			</div>

			<div className={Classes.Container} style={{padding:0,borderRadius:"0px 0px 4px 4px"}}>
				<div style={{ marginTop: 20 }}></div>

				<div className={Classes.Header} style={{ padding:'12px 0px 5px 0px',backgroundColor:'#243750',color:'#fff',borderRadius:'4px 4px 0px 0px' }}>
					<h6 className={Classes.Column} style={{ fontSize:14,width:'8%',paddingLeft:10 }}>
						Group Id
					</h6>
					<h6 className={Classes.ProductDetail} style={{ fontSize: 14,margin:0,width:"11%" }}>
						Upload Type
					</h6>
					<h6 className={Classes.CreatedAtHeader} style={{ width:'14%',fontSize:14 }}>
						Created At
					</h6>
				
					<h6 className={Classes.RequestTypeHeader} style={{width:'13%'}}>
						Request Type
					</h6>

					<h6 className={Classes.CreatedBy} style={{ fontSize:14,width:'11%',textAlign:'center' }}>
						Created By
					</h6>
					
					<h6 className={Classes.Duration} style={{ fontSize:14,margin:0,width:'12%' }}>
						Duration
					</h6>
					<h6 className={Classes.CreatedFrom} style={{ fontSize:14,width:'12%' }}>
						Created From
					</h6>

					<div style={{display:'flex',justifyContent:'space-between',width:'16%'}}>
						<h6>Total</h6>
						<h6>Success</h6>
						<h6>Failed</h6>
					</div>
				</div>

				<div>
				
				</div>

				<div style={{ fontSize:13 }}>
					{reportServiceData?.product?.length !== 0 && reportServiceData?.product?.map((created) => (
						<div 
							key={created?._id} 
							className={Classes.Row}
							style={{padding:"18px 0px"}}
							onClick={() => navigate(`/productsCreated/${created?._id}`)}
						>
							<div className={[Classes.GroupId, 'd-flex'].join(' ')}>
								<p className='d-flex d-md-none text-muted' style={{marginRight:10}}>Group Id:</p>
								<p style={{margin:'auto 0px'}}>{created?.groupId}</p>
							</div>
							
							<div className={Classes.UploadType} style={{ margin:'auto 0px',fontWeight:600 }}>
								<p className='d-flex d-md-none text-muted' style={{marginRight:10,paddingLeft:10}}>
									Upload Type:
								</p>
								<p >{created?.productUploadType}</p>
							</div>

							<div className={Classes.CreatedAt}>
								<p className='d-flex d-md-none text-muted' style={{marginRight:10,paddingLeft:10,fontWeight:600}}>
									Created At:
								</p>
								<p className='mx-1'>{createdAt(created?.createdAt)}</p>
							</div>


							<div className={Classes.Request}>
								<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>
									Request Type:
								</p>
								{created?.requestType === 'Delete Product' ? (
									<p 
										className={Classes.RequestType}
										style={{ backgroundColor:'#FFD79D',width:'fit-content' }}
									>
										{created?.requestType}
									</p>
								) : (
									<p 
										className={Classes.RequestType}
										style={{
											width:'fit-content',
											backgroundColor: created?.requestType === 'Update Product' ?
											'#f1e46f' : 'powderblue'
										}}
									>
										{created?.requestType}
									</p>
								)}
								
							</div>

							<div className={Classes.CreatedBy}>
								<p className='d-flex d-md-none text-muted' style={{paddingLeft:5,fontWeight:600}}>
									Created By:
								</p>
								<p style={{ paddingLeft:10 }}>{created?.createdBy?.userName}</p>
							</div>
								
							<div className={Classes.Duration}>
								<p className='d-flex d-md-none text-muted' style={{paddingLeft:2,fontWeight:600,marginRight:10}}>
									Duration:
								</p>
								<p className={Classes.DurationContent}>{created?.duration ? created?.duration :''}</p>
							</div>
							<div className={Classes.CreatedFrom}>
								<p className='d-flex d-md-none text-muted' style={{paddingLeft:5,fontWeight:600}}>
									Created From:
								</p>
								<p style={{ paddingLeft:10 }}>{created?.createdFrom? created?.createdFrom : 'N/A'}</p>
							</div>

							<div className={Classes.Reports} >
								<div>
									<p className='d-flex d-md-none text-muted' style={{fontWeight:600}}>Total</p>
									<p 
										className={Classes.Total} 
										style={{background:'#627d98',color:'#fff',fontWeight:600,height:'fit-content'}}
									>{created?.total}</p>
								</div>

								<div>
									<p className='d-flex d-md-none text-muted' style={{fontWeight:600}}>Success</p>
									<p 
										className={Classes.Success} 
										style={{background:'#0A9154',color:'#fff',fontWeight:600,height:'fit-content'}}
									>{created?.success ? created?.success : 0}</p>
								</div>
								
								<div>
									<p className='d-flex d-md-none text-muted' style={{fontWeight:600}}>Failed</p>
									<p 
										className={Classes.Failed} 
										style={{background:'#e12d39',color:"#fff",fontWeight:600,height:'fit-content'}}
									>{created?.failed ? created?.failed : 0}</p>
								</div>									
							</div>
						</div>
					))}
				</div>
			</div>
			
			<div style={{ marginTop: 10, margin: '10px auto 0px' }}>
				{reportServiceData?.product?.length > 0 ? (
					<NewPagination totalPage={reportServiceData} fetchData={fetchProduct} />
				) : null}
			</div>
		</Fragment>
	);
};

export default ProductsCreated;
