import { useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from '../../customers/css/customer.module.css';
import { Paper, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import axiosInstance from '../../../../utility/axios-instance';
import Spinner from '../../../../components/spinner';
import NewPagination from '../../../../components/newPagination';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { GridToolbar } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import SchemePopup from './schemePopup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { services } from '../../../../utility/checkRoleBasedPermission';
import Permission from '../../../auth/permissions';
import MobileResponsive from './mobileResponsive';
import { useSelector, useDispatch } from 'react-redux';
import { setAllSchemes } from '../../../../store/allSchemesSlice.js';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const years = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const Schemes = (props) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({ field: 'username', value: '' });
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ field: 'name', sort: 'asc' });
    const [popup, setPopup] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [schemeId, setSchemeId] = useState(null);
    const [operator, setOperator] = useState('custom');
    const [value, setValue] = useState(null);
    const [skus, setSkus] = useState([]);
    const [unit, setUnit] = useState('Value');
    const [target, setTarget] = useState(null);
    const [name, setName] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const allSchemes = useSelector((state) => state.allSchemes);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    const columns = [
        { field: 'scheme_name', flex: 1.5, headerName: 'NAME', headerClassName: 'super-app-theme--header', sortable: true },
        {
            field: 'sku', flex: 2, headerName: 'SKU', headerClassName: 'super-app-theme--header', filterable: false, sortable: false,
            renderCell: (params) => {
                let arr = params?.value
                return Array.isArray(arr) && (
                    <div>
                        {arr?.slice(0, 1)?.map((user, index) => (
                            <Chip key={index} label={user} style={{ margin: '3px' }} />
                        ))}
                        {arr?.length > 1 ? (
                            <Tooltip title={
                                <div>
                                    {arr?.slice(1)?.map((service, index) => (
                                        <p>{service}, </p>
                                    ))}
                                </div>
                            } arrow>
                                <Chip size="small" label={`+ ${arr?.length - 1} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                            </Tooltip>
                        ) : null}
                    </div>
                )
            }
        },
        { field: 'unit', flex: 1, headerName: 'UNIT', headerClassName: 'super-app-theme--header', sortable: true },
        {
            field: 'minimum_target', flex: 1, headerName: 'MIN. TARGET', headerClassName: 'super-app-theme--header', sortable: true,
            renderCell: (params) => {
                return params.row.unit === "Value" ? (
                    <div>
                        ₹{params?.value}
                    </div>
                ) : (
                    <div>
                        {params?.value}
                    </div>
                )
            }

        },
        { field: 'createdAt', filterable: false, headerName: 'DATE', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.scheme} permission={"update"}>
                <Button size='small' color='success' variant="contained" onClick={(e) => showUpdatePopup(params?.row)}>Update</Button>
                </Permission>
            )
        },
    ];

    const showCreatePopup = () => {
        setPopup(true);
    }

    const showUpdatePopup = (scheme) => {
        setSchemeId(scheme.id);
        setName(scheme.scheme_name);
        setTarget(scheme.minimum_target);
        setUnit(scheme.unit);
        setSkus(scheme.sku);
        setValue(null);
        setOperator('custom');
        setUpdatePopup(true);
    }

    const hideUpdatePopup = () => {
        setName(null);
        setTarget(null);
        setUnit('Value');
        setSkus([]);
        setValue(null);
        setOperator('custom');
        setSchemeId(null);
        setUpdatePopup(false);
        fetchSchemes();
    }

    const hideCreatePopup = () => {
        setName(null);
        setTarget(null);
        setUnit('Value');
        setSkus([]);
        setValue(null);
        setOperator('custom');
        setPopup(false);
        fetchSchemes();
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };


    const handleCreatedAt = (createdAt) => {
        const dn = new Date();
        let ca = createdAt
            ? createdAt.getFullYear() === dn.getFullYear()
                ? createdAt.getMonth() === dn.getMonth()
                    ? createdAt.getDate() === dn.getDate()
                        ? `Today at ${createdAt.toLocaleTimeString()}`
                        : createdAt.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
                            : years[createdAt.getMonth()] +
                            ' ' +
                            createdAt.getDate() +
                            ' at ' +
                            createdAt.toLocaleTimeString()
                    : years[createdAt.getMonth()] +
                    ' ' +
                    createdAt.getDate() +
                    ' at ' +
                    createdAt.toLocaleTimeString()
                : years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
            : null
        return ca;
    }

    const fetchSchemes = async (page) => {
        setLoading(true);
        setError(null);

        const params = {
            page,
            searchText: search ? search : '',
            // filter: JSON.stringify(filter),
            // sort,
        };

        await axiosInstance
            .get('/scheme/schemes', { params })
            .then((response) => {
                dispatch(setAllSchemes(response?.data?.data));
            })
            .catch((error) => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    };

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Dashboard')) {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            fetchSchemes(page ? page : 1);
        }
    }, [search]);


    return (
        <Fragment>
            <Permission service={services} permission={"create"}>
                <div style={{ display:'flex',justifyContent:'end',marginBottom:10,marginTop:-25 }} className={Classes.inventoryTabHeader}>
                    <Button variant="contained" onClick={(e) => showCreatePopup(e)}>Create</Button>
                </div>
            </Permission>

            <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            <div style={{ width: '100%' }}  className={Classes.MuiTable} >
                <Box
                    component={Paper}
                    className="shadow"
                    sx={{
                        '& .super-app-theme--header': {
                            backgroundColor:'#243750',color:'#ffffff',cursor:"default"
                        },
                    }}
                >
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        filterMode="server"
                        filterColumns={['name', 'role', 'username']}
                        sortingMode="server"
                        paginationMode="server"
                        disableRowSelectionOnClick
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                        }}
                        onSortModelChange={(sort) => setSort(sort[0])}
                        sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            ".MuiDataGrid-footerContainer": {
                                display: "none !important",
                            },
                            boxShadow: 2,
                            borderRadius: 2,
                        }}
                        columns={columns}
                        rows={allSchemes?.allSchemes?.map((scheme) => ({
                            id: scheme?.scheme_id,
                            scheme_name: scheme?.scheme_name,
                            sku: scheme?.sku,
                            unit: scheme?.unit,
                            minimum_target: scheme?.minimum_target,
                            createdAt: handleCreatedAt(new Date(scheme?.created_at)),
                        }))}
                        sortingOrder={['asc', 'desc']}
                        autoHeight
                        autoPageSize
                        onFilterModelChange={(filter) => (setFilter(filter.items[0]), setSearch(filter.quickFilterValues[0]))}
                    />
                </Box>

                {allSchemes?.allSchemes?.length > 0 ? (
                    <nav aria-label="Page navigation">
                        <NewPagination totalPage={allSchemes} fetchData={fetchSchemes} />
                    </nav>
                ) : null}

                {loading ? <Spinner /> : null}
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"allSchemes"}
                    endpoint={`/scheme/schemes`}
                    rowId={'scheme_id'}
                    handleClickOpen={showUpdatePopup}
                    // showClosePopup={showClosePopup}
                />
            </div>

            {/* this is for create new scheme */}
            <SchemePopup
                open={popup}
                handleClose={hideCreatePopup}
                operator={operator}
                setOperator={setOperator}
                value={value}
                setValue={setValue}
                unit={unit}
                setUnit={setUnit}
                target={target}
                setTarget={setTarget}
                name={name}
                setName={setName}
                skus={skus}
                setSkus={setSkus}
                fetchSchemes={fetchSchemes}
                setSnackBar={setSnackBar}
                type={'create'}
            />

            {/* this for update and delete scheme */}
            <SchemePopup
                open={updatePopup}
                handleClose={hideUpdatePopup}
                operator={operator}
                setOperator={setOperator}
                value={value}
                setValue={setValue}
                unit={unit}
                setUnit={setUnit}
                target={target}
                setTarget={setTarget}
                name={name}
                setName={setName}
                skus={skus}
                setSkus={setSkus}
                fetchSchemes={fetchSchemes}
                setSnackBar={setSnackBar}
                type={'edit'}
                id={schemeId}
            />
        </Fragment>
    );
};

export default Schemes;