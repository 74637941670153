import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "./css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";


const TaxRatesPopup = (props)=> {
    const {
        showTaxRate,
        totalTaxAmount,
        hideTaxPopup,
        showProduct,
        showCustomerDetail,
        addressArray,
        customerIDAddress,
        taxRates
    } = props;

    const handleGst = (gst) => {
        return gst/2
    }

    const handleTaxAmount = (amount) => {
        let splitAmount = (amount/2);
        let checkDecimal = countPlaces(splitAmount)
        return checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? splitAmount : Math.trunc(splitAmount*100)/100
    }

    const handleFirst2Decimal = (amount) => {
        let checkDecimal = countPlaces(amount)
        return checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? amount : Math.trunc(amount*100)/100
    }

    const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    return (
        <Fragment>
            {showTaxRate && (
                <div className={Classes.modal} style={{bottom:'28%',top:'16%'}}>
                    <div className={Classes.BoxContent} style={{boxShadow: "0 5px 5px -5px #000"}}>
                        <p>Tax rates</p>
                        <div>
                            <FontAwesomeIcon 
                                icon={faXmark} 
                                style={{fontSize: '25px',
                                    color: 'rgb(129, 138, 155)',
                                    cursor: "pointer"
                                }}
                                onClick={hideTaxPopup}
                            />
                        </div>
                    </div>

                    {showProduct.length !== 0 ?   
                        <div className={Classes.TaxRatePopup} style={{height:'77%'}}>
                            {taxRates?.map((taxes,index) => (
                                <div style={{padding:'10px'}} key={index}>
                                    <div>
                                        <div>
                                            <p style={{marginRight:5}}>
                                                HSN Code ({taxes?.hsnCode})
                                            </p>
                                        </div>

                                        {showCustomerDetail.length !== 0  && addressArray.length !== 0 && customerIDAddress.length ? (
                                            <div>
                                                {customerIDAddress[0].province === 'West Bengal' ? (
                                                    <>
                                                        <div className={Classes.TaxRatesPopupFlexBetween}>
                                                            <p style={{padding:'0px 5px'}}>
                                                                CGST({handleGst(taxes?.gst)}%)
                                                            </p>
                                                            <p>&#8377;{handleTaxAmount(taxes.tax)}</p>
                                                        </div>

                                                        <div className={Classes.TaxRatesPopupFlexBetween}>
                                                            <p style={{padding:'0px 5px'}}>
                                                                SGST({handleGst(taxes?.gst)}%)
                                                            </p>
                                                            <p>&#8377;{handleTaxAmount(taxes.tax)}
                                                            </p>
                                                        </div>
                                                    </>
                                                ): (
                                                    <div className={Classes.TaxRatesPopupFlexBetween}>
                                                        <p style={{padding:'0px 5px'}}>
                                                            IGST({taxes?.gst}%)
                                                        </p>
                                                        <p>&#8377;{handleFirst2Decimal(taxes?.tax)}</p>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <div className={Classes.TaxRatesPopupFlexBetween}>
                                                    <p style={{padding:'0px 5px'}}>
                                                        CGST({handleGst(taxes?.gst)}%)
                                                    </p>
                                                    <p>&#8377;{handleTaxAmount(taxes?.tax)}</p>
                                                </div>

                                                <div className={Classes.TaxRatesPopupFlexBetween}>
                                                    <p style={{padding:'0px 5px'}}>
                                                        SGST({handleGst(taxes?.gst)}%)
                                                    </p>
                                                    <p>&#8377;{handleTaxAmount(taxes?.tax)}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {showProduct?.shippingLine && (
                                <>
                                    {showProduct?.shippingLine?.taxAmount && showProduct?.shippingLine?.taxAmount !== 0 && (
                                        <div style={{padding:'10px'}}>
                                            <div>
                                                <p>Shipping HSN Code(9965)</p>
                                            </div>
                                            <div className={Classes.TaxRatesPopupFlexBetween}>
                                                <p style={{padding:'0px 15px'}}>GST(18%)</p>
                                                <p>&#8377;{showProduct?.shippingLine?.taxAmount}</p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            <div 
                                style={{padding:'10px',fontWeight:'600'}}
                                className={Classes.TaxRatesPopupFlexBetween}
                            >
                                <div>
                                    <p>Total Tax</p>
                                </div>
                                <div>
                                    <p>&#8377;{totalTaxAmount ? totalTaxAmount.toFixed(2) : ""}</p>
                                </div>
                            </div>
                        </div>
                    :  null}
                </div>
            )}
        </Fragment>
    );
}


export default TaxRatesPopup;