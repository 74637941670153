import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Classes from '../createOrders/css/Modal.module.css';
import { faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../utility/axios-instance';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { Chip } from '@mui/material';
let CancelToken = axios.CancelToken;
let source = CancelToken.source();

const Modal = (props) => {
	const [searchProduct, setSearchProduct] = useState([]);
	const [nextPage, setNextPage] = useState([]);
	const [maxPage,setMaxPage] = useState();

	const {
		productArr,
		variant,
		setVariant,
		toggle,
		setToggle,
		search,
		setSearch,
		show,
		setProductNew,
		productNew,
		productIdArr,
		setProductIdArr,
		locationCode,
	} = props;

	let countProduct = { variant };
	let countVariant = countProduct.variant;

	document.addEventListener('mouseup', function (e) {
		let container = document.getElementById('modalOutline');
		if (container !== null) {
			if (!container.contains(e.target)) {
				container.style.border = '1px solid rgb(206, 212, 218)';
			}
		}
	});

	const AddElemFromArr = (product, locationCode) => {
		if (productArr.length === 0) {
			setProductNew((productNew) => [
				...productNew,
				{ variantId: product, quantity: 0, PCS:0, locationCode: locationCode  },
			]);
			document.getElementById('btn').style.backgroundColor = '#008060';
			document.getElementById('btn').style.color = '#fff';
			document.getElementById('variant').style.color = '#000000';
		} else {
			document.getElementById('btn').style.backgroundColor = '#008060';
			document.getElementById('btn').style.color = '#fff';
			document.getElementById('variant').style.color = '#000000';
			setProductNew((productNew) => [
				...productNew,
				{ variantId: product, quantity: 0, PCS:0, locationCode: locationCode },
			]);
		}
	};

	const remElemFromArr = (product) => {
		setProductNew((productNew) =>
			productNew.filter((ele) => ele.variantId !== product)
		);

		if (countVariant === 0) {
			document.getElementById('btn').style.backgroundColor = '';
			document.getElementById('btn').style.color = '';
			document.getElementById('variant').style.color = '';
		}
	};

	const handleChange = async (event, parentId, childId, locationCode, childNodes) => {
		if (event.checked) {
			if (productIdArr.length === 0) {
				setProductIdArr([parentId])
			} else {
				if (!productIdArr.includes(parentId)) {
					const array = productIdArr;
					array.push(parentId);
					setProductIdArr(array)
				}
			}

			if (event.className === 'parentNode') {
				const parentList = document.getElementById(parentId);
				const ListChildren = parentList.children;
				for (let i of ListChildren) {
					const productVariant = childNodes?.find((variant) => variant?.variantId === i?.id)
					if (i.id) {
						const child =
							i.firstElementChild.firstElementChild.firstElementChild;
						if (!child.checked) {
							child.checked = true;
							countVariant += 1;
							AddElemFromArr(i.id, productVariant?.locationCode);
						}
					}
				}
				setVariant(countVariant);
			} else {
				if (childId) {
					countVariant += 1;
					setVariant(countVariant);
					console.log(document.getElementById(parentId).firstElementChild)
					const parent =
						document.getElementById(parentId).firstElementChild.firstElementChild;
					if (productArr.length > 0) {
						if (!parent.checked) {
							parent.checked = true;
						}
						AddElemFromArr(childId, locationCode);
						return;
					}
					parent.checked = true;
					AddElemFromArr(childId, locationCode);
					return;
				}
				setVariant(countVariant);
			}
		} else {
			if (event.className === 'parentNode') {
				if (productIdArr.length !== 0) {
					setProductIdArr((product) =>
						product.filter((ele) => ele !== parentId)
					);
				}
				const parentList = document.getElementById(parentId);
				const ListChildren = parentList.children;
				for (let i of ListChildren) {
					if (i.id) {
						if (countVariant !== 0) {
							countVariant -= 1;
							const child =
								i.firstElementChild.firstElementChild.firstElementChild;
							if (child.checked) {
								child.checked = false;
								remElemFromArr(i.id);
							}
						}
					}
				}
				setVariant(countVariant);
			} else {
				const parentList = document.getElementById(parentId);
				const ListChildren = parentList.children;
				const parent =
					document.getElementById(parentId).firstElementChild.firstElementChild
						.firstElementChild;
				for (let i of ListChildren) {
					if (i.id) {
						const child = document.getElementById(i.id).firstElementChild
							.firstElementChild.firstElementChild;
						if (child.checked) {
							if (countVariant !== 0) {
								countVariant -= 1;
							}
							remElemFromArr(event.id);
							parent.checked = true;
							setVariant(countVariant);
							return;
						}
					}
				}
				if (productIdArr.length !== 0) {
					setProductIdArr((productId) =>
						productId.filter((ele) => ele !== parentId)
					);
				}
				parent.checked = false;
				if (countVariant !== 0) {
					countVariant -= 1;
				}
				setVariant(countVariant);
				remElemFromArr(event.id);
			}
		}
	};

	const fetchProducts = (product) => {
		document.getElementById('modalOutline').style.border = '2px solid #458fff';
		setToggle(true);
		if (product === '') {
			setNextPage([1])
		}

		setSearch(product);
	};

	const [searchLoader, setSearchLoader] = useState(false);

	useEffect(() => {}, [searchLoader]);

	useEffect(() => {
		if (toggle) {
			setSearchLoader(true);
			source.cancel('incoming new search request!');
			CancelToken = axios.CancelToken;
			source = CancelToken.source();
			axiosInstance
				.get(`product/productsFromPgDb?page=${1}&text=${encodeURIComponent(search)}&locationCode=${locationCode}`, {
					cancelToken: source.token
				})
			.then((res) => {
				if (res?.data?.data?.maxPage > 1) {
					setNextPage([2]);
				}
				setMaxPage(res?.data?.data?.maxPage)
				setSearchProduct(res?.data?.data?.productVariant);
				setSearchLoader(false);
			}).catch(error => {
				if (axios.isCancel(error)) {
					console.log('Request canceled:', error.message);
				} else {
					console.log('Error:', error.message);
					setSearchLoader(false);
				}
			});
		}
	}, [search, toggle]);

	const fetchMoreData = () => {
		if (maxPage >= nextPage[0]) {
			setTimeout(() => {
				axiosInstance
					.get(`product/productsFromPgDb?page=${nextPage[0]}&text=${encodeURIComponent(search)}&locationCode=${locationCode}`)
					.then((res) => {
						if (res?.data?.data?.maxPage >= res?.data?.data?.page) {
							const page = res?.data?.data?.page + 1
							setNextPage([page]);
						}
						setSearchProduct([...searchProduct,...res?.data?.data?.productVariant]);
					});
			}, 1500);
		}
	};

	return (
		<Fragment>
			{show && (
				<div className={Classes.modal}>
					<div className={Classes.BoxContent} style={{paddingBottom:0}}>
						<p>All Products</p>
						<FontAwesomeIcon
							icon={faXmark}
							className={Classes.ClosePopUpIcon}
							onClick={props.onHide}
						/>
					</div>

					<div className={[Classes.BoxContent,Classes.Border].join(' ')}>
						<div id="modalOutline" className={Classes.SearchInputBox}>
							<FontAwesomeIcon icon={faMagnifyingGlass} className={Classes.SearchIcon} />

							<div style={{ width: '100%', position: 'relative' }}>
								<input
									type="text"
									id="search"
									autoFocus={true}
									autoComplete="off"
									className={props.styles.searchField}
									placeholder="Search products"
									value={search}
									onChange={(e) => fetchProducts(e.target.value)}
								/>

								{searchLoader ? 
									<div style={{ position:'absolute',top:4,right:10 }}>
										<span className={Classes.SearchLoader}></span>
									</div> 
								: null}
							</div>
						</div>
					</div>

					{searchProduct?.length !== 0  && (
						<div className={Classes.BoxContainer} style={{height:'63.5%'}} id="ScrollableContainer">
							<InfiniteScroll
								dataLength={searchProduct?.length}
								next={fetchMoreData}
								hasMore={nextPage[0] <= maxPage ? true : false}
								loader={ <div>{nextPage[0] < maxPage ? <h6>Loading...</h6> : ''}</div> }
								scrollableTarget="ScrollableContainer"
							>
								{searchProduct?.map((product,index) => (
									<div id={product?.productId} key={index}>
										<div className={Classes.Boxes}>
											<div>
												<input
													type="checkbox"
													className="parentNode"
													key={productIdArr.length !== 0 && productIdArr.includes(product?.productId) ? true : false}
													defaultChecked={productIdArr.length !== 0 && productIdArr.includes(product?.productId) ? true : false}
													onChange={(e) => handleChange(e.target, product?.productId, undefined, undefined, product?.childNode)}
												/>
											</div>
											<div style={{display:'flex'}}>
												<img
													src={product?.images? product?.images: process.env.REACT_APP_IMAGE_COMMING_SOON}
													alt="icon"
													className={Classes.productImage}
												/>
												<p style={{ margin:'auto 0px' }} className={Classes.productContent}>
													{product?.groupName}
												</p>
											</div>
										</div>
										
										{product?.childNode?.map((variant) => (
											<div className={Classes.Child} key={variant?.variantId} id={variant?.variantId}>
												<div style={{ display: 'flex' }}>
													<div style={{margin:'auto 0px'}}>
														<input
															type="checkbox"
															className="childNode"
															id={variant?.variantId}
															key={productNew.length !== 0 && productNew.some(el => el.variantId === variant?.variantId) ? true : false}
															defaultChecked={productNew.length !== 0 && productNew.some(el => el.variantId === variant?.variantId) ? true : false}
															onChange={(e) => handleChange(e.target, product?.productId, variant?.variantId, variant?.locationCode, undefined)}
														/>
													</div>

													<div>
														<div style={{display:'flex'}}>	
															<p className={Classes.variantContent}>{variant?.variantSize1}</p>
															{variant?.variantSize2 && ( <p>/ &nbsp;{variant?.variantSize2}</p> )}
															{variant?.variantSize3 && ( <p>/ &nbsp;{variant?.variantSize3}</p> )}
														</div>

														<div className={Classes.variantSkuBox}>
															<p className={Classes.variantSku}>SKU:- </p>
															<p>{variant?.sku}</p>
														</div>

														<div className={Classes.variantSkuBox}>
															<p className={Classes.variantSku}>GST:- </p>
															<p>{variant?.gst}%</p>
														</div>
													</div>
												</div>

												<div>
													<div className={Classes.fontSizeDisplay}>
														<p style={{ marginLeft: '5px', marginRight: '5px' }}>Available Qty:</p>
														{
															variant?.availableQuantity?.length > 0 &&
															variant?.availableQuantity?.map((inventory) => (
																<Chip label={`${inventory?.locationCode}: ${inventory?.availableQty}`} size='small' style={{ marginRight: 5, backgroundColor: "#ff6200", color: "#fff", height: 'fit-content' }} />
															))
														}
													</div>
													<div className={Classes.fontSizeDisplay} style={{ marginTop: '10px' }}>
														<p style={{ marginLeft: '5px', marginRight: '5px' }}>Price:</p>
														<Chip variant="outlined" label={`${variant?.locationCode}: ₹ ${variant?.price} INR`} size='small' style={{ marginRight: 5, backgroundColor: "#10243E", color: "#fff", height: 'fit-content' }} />
													</div>
												</div>
											</div>
										))}
									</div>
								))}
							</InfiniteScroll>
						</div>
					)}

					<div className={[Classes.Footer,Classes.Button].join(' ')} style={{width:'100%'}}>
						<button id="variant">{variant} variants selected</button>
						<div className={[Classes.TextAlignRight,Classes.Add].join(' ')}>
							<button onClick={props.onHide} style={{ color:'black' }}>
								Cancel
							</button>
							<button id="btn" onClick={props.AddHide} style={{ marginLeft:10 }}>
								Add
							</button>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Modal;
