import { Fragment, useState, useEffect } from 'react';
import axiosInstance from '../../../../utility/axios-instance';
import Classes from './css/addProduct.module.css';

const  Pricing = (props) => {
    const [unitToggle,setUnitToggle] = useState(true);
    const [units, setUnits] = useState([]);

    const {
        handleGroupTitle,
        handleHsnCode,
        hsnCodes,
        showErrorMsg,
        handleDiscount,
        storeDiscountArr,
        productDetails,
        limitPrice,
        handleDatabase,
        handleGstRate,
        handleVinculum,
        unitType
    }= props;

    useEffect(() => {
		if(unitToggle){
			axiosInstance
				.get('/others/unit')
				.then((res) => {
					setUnits(res.data.data)
					setUnitToggle(false)
				})
		}
	},[unitToggle]);
	
	return (
		<Fragment>
            <div className={Classes.Pricing}>
                <div className={Classes.Boxes}>
                    <div style={{display:'flex'}}>
                        <h6>Pricing</h6>
                    </div>

                    <div>
                        <div style={{display:'flex',marginTop:15}}>
                            <div style={{display:'flex'}}>
                                <p>Group Title (Shopify Listing Name)</p>
                                <p className={Classes.requiredField}>*</p>
                            </div>
                        </div>
                        <div>
                            <input
                                type="text"
                                id="groupTitle"
                                name='title'
                                required={true}
                                key={
                                    productDetails?.length !== 0 ?
                                    productDetails[0]?.groupName :
                                    ''
                                }
                                defaultValue={
                                    productDetails?.length !== 0 ?
                                    productDetails[0]?.groupName :
                                    ''
                                }
                                autoComplete='off'
                                className={[
                                    Classes.searchField, 
                                    Classes.InputField
                                ].join(' ')}
                                onBlur={(e) => handleGroupTitle(e)}
                            />
                        </div>

                        <div style={{ display: "flex",marginTop:15 }}>
                            <p>Product Title (System Listing Name)</p>
                            <p className={Classes.requiredField}>*</p>
                        </div>

                        <div>
                            <input
                                name="productName"
                                type="text"
                                id="product_title"
                                required={true}
                                autoComplete="off"
                                defaultValue={
                                    productDetails?.length !== 0 ? 
                                    productDetails[0]?.productName : 
                                    ""
                                }
                                key={
                                    productDetails?.length !== 0 ? 
                                    productDetails[0]?.productName : 
                                    ""
                                }
                                className={[
                                    Classes.searchField,
                                    Classes.InputField,
                                    "productTitle",
                                ].join(" ")}
                                onBlur={(e) => handleDatabase(e)}
                                placeholder="Hinges"
                            />
                        </div>

                        <div>
                            {showErrorMsg?.length !== 0 && (
                                <div>
                                    {showErrorMsg?.fieldName === "productName" && (
                                        <p className={Classes.Msg}>{showErrorMsg?.message}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{width:'48%'}}>
                            <div className={Classes.Field}>  
                                <div style={{marginTop:15,display:'flex'}}>
                                    <p>DSIN Code (SKU)</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>
                                <div>
                                    <input
                                        name='dsinCode'
                                        type="text"
                                        id="dsinCode"
                                        defaultValue={
                                            productDetails?.length !== 0 ?
                                            productDetails[0]?.sku :
                                            ''
                                        }
                                        key={
                                            productDetails?.length !== 0 ?
                                            productDetails[0]?.sku :
                                            ''
                                        }
                                        autoComplete='off'
                                        required={true}
                                        onBlur={(e) => handleDatabase(e)}
                                        className={[
                                            Classes.searchField,
                                            Classes.InputField
                                        ].join(' ')}
                                    />
                                </div>

                                <div>
                                    {showErrorMsg?.length !== 0 && (
                                        <div>
                                            {showErrorMsg?.fieldName === 'sku' && (
                                                <p>{showErrorMsg?.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{width:'48%'}}>
                            <div style={{marginTop: 15,display: 'flex'}}>
                                <p>HSN Code</p>
                                <p className={Classes.vinculumRequiredField}>*</p>
                            </div>

                            <div>
                                {hsnCodes?.length !== 0 && (
                                    <select 
                                        name='hsnCode'
                                        id='hsnCode'
                                        defaultValue={
                                            productDetails?.length !== 0 &&
                                            productDetails[0]?.hsnCode ?
                                            productDetails[0]?.hsnCode :
                                            ''
                                        }
                                        key={
                                            productDetails?.length !== 0 &&
                                            productDetails[0]?.hsnCode ?
                                            productDetails[0]?.hsnCode :
                                            ''
                                        }
                                        onChange={handleHsnCode}
                                    >
                                        <option value='' disabled={true}>
                                            Select Hsn Code
                                        </option>

                                        {hsnCodes?.map((hsnCode,index) => (
                                            <option 
                                                key={index} 
                                                value={hsnCode?.HSNCode}
                                                data-percentage={hsnCode?.Percentage}
                                            >
                                                {hsnCode?.HSNCode}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'hsn_code' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div> 
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={Classes.PriceField}>  
                            <div style={{marginTop:15,display:'flex'}}>
                                <p>MRP</p>
                                <p className={Classes.requiredField}>*</p>
                            </div>
                            <div>
                                <input
                                    name='MRP'
                                    type="number"
                                    id="mrp"
                                    step="0.01"
                                    autoComplete='off'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    key={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.mrp :
                                        ''
                                    }
                                    defaultValue={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.mrp :
                                        ''
                                    }
                                    required={true}
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onBlur={() => handleDatabase()}
                                />
                            </div>

                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'compareAtPrice' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={Classes.PriceField}>  
                            <div style={{marginTop:15,display:'flex'}}>
                                <p>LIST PRICE (Exclusive GST)</p>
                                <p className={Classes.requiredField}>*</p>
                            </div>
                            <div>
                                <div>
                                    <input
                                        name='listingPrice'
                                        type="number"
                                        id="listDiscount"
                                        step="0.01"
                                        required={true}
                                        onKeyDown={
                                            e => ( e.keyCode === 69) 
                                            && e.preventDefault()
                                        }
                                        key={
                                            productDetails?.length !== 0 ?
                                            productDetails[0]?.listingPrice :
                                            ''
                                        }
                                        defaultValue={
                                            productDetails?.length !== 0 ?
                                            productDetails[0]?.listingPrice :
                                            ''
                                        }
                                        min="0"
                                        autoComplete='off'
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        placeholder='&#8377;  0.00'
                                        onBlur={(e) => handleDiscount(
                                            e,'listDiscountErrorPopUp'
                                        )}
                                    />
                                </div>
                            </div>

                            <div 
                                id='listDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.thresholdPrice}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'price' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>

                            {storeDiscountArr?.length !== 0 && (
                                <div>
                                    {storeDiscountArr?.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'listDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType?.listDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>RETAILER (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='retailer'
                                    type="number"
                                    id="retailerDiscount"
                                    autoComplete='off'
                                    step="0.01"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    key={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.retailPrice :
                                        ''
                                    }
                                    defaultValue={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.retailPrice :
                                        ''
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onBlur={(e) => handleDiscount(
                                        e,'RetailerDiscountErrorPopUp'
                                    )}
                                />
                            </div>

                            <div 
                                id='RetailerDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.thresholdPrice}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {/* <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'retailPrice' && (
                                            <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                        )}
                                    </div>
                                )}
                            </div> */}

                            {storeDiscountArr?.length !== 0 && (
                                <div>
                                    {storeDiscountArr?.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'retailerDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType?.retailerDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>INTERIOR (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='interior'
                                    type="number"
                                    id="interiorDiscount"
                                    step="0.01"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    key={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.interiorPrice :
                                        ''
                                    }
                                    defaultValue={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.interiorPrice :
                                        ''
                                    }
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onBlur={(e) => handleDiscount(
                                        e,'InteriorDiscountErrorPopUp'
                                    )}
                                />
                            </div>

                            <div 
                                id='InteriorDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.thresholdPrice}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {/* <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'interiorPrice' && (
                                            <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                        )}
                                    </div>
                                )}
                            </div> */}

                            {storeDiscountArr?.length !== 0 && (
                                <div>
                                    {storeDiscountArr?.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'interiorDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType?.interiorDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>OEM (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='oem'
                                    type="number"
                                    id="oemDiscount"
                                    step="0.01"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    key={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.oemPrice :
                                        ''
                                    }
                                    defaultValue={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.oemPrice :
                                        ''
                                    }
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onBlur={(e) => handleDiscount(
                                        e,'oemDiscountErrorPopUp'
                                    )}
                                />
                            </div>

                            <div 
                                id='oemDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.thresholdPrice}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {/* <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'oemPrice' && (
                                            <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                        )}
                                    </div>
                                )}
                            </div> */}
                            
                            {storeDiscountArr?.length !== 0 && (
                                <div>
                                    {storeDiscountArr?.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'oemDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType?.oemDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>END USER (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='endUser'
                                    type="number"
                                    id="endUser"
                                    step="0.01"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    key={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.endUserPrice :
                                        ''
                                    }
                                    defaultValue={
                                        productDetails?.length !== 0 ?
                                        productDetails[0]?.endUserPrice :
                                        ''
                                    }
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onBlur={(e) => handleDiscount(
                                        e,'endUserDiscountErrorPopUp'
                                    )}
                                />
                            </div>

                            <div 
                                id='endUserDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.thresholdPrice}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {storeDiscountArr?.length !== 0 && (
                                <div>
                                    {storeDiscountArr?.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'endUser' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType?.endUser}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        
                        <div style={{width:'48%'}}>
                            <div className={Classes.Field}>  
                                <div style={{display:'flex',marginTop: 15}}>
                                    <p>GST Rate</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>
                                <div>
                                    <select 
                                        name="gst_rate" 
                                        id="gstRate" 
                                        onChange={(e) => handleGstRate(e)} 
                                        required={true}
                                        key={
                                            productDetails?.length !== 0 ? 
                                            `${productDetails[0]?.gst}.0`:
                                            '18.0'
                                        }
                                        defaultValue={
                                            productDetails?.length !== 0 ? 
                                            `${productDetails[0]?.gst}.0` :
                                            '18.0'
                                        }
                                    >
                                        <option value="18.0">18.0</option>
                                        <option value="0.0">0.0</option>
                                        <option value="5.0">5.0</option>
                                        <option value="12.0">12.0</option>
                                        <option value="28.0">28.0</option>
                                    </select>
                                </div>

                                <div>
                                    {showErrorMsg?.length !== 0 && (
                                        <div>
                                            {showErrorMsg?.fieldName === 'gst_rate' && (
                                                <p>{showErrorMsg?.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{width:'48%'}}>
                            <div className={Classes.Field}>  
                                <div style={{marginTop:15,display:'flex'}}>
                                    <p>Model Number</p>
                                </div>
                                <div>
                                    <input
                                        name='modelNumber'
                                        type="text"
                                        id="modelNumber"
                                        key={
                                            productDetails?.length !== 0 ?
                                            productDetails[0]?.modelNo :
                                            ''
                                        }
                                        defaultValue={
                                            productDetails?.length !== 0 ?
                                            productDetails[0]?.modelNo :
                                            ''
                                        }
                                        autoComplete='off'
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        // onBlur={handleShopify}
                                    />
                                </div>
                                <div>
                                    {showErrorMsg?.length !== 0 && (
                                        <div>
                                            {showErrorMsg?.fieldName === 'model_number' && (
                                                <p>{showErrorMsg?.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{width:'48%', marginTop:15}}>
                            <div style={{display:'flex'}}>
                                <p>Unit</p>
                                {/* <p className={Classes.vinculumRequiredField}>*</p> */}
                            </div>
                            
                            <div>
                                {units?.length !== 0 && (
                                    <select
                                        name="unit" 
                                        key={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.unit ?
                                            productDetails[0]?.unit :
                                            ''
                                        }
                                        defaultValue={
                                            productDetails?.length !== 0 &&
                                            productDetails[0]?.unit ?
                                            productDetails[0]?.unit :
                                            ''
                                        }
                                        // disabled={
                                        //     productDetails?.length !== 0 &&
                                        //     productDetails[0]?.unit ? 
                                        //     true : false
                                        // }
                                        id='unit'
                                        onChange={handleVinculum}
                                        style={{ marginTop:0,paddingTop:8 }}
                                    >
                                        <option value=''>
                                            Select Unit Type
                                        </option>

                                        {units?.map((unit) => (
                                            <option key={unit?._id} value={unit?.unit}>
                                                {unit?.unit}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>

                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'unit' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{width:'48%', marginTop:15}}>
                            <p>Delivery Time (Hour/s)</p>
                            <div>
                                <input
                                    name='deliveryTime'
                                    type="number"
                                    id="delivery_time"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    key={
                                        productDetails?.length !== 0 &&
                                        productDetails[0]?.shipmentTime ?
                                        productDetails[0]?.shipmentTime :
                                        ''
                                    }
                                    defaultValue={
                                        productDetails?.length !== 0 &&
                                        productDetails[0]?.shipmentTime ?
                                        productDetails[0]?.shipmentTime :
                                        ''
                                    }
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                />
                            </div>

                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'delivery_time' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div 
                        className={Classes.CommonMarginTop} 
                        style={{display:'flex',justifyContent:'space-between'}}
                    >
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex'}}>
                                <p>Bulk Packaging Type</p>
                                {/* <p className={Classes.vinculumRequiredField}>*</p> */}
                            </div>
                            
                            <div>
                                {units.length !== 0 && (
                                    <select 
                                        name='bulkType1'
                                        id='bulkType1'
                                        // defaultValue=''
                                        defaultValue={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingType1?
                                            productDetails[0]?.bulkPackingType1:
                                            ''
                                        }
                                        key={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingType1?
                                            productDetails[0]?.bulkPackingType1:
                                            ''
                                        }
                                        onChange={handleVinculum}
                                        style={{ marginTop:0,paddingTop:8 }}
                                    >
                                        <option value=''>
                                            Select Bulk Type 1
                                        </option>

                                        {units?.map((unit) => (
                                            <option key={unit?._id} value={unit?.unit}>
                                                {unit?.unit}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'bulkType1' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{display:'flex',width:'48%'}}>
                            <div>
                                <p>Quantity</p>
                                <div>
                                    <input
                                        name='bulkVal1'
                                        type="number"
                                        id="bulkVal1"
                                        onKeyDown={
                                            e => ( e.keyCode === 69) 
                                            && e.preventDefault()
                                        }
                                        defaultValue={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingValue1 ?
                                            productDetails[0]?.bulkPackingValue1 :
                                            ''
                                        }
                                        key={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingValue1?
                                            productDetails[0]?.bulkPackingValue1:
                                            ''
                                        }
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                    />
                                </div>
                            </div>

                            <div className={Classes.unitType}>
                                <p>{unitType}</p>
                            </div>
                            

                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'bulkVal1' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div 
                        className={Classes.CommonMarginTop} 
                        style={{display:'flex',justifyContent:'space-between'}}
                    >
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex'}}>
                                <p>Bulk Packaging Type 2</p>
                                {/* <p className={Classes.vinculumRequiredField}>*</p> */}
                            </div>
                            
                            <div>
                                {units.length !== 0 && (
                                    <select 
                                        name='bulkType2'
                                        id='bulkType2'
                                        // defaultValue=''
                                        defaultValue={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingType2?
                                            productDetails[0]?.bulkPackingType2:
                                            ''
                                        }
                                        key={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingType2?
                                            productDetails[0]?.bulkPackingType2:
                                            ''
                                        }
                                        onChange={handleVinculum}
                                        style={{ marginTop:0,paddingTop:8 }}
                                    >
                                        <option value=''>
                                            Select Bulk Type 2
                                        </option>

                                        {units?.map((unit) => (
                                            <option key={unit?._id} value={unit?.unit}>
                                                {unit?.unit}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'bulkType2' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{display:'flex',width:'48%'}}>
                            <div>
                                <p>Quantity</p>
                                <div>
                                    <input
                                        name='bulkVal2'
                                        type="number"
                                        id="bulkVal2"
                                        onKeyDown={
                                            e => ( e.keyCode === 69) 
                                            && e.preventDefault()
                                        }
                                        defaultValue={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingValue2?
                                            productDetails[0]?.bulkPackingValue2:
                                            ''
                                        }
                                        key={
                                            productDetails?.length !== 0 && 
                                            productDetails[0]?.bulkPackingValue2?
                                            productDetails[0]?.bulkPackingValue2:
                                            ''
                                        }
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                    />
                                </div>
                            </div>

                            <div className={Classes.unitType}>
                                <p>{unitType}</p>
                            </div>
                            
                            <div>
                                {showErrorMsg?.length !== 0 && (
                                    <div>
                                        {showErrorMsg?.fieldName === 'bulkVal2' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg?.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={Classes.CommonMarginTop}>
                        <div style={{display:'flex'}}>
                            <div>
                                <input
                                    type='checkbox'	
                                    name='chargeTax'
                                    id='chargeTax'
                                    defaultChecked={true}
                                />
                            </div>
                            <div style={{padding: '3px 10px'}}>
                                <p>Charge tax on this product</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};


export default Pricing;
