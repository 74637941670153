import { useEffect, useState, Fragment } from 'react';
import Alert from 'react-bootstrap/Alert';
import CreatedByModal from '../orders/createdByFilter';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import Classes from '../orders/css/order.module.css';
import NewPagination from '../../../components/newPagination';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import { Paper, InputAdornment, TextField, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setInboundPurchaseOrders } from '../../../store/inboundPOSlice';

const InboundPurchaseOrders = (props) => {
	const [text, setText] = useState('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [createdBy, setCreatedBy] = useState([]);
	const [users, setUsers] = useState([]);
	const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });

	const navigate = useNavigate();
	const inboundPurchaseOrders = useSelector((state) => state.inboundPurchaseOrders);
	const pinTab = useSelector((state) => state.pinTab);
	const dispatch = useDispatch();

	const columnVisibilityModel = {
		locationName: false
	}
	const columns = [
		{ field: 'purchaseOrderName', filterable: false, headerName: 'ORDER', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'createdAt', filterable: false, headerName: 'DATE', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'vendorName', filterable: false, headerName: 'VENDOR', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: false },
		{ field: 'locationCode', filterable: true, headerName: 'Location Code', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'locationName', filterable: false, headerName: 'Location Name', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{
			field: 'paymentStatus',
			headerName: 'PAY STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			filterable: false,
			sortable: true,
			flex: 1.5,
			renderCell: (params) => (
				params.value === "Unpaid" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			)
		},
		{
			field: 'status',
			headerName: 'STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			filterable: false,
			sortable: true,
			flex: 1.5,
			renderCell: (params) => (
				params.value === "Completed" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			)
		},
		{ field: 'totalAmount', filterable: false, valueFormatter: (params) => `₹${params.value}`, headerName: 'TOTAL', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'createdBy', filterable: false, headerName: 'CREATED BY', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
	];

	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}

	const fetchOrders = async (type) => {
		setLoading(true);
		setError(null);
		let usersQuery = createdBy.join(',');

		const params = {
			searchFor: text,
			createdBy: usersQuery,
		};

		const page = type ? type : 1;
		await axiosInstance
			.get(`/purchaseOrders/purchaseOrders?page=${page}&sort=${JSON.stringify(sort)}`, {
				params,
			})
			.then((response) => {
				dispatch(setInboundPurchaseOrders(response.data.data));
			})
			.catch((error) => {
				console.log(error)
				setError(error.response.data.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};


	const handleUserSelect = async (selectedUsers) => {
		const admins = selectedUsers.map(user => user.name);
		let usersQuery = admins.join(',');
		setCreatedBy(admins);

		await axiosInstance
			.get(`/purchaseOrders/purchaseOrders?searchFor=${text}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setInboundPurchaseOrders(res.data.data));
			});
	};


	useEffect(() => {
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Inbound P.Os')) {
			const urlParams = new URLSearchParams(window.location.search);
			const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
			fetchOrders(page ? page : 1);
		}
	}, [text, sort]);

	const handleSearch = (e) => {
		setText(e.target.value);
	}


	return (
		<Fragment>
			<div className="mb-3" style={{ width: "98%",display:'flex',justifyContent:'flex-end' }}>
				<p>
					Purchase Orders: {(inboundPurchaseOrders?.page * 10) - 10}-{Math.min((inboundPurchaseOrders?.page * 10), inboundPurchaseOrders?.total)} of {inboundPurchaseOrders?.total}
				</p>
			</div>
			{error ? (
				<Alert variant="danger" onClose={() => setError(false)} dismissible>
					<Alert.Heading>{error}</Alert.Heading>
					<p>Try reloading!</p>
				</Alert>
			) : null}

			<form style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '15px' }}>
				<div className={Classes.SearchDraftOrder}>
					<TextField
						id="search"
						type="search"
						label="Search"
						value={text}
						onChange={(e) => handleSearch(e)}
						autoComplete='off'
						sx={{ width: '100%', "& label": { top: text ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<div className={Classes.CreatedByFilter}>
					<CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
				</div>
			</form>

			<div className="d-none d-sm-block">
				<Box className="shadow"
					component={Paper}
					sx={{
						'& .super-app-theme--header': {
							backgroundColor: '#243750',
							color: '#ffffff',
							cursor: "default",
						},
						width: '99.5%'
					}}>
					<DataGrid
						disableColumnFilter
						slots={{ toolbar: GridToolbar }}
						componentsProps={{
							toolbar: {
								csvOptions: { disableToolbarButton: true },
								printOptions: { disableToolbarButton: true },
							},
						}}
						rows={inboundPurchaseOrders?.purchaseOrders?.map((order) => ({
							purchaseOrderName: order?.purchaseOrderName,
							createdAt: createdAt(new Date(order?.createdAt)),
							vendorName: order?.vendorName ? order?.vendorName : 'N/A',
							locationName: order?.locationName ? order?.locationName : 'N/A',
							status: order?.status,
							createdBy: order?.createdBy,
							totalAmount: order?.totalAmount,
							paymentStatus: order?.paymentStatus,
							id: order?.purchaseOrderName,
							locationCode: order?.locationCode
						}))}
						columns={columns}
						filterMode="server"
						sortingMode="server"
						paginationMode="server"
						disableRowSelectionOnClick
						autoHeight
						autoPageSize
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "none !important",
							},
							cursor: "pointer",
							".MuiDataGrid-footerContainer": {
								display: "none !important",
							},
						}}
						onRowClick={(params) => { navigate(`${params?.id}`)}}
						initialState={{
							sorting : {
								sortModel: [{ field: 'createdAt', sort: 'desc' }],
							},
							columns: {
								columnVisibilityModel
							}
						}}
						onSortModelChange={(sort) => setSort(sort[0])}
					/>
				</Box>
			</div>

			<div className="d-block d-sm-none" style={{marginTop:'-25px'}}>
				<div className={Classes.ordersHeader}>
					<p className={Classes.OrderName}>
						Order
					</p>
					<p style={{ marginRight: '5px' }}>
						Amount
					</p>
				</div>

				{inboundPurchaseOrders?.purchaseOrders?.map((order) => (
					<div
						key={order?.orderName}
						style={{
							cursor: 'pointer',
							display: 'flex',
							borderBottom: '1px solid lightgray',
							fontSize: 13,
							padding: '10px  10px',
						}}
						onClick={() => {
							navigate(`${order?.purchaseOrderName}`);
						}}
					>
						<div className={Classes.orderName}>
							<h6 style={{ fontSize:14,margin:'auto 0px' }}>
								{order?.purchaseOrderName}
							</h6>

							<div>
								<div className={Classes.ResponsiveCreatedAt}>
									{createdAt(order?.createdAt)}
								</div>

								<div className={Classes.ResponsiveCustomerName}>
									{order?.vendorName ? order?.vendorName : 'N/A'}
								</div>
								<div className={Classes.ResponsiveCustomerName}>
									{order?.locationName ? order?.locationName : 'N/A'}
								</div>

								<div
									className={Classes.ResponsivePaymentStatus}
								>
									<p style={{ marginRight: 5, fontWeight: 600, color: '#72767d' }}>
										Payment Status:
									</p>
									<p
										className="px-2"
										style={{
											backgroundColor:
												order?.paymentStatus === 'paid' ?
													'#e4e5e7' :
													'#ffd79d',
											borderRadius: '1rem',
											width: 'fit-content',
										}}
									>
										{order?.paymentStatus}
									</p>
								</div>

								<div className={Classes.ResponsiveOrderStatus}>
									<p style={{ marginRight: 5, fontWeight: 600, color: '#72767d' }}>
										Order Status:
									</p>
									<p
										className="px-2"
										style={{
											backgroundColor:
												order?.orderStatus === 'delivered' ?
													'#e4e5e7' :
													'#ffea88',
											borderRadius: '1rem',
											width: 'fit-content',
										}}
									>
										{order?.status}
									</p>
								</div>
							</div>
						</div>
						<p style={{ marginRight: '6px' }}>&#8377;{order?.totalAmount}</p>
					</div>
				))}
			</div>

			{inboundPurchaseOrders?.purchaseOrders?.length > 0 ? (
				<div>
					<NewPagination totalPage={inboundPurchaseOrders} fetchData={fetchOrders} />
				</div>
			) : null}

			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default InboundPurchaseOrders;
