import {Fragment,useState} from 'react';
import Classes from '../css/aside.module.css';
import ContactPopUp from './contactPopUp';
import ShippingPopUp from './shippingAddressPopup';
import DefaultAdressPopUp from './defaultAddressPopUp';
import TagsPopup from './tagsPopup';

const Aside = (props) => {
	const {
		showCustomerProfile, 
		setCustomerProfile,
		address, 
		setAddress,
		orders,
		addressArray,
		setAddressArray
	} = props;

	const [contactModal,setContactModal] = useState(false);
	const [shippingModal,setShippingModal] = useState(false);
	const [defaultAddressModal,setDefaultAddressModal] = useState(false);
	const [tagsModal,setTagsModal] = useState(false);
	const [pincode, setPincode] = useState('');
    const [number, setNumber] = useState('');
	const [displayName, setDisplayName] = useState(showCustomerProfile.displayName);
    const [firstName, setFirstName] = useState(showCustomerProfile.firstName);
    const [lastName, setLastName] = useState(showCustomerProfile.lastName);
    const [businessName, setBusinessName] = useState(showCustomerProfile.businessName);
	
	const showContactPop = (e) => {
		setDisplayName(showCustomerProfile.displayName);
		setContactModal(true);
	}

	const hideContactPop = () => {
		setContactModal(false)
	}

	const showShippingPop = () => {
		setShippingModal(true);
	}

	const hideShippingPop = () => {
		setNumber('');
		setPincode('');
		setShippingModal(false);
	}

	const showDefaultAddressPopUp = () => {
		setDefaultAddressModal(true)
	}

	const hideDefaultAddressPopUp = () => {
		setDefaultAddressModal(false)
	}

	const showTagsPopup = () => {
		setTagsModal(true)
	}

	const hideTagsPopup = () => {
		setTagsModal(false)
	}

	return (
		<Fragment>
				<div className={Classes.Aside}>
					{showCustomerProfile?.length !== 0 ?	
						<> 
							<div id={showCustomerProfile?._id}  className={Classes.Box}>
								<div style={{borderBottom: '1px solid #DBDDE0'}}>
									<div className={Classes.ContainerBox}>
										<div className='mb-4'  style={{ display:'flex',justifyContent:'space-between'}}>
											<h6 style={{margin: 'auto 0px'}}>
												Customer
											</h6>

											<div>
												<p className={Classes.customerEditBtn} onClick={(e) => showContactPop(e)}>
													Edit
												</p>
											</div>
										</div>

										<div style={{ display:'flex',fontSize:14 }}>
											{showCustomerProfile?.displayName ? (
												<p>{showCustomerProfile?.displayName}</p>
											) : (
												<>
													<p style={{marginRight:5}}>
														{showCustomerProfile?.firstName}
													</p>

													{showCustomerProfile?.lastName !== 'undefined' 
														? ( <p>{showCustomerProfile?.lastName}</p> )
													: null}
												</>
											)}
										</div>
										
										<div className={Classes.customerDetail}>
											<p>{showCustomerProfile?.email}</p>
											<p style={{marginTop:5}}>{showCustomerProfile?.phone}</p>
										</div>
									</div>
								</div>
										
								{addressArray?.length !== 0 ? (
									<div style={{borderBottom: '1px solid #DBDDE0'}}>
										<div className={Classes.ContainerBox}>
											<div className={Classes.defaultAddress}>
												<p className={Classes.defaultAddressHeader}>
													DEFAULT ADDRESS
												</p>
												<p 
													className={Classes.customerEditBtn}
													onClick={showDefaultAddressPopUp}
												>
													Manage
												</p>
											</div>

											<div style={{fontSize:14}}>
												<div style={{display:'flex'}}>
													<p style={{marginRight:5}}>{addressArray[0]?.firstName}</p>
													{addressArray[0]?.lastName !== 'undefined' 
														? ( <p>{addressArray[0]?.lastName}</p> )
													: null}
												</div>
												
												<>
													<div>
														<p>{addressArray[0]?.address1}</p>
														{addressArray[0]?.address2 !== null 
															? ( <p>{addressArray[0]?.address2}</p> )
														: null}
													</div>

													<div style={{display:'flex'}}>
														<p style={{marginRight:5}}>
															{addressArray[0]?.zip},
														</p>	
														<p style={{marginRight:5}}>
															{addressArray[0]?.city},
														</p>
														<p style={{marginRight:5}}>
															{addressArray[0]?.province}
														</p>	
													</div>

													<div>
														<p>{addressArray[0]?.country}</p>
													</div>
												</>

												<div>
													<p>{addressArray[0]?.phone}</p>
												</div>
												<div>
													<p 
														className={Classes.addNewAddress}
														onClick={showShippingPop}
														style={{ marginTop:10 }}
													>
														Add new address
													</p>
												</div>
											</div>
										</div>
									</div>
								): (
									<div style={{ padding:'10px 15px' }}>
										<div style={{ display:'flex',justifyContent:'space-between' }}>
											<p className={Classes.defaultAddressHeader}>
												DEFAULT ADDRESS
											</p>
											<p className={Classes.addNewAddress} onClick={showShippingPop}>
												Add
											</p>
										</div>

										<div style={{ marginTop:10, fontSize:14,color:'#6d7175' }}>
											<p>No address provided</p>
										</div>
									</div>
								)}
							</div>

							<div className={Classes.Box} style={{ marginTop:10,padding:'20px 15px' }}>
								<div className={Classes.containerBox}>
									<div className={Classes.tagsBox}>
										<h6 className={Classes.tagsHeader}>
											Tags
										</h6>
										<div>
											<p onClick={showTagsPopup} className={Classes.tagsChangeBtn}>
												Change
											</p>
										</div>
									</div>
									
									<div className={Classes.searchContainer}>
										<div className={Classes.tagsInput}>
											<input
												type="text"
												id="search"
												className={Classes.searchField}
												autoComplete='off'
												placeholder='Find or create tags'
												value={props.text}
												onChange={(e) => props.setText(e.target.value)}
												style={{border:'0',padding:'5px', width:'100%'}}
											/>
										</div>
									</div>

									{orders?.length !== 0 && (
										<div>
											{orders[0]?.draftOrder?.tags?.length !== 0 && (
												<div className={Classes.tagsContentBox}>
													{orders[0]?.draftOrder?.tags?.map((tag,index) => (
														<p key={index} className={Classes.tagsContent}>
															{tag}
														</p>
													))}
												</div>
											)}
										</div>
									)}
									
								</div>
							</div>

							<div className={defaultAddressModal ? Classes.UpdateSalesDialog : undefined}>
								<DefaultAdressPopUp 
									showDefaultAddressPopUp={showDefaultAddressPopUp}
									hideDefaultAddressPopUp={hideDefaultAddressPopUp}
									defaultAddressModal={defaultAddressModal}
									setDefaultAddressModal={setDefaultAddressModal}
									showCustomerProfile={showCustomerProfile}
									addressArray={addressArray}
									setAddressArray={setAddressArray}
									address={address}
									setAddress={setAddress}
								/>
							</div>
						
							<div className={contactModal ? Classes.UpdateSalesDialog : undefined}>
								<ContactPopUp
									contactModal={contactModal}
									setContactModal={setContactModal}
									showContactPop={showContactPop}
									hideContactPop={hideContactPop}
									showCustomerProfile={showCustomerProfile}
									setCustomerProfile={setCustomerProfile}
									displayName={displayName}
									setDisplayName={setDisplayName}
									firstName={firstName}
									setFirstName={setFirstName}
									lastName={lastName}
									setLastName={setLastName}
									businessName={businessName}
									setBusinessName={setBusinessName}
								/>
							</div>

							<div className={shippingModal ? Classes.UpdateSalesDialog : undefined}>
								<ShippingPopUp
									shippingModal={shippingModal}
									setShippingModal={setShippingModal}
									showShippingPop={showShippingPop}
									hideShippingPop={hideShippingPop}
									showCustomerProfile={showCustomerProfile}
									setAddress={setAddress}
									addressArray={addressArray}
									setAddressArray={setAddressArray}
									pincode={pincode} 
									setPincode={setPincode}
									number={number}
									setNumber={setNumber}
								/>
							</div>

							<div className={tagsModal ? Classes.UpdateSalesDialog : undefined}>
								<TagsPopup 
									showTagsPopup={showTagsPopup}
									hideTagsPopup={hideTagsPopup}
									tagsModal={tagsModal}
									setTagsModal={setTagsModal}
								/>
							</div>
						</>
					: null }
                </div>

		</Fragment>
	)				
};

export default Aside;
