import React, {Fragment} from "react";
import Classes from './css/draftOrderDetailPage.module.css';


const TaxRatesPrint = (props)=> {
    const { draftOrder,taxRates } = props;

    const handleGst = (gst) => {
        return gst/2
    }

    const handleTaxAmount = (amount) => {
        return Math.floor((amount/2)*100)/100
    }

    const handleFirst2Decimal = (amount) => {
        return Math.floor(amount*100)/100
    }


    return (
        <Fragment>
            <div className={[Classes.Box,'box'].join(' ')}>
                <div className={Classes.TaxRatePrintHeader}>
                    <h6>Tax rates</h6>
                </div> 

                {draftOrder.length !== 0 && (
                    <div className={Classes.draftOrderModal}>
                        {taxRates?.length !== 0 ? (
                            <>
                                {taxRates.map((taxes,index) => (
                                    <div 
                                        style={{padding:'10px'}} 
                                        key={index}
                                    >
                                        <div>
                                            <p>
                                                HSN Code ({taxes?.hsnCode})
                                            </p>
                                        </div>
                                        <div>
                                            {draftOrder?.shippingAddress ?  (
                                                <div>
                                                    {draftOrder?.shippingAddress?.province === 'West Bengal' ? (
                                                        <>
                                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                                <p style={{padding:'0px 5px'}}>
                                                                    CGST({handleGst(taxes.gst)}%)
                                                                </p>
                                                                <p>&#8377;{handleTaxAmount(taxes.tax)}</p>
                                                            </div>

                                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                                <p style={{padding:'0px 5px'}}>
                                                                    SGST({handleGst(taxes.gst)}%)
                                                                </p>
                                                                <p>&#8377;{handleTaxAmount(taxes.tax)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ): (
                                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                                            <p style={{padding:'0px 5px'}}>
                                                                IGST({taxes.gst}%)
                                                            </p>
                                                            <p>&#8377;{handleFirst2Decimal(taxes.tax)}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                                        <p style={{padding:'0px 5px'}}>
                                                            CGST({handleGst(taxes.gst)}%)
                                                        </p>
                                                        <p>&#8377;{handleTaxAmount(taxes.tax)}</p>
                                                    </div>

                                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                                        <p style={{padding:'0px 5px'}}>
                                                            SGST({handleGst(taxes.gst)}%)
                                                        </p>
                                                        <p>&#8377;{handleTaxAmount(taxes.tax)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {draftOrder.taxLines !== undefined && draftOrder.taxLines.length !== 0 && (
                                    <>
                                        {draftOrder.taxLines.map((taxes,index) => (
                                            <div 
                                                style={{padding:'10px'}} 
                                                key={index}
                                            >
                                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                                    <p style={{padding:'0px 5px'}}>
                                                        {taxes?.title}({taxes?.ratePercentage}%)
                                                    </p>
                                                    <p>&#8377;{taxes?.price}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        )}

                        {draftOrder?.shippingLine && (
                            <>
                                {draftOrder?.shippingLine?.taxAmount && draftOrder?.shippingLine?.taxAmount !== 0 && (
                                    <div style={{padding:'10px'}}>
                                        <div>
                                            <p>Shipping HSN Code(9965)</p>
                                        </div>
                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                            <p style={{padding:'0px 5px'}}>GST(18%)</p>
                                            <p>&#8377;{draftOrder?.shippingLine?.taxAmount}</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        <div 
                            style={{
                                display:'flex',
                                justifyContent:'space-between',
                                padding: '0px 12px'
                            }}
                        >
                            <div>
                                <p>Total Tax</p>
                            </div>
                            <div>
                                <p>&#8377;{parseFloat(draftOrder?.totalTax).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>  
                )}
            </div>
        </Fragment>
    );
}


export default TaxRatesPrint;