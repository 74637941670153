import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props) {
  const { label, selectedOptions, setSelectedOptions, setSelectedOptionsAdv, selectedOptionsAdv, OptionValues } = props;
  const theme = useTheme();
  const handleChange = (event, obj) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const id = obj?.key.slice(2,)
    const foundOptionInExistingAdvOptions = selectedOptionsAdv.find((selectedOptionAdv) => { return selectedOptionAdv?.id === id })
    if (foundOptionInExistingAdvOptions) {
      // remove selected option on reselect
      setSelectedOptionsAdv && setSelectedOptionsAdv((prevSelectedOptionsAdv) => {
        return prevSelectedOptionsAdv.filter(function (prevSelectedOptionAdv) {
          return prevSelectedOptionAdv?.id !== id
        })
      })
    } else {
      // add selected option on first select
      const currentlySelectedValue = value[value?.length - 1]
      setSelectedOptionsAdv && setSelectedOptionsAdv((prevSelectedOptionsAdv) => { return [...prevSelectedOptionsAdv, { id, value: currentlySelectedValue }] })
    }
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          sx={{"& .MuiOutlinedInput-input":{padding:"8px"}}}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} sx={{height:'23px'}}/>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          size="small"
        >
          {OptionValues.map(({ value, id }) => (
            <MenuItem
              key={id}
              value={value}
              style={getStyles(value, selectedOptions, theme)}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}