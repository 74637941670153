import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faXmark, 
    faMagnifyingGlass, 
    faRightLeft
} from "@fortawesome/free-solid-svg-icons";
import Classes from "../css/Modal.module.css";


const TagsPopup = (props)=> {
    const {
        tagsModal,
        hideTagsPopup,
        setSelectedTag,
        showSelectedTags,
        setTags,
        tags
    } = props;

    const handleSubmit = () =>{
        hideTagsPopup();
        const selected = []

        const tagss = document.getElementsByClassName('checkBox');
        for (let i = 0; i < tagss.length; i++) {
            if(tagss[i].checked) {
                selected.push(tagss[i].value)
            }
        }
        setSelectedTag([...showSelectedTags, ...selected]);
        setTags([...tags, ...selected]);
    }

    return (
        <Fragment>
            {tagsModal && (
                <div 
                    className={Classes.modal} 
                    style={{bottom:'8%',top:'10%'}}
                >
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage tags</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideTagsPopup}
                                />
                            </div>
                        </div>
                    </div>
                    
                    {/* <form method='post' onSubmit={(e) => handleSubmit(e)}> */}
                        <div className={Classes.TagContainer}>
                            <div style={{display:'flex'}}>
                                <div className={Classes.TagContainerBox}>
                                    <div>
                                        <FontAwesomeIcon 
                                            icon={faMagnifyingGlass} 
                                            className={Classes.FaMagnifyingGlass}
                                        />
                                    </div>

                                    <div style={{width:'100%'}}>
                                        <input
                                            type="text"
                                            id="search"
                                            className={Classes.SearchTag}
                                            placeholder="Search to find and create tags"
                                        />
                                    </div>
                                </div>

                                <div 
                                    className={Classes.Search} 
                                    style={{marginLeft:'10px'}}
                                >
                                    <FontAwesomeIcon 
                                        icon={faRightLeft} 
                                        className={Classes.FaRightLeftIcon}
                                    />
                                        <button 
                                            type="submit" 
                                            className={Classes.FrequentlyUsedBtn}
                                        >
                                            Frequently used
                                        </button>
                                    </div>
                            </div>
                            
                            <div className={Classes.AvailableBox} id='tags'>
                                <div style={{color: 'rgb(104, 111, 123)'}}>
                                    <p style={{fontSize:'13px',fontWeight:'600'}}>
                                        AVAILABLE
                                    </p>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='RETAILER'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>RETAILER</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='SAMPLE100'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>SAMPLE100</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='SAMPLE'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>SAMPLE</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='XYZ-4'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>XYZ-4</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='GOAFFPRO'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>GOAFFPRO</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='ARCHITECT'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>ARCHITECT</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='COD'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>COD</p>
                                    </div>
                                </div>

                                <div className={Classes.Tag}>
                                    <div>
                                        <input
                                            type='checkbox'
                                            value='OFFER100'
                                            name="checkbox"
                                            className="checkBox"
                                        />
                                    </div>
                                    <div style={{marginLeft:'8px'}}>
                                        <p>OFFER100</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={ Classes.ButtonBox }>
                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.DoneButton}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Done
                            </button>
                        </div>
                    {/* </form> */}

                </div>
            )}
        </Fragment>
    );
}


export default TagsPopup;