import React, {Fragment, useEffect,useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../../utility/axios-instance';
import {faXmark,faCaretRight,faLeaf} from "@fortawesome/free-solid-svg-icons";


const CollectionsPopUp = (props)=> {
    const [collections,setCollections] = useState([])
    const {
        collectionModal,
        hideCollectionModal,
        setCollectionStore,
        storePrevCollectionId,
        setPrevCollectionId,
        productDetails,
        setTags,
        handleVinculum,
    } = props;


    const showL2 = (Id) => {
        const parent = document.getElementById(Id);
        const fetchChild = parent.parentNode.parentNode;
        const attrValue = parent.getAttribute('data-toggle');
        if(attrValue === 'false'){
            parent.setAttribute('data-toggle',true);
            fetchChild.children[1].style.display = 'block';
        }else{
            const arrL2 = fetchChild.children[1].children;
            for(let arr of arrL2){
                if(arr.children[1].style.display === 'block'){
                    arr.children[1].style.display = 'none';
                }
            }
            parent.setAttribute('data-toggle',false);
            fetchChild.children[1].style.display = 'none';
        }
    }

    const showL3 = (Id) => {
        const fetchChild = document.getElementById(Id);
        const attrVal = fetchChild.getAttribute('data-toggle');
        if(attrVal === 'false'){
            fetchChild.children[1].style.display = 'block';
            fetchChild.setAttribute('data-toggle',true);
        }else{
            fetchChild.children[1].style.display = 'none';
            fetchChild.setAttribute('data-toggle',false);
        }   
    }

    const handleCollection = (event,L2Id,L1Id) => {
        const array = [];
        array.push(productDetails[0].variable1Value);
        if(productDetails[0].variable2Value) {
            array.push(productDetails[0].variable2Value);
        }
        if(productDetails[0].variable3Value) {
            array.push(productDetails[0].variable3Value);
        }
        array.push(`${productDetails[0].gst}.0`);
        const fetchL1 = document.getElementById(L1Id);
        const l3Id = document.getElementById(event.target.id);
        if(storePrevCollectionId.length !== 0){
            const l3PrevId = document.getElementById(storePrevCollectionId[0]); 
            l3PrevId.style.backgroundColor = '';
            l3PrevId.style.color = 'black';
        }
        setPrevCollectionId([event.target.id])
        l3Id.style.backgroundColor = '#01a2d6ed';
        l3Id.style.color = 'white';
        const L1 = fetchL1.innerText;
        const fetchParent = document.getElementById(L2Id)
        const L2 = fetchParent.children[0].lastChild.innerText;
        const L3 = event.target.innerText;
        document.getElementById('collection3').value = L3;
        handleVinculum();
        const arr = [];
        arr.push(L1,L2,L3);
        array.push(L1,L2,L3);
        setTags(array);
        setCollectionStore(arr);
    }
   
    useEffect(() => {
        axiosInstance
        // .get('/others/l1s')
        //:: NEW FEATURE
        .get('/others/getAll-l1s')
        .then((res) => {
            setCollections(res.data.data)
        })
    },[])

    return (
        <Fragment>
            {collectionModal && (
                <div 
                    className={Classes.modal} 
                    style={{bottom:'13%',top:'4%'}}
                >
                    {/* <div className={Classes.Box}> */}
                        <div className={Classes.BoxContent}>
                            <p>Categories Tree</p>
                            {/* <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideCollectionModal}
                                />
                            </div> */}
                        </div>
                    {/* </div> */}

                    <div style={{ padding:'10px 20px',overflowY:'auto',height:400 }}>
                        {collections.length !== 0 && (
                            <div>
                                {
                                    collections.map( (l1Collections) =>(
                                    <div key={l1Collections._id}
                                    style={{marginTop:'10px'}}>
                                        <div 
                                            style={{ display:'flex',cursor: "pointer" }}
                                            onClick={() =>showL2(l1Collections._id)}
                                        >
                                            <FontAwesomeIcon 
                                                icon={faCaretRight} 
                                                style={{fontSize: '15px',
                                                    color: 'rgb(129, 138, 155)',
                                                    margin: '5px 6px 0px 0px'
                                                }}
                                                data-toggle={false}
                                            />
                                            <h6 
                                                id={l1Collections._id}
                                                data-toggle={false}
                                            >
                                                {l1Collections.title}
                                            </h6>
                                        </div>

                                        {l1Collections.l2s.length !== 0  && (
                                            <div className={Classes.l2Collections} id='collection2'>
                                                {l1Collections.l2s.map((l2Collections) => (
                                                    <div 
                                                        key={l2Collections._id} 
                                                        id={l2Collections._id}
                                                        data-toggle={false}
                                                    >
                                                        <div 
                                                            style={{ display:'flex',cursor: "pointer" }}
                                                            onClick={()=>showL3(l2Collections._id)}
                                                        >
                                                            <FontAwesomeIcon 
                                                                icon={faCaretRight} 
                                                                style={{
                                                                    fontSize: '15px',
                                                                    color: 'rgb(129, 138, 155)',
                                                                    margin: '4px 6px 0px 0px'
                                                                }}
                                                                data-toggle={false}
                                                            />
                                                            <p>{l2Collections.title}</p>
                                                        </div>

                                                        {l2Collections.l3s.length !== 0 && (
                                                            <div className={Classes.l3Collection}>
                                                                {l2Collections.l3s.map((l3Collection,index) => (
                                                                    <div key={index}>
                                                                        {l3Collection._id && (
                                                                            <div style={{display:'flex',width:'fit-content'}}>
                                                                                <FontAwesomeIcon 
                                                                                icon={faLeaf} 
                                                                                style={{fontSize: '14px',
                                                                                    // color: 'rgb(129, 138, 155)',
                                                                                    margin: '5px 6px 0px 0px'
                                                                                }}
                                                                            />
                                                                            <p 
                                                                                id={l3Collection._id} 
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    padding: "0px 5px",
                                                                                    borderRadius: 4
                                                                                }} 
                                                                                onClick={(e) => 
                                                                                    handleCollection(
                                                                                        e,
                                                                                        l2Collections._id,
                                                                                        l1Collections._id
                                                                                    )}
                                                                            >{l3Collection.title}</p>
                                                                        </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                </div>
                                     ) )
                                }
                            </div>
                        
                        )}
                    </div>

                    <div 
                        className={Classes.Button} 
                        style={{ display:'flex',justifyContent:'flex-end',padding:'15px 20px'}}
                    >
                        <button 
                            id='btn'
                            onClick={hideCollectionModal}
                            style={{
                                backgroundColor:'#1976d2',color:'#fff',fontSize:15,
                                padding:'6px 15px',border:0,marginRight:10
                            }}
                        >
                            Cancel
                        </button>

                        <button 
                            id='btn'
                            onClick={hideCollectionModal}
                            style={{
                                backgroundColor:'#1976d2',color:'#fff',fontSize:15,
                                padding: '6px 15px',border:0
                            }}
                        >
                            Add
                        </button>
                    </div>

                </div>
            )}
        </Fragment>
    );
}


export default CollectionsPopUp;