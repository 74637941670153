import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Classes from '../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import Button from '@mui/material/Button';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const { dataPosition, endpoint, handleClickOpen } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2
        }
    }

    useEffect(() => {
        fetchData();
    },[searchFor]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length !== 0 && data?.map((customer) => (
                <div key={customer?._id} className={Classes.Box}>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Customer:</p>
                        <p>
                            {customer?.displayName ? customer?.displayName :
                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}
                        </p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Phone:</p>
                        <p>{customer?.phone}</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Role:</p>
                        <p>{capitalizeState(customer?.typeOfRole)}</p>
                    </div>

                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Sales User:</p>
                        <p>{customer?.salesUser ? customer?.salesUser?.name : "N/A"}</p>
                    </div>

                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Pre-Sales User:</p>
                        <p>{customer?.preSalesUser ? customer?.preSalesUser?.name : "N/A"}</p>
                    </div>

                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Created At:</p>
                        <p>{customer?.createdAt ? moment(customer?.createdAt).format('MMM DD, YYYY h:mm:ss A') : "N/A"}</p>
                    </div>

                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>Delete:</p>
                        <p> 
                            <Permission service={services?.affiliates} permission={"delete"}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ textTransform:'capitalize' }}
                                    style={{ padding:'2px 10px',fontSize:14,backgroundColor:'#EE5C55' }}
                                    onClick={() => handleClickOpen(customer)}
                                >
                                    Delete
                                </Button>
                            </Permission>
                        </p>
                    </div>
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;