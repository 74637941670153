const years = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const handleCreatedAt = (createdAt) => {
  const dn = new Date();
  let ca = createdAt
    ? createdAt.getFullYear() === dn.getFullYear()
      ? createdAt.getMonth() === dn.getMonth()
        ? createdAt.getDate() === dn.getDate()
          ? `Today at ${createdAt.toLocaleTimeString()}`
          : createdAt.getDate() === dn.getDate() - 1
          ? `Yesterday at ${createdAt.toLocaleTimeString()}`
          : years[createdAt.getMonth()] +
            " " +
            createdAt.getDate() +
            " at " +
            createdAt.toLocaleTimeString()
        : years[createdAt.getMonth()] +
          " " +
          createdAt.getDate() +
          " at " +
          createdAt.toLocaleTimeString()
      : years[createdAt.getMonth()] + " " + createdAt.getFullYear()
    : null;
  return ca;
};

export { handleCreatedAt };
