import { useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from './css/draftOrder.module.css';
import NewPagination from '../../../components/newPagination';
import CreatedByModal from '../orders/createdByFilter';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { Paper, InputAdornment, TextField, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridToolbar } from '@mui/x-data-grid';
import { downloadCSV } from "../../../utility/downloadCSV";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Spinner from '../../../components/spinner';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setDraftOrders } from '../../../store/draftOrdersSlice';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const DraftOrders = () => {
	const [text, setText] = useState('');
	const [searchOrderPage, setOrderPage] = useState(1);
	const [createdBy, setCreatedBy] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

	const draftOrders = useSelector((state) => state.draftOrders);
	const pinTab = useSelector((state) => state.pinTab);
	const dispatch = useDispatch();

	const handleUserSelect = async (selectedUsers) => {
		setLoading(true);
		const admins = selectedUsers.map(user => user.name);
		let usersQuery = admins.join(',');
		setCreatedBy(admins);

		await axiosInstance
			.get(`/orders/admin/draftOrders?text=${text}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setDraftOrders(res.data.data));
			});
		setLoading(false);
	};

	const fetchOrders = async (type) => {
		setLoading(true);
		let usersQuery = createdBy.join(',');
		const page = type;

		await axiosInstance
			.get(`/orders/admin/draftOrders?page=${page}&text=${text}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setDraftOrders(res.data.data));
				setLoading(false);
			});
	};


	useEffect(() => {
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Draft Order')) {
			const urlParams = new URLSearchParams(window.location.search);
			const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
			fetchOrders(page ? page : 1);
		}
	}, [])

	const fetchDraftOrder = (e) => {
		setText(e.target.value);
		let usersQuery = createdBy.join(',');
		axiosInstance
			.get(`/orders/admin/draftOrders?page=${searchOrderPage}&text=${e.target.value}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setDraftOrders(res.data.data));
			})
	}

	const columns = [
		{ field: 'draftOrder', filterable: false, headerName: 'DRAFT ORDER', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
		{ field: 'createdAt', filterable: false, headerName: 'DATE', flex: 1.7, headerClassName: 'super-app-theme--header', sortable: false },
		{ field: 'createdVia', filterable: false, headerName: 'Platform', flex: 1.7, headerClassName: 'super-app-theme--header', sortable: false },
		{ field: 'customer', filterable: false, headerName: 'CUSTOMER', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: false },
		{ field: 'total', filterable: false, valueFormatter: (params) => `₹${params.value}`, headerName: 'TOTAL', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
		{ field: 'createdBy', filterable: false, headerName: 'CREATED BY', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
	];

	if (document.querySelector('.MuiDataGrid-footerContainer')) {
		document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
	}

	const handleExport = (event) => {
		setLoading(true);
		event.preventDefault();

		axiosInstance
			.get('/exports/draftOrders')
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data?.exportedData
				downloadCSV(arrayOfRowObjects, `Orders export - ${new Date()}`);
				setLoading(false);
				const successMessage = "Draft Orders Exported Successfully!"
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
				setLoading(false);
			})
	}

	const handleSnackBarClose = () => {
		setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: false }
		});
	};


	return (
		<Fragment>
			<div style={{ display:'flex',justifyContent:'end',width:"99.6%",marginTop:"-25px",marginBottom:10 }}>
				<Button
					id='exportbtn'
					variant="contained" color="success"
					onClick={handleExport}
					startIcon={<FileDownloadIcon />}
				>
					Export
				</Button>
			</div>

			<Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
				<Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
					{snackBar?.message}
				</Alert>
			</Snackbar>

			<form style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '15px', width: '99.5%' }}>
				<div className={Classes.SearchDraftOrder}>
					<TextField
						id="search"
						type="search"
						label="Search"
						value={text}
						onChange={fetchDraftOrder}
						autoComplete='off'
						sx={{ width: '99%', "& label": { top: text ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<div className={Classes.CreatedByFilter}>
					<CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
				</div>
			</form>
			{draftOrders?.orders?.length !== 0 && (
				<div className="d-none d-sm-block">
					<Box className="shadow"
						component={Paper}
						sx={{
							'& .super-app-theme--header': {
								backgroundColor: '#243750',
								color: '#ffffff',
								cursor: "default",
							},
							width: '99.5%',
						}}>
						<DataGrid
							disableColumnFilter
							slots={{ toolbar: GridToolbar }}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
								},
							}}
							rows={draftOrders?.orders?.map((order) => ({
								draftOrder: order?.draftOrderName ? order?.draftOrderName : order?.draftOrder?.name,
								createdAt: moment(order?.createdAt).format('MMM DD, YYYY h:mm:ss A'),
								createdVia: order?.createdVia || "N/A",
								customer: order?.user ? (
									order?.user?.displayName ?
										order?.user?.displayName :
										`${order?.user?.firstName} ${order?.user?.lastName && order?.user?.lastName}`
								) : (
									"N/A"
								),
								createdBy: order?.createdBy !== undefined ? order?.createdBy?.name : null,
								total: order?.totalPrice ? Math.round(order?.totalPrice) : Math.round(order?.draftOrder?.totalPrice),
								id: order?._id,
							}))}
							columns={columns}
							filterMode="server"
							sortingMode="server"
							paginationMode="server"
							disableRowSelectionOnClick
							autoHeight
							autoPageSize
							initialState={{
								columns: {
									columnVisibilityModel: {
										createdVia: false,
									},
								},
								pagination: {
									paginationModel: { pageSize: 10, page: 0 },
								},
							}}
							sx={{
								"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
									outline: "none !important",
								},
								cursor: "pointer",
							}}
							onRowClick={(params) => {
								navigate(`${params?.id}`);
							}}
						/>
					</Box>
				</div>
			)}

			<div className="d-block d-sm-none">
				<div
					style={{
						display: 'flex',
						padding: '8px 12px 5px',
						// borderBottom: '2px solid black',
						backgroundColor: '#243750',
						color: '#fff'
					}}
				>
					<h6 className={Classes.Column}>
						Draft Order
					</h6>
					<h6 className={Classes.DateColumn}>
						Date
					</h6>
					<h6 className={Classes.CustomerColumn}>
						Customer
					</h6>
					<h6 className={Classes.CreatedBy}>
						Created By
					</h6>
					<h6 style={{ marginLeft: 5 }}>
						Total
					</h6>
				</div>


				{draftOrders?.orders?.length !== 0 ? (
					<div style={{ fontSize: '14px' }}>
						{draftOrders?.orders?.map((order, index) => (
							<div key={index} className={Classes.Row} onClick={() => navigate(order?._id)}>
								<div className={Classes.Column}>
									<h6>{order?.draftOrderName ? order?.draftOrderName : order?.draftOrder?.name}</h6>

									<div className={Classes.Responsive}>
										<p>{moment(order?.createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
										<div style={{display:'flex'}}>
											<p className='text-muted d-block d-md-none'>Customer:</p>
											<p style={{ paddingLeft:5 }}>
												{order?.user && (
													<>{order?.user?.firstName}{" "} 
													{order?.user?.lastName && order?.user?.lastName}</> 
												)}
											</p>
										</div>
									</div>

									<div style={{display:'flex'}}>
										<p className='text-muted d-block d-md-none'>Created By:</p>
										<p style={{ paddingLeft:5 }}>
											{order?.createdBy ? ( <>{order?.createdBy?.name}</> ) : "N/A"}
										</p>
									</div>
								</div>

								<p className={Classes.TotalPrice}>
									&#8377;
									<>{order?.totalPrice ? Math.round(order?.totalPrice) : Math.round(order?.draftOrder?.totalPrice)}</>
								</p>
							</div>
						))}
					</div>
				) : (
					<p style={{ position:'absolute',left:'41%',color:'red',marginTop:'10%' }}>
						No Draft Orders Exist
					</p>
				)}
			</div>

			{loading && ( <Spinner /> )}

			{draftOrders?.orders?.length > 0 && (
				<div style={{ margin: '10px auto 0px' }}>
					<NewPagination totalPage={draftOrders} fetchData={fetchOrders} />
				</div>
			)}

		</Fragment>
	);
};

export default DraftOrders;
