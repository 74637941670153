import React, {Fragment, useState, useEffect } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';


const UpdateShippingPopUp = (props)=> {
    const [states,setStates] = useState([]);
    const [loading,setLoading] = useState(false);

    const {
        updateShippingModal,
        customerIDAddress,
        hideUpdateShippingPop,
        showCustomerDetail,
        setCustomerIDAddress,
        addressType,
        addressArray,
        setAddressArray
    } = props;

    const [selectedState, setSelectedState] = useState('');

    async function handlePincodeChange(event) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${enteredPincode}`);
            const data = await response.json();

            if (data[0]?.Status === 'Success') {
                const state = document.getElementById('states');
                const extractedState = data[0]?.PostOffice[0]?.State;
                state.value = extractedState;
            }
        } catch (err) {
            console.log(err);
        }
    }

    function handleStateChange(event) {
        setSelectedState(event.target.value);
    }



    const handleFormSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        hideUpdateShippingPop();

        axiosInstance
            .post(`/user/admin/${showCustomerDetail[0]._id}/address`, {
                address1: event.target.address1.value,
                address2: event.target.address2.value,
                city: event.target.city.value,
                province: event.target.state.value,
                zip: event.target.pincode.value,
                country: event.target.country.value,
                firstName: event.target.first.value,
                lastName: event.target.last.value ? event.target.last.value : undefined,
                phone: `+91${event.target.phone.value}`,
                addressType: addressType
            })
            .then((res) => {
                setLoading(false);
                setAddressArray([...addressArray, res.data.data]);
                setCustomerIDAddress([res.data.data])
                setSelectedState('');
            });
    }

    const handleMinLengthPinCodes = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                document.getElementById('pinErrMsg1').style.display = 'none';
                document.getElementById('updateAddress').disabled = false;
            } else {
                document.getElementById('updateAddress').disabled = true;
                document.getElementById('pinErrMsg1').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsg1').style.display = 'none';
            document.getElementById('updateAddress').disabled = false;
        }
    }

    const handleMinLengthPhoneNumber = (event) => {
        if (event.targe.value) {
            const phoneNo = /^\d{10}$/;
            if (event.target.value.match(phoneNo)) {
                document.getElementById('phoneErrMsg1').style.display = 'none';
                document.getElementById('updateAddress').disabled = false;
            } else {
                document.getElementById('updateAddress').disabled = true;
                document.getElementById('phoneErrMsg1').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg1').style.display = 'none';
            document.getElementById('updateAddress').disabled = false;
        }
    }

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setStates(states)
            })
    },[])

    return (
        <Fragment>
            {updateShippingModal && (
                <div className={Classes.modal} style={{ bottom: '11%', top: '3%', zIndex:'9999' }}>
                    <div className={Classes.BoxContent}>
                        <p>Edit Shipping Address</p>
                    </div>

                    <form method="post" onSubmit={handleFormSubmit} id='form'>
                        <div className={Classes.AddressPopUp}>
                            <div style={{ marginTop: '10px' }}>
                                <TextField 
                                    id='country'
                                    name='country'
                                    label='Country / Origin'
                                    value='India'
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top:"0%",fontSize:14},"& .MuiOutlinedInput-input":{padding:'6px 10px'}}}
                                    disabled={true}
                                />
                            </div>

                            <div style={{display:'flex', marginTop:10,justifyContent:'space-between'}}>
                                <div style={{width:'48%'}}>
                                    <TextField 
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        autoComplete='off'
                                        key={customerIDAddress[0]?.firstName}
                                        defaultValue={customerIDAddress[0]?.firstName}
                                        placeholder='Enter Your First Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField 
                                        id='last'
                                        name='last'
                                        label='Last Name'
                                        autoComplete='off'
                                        key={customerIDAddress[0]?.lastName}
                                        defaultValue={customerIDAddress[0]?.lastName}
                                        placeholder='Enter Your Last Name'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>


                            <div style={{marginTop:'10px'}}>
                                <TextField 
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    key={customerIDAddress[0]?.address1}
                                    defaultValue={customerIDAddress[0]?.address1}
                                    placeholder='Apartment,suite,etc'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    key={customerIDAddress[0]?.address2}
                                    defaultValue={customerIDAddress[0]?.address2}
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                                <div style={{width:'48%'}}>
                                    <TextField 
                                        required={true}
                                        id='city'
                                        name='city'
                                        label='City'
                                        key={customerIDAddress[0]?.city}
                                        defaultValue={customerIDAddress[0]?.city}
                                        autoComplete='off'
                                        placeholder='Enter Your City'
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            
                                <div style={{width:'48%'}}>
                                    <TextField 
                                        type="number"
                                        required={true}
                                        id='pincode'
                                        name='pincode'
                                        label='Pincode'
                                        autoComplete='off'
                                        placeholder='Enter Your Pincode'
                                        maxLength={6}
                                        sx={{ width: '100%'}}
                                        key={customerIDAddress[0]?.zip}
                                        defaultValue={customerIDAddress[0]?.zip}
                                        onBlur={(e) => handleMinLengthPinCodes(e)}
                                        onChange={handlePincodeChange}
                                    />

                                    <div 
                                        className={Classes.PhoneErrorMsg} 
                                        id='pinErrMsg1' 
                                        style={{ fontSize:'14px' }}
                                    >
                                        <p>Please enter a valid 6 digit pincode</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                                <div style={{width: '48%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="state"
                                        name='state'
                                        options={states}
                                        defaultValue={customerIDAddress[0]?.province}
                                        key={customerIDAddress[0]?.province}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{ width: '100%' }}
                                        onChange={(event, newValue) => {setSelectedState(newValue.id)}}
                                        renderInput={(params) => <TextField {...params} label="States" required/>}
                                    />
                                </div>

                                <div style={{width: '48%'}}>
                                    <TextField 
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%'}}
                                        key={customerIDAddress[0]?.phone?.slice(3)}
                                        defaultValue={customerIDAddress[0]?.phone?.slice(3)}
                                        onBlur={(e) => handleMinLengthPhoneNumber(e)}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='phoneErrMsg1'
                                        style={{ fontSize: '15px' }}
                                    >
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={Classes.DialogBtnBox} style={{bottom: "10%"}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideUpdateShippingPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='updateAddress' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>

                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default UpdateShippingPopUp;