import React, { Fragment, useState, useEffect } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import AutoCompleteValueComponent from '../../../../components/autoCompleteValue';
import Spinner from '../../../../components/spinner';

const ContactPopUp = (props) => {
    const [loading,setLoading] = useState(false);
    const [salesTeam,setSalesTeam] = useState([]);
    const [salesUser,setSalesUser] = useState('');
    const [preSalesUser,setPreSalesUser] = useState('');
    const [states,setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');

    const {
        contactModal,
        hideContactPop,
        showCustomerProfile,
        setCustomerProfile,
        displayName,
        setDisplayName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        businessName,
        setBusinessName,
    } = props;

    // const handleNumChange = event => {
    //     const limit = 10;
    //     setNumber(event.target.value.slice(0, limit));
    // };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value);
    };

    useEffect(() => {
        if (businessName !== "") {
        setDisplayName(businessName);
        } else {
        setDisplayName(`${firstName} ${lastName}`);
        }
    }, [firstName, lastName, businessName]);

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setStates(states)
            })
    },[])

    useEffect(() => {
        axiosInstance
            .get('/admin/salesTeam')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data?.users) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setSalesTeam(arr);
            })
    },[]);

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        hideContactPop();

        const roles = {
            "Retailer": "retailer",
            "Special Retailer": "special_retailer",
            "Interior": "interior",
            "Oem": "oem",
            "End User": "end_user",
            "No Role": "remove"
        }
        console.log("selectedState ",selectedState);
        const data = {
            firstName: event.target.first.value,
            lastName: event.target.lastName.value ? event.target.lastName.value : undefined,
            email: event.target.email.value,
            customerRole: roles[event.target.role.value],
            customerType: event.target.customerType.value,
            businessName: event.target.businessName.value ? event.target.businessName.value : undefined,
            salesUser: salesUser ? salesUser : undefined,
            preSalesUser: preSalesUser ? preSalesUser: undefined,
            state : selectedState ? selectedState : undefined
        };

        axiosInstance
            .post(`/user/admin/${showCustomerProfile?._id}`, { ...data })
            .then((res) => {
                setLoading(false);
                setCustomerProfile(res.data.data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
    };

    const capitalName = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const roles = ["Retailer","Special Retailer","Interior","Oem","End User","No Role"];

    return (
        <Fragment>
            {contactModal && (
                <div className={[Classes.modal, Classes.contactPopUp].join(" ")}>
                    <div className={Classes.BoxContent}>
                        <p>Update Contact Details</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id="form">
                        <div style={{ padding: "30px 20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width:"30%" }}>
                                    <TextField 
                                        required={true}
                                        id='first'
                                        name='first'
                                        label='First Name'
                                        placeholder='Enter First Name'
                                        key={showCustomerProfile?.firstName}
                                        defaultValue={showCustomerProfile?.firstName}
                                        autoComplete='off'
                                        sx={{ width: '100%'}}
                                        onChange={handleFirstNameChange}
                                    />
                                </div>
                                <div style={{ width: "30%" }}>
                                    <TextField 
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        placeholder='Enter Last Name'
                                        key={showCustomerProfile?.lastName}
                                        defaultValue={showCustomerProfile?.lastName}
                                        autoComplete='off'
                                        sx={{ width: '100%'}}
                                        onChange={handleLastNameChange}
                                    />
                                </div>
                                <div style={{ width: "38%" }}>
                                    <TextField
                                        id='displayName'
                                        name='displayName'
                                        label='Display Name'
                                        placeholder='Enter Display Name'
                                        key={displayName}
                                        defaultValue={displayName}
                                        disabled={true}
                                        sx={{ width: '100%'}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <TextField
                                        required={true}
                                        id='email'
                                        name='email'
                                        label='Email'
                                        placeholder='Enter Email'
                                        key={showCustomerProfile?.email}
                                        defaultValue={showCustomerProfile?.email}
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width:"32%" }}>
                                    <TextField
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        placeholder='Enter Your Phone No.'
                                        key={showCustomerProfile?.phone?.slice(3)}
                                        defaultValue={showCustomerProfile?.phone?.slice(3)}
                                        disabled={true}
                                        sx={{ width: '100%'}}
                                    />
                                </div>

                                <div style={{ width:"33%" }}>
                                    <TextField 
                                        id='businessName'
                                        name='businessName'
                                        label='Business Name'
                                        placeholder='Enter Business Name'
                                        key={showCustomerProfile?.businessName}
                                        defaultValue={showCustomerProfile?.businessName}
                                        autoComplete='off'
                                        sx={{ width: '100%'}}
                                        onChange={handleBusinessName}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <AutoCompleteValueComponent 
                                        fieldName={'Customer Type'}
                                        name={'customerType'}
                                        options={["B2B", "B2C"]}
                                        value={showCustomerProfile?.customerType}
                                    />
                                </div>

                                <div style={{ width: "32%" }}>
                                    <AutoCompleteValueComponent 
                                        fieldName={'Customer Role'}
                                        name={'role'}
                                        options={roles}
                                        value={showCustomerProfile?.customerRole ? capitalName(showCustomerProfile?.customerRole): ''}
                                    />
                                </div>

                                <div style={{ width: "33%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="salesUser"
                                        options={salesTeam}
                                        key={showCustomerProfile?.salesUser ? showCustomerProfile?.salesUser?.name : ''}
                                        defaultValue={showCustomerProfile?.salesUser ? showCustomerProfile?.salesUser?.name : ''}
                                        sx={{width: '100%'}}
                                        onChange={(event, newValue) => { setSalesUser(newValue.Id);}}
                                        renderInput={(params) => <TextField {...params} label="Sales User"/>}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: "32%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="preSalesUser"
                                        options={salesTeam}
                                        key={showCustomerProfile?.preSalesUser ? showCustomerProfile?.preSalesUser?.name : ''}
                                        defaultValue={showCustomerProfile?.preSalesUser ? showCustomerProfile?.preSalesUser?.name : ''}
                                        sx={{width: '100%'}}
                                        onChange={(event, newValue) => { setPreSalesUser(newValue.Id);}}
                                        renderInput={(params) => <TextField {...params} label="Pre-Sales User"/>}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        name='state'
                                        options={states}
                                        defaultValue={showCustomerProfile?.state}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{
                                            width: '100%',
                                            "& label": {top: selectedState ? "0%": "-16%",fontSize:14},
                                            "& .MuiInputBase-input": {height:'15px'},
                                            "& .MuiOutlinedInput-root":{padding:'12px 10px'},
                                            "& .MuiAutocomplete-input" : {fontSize:14}
                                        }}
                                        onChange={(event, newValue) => {setSelectedState(newValue.id)}}
                                        renderInput={(params) => <TextField {...params} label="States"/>}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'13%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default ContactPopUp;
