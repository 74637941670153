import React, {Fragment} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';

const DefaultBillingAddressPopUp = (props)=> {
    
    const {
        defaultBillingAddressModal,
        hideDefaultBillingAddressPop,
        showCustomerDetail,
        addressArray,
        setBillingAddress,
        previousTarget,
        setPreviousTarget,
        BillingAddress
    } = props;

    const handleDefaultBillingAddress =(event,addressId) => {
        if(previousTarget.length !== 0){
            document.getElementById(previousTarget).style.background = 'rgb(250, 251, 251)';
            document.getElementById(previousTarget).style.color = '#000';
        }
        setPreviousTarget([event.target.id])
  
        event.target.style.background= '#008060';
        event.target.style.color = '#fff';
        setBillingAddress([addressArray.find(obj => obj.vendorAddressesId === addressId)]);
        hideDefaultBillingAddressPop();
        
    }

    const capitalizeState = (text) => {
        const arr = text.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
		return str2;
	}

    return (
        <Fragment>
            {defaultBillingAddressModal && (
                <div className={Classes.modal} style={{bottom:'6%'}}>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage address</p>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    style={{fontSize: '25px',
                                        color: 'rgb(129, 138, 155)',
                                        cursor: "pointer"
                                    }}
                                    onClick={hideDefaultBillingAddressPop}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div style={{overflowY: 'auto',height: '512px'}}>
                        <div style={{marginTop:'10px',padding:'10px 20px'}}>
                            <div>
                                <p style={{fontWeight:'600',fontSize:'14px'}}>
                                    SELECT ADDRESS 
                                </p>
                            </div>
                        </div> 
                        {showCustomerDetail.length !== 0 
                            ? <div>
                                {addressArray.map((address,index) => (
                                <div key={index} style={{borderBottom: '1px solid #ced4da',padding: '20px'}}>
                                        <div>
                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                <div style={{display:'flex'}}>
                                                    <p style={{marginRight:'5px'}}>{address.firstName}</p>
                                                    {address.lastName !== 'undefined' 
                                                        ? <p>{address.lastName}</p>
                                                        : ''
                                                    }
                                                </div>
                                                <div>
                                                    <p>Address Type</p>
                                                    <p 
                                                        className={Classes.AddressType}
                                                        style={{backgroundColor: address.addressType === 'Shipping' ? 'turquoise': 'greenyellow'}}
                                                    >{address.addressType}</p>
                                                </div>
                                            </div>

                                            <div style={{marginTop:'-20px'}}>
                                                <p>{address.addressLine1}</p>
                                                {address.addressLine2 !== null 
                                                    ? <p>{address.addressLine2}</p>
                                                : ''}
                                            </div>
                                            <div style={{display:'flex'}}>
                                                <p style={{marginRight:'5px'}}>{address.pincode},</p>
                                                <p style={{marginRight:'5px'}}>{address.city}</p>
                                                <p>{capitalizeState(address.state)}</p>
                                            </div>
                                            <div>
                                                <p>{address.country}</p>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                                <p>{address.phone}</p>
                                                {BillingAddress.length !== 0 ? (
                                                    <div>
                                                        {BillingAddress[0].vendorAddressesId === address.vendorAddressesId? (
                                                            <div>
                                                                <button id={address._id} style={{
                                                                    background: '#008060',
                                                                    border: 0,
                                                                    padding: '5px 15px',
                                                                    borderRadius: '4px',
                                                                    color: '#fff',
                                                                }} 
                                                                disabled={true}
                                                                >Selected address</button>
                                                            </div>
                                                        ):(
                                                            <div>
                                                                <button id={address._id} 
                                                                    style={{
                                                                        background: '#fafbfb',
                                                                        border: '1px solid #d2d5d8',
                                                                        padding: '5px 15px',
                                                                        borderRadius: '4px',
                                                                        color: '#000'
                                                                    }} 
                                                                onClick={(e) => handleDefaultBillingAddress(e,address.vendorAddressesId)}>Select address</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                ):(
                                                    <div>
                                                        <button id={address._id} 
                                                            style={{
                                                                background: '#fafbfb',
                                                                border: '1px solid #d2d5d8',
                                                                padding: '5px 15px',
                                                                borderRadius: '4px',
                                                                color: '#000'
                                                            }} 
                                                        onClick={(e) => handleDefaultBillingAddress(e,address.vendorAddressesId)}>Select address</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        : ''}
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DefaultBillingAddressPopUp;