import { Fragment } from 'react';
import { snakeToTitleCase } from '../../../utility/textFormatting';
import Classes from './css/orders.module.css';

const NewCustomerDetails = (props) => {
    const { draftOrder } = props;

    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2;
        }
    }

    return (
        <Fragment>
            <div style={{width:'100%',marginBottom:20}}>
                <div style={{ width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                    <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>Customer Details</p>
                    <div style={{display:'flex',justifyContent:'space-between',padding:'15px',flexWrap:'wrap'}}>
                        <div className={Classes.CommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Customer:</p>
                            <p>{draftOrder?.OCustomerDetails[0]?.displayName}</p>
                        </div>

                        {draftOrder?.OCustomerDetails[0]?.gstNumber?.length !== 0 && (
                            <div className={Classes.CommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>GST Number:</p>
                                <p>{draftOrder?.OCustomerDetails[0]?.gstNumber[0]}</p>
                            </div>
                        )}

                        {draftOrder?.OCustomerDetails[0]?.role && (
                            <div className={Classes.CustomerRole}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Role:</p>
                                <p>{snakeToTitleCase(draftOrder?.OCustomerDetails[0]?.role)}</p>
                            </div>
                        )}

                        <div className={Classes.CommonWidth}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Phone Number:</p>
                            <p>{draftOrder?.OCustomerDetails[0]?.phoneNumber}</p>
                        </div>

                        <div className={Classes.Email}>
                            <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Email:</p>
                            <p>{draftOrder?.OCustomerDetails[0]?.email}</p>
                        </div>   
                    </div>
                </div>

                {draftOrder?.OCustomerAddresses && draftOrder?.OCustomerAddresses?.length !== 0 && draftOrder?.OCustomerAddresses?.map((location, index) => (
                    <div style={{ marginTop:15,width:'100%',borderRadius:4,boxShadow:"0 0 0.3152rem rgba(0,0,0,.181), 0 0 0.3152rem rgba(0,0,0,.181)"}}>
                        <p style={{fontWeight:600,padding:"3px 15px",borderRadius:"4px 4px 0px 0px",backgroundColor:'#9e9e9e1f',fontSize:15}}>{location?.addressType} Address</p>
                        <div style={{display:'flex',justifyContent:'flex-start',padding:'15px 15px 5px',flexWrap:'wrap'}}>
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Name:</p>
                                <p>{location?.firstName}{" "}{location?.lastName && location?.lastName}</p>
                            </div>
                            
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Address1:</p>
                                <p>{location?.addressLine1}</p>
                            </div>

                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Address2:</p>
                                <p>{location?.addressLine2}</p>
                            </div>
                        {/* </div>

                        <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',padding:'10px 15px'}}> */}
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Pincode:</p>
                                <p>{location?.pincode}</p>
                            </div>
                            
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>City:</p>
                                <p>{location?.city}</p>
                            </div>

                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>State:</p>
                                <p>{capitalizeState(location?.state)}</p>
                            </div>

                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Country:</p>
                                <p>{location?.country}</p>
                            </div>

                            
                            <div className={Classes.AddressCommonWidth}>
                                <p style={{fontWeight:500,color:'#6d7175',fontSize:14}}>Phone Number:</p>
                                <p>{location?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )

}

export default NewCustomerDetails;