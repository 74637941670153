import { Fragment, useEffect, useState } from "react"
import QRCode from 'qrcode';
import Classes from './css/addProduct.module.css';

const MainQrCode = (props) => {
    
    // const {QRCimageData , qrSKU , handlePrint , handleDownload } = props;
    const {Id , qrSKU} = props;
    const [QRCimageData, setQRCImageData] = useState('');

    useEffect(()=>{
        console.log("Called ",Id)
        getApkQRCode(Id)
    },[])

    const getApkQRCode = async (Id) =>{
        console.log("Called")
        var opts = {
            errorCorrectionLevel: 'M',
            quality: 0.3,
            margin: 1,
          }
        const URL = `https://depo24.page.link/?link=https://depo24.in/products/${Id}&apn=in.depo24.depo_24&ibi=in.depo24.depo24`;
        const qrCode = await QRCode.toDataURL(URL,opts)
        setQRCImageData(qrCode);
    }

    const handlePrint = async (e) => {
        e.preventDefault();
        const printPage = window.open("","_blank","")
        printPage.document.write(`
            <html>
                <head><title>Print Window</title></head>
                <body>
                    <img alt="qrc" src=${QRCimageData} width="66" height="66" onload="window.print();window.close()"/>
                    <p style="margin:2px 1px auto;font-size:10px;line-height:10px">${qrSKU}</p>
                </body>
            </html>
        `)
    }

    const handleDownload = (e)=>{
        e.preventDefault();
        const anchor = document.createElement('a');
        anchor.href = QRCimageData;
        anchor.download = 'QrCode.png';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
    
    return (
        <Fragment>
            <div className="shadow p-3 rounded my-2 mx-2">
				<div id="image-to-print">
					{/* <QrCodeCompo id={Id}/>  This is stylish qrCode we will use further in future */}
					<img className="ms-3" alt='QrCode' id='qrCode' width="66px" height="66px" src={QRCimageData} />
                
					<p style={{marginTop:'3px', fontSize:'10px' , lineHeight:'10PX'}}>{qrSKU}</p>
				</div>
				<br />
				<button 
					style={{ margin:'3px' }}
					className={Classes.UpdateBtn}
					onClick={handlePrint}>Print QRC </button><br/>
					<button 
					style={{ margin:'3px' }}
					className={Classes.UpdateBtn}
					onClick={handleDownload}>Download</button>
				
			</div>
        </Fragment>
    )
}

export default MainQrCode;