import { Fragment, useState, useEffect, forwardRef } from 'react';
// import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import NewPagination from '../../../components/newPagination';
import NewSalesUser from './newSalesUser';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Search from '../../../components/newSearch';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Spinner from '../../../components/spinner';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Button from '@mui/material/Button';
import UpdateSalesUser from './updateSalesUser';
import { useSelector, useDispatch } from 'react-redux';
import DeleteSalesUser from './deleteUser';
import RefreshIcon from '@mui/icons-material/Refresh';
import Caret from '../../../components/caret';
import SalesUserDialog from './addSalesUser';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportReport from './exportReport';
import { setReportDashboardData } from '../../../store/reportDashboardSlice';

const Reports = (props) => {
    const [loading,setLoading] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [userId,setUserId] =  useState('');

    // Create SalesUser
    const [userName,setUserName] = useState('');
    const [name,setName] = useState(''); 
    const [phoneNo,setPhoneNo] = useState('');
    const [customerType,setCustomerType] = useState('');
    const [password,setPassword] = useState(''); 
    const [monthlyTarget,setMonthlyTarget] = useState('');
    const [showModal, setShowModal] = useState(false);

    // exports
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [month,setMonth] = useState('');

    // Update Sales User
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    // Delete Sales User
    const [showDeleteModal,setShowDeleteModal] = useState(false);

    // Add Sales User
    const [open,setOpen] = useState(false);

    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const reportDashboardData = useSelector((state) => state.reportDashboardData);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    const showSalesUserDialog = () => {
        setOpen(true);
    }

    const hideSalesUserDialog = () => {
        setOpen(false);
    };

    const hideDialog = () => {
        setShowModal(false);
    };

    const showDialog = () => {
        setShowModal(true);
    }

    const handleUpdateDialog = (event,customerId) => {
        event.stopPropagation();
        setUserId(customerId);
        setShowUpdateModal(true);
    }

    const hideUpdateDialog = () => {
        setUserId('');
        setShowUpdateModal(false);
    }

    const handleDeleteDialog = (event,customerId) => {
        event.stopPropagation();
        setUserId(customerId);
        setShowDeleteModal(true);
    }

    const hideDeleteDialog = () => {
        setUserId('');
        setShowDeleteModal(false);
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const handleSearchCustomer = (event) => {
        setSearchCustomer(event.target.value);
        fetchCustomers(1,event.target.value);
    }

    const fetchCustomers = async (type,searchCustomer) => {
        setLoading(true);
        const page = type;

		await axiosInstance
			.get(`/fieldSalesVisit/salesUsers?page=${page}&text=${searchCustomer? searchCustomer: ''}&sort=${sort?.direction}&fieldName=${sort?.keyToSort}`)
			.then((res) => {
                setLoading(false);
				dispatch(setReportDashboardData(res.data.data));
			});
	};

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Report Dashboard')) {
            // fetch draftOrders
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
            fetchCustomers(page? page : 1);
        }
    }, [])

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
            fontSize: 13
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        }
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleTotal = (total) => {
		let fetchDecimal= countPlaces(total)
        let amount = fetchDecimal === 1 || fetchDecimal === 0 || fetchDecimal === 2 ? total :  Math.trunc(total*100)/100;
		return amount;
	}

	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    const handlePermissions = () => {
        const userPermission = auth?.user?.role === 'super-admin' || 
        auth?.user?.role === 'admin' || 
        auth?.user?.role === 'salesManager';
        return userPermission;
    }

    const handleRefreshUsers = async () => {
        setLoading(true);
        await axiosInstance
			.put(`/admin/updateAdmins`)
			.then((res) => {
                setLoading(false);
				fetchCustomers(1);
			});
    }

    const [sort,setSort] = useState({keyToSort:'',direction:'asc'});

    const handleHeaderClick = async (header) => {
        setLoading(true);
        const direction = header === sort?.keyToSort ? sort?.direction === 'asc' ? 'desc' : 'asc' : 'desc';
        setSort({
            keyToSort: header,
            direction: direction
        })

        await axiosInstance
            .get(`/fieldSalesVisit/salesUsers?sort=${direction}&fieldName=${header}`)
            .then((res) => {
                setLoading(false);
                dispatch(setReportDashboardData(res.data.data));
            });
    }

    const handleExport = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        selectedUsers.splice(0, selectedUsers.length);
        setMonth('');
    }

	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>

                
                <div style={{display:'flex',marginTop:-25}} className={Classes.salesReportHeader}>
                    <div style={{display:'flex',marginBottom:5}}>
                        {
                            (auth?.user?.role === 'super-admin' || 
                            auth?.user?.role === 'admin' || 
                            auth?.user?.role === 'salesManager') &&
                        (
                            <Button
                                id='exportbtn'
                                variant="contained"
                                style={{backgroundColor:'#26b278',marginRight:'5px',fontSize:14}}
                                startIcon={<FileDownloadIcon />}
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                        )}
                        
                        {
                            (auth?.user?.role === 'super-admin' || 
                            auth?.user?.role === 'admin' || 
                            auth?.user?.role === 'salesManager') &&
                        (
                            <Button 
                                className={Classes.ResponsiveBtn}
                                sx={{
                                    textTransform:'capitalize',
                                    "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                                }} 
                                variant="contained"
                                startIcon={<PersonAddIcon />}
                                onClick={showSalesUserDialog}
                            >
                                ADD
                            </Button>
                        )}
                    </div>

                    <div style={{display:'flex'}}>
                        <Button  
                            startIcon={<RefreshIcon />} 
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize:'14px',
                                textTransform:'capitalize',
                                marginRight:'5px',
                                backgroundColor:'#EE5C55',
                                ":hover": {backgroundColor:'#EE5C55'},
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                            }}
                            onClick={handleRefreshUsers}
                        >
                            Refresh
                        </Button>
                        
                        {
                            (auth?.user?.role === 'super-admin' || 
                            auth?.user?.role === 'admin' || 
                            auth?.user?.role === 'salesManager') &&
                        (
                            <Button 
                                sx={{
                                    textTransform:'capitalize',
                                    "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                                }} 
                                variant="contained"
                                startIcon={<PersonAddIcon />}
                                onClick={showDialog}
                            >
                                Create New
                            </Button>
                        )}
                    </div>
                </div>

                <div className={Classes.Container}>
                    <div className={Classes.HeaderBox} style={{paddingTop:20,paddingBottom:10}}>
                        <Search  
                            text={searchCustomer}
                            fetchData={handleSearchCustomer}
                        />
					</div>
                </div>

                <TableContainer component={Paper} className={Classes.hideTable}>
                    <Table 
                        sx={{ minWidth: 'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'} }} 
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>User</StyledTableCell>
                                <StyledTableCell>Phone</StyledTableCell>
                                <StyledTableCell>Customer Type</StyledTableCell>
                                <StyledTableCell onClick={() => handleHeaderClick("monthVisits")}>
                                    {new Date().getDate() <= 5 ? (
                                        <>{monthNames[new Date().getMonth() -1]}{" "}</>
                                    ) : (
                                        <>{monthNames[new Date().getMonth()]}{" "}</>
                                    )}  Total Visits 
                                    {"monthVisits" === sort.keyToSort && (
                                        <Caret sort={sort} headerName={'monthVisits'}/>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell onClick={() => handleHeaderClick("customerVisits")}>
                                    Total No. Of Customers
                                    {"customerVisits" === sort.keyToSort && (
                                        <Caret sort={sort} headerName={'customerVisits'}/>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell onClick={() => handleHeaderClick("monthOrders")}>
                                    Sum Of Orders
                                    {"monthOrders" === sort.keyToSort && (
                                        <Caret sort={sort} headerName={'monthOrders'}/>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {new Date().getDate() <= 5 ? (
                                        <>{monthNames[new Date().getMonth() -1]}{" "}</>
                                    ) : (
                                        <>{monthNames[new Date().getMonth()]}{" "}</>
                                    )}  Target
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportDashboardData?.salesUsers?.length !== 0 && reportDashboardData?.salesUsers?.map((customer) => (
                                <StyledTableRow 
                                    key={customer?._id}   
                                    onClick={() => {navigate(`${customer?._id}`)}}
                                >
                                    <StyledTableCell>
                                        {customer?.name ? customer?.name :"N/A"}
                                    </StyledTableCell>
                                    <StyledTableCell>{customer?.phone ? customer?.phone : "N/A"}</StyledTableCell>

                                    <StyledTableCell sx={{textTransform:'capitalize'}}>
                                        {customer?.typeOfCustomer ? customer?.typeOfCustomer :"N/A"}
                                    </StyledTableCell>

                                    <StyledTableCell>{customer?.monthVisits}</StyledTableCell>

                                    <StyledTableCell>{customer?.customerVisits}</StyledTableCell>

                                    <StyledTableCell>{handleTotal(customer?.monthOrders)}</StyledTableCell>

                                    <StyledTableCell>
                                        {customer?.monthlyTarget ? customer?.monthlyTarget : 2500000}
                                    </StyledTableCell>

                                    <StyledTableCell sx={{padding:'16px 8px'}}>
                                        {handlePermissions() && (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding:'2px 5px',
                                                    fontSize:'12px',
                                                    cursor:'pointer',
                                                    minWidth: "fit-content"
                                                }}
                                                onClick={(event) => handleUpdateDialog(event,customer?._id)}
                                                id='btnArea'
                                            >
                                                Update
                                            </Button>
                                        )}
                                    </StyledTableCell>

                                    <StyledTableCell sx={{padding:'16px 8px'}}>
                                        {handlePermissions() && (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding:'2px 5px',
                                                    fontSize:'12px',
                                                    cursor:'pointer',
                                                    backgroundColor:'rgb(211, 47, 47)',
                                                    ":hover": {backgroundColor:'rgb(211, 47, 47)'},
                                                    minWidth: "fit-content"
                                                }}
                                                onClick={(event) => handleDeleteDialog(event,customer?._id)}
                                                id='btnArea'
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className={Classes.responsiveTable}>
                    {reportDashboardData?.salesUsers?.length !== 0 && reportDashboardData?.salesUsers?.map((customer,index) => (
                        <div 
                            key={customer?._id}   
                            onClick={() => {navigate(`${customer?._id}`)}}
                            className={Classes.responsiveTableBox}
                            style={{marginTop: index === 0 ? 0 : 15}}
                        >
                            <div style={{display:'flex'}}>
                                <AccountCircleIcon sx={{marginRight:'5px'}}/>
                                <p style={{fontWeight:600}}>
                                    {customer?.name ? customer?.name :"N/A"}
                                </p>
                            </div>

                            <div style={{display:'flex'}}>
                                <p style={{marginRight:5,color: "#6c757d"}}>Phone:</p>
                                <p>{customer?.phone ? customer?.phone : "N/A"}</p>
                            </div>

                            <div style={{display:'flex'}}>
                                <p style={{marginRight:5,color: "#6c757d"}}>Customer Type:</p>
                                <p style={{textTransform:'capitalize'}}>
                                    {customer?.typeOfCustomer ? customer?.typeOfCustomer :"N/A"}
                                </p>
                            </div>

                            <div style={{display:'flex'}}>
                                <p style={{marginRight:5,color: "#6c757d"}}>
                                    {new Date().getDate() <= 5 ? (
                                        <>{monthNames[new Date().getMonth() -1]}{" "}</>
                                    ) : (
                                        <>{monthNames[new Date().getMonth()]}{" "}</>
                                    )} Total Visits:
                                </p>
                                <p>{customer?.monthVisits}</p>
                            </div>

                            <div style={{display:'flex'}}>
                                <p style={{marginRight:5,color: "#6c757d"}}>Total No. Of Customers:</p>
                                <p>{customer?.customerVisits}</p>
                            </div>

                            <div style={{display:'flex'}}>
                                <p style={{marginRight:5,color: "#6c757d"}}>Sum Of Orders:</p>
                                <p>{handleTotal(customer?.monthOrders)}</p>
                            </div>

                            <div style={{display:'flex'}}>
                                <p style={{marginRight:5,color: "#6c757d"}}>
                                    {new Date().getDate() <= 5 ? (
                                        <>{monthNames[new Date().getMonth() -1]}{" "}</>
                                    ) : (
                                        <>{monthNames[new Date().getMonth()]}{" "}</>
                                    )} Target:
                                </p>
                                <p>{customer?.monthlyTarget ? customer?.monthlyTarget : 2500000}</p>
                            </div>

                            {handlePermissions() && (
                                <div style={{display:'flex',marginTop:5}}>
                                    <p style={{marginRight:5,color: "#6c757d",width:44}}>Edit:</p>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "capitalize",
                                            padding:'2px 5px',
                                            fontSize:'12px',
                                            cursor:'pointer',
                                            minWidth: "fit-content"
                                        }}
                                        onClick={(event) => handleUpdateDialog(event,customer?._id)}
                                        id='btnArea'
                                    >
                                        Update
                                    </Button>
                                </div>
                            )}

                            {handlePermissions() && (
                                <div style={{display:'flex',marginTop:5}}>
                                    <p style={{marginRight:5,color: "#6c757d",width:44}}>Delete:</p>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "capitalize",
                                            padding:'2px 5px',
                                            fontSize:'12px',
                                            cursor:'pointer',
                                            backgroundColor:'rgb(211, 47, 47)',
                                            minWidth: "fit-content"
                                        }}
                                        onClick={(event) => handleDeleteDialog(event,customer?._id)}
                                        id='btnArea'
                                    >
                                        Delete
                                    </Button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {reportDashboardData?.salesUsers?.length > 0 && (
                    <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                        <NewPagination totalPage={reportDashboardData} fetchData={fetchCustomers} />
                    </div>
                )}
            </div>

            {loading ? <Spinner /> : null}


            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewSalesUser 
                    showModal={showModal}
                    hideDialog={hideDialog}
                    userName={userName}
                    setUserName={setUserName}
                    name={name}
                    setName={setName}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    customerType={customerType}
                    setCustomerType={setCustomerType}
                    password={password}
                    setPassword={setPassword}
                    fetchCustomers={fetchCustomers}
                />
            </div>

            <div className={showUpdateModal ? Classes.UpdateSalesDialog : undefined}>
                <UpdateSalesUser 
                    showModal={showUpdateModal}
                    hideDialog={hideUpdateDialog}
                    userId={userId}
                    userName={userName}
                    setUserName={setUserName}
                    name={name}
                    setName={setName}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    customerType={customerType}
                    setCustomerType={setCustomerType}
                    password={password}
                    setPassword={setPassword}
                    fetchCustomers={fetchCustomers}
                    monthlyTarget={monthlyTarget}
                    setMonthlyTarget={setMonthlyTarget}
                />
            </div>

            <div className={showDeleteModal ? Classes.UpdateSalesDialog : undefined}>
                <DeleteSalesUser 
                    showModal={showDeleteModal}
                    hideDialog={hideDeleteDialog}
                    userId={userId}
                    fetchCustomers={fetchCustomers}
                />
            </div>

            <div className={open ? Classes.UpdateSalesDialog : undefined}>
                <SalesUserDialog 
                    open={open}
                    handleClose={hideSalesUserDialog}
                    fetchCustomers={fetchCustomers}
                />
            </div>
            <div className={open ? Classes.UpdateSalesDialog : undefined}>
                <ExportReport 
                    open={open}
                    handleClose={handleClose}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    month={month}
                    setMonth={setMonth}
                />
            </div>
        </Fragment>
    );
};

export default Reports;
