import { useState, Fragment, useEffect } from 'react';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setReturns } from '../../../store/returnsSlice';
import { CheckRoleBasedPermission, permission, services } from '../../../utility/checkRoleBasedPermission';
import { Box, Paper, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axiosInstance from '../../../utility/axios-instance';
import NewPagination from '../../../components/newPagination';
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import {
    DataGrid,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';

const columns = [
    {
        field: 'returnNumber',
        headerName: 'Return No.',
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'orderName',
        headerName: 'Order No.',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 0.7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'totalAmount',
        headerName: 'Total Amount',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'reason',
        headerName: 'Reason',
        flex: 0.7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'locationCode',
        headerName: 'Location Code',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 1.5,
        headerClassName: 'super-app-theme--header'
    }
];

function SearchInput({ searchFor, handleSearch }) {
    return (
        <div style={{ width: '100%' }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ 
                    width: '100%',
                    "& label": { top: searchFor ? "0%" : "-18%" },
                    "& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
                 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

function CustomToolbar({ searchFor, handleSearch }) {
    return (
        <GridToolbarContainer style={{paddingTop:8,justifyContent:'space-between'}}>
            <div>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </div>
            <div>
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />
            </div>
        </GridToolbarContainer>
    );
}

const ReturnsNew = (props) => {
    const [searchFor,setSearchFor] = useState('');
    const [sort,setSort] = useState({ field: 'createdAt', sort: 'desc' });
    const [filter, setFilter] = useState(null);
    const [loading,setLoading] = useState(false);
    const returns = useSelector((state) => state.returns);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    const pinTab = useSelector((state) => state?.pinTab);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

    const handleCreatedAt = (createdAt) => {
        const dn = new Date();
        let ca = createdAt
            ? createdAt.getFullYear() === dn.getFullYear()
                ? createdAt.getMonth() === dn.getMonth()
                    ? createdAt.getDate() === dn.getDate()
                        ? `Today at ${createdAt.toLocaleTimeString()}`
                        : createdAt.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
                            : years[createdAt.getMonth()] +
                            ' ' +
                            createdAt.getDate() +
                            ' at ' +
                            createdAt.toLocaleTimeString()
                    : years[createdAt.getMonth()] +
                    ' ' +
                    createdAt.getDate() +
                    ' at ' +
                    createdAt.toLocaleTimeString()
                : years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
            : null
        return ca;
    }

    const fetchReturns = async (type) => {
        setLoading(true);
        const page = type ? type : 1;

        const params = {
            page,
            searchFor,
            sort : JSON.stringify(sort),
            filter: `${filter?.value}` ? filter : undefined,
        };

        await axiosInstance
            .get(`/pgOrder/admin/returnsNew`, { params })
            .then((res) => {
                dispatch(setReturns(res.data.data));
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
        setLoading(false);
    };

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Returns')) {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
            fetchReturns(page ? page : 1);
        }
    }, [sort, searchFor, filter]);

    return (
        <Fragment>
            <div className={Classes.MuiTable}>
                {loading && <Spinner />}

                <Box
                    component={Paper}
                    className="shadow"
                    sx={{
                        '& .super-app-theme--header': {  
                            backgroundColor:'#243750',color:'#ffffff',cursor:"default" 
                    }}}
                >
                    <DataGrid
                        slots={{ toolbar: CustomToolbar }}
                        filterMode="server"
                        sortingMode="server"
                        paginationMode="server"
                        slotProps={{
                            toolbar: {
                                searchFor,
                                handleSearch
                            }
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'desc' }],
                            },
                        }}
                        onSortModelChange={(sort) => setSort(sort[0])}
                        sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            ".MuiDataGrid-footerContainer": {
                                display: "none !important",
                            },
                            boxShadow: 2,
                            borderRadius: 2,
                            cursor: "pointer"
                        }}
                        columns={columns}
                        rows={returns?.fetchedReturns?.map((order) => {
                            let row = {
                                id: order?.returnNumber,
                                returnNumber: order?.returnNumber,
                                orderName: order?.orderName,
                                customerName: order?.customerName,
                                type: order?.type,
                                status: order?.status,
                                totalAmount: order?.totalAmount,
                                reason: order?.reason,
                                locationCode: order?.locationCode,
                                createdAt: handleCreatedAt(new Date(order?.createdAt)),
                            }
                            return row;
                        })}
                        onRowClick={(params) => {
                            const permissible = CheckRoleBasedPermission(auth?.user, services?.returns, permission?.read)
                            if (permissible) {
                                navigate(`/returnsNew/${params?.id}`);
                            }
                        }}
                        sortingOrder={['asc', 'desc']}
                        autoHeight
                        autoPageSize
                        onFilterModelChange={(filter) => (setFilter(filter.items[0]))}
                    />
                </Box>

                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

                {returns?.fetchedReturns?.length > 0 ? (
                    <div>
                        <NewPagination totalPage={returns} fetchData={fetchReturns} />
                    </div>
                ) : null}
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"fetchedReturns"}
                    endpoint={`/pgOrder/admin/returnsNew`}
                    rowId={'returnNumber'}
                    clickable={true}
                    tab={'/returnsNew'}
                />
            </div>
        </Fragment>
    );
};

export default ReturnsNew;