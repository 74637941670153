import { Fragment } from 'react';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from './css/addProduct.module.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../utility/axios-instance';

const  AllPopUp = (props) => {
    const {
        showErrorMsg,
        storePrevGroupName,
        handlePrevButton,
        handleChangeTitleName,
        handleChangeTitle,
        skuExistDB,
        handleError,
        showVinculumError,
        productId,
        variants,
        variantSku,
        setVariantSku,
        setProductDetails,
        setGroupImageUrl,
        setMainImageUrl,
        setImageUrl2,
        setImageUrl3,
        setImageUrl4,
        setImageUrl5,
        setImageUrl6,
        setImageUrl7,
        setListDiscount,
		setRetailerDiscount,
		setInteriorDiscount,
		setOemDiscount,
		setEndUserDiscount,
		setDiscountArr,
        setSalesChannel,
        setVendorCode,
        setVendor,
        setBrand,
        setBrandAbr,
        setCollectionStore,
        setSelectedTag,
        setPrevParentId
    }= props;

    const navigate = useNavigate();

    const handleVariantId = (event) => {
        if (event.target.checked) {
            if (variantSku.length !== 0) {
                document.getElementById(variantSku[0]).checked = false;
            } 
            setVariantSku([event.target.id])
        } else {
            setVariantSku([]);
        }
    }

	const handleVariantSubmit = (event) => {
        event.preventDefault();
        if (variantSku.length !== 0) {
            axiosInstance
                .get(`/product/${variantSku[0]}`)
                .then((res) => {
                    const array = [];
                    if (res.data.data.mainImageUrl) {
                        setMainImageUrl(res.data.data.mainImageUrl);
                        array.push(res.data.data.mainImageUrl);
                    }

                    if (res.data.data.imageUrl2) {
                        setImageUrl2(res.data.data.imageUrl2);
                        array.push(res.data.data.imageUrl2);
                    }

                    if (res.data.data.imageUrl3) {
                        setImageUrl3(res.data.data.imageUrl3);
                        array.push(res.data.data.imageUrl3);
                    }

                    if (res.data.data.imageUrl4) {
                        setImageUrl4(res.data.data.imageUrl4);
                        array.push(res.data.data.imageUrl4);
                    }

                    if (res.data.data.imageUrl5) {
                        setImageUrl5(res.data.data.imageUrl5);
                        array.push(res.data.data.imageUrl5);
                    }

                    if (res.data.data.imageUrl6) {
                        setImageUrl6(res.data.data.imageUrl6);
                        array.push(res.data.data.imageUrl6);
                    }

                    if (res.data.data.imageUrl7) {
                        setImageUrl7(res.data.data.imageUrl7);
                        array.push(res.data.data.imageUrl7);
                    }

                    if (res.data.data.salesChannel) {
                        setSalesChannel(res.data.data.salesChannel)
                        if (res.data.data.salesChannel.includes('Website')) {
                            document.getElementById('website').checked = true;
                        }
                        
                        if (res.data.data.salesChannel.includes('Portal')) {
                            document.getElementById('portal').checked = true;
                        }
                        
                        if (res.data.data.salesChannel.includes('App')) {
                            document.getElementById('app').checked = true;
                        }
                    }

                    if (res.data.data.brandAbr) {
                        setBrand(res.data.data.brandName);
                        setBrandAbr(res.data.data.brandAbr);
                    }

                    if (res.data.data.vendorCode) {
                        setVendor(res.data.data.vendorName);
                        setVendorCode(res.data.data.vendorCode);
                    }

                    if (
                        res.data.data.l1 &&
                        res.data.data.l2 &&
                        res.data.data.l3
                    ) {
                        const collectionArray = [
                            res.data.data.l1,
                            res.data.data.l2,
                            res.data.data.l3
                        ]
                        setCollectionStore(collectionArray);
                    }

                    setPrevParentId([res.data.data.shopifyProductId]);

                    if (res.data.data.tags) {
                        setSelectedTag(res.data.data.tags);
                    }

                    const discountArray = [];
                    discountArray.push({'listDiscount': res.data.data.listingDiscount});
                    setListDiscount(res.data.data.listingDiscount);
                    if (res.data.data.retailDiscount) {
                        discountArray.push({'retailerDiscount':res.data.data.retailDiscount});
                        setRetailerDiscount(res.data.data.retailDiscount);
                    }

                    if (res.data.data.interiorDiscount) {
                        discountArray.push({'interiorDiscount': res.data.data.interiorDiscount});
                        setInteriorDiscount(res.data.data.interiorDiscount);
                    }

                    if (res.data.data.endUserDiscount) {
                        discountArray.push({'endUser': res.data.data.endUserDiscount});
                        setEndUserDiscount(res.data.data.endUserDiscount);
                    }

                    if (res.data.data.oemDiscount) {
                        discountArray.push({'oemDiscount':res.data.data.oemDiscount});
                        setOemDiscount(res.data.data.oemDiscount);
                    }

                    setDiscountArr(discountArray);
                    setGroupImageUrl(array);
                    document.getElementById('GroupNameBox').style.display = 'none';
                    setProductDetails([res.data.data]);
                });
        }
    }

	return (
		<Fragment>
            <div>
                <div className={Classes.GroupNameBox} id='GroupNameBox'>
                    <div id='hideButtons'>
                        <div className={Classes.GroupNameContent}>
                            <p>&#x2022;</p>
                            <p className={Classes.GroupName}>
                                This group title already exist on Shopify
                                &nbsp;
                                <span style={{color:'red'}}>
                                    {storePrevGroupName}
                                </span>
                            </p> 
                        </div>

                        <div className={Classes.GroupNameButtons}>
                            <div className={Classes.GroupNameCreateVariant}>
                                <p
                                    className={Classes.CreateVariant}
                                    onClick={(e) => handlePrevButton(e)}
                                >
                                    Create Variant
                                </p>
                            </div>
                            <div style={{display:'flex'}}>
                                <div 
                                    className={Classes.ChangeTitle} 
                                    id='changeTitle'
                                >
                                    <input 
                                        id='titleName'
                                        type="text" 
                                        className={Classes.GroupNameTitle}
                                    />
                                    <p
                                        className={Classes.GroupNameChangeTitle}
                                        onClick={(e) => handleChangeTitleName(e)}
                                    >
                                        Change Title
                                    </p>
                                </div>
                                <p
                                    id='titleBtn'
                                    className={Classes.GroupNameChangeTitle}
                                    onClick={(e) => handleChangeTitle(e)}
                                >
                                    Change Title
                                </p>
                            </div>
                        </div>
                    </div>
            
                    {variants.length !== 0 && (
                        <div>
                            <p style={{fontWeight:600,color:'red'}}> 
                                {variants.length === 1 ? (
                                    <span>
                                        Please select this sku
                                    </span>
                                ): (
                                    <span>
                                        Please choose any of these sku
                                    </span>
                                )}
                            </p>
                            {variants.map((variant) => (
                                <div 
                                    style={{display:'flex',marginTop:5}} 
                                    key={variant._id}
                                >
                                    <input 
                                        type="checkbox" 
                                        name="" 
                                        id={variant._id}
                                        style={{width:16,height:16}}
                                        onChange={handleVariantId}
                                    />
                                    <p style={{marginLeft:5}}>
                                        {variant.basicCollectionDataBrandData.sku}
                                    </p>
                                </div>
                            ))}

                            <button 
                                className={Classes.Continue}
                                disabled={variantSku.length !== 0 ? false : true}
                                onClick={handleVariantSubmit}
                                id='continue'
                            >
                                Continue
                            </button>
                        </div>
                    )}
                </div>

                <div className={Classes.SkuBox} id='SkuBox'>
                    <p className={Classes.Sku}>
                        {skuExistDB.data}
                    </p>
                </div>

                <div 
                    className={Classes.Platform} 
                    id='platforms'
                >
                    <h6 
                        style={{
                            padding: 10,
                            margin:0
                        }}
                    >
                        Product Created
                    </h6>
                    <div className={Classes.PlatformBox}>
                        <div style={{display:'flex'}}>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faCircleCheck} 
                                    style={{ color:'green' }}
                                    className={Classes.Icon}
                                />
                            </div>
                            <p>Database</p>
                        </div>
                    </div>
                    
                {/* <div>
                        <div className={Classes.PlatformBox}>
                            <div style={{display:'flex'}}>
                                <div>
                                    <FontAwesomeIcon 
                                        icon={
                                            showErrorMsg.length !== 0 ? 
                                            faCircleXmark : 
                                            faCircleCheck
                                        } 
                                        style={{
                                            color: showErrorMsg.length !== 0 ? 
                                            'red' :
                                            'green'
                                        }}
                                        className={Classes.Icon}
                                    />
                                </div>
                                <p>Shopify</p>
                            </div>

                            {showErrorMsg.length !== 0 && (
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faAngleDown} 
                                        className={Classes.AngleDown}
                                        id='shopify'
                                        data-toggle={false}
                                        onClick={(e) => handleError(e)}
                                    />
                                </div>
                            )}
                        </div>
                        
                        {showErrorMsg.length !== 0 && (
                            <div className={Classes.ErrorContent}>
                                <div className={Classes.ErrorContentBox1}>
                                    &#x2022; &nbsp;
                                    <div className={Classes.ErrorMessage}>
                                        {showErrorMsg.platform && (
                                            <p>{showErrorMsg.platform} 
                                                <span style={{color: '#000'}}>
                                                    {showErrorMsg.fieldName && (
                                                        <span>{showErrorMsg.fieldName}</span>
                                                    )}. 
                                                </span>
                                            </p>
                                        )}
                                        <p> Product not created on Shopify.</p>
                                    </div>
                                </div>
                                <div style={{ width:'21%' }}>
                                    <p>Create Product on Shopify</p>
                                    <div style={{textAlign:'center'}}>
                                        <p
                                            className={Classes.PlatformSpecificBtn}
                                            onClick={() => navigate(`/products/${productId}`)}
                                        >Create</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div>
                        <div className={Classes.PlatformBox}>
                            <div style={{display:'flex'}}>
                                <div>
                                    <FontAwesomeIcon 
                                        icon={
                                            showVinculumError.length !== 0 ? 
                                            faCircleXmark: 
                                            faCircleCheck
                                        } 
                                        style={{
                                            color: showVinculumError.length !== 0 ? 
                                            'red' :
                                            'green'
                                        }}
                                        className={Classes.Icon}
                                    />
                                </div>
                                <p>Vinculum</p>
                            </div>

                            {showVinculumError.length !== 0 && (
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faAngleDown} 
                                        className={Classes.AngleDown}
                                        id='veniculum'
                                        data-toggle={false}
                                        onClick={(e) => handleError(e)}
                                    />
                                </div>
                            )}
                        </div>

                        {showVinculumError.length !== 0 && (
                            <div className={Classes.ErrorContent}>
                                <div className={Classes.ErrorContentBox1}>
                                    &#x2022; &nbsp;
                                    <div className={Classes.ErrorMessage}>
                                        <p>{showVinculumError.errorMsg}.</p>
                                        <p> Product not created on Vinculum.</p>
                                    </div>
                                </div>
                                <div style={{ width:'21%' }}>
                                    <p>Create Product on Vinculum</p>
                                    <div style={{textAlign:'center'}}>
                                        <p
                                            className={Classes.PlatformSpecificBtn}
                                            onClick={() => navigate(`/products/${productId}`)}
                                        >Create</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
		</Fragment>
	);
};

export default AllPopUp;
