import React, { Fragment, useState, useEffect } from "react";
import Classes from "../../createOrders/css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';
import SnackBar from '../../../../components/SnackBar';

const CustomerModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [districts, setDistricts] = useState([]);
    const [contactPhoneNumber, setContactPhoneNumber] = useState('')
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const closeDialog = () => {
        const form = document.getElementById('form');
        if (form) {
            form.reset();
        }
        setContactPhoneNumber('');
        props.onHideModal();
    }

    const {
        errorMessage,
        reRender,
        setReRender
    } = props;

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const button = document.getElementById('btn')
        button.innerHTML = 'Saving...';

        const data = {
            "locationCode": event.target.locationCode.value,
            "locationName": event.target.name.value.trim(),
            "locationGstNumber": event.target.gst.value ? [event.target.gst.value] : undefined,
            "status": event.target.locationStatus.value ? event.target.locationStatus.value : undefined,
            "locationType": event.target.locationType.value ? event.target.locationType.value : undefined,
            "district": event.target.district.value ? event.target.district.value : undefined
        }

        const locationContactDetailData = {
            "firstName": event.target.firstName.value || undefined,
            "lastName": event.target.lastName.value || undefined,
            "phoneNumber": event.target.phone.value || undefined,
            "email": event.target.email.value || undefined,
            "role": event.target.role.value || undefined,
        }

        axiosInstance
            .post("/location/createLocation", { ...data })
            .then((res) => {
                axiosInstance
                    .post(`/location/locationContactDetail/${data?.locationCode}`, { ...locationContactDetailData })
                    .then((res) => {
                        const successMessage = 'Location Created Successfully'
                        setLoading(false)
                        setSnackBar((prevSnackBar) => {
                            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                        });
                        closeDialog();
                        if (setReRender) { setReRender(!reRender) }
                        button.innerHTML = 'Location Created';
                    }).catch((error) => {
                        const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                        setLoading(false)
                        setSnackBar((prevSnackBar) => {
                            return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                        });
                    })
            }).catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })

    }

    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    useEffect(() => {
        axiosInstance
            .get(`/others/districts`)
            .then((res) => {
                let districts = res?.data?.data?.map((item) => {
                    return item.district;
                });
                setDistricts(districts);
            });
    }, []);


    const handleNumChange = event => {
        const limit = 10;
        setContactPhoneNumber(event.target.value.slice(0, limit));
    };


    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^(?!0)\d{10}$/;
            if (event.target.value.match(phoneno)) {
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {props.onShowModal && (
                <div className={Classes.modal} style={{ top: '10%', bottom: '17%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Create a new Location</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>
                        <div style={{ padding: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="text"
                                        id='locationName'
                                        name='name'
                                        label='Location Name'
                                        autoComplete='off'
                                        placeholder='Location Name'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        required={true}
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="locationStatus"
                                        name="locationStatus"
                                        defaultValue={"Active"}
                                        options={["Active", "In-Active", "Dis-Continued"]}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Location Status"} />
                                        }
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="text"
                                        id='locationCode'
                                        name='locationCode'
                                        label='Location Code'
                                        autoComplete='off'
                                        placeholder='Location Code'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        required={true}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField
                                        required={true}
                                        id='gst'
                                        name='gst'
                                        label='GST'
                                        placeholder='Enter GST No'
                                        autoComplete='off'
                                        inputProps={{ maxLength: 15, minLength: 15 }}
                                        sx={{ width: '100%' }}
                                        // onChange={handleGstField}
                                        onBlur={handleValidGst}
                                        size="small"
                                    />

                                    <div
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="locationType"
                                        name="locationType"
                                        options={["WH", "Store", "Head Office", "Franchise"]}
                                        defaultValue="WH"
                                        sx={{ width: '100%' }}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Location Type"} />
                                        }
                                        size="small"
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="district"
                                        name="district"
                                        options={districts}
                                        defaultValue={districts[0]}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={"District"} required={true}/>}
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        required={true}
                                        type="text"
                                        id='firstName'
                                        name='firstName'
                                        label='First Name'
                                        autoComplete='off'
                                        placeholder='First Name'
                                        sx={{ width: '100%' }}
                                        size="small"
                                    />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="text"
                                        id='lastName'
                                        name='lastName'
                                        label='Last Name'
                                        autoComplete='off'
                                        placeholder='Last Name'
                                        sx={{ width: '100%' }}
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%' }}
                                        value={contactPhoneNumber}
                                        onBlur={(e) => handleMinLength(e)}
                                        onChange={(e) => handleNumChange(e)}
                                        size="small"
                                    />
                                    <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="email"
                                        id='email'
                                        name='email'
                                        label='Email'
                                        autoComplete='off'
                                        placeholder='Enter Your Email'
                                        sx={{ width: '100%' }}
                                        size="small"
                                    />
                                </div>
                            </div>
                            <div className={Classes.ContentBox}>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id='role'
                                        name='role'
                                        autoComplete='off'
                                        defaultValue="Warehouse Manager"
                                        options={["Warehouse Manager", "Warehouse Executive"]}
                                        getOptionLabel={(option) => option || ""}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={'Role'} required={true} />}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={Classes.DialogBtnBox}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={closeDialog}
                            >
                                CANCEL
                            </button>

                            <button
                                id='btn'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>

                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default CustomerModal;