import { Fragment,useState, useEffect } from 'react';
import TagsPopup from './tagsPopup';
import axiosInstance from '../../../../../utility/axios-instance';
import CollectionsPopUp from './collectionModal';
import ProductStatus from './productStatus';
import Aside2ndBox from './aside2ndBox';
import Classes from '../css/addProduct.module.css';

const Aside = (props) => {
	const [tagsModal,setTagsModal] = useState(false);
	const [brands,setBrands] = useState([]);
	const [brandToggle,setBrandToggle] = useState(true);
	const [collectionModal,setCollectionModal] = useState(false);
	const [vendorToggle,setVendorToggle] = useState(true)
	const [vendors,setVendors] = useState([]);
	const [storePrevCollectionId,setPrevCollectionId] = useState([]);

	const {
		setBrand,
		setBrandAbr,
		setVendor,
		setVendorCode,
		storeCollections,
		setCollectionStore,
		showSelectedTags,
		setSelectedTag,
		storeSalesChannel,
		setSalesChannel,
		handleVinculum,
		productDetailsVariant
	} = props;

	const showTagsPopup = () => {
		setTagsModal(true)
	}

	const hideTagsPopup = () => {
		setTagsModal(false)
	}

	document.addEventListener('mouseup', function(e) {
        let container = document.getElementById('contactOutline');
        if(container !== null){
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

	const handleSelectBrand = (event) => {
		handleVinculum();
		const brandAbr = event.target.options[event.target.selectedIndex].dataset.brandabr;
		setBrandAbr(brandAbr);
		setBrand(event.target.value);
	}

	const handleCollectionModal = () => {
		setCollectionModal(true)
	}

	const hideCollectionModal = () => {
		setCollectionModal(false)
	}

	const handleSalesChannel = (event) => {
		if(event.target.checked){
			if(storeSalesChannel.length === 0){
				setSalesChannel([event.target.name])
			}else{
				for(let sales of storeSalesChannel){
					if(sales !== event.target.name){
						setSalesChannel([...storeSalesChannel,event.target.name])
					}
				}
			}
		}else{
			for(let sales of storeSalesChannel){
				if(sales === event.target.name){
					setSalesChannel(storeSalesChannel.filter((sales) => sales !== event.target.name));
				}
			}
		}
	}

	document.addEventListener('mouseup', function(e) {
        let container = document.getElementById('vendors');
        if(container !== null){
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });


	const handleSelectVendor = (event) => {
		handleVinculum();
		const vendorCode = event.target.options[event.target.selectedIndex].dataset.vendorcode;
		setVendorCode(vendorCode)
		setVendor(event.target.value);
	}

	useEffect(() => {
		if(brandToggle){
			axiosInstance
				.get('/others/adminBrands')
				.then((res) => {
					setBrands(res.data.data)
					setBrandToggle(false)
				})
		}
	},[brandToggle]);

	useEffect(() => {
		if(vendorToggle){
			axiosInstance
				.get('/others/vendors')
				.then((res) => {
					setVendors(res.data.data)
					setVendorToggle(false)
				})
		}
	},[vendorToggle]);

	return (
		<Fragment>
		
			<div>
				<Aside2ndBox 
					brands={brands}
					handleSelectBrand={handleSelectBrand}
					vendors={vendors}
					handleSelectVendor={handleSelectVendor}
					handleCollectionModal={handleCollectionModal}
					storeCollections={storeCollections}
					showSelectedTags={showSelectedTags}
					showTagsPopup={showTagsPopup}
					productDetailsVariant={productDetailsVariant}
					setSelectedTag={setSelectedTag}

				/>
			</div>

			<div className={collectionModal ? Classes.UpdateSalesDialog : undefined}>
				<CollectionsPopUp
					collectionModal={collectionModal}
					setCollectionModal={setCollectionModal}
					handleCollectionModal={handleCollectionModal}
					hideCollectionModal={hideCollectionModal}
					storeCollections={storeCollections}
					setCollectionStore={setCollectionStore}
					storePrevCollectionId={storePrevCollectionId}
					setPrevCollectionId={setPrevCollectionId}
					handleVinculum={handleVinculum}
				/>
			</div>

			<div>
				<TagsPopup 
					tagsModal={tagsModal}
					hideTagsPopup={hideTagsPopup}
					setSelectedTag={setSelectedTag}
					setCollectionStore={setCollectionStore}
					showSelectedTags={showSelectedTags}

				/>
			</div>
			<div>
				<ProductStatus 
					handleSalesChannel={handleSalesChannel}
					setSalesChannel={setSalesChannel}
					productDetailsVariant={productDetailsVariant}
				/>
			</div>

		</Fragment>
	)				
};

export default Aside;
