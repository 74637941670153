import { Fragment, useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/addProduct.module.css';
import axiosInstance from '../../../../utility/axios-instance';
import Aside from './aside/aside';
import GroupTitle from './GroupTitle';
import Media from './MediaFile';
import Pricing from './Pricing';
import Inventory from './Inventory';
import Variants from './Variants';
import AllPopUp from './AllPopUp';
import './css/specialImg.css';
import {
	handleGstRateFile,
	checkGroupTitleFile,
	handleDiscountFile,
	handleMRPFile,
} from './AllFunctions';
import SnackBar from '../../../../components/SnackBar';
import MultiPricing from './MultiPricing';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import IconButton from '@mui/material/IconButton';

const AddProduct = (props) => {
	const [error, setError] = useState(null);
	const [previousId, setPreviousId] = useState('');
	const [listDiscount, setListDiscount] = useState('');
	const [retailerDiscount, setRetailerDiscount] = useState('');
	const [interiorDiscount, setInteriorDiscount] = useState('');
	const [oemDiscount, setOemDiscount] = useState('');
	const [endUserDiscount, setEndUserDiscount] = useState('');
	const [groupImgUrl, setGroupImageUrl] = useState([]);
	const [groupImageUrlDimension2, setGroupImageUrlDimension2] = useState([]);
	const [showErrorMsg, setErrorMsg] = useState([]);
	const [showVinculumError, setVinculumError] = useState([]);
	const [storeFormData, setFormData] = useState();
	const [skuExistDB, setExistDB] = useState([]);
	const [storePrevImgId, setPrevImgId] = useState([]);
	const [storePrevParentId, setPrevParentId] = useState([]);
	const [storePrevGroupName, setPrevGroupName] = useState([]);
	const [country, setCountry] = useState([]);
	const [productId, setProductId] = useState('');
	const [limitPrice, setLimitPrice] = useState('');
	const [mainImageUrl, setMainImageUrl] = useState('');
	const [imageUrl2, setImageUrl2] = useState('');
	const [imageUrl3, setImageUrl3] = useState('');
	const [imageUrl4, setImageUrl4] = useState('');
	const [imageUrl5, setImageUrl5] = useState('');
	const [imageUrl6, setImageUrl6] = useState('');
	const [imageUrl7, setImageUrl7] = useState('');
	const [mainImageUrl1Dimension2, setMainImageUrlDimension2] = useState('');
	const [imageUrl2Dimension2, setImageUrl2Dimension2] = useState('');
	const [imageUrl3Dimension2, setImageUrl3Dimension2] = useState('');
	const [imageUrl4Dimension2, setImageUrl4Dimension2] = useState('');
	const [imageUrl5Dimension2, setImageUrl5Dimension2] = useState('');
	const [imageUrl6Dimension2, setImageUrl6Dimension2] = useState('');
	const [imageUrl7Dimension2, setImageUrl7Dimension2] = useState('');
	const [variants, setAllVariant] = useState([]);
	const [variantSku, setVariantSku] = useState([]);
	const [storeDiscountArr, setDiscountArr] = useState([]);
	const [productDetailsVariant, setProductDetails] = useState([]);
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
	const [multiPrice, setMultiPrice] = useState([]);
	const [mrp, setMrp] = useState('');
	const [gst, setGst] = useState('');
	const [locations, setLocations] = useState([])

	// aside
	const [vendor, setVendor] = useState('');
	const [unitType, setUnitType] = useState('');
	const [vendorCode, setVendorCode] = useState();
	const [brand, setBrand] = useState('');
	const [brandAbr, setBrandAbr] = useState('')
	const [storeCollections, setCollectionStore] = useState([]);
	const [storeSalesChannel, setSalesChannel] = useState([]);
	const [hsnToggle, setHsnToggle] = useState(true);
	const [hsnCodes, setHsnCodes] = useState([]);
	const navigate = useNavigate();

	const [showSelectedTags, setSelectedTags] = useState([]);

	const handleSubmit = (event) => {
		event.preventDefault();

		const AddProductBtn = document.getElementById('btn');
		AddProductBtn.style.backgroundColor = '#008060';
		AddProductBtn.style.color = '#fff';
		AddProductBtn.innerHTML = 'Product Create...';

		const option1Name = document.getElementById('option1Name').value;
		const option1Value = document.getElementById('option1Value').value;
		const option2Name = document.getElementById('option2Name').value;
		const option2Value = document.getElementById('option2Value').value;
		const option3Name = document.getElementById('option3Name').value;
		const option3Value = document.getElementById('option3Value').value;

		const optionNameArr = [];
		const optionValueArr = [];
		if (option1Value) {
			optionNameArr.push((option1Name).trim())
			optionValueArr.push((option1Value).trim())
		}

		if (option2Value) {
			optionNameArr.push(option2Name)
			optionValueArr.push(option2Value)
		}

		if (option3Value) {
			optionNameArr.push(option3Name)
			optionValueArr.push(option3Value)
		}

		const MergeTwoArr = optionValueArr.concat(storeCollections)
		const TagArr = [...showSelectedTags]
		TagArr.push(...MergeTwoArr, event.target.gst_rate.value);

		const isActive = document.getElementById('isActive').value;

		const data = {
			groupName: event.target.title.value ? event.target.title.value.trim() : undefined,
			// vendor: brand,
			productName: (event.target.productName.value).trim(),
			unit: event.target.unit.value ? event.target.unit.value : undefined,
			l1: storeCollections.length !== 0 ? storeCollections[0] : undefined,
			l2: storeCollections.length !== 0 ? storeCollections[1] : undefined,
			l3: storeCollections.length !== 0 ? storeCollections[2] : undefined,
			collections: storeCollections,
			sku: event.target.dsinCode.value.trim(),
			brandName: brand,
			brandAbr: brandAbr,
			modelNo: event.target.modelNumber.value ? event.target.modelNumber.value.trim() : undefined,
			hsnCode: event.target.hsnCode.value ? event.target.hsnCode.value : undefined,
			mrp: parseFloat(event.target.MRP.value),
			gst: parseInt(event.target.gst_rate.value),
			baseCost: event.target.costPerItem.value ? parseFloat(event.target.costPerItem.value.trim()) : 0,
			listingPrice: event.target.listingPrice.value ? parseFloat(event.target.listingPrice.value) : '',
			listingDiscount: listDiscount ? parseFloat(listDiscount) : undefined,
			retailPrice: event.target.retailer.value ? parseFloat(event.target.retailer.value) : '',
			retailDiscount: retailerDiscount ? parseFloat(retailerDiscount) : undefined,
			interiorPrice: event.target.interior.value ? parseFloat(event.target.interior.value) : '',
			interiorDiscount: interiorDiscount ? parseFloat(interiorDiscount) : undefined,
			variable1Type: option1Name,
			variable1Value: option1Value,
			variable2Type: option2Name ? option2Name.trim() : undefined,
			variable2Value: option2Value ? option2Value.trim() : undefined,
			variable3Type: option3Name ? option3Name.trim() : undefined,
			variable3Value: option3Value ? option3Value.trim() : undefined,
			productDescription: event.target.description.value ? event.target.description.value.trim() : undefined,
			countryOfOrigin: event.target.country.value ? event.target.country.value.trim() : undefined,
			customerCareAddress: event.target.customerCare.value ? event.target.customerCare.value.trim() : undefined,
			marketedBy: event.target.marketedBy.value ? event.target.marketedBy.value : undefined,
			includedComponents: event.target.includedComponents.value ? event.target.includedComponents.value.trim() : undefined,
			warranty: event.target.warranty.value ? event.target.warranty.value.trim() : undefined,
			weight: event.target.weight.value ? parseFloat(event.target.weight.value) : 0,
			materialType: event.target.material.value ? event.target.material.value.trim() : undefined,
			size: event.target.size.value ? event.target.size.value.trim() : '',
			finish: event.target.finish.value ? event.target.finish.value.trim() : undefined,
			loadCapacity: event.target.loadCapacity.value ? event.target.loadCapacity.value.trim() : undefined,
			shipmentTime: event.target.deliveryTime.value ? parseInt(event.target.deliveryTime.value) : undefined,
			mainImageUrl400x400: mainImageUrl ? mainImageUrl : undefined,
			imageUrl2400x400: imageUrl2 ? imageUrl2 : undefined,
			imageUrl3400x400: imageUrl3 ? imageUrl3 : undefined,
			imageUrl4400x400: imageUrl4 ? imageUrl4 : undefined,
			imageUrl5400x400: imageUrl5 ? imageUrl5 : undefined,
			imageUrl6400x400: imageUrl6 ? imageUrl6 : undefined,
			imageUrl7400x400: imageUrl7 ? imageUrl7 : undefined,
			availableQuantity: event.target.availableQuantity.value ? parseInt(event.target.availableQuantity.value) : undefined,
			mainImageUrl1200x1200: mainImageUrl1Dimension2 ? mainImageUrl1Dimension2 : undefined,
			imageUrl21200x1200: imageUrl2Dimension2 ? imageUrl2Dimension2 : undefined,
			imageUrl31200x1200: imageUrl3Dimension2 ? imageUrl3Dimension2 : undefined,
			imageUrl41200x1200: imageUrl4Dimension2 ? imageUrl4Dimension2 : undefined,
			imageUrl51200x1200: imageUrl5Dimension2 ? imageUrl5Dimension2 : undefined,
			imageUrl61200x1200: imageUrl6Dimension2 ? imageUrl6Dimension2 : undefined,
			imageUrl71200x1200: imageUrl7Dimension2 ? imageUrl7Dimension2 : undefined,
			vendorCode: vendorCode,
			vendorName: vendor,
			isSampleAvailable: event.target.sample.checked ? 'yes' : 'no',
			isTracked: event.target.trackQty.checked,
			isTaxable: event.target.chargeTax.checked,
			isCustomOrder: event.target.availableOnRequest.value,
			backOrder: event.target.continueSelling.checked ? 'CONTINUE' : 'DENY',
			isActive: isActive === 'Active' ? true : false,
			salesChannel: storeSalesChannel,
			tags: TagArr,
			bulkPackingType1: event.target.bulkType1.value ? event.target.bulkType1.value : undefined,
			bulkPackingValue1: event.target.bulkVal1.value ? event.target.bulkVal1.value : undefined,
			bulkPackingType2: event.target.bulkType2.value ? event.target.bulkType2.value : undefined,
			bulkPackingValue2: event.target.bulkVal2.value ? event.target.bulkVal2.value : undefined,
			// shopfiy field
			optionsName: optionNameArr,
			optionsValue: optionValueArr,
			oemPrice: event.target.oem.value ? parseFloat(event.target.oem.value) : '',
			oemDiscount: oemDiscount ? parseFloat(oemDiscount) : undefined,
			endUserPrice: event.target.endUser.value ? parseFloat(event.target.endUser.value) : '',
			endUserDiscount: endUserDiscount ? parseFloat(endUserDiscount) : undefined,
			imgId: storePrevImgId.length !== 0 ? storePrevImgId[0] : undefined,
			prevParentId: storePrevParentId.length !== 0 ? storePrevParentId[0] : undefined,
			multiPriceData: multiPrice,
		}


		setFormData(data)

		axiosInstance
			.post('/product/create', {
				...data
			})
			.then((res) => {
				if (res.data.data === 'This sku already exist in the database') {
					AddProductBtn.innerHTML = 'Add Product';
					document.getElementById('SkuBox').style.display = 'flex';
					document.getElementById('GroupNameBox').style.display = 'none';
					if (document.getElementById('showImages')) {
						document.getElementById('showImages').style.display = 'none';
					}
					setExistDB(res.data)
					return;
				}

				if (res.data.status === 'success') {
					setProductId(res.data.data._id);
				}

				if (res.data.hasOwnProperty('shopify')) {
					if (res.data.shopify !== null) {
						if (res.data.shopify.hasOwnProperty('errorMessage')) {
							AddProductBtn.innerHTML = 'Add Product';
							document.getElementById('GroupNameBox').style.display = 'none';
							const Notification = document.getElementById('platforms');
							Notification.style.display = 'block';
							if (res.data.vinculum.hasOwnProperty('errorMessage')) {
								setVinculumError({ errorMsg: res.data.vinculumDataCreated.errorMessage })
								if (res.data.shopify.errorMessage === "invalid decimal ''") {
									const obj = {
										fieldName: res.data.shopify.fieldName,
										message: `Please enter any value`,
										platform: `Please enter any value in this field`
									}
									setErrorMsg(obj)
								} else {
									if (res.data.shopify.fieldName) {
										const obj = {
											fieldName: res.data.shopify.fieldName,
											message: `Please enter text input`,
											platform: `Please enter text input in this field`
										}
										setErrorMsg(obj);
									} else {
										setErrorMsg({ platform: res.data.shopify.errorMessage })
									}
								}
							} else {
								if (res.data.shopify.errorMessage === "invalid decimal ''") {
									const obj = {
										fieldName: res.data.shopify.fieldName,
										message: `Please enter any value`,
										platform: `Please enter any value in this field`
									}
									setErrorMsg(obj);
								} else {
									if (res.data.shopify.hasOwnProperty('fieldName')) {
										const obj = {
											fieldName: res.data.shopify.fieldName,
											message: 'Please enter text input',
											platform: `Please enter text input in this field`
										}
										setErrorMsg(obj);
									} else {
										setErrorMsg({ platform: res.data.shopify.errorMessage })
									}

								}
							}
						} else if (!res.data.vinculumDataCreated.created) {
							document.getElementById('GroupNameBox').style.display = 'none';
							if (document.getElementById('showImages')) {
								document.getElementById('showImages').style.display = 'none';
							}
							setVinculumError({ errorMsg: res.data.vinculumDataCreated.errorMessage })
						}
					}
				}
				let form = document.getElementById("formData");
				AddProductBtn.innerHTML = 'Product Created';
				form.reset();
				setBrand('');
				setVendor('');
				setDiscountArr([]);
				setMultiPrice([]);
				setCollectionStore([]);
				setGroupImageUrl([]);
				setMainImageUrl('');
				setImageUrl2('');
				setImageUrl3('');
				setImageUrl4('');
				setImageUrl5('');
				setImageUrl6('');
				setImageUrl7('');
				setProductDetails([]);
				document.getElementById('GroupNameBox').style.display = 'none';
				if (document.getElementById('showImages')) {
					document.getElementById('showImages').style.display = 'none';
				}
				document.getElementById('product').style.display = 'none';
				document.getElementById('SkuBox').style.display = 'none';
				document.getElementById('platforms').style.display = 'block';
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			})
	}

	document.addEventListener('mouseup', function (e) {
		let container = document.getElementById(previousId);
		if (container !== null) {
			if (!container.contains(e.target)) {
				document.getElementById(previousId).style.display = 'none';
			}
		}
	});


	const handleDiscount = (event, Id) => {
		handleDiscountFile(
			event,
			Id,
			storeDiscountArr,
			setListDiscount,
			setDiscountArr,
			setRetailerDiscount,
			setInteriorDiscount,
			setOemDiscount,
			setEndUserDiscount,
			setLimitPrice,
		)
	}

	const handleGstRate = (event) => {
		handleGstRateFile(
			event,
			setDiscountArr,
			setListDiscount,
			setRetailerDiscount,
			setInteriorDiscount,
			setOemDiscount,
			setEndUserDiscount,
			setLimitPrice,
		);
		setGst(document.getElementById('gstRate').value);
	}

	const handleMRP = () => {
		handleMRPFile(
			setDiscountArr,
			setListDiscount,
			setRetailerDiscount,
			setInteriorDiscount,
			setOemDiscount,
			setEndUserDiscount,
			setLimitPrice,
		);
		setMrp(document.getElementById('mrp').value);
	}

	const checkGroupTitle = (title) => {
		checkGroupTitleFile(
			title,
			setPrevGroupName,
			setPrevImgId,
			setPrevParentId,
			setMainImageUrl
		);
	}

	const isImgUrl = async (url) => {
		const img = new Image();
		img.src = url;
		return new Promise((resolve) => {
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
		});
	}

	const handleImg = async (event) => {
		if (event.target.value) {
			const res = await isImgUrl(event.target.value);
			if (!event.target.checkValidity()) {
				alert("Please enter valid Image URL");
				return
			} else {
				if (res) {
					if (groupImgUrl.length === 0) {
						setGroupImageUrl([event.target.value]);
						setMainImageUrl(event.target.value);
					} else {
						if (groupImgUrl.length === 1) {
							setImageUrl2(event.target.value);
							setGroupImageUrl([...groupImgUrl, event.target.value]);
						}

						if (groupImgUrl.length === 2) {
							setImageUrl3(event.target.value);
							setGroupImageUrl([...groupImgUrl, event.target.value]);
						}

						if (groupImgUrl.length === 3) {
							setImageUrl4(event.target.value);
							setGroupImageUrl([...groupImgUrl, event.target.value]);
						}

						if (groupImgUrl.length === 4) {
							setImageUrl5(event.target.value);
							setGroupImageUrl([...groupImgUrl, event.target.value]);
						}

						if (groupImgUrl.length === 5) {
							setImageUrl6(event.target.value);
							setGroupImageUrl([...groupImgUrl, event.target.value]);
						}

						if (groupImgUrl.length === 6) {
							setImageUrl7(event.target.value);
							setGroupImageUrl([...groupImgUrl, event.target.value]);
							document.getElementById('dimension1').style.display = 'none';
						}
					}
					event.target.value = '';
				} else {
					alert("Please enter valid Image URL");
					return;
				}
			}
		}
	}

	const handleImageDimension = async (event) => {
		if (event.target.value) {
			const res = await isImgUrl(event.target.value);
			if (!event.target.checkValidity()) {
				alert("Please enter valid Image URL");
				return
			} else {
				if (res) {
					if (groupImageUrlDimension2.length === 0) {
						setGroupImageUrlDimension2([event.target.value]);
						setMainImageUrlDimension2(event.target.value);
					} else {
						if (groupImageUrlDimension2.length === 1) {
							setImageUrl2Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2, event.target.value]);
						}

						if (groupImageUrlDimension2.length === 2) {
							setImageUrl3Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2, event.target.value]);
						}

						if (groupImageUrlDimension2.length === 3) {
							setImageUrl4Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2, event.target.value]);
						}

						if (groupImageUrlDimension2.length === 4) {
							setImageUrl5Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2, event.target.value]);
						}

						if (groupImageUrlDimension2.length === 5) {
							setImageUrl6Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2, event.target.value]);
						}

						if (groupImageUrlDimension2.length === 6) {
							setImageUrl7Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2, event.target.value]);
							document.getElementById('dimension2').style.display = 'none';
						}
					}
					event.target.value = '';
				} else {
					alert("Please enter valid Image URL");
					return;
				}
			}
		}
	}

	const handleGroupTitle = (event) => {
		if (event.target.value) {
			document.getElementById('btn').style.backgroundColor = '#008060';
			document.getElementById('btn').style.color = '#fff';
			document.getElementById('btn').style.border = 0;
			document.getElementById('platforms').style.display = 'none';
			if (storePrevGroupName.length !== 0) {
				if (storePrevGroupName !== event.target.value) {
					document.getElementById('GroupNameBox').style.display = 'none';
					checkGroupTitle(event.target.value);
				} else {
					document.getElementById('GroupNameBox').style.display = 'block';
				}
			} else {
				checkGroupTitle(event.target.value)
			}
		} else {
			document.getElementById('btn').style.backgroundColor = 'transparent';
			document.getElementById('btn').style.color = '';
			document.getElementById('btn').style.border = '1px solid lightgray';
		}
		document.getElementById('platforms').style.display = 'none';
	}

	const handleError = (event) => {
		const fetchPlatformId = document.getElementById(event.target.id);
		const attrVal = fetchPlatformId.getAttribute('data-toggle');
		const showError = fetchPlatformId.parentNode.parentNode.parentElement.children[1];
		if (attrVal === 'false') {
			showError.style.display = 'flex';
			fetchPlatformId.setAttribute('data-toggle', true);
		} else {
			showError.style.display = 'none';
			fetchPlatformId.setAttribute('data-toggle', false);
		}
	}

	const handleCreateProduct = () => {
		axiosInstance
			.post('/product/create/specificPlatform', {
				...storeFormData
			})
			.then((res) => {
				setErrorMsg([])
			})
	}

	const handleCreateProductInVinculum = () => {
		axiosInstance
			.post('/product/create/vinculum', {
				...storeFormData
			})
			.then((res) => {
				setVinculumError([])
			})
	}

	const handlePrevButton = (event) => {
		document.getElementById('hideButtons').style.display = 'none';
		axiosInstance
			.get(`/product/fetchAllVariant/${storePrevGroupName}`)
			.then((res) => {
				setAllVariant(res.data.data);
			})
	}

	const handleChangeTitle = (event) => {
		document.getElementById(event.target.id).style.display = 'none';
		document.getElementById('changeTitle').style.display = 'flex';
	}

	const handleChangeTitleName = (event) => {
		const TitleName = document.getElementById('titleName').value;
		if (TitleName) {
			setPrevGroupName([])
			document.getElementById('changeTitle').style.display = 'none';
			document.getElementById('GroupNameBox').style.display = 'none';
			document.getElementById('groupTitle').value = TitleName;
		}
	}

	const handleVinculum = () => {
		
		setUnitType(document.getElementById('unit').value ? document.getElementById('unit').value : '');
		
		// if(
		// 	document.getElementById('hsnCode').value &&
		// 	document.getElementById('vendors').value &&
		// 	document.getElementById('collection3').value
		// ) {
		// 	document.getElementById('vinculum').checked = true;	
		// }
	}

	const handleDatabase = () => {
		// if (
		// 	document.getElementById('groupTitle').value &&
		// 	document.getElementById('mrp').value &&
		// 	document.getElementById('listDiscount').value &&
		// 	document.getElementById('dsinCode').value &&
		// 	document.getElementById('product_title').value &&
		// 	document.getElementById('option1Name').value &&
		// 	document.getElementById('option1Value').value &&
		// 	document.getElementById('gstRate').value
		// ) {
		// 	document.getElementById('database').checked = true;		
		// }

		// if(
		// 	document.getElementById('hsnCode').value &&
		// 	document.getElementById('vendors').value &&
		// 	document.getElementById('collection3').value
		// ) {
		// 	document.getElementById('vinculum').checked = true;	
		// }

		handleMRP();
	}

	const handleHsnCode = (event) => {
		const hsnCodePercentage = event.target.options[event.target.selectedIndex].dataset.percentage;
		document.getElementById('gstRate').value = `${hsnCodePercentage}.0`;
		handleMRP();
		handleVinculum();
	}

	useEffect(() => {
		if (hsnToggle) {
			axiosInstance
				.get('/others/hsnCodes')
				.then((res) => {
					setHsnCodes(res.data.data);
					setHsnToggle(false);
				})
		}
	}, [hsnToggle]);

	useEffect(() => {
		axiosInstance
			.get('/others/countryOfOrigin')
			.then((res) => {
				setCountry(res.data.data);
			})
	}, []);


	const handleRemove = (index) => () => {
		setMultiPrice(multiPrice.filter((s, sindex) => index !== sindex));
	}

	const addMultiPricing = (e) => {
		setMultiPrice([...multiPrice, { locationCode: '', listingPrice: '', retailerPrice: '', interiorPrice: '', oemPrice: '', endUserPrice: '', listingDiscount: '', retailerDiscount: '', interiorDiscount: '', oemDiscount: '', endUserDiscount: '' }])
	}

	useEffect(() => {
        axiosInstance.get(`/location/locations?text=`)
            .then(res => {
                const locations = [];
                for (let location of res?.data?.data) {
                    locations.push(location?.locationCode)
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

	return (
		<Fragment>
			<SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
			<div className={Classes.container} id="color">
				<form
					method='post'
					onSubmit={handleSubmit}
					encType="multipart/form-data"
					id='formData'
				>
					<div className={Classes.AddProductHeader} id='header' style={{ justifyContent: 'end', marginTop: -25 }}>
						{/* <div className={Classes.AddProductHeaderContent}>
							<div>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
										navigate(-1);
									}}
								>
									<BiArrowBack />
								</button>
							</div>
							<div className="mb-0">
								<strong className={Classes.AddProductBtn}>
									Add Product
								</strong>
							</div>
						</div> */}

						<div>
							<button
								id='btn'
								type="submit"
								className={Classes.SaveBtn}
								style={{ padding: '4px 10px', fontSize: 15, border: 0 }}
							>
								Add Product
							</button>
						</div>
					</div>

					{error ? (
						<Alert variant="danger" onClose={() => setError(false)} dismissible>
							<Alert.Heading>{error}</Alert.Heading>
							<p>Try reloading!</p>
						</Alert>
					) : null}

					<AllPopUp
						showErrorMsg={showErrorMsg}
						storePrevGroupName={storePrevGroupName}
						handlePrevButton={handlePrevButton}
						handleChangeTitleName={handleChangeTitleName}
						handleChangeTitle={handleChangeTitle}
						skuExistDB={skuExistDB}
						handleError={handleError}
						handleCreateProduct={handleCreateProduct}
						showVinculumError={showVinculumError}
						handleCreateProductInVinculum={handleCreateProductInVinculum}
						productId={productId}
						variants={variants}
						variantSku={variantSku}
						setVariantSku={setVariantSku}
						setProductDetails={setProductDetails}
						setMainImageUrl={setMainImageUrl}
						setImageUrl2={setImageUrl2}
						setImageUrl3={setImageUrl3}
						setImageUrl4={setImageUrl4}
						setImageUrl5={setImageUrl5}
						setImageUrl6={setImageUrl6}
						setImageUrl7={setImageUrl7}
						setGroupImageUrl={setGroupImageUrl}
						setListDiscount={setListDiscount}
						setRetailerDiscount={setRetailerDiscount}
						setInteriorDiscount={setInteriorDiscount}
						setOemDiscount={setOemDiscount}
						setEndUserDiscount={setEndUserDiscount}
						setDiscountArr={setDiscountArr}
						setSalesChannel={setSalesChannel}
						setBrand={setBrand}
						setBrandAbr={setBrandAbr}
						setVendor={setVendor}
						setVendorCode={setVendorCode}
						setCollectionStore={setCollectionStore}
						setSelectedTag={setSelectedTags}
						setPrevParentId={setPrevParentId}
					/>

					<div className={Classes.AddProductContainer}>
						<div className={Classes.containBoxes} >
							<div className={Classes.mainContainer}>
								<Pricing
									showErrorMsg={showErrorMsg}
									handleDiscount={handleDiscount}
									storeDiscountArr={storeDiscountArr}
									handleDatabase={handleDatabase}
									limitPrice={limitPrice}
									handleMRP={handleMRP}
									productDetailsVariant={productDetailsVariant}
									hsnCodes={hsnCodes}
									handleGstRate={handleGstRate}
									handleVinculum={handleVinculum}
									handleHsnCode={handleHsnCode}
									unitType={unitType}
									handleGroupTitle={handleGroupTitle}
									multiPrice={multiPrice}
									setMultiPrice={setMultiPrice}
									setMrp={setMrp}
									mrp={mrp}
								/>
								<div className={Classes.Box} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 12px' }}>
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: 500 }}>Location Wise Pricing</div>
									{mrp !== '' ? (
										<IconButton size='large' onClick={(e) => addMultiPricing(e)}><ControlPointIcon /></IconButton>
									): <span style={{color:'red'}}>*MRP Required</span>}
								</div>

								{multiPrice && multiPrice.map((item, index) => (
									<MultiPricing multiPriceArr={multiPrice} mrp={mrp} gst={gst} details={item} removeSection={handleRemove(index)} locations={locations} />
								))}

								<Variants
									showErrorMsg={showErrorMsg}
									country={country}
									handleDatabase={handleDatabase}
									productDetailsVariant={productDetailsVariant}
								/>

								<Inventory
									showErrorMsg={showErrorMsg}
									hsnCodes={hsnCodes}
									handleGstRate={handleGstRate}
									handleDatabase={handleDatabase}
									handleVinculum={handleVinculum}
									handleHsnCode={handleHsnCode}
									productDetailsVariant={productDetailsVariant}
								/>

								<GroupTitle
									showErrorMsg={showErrorMsg}
									productDetailsVariant={productDetailsVariant}
								/>

								<Media
									showErrorMsg={showErrorMsg}
									handleImg={handleImg}
									handleImageDimension={handleImageDimension}
									groupImageUrl={groupImgUrl}
									setGroupImageUrl={setGroupImageUrl}
									setMainImageUrl={setMainImageUrl}
									setImageUrl2={setImageUrl2}
									setImageUrl3={setImageUrl3}
									setImageUrl4={setImageUrl4}
									setImageUrl5={setImageUrl5}
									setImageUrl6={setImageUrl6}
									setImageUrl7={setImageUrl7}
									groupImageUrlDimension2={groupImageUrlDimension2}
									setGroupImageUrlDimension2={setGroupImageUrlDimension2}
									setMainImageUrlDimension2={setMainImageUrlDimension2}
									setImageUrl2Dimension2={setImageUrl2Dimension2}
									setImageUrl3Dimension2={setImageUrl3Dimension2}
									setImageUrl4Dimension2={setImageUrl4Dimension2}
									setImageUrl5Dimension2={setImageUrl5Dimension2}
									setImageUrl6Dimension2={setImageUrl6Dimension2}
									setImageUrl7Dimension2={setImageUrl7Dimension2}
									productDetailsVariant={productDetailsVariant}
								/>
							</div>
						</div>

						<div className={Classes.asideContainer}>
							<Aside
								showSelectedTags={showSelectedTags}
								setSelectedTag={setSelectedTags}
								vendor={vendor}
								setVendor={setVendor}
								setVendorCode={setVendorCode}
								brand={brand}
								setBrand={setBrand}
								setBrandAbr={setBrandAbr}
								storeCollections={storeCollections}
								setCollectionStore={setCollectionStore}
								storeSalesChannel={storeSalesChannel}
								setSalesChannel={setSalesChannel}
								handleVinculum={handleVinculum}
								productDetailsVariant={productDetailsVariant}
							/>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default AddProduct;
