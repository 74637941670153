import { Fragment, useState } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import '../createOrders/css/styles.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBar from '../../../components/SnackBar';


const DeleteSalesUser = (props)=> {
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const { 
        fetchCustomers,
        showModal,
        hideDialog,
        userId
    } = props;

    const handleDeleteUser = () => {
        setLoading(true);
        axiosInstance
			.put(`/admin/deleteSalesUserFromCustomer/${userId}`)
			.then((res) => {
                setLoading(false);
                hideDialog();
                fetchCustomers();
                const successMessage = 'User Deleted Successfully';
                setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((err) => {
				console.log(err.response.data.message);
                const errorMessage = err?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
			})
    }


    return (
        <Fragment>
            {showModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'15%',bottom:'30%',left: "35%",right:"35%"}}
                >   
                    <div style={{padding:20}}>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <HighlightOffIcon 
                                sx={{
                                    color:'rgb(211, 47, 47)',
                                    fontSize:'75px',
                                    marginTop: '20px'
                                }}
                            />
                        </div>

                        <div style={{marginTop:25,textAlign:'center',fontSize:25}}>
                            <p>Are You Sure ?</p>
                        </div>

                        <div style={{textAlign:'center'}}>
                            <p>Do you really want to delete this Sales User From Customers?</p>
                        </div>

                        <div 
                            style={{
                                margin:"25px auto",
                                width:'70%',
                                display:"flex",
                                justifyContent: "space-around"
                            }}
                        >
                            <Button 
                                variant="contained" 
                                style={{backgroundColor:'#E12D39',width:120,borderRadius:20}} 
                                onClick={handleDeleteUser}
                                startIcon={<DeleteIcon />}
                            >
                                DELETE
                            </Button>
                            <Button 
                                variant="contained" 
                                onClick={hideDialog}
                                style={{backgroundColor:'#627D98',width:120,borderRadius:20}}
                            >
                                CANCEL
                            </Button>
                        </div>
                    </div>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default DeleteSalesUser;