
import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';

const Variants = (props) => {
    const {
        showErrorMsg,
        country,
        handleDatabase,
        productDetailsVariant
    }= props;

    const handleShowField = (event) => {
        if (event.target.checked) {
            document.getElementById('nonMandetoryfield').style.display = 'block';
            document.getElementById('allFieldText').innerHTML = 'Hide All Fields';
        }else{
            document.getElementById('allFieldText').innerHTML = 'Show All Fields';
            document.getElementById('nonMandetoryfield').style.display = 'none';
        }
    }
	
	return (
		<Fragment>
            <div className={Classes.Pricing}>
                <div className={Classes.Boxes}>
                    <div 
                        style={{
                            display:'flex',
                            justifyContent:'space-between'
                        }}
                    >
                        <h6>Variants</h6>
                    </div>
                    <div className={Classes.Field}>  
                    <div>
                        {showErrorMsg.length !== 0 && (
                            <div>
                                {showErrorMsg.fieldName === 'title' && (
                                    <p>{showErrorMsg.message}</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                    <div>
                        <div className={Classes.variantField}>
                            <div style={{width:'48%'}}>
                                <div style={{display:'flex'}}>
                                    <p>Option Name</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        id="option1Name"
                                        required={true}
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        autoComplete='off'
                                        placeholder='Option Name 1'
                                        onBlur={handleDatabase}
                                        defaultValue={
                                            productDetailsVariant.length !== 0 ?
                                            productDetailsVariant[0].variable1Type :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 ?
                                            productDetailsVariant[0].variable1Type :
                                            ''
                                        }
                                    />
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'options' && (
                                                <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{width:'48%'}}>
                                <div style={{display:'flex'}}>
                                    <p>Option Value</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        id="option1Value"
                                        autoComplete='off'
                                        required={true}
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        placeholder='Option Value 1'
                                        onBlur={handleDatabase}
                                        defaultValue={
                                            productDetailsVariant.length !== 0 ?
                                            productDetailsVariant[0].variable1Value :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 ?
                                            productDetailsVariant[0].variable1Value :
                                            ''
                                        }
                                    />
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'options' && (
                                                <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={Classes.variantField}>
                            <div style={{width:'48%'}}>
                                <p>Option Name</p>
                                <div>
                                    <input
                                        type="text"
                                        id="option2Name"
                                        autoComplete='off'
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        placeholder='Option Name 2'
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable2Type ?
                                            productDetailsVariant[0].variable2Type :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable2Type ?
                                            productDetailsVariant[0].variable2Type :
                                            ''
                                        }
                                    />
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'options' && (
                                                <p className={Classes.Msg}>
                                                    {showErrorMsg.message}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{width:'48%'}}>
                                <p>Option Value</p>
                                <div>
                                    <input
                                        type="text"
                                        id="option2Value"
                                        autoComplete='off'
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        placeholder='Option Value 2'
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable2Value ?
                                            productDetailsVariant[0].variable2Value :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable2Value ?
                                            productDetailsVariant[0].variable2Value :
                                            ''
                                        }
                                    />
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'options' && (
                                                <p className={Classes.Msg}>
                                                    {showErrorMsg.message}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={Classes.variantField}>
                            <div style={{width:'48%'}}>
                                <p>Option Name</p>
                                <div>
                                    <input
                                        type="text"
                                        id="option3Name"
                                        autoComplete='off'
                                        placeholder='Option Name 3'
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable3Type ?
                                            productDetailsVariant[0].variable3Type :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable3Type ?
                                            productDetailsVariant[0].variable3Type :
                                            ''
                                        }
                                    />
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'options' && (
                                                <p className={Classes.Msg}>
                                                    {showErrorMsg.message}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{width:'48%'}}>
                                <p>Option Value</p>
                                <div>
                                    <input
                                        type="text"
                                        id="option3Value"
                                        autoComplete='off'
                                        placeholder='Option Value 3'
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable3Value ?
                                            productDetailsVariant[0].variable3Value :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].variable3Value ?
                                            productDetailsVariant[0].variable3Value :
                                            ''
                                        }
                                    />
                                </div>
                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'options' && (
                                                <p className={Classes.Msg}>
                                                    {showErrorMsg.message}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop:15, display:'flex'}}>
                            <div style={{marginRight:5}}>
                                <input 
                                    type="checkbox" 
                                    onChange={(e) => handleShowField(e)}
                                />
                            </div>
                            <p 
                                style={{
                                    marginTop:3,
                                    fontWeight:600
                                }} 
                                id='allFieldText'
                            >
                                Show All Fields
                            </p>
                        </div>

                        <div 
                            id='nonMandetoryfield' 
                            className={Classes.RestField}
                        >
                            <div className={Classes.variantField}>
                                <div style={{width:'48%'}}>
                                    <div style={{display:'flex'}}>
                                        <p>Size</p>
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            id="size"
                                            name='size'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            autoComplete='off'
                                            placeholder='Size'
                                            defaultValue={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].size ?
                                                productDetailsVariant[0].size :
                                                ''
                                            }
                                            key={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].size ?
                                                productDetailsVariant[0].size :
                                                ''
                                            }
                                        />
                                    </div>

                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'size' && (
                                                    <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%'}}>
                                    <p>Finish</p>
                                    <div>
                                        <input
                                            name='finish'
                                            type="text"
                                            id="finish"
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Finish'
                                            defaultValue={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].finish ?
                                                productDetailsVariant[0].finish :
                                                ''
                                            }
                                            key={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].finish ?
                                                productDetailsVariant[0].finish :
                                                ''
                                            }
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'finish' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.variantField}>
                                {/* {materialType.length !== 0 && ( */}
                                    <div style={{width:'48%'}}>
                                        <p>Material</p>
                                        <div>
                                            <input
                                                name="material"
                                                type="text"
                                                id="material"
                                                autoComplete='off'
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                                placeholder='Material Type'
                                                defaultValue={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].materialType ?
                                                    productDetailsVariant[0].materialType :
                                                    ''
                                                }
                                                key={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].materialType ?
                                                    productDetailsVariant[0].materialType :
                                                    ''
                                                }
                                            />
                                        </div>
                                        <div>
                                            {showErrorMsg.length !== 0 && (
                                                <div>
                                                    {showErrorMsg.fieldName === 'material_type' && (
                                                        <p className={Classes.Msg}>
                                                            {showErrorMsg.message}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                {/* )} */}

                                <div style={{width:'48%'}}>
                                    <p>Included Components</p>
                                    <div>
                                        <input
                                            name='includedComponents'
                                            type="text"
                                            autoComplete='off'
                                            id="includedComponents"
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Included components'
                                            defaultValue={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].includedComponents ?
                                                productDetailsVariant[0].includedComponents :
                                                ''
                                            }
                                            key={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].includedComponents ?
                                                productDetailsVariant[0].includedComponents :
                                                ''
                                            }
                                        />
                                    </div>

                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'included_components' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.variantField}>
                                <div style={{width:'48%'}}>
                                    <p>Load Capacity</p>
                                    <div>
                                        <input
                                            name='loadCapacity'
                                            type="text"
                                            id="loadCapacity"
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Load Capacity'
                                            defaultValue={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].loadCapacity ?
                                                productDetailsVariant[0].loadCapacity :
                                                ''
                                            }
                                            key={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].loadCapacity ?
                                                productDetailsVariant[0].loadCapacity :
                                                ''
                                            }
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'loadCapacity' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{width:'48%'}}>
                                    <p>Weight (kg)</p>
                                    <div>
                                        <input
                                            name='weight'
                                            type="number"
                                            id="weight"
                                            step="0.01"
                                            onKeyDown={
                                                e => ( e.keyCode === 69) 
                                                && e.preventDefault()
                                            }
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Weight'
                                            defaultValue={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].weight ?
                                                productDetailsVariant[0].weight :
                                                ''
                                            }
                                            key={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].weight ?
                                                productDetailsVariant[0].weight :
                                                ''
                                            }
                                        />
                                    </div>

                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'weight' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.variantField}>
                                {/* <div style={{width:'48%'}}>
                                    <p style={{fontWeight:600}}>Sample</p>
                                    <select 
                                        name="sample" 
                                        id="sample"
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].isSampleAvailable ?
                                            productDetailsVariant[0].isSampleAvailable :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].isSampleAvailable ?
                                            productDetailsVariant[0].isSampleAvailable :
                                            ''
                                        }
                                    >
                                        <option value="no">NO</option>
                                        <option value="yes">YES</option>
                                    </select>

                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'sample' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div> */}
                                <div style={{width:'48%'}}>
                                    <div style={{display:'flex'}}>
                                        <p>Country Of Origin</p>
                                    </div>
                                    <div>
                                        {country.length !== 0 && (
                                            <select 
                                                name='country' 
                                                id='country' 
                                                // defaultValue=''
                                                defaultValue={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].countryOfOrigin ?
                                                    productDetailsVariant[0].countryOfOrigin :
                                                    ''
                                                }
                                                key={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].countryOfOrigin ?
                                                    productDetailsVariant[0].countryOfOrigin :
                                                    ''
                                                }
                                                style={{
                                                    marginTop:0,
                                                    paddingBottom:7,
                                                    paddingTop:6
                                                }}
                                            >
                                                <option 
                                                    value='' 
                                                    disabled={true}
                                                >
                                                    Select Country
                                                </option>

                                                <option value='India'>
                                                    India
                                                </option>

                                                {country.map((countrys) => (
                                                    <option 
                                                        key={countrys._id} 
                                                        value={countrys.country}
                                                    >
                                                        {countrys.country}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'country_of_origin' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                            </div>
                                

                                <div style={{width:'48%'}}>
                                    <p>Warranty (Year/s)</p>
                                    <div>
                                        <input
                                            name='warranty'
                                            type="text"
                                            id="warranty"
                                            step="0.01"
                                            autoComplete='off'
                                            className={[
                                                Classes.searchField, 
                                                Classes.InputField
                                            ].join(' ')}
                                            placeholder='Warranty'
                                            defaultValue={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].warranty ?
                                                productDetailsVariant[0].warranty :
                                                ''
                                            }
                                            key={
                                                productDetailsVariant.length !== 0 && 
                                                productDetailsVariant[0].warranty ?
                                                productDetailsVariant[0].warranty :
                                                ''
                                            }
                                            
                                        />
                                    </div>
                                    <div>
                                        {showErrorMsg.length !== 0 && (
                                            <div>
                                                {showErrorMsg.fieldName === 'warranty' && (
                                                    <p className={Classes.Msg}>
                                                        {showErrorMsg.message}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.variantField}>
                                <div style={{width:'48%'}}>
                                    <div className={Classes.Field}>  
                                        <div>
                                            <p>Marketed By</p>
                                        </div>
                                        <div>
                                            <input
                                                name='marketedBy'
                                                type="text"
                                                autoComplete='off'
                                                id="marketedBy"
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                                defaultValue={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].marketedBy ?
                                                    productDetailsVariant[0].marketedBy :
                                                    ''
                                                }
                                                key={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].marketedBy ?
                                                    productDetailsVariant[0].marketedBy :
                                                    ''
                                                }
                                            />
                                        </div>

                                        <div>
                                            {showErrorMsg.length !== 0 && (
                                                <div>
                                                    {showErrorMsg.fieldName === 'marketedBy' && (
                                                        <p className={Classes.Msg}>
                                                            {showErrorMsg.message}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div style={{width:'48%'}}>
                                    <div className={Classes.Field}>  
                                        <div>
                                            <p>Customer Care</p>
                                        </div>
                                        <div>
                                            <input
                                                name='customerCare'
                                                type="text"
                                                autoComplete='off'
                                                id="customerCare"
                                                className={[
                                                    Classes.searchField, 
                                                    Classes.InputField
                                                ].join(' ')}
                                                defaultValue={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].customerCareAddress ?
                                                    productDetailsVariant[0].customerCareAddress :
                                                    ''
                                                }
                                                key={
                                                    productDetailsVariant.length !== 0 && 
                                                    productDetailsVariant[0].customerCareAddress ?
                                                    productDetailsVariant[0].customerCareAddress :
                                                    ''
                                                }
                                            />
                                        </div>

                                        <div>
                                            {showErrorMsg.length !== 0 && (
                                                <div>
                                                    {showErrorMsg.fieldName === 'customer_care' && (
                                                        <p className={Classes.Msg}>
                                                            {showErrorMsg.message}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};


export default Variants;
