import { Fragment, useEffect, useState } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';
import GradeIcon from '@mui/icons-material/Grade';

const EndVisitModal = (props)=> {
    const [loading,setLoading] = useState(false);
    const [customer,setCustomer] = useState([]);

    const { 
        errorMessage,
        setErrorMessage,
        fetchCustomers,
        endVisitModal,
        hideEndVisitModal,
        selectedRatings,
        setSelectedRatings,
        visitRemarks,
        setVisitRemarks,
        endVisitedCustomer
    } = props;

    useEffect(() => {
        if(endVisitedCustomer) {
            axiosInstance
                .get(`/fieldSalesVisit/visitDetails/${endVisitedCustomer}`)
                .then((res) => {
                    setCustomer(res.data.data)
                }).catch((err) => {
                    console.log(err,'err')
                })
        }
    },[endVisitedCustomer])

    const handleSubmit = async (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn')
        button.innerHTML = 'End Visiting..';

        let data = {}

        if (customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit') {
            data = {
                endTime: Date.now(),
                ratings: selectedRatings ? selectedRatings : undefined,
                remarks: visitRemarks ? visitRemarks : undefined,
                storeFrontImage: event.target.storeFrontImage.files.length !== 0 ? event.target.storeFrontImage.files[0] : undefined,
                proofOfVisitImage: event.target.proofOfVisitImage.files.length !== 0 ? event.target.proofOfVisitImage.files[0] : undefined
            }
        } else {
            data = {
                endTime: Date.now(),
                ratings: selectedRatings ? selectedRatings : undefined,
                remarks: visitRemarks ? visitRemarks : undefined
            }
        }

        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });


        navigator.geolocation.getCurrentPosition((pos) => {
            const geoLocation = {
                accuracy: pos.coords.accuracy,
                altitude: pos.coords.altitude,
                altitudeAccuracy: pos.coords.altitudeAccuracy,
                heading: pos.coords.heading,
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                speed: pos.coords.speed,
            };

            formData.append('geoLocation', JSON.stringify(geoLocation));
        })

        setTimeout(() => {
            axiosInstance
            .post(`/fieldSalesVisit/updateFieldVisit/${endVisitedCustomer}`, formData)
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                hideEndVisitModal();
                fetchCustomers();
                setLoading(false);
                setErrorMessage('');
                setVisitRemarks('');
                button.innerHTML = 'Visit End Completed';
            }).catch((err) => {
                button.innerHTML = 'Submit';
                setLoading(false);
            })
        }, 1000);
    }

    const handleVisitRemarks = (event) => {
        if (event.target.value) {
            setVisitRemarks(event.target.value);
        }
    }

    const handleChange = (event) => {
        setSelectedRatings(event.target.value);
    };

    return (
        <Fragment>
            {endVisitModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'16%',bottom:'11%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>End 
                            {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit' ? 
                                <span style={{padding:'0px 3px'}}>Visit</span> : 
                                <span style={{padding:'0px 3px'}}>Call</span>}
                            Details
                        </p>
                    </div>
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{overflowY:'auto',height:'69%'}}
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>

                        <div style={{padding:20}}>
                            <div>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <p style={{fontWeight:600}}>Rating Of 
                                        {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit' ? 
                                        <span style={{padding:'0px 3px'}}>Visit</span> : 
                                        <span style={{padding:'0px 3px'}}>Call</span>}
                                    </p>
                                    <div style={{display:'flex'}}>
                                        <div style={{display:'flex',marginRight:10}}>
                                            <input 
                                                type="radio" 
                                                name="ratings" 
                                                value="green" 
                                                onChange={handleChange}
                                                checked={selectedRatings === 'green'}
                                            />
                                            <GradeIcon sx={{color:'green',fontSize:30}}/>
                                        </div>
                                        <div style={{display:'flex',marginRight:10}}>
                                            <input 
                                                type="radio" 
                                                name="ratings" 
                                                value="yellow" 
                                                onChange={handleChange}
                                                checked={selectedRatings === 'yellow'}
                                            />
                                            <GradeIcon sx={{color:'yellow',fontSize:30}}/>
                                        </div>
                                        <div style={{display:'flex'}}>
                                            <input 
                                                type="radio" 
                                                name="ratings" 
                                                value="red" 
                                                onChange={handleChange}
                                                checked={selectedRatings === 'red'}
                                            />
                                            <GradeIcon sx={{color:'red',fontSize:30}}/>
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop:25}}>
                                    <TextField 
                                        required
                                        id='visitRemarks'
                                        name="visitRemarks"
                                        label="Remarks"
                                        multiline
                                        onBlur={handleVisitRemarks}
                                        placeholder='Enter Remarks'
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: visitRemarks ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"5px 2px" }}}
                                    />

                                </div>
                            </div>

                            {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit' && (
                                <div className={Classes.ContentBox} style={{marginTop:20}}>
                                {/* {boardingRoles && boardingRoles === 'Retailer' && ( */}
                                    <div style={{width:'49%'}}>
                                        <p style={{fontSize: 14,marginBottom: 8}}>Upload Photo of Storefront</p>
                                        <input
                                            type="file"
                                            id="storeFrontImage"
                                            name="storeFrontImage"
                                            accept="image/*"
                                            className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                        ></input>
                                    </div>
                                {/* )} */}

                                    <div style={{width:'49%'}}>
                                        <p style={{fontSize: 14,marginBottom: 8}}>Upload Photo (Proof of Visit)</p>
                                        <input
                                            type="file"
                                            id="proofOfVisitImage"
                                            name='proofOfVisitImage'
                                            accept="image/*"
                                            className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                        ></input>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'11%',
                                position:'fixed',
                                boxShadow:'none',
                                borderTop: 0
                            }}
                        >
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideEndVisitModal}
                            >
                                CANCEL
                            </button>
                            
                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                SUBMIT
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default EndVisitModal;