import { Fragment, useState, useEffect } from 'react';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import NewPagination from '../../../components/newPagination';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CreatedByModal from '../../../components/createdByFilter';
import Search from '../../../components/newSearch';
import Button from '@mui/material/Button';
import { useSearchParams } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import Spinner from '../../../components/spinner';
import UpdateSalesUserDialog from './salesUserDialog';
import SalesTargetDialog from './salesTarget';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux';
import { setManagerDashboardData } from '../../../store/managerSlice';

const ManagerDashboard = (props) => {
    const [loading,setLoading] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState([]);
    const [createdBy, setCreatedBy] = useState([]);
    const [users, setUsers] = useState([]);
    const [salesUser,setSalesUser] = useState([]);
    const [customerReassign,setCustomerReassign] = useState('');

    // salesUser Reassign 
    const [open, setOpen] = useState(false);

    // salesUserTarget
    const [targetOpen, setTargetOpen] = useState(false);
    const [target,setTarget] = useState('');

    const managerDashboardData = useSelector((state) => state.managerDashboardData);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    // salesUserTarget
    const handleTargetOpen = () => {
        setTargetOpen(true);
    };

    // salesUserTarget
    const handleTargetClose = (event) => {
        setSalesUser('');
        setTarget('');
        setTargetOpen(false);
    };

    // salesUser Reassign 
    const handleOpen = (customerId) => {
        setCustomerReassign(customerId)
        setOpen(true);
    };

    // salesUser Reassign
    const handleClose = (event) => {
        setSalesUser('');
        setOpen(false);
        const searchString = searchParams.get('text') ? searchParams.get('text') : '';
        if (searchString) {
            setSearchCustomer([searchString])
        }

        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
        fetchCustomers(page? page : 1,searchString);
    };
    

    // Using useSearchParams hook It will update,delete & add queryParams
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSearchCustomer = (event) => {
        if (event.target.value) {
            setSearchCustomer([event.target.value]);
            // set SearchQuery In QueryParams
            searchParams.set("text", event.target.value);
            setSearchParams(searchParams);
        } else {
            // 👇️ delete search query param
            searchParams.delete('text');

            // 👇️ update state after
            setSearchParams(searchParams);
            searchCustomer.splice(0,searchCustomer.length);
        }
        
        searchParams.set("page", 1);
        setSearchParams(searchParams);
        fetchCustomers(1,event.target.value);
    }

    const fetchCustomers = async (type,searchCustomerName) => {
        const page = type;
        setLoading(true);

        const customer = searchCustomerName ? searchCustomerName : searchCustomer.length !== 0 ? searchCustomer[0]: ''
        if (customer) {
            searchParams.set("text", customer);
            setSearchParams(searchParams);
        }

        searchParams.set("page", page);
        setSearchParams(searchParams);

		await axiosInstance
			.get(`/fieldSalesVisit/customersWithSalesUser?page=${page}&text=${customer}`)
			.then((res) => {
                setLoading(false);
				dispatch(setManagerDashboardData(res.data.data));
                axiosInstance
                    .get(`/fieldSalesVisit/allSalesUsers`)
                    .then((response) => {
                        const salesUser = [];
                        for (let user of response.data.data) {
                            salesUser.push({id:user?._id,label:user?.name})
                        }
        
                        setSalesUser(salesUser);
                    });
			});
	};

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Manager')) {
            const searchString = searchParams.get('text') ? searchParams.get('text') : '';
            if (searchString) {
                setSearchCustomer([searchString])
            }
            
            // fetch Customers
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
            fetchCustomers(page? page : 1,searchString);
        }
    }, []);

    const handleUserSelect = async (selectedUsers) => {
        const admins = selectedUsers.map(user => user.name);
        let usersQuery = admins.join(',');
        setCreatedBy(admins);
        const customer = searchCustomer.length !== 0 ? searchCustomer[0] : '';
        await axiosInstance
            .get(`/fieldSalesVisit/customersWithSalesUser?text=${customer}&createdBy=${usersQuery}`)
            .then((res) => {
                dispatch(setManagerDashboardData(res.data.data));
            });
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
            fontSize:12
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

	return (
		<Fragment>
			<div  id="color">
                <div className={Classes.Container}>
                    <div className={Classes.HeaderBox} style={{paddingTop:20}}>
                        <Search  
                            text={searchCustomer.length !== 0 ? searchCustomer[0] : ''}
                            fetchData={handleSearchCustomer}
                        />

                        {/* <div className={Classes.CreatedByFilter} style={{marginLeft:10}}>
                            <CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
                        </div> */}
					</div>

                    <TableContainer component={Paper} className={Classes.hideTable} >

                        <Table sx={{ minWidth: 'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'} }} aria-label="customized table">
                            <TableHead sx={{position:'relative'}}>
                                <TableRow>
                                    <StyledTableCell>Customer Name</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell sx={{width:'22%'}}>Sales User</StyledTableCell>
                                </TableRow>
                            </TableHead>



                            <TableBody>
                                {managerDashboardData?.users?.length !== 0 && managerDashboardData?.users.map((customer) => (
                                    <StyledTableRow key={customer?._id}>
                                        <StyledTableCell scope="row" sx={{padding:'16px 10px'}}>
                                            {customer?.displayName ? customer?.displayName :
                                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}
                                        </StyledTableCell>
                                        <StyledTableCell>{customer?.phone}</StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.area ? customer?.area : "N/A"}
                                        </StyledTableCell>

                                        <StyledTableCell sx={{display:'flex'}}>
                                            <p style={{width:130}}>{customer?.salesUser ? customer?.salesUser?.name : ''}</p>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding:'2px 10px',
                                                    fontSize:'13px',
                                                    "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'16px'}
                                                }}
                                                startIcon={<EditIcon />}
                                                onClick={() => handleOpen(customer?._id)}
                                                id='btnArea'
                                            >
                                                Update
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className={Classes.responsiveTable}>
                        {managerDashboardData?.users?.length !== 0 && managerDashboardData?.users?.map((customer,index) => (
                            <div 
                                key={customer?._id}   
                                // onClick={() => {navigate(`${customer?._id}`)}}
                                className={Classes.responsiveTableBox}
                                style={{marginTop: index === 0 ? 0 : 15}}
                            >
                                <div style={{display:'flex'}}>
                                    <AccountCircleIcon sx={{marginRight:'5px'}}/>
                                    <p style={{fontWeight:600}}>
                                        {customer?.displayName ? customer?.displayName :
                                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Phone:</p>
                                    <p>{customer?.phone ? customer?.phone : "N/A"}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Area:</p>
                                    <p>{customer?.area ? customer?.area : "N/A"}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Sales User:</p>
                                    <div style={{display:'flex'}}>
                                        <p style={{marginRight:5}}>{customer?.salesUser ? customer?.salesUser?.name : ''}</p>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                textTransform: "capitalize",
                                                padding:'2px 10px',
                                                fontSize:'13px',
                                                cursor:'pointer'
                                            }}
                                            onClick={() => handleOpen(customer?._id)}
                                            id='btnArea'
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                        <NewPagination totalPage={managerDashboardData} fetchData={fetchCustomers} />
                    </div>
                </div>
            </div>

            <div className={open && Classes.UpdateSalesDialog}>
                <UpdateSalesUserDialog 
                    open={open}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    customer={customerReassign}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                />
            </div>

            <div className={targetOpen && Classes.UpdateSalesDialog}>
                <SalesTargetDialog
                    open={targetOpen}
                    handleClose={handleTargetClose}
                    // customer={customerReassign}
                    target={target}
                    setTarget={setTarget}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                />
            </div>


    
            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default ManagerDashboard;
