import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


const RefundAmountPopup = (props) => {

    const {
        showPopup,
        hidePopup,
        handleRefundAmount,
        refundAmt,
        setRefundAmt
    } = props;


    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom: '35%', top: '26%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Refund Amount</p>
                    </div>

                    <div style={{ padding: '30px 20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                            <TextField
                                required
                                id='amt'
                                type="number"
                                label="Amount"
                                defaultValue={refundAmt}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon></CurrencyRupeeIcon></InputAdornment>,
                                }}
                                placeholder='Enter refund amount'
                                onChange={(e) => setRefundAmt(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={Classes.FooterBox} style={{ gap: '7px',borderTop:0 }}>
                        <button
                            type="submit"
                            className={Classes.ApplyButton}
                            style={{ background: '#fff', color: 'black' }}
                            onClick={hidePopup}
                        >
                            Close
                        </button>
                        <button
                            id='btn'
                            className={Classes.ApplyButton}
                            style={{ background: 'green', color: '#fff' }}
                            onClick={(e) => handleRefundAmount(e)}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default RefundAmountPopup;