import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';
import './css/specialImg.css';

const Inventory = (props) => {

    const {
        showErrorMsg,
        productDetails,

    }= props;



	return (
		<Fragment>
            <div className={Classes.Pricing}>
                <div className={Classes.Boxes}>
                    <div>
                        <h6>Inventory</h6>
                    </div>
                    <div style={{width:'100%', marginTop:15}}>
                        <p style={{fontWeight:600}}>
                            Available On Request
                        </p>
                        <select 
                            name="availableOnRequest" 
                            id="availableOnRequest"
                            defaultValue={
                                productDetails?.length !== 0 && 
                                productDetails[0]?.isCustomOrder ?
                                productDetails[0]?.isCustomOrder :
                                ''
                            }
                            key={
                                productDetails?.length !== 0 && 
                                productDetails[0]?.isCustomOrder ?
                                productDetails[0]?.isCustomOrder :
                                ''
                            }
                            style={{ marginTop:0,paddingTop:8 }}
                        >
                            <option value="no">NO</option>
                            <option value="yes">YES</option>
                        </select>
                        <div>
                            {showErrorMsg?.length !== 0 && (
                                <div>
                                    {showErrorMsg?.fieldName === 'available_on_request' && (
                                        <p className={Classes.Msg}>
                                            {showErrorMsg?.message}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={Classes.CommonMarginTop}>
                        <p>Available Stock</p>
                    </div>
                    <div>
                        <input
                            name='availableQuantity'
                            type="number"
                            id="availableQuantity"
                            onKeyDown={
                                e => ( e.keyCode === 69) 
                                && e.preventDefault()
                            }
                            key={
                                productDetails?.length !== 0 ?
                                productDetails[0]?.availableQuantity :
                                ''
                            }
                            defaultValue={
                                productDetails?.length !== 0 ?
                                productDetails[0]?.availableQuantity :
                                ''
                            }
                            autoComplete='off'
                            style={{
                                border: '1px solid lightgray',
                                borderColor: 
                                    showErrorMsg?.fieldName === 'cost' ? 
                                    'red': 
                                    'lightgray',
                                borderRadius: 4,
                                width: '100%'
                            }}
                            className={Classes.searchField}
                            placeholder='No data available'
                        />
                    </div>

                    <div>
                        {showErrorMsg?.length !== 0 && (
                            <div>
                                {showErrorMsg?.fieldName === 'availableQuantity' && (
                                    <p className={Classes.Msg}>
                                        {showErrorMsg?.message}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={Classes.CommonMarginTop}>
                        <p>Cost per item</p>
                    </div>
                    <div>
                        <input
                            name='costPerItem'
                            type="number"
                            id="costPerItem"
                            step="0.01"
                            onKeyDown={
                                e => ( e.keyCode === 69) 
                                && e.preventDefault()
                            }
                            key={
                                productDetails?.length !== 0 ?
                                productDetails[0]?.baseCost :
                                ''
                            }
                            defaultValue={
                                productDetails?.length !== 0 ?
                                productDetails[0]?.baseCost :
                                ''
                            }
                            autoComplete='off'
                            style={{
                                border: '1px solid lightgray',
                                borderColor: 
                                    showErrorMsg?.fieldName === 'cost' ? 
                                    'red': 
                                    'lightgray',
                                borderRadius: 4,
                                width: '100%'
                            }}
                            className={Classes.searchField}
                            placeholder='&#8377;  0.00'
                        />
                    </div>

                    <div>
                        {showErrorMsg?.length !== 0 && (
                            <div>
                                {showErrorMsg?.fieldName === 'cost' && (
                                    <p className={Classes.Msg}>
                                        {showErrorMsg?.message}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>

                    <div style={{color:'gray'}}>
                        <p>Customer won't see this</p>
                    </div>

                    <div className={Classes.CommonMarginTop}>
                        <div style={{display:'flex'}}>
                            <div>
                                <input
                                    type='checkbox'
                                    name='trackQty'
                                    id='trackQty'
                                    // defaultChecked={true}
                                    defaultChecked={
                                        productDetails?.length !== 0 &&
                                        productDetails[0]?.isTracked ?
                                        productDetails[0]?.isTracked :
                                        false
                                    }
                                    key={
                                        productDetails?.length !== 0 &&
                                        productDetails[0]?.isTracked ?
                                        productDetails[0]?.isTracked :
                                        false
                                    }
                                />
                            </div>
                            <div style={{padding: '3px 10px'}}>
                                <p>Track quantity</p>
                            </div>
                        </div>
                    </div>

                    <div style={{display:'flex'}}>
                        <div>
                            <input
                                type='checkbox'	
                                name='continueSelling'
                                id='continueSelling'
                                defaultChecked={
                                    productDetails?.length !== 0 &&
                                    productDetails[0]?.backOrder &&
                                    productDetails[0]?.backOrder === 'CONTINUE' ?
                                    true : false
                                }
                                key={
                                    productDetails?.length !== 0 &&
                                    productDetails[0]?.backOrder &&
                                    productDetails[0]?.backOrder === 'CONTINUE' ?
                                    true : false
                                }
                            />
                        </div>
                        <div style={{padding: '3px 10px'}}>
                            <p>Contiue selling when out of stock</p>
                        </div>
                    </div>

                    <div style={{display:'flex'}}>
                        <div>
                            <input
                                type='checkbox'	
                                name='sample'
                                id="sample"
                                defaultChecked={
                                    productDetails?.length !== 0 &&
                                    productDetails[0]?.isSampleAvailable &&
                                    productDetails[0]?.isSampleAvailable === 'yes' ?
                                    true : 
                                    false
                                }
                                key={
                                    productDetails?.length !== 0 &&
                                    productDetails[0]?.isSampleAvailable &&
                                    productDetails[0]?.isSampleAvailable === 'yes' ?
                                    true : 
                                    false
                                }
                            />
                        </div>
                        <div style={{padding: '3px 10px'}}>
                            <p>Sample Available</p>
                        </div>
                    </div>
                </div>

                <div 
                    className={Classes.Boxes}
                    style={{ paddingBottom:'0.5rem' }}
                >
                    {/* <div style={{fontWeight:'600'}}>
                        <p>QUANTITY</p>
                    </div> */}
                    <div className={Classes.Location}>
                        <p>Location name</p>
                    </div>
                    <div className={Classes.LocationField}>
                        <p>77/1/A, Christopher Road, Topsia, Kolkata, West Bengal 700046</p>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};

export default Inventory;
