import { useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import Classes from '../users/css/users.module.css';
import { Paper, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { GridToolbar } from '@mui/x-data-grid';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateRangePicker,LocalizationProvider } from '@mui/x-date-pickers-pro';
import BrandWisePopup from './brandWisePopup.js';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MobileResponsive from "./mobileResponsive";
import Classes from '../customers/css/customer.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { setSchemes } from '../../../store/schemesSlice.js';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const years = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const SchemeDashboard = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({ field: 'displayName', value: '' });
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });
    const [dateRange, setDateRange] = useState([null, null]);
    const [showBrandPopup, setShowBrandPopup] = useState(false);
    const [brandWiseDetails, setBrandWiseDetails] = useState([]);
    const [schemesColumns, setSchemesColumns] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const schemes = useSelector((state) => state.schemes);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    let columns = [
        { 
            field: 'displayName', 
            width: 180, 
            headerName: 'NAME', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'phoneNumber', 
            width: 140, 
            headerName: 'PHONE', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'sales_user', 
            width: 150, 
            headerName: 'SALES USER', 
            headerClassName: 'super-app-theme--header', 
            filterable: false, 
            sortable: false,
            renderCell: (params) => {
                let arr = params?.value !== "" && params?.value?.split(',');
                return Array.isArray(arr) && (
                    <div>
                        {arr?.slice(0, 1)?.map((user, index) => (
                            <Chip key={index} label={user} style={{ margin: '3px' }} />
                        ))}
                        {arr?.length > 1 ? (
                            <Tooltip title={
                                <div>
                                    {arr?.slice(1)?.map((service, index) => (
                                        <p>{service}, </p>
                                    ))}
                                </div>
                            } arrow>
                                <Chip size="small" label={`+ ${arr?.length - 1} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                            </Tooltip>
                        ) : null}
                    </div>
                )
            }
        },
        {
            field: 'pre_sales_user', 
            width: 150, 
            headerName: 'PRE-SALES USER', 
            headerClassName: 'super-app-theme--header', 
            filterable: false, 
            sortable: false,
            renderCell: (params) => {
                let arr = params?.value !== "" && params?.value?.split(',');
                return Array.isArray(arr) && (
                    <div>
                        {arr?.slice(0, 1)?.map((user, index) => (
                            <Chip key={index} label={user} style={{ margin: '3px' }} />
                        ))}
                        {arr?.length > 1 ? (
                            <Tooltip title={
                                <div>
                                    {arr?.slice(1)?.map((service, index) => (
                                        <p>{service}, </p>
                                    ))}
                                </div>
                            } arrow>
                                <Chip size="small" label={`+ ${arr?.length - 1} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                            </Tooltip>
                        ) : null}
                    </div>
                )
            }
        },
        { 
            field: 'role', 
            width: 100, 
            headerName: 'ROLE', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'city', 
            width: 100, 
            headerName: 'CITY', 
            headerClassName: 'super-app-theme--header', 
            sortable: false 
        },
        { 
            field: 'area', 
            width: 160, 
            headerName: 'AREA', 
            headerClassName: 'super-app-theme--header', 
            sortable: false 
        },
        { 
            field: 'last_created_at', 
            width: 130, 
            headerName: 'LAST ORDER', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'totalOrderAmount', 
            width: 150, 
            headerName: 'TOTAL ORDERS AMT.', 
            valueFormatter: (params) => 
                `₹${params.value}`, 
                headerClassName: 'super-app-theme--header', 
                sortable: true 
            },
        { 
            field: 'growth', 
            width: 130, 
            headerName: 'GROWTH %', 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'value_of_furniture', 
            width: 130, 
            valueFormatter: (params) => 
                `₹${params.value}`, 
                headerName: 'FURNITURE FITTING', 
                headerClassName: 'super-app-theme--header', 
                sortable: true 
            },
        { 
            field: 'value_of_pant_polish', 
            width: 130, 
            valueFormatter: (params) => 
                `₹${params.value}`, 
                headerName: 'PAINTS & POLISH', 
                headerClassName: 'super-app-theme--header', 
                sortable: true 
            },
        { 
            field: 'value_of_board_lamination', 
            width: 130, 
            valueFormatter: (params) => 
                `₹${params.value}`, 
                headerName: 'BOARD & LAMINATION', 
                headerClassName: 'super-app-theme--header', 
                sortable: true 
            },
        { 
            field: 'value_of_appliances', 
            width: 130, 
            valueFormatter: (params) => 
                `₹${params.value}`, 
                headerName: 'APPLIANCES', 
                headerClassName: 'super-app-theme--header', 
                sortable: true 
            },
        {
            field: 'brand_wise_breakdown',
            headerName: 'BRAND WISE BREAKDOWN',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: true,
            renderCell: (params) => (
                <Button startIcon={<OpenInNewIcon />} variant="contained" onClick={(e) => showBrandWiseBreakdown(e, params?.row?.phoneNumber)}>Open</Button>
            )
        },
    ];

    const fetchSchemes = async () => {
        await axiosInstance
            .get('/scheme/schemes',)
            .then((response) => {
                let columns = [];
                for (let i = 0; i < response?.data?.data?.allSchemes?.length; i++) {
                    let schemeName = response?.data?.data?.allSchemes[i]?.scheme_name;
                    columns.push({
                        field: schemeName,
                        width: 130,
                        headerName: schemeName,
                        headerClassName: 'super-app-theme--header',
                        sortable: false,
                        filterable: false,
                        valueFormatter: (params) => params.value ? `₹${params.value}` : "N/A"
                    })
                }

                setSchemesColumns(columns);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    };

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    const showBrandWiseBreakdown = async (e, value) => {
        e.preventDefault();
        setLoading(true);
        const startDate = dateRange.length !== 0 && dateRange[0] ? new Date(dateRange[0]?.$d) : null;
        const endDate = dateRange.length !== 0 && dateRange[1] ? new Date(dateRange[1]?.$d) : null;
        let formattedStartDate;
        let formattedEndDate;
        if (startDate) {
            formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;
        }
        if (endDate) {
            formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;
        }


        const params = {
            phoneNumber: value,
            startTime: formattedStartDate,
            endTime: formattedEndDate,
        };

        await axiosInstance
            .get(`/scheme/brandWiseBreakup`, { params })
            .then((res) => {
                setBrandWiseDetails(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
        setLoading(false);
        setShowBrandPopup(true);
    }

    const hideBrandPopup = () => {
        setShowBrandPopup(false);
        setBrandWiseDetails([]);
    }


    const handleCreatedAt = (createdAt) => {
        const dn = new Date();
        let ca = createdAt
            ? createdAt.getFullYear() === dn.getFullYear()
                ? createdAt.getMonth() === dn.getMonth()
                    ? createdAt.getDate() === dn.getDate()
                        ? `Today at ${createdAt.toLocaleTimeString()}`
                        : createdAt.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
                            : years[createdAt.getMonth()] +
                            ' ' +
                            createdAt.getDate() +
                            ' at ' +
                            createdAt.toLocaleTimeString()
                    : years[createdAt.getMonth()] +
                    ' ' +
                    createdAt.getDate() +
                    ' at ' +
                    createdAt.toLocaleTimeString()
                : years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
            : null
        return ca;
    }

    const fetchUsers = async (page) => {
        setLoading(true);
        setError(null);

        const startDate = dateRange.length !== 0 && dateRange[0] ? new Date(dateRange[0]?.$d) : null;
        const endDate = dateRange.length !== 0 && dateRange[1] ? new Date(dateRange[1]?.$d) : null;
        let formattedStartDate;
        let formattedEndDate;
        if (startDate) {
            formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`;
        }
        if (endDate) {
            formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`;
        }


        const params = {
            page,
            startTime: formattedStartDate,
            endTime: formattedEndDate,
            searchText: search ? search : '',
            // filter: JSON.stringify(filter),
            sort,
        };

        await axiosInstance
            .get('/scheme/schemeDashboard', { params })
            .then((response) => {
                dispatch(setSchemes(response?.data?.data));
            })
            .catch((error) => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);

    };

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Dashboard')) {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            fetchUsers(page ? page : 1);
            fetchSchemes();
        }
    }, [dateRange, search, sort, filter]);


    return (
        <Fragment>
            <div style={{ display:'flex',justifyContent:'end',marginRight:10,marginBottom:10 }} className={Classes.inventoryTabHeader}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                                'DateRangePicker',
                                'MobileDateRangePicker',
                                'DesktopDateRangePicker',
                                'StaticDateRangePicker',
                            ]}
                        >
                            <DemoItem component="MobileDateRangePicker" >
                                <MobileDateRangePicker
                                    onChange={(newValue) => {
                                        setDateRange(newValue)
                                    }}
                                    format="DD-MM-YYYY"
                                    views={["year", "month", "day"]}
                                // defaultValue={[dayjs(todayDate()), dayjs(todayDate())]}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            <div style={{ width: '100%'}} className={Classes.MuiTable}>
                <Box
                    component={Paper}
                    className="shadow"
                    sx={{
                        '& .super-app-theme--header': {  
                            backgroundColor:'#243750',color:'#ffffff',cursor:"default" 
                    }}}
                >
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        filterMode="server"
                        filterColumns={['name', 'role', 'username']}
                        sortingMode="server"
                        paginationMode="server"
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                        }}
                        onSortModelChange={(sort) => setSort(sort[0])}
                        // loading={loading}
                        sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            ".MuiDataGrid-footerContainer": {
                                display: "none !important",
                            },
                            boxShadow: 2,
                            borderRadius: 2,
                        }}
                        columns={[...columns, ...schemesColumns]}
                        rows={schemes?.schemeDashboard?.map((user) => {
                            let row = {
                                id: user?.phoneNumber,
                                phoneNumber: user?.phoneNumber,
                                createdAt: handleCreatedAt(new Date(user?.createdAt)),
                                displayName: user?.displayName,
                                role: user?.role,
                                pre_sales_user: user?.pre_sales_user,
                                sales_user: user?.sales_user,
                                totalOrderAmount: user?.totalOrderAmount,
                                area: user?.area,
                                city: user?.city,
                                last_created_at: user?.last_created_at,
                                growth: user?.growth,
                                value_of_furniture: user?.value_of_furniture,
                                value_of_pant_polish: user?.value_of_pant_polish,
                                value_of_board_lamination: user?.value_of_board_lamination,
                                value_of_appliances: user?.value_of_appliances,
                            }
                            if (user && Object.keys(user?.applied_schemes)?.length > 0) {
                                for (const [key, scheme] of Object.entries(user.applied_schemes)) {
                                    Object.assign(row, { [key]: scheme?.totalOrderAmount });
                                }
                            }
                            return row;
                        })}
                        sortingOrder={['asc', 'desc']}
                        autoHeight
                        autoPageSize
                        onFilterModelChange={(filter) => (setFilter(filter.items[0]), setSearch(filter.quickFilterValues[0]))}
                    />
                </Box>

                {schemes?.schemeDashboard?.length > 0 ? (
                    <nav aria-label="Page navigation">
                        <NewPagination totalPage={schemes} fetchData={fetchUsers} />
                    </nav>
                ) : null}
        
                {loading ? <Spinner /> : null}
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"schemeDashboard"}
                    endpoint={`/scheme/schemeDashboard`}
                    rowId={'phoneNumber'}
                    handleClickOpen={showBrandWiseBreakdown}
                    // showClosePopup={showClosePopup}
                />
            </div>


            <BrandWisePopup
                open={showBrandPopup}
                handleClose={hideBrandPopup}
                details={brandWiseDetails}

            />
        </Fragment>
    );
};

export default SchemeDashboard;