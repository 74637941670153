import { useEffect, useState } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Classes from '../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const navigate = useNavigate();
    const { endpoint, rowId, sort, dataPosition } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%',marginTop:10 }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { text:searchFor, page };
        if (sort){
            params["sort"] = { ...sort}
        }
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data;
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box} onClick={() => navigate(`/users/${row?._id}`)}>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>
                            NAME:
                        </p>
                        <p>{row?.userData?.displayName}</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>
                            PHONE:
                        </p>
                        <p>{row?.userData?.phone}</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>
                            EMAIL:
                        </p>
                        <p>{row?.userData?.email}</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>
                            NUMBER OF PRODUCTS:
                        </p>
                        <p>{row?.products?.length}</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>
                            STATUS:
                        </p>
                        <p>{row?.status}</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:5,color:'#6c757d'}}>
                            CREATED AT:
                        </p>
                        <p>{moment(row?.createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
                    </div>
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;