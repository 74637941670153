export default class TableCSVExporter {
    constructor (table, includeHeaders = true) {
        this.table = table;
        this.rows = Array.from(table.querySelectorAll("tr"));

        if (!includeHeaders && this.rows[0].querySelectorAll("th").length) {
            this.rows.shift();
        }else{
            this.headers=[]
            Array.from(this.rows[0].querySelectorAll("th"))
            .forEach(head=>{
                if(Object.keys(head.dataset).includes("product_title")){
                    Object.keys(head.dataset).forEach((unithead)=>{
                        this.headers.push(unithead)
                    })
                }else{
                    this.headers.push(Object.keys(head.dataset)[0])
                }
            })
            this.rows.shift();
        }
    }

    convertToCSV () {

        const lines = [];
        const numCols = this._findLongestRowLength();
        let headline=""
        this.headers.forEach((header,headerIndex)=>{
            headline+=header;
            headline+=(headerIndex !== (this.headers.length - 1)) ? "," : "";
        })
        lines.push(headline)

        for (let row=0; row<this.rows.length; row++){
            const rowObj={}
            let line="";

            for(let i=0; i<numCols; i++){
                if(Object.keys(this.rows[row].children[i].dataset).includes("inside")){
                    Object.keys(this.rows[row].children[i].dataset).forEach((r)=>{rowObj[r]=TableCSVExporter.parseCell(this.rows[row].children[i].dataset[r])})
                }else{
                    Object.keys(this.rows[row].children[i].dataset).forEach((r)=>{rowObj[r]=TableCSVExporter.parseCell(this.rows[row].children[i].textContent)})
                }
            }
            this.headers.forEach((header,headerIndex)=>{
                line += rowObj[header]
                line += (headerIndex !== (this.headers.length - 1)) ? "," : "";
            })
            lines.push(line);
        }

    
        return lines.join("\n");

    }

    _findLongestRowLength () {
        return this.rows.reduce((l, row) => row.childElementCount > l ? row.childElementCount : l, 0);
    }

    static parseCell (cell) {
        
        let parsedValue = cell;

        // Parse any data type other than string to string
        parsedValue = String(parsedValue)

        // Replace all double quotes with two double quotes
        parsedValue = parsedValue.replace(/"/g, `""`);

        // If value contains comma, new-line or double-quote, enclose in double quotes
        parsedValue = /[",\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue;

        return parsedValue;
    }

}