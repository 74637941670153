import React from 'react';
import { Container, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = (props) => {
    const {
        text,
        fetchData
    } = props;

    return (
        <Container disableGutters={true}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={text}
                onChange={fetchData}
                autoComplete='off'
                sx={{ 
                    width: '100%',
                    "& label": {top: text ? "0%":"-18%"},
                    "& .MuiOutlinedInput-input": {padding:"8.5px 14px" }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </Container>
    );
};

export default Search;