import { Fragment, useEffect, useState } from "react";
import axiosInstance from "../../../utility/axios-instance";
import { Alert } from "react-bootstrap";
import QRCode from 'qrcode';

const SkuToQrCode = () => {

    const [error, setError] = useState("");
    const [message, setMessage] = useState(null);
    const [qrcData , setQrcData] = useState([]);
    const [response , setResponse] = useState([]);
    const [invalidSku , setInvalidSku] = useState([]);

    const handleImport = (event) => {
        event.preventDefault();
        document.getElementById('import').innerText = 'Importing...'
        var formData = new FormData();
        formData.append("sku", document.getElementById('skuUpload').files[0]);
    
        axiosInstance
            .post('/product/skuToQRC', formData)
            .then(result => {
                setResponse(result.data.data.productIds)
                setInvalidSku(result.data.data.invalidSkus);
                document.getElementById('import').innerText = 'Import'
            })
            .catch(error => {
                console.log('error', error)
                document.getElementById('import').innerText = 'Import'
                setError(error.message)
            })
        }

    const getQRCode = () => {
        let opts = {
            errorCorrectionLevel: 'M',
            quality: 0.3,
            margin: 1,
        }

        response.map( async item => {
            const URL = `https://depo24.page.link/?link=https://depo24.in/products/${item.Id}&apn=in.depo24.depo_24&ibi=in.depo24.depo24`;
            const qrCode = await QRCode.toDataURL(URL,opts);
            item.qrCode = qrCode;
        });
        setQrcData(response)
    }

    useEffect(()=>{
        getQRCode()
    },[response])

    const handlePrint = (e) =>{
        let content = document.getElementById('printContainer').innerHTML
        e.preventDefault();
        const printPage = window.open("","_blank","")
        printPage.document.write(`
            <html>
                <head><title>Print Window</title></head>
                <body onload="window.print();window.close()">
                    ${content}
                </body>
            </html>
        `)
        printPage.print();
        printPage.close();
    }


    return (
        <Fragment>
            <div
                className="alert alert-primary p-2"
                data-file-upload-alert
                role="alert"
                style={{ display: "none" }}
            >
                {message}
            </div>

            {error &&   <Alert variant="danger" onClose={() => setError("")} dismissible>
                            <Alert.Heading>{error}</Alert.Heading>
                            {error}
                            <p>Reload Again</p>
                        </Alert>
            }

            <div className="container h-100">
                <div className=" d-flex flex-row justify-content-between">
                    <div className="w-25 px-2 " >
                    {(invalidSku.length !== 0) ?
                        <div className="border border-danger overflow-auto" style={{height:'100px'}}>
                            <h3 className="font-weight-bold text-danger text-center">Invalid Sku's</h3>
                            {invalidSku.map(item => (
                                    <p className="m-2 font-weight-bold">{`Sku : ${item.sku}`}</p>
                                ))
                            }
                        </div>
                    :null}
                    </div>    
                    
                    <div className="col-md-4 d-flex align-items-start justify-content-end w-75 me-0">
                        <div>
                            <form className="file-input">
                                <div className="d-flex flex-column justify-content-center">
                                    <label
                                    htmlFor="skuUpload"
                                    className="form-label text-center"
                                    >
                                        Sku To QR-Code
                                    </label>
                                    <div className="d-flex">
                                    <input
                                        className="form-control margin-right"
                                        id="skuUpload"
                                        type="file"
                                        accept=".csv"
                                        name="sku"
                                        required
                                    />
                                    <button
                                        type="submit"
                                        id='import'
                                        className="btn btn-primary"
                                        onClick={handleImport}
                                    >
                                        Import
                                    </button>
                                    </div>
                                </div>
                            </form>
                            <button className="btn btn-primary text-white p-2 px-4" onClick={handlePrint}>Print All</button>
                        </div>
                    </div>
                </div>
                
                <hr />
                <div id="printContainer" className="d-flex justify-content-around flex-wrap">
                    {qrcData.map(item => (
                        <div key={item.qrSKU} className="image-to-print m-3" style={{margin:'15px',textAlign : 'center',display:'inline-block'}}>
                            <img className="" alt='QrCode' id='qrCode' width="66px" height="66px" src={item.qrCode} />
                            <p style={{marginTop:'3px', fontSize:'10px' , lineHeight:'10PX'}}>{item.qrSKU}</p>
				        </div>
                    ))

                    }
                </div>
            </div>
        </Fragment>
    )
}

export default SkuToQrCode;