import { Fragment, useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import NewPagination from '../../../components/newPagination';
import NewCustomerModal from '../createOrders/aside/newCustomerModal';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CreatedByModal from '../../../components/createdByFilter';
import Search from '../../../components/newSearch';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Spinner from '../../../components/spinner';
import ConfirmDialog from './confirmDialog';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import MobileResponsive from './mobileResponsive';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Snackbar from '@mui/material/Snackbar';

const Affiliate = (props) => {
    const [error, setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const [showAffiliateCustomer, setAffiliateCustomer] = useState([]);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [gstNO, setGstNO] = useState();
    const [showModal, setShowModal] = useState(false);
    const [num,setNum] = useState('');
	const [fetchCustomerRoleDB,setCustomerRoleDB] = useState([]);
    const [errorMessage,setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
	const [storeCustomerName,setCustomerName] = useState('');
    const [createdBy, setCreatedBy] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedState,setSelectedState] = useState('');
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const [confirmDialog,setConfirmDialog] = useState(false);
    const [customer,setCustomer] = useState('');

    const showConfirmDialog = (customer) => {
        setConfirmDialog(true);
        setCustomer(customer);
    }

    const hideConfirmDialog = () => {
        setConfirmDialog(false);
    }

    const getModal = () => {
        setShowModal(true);
    };

    const hideModal = () => {
        setNum('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setBusinessName('');
        setGstNO('');
        setErrorMessage('');
        setShowModal(false);
        setSelectedState('');
        setCity('');
        setArea('');
    };


    const years = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dn = new Date();

    const createdAt = (createdAt) => {
        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

    const handleSearchCustomer = async (event) => {
        setLoading(true);
        setSearchCustomer(event.target.value);

        await axiosInstance
			.get(`/others/getAffiliate?page=${1}&text=${event.target.value}&createdBy=${storeCustomerName}`)
			.then((res) => {
                setLoading(false);
				setAffiliateCustomer(res.data.data);
			});
    }

    const fetchCustomers = async (type) => {
        setLoading(true);
        const page = type;

		await axiosInstance
			.get(`/others/getAffiliate?page=${page}&text=${searchCustomer? searchCustomer: ''}&createdBy=${storeCustomerName}`)
			.then((res) => {
                setLoading(false);
				setAffiliateCustomer(res.data.data);
			});
	};

    useEffect(() => {
        axiosInstance
            .get('/user/admin/customerRoles')
            .then((res) => {
                setCustomerRoleDB([...res.data.data])
            })

            // fetch draftOrders
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
            fetchCustomers(page? page : 1);
    }, [])


    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('customerSearchOutline');
        if (container !== null) {
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleUserSelect = async (selectedUsers) => {
        const admins = selectedUsers.map(user => user.name);
        let usersQuery = admins.join(',');
        setCreatedBy(admins);
        
        await axiosInstance
            .get(`/others/getAffiliate?text=${searchCustomer}&createdBy=${usersQuery}`)
            .then((res) => {
                setAffiliateCustomer(res.data.data);
            });
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2
        }
    }

    const handleVerifyCustomer = async (customerId) => {
        await axiosInstance
            .put(`/user/admin/affiliateCustomer/${customerId}`, {
                isVerified: true
            })
            .then((res) => {
                const successMessage = "Customer Verified Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                const urlParams = new URLSearchParams(window.location.search);
                const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
                fetchCustomers(page? page : 1);
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                // setLoading(false);
            })
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>

                <div className="d-flex" style={{justifyContent:'flex-end',marginTop:-25,marginBottom:5}}>
                    <Button  
                        startIcon={<PersonAddIcon />} 
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize:'14px',
                            textTransform:'capitalize',
                            "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                        }}
                        onClick={getModal}
                    >
                        Create
                    </Button>
                </div>

				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}

                <div className={[Classes.Container,Classes.MuiTable].join(' ')} >
                    <div className={Classes.HeaderBox} style={{ paddingTop:20 }}>
                        <Search text={searchCustomer} fetchData={handleSearchCustomer} />

                        <div className={Classes.CreatedByFilter} style={{marginLeft:10}}>
                            <CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
                        </div>
					</div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead onSelectAllClick>
                                <TableRow>
                                    <StyledTableCell>Customer Name</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell>Sales User</StyledTableCell>
                                    <StyledTableCell>Pre-Sales User</StyledTableCell>
                                    <StyledTableCell>Created At</StyledTableCell>
                                    <StyledTableCell>Verified</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showAffiliateCustomer?.length !== 0 && showAffiliateCustomer?.affiliateData?.map((customer) => (
                                    <StyledTableRow key={customer?._id}>
                                        <StyledTableCell>
                                            {customer?.displayName ? customer?.displayName :
                                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}
                                        </StyledTableCell>
                                        <StyledTableCell>{customer?.phone}</StyledTableCell>
                                        <StyledTableCell>
                                            {capitalizeState(customer?.typeOfRole)}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.salesUser ? customer?.salesUser?.name : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.preSalesUser ? customer?.preSalesUser?.name : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.createdAt ? createdAt(customer?.createdAt) : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell style={{textAlign:'center'}}>
                                            {customer?.isVerified ? 
                                                <VerifiedUserIcon 
                                                    style={{color:'green'}}
                                                /> : 
                                                <Permission service={services?.affiliates} permission={"create"}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ textTransform:'capitalize' }}
                                                        style={{ padding:'2px 10px',fontSize:14 }}
                                                        onClick={() => handleVerifyCustomer(customer?._id)}
                                                    >
                                                        Verify
                                                    </Button>
                                                </Permission>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Permission service={services?.affiliates} permission={"delete"}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ textTransform:'capitalize' }}
                                                    style={{ padding:'2px 10px',fontSize:14,backgroundColor:'#EE5C55' }}
                                                    onClick={() => showConfirmDialog(customer)}
                                                >
                                                    Delete
                                                </Button>
                                            </Permission>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div style={{ margin:'20px auto 0px',paddingBottom: 20 }}>
                        <NewPagination
                            totalPage={showAffiliateCustomer}
                            fetchData={fetchCustomers}
                        />
                    </div>
                </div>

                <div className={Classes.MobileResponsive}>
                    <MobileResponsive 
                        dataPosition={"affiliateData"} 
                        endpoint={`/others/getAffiliate`}
                        handleClickOpen={showConfirmDialog}
                    />
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewCustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    fetchCustomerRoleDB={fetchCustomerRoleDB}
                    fetchCustomers={fetchCustomers}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    popUpType='customerDashboard'
                    endpoint={`/user/admin/affiliates`}
                    setSelectedState={setSelectedState}
                    selectedState={selectedState}
                    setCity={setCity}
                    city={city}
                    setArea={setArea}
                    area={area}
                />
            </div>

            <div className={confirmDialog ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    customer={customer}
                    hideConfirmDialog={hideConfirmDialog}
                    fetchCustomers={fetchCustomers}
                />
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default Affiliate;
