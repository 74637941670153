import { Fragment, useState, forwardRef, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBar from '../../../components/SnackBar';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const QcPopup = (props) => {
    const [users, setUsers] = useState([]);
    const [qcPerson, setQcPerson] = useState(null);
    const { open, handleClose, inboundDetails, reRender, setReRender, loading, setLoading, url } = props;
    const totalQty = inboundDetails?.quantity
    const [pass, setPass] = useState('');
    const [fail, setFail] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const closePopup = () => {
        setQcPerson(null);
        setPass('');
        setFail('');
        handleClose();
    }

    const handlePassChange = (value) => {
        
        const passValue = parseInt(value, 10) || 0;
        const failValue = parseInt(fail, 10) || 0;
        const total = passValue + failValue;

        if (value < 0) {
            alert('Pass value cannot be less than 0');
            setPass('');
            setFail(totalQty);
            return
        }

        if (value > totalQty) {
            alert('Sum of "Pass" and "Fail" values cannot exceed totalQty.');
            setPass('');
            setFail(totalQty);
            return
        }

        setPass(value);
        setFail(totalQty - passValue);
    };

    const handleFailChange = (value) => {
        const passValue = parseInt(pass, 10) || 0;
        const failValue = parseInt(value, 10) || 0;
        const total = passValue + failValue;

        if (total > totalQty) {
            alert('Sum of "Pass" and "Fail" values cannot exceed totalQty.');
            setFail('');
            return
        }
        setFail(value);
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const data = {
            qcPerson: qcPerson,
            pass: parseInt(pass, 10) || 0,
            fail: parseInt(fail, 10) || 0,
        }
        await axiosInstance
            .put(`${url}/${inboundDetails?.id}`, data)
            .then((res) => {
                const successMessage = 'Order QC Successfully'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                closePopup();
                setReRender(!reRender);
            })
            .catch((error) => {
                // alert('Error in QC');
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }


    useEffect(() => {
        axiosInstance
            .get(`/admin/allPiAdmin?type=qcPerson&locationCode=${inboundDetails?.locationCode}`)
            .then((res) => {
                let users = res?.data?.data?.map((user) => user.name);
                setUsers(users);
            });
    }, [inboundDetails]);

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{`QC of ${inboundDetails?.inboundNumber}`}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                        Total Quantity:{inboundDetails?.quantity}
                    </DialogContentText>
                    <div style={{ margin: '20px 0px 5px 0px' }} >
                        <form onSubmit={handleSubmit} >
                            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                                <Autocomplete
                                    size='small'
                                    required
                                    id="user"
                                    options={users}
                                    onChange={(event, newValue) => {
                                        setQcPerson(newValue);
                                    }}
                                    style={{ width: '50%' }}
                                    value={qcPerson}
                                    renderInput={(params) => <TextField {...params} required label="QC Person" defaultValue="" />}
                                />
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <TextField
                                        size='small'
                                        label="Pass"
                                        required
                                        type="number"
                                        fullWidth
                                        value={pass}
                                        min={1}
                                        onChange={(e) => handlePassChange(e.target.value)}
                                    />
                                    <TextField
                                        size='small'
                                        disabled={true}
                                        label="Fail"
                                        type="number"
                                        fullWidth
                                        value={fail}
                                        min={1}
                                        onChange={(e) => handleFailChange(e.target.value)}
                                    />

                                </div>
                            </div>
                            <DialogActions>
                                <Button type="submit" id='moveInventory' variant="contained" color="primary" >Done</Button>
                                <Button onClick={closePopup}>Close</Button>
                            </DialogActions>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}

export default QcPopup;