import { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from './css/draftOrder.module.css';
import NewPagination from '../../../components/newPagination';
import CreatedByModal from '../orders/createdByFilter';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import Spinner from '../../../components/spinner';
import { Paper, InputAdornment, TextField, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridToolbar } from '@mui/x-data-grid';
import { setDraftPurchaseOrders } from '../../../store/draftPurchaseOrdersSlice';
import { useSelector, useDispatch } from 'react-redux';

const DraftPurchaseOrders = () => {
	const [text, setText] = useState('');
	const [createdBy, setCreatedBy] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });

	const draftPurchaseOrders = useSelector((state) => state.draftPurchaseOrders);
	const pinTab = useSelector((state) => state.pinTab);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const columnVisibilityModel = {
		locationName: false,
	}
	const columns = [
		{ field: 'draftPurchaseOrderName', filterable: false, headerName: 'DRAFT ORDER', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'createdAt', filterable: false, headerName: 'DATE', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'vendorName', filterable: false, headerName: 'VENDOR', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'locationCode', filterable: true, headerName: 'Location Code', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'locationName', filterable: false, headerName: 'Location Name', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{
			field: 'status',
			headerName: 'STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			filterable: false,
			sortable: true,
			flex: 1.1,
			renderCell: (params) => (
				params.value === "Completed" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			)
		},
		{ field: 'totalAmount', filterable: false, valueFormatter: (params) => `₹${params.value}`, headerName: 'TOTAL', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'createdBy', filterable: false, headerName: 'CREATED BY', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
	];

	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}


	const handleUserSelect = async (selectedUsers) => {
		setLoading(true);
		const admins = selectedUsers.map(user => user.name);
		let usersQuery = admins.join(',');
		setCreatedBy(admins);

		await axiosInstance
			.get(`/purchaseOrders/draftPurchaseOrders?page=${1}&text=${text}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setDraftPurchaseOrders(res.data));
			});
		setLoading(false);
	};

	const fetchOrders = async (type) => {
		setLoading(true);
		let usersQuery = createdBy.join(',');
		const page = type;
		await axiosInstance
			.get(`/purchaseOrders/draftPurchaseOrders?page=${page}&text=${text}&createdBy=${usersQuery}&sort=${JSON.stringify(sort)}`)
			.then((res) => {
				dispatch(setDraftPurchaseOrders(res.data));
			});
		setLoading(false);
	};


	useEffect(() => {
		// fetch draftOrders
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Draft P.O')) {
			const urlParams = new URLSearchParams(window.location.search);
			const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
			fetchOrders(page ? page : 1);
		}
	}, [text, sort])

	const handleSearch = (e) => {
		setText(e.target.value);
	}


	return (
		<Fragment>
			<div className="mb-3" style={{ textAlign:'end'}}>
				<p>
					Draft Purchase Orders: {(draftPurchaseOrders?.page * 10) - 10}-{Math.min((draftPurchaseOrders?.page * 10), draftPurchaseOrders?.total)} of {draftPurchaseOrders?.total}
				</p>
			</div>

			<form style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '15px', width: '99%' }}>
				<div className={Classes.SearchDraftOrder}>
					<TextField
						id="search"
						type="search"
						label="Search"
						value={text}
						onChange={(e) => handleSearch(e)}
						autoComplete='off'
						sx={{ width: '100%', "& label": { top: text ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<div className={Classes.CreatedByFilter}>
					<CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
				</div>
			</form>

			{draftPurchaseOrders?.data?.length  && (
				<div className="d-none d-sm-block">
					<Box className="shadow"
						component={Paper}
						sx={{
							'& .super-app-theme--header': {
								backgroundColor: '#243750',
								color: '#ffffff',
								cursor: "default",
							},
							width: '99.5%'
						}}>
						<DataGrid
							disableColumnFilter
							slots={{ toolbar: GridToolbar }}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
								},
							}}
							rows={draftPurchaseOrders?.data?.map((draftOrder) => ({
								draftPurchaseOrderName: draftOrder?.draftPurchaseOrderName,
								createdAt: createdAt(new Date(draftOrder?.createdAt)),
								vendorName: draftOrder?.vendorName ? draftOrder?.vendorName : 'N/A',
								locationName: draftOrder?.locationName ? draftOrder?.locationName : 'N/A',
								status: draftOrder?.status,
								createdBy: draftOrder?.createdBy,
								totalAmount: draftOrder?.totalAmount,
								id: draftOrder?.draftPurchaseOrderName,
								locationCode: draftOrder?.locationCode,
							}))}
							columns={columns}
							filterMode="server"
							sortingMode="server"
							paginationMode="server"
							disableRowSelectionOnClick
							autoHeight
							autoPageSize
							sx={{
								"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
									outline: "none !important",
								},
								cursor: "pointer",
								".MuiDataGrid-footerContainer": {
									display: "none !important",
								},
							}}
							onRowClick={(params) => {
								navigate(`${params?.id}`);
							}}
							initialState={{
								sorting: {
									sortModel: [{ field: 'createdAt', sort: 'desc' }],
								},
								columns: { columnVisibilityModel },
							}}
							onSortModelChange={(sort) => setSort(sort[0])}
						/>
					</Box>
				</div>
			)}

			<div className="d-block d-sm-none">
				<div
					style={{
						display: 'flex',
						padding: '8px 12px 5px',
						// borderBottom: '2px solid black',
						backgroundColor: '#243750',
						color: '#fff'
					}}
				>
					<h6 className={Classes.Column} style={{width:'85%'}}>
						Draft Order
					</h6>
					<h6 className={Classes.TotalPrice}>
						Total
					</h6>

				</div>


				{draftPurchaseOrders?.data?.length !== 0 ? (
					<div style={{ fontSize:14 }}>
						{draftPurchaseOrders?.data?.map((draftOrder, index) => (
							<div
								key={index} className={Classes.Row}
								onClick={() => navigate(draftOrder?.draftPurchaseOrderName)}
							>
								<div className={Classes.Column}>
									<h6>{draftOrder?.draftPurchaseOrderName}</h6>

									<div className={Classes.Responsive}>
										<p>{createdAt(draftOrder?.createdAt)}</p>

										<div style={{display:'flex'}}>
											<p className='text-muted d-block d-md-none'>Vendor:</p>
											{draftOrder?.vendorName && ( <p style={{marginLeft:5}}>{draftOrder?.vendorName}</p> )}
										</div>

										<div style={{display:'flex'}}>
											<p className='text-muted d-block d-md-none'>Location:</p>
											{draftOrder?.locationName && ( <p style={{marginLeft:5}}>{draftOrder?.locationName}</p> )}
										</div>

										<div style={{ display:'flex',alignItems:'center',gap:'4px' }}>
											<p className='text-muted d-block d-md-none'>Status:</p>
											<p style={{
												backgroundColor: draftOrder?.status === 'Completed' ? '#d4edda': '#ffd79d',
												padding: '0.2rem 0.4rem',
												borderRadius: '0.6rem',
											}}>
												{draftOrder?.status}
											</p>
										</div>

										<div style={{display:'flex'}}>
											<p className='text-muted d-block d-md-none'>Created By:</p>
											{draftOrder?.createdBy && ( <p style={{marginLeft:5}}>{draftOrder?.createdBy}</p> )}
										</div>
									</div>
								</div>

								<p className={Classes.TotalPrice}>&#8377;{draftOrder?.totalAmount}</p>
							</div>
						))}
					</div>
				) : (
					<p style={{ position:'absolute',left:'41%',color:'red',marginTop:'10%' }}>
						No Draft Orders Exist
					</p>
				)}
			</div>

			{loading && <Spinner />}

			{draftPurchaseOrders?.data?.length > 0 ? (
				<div>
					<NewPagination totalPage={draftPurchaseOrders} fetchData={fetchOrders} />
				</div>
			) : null}
			
		</Fragment>
	);
};

export default DraftPurchaseOrders;
