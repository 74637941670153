
import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';

const  Media = (props) => {
    const {
        setMainImageUrl,
        setImageUrl2,
        setImageUrl3,
        setImageUrl4,
        setImageUrl5,
        setImageUrl6,
        setImageUrl7,
        handleImg,
        groupImageUrl,
        setGroupImageUrl,
        handleImageDimension,
        groupImageUrlDimension2,
		setGroupImageUrlDimension2,
		setMainImageUrlDimension2,
		setImageUrl2Dimension2,
		setImageUrl3Dimension2,
		setImageUrl4Dimension2,
		setImageUrl5Dimension2,
		setImageUrl6Dimension2,
		setImageUrl7Dimension2
    }= props;

    const handleRemoveImage = (event) => {
        const productIndex = parseInt(event.target.parentNode.id);
        const productImage = groupImageUrl.filter((image,index) => index !== productIndex);
        const url =  document.getElementById('dimension1');
        if (productImage.length === 0) {
            setMainImageUrl('');
            setImageUrl2('');
            setImageUrl3('');
            setImageUrl4('');
            setImageUrl5('');
            setImageUrl6('');
            setImageUrl7('');
        }

        if (productImage.length === 1) {
            setMainImageUrl(productImage[0]);
            setImageUrl2('');
            setImageUrl3('');
            setImageUrl4('');
            setImageUrl5('');
            setImageUrl6('');
            setImageUrl7('');
        }

        if (productImage.length === 2) {
            setMainImageUrl(productImage[0]);
            setImageUrl2(productImage[1]);
            setImageUrl3('');
            setImageUrl4('');
            setImageUrl5('');
            setImageUrl6('');
            setImageUrl7('');
        }

        if (productImage.length === 3) {
            setMainImageUrl(productImage[0]);
            setImageUrl2(productImage[1]);
            setImageUrl3(productImage[2]);
            setImageUrl4('');
            setImageUrl5('');
            setImageUrl6('');
            setImageUrl7('');
        }

        if (productImage.length === 4) {
            setMainImageUrl(productImage[0]);
            setImageUrl2(productImage[1]);
            setImageUrl3(productImage[2]);
            setImageUrl4(productImage[3]);
            setImageUrl5('');
            setImageUrl6('');
            setImageUrl7('');
        }

        if (productImage.length === 5) {
            setMainImageUrl(productImage[0]);
            setImageUrl2(productImage[1]);
            setImageUrl3(productImage[2]);
            setImageUrl4(productImage[3]);
            setImageUrl5(productImage[4]);
            setImageUrl6('');
            setImageUrl7('');
        }

        if (productImage.length === 6) {
            setMainImageUrl(productImage[0]);
            setImageUrl2(productImage[1]);
            setImageUrl3(productImage[2]);
            setImageUrl4(productImage[3]);
            setImageUrl5(productImage[4]);
            setImageUrl6(productImage[5]);
            setImageUrl7('');
            if (url.style.display === 'none'){
                url.style.display = 'block';
            }
        }

        if (productImage.length === 7) {
            setMainImageUrl(productImage[0]);
            setImageUrl2(productImage[1]);
            setImageUrl3(productImage[2]);
            setImageUrl4(productImage[3]);
            setImageUrl5(productImage[4]);
            setImageUrl6(productImage[5]);
            setImageUrl7(productImage[6]);
        }
        setGroupImageUrl(productImage);
    }

    const handleRemoveImageDimension2 = (event) => {
        const productIndex = event.target.parentNode.id;
        const productImage = groupImageUrlDimension2.filter((image,index) => `${index}Dimension2` !== productIndex);
        const url =  document.getElementById('dimension2');
        if (productImage.length === 0) {
            setMainImageUrlDimension2('');
            setImageUrl2Dimension2('');
            setImageUrl3Dimension2('');
            setImageUrl4Dimension2('');
            setImageUrl5Dimension2('');
            setImageUrl6Dimension2('');
            setImageUrl7Dimension2('');
        }

        if (productImage.length === 1) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2('');
            setImageUrl3Dimension2('');
            setImageUrl4Dimension2('');
            setImageUrl5Dimension2('');
            setImageUrl6Dimension2('');
            setImageUrl7Dimension2('');
        }

        if (productImage.length === 2) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2(productImage[1]);
            setImageUrl3Dimension2('');
            setImageUrl4Dimension2('');
            setImageUrl5Dimension2('');
            setImageUrl6Dimension2('');
            setImageUrl7Dimension2('');
        }

        if (productImage.length === 3) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2(productImage[1]);
            setImageUrl3Dimension2(productImage[2]);
            setImageUrl4Dimension2('');
            setImageUrl5Dimension2('');
            setImageUrl6Dimension2('');
            setImageUrl7Dimension2('');
        }

        if (productImage.length === 4) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2(productImage[1]);
            setImageUrl3Dimension2(productImage[2]);
            setImageUrl4Dimension2(productImage[3]);
            setImageUrl5Dimension2('');
            setImageUrl6Dimension2('');
            setImageUrl7Dimension2('');
        }

        if (productImage.length === 5) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2(productImage[1]);
            setImageUrl3Dimension2(productImage[2]);
            setImageUrl4Dimension2(productImage[3]);
            setImageUrl5Dimension2(productImage[4]);
            setImageUrl6Dimension2('');
            setImageUrl7Dimension2('');
        }

        if (productImage.length === 6) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2(productImage[1]);
            setImageUrl3Dimension2(productImage[2]);
            setImageUrl4Dimension2(productImage[3]);
            setImageUrl5Dimension2(productImage[4]);
            setImageUrl6Dimension2(productImage[5]);
            setImageUrl7Dimension2('');
            if (url.style.display === 'none'){
                url.style.display = 'block';
            }
        }

        if (productImage.length === 7) {
            setMainImageUrlDimension2(productImage[0]);
            setImageUrl2Dimension2(productImage[1]);
            setImageUrl3Dimension2(productImage[2]);
            setImageUrl4Dimension2(productImage[3]);
            setImageUrl5Dimension2(productImage[4]);
            setImageUrl6Dimension2(productImage[5]);
            setImageUrl7Dimension2(productImage[6]);
        }
        setGroupImageUrlDimension2(productImage);
    }
	
	return (
		<Fragment>
            <div className={Classes.Box}>
                <div className="mb-3">
                    <h6>Media</h6>
                </div>

                <div>
                    <div 
                        className={Classes.ProductImg} 
                        id='product'
                        style={{display:'flex',flexWrap:'wrap'}}
                    >
                        {groupImageUrl.length !== 0 && (
                            <div 
                                id='smallImage' 
                                style={{ display:'flex',flexWrap:'wrap' }}
                            >
                                {groupImageUrl.map((productImg,index) => (
                                    <div 
                                        id={index}
                                        key={index}
                                        className='smallImageDiv'
                                    >
                                        <img 
                                            src={
                                                productImg ?
                                                productImg :
                                                process.env.REACT_APP_IMAGE_COMMING_SOON
                                            }
                                            style={{width:'100%',height:'100%'}}
                                            alt=""
                                        />
                                        <p className={Classes.dimension}>
                                            400x400
                                        </p>
                                        <img 
                                            src="https://cdn-icons-png.flaticon.com/512/1828/1828613.png" 
                                            alt=""
                                            className='crossIcon'
                                            onClick={handleRemoveImage}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {groupImageUrlDimension2.length !== 0 && (
                            <div 
                                id='smallImage' 
                                style={{
                                    display:'flex',
                                    flexWrap:'wrap',
                                    // width:388
                                }}
                            >
                                {groupImageUrlDimension2.map((productImg,index) => (
                                    <div 
                                        key={`${index}Dimension2`}
                                        id={`${index}Dimension2`}
                                        className='smallImageDiv'
                                    >
                                        <img 
                                            src={
                                                productImg ?
                                                productImg :
                                                process.env.REACT_APP_IMAGE_COMMING_SOON
                                            }
                                            style={{width:'100%',height:'100%'}}
                                            alt=""
                                        />
                                        <p 
                                            className={Classes.dimension}
                                            style={{ backgroundColor:'#d0ffc4' }}
                                        >
                                            1200x1200
                                        </p>
                                        <img 
                                            src="https://cdn-icons-png.flaticon.com/512/1828/1828613.png" 
                                            alt=""
                                            className='crossIcon'
                                            onClick={handleRemoveImageDimension2}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div style={{width:'100%'}}>
                        <div id='dimension1'>
                            <p style={{ fontWeight:400 }}>
                                Upload Url 400x400
                            </p>
                            <input
                                type="url"
                                id="img"
                                name='url'
                                autoComplete='off'
                                placeholder='Add File Url'
                                className={[
                                    Classes.imgInput, 
                                    Classes.ImageUrl
                                ].join(' ')}
                                onBlur={(e) => handleImg(e)}
                            />
                        </div>

                        <div style={{marginTop:5}} id='dimension2'>
                            <p style={{ fontWeight:400 }}>
                                Upload Url 1200x1200
                            </p>
                            <input
                                type="url"
                                id="img"
                                name='url'
                                autoComplete='off'
                                placeholder='Add File Url'
                                className={[
                                    Classes.imgInput, 
                                    Classes.ImageUrl
                                ].join(' ')}
                                onBlur={(e) => handleImageDimension(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};


export default Media;
