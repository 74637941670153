
import { Fragment } from 'react';
import Classes from './css/addProduct.module.css';


const  GroupTitle = (props) => {
    const {
        showErrorMsg,
        productDetailsVariant
    }= props;

	
	return (
		<Fragment>
            <div className={Classes.Box}>
            <div style={{display:'flex'}}>
                        <h6>Description</h6>
                    </div>

                <div className={Classes.Field} >  
    
                    <div>
                        <textarea 
                            name='description'
                            autoComplete='off'
                            className={Classes.TextArea}
                            key={
                                productDetailsVariant.length !== 0 
                                && productDetailsVariant[0].productDescription?
                                productDetailsVariant[0].productDescription :
                                ''
                            }
                            defaultValue={
                                productDetailsVariant.length !== 0 
                                && productDetailsVariant[0].productDescription?
                                productDetailsVariant[0].productDescription :
                                ''
                            }
                        ></textarea>
                    </div>

                    <div>
                        {showErrorMsg.length !== 0 && (
                            <div>
                                {showErrorMsg.fieldName === 'descriptionHtml' && (
                                    <p>{showErrorMsg.message}</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
		</Fragment>
	);
};

export default GroupTitle;
