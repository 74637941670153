import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';

const PaymentDetailsComponent = (props) => {
    const {
        showProduct,
        showDiscountPopup,
        handleClick,
        handleTaxRates,
        handleTaxAmount,
        showCustomerDetail,
        showLocationDetail,
        sellerLocationAddress,
        sellerLocationBillingAddress,
        updateExistOrder,
        notExistProductShopify,
        handleCreateDraftOrder,
        handleSendPI,
        handleUpdateOrder,
        buttonText,
        customerLocationDetail,
        customerLocationAddress,
        customerLocationBillingAddress
    } = props;

    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    return (
        <Fragment>
            <div className={[Classes.Box,'box'].join(' ')} id='createOrderPaymentBox'>
                <div className={Classes.containerBox}>
                    <h6 style={{ margin:'auto 0px 12px' }}>Payment</h6>

                    <div className={Classes.fontSize}>
                        <div 
                            className={['removeSomeMarginPrint',Classes.commonDisplayContent].join(' ')}
                            style={{ marginBottom: '1rem' }}
                        >
                            <p>Subtotal</p>
                            <p>&#8377;{showProduct?.length !== 0? formatNumber(showProduct?.subTotal): 0.00}</p>
                        </div>

                        <div className={Classes.commonTextWidth}>
                            <div
                                className={[Classes.textMutedContent,'removeSomeMarginPrint'].join(' ')}
                                id='noDiscountAvailPrint'
                            >
                                {showProduct?.length !== 0 ? (
                                    <div style={{ width:'30%' }}>
                                        {showProduct?.overallDiscount ? (
                                            <>
                                                <p
                                                    className={['removeEditDiscountPrint',Classes.editDiscount].join(' ')}
                                                    style={{ width: '100%' }}
                                                    onClick={showDiscountPopup}
                                                >
                                                    Edit Discount
                                                </p>
                                                <p className={['showEditDiscountPrint',Classes.discount].join(' ')}>
                                                    Discount
                                                </p>
                                            </>
                                        ) : (
                                            <p
                                                className={['discountRemPrint',Classes.editDiscount].join(' ')}
                                                style={{ width: '100%' }}
                                                onClick={showDiscountPopup}
                                            >
                                                Add Discount
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <p>Add Discount</p>
                                )}

                                <p className='removerReason'>
                                    {showProduct?.overallDiscount && showProduct?.overallDiscount?.title}
                                </p>

                                {showProduct?.overallDiscount ? (
                                    <p style={{ textAlign:'end' }} className={Classes.overAllDiscount}>
                                        -{showProduct?.overallDiscount?.valueType === 'PERCENTAGE' ? 
                                        `${showProduct?.overallDiscount?.value}%`: 
                                        `₹${showProduct?.overallDiscount?.value}`}
                                    </p>
                                ) : (
                                    <p className='discountRemPrint' style={{ textAlign:'end' }}>
                                        &#8377;0.00
                                    </p>
                                )}
                            </div>

                            <div 
                                className={[Classes.shippingBox,'removeSomeMarginPrint'].join(' ')}
                                id='removeShippingPrint'
                            >
                                {showProduct?.length !== 0  ? (
                                    <div>
                                        {showProduct?.shippingLine && Object.keys(showProduct?.shippingLine).length !== 0 ? (
                                            <div className={Classes.shippingContent}>
                                                <p className={Classes.editShipping} onClick={handleClick}>
                                                    Shipping Charges
                                                </p>

                                                <p className={Classes.shippingPrice}>
                                                    +₹{showProduct?.shippingLine?.price}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className={Classes.shippingContent}>
                                                <p className={Classes.editDiscount} onClick={handleClick}>
                                                    Shipping Charges
                                                </p>
                                                <p>--</p>
                                                <p style={{ textAlign: 'end' }}>₹0.00</p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={Classes.shippingContent}>
                                        <p onClick={handleClick}>Add Shipping</p>
                                        <p>--</p>
                                        <p style={{ textAlign: 'end' }}>₹0.00</p>
                                    </div>
                                )}
                            </div>

                            <div className={[Classes.textMutedContent,'removeSomeMarginPrint'].join(' ')}>
                                <p>Tax</p>
                                {showProduct?.length !== 0 ? (
                                    <p
                                        className={['removeShowTaxPrint',Classes.taxRates].join(' ')}
                                        onClick={handleTaxRates}
                                    >
                                        Show tax rates
                                    </p>
                                ) : (
                                    <p>Not calculated</p>
                                )}

                                {showProduct?.length !== 0 ? (
                                    <p className={Classes.taxAmount}>
                                        ₹{handleTaxAmount(showProduct?.totalTaxAmount)}
                                    </p>
                                ) : (
                                    <p style={{ textAlign: 'end' }}>₹0.00</p>
                                )}
                            </div>
                        </div>

                        <div className={Classes.Total}>
                            <p>Total</p>
                            <p>
                                ₹{showProduct?.length !== 0 ? 
                                Math.round(showProduct.total ? showProduct.total : showProduct.subTotal + showProduct.totalTaxAmount)
                                : 0.00}
                            </p>
                        </div>
                    </div>
                </div>

                <div className={Classes.containerFooter} id='removeCreateOrderPrint'>
                    {(showProduct?.length !== 0 && (showCustomerDetail?.length !== 0 || showLocationDetail?.length) !== 0) && (
                        <>
                            {!updateExistOrder ? (
                                <div className={Classes.buttonBox}>
                                    {!notExistProductShopify && (
                                        <>
                                            {buttonText === 'Create WareHouse Inventory Move' ? (
                                                <>
                                                    {sellerLocationAddress?.length !== 0 &&
                                                    sellerLocationBillingAddress?.length !== 0 && 
                                                    customerLocationDetail?.length !== 0 && 
                                                    customerLocationAddress?.length !== 0 && 
                                                    customerLocationBillingAddress?.length !== 0 && (
                                                        <button
                                                            id='draftOrderBtn'
                                                            className={[Classes.draftOrderButton,Classes.Btn].join(' ')}
                                                            style={{ backgroundColor: '#6c943eeb' }}
                                                            onClick={(e) => handleCreateDraftOrder(e)}
                                                        >
                                                            {buttonText}
                                                        </button>
                                                    )}
                                                </>
                                            ) : (
                                                <button
                                                    id='draftOrderBtn'
                                                    className={[Classes.draftOrderButton,Classes.Btn].join(' ')}
                                                    style={{ backgroundColor: '#6c943eeb' }}
                                                    onClick={(e) => handleCreateDraftOrder(e)}
                                                >
                                                    {buttonText}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className={Classes.buttonBox}>
                                    <div style={{ margin: '0px 20px' }}>
                                        <button
                                            className={[Classes.Btn,Classes.createOrderSendPIButton].join(' ')}
                                            id='submitBtn'
                                            onClick={(e) => handleSendPI(e)}
                                        >
                                            Send PI
                                        </button>
                                    </div>

                                    <div>
                                        <button
                                            onClick={(e) => handleUpdateOrder(e)}
                                            id='createOrderBtn'
                                            className={Classes.updateOrderButton}
                                        >
                                            Update Order
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default PaymentDetailsComponent;