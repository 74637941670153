import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from '../ordersNew/css/orders.module.css';
import Aside from '../ordersNew/aside/aside';
import axiosInstance from '../../../utility/axios-instance';
import { BiArrowBack } from 'react-icons/bi';
import { TbTruckReturn } from 'react-icons/tb';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';


const ReturnDetail = (props) => {
    const [error, setError] = useState(null);
    const [order, setOrder] = useState([]);

    // sendExistOrder
    const [updateToggle, setUpdateToggle] = useState(true);
    const [returnDetails, setReturnDetails] = useState({});

    const years = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dn = new Date();

    const createdAt = (createdAt) => {
        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

    const navigate = useNavigate();

    const { id } = useParams();

    const countPlaces = (num) => {
        var sep = String(23.32).match(/\D/)[0];
        var b = String(num).split(sep);
        return b[1] ? b[1].length : 0;
    }

    // handle Discount
    const handleDiscount = (mrp, discountedPrice, value) => {
        const MRP = parseFloat(mrp);
        const DP = parseFloat(discountedPrice);
        const GST = parseInt(value);
        const result = (MRP - (DP * (1 + GST / 100))) / MRP;
        const TotalDiscount = result * 100;
        const countDecimal = countPlaces(TotalDiscount);
        let results = countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? TotalDiscount : Math.trunc(TotalDiscount * 100) / 100;
        return results;
    };

    const handleInclGstTotal = (amount, tax) => {
        const total = parseFloat(amount);
        const gst = parseInt(tax);
        const result = total * (1 + (gst / 100));
        const countDecimal = countPlaces(result)
        return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result * 100) / 100
    }

    const rateInclGST = (amount, tax) => {
        const total = parseFloat(amount);
        const gst = parseInt(tax);
        const result = total * (1 + (gst / 100));
        const countDecimal = countPlaces(result)
        return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? result : Math.trunc(result * 100) / 100
    }


    const fetchReturn = async () => {
        if (id) {
            axiosInstance
                .get(`/pgOrder/admin/return/${id}`)
                .then((response) => {
                    setOrder(response?.data?.data?.order)
                    setReturnDetails(response?.data?.data?.fetchedReturnDetails)
                });
        }
    }

    useEffect(() => {
        fetchReturn();
    }, [id, updateToggle])

    const capitalizeState = (text) => {
        const arr = text.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    const handleApprove = async (e, id) => {
        e.preventDefault();
        e.target.innerHTML = "Approving.."
        if (id) {
            axiosInstance
                .put(`/pgOrder/admin/approveRefundRequest/${id}`)
                .then((response) => {
                    fetchReturn();
                });
        }
    }

    const handleReject = async (e, id) => {
        e.preventDefault();
        e.target.innerHTML = "Rejecting.."
        if (id) {
            axiosInstance
                .put(`/pgOrder/admin/rejectRefundReq/${id}`)
                .then((response) => {
                    fetchReturn();
                });
        }
    }



    return (
        <Fragment>
            <div className={Classes.container} id="color">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    id='hideHeader'
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: '1rem' }}
                    >
                        <div>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <BiArrowBack />
                            </button>
                        </div>

                        <div>
                            <div style={{ display: 'flex', gap: '12px', textAlign: 'center', margin: 'auto 0px' }}>
                                <h6 className={Classes.draftOrderDetail}>
                                    Return: {returnDetails?.returnNumber}
                                </h6>
                                <p style={{
                                    padding: '1px 10px',
                                    borderRadius: '10px',
                                    backgroundColor: order.status === 'Completed' ? '#d4edda' : '#ffd79d'
                                }}>{returnDetails?.status}</p>
                            </div>

                            <h6 className={Classes.draftOrderDetail}>
                                {createdAt(returnDetails?.createdAt)}
                            </h6>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    </div>
                </div>

                {error ? (
                    <Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                    </Alert>
                ) : null}

                <div
                    className={Classes.mainContainer}
                    style={{ marginTop: '30px' }}
                >
                    <div
                        className={Classes.containBoxes}
                        id='productContainer'
                    >
                        {returnDetails ? (
                            <div className={[Classes.Box, Classes.CancelResponsive].join(' ')}>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ gap: '0.5rem' }}
                                >
                                    <TbTruckReturn color="red" size={32} />
                                    <p style={{ fontWeight: 600 }}>Return Requested At: </p>
                                    <p>{createdAt(returnDetails.createdAt)}</p>
                                </div>

                                {
                                    <table className="table table-borderless table-sm mt-3">
                                        <tbody>
                                            {/* <tr>
                                            <th col="row">Status</th>
                                            <td>{returnDetails?.status}</td>
                                        </tr> */}
                                            <tr>
                                                <th col="row">Reason</th>
                                                <td>{returnDetails?.reason}</td>
                                            </tr>

                                            {returnDetails?.description ? (
                                                <tr>
                                                    <th col="row">Description</th>
                                                    <td>{returnDetails?.description}</td>
                                                </tr>
                                            ) : null}

                                            {returnDetails?.refund ? (
                                                <tr>
                                                    <th col="row">Refund Amount</th>
                                                    <td>{returnDetails?.refund?.amount}</td>
                                                </tr>
                                            ) : null}

                                            {/* {returnDetails?.refundAmount ? (
                                            <tr>
                                                <th col="row">Refund Amount</th>
                                                <td>{returnDetails?.refundAmount}</td>
                                            </tr>
                                        ) : null} */}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        ) : null}

                        <div
                            className={[
                                Classes.Box,
                                'box'
                            ].join(' ')}
                        >
                            {order.length !== 0 ? (
                                <div
                                    style={{ fontSize: '14px', padding: 0 }}
                                >
                                    <div className={Classes.Thead}>
                                        <div
                                            style={{
                                                padding: '10px 20px',
                                                fontWeight: 600,
                                                width: '55%'
                                            }}
                                            className={Classes.ProductHeading}
                                            id='productHeading'
                                        >
                                            <p>Product</p>
                                        </div>

                                        <div
                                            className={[
                                                Classes.hideResponsive,
                                                'discount'
                                            ].join(' ')}
                                            style={{
                                                width: '15%',
                                                padding: '10px 0px'
                                            }}
                                        >
                                            <p style={{ fontWeight: 600 }}>
                                                Discount
                                            </p>
                                            <p
                                                style={{ fontSize: '13px' }}
                                                id='hideInclGst'
                                            >
                                                (Incl. GST)
                                            </p>
                                        </div>
                                        <div
                                            className={Classes.hideResponsive}
                                            style={{ width: '11%', padding: '10px 2px' }}
                                        >
                                            <p style={{ fontWeight: 600 }}>
                                                Quantity
                                            </p>
                                            <p
                                                style={{ fontSize: '11px' }}
                                            >
                                                (Expected)
                                            </p>
                                        </div>
                                        <div
                                            className={Classes.hideResponsive}
                                            style={{ padding: '10px 0px 10px 8px', width: '15%' }}>
                                            <p style={{ fontWeight: 600, paddingLeft: 5 }}>
                                                Total
                                            </p>
                                            <p>(Incl. GST)</p>
                                        </div>
                                        {order.OLineItems.some((item) => item.status === 'Putaway') ? (
                                            <div
                                                className={Classes.hideResponsive}
                                                style={{ padding: '10px 0px 10px 8px', width: '15%' }}>
                                                <p style={{ fontWeight: 600, paddingLeft: 5 }}>
                                                    Action
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div id='productContainer'>
                                        {order?.OLineItems?.map((item, index) => (
                                            <div
                                                className={[
                                                    Classes.containProduct,
                                                    'variantProduct'
                                                ].join(' ')}
                                                id={item.oLineItemId}
                                                key={index}
                                            >
                                                <div
                                                    className={[
                                                        Classes.productDetail,
                                                        'products'
                                                    ].join(' ')}
                                                    id={index}
                                                >
                                                    <div>
                                                        <img
                                                            src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                            alt="icon"
                                                            className={Classes.productImage}
                                                        />
                                                    </div>

                                                    <div className={Classes.VariantDetail}>
                                                        <div>
                                                            <p style={{ color: '#0d6efd' }}>
                                                                {item.productTitle}
                                                            </p>
                                                        </div>
                                                        <div style={{ color: '#6d7175' }}>
                                                            {/* <p>{item.node.variantTitle}</p> */}
                                                            <p>{item.sku}</p>
                                                        </div>
                                                        <div style={{ display: 'flex' }}
                                                            className={[
                                                                Classes.rateFontSize,
                                                                'rateExcl hidePricePrint'
                                                            ].join(' ')}
                                                        >
                                                            <p className={Classes.newRateExclGst}>
                                                                Rate Excl. GST
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p>&#8377;</p>
                                                                <p>
                                                                    {item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice}
                                                                </p>
                                                            </div>

                                                            <div>
                                                                <p className={Classes.newCompareAtPrice}>
                                                                    &#8377;{item.mrp}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}
                                                            className={[
                                                                Classes.rateFontSize,
                                                                'rateExcl hidePricePrint'
                                                            ].join(' ')}
                                                        >
                                                            <p className={Classes.newRateExclGst}>
                                                                Rate Incl. GST
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p>&#8377;</p>

                                                                <p>
                                                                    {item?.discountValue !== "0.00" ?
                                                                        rateInclGST(item?.discountValue, item?.gstPercentage) :
                                                                        rateInclGST(item.originalUnitPrice, item?.gstPercentage)}
                                                                </p>
                                                            </div>

                                                            <div>
                                                                <p className={Classes.newCompareAtPrice}>
                                                                    &#8377;{item.mrp}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* In screen is smaller than 650px then show this box */}
                                                        <div className={Classes.ShowResponsive}>
                                                            <div
                                                                className='percent'
                                                                style={{
                                                                    fontSize: '14px',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                <div>
                                                                    <p className={Classes.Text}>
                                                                        Discount Incl. GST:
                                                                    </p>
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p>
                                                                        {handleDiscount(
                                                                            item.mrp,
                                                                            item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
                                                                            item.gstPercentage === null ?
                                                                                18 : item.gstPercentage
                                                                        )}
                                                                    </p>
                                                                    <p>%</p>
                                                                </div>

                                                                {item.discountTitle !== null && (
                                                                    <div
                                                                        className={[
                                                                            Classes.hideDiscountTitle,
                                                                            'hideDiscountTitle'
                                                                        ].join(' ')}
                                                                    >
                                                                        {item.discountTitle}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div
                                                                className='qty'
                                                                style={{ display: 'flex' }}
                                                            >
                                                                <p className={Classes.Text}>
                                                                    Quantity: 
                                                                </p>
                                                                <p>{item.quantity}</p>
                                                            </div>


                                                            <div
                                                                className='total'
                                                                id='discTotalPrint'
                                                                style={{ display: 'flex' }}
                                                            >
                                                                <p className={Classes.Text}>
                                                                    Total:
                                                                </p>
                                                                &#8377;{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className={[
                                                        Classes.percent,
                                                        Classes.PercentBox,
                                                        'percent'
                                                    ].join(' ')}
                                                >
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                    >
                                                        <p>
                                                            {handleDiscount(
                                                                item.mrp,
                                                                item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
                                                                item.gstPercentage === null ?
                                                                    18 : item.gstPercentage
                                                            )}
                                                        </p>
                                                        <p>%</p>
                                                    </div>

                                                    {item.discountTitle !== null && (
                                                        <div
                                                            className={[
                                                                Classes.hideDiscountTitle,
                                                                'hideDiscountTitle'
                                                            ].join(' ')}
                                                            style={{ margin: '0px auto' }}
                                                        >
                                                            {item.discountTitle}
                                                        </div>
                                                    )}

                                                    {item.node?.appliedDiscount !== null && (
                                                        <div
                                                            className='exclGSTPrint'
                                                            style={{
                                                                fontSize: '12px',
                                                                display: 'none'
                                                            }}
                                                        >
                                                            <p>(Incl. GST)</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div
                                                    className={[
                                                        Classes.percent,
                                                        Classes.Quantity,
                                                        'qty'
                                                    ].join(' ')}
                                                >
                                                    <p>{item.quantity}</p>
                                                </div>

                                                <div
                                                    className={[
                                                        Classes.percent,
                                                        'total'
                                                    ].join(' ')}
                                                    style={{
                                                        padding: '10px 8px',
                                                        width: '80px'
                                                    }}
                                                    id='discTotalPrint'
                                                >
                                                    &#8377;{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
                                                </div>
                                                {item?.status === 'Putaway' ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '10px', padding: '5px' }}>
                                                        <div>
                                                            <Permission service={services?.returns} permission="update">
                                                                <button
                                                                    className={[
                                                                        Classes.Btn,
                                                                        Classes.completeOrderButton
                                                                    ].join(' ')}
                                                                    onClick={(e) => handleApprove(e, item?.returnId)}
                                                                >
                                                                    Approve
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                        <div>
                                                            <Permission service={services?.returns} permission="update">
                                                                <button
                                                                    className={[
                                                                        Classes.Btn,
                                                                        Classes.waitingOrderButton
                                                                    ].join(' ')}
                                                                    onClick={(e) => handleReject(e, item?.returnId)}
                                                                    style={{ backgroundColor: '#dc3545', color: '#fff' }}
                                                                >
                                                                    Reject
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {returnDetails?.length !== 0 && (
                            <div
                                className={[
                                    Classes.Box,
                                    'box'
                                ].join(' ')}
                                id='paymentBox'
                            >
                                <div className={Classes.containerBox}>
                                    <div className="d-flex justify-content-between mb-3">
                                        <h6 style={{ margin: 'auto 0px' }}>
                                            More Details
                                        </h6>
                                    </div>

                                    <div className={Classes.fontSize}>
                                        <div
                                            style={{
                                                marginBottom: '1rem',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                            <div style={{ width: '25%' }}>
                                                <p>Type</p>
                                            </div>
                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {returnDetails?.type}
                                            </p>
                                        </div>
                                        {/* <div
                                            style={{
                                                marginBottom: '1rem',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                            <div style={{ width: '25%' }}>
                                                <p>Reason</p>
                                            </div>


                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {returnDetails?.reason}
                                            </p>
                                        </div> */}

                                        {/* <div>

                                            <div style={{ marginBottom: '1rem', display: 'flex' }}>
                                                <p
                                                    style={{ width: '25%' }}
                                                >
                                                    Description
                                                </p>
                                                <p
                                                    style={{ width: '75%', textAlign: 'end' }}
                                                >
                                                    {returnDetails?.description}
                                                </p>
                                            </div>
                                        </div> */}
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ fontSize: '14px', fontWeight: '600' }}
                                        >
                                            <p>Total</p>
                                            <div>
                                                <p>
                                                    &#8377;{Math.round(
                                                        order?.OPaymentDetail?.totalPrice
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={Classes.containerFooter}
                                    id='removeCreateOrderPrint'
                                >
                                    {order.length !== 0 && (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >


                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>


                    <div
                        className={Classes.asideContainer}
                        id='asideContainer'
                    >
                        <Aside
                            draftOrder={order}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ReturnDetail;