import { Fragment } from 'react';
import Classes from '../css/addProduct.module.css';


const  ProductStatus = (props) => {
    const {
        handleSalesChannel,
        setSalesChannel,
        productDetailsVariant
    }= props;

    const handleSelectAll = (event) => {
        if (event.target.innerText === 'Select All') {
            document.getElementById('website').checked = true;
            document.getElementById('portal').checked = true;
            document.getElementById('app').checked = true;
            event.target.innerText = 'Deselect All';
            setSalesChannel(['Website','Portal','App']);

        } else {
            document.getElementById('website').checked = false;
            document.getElementById('portal').checked = false;
            document.getElementById('app').checked = false;
            event.target.innerText = 'Select All';
            setSalesChannel([]);
        }
    }

	
	return (
		<Fragment>
            <div className={Classes.Box}>
                <div style={{borderBottom:'1px solid lightgray'}}>
                    <h6>Product Status</h6>
                    <div style={{marginTop:'20px'}}>
                        <select name='isActive' id='isActive'>
                            <option value='Active'>Active</option>
                            <option value='Draft'>Draft</option>
                        </select>
                    </div>
                </div>
                <div 
                    style={{
                        margin:'10px 0px',
                        borderBottom: '1px solid lightgray',
                        paddingBottom: 10
                    }}
                >
                    <div className='d-flex justify-content-between mb-2'>
                        <p className={Classes.Sales}>
                            SALES CHANNELS AND APPS
                        </p>
                    </div>
                    
                    <div className={Classes.fontSize}>
                        <p 
                            className={Classes.DeselectAll}
                            onClick={(e) => handleSelectAll(e)}
                            style={{cursor:'pointer'}}
                        >
                            Select All
                        </p>
                    </div>
                </div>

                <div>
                    <div style={{display:'flex'}}>
                        <input 
                            type="checkbox"
                            name='Website'
                            id='website'
                            onBlur={(e) => handleSalesChannel(e)}
                        />
                        <p className={Classes.SalesChannel}>Website</p>
                    </div>

                    <div style={{display:'flex',marginTop: 15}}>
                        <input 
                            type="checkbox"
                            name='Portal'
                            id='portal'
                            onBlur={(e) => handleSalesChannel(e)}
                        />
                        <p className={Classes.SalesChannel}>Portal</p>
                    </div>

                    <div style={{display:'flex',marginTop: 15}}>
                        <input 
                            type="checkbox"
                            name='App'
                            id='app'
                            // defaultChecked={true}
                            onBlur={(e) => handleSalesChannel(e)}
                        />
                        <p className={Classes.SalesChannel}>App</p>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};

export default ProductStatus;
