import { Fragment, useState } from 'react';
import Classes from "../pages/home/createOrders/css/Modal.module.css";
import axiosInstance from '../utility/axios-instance';
import Spinner from './spinner';
import '../pages/home/createOrders/css/styles.css';
import SnackBar from './SnackBar';

const Dialog = (props)=> {
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const { 
        headerName,
        fields,
        endPoint,
        setReRender,
        reRender,
        type
    } = props;

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = {};
        const modes = {
            "Inventory Increase": 'incr',
            "Inventory Decrease": 'decr'
        }
        fields.length !== 0 && fields.map((field) => (
            data[field?.props?.name] = field?.props?.name === 'adjustedQty' ? 
            parseInt(event.target[field?.props?.name]?.value) : 
            field?.props?.name === 'mode' ? modes[event.target[field?.props?.name]?.value] : event.target[field?.props?.name]?.value
        ));

        if (type === 'create') {
            axiosInstance
                .post(endPoint, data)
                .then((res) => {
                    const form = document.getElementById('form');
                    form.reset();
                    props.closeDialog();
                    const successMessage = 'Inventory Updated Successfully'
                    if (setReRender) { setReRender(!reRender) }
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setLoading(false);
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                    setLoading(false);
                })
        } else if (type === 'update') {
            axiosInstance
                .put(endPoint, data)
                .then((res) => {
                    const form = document.getElementById('form');
                    form.reset();
                    props.closeDialog();
                    const successMessage = 'Inventory Updated Successfully'
                    if (setReRender) { setReRender(!reRender) }
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    setLoading(false);
                }).catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                    setLoading(false);
                })
        }
    }

    return (
        <Fragment>
            {props.open && (
                <div className={Classes.modal} style={{top:'12%',bottom:'16%'}}>
                    <div className={Classes.BoxContent}>
                        <p>{headerName}</p>
                    </div>

                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{ overflowY:'auto',height:'72%' }}
                    >
                        <div className={Classes.DialogFieldBox}>
                            {fields?.length !== 0 && fields?.map((field,index) => (
                                <div key={index} style={{width:'49%',marginBottom:15}}>
                                    {field}
                                </div>
                            ))}
                        </div>

                        <div className={ Classes.DialogBtnBox}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={props.closeDialog}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>
                    </form> 
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}

export default Dialog;