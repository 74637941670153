import { Fragment, useState, useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';

const SalesUserDialog = (props)=> {
    const [loading,setLoading] = useState(false);
    const [users,setUsers] = useState([]);
    const [user,setUser] = useState('');

    const {
        open,
        handleClose,
        fetchCustomers
    } = props;


    useEffect(() => {
        axiosInstance
            .get('/admin/allAdmins')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setUsers(arr);
            })
    },[]);


    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .post(`/admin/${user}`,{
                salesUser: true
            })
            .then((res) =>{
                handleClose();
                setLoading(false);
                fetchCustomers()
                setUser('');
            }).catch((err) => {
                setLoading(false);
            })
    }

    const handleSalesUser = (customer) => {
        if (customer !== null) {
            setUser(customer.Id);
        } else {
            setUser('');
        }
    }

    return (
        <Fragment>
            {open && (
                <div 
                    className={Classes.modal} 
                    style={{top:'15%',bottom:'30%',left:'30%',right:'30%'}}
                >
                    <div className={Classes.BoxContent} style={{padding:'15px 25px'}}>
                        <h6 style={{fontSize:17}}>Users</h6>
                    </div>

                    <form method='post' onSubmit={handleSubmit}>
                        <div style={{padding:"10px 20px 0px"}}>
                            {users.length !== 0 && (
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="user"
                                    name='user'
                                    options={users}
                                    ListboxProps={{sx: { fontSize: 14 }}}
                                    sx={{width: '100%',"& label": {top: user ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                    onChange={(event,newValue) => handleSalesUser(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Users" required/>}
                                />
                            )}
                        </div>

                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'30%',
                                position:'fixed',
                                width:'40%',
                                boxShadow: "none",
                                border: 0
                            }}
                        >
                            <button 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleClose}
                            >
                                CANCEL
                            </button>


                            <button
                                className={Classes.UpdateSalesUserOfCustomer}
                                type='submit'
                            >
                                ADD
                            </button>
                        </div>
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}

export default SalesUserDialog;