import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/draftOrderDetailPage.module.css';
import VendorDetails from './sendPIVendorDetails';
import Aside from './aside/aside';
import axiosInstance from '../../../utility/axios-instance';
import { BiArrowBack } from 'react-icons/bi';
import TaxRatesPopup from './TaxRates_popup';
import DeleteOrderConfirmPopup from './deleteOrderPopup';
import Permission from '../../auth/permissions';
import SnackBar from '../../../components/SnackBar';
import Spinner from '../../../components/spinner';
import { services } from '../../../utility/checkRoleBasedPermission';
import CapsuleChip from '../../../components/CapsuleChip';


const DraftPurchaseOrderDetail = (props) => {
    const [showTaxRate, setTaxRates] = useState(false);
    const [draftOrder, setDraftOrder] = useState([]);
    const [totalItems, setTotalItems] = useState(0)

    // store deliveryRemark & notes in State
    const [notes, setNotes] = useState('');
    const [deliveryRemark, setDeliveryRemark] = useState('');

    // sendExistOrder
    const [updateToggle, setUpdateToggle] = useState(true);
    const [completeExistOrder, setCompleteExistOrder] = useState([]);
    const [showDeleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [loading, setLoading] = useState(false);


    const handleTotal = (price, quantity) => {
        const result = price * quantity;
        return Math.round(result);
    }


    const handleShowDeleteOrderPopup = () => {
        setDeleteConfirmPopup(true);
    }


    const hideDeleteOrderConfirmPopup = (event, orderId) => {
        if (event.target.innerText === 'Yes') {
            handleDeleteOrder(orderId);
        }
        setDeleteConfirmPopup(false);
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }

    const years = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dn = new Date();

    const createdAt = (createdAt) => {
        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

    const navigate = useNavigate();

    const { id } = useParams();


    // handle Discount

    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };


    // Open Window Print
    const handleSendPI = (event) => {
        const hideButtons = document.getElementById('removeCreateOrderPrint');
        const hideHeader = document.getElementById('hideHeader');
        const hideProductHeader = document.getElementById('DOProductHeading');
        const qtyHeader = document.getElementById('quantity');
        const totalHeader = document.getElementById('total');
        const paymentBox = document.getElementById('paymentBox');
        document.title = draftOrder?.draftPurchaseOrderName;
        const discountHeader = document.getElementById('discount');
        const subtotalHeader = document.getElementById('subtotal');
        const total = document.getElementsByClassName('total');
        const discount = document.getElementsByClassName('percent');
        const netPrice = document.getElementsByClassName('rateExcl');
        const aside = document.getElementById('DraftPOAsideContainer');
        const capsuleChips = document.getElementsByClassName('capsule-chip');

        // const check = draftOrder?.DpoLineItems.findI
        const array = draftOrder?.DpoLineItems;

        const isKeyInArray = (arr, key) => {
            return arr.some(obj => obj[key]);
        }

        const isKeyInArray1 = (arr, key) => {
            return arr.some(obj => obj[key] !== '100.00');
        }

        const exist = isKeyInArray(array, "discountValue");
        const exist1 = isKeyInArray1(array, "discountValue");
        if (!exist || !exist1) {
            hideProductHeader.style.width = '88%';
            discountHeader.style.display = 'none';
            subtotalHeader.style.display = 'none';
            totalHeader.style.display = 'none';
            for (let ele of total) {
                ele.style.display = 'none';
            }

            for (let ele of discount) {
                ele.style.display = 'none';
            }

            for (let ele of netPrice) {
                ele.style.display = 'none';
            }
        } else {
            hideProductHeader.style.width = '64%';
        }

        if (capsuleChips.length > 0) {
            for (let ele of capsuleChips) {
                ele.style.display = 'none';
            }
        }

        qtyHeader.style.width = '9%';
        // hideProductHeader.style.width = '64%';
        hideHeader.style.display = 'none';
        hideButtons.style.display = 'none';
        totalHeader.style.width = '15%';
        paymentBox.style.display = 'none';
        aside.style.display = 'none';

        window.print();
        onafterprint = document.title = 'DEPO24 PORTAL';
        onafterprint = paymentBox.style.display = 'block';
        onafterprint = qtyHeader.style.width = '11%';
        onafterprint = hideHeader.style.display = 'flex';
        onafterprint = hideProductHeader.style.width = '68%';
        onafterprint = totalHeader.style.width = '13%';
        onafterprint = hideButtons.style.display = 'block';
        onafterprint = discountHeader.style.display = 'block';
        onafterprint = subtotalHeader.style.display = 'block';
        onafterprint = totalHeader.style.display = 'block';
        aside.style.display = 'block';

        const handleShowData = () => {
            for (let ele of total) {
                ele.style.display = 'flex';
            }

            for (let ele of discount) {
                ele.style.display = 'block';
            }

            for (let ele of netPrice) {
                ele.style.display = 'flex';
            }

            for (let ele of capsuleChips) {
                ele.style.display = 'flex';
            }

            return;
        }

        onafterprint = function () {
            handleShowData()
        }
    }


    const handleTaxRates = () => {
        setTaxRates(true);
    };

    const hideTaxPopup = () => {
        setTaxRates(false);
    }

    // Complete Exist Order

    const handleCompleteOrder = (event) => {
        setLoading(true)
        axiosInstance
            .post(`/purchaseOrders/completeDraftPurchaseOrder/${completeExistOrder[0]}`)
            .then((res) => {
                const successMessage = `Draft PO Completed Successfully`
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setTimeout(() => {
                    navigate(`/purchaseOrders`)
                }, 1000);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleCopyDpo = (event) => {
        setLoading(true)
        axiosInstance
            .post(`/purchaseOrders/copyDraftPurchaseOrder/${completeExistOrder[0]}`)
            .then((res) => {
                const successMessage = `Draft PO Copied Successfully`
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setTimeout(() => {
                    navigate(`/draftPurchaseOrders`)
                }, 1000);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleRevertOrder = async (event) => {
        setLoading(true)
        axiosInstance
            .put(`/purchaseOrders/changeStatusToDraftOfDraftPurchaseOrder/${completeExistOrder[0]}`)
            .then((res) => {
                const successMessage = `Draft PO Reverted Successfully`
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                navigate(`/draftPurchaseOrders`)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleSendForApprovalOrder = (event) => {
        setLoading(true)
        axiosInstance
            .put(`/purchaseOrders/sendForApprovalDraftPurchaseOrder/${completeExistOrder[0]}`)
            .then((res) => {
                const successMessage = `Request For Approval Sent Successfully`
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                fetchDraftPurchaseOrder();
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleDeleteOrder = (Id) => {
        axiosInstance
            .delete(`/purchaseOrders/draftPurchaseOrder/${Id}`)
            .then((res) => {
                navigate('/draftPurchaseOrders');
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const fetchDraftPurchaseOrder = () => {
        if (id) {
            if (updateToggle) {
                setLoading(true)
                axiosInstance
                    .get(`/purchaseOrders/draftPurchaseOrder/${id}`)
                    .then((response) => {
                        let ti = 0;
                        for (
                            let i = 0;
                            i < response.data?.data?.DpoLineItems?.length;
                            i++
                        ) {
                            ti += response.data?.data?.DpoLineItems[i]?.quantity;
                        }
                        setTotalItems(ti);
                        setDraftOrder(response.data?.data)
                        setDeliveryRemark(response.data?.data?.deliveryRemark);
                        setNotes(response.data.data.notes)
                        setCompleteExistOrder([response.data?.data?.draftPurchaseOrderName]);
                        setLoading(false)
                    })
                    .catch((error) => {
                        const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                        setLoading(false)
                        setSnackBar((prevSnackBar) => {
                            return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                        });
                    })
            }
        }
    }

    useEffect(() => {
        fetchDraftPurchaseOrder();
    }, [id, updateToggle])


    return (
        <Fragment>
            {loading && <Spinner />}
            <div className={Classes.container} id="color">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    id='hideHeader'
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: '1rem' }}
                    >
                        <div>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <BiArrowBack />
                            </button>
                        </div>

                        <div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <h6 className={Classes.draftOrderDetail}>
                                    {draftOrder?.draftPurchaseOrderName}
                                </h6>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <p style={{
                                        padding: '2px 10px',
                                        borderRadius: '10px',
                                        backgroundColor: draftOrder.status === 'Completed' ? '#d4edda' : '#ffd79d'
                                    }}>{draftOrder.status}</p>
                                </div>

                            </div>

                            <h6 className={Classes.draftOrderDetail}>
                                {createdAt(draftOrder.createdAt)}
                            </h6>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 10,
                        gap: '12px'
                    }}>
                        <Permission service={services?.draftPurchaseOrders} permission="create">
                            <button
                                onClick={handleCopyDpo}
                                style={{ backgroundColor: 'black', color: '#fff' }}
                                className={[Classes.Btn, Classes.sendPIButton].join(' ')}
                            >
                                Copy DPO
                            </button>
                        </Permission>
                        <button
                            className={[Classes.Btn, Classes.sendPIButton].join(' ')}
                            id='submitBtn'
                            onClick={(e) => handleSendPI(e)}
                        >
                            Request Quote
                        </button>
                    </div>
                </div>

                {draftOrder && (
                    <div className={Classes.draftOrderName} id='orderNamePrint'>
                        <p style={{ textAlign: 'center' }}>Quotation Required</p>
                        <div style={{ display: 'flex' }}>
                            <h6 style={{ marginRight: '5px' }}>DPO Name:-</h6>
                            <p>{draftOrder?.draftPurchaseOrderName}</p>
                        </div>
                    </div>
                )}

                <VendorDetails
                    draftOrder={draftOrder}
                />

                <div
                    className={Classes.mainContainer}
                    style={{ marginTop: '30px' }}
                >
                    <div
                        className={Classes.containBoxes}
                        id='productContainer'
                    >
                        <div
                            className={[Classes.Box, 'box'].join(' ')}
                            id='productBox'
                        >
                            {draftOrder ? (
                                <div style={{ fontSize: '14px', padding: 0 }}>
                                    <div className={Classes.Thead}>
                                        <div
                                            style={{
                                                padding: '10px 20px',
                                                fontWeight: 600
                                            }}
                                            className={Classes.ProductHeading}
                                            id='DOProductHeading'
                                        >
                                            <p>Product</p>
                                        </div>

                                        <div
                                            id='discount'
                                            className={[Classes.hideResponsive, 'discount'].join(' ')}
                                            style={{ width: '12%', padding: '10px 0px' }}
                                        >
                                            <p style={{ fontWeight: 600 }}>
                                                Discount
                                            </p>
                                            <p
                                                style={{ fontSize: '13px' }}
                                                id='hideInclGst'
                                            >
                                                (Incl. GST)
                                            </p>
                                        </div>

                                        <div
                                            id='quantity'
                                            className={Classes.hideResponsive}
                                            style={{ width: '11%', padding: '10px 0px' }}
                                        >
                                            <p style={{ fontWeight: 600 }}>
                                                Quantity
                                            </p>
                                        </div>

                                        <div
                                            id='subtotal'
                                            className={Classes.hideResponsive}
                                            style={{ padding: '10px 0px 10px 10px', width: '14%' }}
                                        >
                                            <p style={{ fontWeight: 600, paddingLeft: 5 }}>
                                                Subtotal
                                            </p>
                                            <p>(Excl. GST)</p>
                                        </div>

                                        <div
                                            id='total'
                                            className={Classes.hideResponsive}
                                            style={{ padding: '10px 0px 10px 10px', width: '13%' }}
                                        >
                                            <p style={{ fontWeight: 600, paddingLeft: 5 }}>
                                                Total
                                            </p>
                                            <p>(Incl. GST)</p>
                                        </div>
                                    </div>

                                    <div id='productContainer'>
                                        {draftOrder?.DpoLineItems?.map((item, index) => (
                                            <div
                                                className={[
                                                    Classes.containProduct, 'variantProduct'
                                                ].join(' ')}
                                                id={item?.lineItemId}
                                                key={index}
                                            >
                                                <div
                                                    className={[
                                                        Classes.productDetail, 'products'
                                                    ].join(' ')}
                                                    id={index}
                                                >
                                                    <div>
                                                        <img
                                                            src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                            alt="icon"
                                                            className={Classes.productImage}
                                                        />
                                                    </div>

                                                    <div className={Classes.VariantDetail}>
                                                        <div>
                                                            <p style={{ color: '#0d6efd' }}>
                                                                {item?.productTitle}
                                                            </p>
                                                        </div>

                                                        <div style={{ color: '#6d7175' }}>
                                                            <p>{item?.sku}</p>
                                                        </div>

                                                        <div className={[Classes.rateFontSize, 'rateExcl'].join(' ')}>
                                                            <p className={Classes.newRateExclGst}>
                                                                Net Price
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                <p>{formatNumber(item?.discountedUnitPrice ? item?.discountedUnitPrice : item?.originalUnitPrice)}</p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{ display: 'flex' }}
                                                            className={[Classes.rateFontSize].join(' ')}
                                                        >
                                                            <p className={Classes.newRateExclGst}>
                                                                MRP
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                <p>{item?.mrp}</p>
                                                            </div>
                                                        </div>

                                                        {item?.locationCodeForPrices && <CapsuleChip capsulePart1="Prices for Location" capsulePart2={`${item?.locationCodeForPrices}`} chipColor={"#243750"} />}

                                                        {/* In screen is smaller than 650px then show this box */}
                                                        <div className={Classes.ShowResponsive}>
                                                            <div
                                                                className='percent'
                                                                style={{ fontSize: '14px', display: 'flex' }}
                                                            >
                                                                <div>
                                                                    <p className={Classes.Text}>
                                                                        Discount Incl. GST:
                                                                    </p>
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    {item.discountPercentage}
                                                                    <p>%</p>
                                                                </div>

                                                                {item.appliedDiscount !== null && (
                                                                    <div
                                                                        className={[
                                                                            Classes.hideDiscountTitle,
                                                                            'hideDiscountTitle'
                                                                        ].join(' ')}
                                                                    >
                                                                        {item.appliedDiscount}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div
                                                                className='qty'
                                                                style={{ display: 'flex' }}
                                                            >
                                                                <p className={Classes.Text}>
                                                                    Quantity:
                                                                </p>
                                                                <p>{item?.quantity}</p>
                                                            </div>

                                                            <div
                                                                className='total'
                                                                id='discTotalPrint'
                                                                style={{ display: 'flex' }}
                                                            >
                                                                <p className={Classes.Text}>
                                                                    Subtotal:
                                                                </p>
                                                                &#8377;
                                                                {
                                                                    formatNumber(item?.discountedSubTotal)
                                                                }
                                                            </div>

                                                            <div
                                                                className='total'
                                                                id='discTotalPrint'
                                                                style={{ display: 'flex' }}
                                                            >
                                                                <p className={Classes.Text}>
                                                                    Total:
                                                                </p>
                                                                &#8377;
                                                                {formatNumber(item?.discountedTotal ?
                                                                    item?.discountedTotal :
                                                                    item?.originalTotal)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className={[
                                                        Classes.percent, Classes.PercentBox, 'percent'
                                                    ].join(' ')}
                                                >
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                    >
                                                        {item.discountPercentage}

                                                        <p>%</p>
                                                    </div>

                                                    {item.discountTitle === "MANUAL" && item.discountValue && (
                                                        <div
                                                            className={[
                                                                Classes.hideDiscountNewTitle,
                                                                'hideDiscountTitle'
                                                            ].join(' ')}
                                                        >
                                                            {item.discountTitle}
                                                        </div>
                                                    )}

                                                    {item.node?.appliedDiscount !== null && (
                                                        <div
                                                            className='exclGSTPrint'
                                                            style={{
                                                                fontSize: '12px',
                                                                display: 'none'
                                                            }}
                                                        >
                                                            <p>(Incl. GST)</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div
                                                    id='quantityCount'
                                                    className={[
                                                        Classes.percent, Classes.Quantity, 'draftPoQty'
                                                    ].join(' ')}
                                                >
                                                    <p>{item?.quantity}</p>
                                                </div>

                                                <div
                                                    className={[Classes.percent, 'total'].join(' ')}
                                                    style={{ padding: '10px 0px 10px 5px', width: '80px' }}
                                                    id='discTotalPrint'
                                                >
                                                    &#8377;
                                                    {
                                                        formatNumber(item?.discountedSubTotal)
                                                    }
                                                </div>

                                                <div
                                                    className={[Classes.percent, 'total'].join(' ')}
                                                    style={{ padding: '10px 0px 10px 5px', width: '80px' }}
                                                    id='discTotalPrint'
                                                >
                                                    &#8377;
                                                    {formatNumber(item?.discountedTotal ?
                                                        item?.discountedTotal :
                                                        item?.originalTotal)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div
                            className={[Classes.Box, 'box'].join(' ')}
                            id='paymentBox'
                        >
                            <div className={Classes.containerBox}>
                                <div className="d-flex justify-content-between mb-3">
                                    <h6 style={{ margin: 'auto 0px' }}>
                                        Payment
                                    </h6>
                                </div>

                                <div className={Classes.fontSize}>
                                    {draftOrder?.DpoPaymentDetails?.invoiceNumber && (
                                        <div
                                            style={{
                                                marginBottom: '1rem',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                            <div style={{ width: '25%' }}>
                                                <p>Invoice Number</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {draftOrder?.DpoPaymentDetails?.invoiceNumber}
                                            </p>
                                        </div>
                                    )}

                                    {draftOrder?.DpoPaymentDetails?.invoiceDate && (
                                        <div
                                            style={{
                                                marginBottom: '1rem',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                            <div style={{ width: '25%' }}>
                                                <p>Invoice Date</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {formatDate(draftOrder?.DpoPaymentDetails?.invoiceDate)}
                                            </p>
                                        </div>
                                    )}
                                    {draftOrder?.DpoPaymentDetails?.paymentMode && (
                                        <div
                                            style={{
                                                marginBottom: '1rem',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        >
                                            <div style={{ width: '25%' }}>
                                                <p>Payment Mode</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {draftOrder?.DpoPaymentDetails?.paymentMode?.toUpperCase()}
                                            </p>
                                        </div>
                                    )}

                                    <div
                                        style={{
                                            marginBottom: '1rem',
                                            width: '100%',
                                            display: 'flex'
                                        }}
                                    >
                                        <div style={{ width: '25%' }}>
                                            <p>Subtotal</p>
                                        </div>

                                        <div style={{ width: '50%', display: 'flex' }}>
                                            <p style={{ marginRight: 3 }}>
                                                {totalItems}
                                            </p>
                                            <p>Items</p>
                                        </div>

                                        {draftOrder && (
                                            <p style={{ width: '25%', textAlign: 'end' }}>
                                                &#8377;{formatNumber(draftOrder?.DpoPaymentDetails?.subTotalAmount)}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <div style={{ marginBottom: '1rem' }}>
                                            {draftOrder && (
                                                <div>
                                                    {draftOrder?.DpoShipmentDetails?.freightCharges && (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <p style={{ width: '25%' }}>
                                                                Shipping
                                                            </p>

                                                            <p style={{ width: '50%' }}>
                                                                {draftOrder?.DpoShipmentDetails?.transporterName}
                                                            </p>

                                                            <p style={{ width: '25%', textAlign: 'end' }}>
                                                                &#8377;{draftOrder?.DpoShipmentDetails?.freightCharges}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {draftOrder?.length !== 0 && (
                                            <div
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                {draftOrder?.DpoPaymentDetails?.overallDiscountValue && (
                                                    <div
                                                        style={{ display: 'flex', width: '100%' }}
                                                    >
                                                        <p style={{ width: '25%' }}>
                                                            Discount
                                                        </p>

                                                        <div style={{ width: '50%', display: 'flex' }}>
                                                            <p style={{ marginRight: 3 }}>
                                                                {draftOrder.DpoPaymentDetails?.overallDiscountTitle}
                                                            </p>
                                                        </div>
                                                        <p style={{ width: '25%', textAlign: 'end' }}>
                                                            -&#8377;{draftOrder.DpoPaymentDetails?.overallDiscountValue}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div style={{ marginBottom: '1rem', display: 'flex' }}>
                                            <p
                                                style={{ width: '25%' }}
                                                id='tax'
                                            >
                                                Tax
                                            </p>
                                            {draftOrder.DpoTaxLines ? (
                                                <p
                                                    id='taxRate'
                                                    style={{
                                                        color: '#0d6efd',
                                                        cursor: 'pointer',
                                                        width: '50%'
                                                    }}
                                                    onClick={handleTaxRates}
                                                >
                                                    Show tax rates
                                                </p>
                                            ) : (
                                                <p style={{ width: '50%' }}>
                                                </p>
                                            )}


                                            {draftOrder && (
                                                <p
                                                    id='taxRateAmount'
                                                    style={{
                                                        textAlign: 'end',
                                                        color: '#000',
                                                        width: '25%'
                                                    }}
                                                >
                                                    &#8377;{draftOrder?.DpoPaymentDetails?.totalTaxAmount
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{ fontSize: '14px', fontWeight: '600' }}
                                    >
                                        <p>Total</p>
                                        <div>
                                            {draftOrder ? (
                                                <p>
                                                    &#8377;{Math.round(
                                                        draftOrder.DpoPaymentDetails?.totalAmount
                                                    )}
                                                </p>
                                            ) : (
                                                <p>&#8377;0.00</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={Classes.containerFooter}
                                id='removeCreateOrderPrint'
                            >
                                {draftOrder && (
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            {(draftOrder?.DpoLocationDetails && draftOrder?.DpoLocationDetails?.length !== 0) && (draftOrder?.DpoVendorDetails && draftOrder?.DpoVendorDetails?.length !== 0) && (
                                                <div>
                                                    {draftOrder.status === "Draft" && (
                                                        <div>
                                                            <Permission service={services?.draftPurchaseOrders} permission="update">
                                                                <button
                                                                    className={[
                                                                        Classes.Btn,
                                                                        Classes.completeOrderButton
                                                                    ].join(' ')}
                                                                    id='completeOrder'
                                                                    onClick={(e) => handleSendForApprovalOrder(e)}
                                                                >
                                                                    Send for Approval
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    )}
                                                    {draftOrder.status === "Pending Approval" && (
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <div>
                                                                <Permission service={services?.purchaseOrders} permission="create">
                                                                    <button
                                                                        className={[
                                                                            Classes.Btn,
                                                                            Classes.completeOrderButton
                                                                        ].join(' ')}
                                                                        id='completeOrder'
                                                                        onClick={(e) => handleCompleteOrder(e)}
                                                                    >
                                                                        Complete Order
                                                                    </button>
                                                                </Permission>
                                                                <Permission service={services?.draftPurchaseOrders} permission="update">
                                                                    <button
                                                                        style={{ marginLeft: '10px' }}
                                                                        className={[
                                                                            Classes.Btn,
                                                                            Classes.waitingOrderButton
                                                                        ].join(' ')}
                                                                        id='completeOrder'
                                                                        onClick={(e) => handleRevertOrder(e)}
                                                                    >
                                                                        Revoke Approval
                                                                    </button>
                                                                </Permission>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            )}

                                            {draftOrder.status !== "Completed" && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <div>
                                                        <Permission service={services?.draftPurchaseOrders} permission="delete">
                                                            <button
                                                                type='btn'
                                                                className={[
                                                                    Classes.deleteOrderButton
                                                                ].join(' ')}
                                                                onClick={(e) => handleShowDeleteOrderPopup(e)}
                                                            >
                                                                Delete Order
                                                            </button>
                                                        </Permission>
                                                    </div>

                                                    {
                                                        draftOrder.status !== "Pending Approval" &&
                                                        <div>
                                                            <Permission service={services?.draftPurchaseOrders} permission="update">
                                                                <button
                                                                    type='btn'
                                                                    className={[
                                                                        Classes.editOrderButton
                                                                    ].join(' ')}
                                                                    onClick={() => navigate(
                                                                        `/createPurchaseOrder/${completeExistOrder}`)
                                                                    }
                                                                >
                                                                    Edit Draft
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    }
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {draftOrder.length !== 0 && (
                            <div id='TaxRatesPrint'>
                                {draftOrder.DpoTaxLines && (
                                    <div className={showTaxRate ? Classes.UpdateSalesDialog : undefined}>
                                        <TaxRatesPopup
                                            showTaxRate={showTaxRate}
                                            setTaxRates={setTaxRates}
                                            handleTaxRates={handleTaxRates}
                                            hideTaxPopup={hideTaxPopup}
                                            draftOrder={draftOrder}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div
                        className={Classes.asideContainer}
                        id='DraftPOAsideContainer'
                    >
                        <Aside
                            draftOrder={draftOrder}
                            deliveryRemark={deliveryRemark}
                            notes={notes}
                        />
                    </div>
                    <div className={showDeleteConfirmPopup ? Classes.UpdateSalesDialog : undefined}>
                        <DeleteOrderConfirmPopup
                            showDeleteConfirmPopup={showDeleteConfirmPopup}
                            hideDeleteOrderConfirmPopup={hideDeleteOrderConfirmPopup}
                            draftOrderId={draftOrder?.length !== 0 && draftOrder?.draftPurchaseOrderName}
                        />
                    </div>
                </div>
            </div>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
};

export default DraftPurchaseOrderDetail;