import { Autocomplete, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import SalesAnalytics from "./salesAnalytics";
import axiosInstance from "../../../utility/axios-instance";
import SnackBar from "../../../components/SnackBar";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import Classes from './css/dashboard.module.css'
import FulfillmentAnalytics from "./fulfillmentAnalytics";

const Analytics = () => {
    const [alignment, setAlignment] = useState('sales');
    const [locations, setLocations] = useState([]);
    const [locCode, setLocCode] = useState('');
    const [dayRange , setDayRange ] = useState('15 days')
    const allDaysRange = ['7 days', '15 days', '30 days'];

    const getFormattedDate = (date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    const [startDate, setStartDate] = useState(() => 
        getFormattedDate(new Date(Date.now() - 15 * 24 * 60 * 60 * 1000))
    );// set last 15 days date by default
    const [endDate, setEndDate] = useState(() => getFormattedDate(new Date()));
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const handleChange = (event) => {
        setAlignment(event.target.value)
    }

    const changeDaysToLastDate = (dayRange) => {
        const currentDate = Date.now();
        let startDateRange;
        if( dayRange === "7 days"){
            startDateRange = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
        }
        else if( dayRange === "15 days"){
            startDateRange = new Date(currentDate - 15 * 24 * 60 * 60 * 1000);
        }
        else if( dayRange === "30 days"){
            startDateRange = new Date(currentDate - 30 * 24 * 60 * 60 * 1000);
        }
        setStartDate(getFormattedDate(startDateRange))
        setEndDate(getFormattedDate(new Date()))
    }

    useEffect(() => {
        changeDaysToLastDate(dayRange);
        console.log("Alignment : ",alignment);
    },[dayRange])

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations?text=${text ? text :''}`)
            .then((res) => {
                const locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                }
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchLocation();
    },[])

    function DateRange () {
        return (
            <div className={Classes.DatePickerContainer} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Start Date" 
                        sx={{ 
                                "& .MuiOutlinedInput-input": {padding: "9.5px 8px",width: '105px',fontSize:'14px'},
                                "& .MuiSvgIcon-root": { fontSize:"18px" },
                                "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                                "& .Mui-error": { color:'#00000099 !important' }
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        onChange={(newValue) => {
                            setStartDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                    <DatePicker 
                        label="End Date"
                        sx={{ 
                            "& .MuiOutlinedInput-input": {padding: "9.5px 8px",width: '105px',fontSize:'14px'},
                            "& .MuiSvgIcon-root": { fontSize:"18px" },
                            "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                            "& .Mui-error": { color:'#00000099 !important' }
                        }}
                        inputFormat="YYYY-MM-DD"
                        size="small"
                        format="YYYY-MM-DD"
                        defaultValue={dayjs(endDate)}
                        value={dayjs(endDate)}
                        onChange={(newValue) => {
                            setEndDate(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`);
                        }} 
                    />
                </LocalizationProvider>
            </div>
        )
    }
    return (
        <>  
            <div className={Classes.DashboardContainer}>
                {/* Header  */}
                <div className={Classes.DashboardHeader} >
                    {/* Tabs */}
                    <div style={{ backgroundColor: '#9e9e9e1f', borderRadius: 4, padding: 4 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            sx={{
                                "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                                "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                            }}
                        >
                                <ToggleButton value="sales">Sales</ToggleButton>
                                <ToggleButton value="fulfillments">Fulfillments</ToggleButton>
                                {/* <ToggleButton value="inventory">Inventory</ToggleButton>
                                <ToggleButton value="return">Return</ToggleButton> */}
                        </ToggleButtonGroup>
                    </div>

                    <div className={Classes.DashboardTabDropDown}>
                        <div>
                            <Autocomplete
                                size='small'
                                disablePortal
                                id="locationCode"
                                name="locationCode"
                                options={locations}
                                renderInput={(params) => <TextField 
                                    {...params} label="Location Code" required={true}
                                    onBlur={(event) => {setLocCode(event.target.value)}}
                                />}
                            />
                        </div>
                        <div>
                            <Autocomplete
                                disablePortal
                                size='small'
                                id="daysRange"
                                name="daysRange"
                                defaultValue='15 days'
                                options={allDaysRange}
                                renderInput={(params) => <TextField 
                                    {...params} label='Last days' required={true}
                                    onBlur={(event) => {setDayRange(event.target.value)}}
                                />}
                            />
                        </div>
                    </div>
                </div>
                <DateRange />
                
                {/* Main Component */}
                {
                    alignment === 'sales' && (
                        <SalesAnalytics
                            locCode={locCode}
                            dayRange={dayRange}
                            startDate={startDate}
                            endDate={endDate}
                            setSnackBar={setSnackBar}
                        />
                    )
                }
                {
                    alignment === 'fulfillments' && (
                        <FulfillmentAnalytics 
                            locCode={locCode}
                            dayRange={dayRange}
                            startDate={startDate}
                            endDate={endDate}
                            setSnackBar={setSnackBar}
                        />
                    )
                }

                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            </div>
        </>
    )
}

export default Analytics;