import { Fragment, useState, useEffect } from 'react';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import ConfirmPopup from '../customers/confirmPopup';
import NewPagination from '../../../components/newPagination';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Search from '../../../components/newSearch';
import Button from '@mui/material/Button';
import VisitCustomerModal from './selectVisitCustomerModal';
import EndVisitModal from './endVisitModal';
import CancelIcon from '@mui/icons-material/Cancel';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CancelMeetingModal from './cancelMeetingModal';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import SalesUsers from './salesUsers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadCSV } from "../../../utility/downloadCSV";
import Spinner from '../../../components/spinner';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment';
import { setMyTasks } from '../../../store/myTasksSlice';

const FieldSales = (props) => {
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [storeCustomerId, setCustomerId] = useState([]);
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);
    const [gstNO, setGstNO] = useState();
    const [num,setNum] = useState('');
	const [fetchCustomerRoleDB,setCustomerRoleDB] = useState([]);
    const [errorMessage,setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [salesUser,setSalesUser] = useState('');
    const [customerInfo, setCustomerInfo] = useState([]);
    const [preSalesUser,setPreSalesUser] = useState('');
    const [modeOfCommunication,setModeOfCommunication] = useState('');
    const [email,setEmail] = useState('');
    const [showVisitCustomerModal,setVisitCustomerModal] = useState(false);

    // endVisit Modal
    const [endVisitModal,setEndVisitModal] = useState(false);
    const [selectedRatings,setSelectedRatings] = useState('');
    const [visitRemarks,setVisitRemarks] = useState('');
    const [endVisitedCustomer,setEndVisitedCustomer] = useState('');

    // CancelMeeting Modal
    const [cancelMeetingModal,setCancelMeetingModal] = useState(false);
    const [cancelVisitReason,setCancelVisitReason] = useState('');
    const [cancelVisitCustomer,setCancelVisitCustomer] = useState('');

    const [previousVisitCustomer,setPreviousVisitCustomer] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const auth = useSelector((state) => state.auth);
    const myTasks = useSelector((state) => state.myTasks);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();
    // salesUser
    const [user,setUser] = useState('');

    const visitCustomerModal = () => {
        setVisitCustomerModal(true);
    };

    const hideVisitCustomerModal = () => {
        customerInfo.splice(0, customerInfo.length);
        setSalesUser('');
        setModeOfCommunication('');
        setVisitCustomerModal(false);
    };

    const getEndVisitModal = (customer) => {
        setEndVisitedCustomer(customer);
        setEndVisitModal(true);
    }

    const hideEndVisitModal = () => {
        setSelectedRatings('');
        setEndVisitModal(false);
    }

    const getCancelMeetingModal = (event,customer) => {
        setCancelVisitCustomer(customer);
        setCancelMeetingModal(true);
    }

    const hideCancelMeetingModal = () => {
        // setSelectedRatings('');
        setCancelMeetingModal(false);
    }
    
    const years = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const dn = new Date();

    const createdAt = (createdAt) => {
        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

    const fetchCustomersWithCreatedBy = async (user) => {
        const page = 1;

		await axiosInstance
			.get(`/fieldSalesVisit?page=${page}&text=${searchCustomer? searchCustomer: ''}&createdBy=${user}`)
			.then((res) => {
				dispatch(setMyTasks(res.data.data));
			});
	};

    const handleSearchCustomer = (event) => {
        setSearchCustomer(event.target.value);
        fetchCustomers(1,event.target.value);
    }

    const fetchCustomers = async (type,searchCustomer) => {
        const page = type;

		await axiosInstance
			.get(`/fieldSalesVisit?page=${page}&text=${searchCustomer? searchCustomer: ''}&createdBy=${user}`)
			.then((res) => {
				dispatch(setMyTasks(res.data.data));
			});
	};

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'My Tasks')) {
            axiosInstance
                .get('/user/admin/customerRoles')
                .then((res) => {
                    setCustomerRoleDB([...res.data.data])
                })

            // fetch draftOrders
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
            fetchCustomers(page? page : 1);
        }
    }, []);

    const handleDeleteCustomer = () => {
        axiosInstance
            .post('/user/admin/deleteCustomersInBulk/', {
                customersIds: storeCustomerId
            })
            .then((res) => {
                const arr = []
                setCustomerId(arr);
                document.getElementById('deleteBtn').style.backgroundColor = 'transparent';
                document.getElementById('deleteBtn').style.color = '';
                document.getElementById('parentBox').checked = false;
                fetchCustomers();
            })
    }

    const hideConfirmPopup = (event) => {
        if (event.target.innerText === 'Yes') {
            if (storeCustomerId.length !== 0) {
                handleDeleteCustomer();
            }
        }
        setConfirmShowPopup(false)
    }

    document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('customerSearchOutline');
        if (container !== null) {
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
            padding:"16px 10px"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            padding:"16px 10px"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const capitalizeState = (text) => {
        if (text) {
            const arr = text.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2
        }
    }

    const handleStartVisit = async (customer,user) => {
        await axiosInstance
            .get(`/fieldSalesVisit/previousVisitCompleted/${user}`)
            .then((res) => {
                if (res?.data?.data && res?.data?.data?.length === 0) {
                    axiosInstance
                        .post(`/fieldSalesVisit/updateFieldVisit/${customer}`,{
                            startTime: Date.now()
                        })
                        .then((res) => {
                            const urlParams = new URLSearchParams(window.location.search);
                            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
                            fetchCustomers(page? page : 1);
                        });
                } else {
                    setError(true);
                    setPreviousVisitCustomer(res.data.data[0]?.user?.displayName);
                    setTimeout(() => {
                        setPreviousVisitCustomer('');
                        setError(false);
                    }, 5000);
                }
            })
    }

    
    const handleEndVisit = async (customer) => {
        await axiosInstance
            .post(`/fieldSalesVisit/updateFieldVisit/${customer}`,{
                endTime: Date.now()
            })
            .then((res) => {
                const urlParams = new URLSearchParams(window.location.search);
                const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
                fetchCustomers(page? page : 1);
            });
    }

    const handleExport = (event) => {
        setLoading(true);
        event.preventDefault();

        axiosInstance
            .get('/exports/myTasks')
            .then((result) => {
                setLoading(false);
                const arrayOfRowObjects = result?.data?.data;
                downloadCSV(arrayOfRowObjects, `Tasks export - ${new Date()}`);
                const successMessage = "Tasks Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>
                

                <div className="d-flex mb-2" style={{justifyContent:'flex-end',marginTop:-27}}>
                    <div style={{ display:'flex',marginRight:10 }}>
                        <Button
                            id='exportbtn'
                            variant="contained"
                            style={{backgroundColor:'#26b278'}}
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExport}
                        >
                            Export
                        </Button>
                    </div>

                    {(auth?.user?.role === 'super-admin' || auth?.user?.role === 'admin' || auth?.user?.role === 'salesManager') && (
                        <Button 
                            variant="contained" 
                            startIcon={<AddCircleIcon />} 
                            sx={{textTransform: "capitalize"}}
                            onClick={visitCustomerModal}
                        >
                            Add Task
                        </Button>
                    )}
                </div>

                {error && (
                    <Alert severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        Please complete previous visit — <strong>{previousVisitCustomer}!</strong>
                    </Alert>
                )}

                <div className={Classes.Container}>        
                    <div className={Classes.HeaderBox} style={{paddingTop:20}}>
                        <div 
                            style={{width:  (auth?.user?.role === 'super-admin' || 
                                    auth?.user?.role === 'admin' || 
                                    auth?.user?.role === 'salesManager') ? '75%' :'100%'
                            }}
                        >
                            <Search  
                                text={searchCustomer}
                                fetchData={handleSearchCustomer}
                            />
                        </div>

                        {(auth?.user?.role === 'super-admin' || auth?.user?.role === 'admin' || auth?.user?.role === 'salesManager') && (
                            <div style={{width:'24%'}}>
                                <SalesUsers 
                                    fetchData={fetchCustomersWithCreatedBy}
                                    setUser={setUser}
                                />
                            </div>
                        )}
					</div>

                    <TableContainer component={Paper} className={Classes.TaskHideTable}>
                        <Table sx={{ minWidth:'unset' }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{width:'20%'}}>Customer Name</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell>Communication</StyledTableCell>
                                    <StyledTableCell>Sales User</StyledTableCell>
                                    <StyledTableCell>Mark Start</StyledTableCell>
                                    <StyledTableCell>Mark End</StyledTableCell>
                                    <StyledTableCell>Duration</StyledTableCell>
                                    <StyledTableCell sx={{textAlign:'center'}}>Meeting</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {myTasks?.fieldVisit?.length !== 0 && myTasks?.fieldVisit?.map((customer) => (
                                    <StyledTableRow key={customer?._id}>
                                        <StyledTableCell component="th" scope="row">
                                            {customer?.user?.displayName ? customer?.user?.displayName :
                                            `${customer?.user?.firstName} ${customer?.user?.lastName && customer?.user?.lastName}`}
                                        </StyledTableCell>
                                        <StyledTableCell>{customer?.user?.phone}</StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.user?.customerRole ? capitalizeState(customer?.user?.customerRole): 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.modeOfCommunication ? customer?.modeOfCommunication : "N/A"}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {customer?.fieldSalesUser ? customer?.fieldSalesUser?.name : "N/A"}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {customer?.startTime ? moment(customer?.startTime).format('MMM DD, YYYY h:mm:ss A') : (
                                                !customer?.cancellationReason ? (
                                                    <Button 
                                                        variant="contained" 
                                                        sx={{textTransform: "capitalize",padding:'4px 12px',fontSize:'13px'}} 
                                                        onClick={() => handleStartVisit(customer?._id,customer?.user?._id)}
                                                    >
                                                        {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit'?  
                                                        "Start Visit" : "Start Call"}
                                                    </Button>
                                                ) : (
                                                    "N/A"
                                                )
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.endTime ? moment(customer?.endTime).format('MMM DD, YYYY, h:mm:ss A') :  customer?.startTime ? 
                                                <Button 
                                                    variant="contained" 
                                                    sx={{textTransform: "capitalize",padding:'4px 12px',fontSize:'13px'}}
                                                    onClick={()=>  getEndVisitModal(customer?._id)}
                                                >
                                                    {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit'?  
                                                    "End Visit" : "End Call"}
                                                </Button> : "N/A"
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.duration ? customer?.duration : "N/A" }
                                        </StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'center'}}>
                                            {customer?.startTime && !customer?.endTime ? (
                                                <PersonPinIcon sx={{color:'orange',fontSize:28}}/> 
                                            ): customer?.endTime ? (
                                                    <HowToRegIcon sx={{color:'green',fontSize:28}}/> 
                                                ) : (
                                                    customer?.cancellationReason ? (
                                                        <p className={Classes.meetingCancelled}>Cancelled</p>
                                                    ) : (
                                                        <div>
                                                            <CancelIcon 
                                                                sx={{color:'#F34336',cursor:'pointer',fontSize:22}} 
                                                                onClick={(event) => getCancelMeetingModal(event,customer?._id)}
                                                            />
                                                        </div>
                                                    )
                                            )}    
                                                                                                
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className={Classes.TaskResponsiveTable}>
                        {myTasks?.fieldVisit?.length !== 0 && myTasks?.fieldVisit?.map((customer,index) => (
                            <div 
                                key={customer?._id}   
                                // onClick={() => {navigate(`${customer?._id}`)}}
                                className={Classes.responsiveTableBox}
                                style={{marginTop: index === 0 ? 0 : 15}}
                            >
                                <div style={{display:'flex'}}>
                                    <AccountCircleIcon sx={{marginRight:'5px'}}/>
                                    <p style={{fontWeight:600}}>
                                        {customer?.user?.displayName ? customer?.user?.displayName :
                                        `${customer?.user?.firstName} ${customer?.user?.lastName && customer?.user?.lastName}`}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Phone:</p>
                                    <p>{customer?.user?.phone}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Role:</p>
                                    <p style={{textTransform:'capitalize'}}>
                                        {customer?.user?.customerRole ? capitalizeState(customer?.user?.customerRole): 'N/A'}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Communication:</p>
                                    <p>{customer?.modeOfCommunication ? customer?.modeOfCommunication : "N/A"}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Mark Start:</p>
                                    <p>
                                        {customer?.startTime ? createdAt(customer?.startTime) : (
                                            !customer?.cancellationReason ? (
                                                <Button 
                                                    variant="contained" 
                                                    sx={{textTransform: "capitalize",padding:'4px 10px',fontSize:'13px'}} 
                                                    onClick={() => handleStartVisit(customer?._id,customer?.user?._id)}
                                                >
                                                    {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit'?  
                                                    "Start Visit" : "Start Call"}
                                                </Button>
                                            ) : (
                                                "N/A"
                                            )
                                        )}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Mark End:</p>
                                        <p style={{textAlign:'center'}}>
                                            {customer?.endTime ? createdAt(customer?.endTime) :  customer?.startTime ? 
                                                <Button 
                                                    variant="contained" 
                                                    sx={{textTransform: "capitalize",padding:'4px 10px',fontSize:'13px'}}
                                                    onClick={()=>  getEndVisitModal(customer?._id)}
                                                >
                                                    {customer?.modeOfCommunication && customer?.modeOfCommunication === 'Field Visit'?  
                                                        "End Visit" : "End Call"}
                                                </Button> : "N/A"
                                            }
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Duration:</p>
                                    <p>{customer?.duration ? customer?.duration : "N/A" }</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Meeting:</p>
                                    <p>
                                        {customer?.startTime && !customer?.endTime ? (
                                            <PersonPinIcon sx={{color:'orange',fontSize:30}}/> 
                                        ): customer?.endTime ? (
                                                <HowToRegIcon sx={{color:'green'}} className={Classes.MeetingDone}/> 
                                            ) : (
                                                customer?.cancellationReason ? (
                                                    <p className={Classes.meetingCancelled}>Cancelled</p>
                                                ) : (
                                                    <div>
                                                        <CancelIcon 
                                                            sx={{color:'#F34336',cursor:'pointer'}} 
                                                            onClick={(event) => getCancelMeetingModal(event,customer?._id)}
                                                        />
                                                    </div>
                                                )
                                        )}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                        <NewPagination totalPage={myTasks} fetchData={fetchCustomers} />
                    </div>

                    <div>
                        <ConfirmPopup
                            showConfirmPopup={showConfirmPopup}
                            hideConfirmPopup={hideConfirmPopup}
                            storeCustomerId={storeCustomerId}
                        />
                    </div>
                </div>
            </div>

            <div className={endVisitModal ? Classes.UpdateSalesDialog : undefined}>
                <EndVisitModal 
                    endVisitModal={endVisitModal}
                    hideEndVisitModal={hideEndVisitModal}
                    handleEndVisit={handleEndVisit}
                    selectedRatings={selectedRatings}
                    setSelectedRatings={setSelectedRatings}
                    visitRemarks={visitRemarks}
                    setVisitRemarks={setVisitRemarks}
                    endVisitedCustomer={endVisitedCustomer}
                    fetchCustomers={fetchCustomers}
                />
            </div>

            <div className={cancelMeetingModal ? Classes.UpdateSalesDialog : undefined}>
                <CancelMeetingModal 
                    cancelMeetingModal={cancelMeetingModal}
                    hideCancelMeetingModal={hideCancelMeetingModal}
                    setCancelVisitReason={setCancelVisitReason}
                    cancelVisitReason={cancelVisitReason}
                    fetchCustomers={fetchCustomers}
                    cancelVisitCustomer={cancelVisitCustomer}
                />
            </div>

            <div className={showVisitCustomerModal ? Classes.UpdateSalesDialog : undefined}>
                <VisitCustomerModal
                    onShowModal={showVisitCustomerModal}
                    setModal={setVisitCustomerModal}
                    onHideModal={hideVisitCustomerModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    fetchCustomerRoleDB={fetchCustomerRoleDB}
                    fetchCustomers={fetchCustomers}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    preSalesUser={preSalesUser}
                    setPreSalesUser={setPreSalesUser}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    area={area}
                    setArea={setArea}
                    city={city}
                    setCity={setCity}
                    email={email}
                    setEmail={setEmail}
                    modeOfCommunication={modeOfCommunication}
                    setModeOfCommunication={setModeOfCommunication}
                    popUpType='customerDashboard'
                    endpoint={`/user/admin/affiliates`}
                />
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            {loading && <Spinner />}
        </Fragment>
    );
};

export default FieldSales;
