import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Classes from "./scss/createCommission.module.scss";
import { TextField, Autocomplete } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Axios from "axios";
import fileDownload from "js-file-download";

import axiosInstance from "../../../utility/axios-instance";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

library.add(fas, faMagnifyingGlass);

const years = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const CreateCommission = (props) => {
  const [snackBar, setSnackBar] = React.useState({
    display: false,
    type: "success",
    message: "default message!",
  });
  const navigate = useNavigate();

  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const panCardRef = useRef();
  const proofRef = useRef();
  const timer = useRef();
  const timer2 = useRef();
  const timer3 = useRef();
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: 1,
    total: 0,
  });
  const [loading2, setLoading2] = useState(false);
  const [affiliates, setAffiliates] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pagination2, setPagination2] = useState({
    page: 1,
    maxPage: 1,
    total: 0,
  });
  const [loading3, setLoading3] = useState(false);
  const [admins, setAdmins] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pagination3, setPagination3] = useState({
    page: 1,
    maxPage: 1,
    total: 0,
  });

  const [order, setOrder] = useState(null);
  const [affiliate, setAffiliate] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [commissionAmount, setCommissionAmount] = useState(null);
  const [paymentReceived, setPaymentReceived] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState(null);
  const [ifscCode, setIFSCCode] = useState(null);
  const [transferred, setTransferred] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [proof, setProof] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCloseModal = () => {
    setText("");
    setShowModal(false);
  };

  const handleCloseModal2 = () => {
    setText2("");
    setShowModal2(false);
  };

  const handleCloseModal3 = () => {
    setText3("");
    setShowModal3(false);
  };

  const handleCreatedAt = (createdAt) => {
    const dn = new Date();
    let ca = createdAt
      ? createdAt.getFullYear() === dn.getFullYear()
        ? createdAt.getMonth() === dn.getMonth()
          ? createdAt.getDate() === dn.getDate()
            ? `Today at ${createdAt.toLocaleTimeString()}`
            : createdAt.getDate() === dn.getDate() - 1
            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
            : years[createdAt.getMonth()] +
              " " +
              createdAt.getDate() +
              " at " +
              createdAt.toLocaleTimeString()
          : years[createdAt.getMonth()] +
            " " +
            createdAt.getDate() +
            " at " +
            createdAt.toLocaleTimeString()
        : years[createdAt.getMonth()] + " " + createdAt.getFullYear()
      : null;
    return ca;
  };

  const fetchOrders = (page = 1, searchText = "", pagination = false) => {
    setLoading(true);

    axiosInstance
      .get(`/pgOrder/admin/orders?page=${page}&searchText=${searchText}`)
      .then((res) => {
        // console.log(res.data.data);
        if (pagination) {
          setOrders((prev) => [...prev, ...res.data.data.orders]);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        } else {
          setOrders(res.data.data.orders);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAffiliates = (page = 1, searchText = "", pagination = false) => {
    setLoading2(true);

    axiosInstance
      .get(`/others/getAffiliate?page=${page}&text=${searchText}`)
      .then((res) => {
        // console.log(res.data.data);
        if (pagination) {
          setAffiliates((prev) => [...prev, ...res.data.data.affiliateData]);
          setPagination2({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        } else {
          setAffiliates(res.data.data.affiliateData);
          setPagination2({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        }
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const fetchAdmins = (page = 1, searchText = "", pagination = false) => {
    setLoading3(true);

    axiosInstance
      .get(`/admin?page=${page}&text=${searchText}`)
      .then((res) => {
        // console.log(res.data.data);
        if (pagination) {
          setAdmins((prev) => [...prev, ...res.data.data.users]);
          setPagination3({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        } else {
          setAdmins(res.data.data.users);
          setPagination2({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        }
      })
      .finally(() => {
        setLoading3(false);
      });
  };

  useEffect(() => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(() => {
      fetchOrders(1, text);
    }, 100);
  }, [text]);

  useEffect(() => {
    clearTimeout(timer2?.current);
    timer2.current = setTimeout(() => {
      fetchAffiliates(1, text2);
    }, 100);
  }, [text2]);

  useEffect(() => {
    clearTimeout(timer3?.current);
    timer3.current = setTimeout(() => {
      fetchAdmins(1, text3);
    }, 100);
  }, [text3]);

  const submit = async () => {
    setSubmitLoading(true);

    const formData = new FormData();

    const data = {
      order_id: order.orderId,
      affiliate: affiliate._id,
      commission_amount: +commissionAmount,
      payment_received: paymentReceived,
      bank_account_number: bankAccountNumber,
      ifsc_code: ifscCode,
      transferred: transferred,
    };

    if (admin) {
      data.approved_by = admin._id;
    }

    // formData.append('order_id', order.orderId);
    // formData.append('affiliate', affiliate._id);
    // formData.append('approved_by', admin._id);
    // formData.append('commission_amount', +(commissionAmount));
    // formData.append('payment_received', paymentReceived);
    // formData.append('bank_account_number', bankAccountNumber);
    // formData.append('ifsc_code', ifscCode);
    // formData.append('transferred', transferred);
    // console.log(JSON.stringify(data));
    formData.append("data", JSON.stringify(data));
    formData.append("panCard", panCard);
    formData.append("proof", proof);

    //console.log(formData.get("data"));

    axiosInstance
      .post("/others/createCommission", formData)
      .then((res) => {
        handleSuccess(res.data.message);
        navigate(-1);
      })
      .catch((err) => {
        handleError(err.response.data.message);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleSuccess = (msg) => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: msg, type: "success" };
    });
  };
  const handleError = (msg) => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: true, message: msg, type: "error" };
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false };
    });
  };

  const getAffiliate = async () => {
    axiosInstance
      .get(`/others/getAffiliates/${affiliate._id}`)
      .then((res) => {
        const data = res.data.data;
        setAffiliate(data);
        Axios.get(data.panCardFile, {
          responseType: "blob",
        }).then((res) => {
          fileDownload(res.data, affiliate.panCard.split("/")[1]);
        });
      })
      .catch((err) => {
        handleError(err.response.data.message);
      });
  };

  const getOrder = async () => {
    // console.log(order);
    if (!order) {
      return;
    }
    let o;
    await axiosInstance
      .get(`/pgOrder/admin/order/${order.orderName}`)
      .then((res) => {
        o = res.data.data;
      })
      .catch((err) => {});

    if (o && o.OAffiliateUser) {
      axiosInstance
        .get(`/others/getAffiliates/${o.OAffiliateUser.mongoDbId}`)
        .then((res) => {
          setAffiliate(res.data.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (affiliate) {
      setBankAccountNumber(affiliate.bankAccountNumber ?? "");
      setIFSCCode(affiliate.ifscCode ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  return (
    <div className={Classes.CreateCommission}>
      <Snackbar
        open={snackBar?.display}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar?.type}
          sx={{ width: "100%" }}
        >
          {snackBar?.message}
        </Alert>
      </Snackbar>
      <div className={Classes.Flex}>
        <div
          style={{
            flexGrow: "1",
          }}
        >
          {order ? (
            <div className={[Classes.Box].join(" ")}>
              <h5>ORDER</h5>
              <div className='d-flex align-items-center justify-content-between'>
                <div
                  className='d-flex align-items-center'
                  style={{ gap: "1rem" }}
                >
                  <h6 className='mb-0'>{order.orderName}</h6>
                  <p
                    className='bg-light rounded px-2 py-1'
                    style={{
                      color: order.cancelledAt ? "red" : "green",
                      fontSize: "14px",
                    }}
                  >
                    {order.status}
                  </p>
                  <h6 className='mb-0'>{order.orderType}</h6>
                </div>

                <p
                  className=''
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {handleCreatedAt(new Date(order.createdAt))}
                </p>
              </div>

              <div className='mt-2' style={{ fontSize: "14px" }}>
                <p>{order.customerFullName}</p>
                <p>{order.phoneNumber}</p>
                <p className='mt-1'>
                  <strong>₹{order.total}</strong>
                </p>
              </div>
            </div>
          ) : null}

          {affiliate ? (
            <div className={[Classes.Box].join(" ")}>
              <h5>AFFILIATE</h5>
              <div className='d-flex align-items-center justify-content-between'>
                <div
                  className='d-flex align-items-center'
                  style={{ gap: "1rem" }}
                >
                  <h6 className='mb-0'>{affiliate.displayName}</h6>
                </div>
              </div>

              <div className='mt-2' style={{ fontSize: "14px" }}>
                <p>{affiliate.email}</p>
                <p>{affiliate.phone}</p>
              </div>

              <div className={[Classes.Box2, "mt-4"].join(" ")}>
                <div style={{ width: "28%" }}>
                  <TextField
                    required
                    id='bankAccountNumber'
                    name='bankAccountNumber'
                    type='text'
                    label='Bank Account Number'
                    // maxLength={10}
                    onChange={(e) => {
                      setBankAccountNumber(e.target.value);
                    }}
                    // onBlur={(e) => handleMinLength(e)}
                    placeholder='Enter Bank Account Number'
                    value={bankAccountNumber ? bankAccountNumber : ""}
                    autoComplete='off'
                    sx={{
                      width: "100%",
                      "& label": {
                        top: bankAccountNumber ? "0%" : "-16%",
                        fontSize: 14,
                      },
                      "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                    }}
                  />
                </div>

                <div style={{ width: "28%" }}>
                  <TextField
                    required
                    id='ifscCode'
                    name='ifscCode'
                    type='text'
                    label='IFSC Code'
                    // maxLength={10}
                    onChange={(e) => {
                      setIFSCCode(e.target.value);
                    }}
                    // onBlur={(e) => handleMinLength(e)}
                    placeholder='Enter IFSC Code'
                    value={ifscCode ? ifscCode : ""}
                    autoComplete='off'
                    sx={{
                      width: "100%",
                      "& label": {
                        top: ifscCode ? "0%" : "-16%",
                        fontSize: 14,
                      },
                      "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                    }}
                  />
                </div>

                <div style={{ width: "28%" }}>
                  <div
                    className='border border-dark-subtle text-dark-subtle rounded px-4 py-2 d-flex justify-content-between align-items-center'
                    style={{
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onClick={() => {
                      panCardRef.current.click();
                    }}
                  >
                    <input
                      ref={panCardRef}
                      type='file'
                      accept='.pdf,.jpg,.png,.jpeg'
                      required
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          setPanCard(e.target.files[0]);
                        }
                      }}
                      style={{
                        height: "0px",
                        width: "0px",
                        position: "absolute",
                      }}
                    />
                    {affiliate.panCard ? (
                      <div
                        style={{
                          gap: ".5rem",
                          cursor: "pointer",
                          fontSize: "14px !important",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p>{affiliate.panCard.split("/")[1]}</p>

                        <FontAwesomeIcon
                          icon='fa-solid fa-download'
                          onClick={(e) => {
                            e.stopPropagation();
                            getAffiliate();
                          }}
                        />
                      </div>
                    ) : (
                      <p>{panCard ? panCard.name : "Upload Pan Card"}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {admin ? (
            <div className={[Classes.Box].join(" ")}>
              <h5>Approved By</h5>
              <div className='d-flex align-items-center justify-content-between'>
                <div
                  className='d-flex align-items-center'
                  style={{ gap: "1rem" }}
                >
                  <h6 className='mb-0'>{admin.username}</h6>
                </div>
              </div>

              <div className='mt-2' style={{ fontSize: "14px" }}>
                <p>{admin.name}</p>
                <p>{admin.role}</p>
              </div>
            </div>
          ) : null}

          <div className={[Classes.Box, Classes.Box2].join(" ")}>
            <div style={{ width: "28%" }}>
              <TextField
                required
                id='commissionAmount'
                name='commissionAmount'
                type='number'
                label='Commission Amount'
                // maxLength={10}
                onChange={(e) => {
                  setCommissionAmount(e.target.value);
                }}
                // onBlur={(e) => handleMinLength(e)}
                placeholder='Enter Commission Amount'
                value={commissionAmount ? commissionAmount : ""}
                autoComplete='off'
                sx={{
                  width: "100%",
                  "& label": {
                    top: commissionAmount ? "0%" : "-16%",
                    fontSize: 14,
                  },
                  "& .MuiOutlinedInput-input": { padding: "7.5px 14px" },
                }}
              />
            </div>

            <div style={{ width: "28%" }}>
              <Autocomplete
                disablePortal
                required
                id='paymentReceived'
                name='paymentReceived'
                options={[{ label: "Yes" }, { label: "No" }]}
                sx={{
                  width: "100%",
                  "& label": {
                    top: paymentReceived ? "0%" : "-16%",
                    fontSize: 14,
                  },
                  "& .MuiInputBase-input": { height: "12px" },
                  "& .MuiOutlinedInput-root": { padding: "6px" },
                }}
                onChange={(event, newValue) => {
                  setPaymentReceived(newValue.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Payment Received' />
                )}
              />
            </div>
          </div>

          <div className={[Classes.Box, Classes.Box2].join(" ")}>
            <div style={{ width: "28%" }}>
              <Autocomplete
                disablePortal
                required
                id='transferred'
                name='transferred'
                options={[{ label: "Yes" }, { label: "No" }]}
                sx={{
                  width: "100%",
                  "& label": { top: transferred ? "0%" : "-16%", fontSize: 14 },
                  "& .MuiInputBase-input": { height: "12px" },
                  "& .MuiOutlinedInput-root": { padding: "6px" },
                }}
                onChange={(event, newValue) => {
                  setTransferred(newValue.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Transferred' />
                )}
              />
            </div>

            <div style={{ width: "28%" }}>
              <div
                className='border border-dark-subtle text-dark-subtle rounded px-4 py-2 d-flex justify-content-between align-items-center'
                style={{
                  cursor: "pointer",
                  position: "relative",
                  opacity: transferred !== "Yes" ? "0.5" : "1",
                }}
                onClick={() => {
                  proofRef.current.click();
                }}
              >
                <input
                  ref={proofRef}
                  type='file'
                  accept='.pdf,.jpg,.png,.jpeg'
                  required
                  disabled={transferred !== "Yes"}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setProof(e.target.files[0]);
                    }
                  }}
                  style={{
                    height: "0px",
                    width: "0px",
                    position: "absolute",
                  }}
                />
                <p>{proof ? proof.name : "Upload Proof"}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            minWidth: "300px",
          }}
        >
          <div className={[Classes.Order, Classes.Box].join(" ")}>
            <h6>Select Order</h6>

            <div
              className={Classes.OrderTextSearch}
              onClick={() => {
                inputRef?.current?.focus();
                setShowModal(true);
              }}
            >
              <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
              <div>
                <input
                  ref={inputRef}
                  type='text'
                  placeholder='Search Orders'
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={[Classes.Order, Classes.Box].join(" ")}>
            <h6>Select Affiliate</h6>

            <div
              className={Classes.OrderTextSearch}
              onClick={() => {
                inputRef3?.current?.focus();
                setShowModal2(true);
              }}
            >
              <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
              <div>
                <input
                  ref={inputRef3}
                  type='text'
                  placeholder='Search Orders'
                  value={text2}
                  onChange={(e) => {
                    setText2(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={[Classes.Order, Classes.Box].join(" ")}>
            <h6>Select Admin</h6>

            <div
              className={Classes.OrderTextSearch}
              onClick={() => {
                inputRef5?.current?.focus();
                setShowModal3(true);
              }}
            >
              <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
              <div>
                <input
                  ref={inputRef5}
                  type='text'
                  placeholder='Search Orders'
                  value={text3}
                  onChange={(e) => {
                    setText3(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type='button'
        onClick={() => {
          submit();
        }}
        className={Classes.Button}
        disabled={
          submitLoading ||
          !order ||
          !affiliate ||
          !commissionAmount ||
          !paymentReceived ||
          !bankAccountNumber ||
          !ifscCode ||
          !transferred ||
          (!affiliate?.panCard && !panCard) ||
          (transferred === "Yes" && !proof)
        }
      >
        {submitLoading ? "Creating ..." : "Create"}
      </button>

      {showModal && (
        <div className={Classes.Modal}>
          <div className={Classes.Head}>
            <h5>All Orders</h5>
            <FontAwesomeIcon
              icon='fa-solid fa-xmark'
              size='xl'
              style={{ cursor: "pointer" }}
              onClick={handleCloseModal}
            />
          </div>

          <div
            className={Classes.OrderTextSearch}
            onClick={() => {
              inputRef2?.current?.focus();
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
            <div>
              <input
                ref={inputRef2}
                type='text'
                placeholder='Search Orders'
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>

          <div
            className={Classes.Orders}
            style={{
              height: "calc(100% - 2rem - 32px - 36px)",
            }}
          >
            {orders.map((order) => {
              return (
                <div
                  key={order.orderId}
                  className='border-bottom px-4 py-2'
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOrder(order);
                    handleCloseModal();
                  }}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "1rem" }}
                    >
                      <h6 className='mb-0'>{order.orderName}</h6>
                      <p
                        className='bg-light rounded px-2 py-1'
                        style={{
                          color: order.cancelledAt ? "red" : "green",
                          fontSize: "14px",
                        }}
                      >
                        {order.status}
                      </p>
                      <h6 className='mb-0'>{order.orderType}</h6>
                    </div>

                    <p
                      className=''
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {handleCreatedAt(new Date(order.createdAt))}
                    </p>
                  </div>

                  <div className='mt-2' style={{ fontSize: "14px" }}>
                    <p>{order.customerFullName}</p>
                    <p>{order.phoneNumber}</p>
                    <p className='mt-1'>
                      <strong>₹{order.total}</strong>
                    </p>
                  </div>
                </div>
              );
            })}

            {loading && <p className='my-1'>Loading ...</p>}
          </div>
        </div>
      )}

      {showModal2 && (
        <div className={Classes.Modal}>
          <div className={Classes.Head}>
            <h5>All Affiliates</h5>
            <FontAwesomeIcon
              icon='fa-solid fa-xmark'
              size='xl'
              style={{ cursor: "pointer" }}
              onClick={handleCloseModal2}
            />
          </div>

          <div
            className={Classes.OrderTextSearch}
            onClick={() => {
              inputRef4?.current?.focus();
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
            <div>
              <input
                ref={inputRef4}
                type='text'
                placeholder='Search Affiliate'
                value={text2}
                onChange={(e) => {
                  setText2(e.target.value);
                }}
              />
            </div>
          </div>

          <div
            className={Classes.Orders}
            style={{
              height: "calc(100% - 2rem - 32px - 36px)",
            }}
          >
            {affiliates.map((aff) => {
              return (
                <div
                  key={aff._id}
                  className='border-bottom px-4 py-2'
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAffiliate(aff);
                    handleCloseModal2();
                  }}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "1rem" }}
                    >
                      <h6 className='mb-0'>{aff.displayName}</h6>
                    </div>
                  </div>

                  <div className='mt-2' style={{ fontSize: "14px" }}>
                    <p>{aff.email}</p>
                    <p>{aff.phone}</p>
                  </div>
                </div>
              );
            })}

            {loading2 && <p className='my-1'>Loading ...</p>}
          </div>
        </div>
      )}

      {showModal3 && (
        <div className={Classes.Modal}>
          <div className={Classes.Head}>
            <h5>All Admins</h5>
            <FontAwesomeIcon
              icon='fa-solid fa-xmark'
              size='xl'
              style={{ cursor: "pointer" }}
              onClick={handleCloseModal3}
            />
          </div>

          <div
            className={Classes.OrderTextSearch}
            onClick={() => {
              inputRef6?.current?.focus();
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
            <div>
              <input
                ref={inputRef6}
                type='text'
                placeholder='Search Affiliate'
                value={text3}
                onChange={(e) => {
                  setText3(e.target.value);
                }}
              />
            </div>
          </div>

          <div
            className={Classes.Orders}
            style={{
              height: "calc(100% - 2rem - 32px - 36px)",
            }}
          >
            {admins.map((adm) => {
              return (
                <div
                  key={adm._id}
                  className='border-bottom px-4 py-2'
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAdmin(adm);
                    handleCloseModal3();
                  }}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "1rem" }}
                    >
                      <h6 className='mb-0'>{adm.username}</h6>
                    </div>
                  </div>

                  <div className='mt-2' style={{ fontSize: "14px" }}>
                    <p>{adm.name}</p>
                    <p>{adm.role}</p>
                  </div>
                </div>
              );
            })}

            {loading3 && <p className='my-1'>Loading ...</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateCommission;
