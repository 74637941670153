import { Fragment, useState, useEffect, forwardRef } from 'react';
// import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import NewPagination from '../../../components/newPagination';
import NewCustomerModal from '../fieldSalesDashboard/newCustomerModal';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Search from '../../../components/newSearch';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useNavigate,useSearchParams } from 'react-router-dom';
import CustomerUpdateModal from './customerUpdate';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Spinner from '../../../components/spinner';
import RefreshIcon from '@mui/icons-material/Refresh';
import Caret from '../../../components/caret';
import FilterListIcon from '@mui/icons-material/FilterList';
import MobileSorting from './mobileSorting';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadCSV } from "../../../utility/downloadCSV";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { setMyCustomers } from '../../../store/myCustomersSlice';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyCustomers = (props) => {
    const [error, setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [searchCustomer, setSearchCustomer] = useState([]);
    const [gstNO, setGstNO] = useState();
    const [showModal, setShowModal] = useState(false);
    const [num,setNum] = useState('');
	const [fetchCustomerRoleDB,setCustomerRoleDB] = useState([]);
    const [errorMessage,setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
	const [storeCustomerName,setCustomerName] = useState('');
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [salesUser,setSalesUser] = useState('');
    const [preSalesUser,setPreSalesUser] = useState('');
    const [modeOfCommunication,setModeOfCommunication] = useState('');
    const [email,setEmail] = useState('');

    const [updateCustomerModal,setUpdateCustomerModal] = useState(false);
    const [editCustomer,setEditCustomer] = useState([]);
    const [phoneNo,setPhoneNo] = useState('');
    const [states,setStates] = useState([]);
    const [cities,setCities] = useState([]);
    const [areas,setAreas] = useState([]);
    const [selectedState,setSelectedState] = useState('');
    const [selectedCity,setSelectedCity] = useState('');

    // customerUpdate
    const [selectedArea,setSelectedArea] = useState('');
    const [selectedCityName,setSelectedCityName] = useState('');
    const [selectedStateName,setSelectedStateName] = useState('');

    // areaFilter
    const [stateFilter,setStateFilter] = useState([]);
    const [cityFilter,setCityFilter] = useState([]);
    const [areaFilter,setAreaFilter] = useState([]);

    // Using useSearchParams hook It will update,delete & add queryParams
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedColumn,setSelectedColumn] = useState([]);
    const [selectedOrder,setSelectedOrder] = useState([]);

    const [sort,setSort] = useState({keyToSort:'',direction:'asc'});
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const myCustomers = useSelector((state) => state.myCustomers);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    const handleHeaderClick = async (header) => {
        setLoading(true);
        const direction = header === sort?.keyToSort ? sort?.direction === 'asc' ? 'desc' : 'asc' : 'desc';
        setSort({
            keyToSort: header,
            direction: direction
        })

        await axiosInstance
            .get(`/fieldSalesVisit/userSpecificCustomers?sort=${direction}&fieldName=${header}`)
            .then((res) => {
                setLoading(false);
                dispatch(setMyCustomers(res.data.data));
            });
    }

    const navigate = useNavigate();

    const getModal = () => {
        setShowModal(true);
    };

    const hideModal = () => {
        setNum('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setBusinessName('');
        setGstNO('');
        setErrorMessage('');
        setSalesUser('');
        setPreSalesUser('');
        setEmail('');
        setCity('');
        setSelectedState('');
        setArea('');
        setShowModal(false);
    };

    const visitCustomer = (event,customer,mode) => {
        event.stopPropagation();
        let communicationMode;
        if (event.target.innerText === 'Visit') {
            event.target.innerText = 'Adding..';
            communicationMode = 'Field Visit';
        } else {
            event.target.innerText = 'Calling..';
            communicationMode = 'On-Call';
        }
        
        axiosInstance
            .post('/fieldSalesVisit/newCreateFieldVisit',{
                customer: customer,
                modeOfCommunication: communicationMode
            })
            .then((res) =>{
                if (event.target.innerText === 'Visit Adding..') {
                    event.target.innerText = 'Visit Added'
                } else {
                    event.target.innerText = 'On-Call';
                }
                const urlParams = new URLSearchParams(window.location.search);
                const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
                fetchCustomers(page? page : 1);
                setErrorMessage('');
            }).catch((err) => {
                console.log(err)
            })
    };

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const handleSearchCustomer = (event) => {
        if (event.target.value) {
            setSearchCustomer([event.target.value]);
            // set SearchQuery In QueryParams
            searchParams.set("text", event.target.value);
            setSearchParams(searchParams);
        } else {
            // 👇️ delete search query param
            searchParams.delete('text');

            // 👇️ update state after
            setSearchParams(searchParams);
            searchCustomer.splice(0,searchCustomer.length);
        }
        
        searchParams.set("page", 1);
        setSearchParams(searchParams);
        fetchCustomers(1,event.target.value);
    }

    const fetchCustomers = async (type,searchCustomerName) => {
        const page = type;
        setLoading(true);

        const customer = searchCustomerName ? searchCustomerName : searchCustomer.length !== 0 ? searchCustomer[0]: ''
        if (customer) {
            searchParams.set("text", customer);
            setSearchParams(searchParams);
        }

        searchParams.set("page", page);
        setSearchParams(searchParams);

        const state = stateFilter.length !== 0 ? stateFilter[0] : '';
        const city = cityFilter.length !== 0 ? cityFilter[0] : '';
        const area = areaFilter.length !== 0 ? areaFilter[0] : '';
		await axiosInstance
			.get(`/fieldSalesVisit/userSpecificCustomers?page=${page}&text=${customer}&createdBy=${storeCustomerName}&state=${state}&city=${city}&area=${area}&sort=${sort?.direction}&fieldName=${sort?.keyToSort}`)
			.then((res) => {
                setLoading(false);
				dispatch(setMyCustomers(res.data.data));
			});
	};

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'My Customers')) {
            axiosInstance
                .get('/user/admin/customerRoles')
                .then((res) => {
                    setCustomerRoleDB([...res.data.data])
                })

                const searchString = searchParams.get('text') ? searchParams.get('text') : '';
                if (searchString) {
                    setSearchCustomer([searchString])
                }
                
                // fetch Customers
                const urlParams = new URLSearchParams(window.location.search);
                const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
                fetchCustomers(page? page : 1,searchString);
        }
    }, []);

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setStates(states)
            })
    },[]);

    useEffect(()=> {
        if (selectedState) {
            axiosInstance
            .get(`/others/cities/${selectedState}`)
            .then((response) => {
                const cities = [];
                for (let cityName of response.data.data) {
                    cities.push({id:cityName?._id,label:cityName?.city})
                }
                setCities(cities)
            })
        }
    },[selectedState]);

    useEffect(()=> {
        if (selectedCity) {
            axiosInstance
            .get(`/others/areas/${selectedCity}`)
            .then((response) => {
                const areas = [];
                for (let areaName of response.data.data) {
                    areas.push({id:areaName?._id,label:areaName?.area})
                }
                setAreas(areas);
            })
        }
    },[selectedCity]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#243750;',
            color: theme.palette.common.white,
            fontSize:12,
            padding:'16px 12px'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
            padding:'16px 12px'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const showContactPop = (event,customer) => {
        event.stopPropagation();
        setEditCustomer(customer);
		setUpdateCustomerModal(true);
	}

	const hideContactPop = () => {
        setArea('');
        setSelectedState('');
        setSelectedCity('');
        setSelectedArea('');
        setSelectedCityName('');
        setSelectedStateName('');
		setUpdateCustomerModal(false)
	}

    const handleAreaFilter = () => {
        const areaFilter = document.getElementById('areaFilter');
        if (areaFilter.style.display === 'flex') {
            areaFilter.style.display = 'none';
            setSelectedState('');
            setSelectedCity('');
        } else {
            areaFilter.style.display = 'flex'
        }
    }

    const handleResponsiveAreaFilter = () => {
        const areaFilterOption = document.getElementById('responsiveFilter');
        const buttonArea = document.getElementById('btnArea');
        if (areaFilterOption.style.display === 'block') {
            areaFilterOption.style.display = 'none';
            buttonArea.innerText = 'Area Filter';
            setSelectedState('');
            setSelectedCity('');
            stateFilter.splice(0, stateFilter.length);
            cityFilter.splice(0, cityFilter.length);
            areaFilter.splice(0, areaFilter.length);
            fetchCustomers(1)
        } else {
            areaFilterOption.style.display = 'block';
            buttonArea.innerText = 'Remove Filter';
        }
    }

    const hideAreaFilter = () => {
        const areaFilterOption = document.getElementById('areaFilter');
        areaFilterOption.style.display = 'none';
        setSelectedState('');
        setSelectedCity('');
        stateFilter.splice(0, stateFilter.length);
        cityFilter.splice(0, cityFilter.length);
        areaFilter.splice(0, areaFilter.length);
        fetchCustomers(1);
    }

    const handleArea = async () => {
        const state = document.getElementById('state').value;
        const city = document.getElementById('city').value;
        const area = document.getElementById('area').value;
        if (state) {
            setStateFilter([state]);
        }
        
        if (city) {
            setCityFilter([city]);
        }

        if (area) {
            setAreaFilter([area]);
        }

        const areaFilter = document.getElementById('areaFilter');
        areaFilter.style.display = 'none';
        const customer = searchCustomer.length !== 0 ? searchCustomer[0] : '';

        await axiosInstance
            .get(`/fieldSalesVisit/userSpecificCustomers?text=${customer}&state=${state}&city=${city}&area=${area}`)
            .then((res) => {
                dispatch(setMyCustomers(res.data.data));
            });
    }

    const handleResponsiveArea = async () => {
        const state = document.getElementById('responsiveState').value;
        const city = document.getElementById('responsiveCity').value;
        const area = document.getElementById('responsiveArea').value;

        if (state) {
            setStateFilter([state]);
        }
        
        if (city) {
            setCityFilter([city]);
        }

        if (area) {
            setAreaFilter([area]);
        }

        const customer = searchCustomer.length !== 0 ? searchCustomer[0] : '';

        await axiosInstance
            .get(`/fieldSalesVisit/userSpecificCustomers?text=${customer}&state=${state}&city=${city}&area=${area}`)
            .then((res) => {
                dispatch(setMyCustomers(res.data.data));
            });
    }

    const handleDifferenceDays = (date) => {
        const dates1 = new Date(date).toLocaleDateString();
        const dates2 = new Date().toLocaleDateString();
        const date1 = new Date(dates1).getTime();
        const date2 = new Date(dates2).getTime();
        const differentDays = date2 - date1;
        const days =  differentDays/(1000 * 3600 * 24);
        return days;
    }

    const handleTotal = (total) => {
		let fetchDecimal= countPlaces(total)
		return fetchDecimal === 1 || fetchDecimal === 0 || fetchDecimal === 2 ? total :  Math.trunc(total*100)/100
	}

	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        fetchCustomers(1)
    };

    const handleRefreshCustomers = async () => {
        setLoading(true);
        await axiosInstance
            .post(`/user/admin/updateCustomers/orders`)
            .then((res) => {
                setLoading(false);
                fetchCustomers(1);
            });
    }

    const getRelativeTime = (oldTime) => {
        //this old time have total mili second 
        const difference = Math.floor((new Date() - new Date(`${oldTime}`))/1000);
        let output = ``;
        if (difference < 60) {
            // Less than a minute has passed:
            output = `${difference} seconds ago`;
        } else if (difference < 3600) {
            // Less than an hour has passed:
            output = `${Math.floor(difference / 60)} minutes ago`;
        } else if (difference < 86400) {
            // Less than a day has passed:
            output = `${Math.floor(difference / 3600)} hours ago`;
        } else if (difference < 2620800) {
            // Less than a month has passed:
            output = `${Math.floor(difference / 86400)} days ago`;
        } else if (difference < 31449600) {
            // Less than a year has passed:
            output = `${Math.floor(difference / 2620800)} months ago`;
        } else {
            // More than a year has passed:
            output = `${Math.floor(difference / 31449600)} years ago`;
        }
        return output
    }

    const handleExport = (event) => {
        setLoading(true);
        event.preventDefault();

        axiosInstance
            .get('/exports/myCustomers')
            .then((result) => {
                setLoading(false);
                const arrayOfRowObjects = result?.data?.data;
                downloadCSV(arrayOfRowObjects, `My Customers export - ${new Date()}`);
                const successMessage = "My Customers Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

	return (
		<Fragment>
			<div  id="color">
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>

                <div className="d-flex" style={{justifyContent:'flex-end',marginTop:-25}}>
                    <div style={{ display:'flex',justifyContent:'space-around' }}>
                        <Button
                            id='exportbtn'
                            variant="contained"
                            style={{backgroundColor:'#26b278',marginRight:'5px',fontSize:14}}
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExport}
                        >
                            Export
                        </Button>

                        <Button  
                            startIcon={<RefreshIcon />} 
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize:'14px',
                                textTransform:'capitalize',
                                marginRight:'5px',
                                backgroundColor:'#EE5C55',
                                ":hover": {backgroundColor:'#EE5C55'},
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                            }}
                            onClick={handleRefreshCustomers}
                        >
                            Refresh
                        </Button>

                        <Button  
                            startIcon={<PersonAddIcon />} 
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize:'14px',
                                textTransform:'capitalize',
                                marginRight:'5px', 
                                "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                            }}
                            onClick={getModal}
                        >
                            Create
                        </Button>
                    </div>
                </div>

				{error ? (
					<Alert 
                        variant="danger" 
                        onClose={() => setError(false)} 
                        dismissible
                    >
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}

                <div className={Classes.Container}>
                    <div className={Classes.HeaderBox} style={{paddingTop:20}}>
                        <Search  
                            text={searchCustomer.length !== 0 ? searchCustomer[0] : ''}
                            fetchData={handleSearchCustomer}
                        />
					</div>

                    <TableContainer component={Paper} className={Classes.hideTable} >
                        <Table sx={{ minWidth: 'unset',"& .MuiTableRow-root:hover":{cursor:'pointer'} }} aria-label="customized table">
                            <TableHead sx={{position:'relative'}}>
                                <TableRow>
                                    <StyledTableCell>Customer Name</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>
                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                            <p>Area</p>
                                            <FontAwesomeIcon 
                                                icon={faFilter} 
                                                style={{marginTop:5,cursor: "pointer"}}
                                                onClick={handleAreaFilter}
                                            />
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleHeaderClick("lastVisitDate")}>
                                        Days Since(LV)
                                        {"lastVisitDate" === sort.keyToSort && (
                                            <Caret sort={sort} headerName={'lastVisitDate'}/>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleHeaderClick("lastOrderDate")}>
                                        Days Since(LO)
                                        {"lastOrderDate" === sort.keyToSort && (
                                            <Caret sort={sort} headerName={'lastOrderDate'}/>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleHeaderClick("avgMonthlyOrder")}>
                                        Avg Monthly Sale
                                        {"avgMonthlyOrder" === sort.keyToSort && (
                                            <Caret sort={sort} headerName={'avgMonthlyOrder'}/>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleHeaderClick("monthOrders")}>
                                        {new Date().getDate() <= 5 ? (
                                            <>{monthNames[new Date().getMonth() -1]}{" "}</>
                                        ) : (
                                            <>{monthNames[new Date().getMonth()]}{" "}</>
                                        )} 
                                        Month Sale
                                        {"monthOrders" === sort.keyToSort && (
                                            <Caret sort={sort} headerName={'monthOrders'}/>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{textAlign:'center'}}>Visit</StyledTableCell>
                                    <StyledTableCell sx={{textAlign:'center'}}>Call</StyledTableCell>
                                    <StyledTableCell sx={{textAlign:'center'}}>Edit</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <div className={Classes.AreaFilter} id='areaFilter'>
                                <ClearIcon 
                                    sx={{
                                        fontSize: "20px",
                                        color: "gray",
                                        marginTop: "14px",
                                        marginRight: "10px"
                                    }}
                                    cursor='pointer'
                                    onClick={hideAreaFilter}
                                />
                                <div style={{width:200,marginRight:5}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        name="state"
                                        options={states}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        // key={selectedStateName ? selectedStateName : ''}
                                        // defaultValue={selectedStateName ? selectedStateName : ''}
                                        sx={{
                                            width: '100%',
                                            "& .MuiAutocomplete-input" : {fontSize:14},
                                            "& label": {top: selectedState ? "0%": "-16%",fontSize:14},
                                            "& .MuiInputBase-input": {height:'12px'},
                                            "& .MuiOutlinedInput-root":{padding:'6px'},
                                        }}
                                        onChange={(event, newValue) => {
                                            setSelectedState(newValue.id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="States" variant="standard"/>}
                                    />
                                </div>

                                <div style={{width:200,marginRight:5}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="city"
                                        name='city'
                                        options={cities}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        // key={selectedCityName ? selectedCityName : ''}
                                        // defaultValue={selectedCityName ? selectedCityName : ''}
                                        // disabled={!selectedState ? true: false}
                                        sx={{
                                            width: '100%',
                                            "& .MuiAutocomplete-input" : {fontSize:14},
                                            "& label": {top: selectedCity ? "0%": "-16%",fontSize:14},
                                            "& .MuiInputBase-input": {height:'12px'},
                                            "& .MuiOutlinedInput-root":{padding:'6px'}
                                        }}
                                        onChange={(event, newValue) => {
                                            setSelectedCity(newValue.id ? newValue.id : event.target.value);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="City" variant="standard"/>}
                                    />
                                </div>

                                <div style={{width:200}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="area"
                                        name='area'
                                        options={areas}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        // key={selectedCityName ? selectedCityName : ''}
                                        // defaultValue={selectedCityName ? selectedCityName : ''}
                                        // disabled={!selectedCity ? true: false}
                                        sx={{width: '100%',"& label": {top: area ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setArea(newValue.id ? newValue.id : event.target.value);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Area" variant="standard"/>}
                                    />
                                </div>

                                <div style={{marginTop:6,marginLeft:5}}>
                                    <Button 
                                        variant="contained"
                                        sx={{
                                            textTransform: "capitalize",
                                            fontSize:'13px',
                                            padding:'4px 10px'
                                        }}
                                        onClick={handleArea}
                                    >
                                        Add
                                    </Button>
                                </div>
                                
                            </div>
                            <TableBody>
                                {myCustomers?.users?.length !== 0 && myCustomers?.users?.map((customer) => (
                                    <StyledTableRow 
                                        key={customer?._id}   
                                        onClick={() => {navigate(`${customer?._id}`)}}
                                    >
                                        <StyledTableCell scope="row" sx={{padding:'16px 10px',width:'160px'}}>
                                            {customer?.displayName ? customer?.displayName :
                                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}
                                        </StyledTableCell>
                                        <StyledTableCell>{customer?.phone}</StyledTableCell>
                                        <StyledTableCell>
                                            {customer?.area ? customer?.area : "N/A"}
                                        </StyledTableCell>

                                        <StyledTableCell sx={{textAlign:'center'}}>
                                            {customer?.lastVisitDate ? (
                                                <>{getRelativeTime(customer?.lastVisitDate)}</>
                                            ): (
                                                "N/A"
                                            )}
                                        </StyledTableCell>
                                        
                                        <StyledTableCell sx={{textAlign:'center'}}>
                                            {customer?.lastOrderDate ? (
                                                <>{getRelativeTime(customer?.lastOrderDate)}</>
                                            ) : (
                                                "N/A"
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {customer?.avgMonthlyOrder ? handleTotal(customer?.avgMonthlyOrder) : 0}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {customer?.monthOrders ? handleTotal(customer?.monthOrders) : 0}
                                        </StyledTableCell>

                                       <StyledTableCell>
                                            {customer?.hasOwnProperty('visitedCompleted') && !customer?.visitedCompleted ? (
                                                <>
                                                    {customer?.modeOfCommunication === "Field Visit" ? (
                                                        <Button 
                                                            variant="contained" 
                                                            sx={{textTransform: "capitalize"}}
                                                            style={{cursor:'default',padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                                                        >
                                                            Added
                                                        </Button>
                                                    ): (
                                                        "N/A"
                                                    )}
                                                </>
                                            ) : (
                                                <Button 
                                                    variant="contained" 
                                                    sx={{textTransform: "capitalize",padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                                                    onClick={(event) => visitCustomer(event,customer?._id)}
                                                >
                                                    Visit
                                                </Button>
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {customer?.hasOwnProperty('visitedCompleted') && !customer?.visitedCompleted ? (
                                                <>
                                                    {customer?.modeOfCommunication === "On-Call" ? (
                                                        <Button 
                                                            variant="contained" 
                                                            sx={{textTransform: "capitalize"}}
                                                            style={{cursor:'default',padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                                                        >
                                                            Called
                                                        </Button>
                                                    ) : (
                                                        "N/A"
                                                    )}
                                                </>
                                            ) : (
                                                <Button 
                                                    variant="contained" 
                                                    sx={{textTransform: "capitalize",padding:'3px 10px',minWidth:'unset',fontSize:'12px'}}
                                                    onClick={(event) => visitCustomer(event,customer?._id)}
                                                >
                                                    Call
                                                </Button>
                                            )}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            <Button 
                                                variant="contained"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    padding:'3px 10px',
                                                    minWidth:'unset',
                                                    fontSize:'12px'
                                                }}
                                                onClick={(event) => showContactPop(event,customer?._id)}
                                            >
                                                Edit
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div 
                            style={{marginBottom:10}} 
                            className={Classes.responsiveFilterBtn}
                        >
                            <Button
                                startIcon={<FilterListIcon/>}
                                variant="contained"
                                sx={{
                                    textTransform: "capitalize",
                                    padding:'4px 12px'
                                }}
                                onClick={handleClickOpen}
                                id='btnArea'
                            >
                                Sort
                            </Button>
                        </div>

                        <div 
                            style={{marginBottom:10}} 
                            className={Classes.responsiveFilterBtn}
                        >
                            <Button
                                startIcon={<FilterAltIcon/>}
                                variant="contained"
                                sx={{
                                    textTransform: "capitalize",
                                    padding:'4px 12px'
                                }}
                                onClick={handleResponsiveAreaFilter}
                                id='btnArea'
                            >
                                Area Filter
                            </Button>
                        </div>
                    </div>

                    <div id='responsiveFilter' className={Classes.ResponsiveFilter}>
                        <div>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id="responsiveState"
                                name="responsiveState"
                                options={states}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                sx={{
                                    width: '100%',
                                    "& .MuiAutocomplete-input" : {fontSize:14},
                                    "& label": {top: selectedState ? "0%": "-16%",fontSize:14},
                                    "& .MuiInputBase-input": {height:'12px'},
                                    "& .MuiOutlinedInput-root":{padding:'6px'},
                                }}
                                onChange={(event, newValue) => {
                                    setSelectedState(newValue.id);
                                }}
                                renderInput={(params) => <TextField {...params} label="States"/>}
                            />
                        </div>

                        <div style={{marginTop:10}}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id="responsiveCity"
                                name='responsiveCity'
                                options={cities}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                // key={selectedCityName ? selectedCityName : ''}
                                // defaultValue={selectedCityName ? selectedCityName : ''}
                                // disabled={!selectedState ? true: false}
                                sx={{
                                    width: '100%',
                                    "& .MuiAutocomplete-input" : {fontSize:14},
                                    "& label": {top: selectedCity ? "0%": "-16%",fontSize:14},
                                    "& .MuiInputBase-input": {height:'12px'},
                                    "& .MuiOutlinedInput-root":{padding:'6px'}
                                }}
                                onChange={(event, newValue) => {
                                    setSelectedCity(newValue.id ? newValue.id : event.target.value);
                                }}
                                renderInput={(params) => <TextField {...params} label="City"/>}
                            />
                        </div>

                        <div style={{marginTop:10,marginBottom:20}}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id="responsiveArea"
                                name='responsiveAarea'
                                options={areas}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                // key={selectedCityName ? selectedCityName : ''}
                                // defaultValue={selectedCityName ? selectedCityName : ''}
                                // disabled={!selectedCity ? true: false}
                                sx={{width: '100%',"& label": {top: area ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                onBlur={(event, newValue) => {
                                    setArea(newValue.id ? newValue.id : event.target.value);
                                }}
                                renderInput={(params) => <TextField {...params} label="Area"/>}
                            />
                        </div>

                        <Button 
                            variant="contained"
                            sx={{
                                textTransform: "capitalize",
                                fontSize:'13px',
                                padding:'4px 10px'
                            }}
                            onClick={handleResponsiveArea}
                        >
                            Add
                        </Button>

                    </div>

                    <div className={Classes.responsiveTable}>
                        {myCustomers?.users?.length !== 0 && myCustomers?.users?.map((customer,index) => (
                            <div 
                                key={customer?._id}   
                                onClick={() => {navigate(`${customer?._id}`)}}
                                className={Classes.responsiveTableBox}
                                style={{marginTop: index === 0 ? 0 : 15,padding:10}}
                            >
                                <div style={{display:'flex'}}>
                                    <AccountCircleIcon sx={{marginRight:'5px'}}/>
                                    <p style={{fontWeight:600}}>
                                        {customer?.displayName ? customer?.displayName :
                                            `${customer?.firstName} ${customer?.lastName && customer?.lastName}`}
                                    </p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Phone:</p>
                                    <p>{customer?.phone ? customer?.phone : "N/A"}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <p style={{marginRight:5,color: "#6c757d"}}>Area:</p>
                                    <p>{customer?.area ? customer?.area : "N/A"}</p>
                                </div>

                                <div style={{display:'flex'}}>
                                    <div style={{marginRight:13}}>
                                        <p style={{color: "#6c757d"}}>Last Visit</p>
                                        <p>
                                            {customer?.lastVisitDate ? (
                                                <>{getRelativeTime(customer?.lastVisitDate)}</>
                                            ): (
                                                "N/A"
                                            )}
                                        </p>
                                    </div>

                                    <div style={{marginRight:13}}>
                                        <p style={{color: "#6c757d"}}>Last Order</p>
                                        <p>
                                            {customer?.lastOrderDate ? (
                                                <>{getRelativeTime(customer?.lastOrderDate)}</>
                                            ) : (
                                                "N/A"
                                            )}
                                        </p>
                                    </div>

                                    <div style={{marginRight:13}}>
                                        <p style={{color: "#6c757d"}}>Avg (M.Sale)</p>
                                        <p>{customer?.avgMonthlyOrder ? handleTotal(customer?.avgMonthlyOrder) : 0}</p>
                                    </div>

                                    <div>
                                        <p style={{color: "#6c757d"}}>
                                            {new Date().getDate() <= 5 ? (
                                                <>{monthNames[new Date().getMonth() -1]}{" "}</>
                                            ) : (
                                                <>{monthNames[new Date().getMonth()]}{" "}</>
                                            )}  (M.Sale)
                                        </p>
                                        <p>{customer?.thisMonthSale ? handleTotal(customer?.thisMonthSale) : 0}</p>
                                    </div>
                                </div>

                                <div style={{display:'flex',marginTop:10}}>
                                    <div>
                                        {customer?.hasOwnProperty('visitedCompleted') && !customer?.visitedCompleted ? (
                                            <>
                                                {customer?.modeOfCommunication === "Field Visit" && (
                                                    <Button 
                                                        variant="contained" 
                                                        sx={{textTransform: "capitalize"}}
                                                        style={{cursor:'default',padding:'2px 8px',fontSize:13,marginRight:15}}
                                                    >
                                                        Visit Added
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <Button 
                                                variant="contained" 
                                                sx={{textTransform: "capitalize",padding:'2px 8px',fontSize:13}}
                                                style={{marginRight:15}}
                                                onClick={(event) => visitCustomer(event,customer?._id)}
                                            >
                                                Visit
                                            </Button>
                                        )}
                                    </div>

                                    <div>
                                        {customer?.hasOwnProperty('visitedCompleted') && !customer?.visitedCompleted ? (
                                            <>
                                                {customer?.modeOfCommunication === "On-Call" && (
                                                    <Button 
                                                        variant="contained" 
                                                        sx={{textTransform: "capitalize"}}
                                                        style={{cursor:'default',padding:'2px 8px',fontSize:13,marginRight:15}}
                                                    >
                                                        On-Call
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <Button 
                                                variant="contained" 
                                                sx={{textTransform: "capitalize",padding:'2px 8px',fontSize:13}}
                                                style={{marginRight:15}}
                                                onClick={(event) => visitCustomer(event,customer?._id)}
                                            >
                                                Call
                                            </Button>
                                        )}
                                    </div>

                                    <div>
                                        <Button 
                                            variant="contained"
                                            sx={{
                                                textTransform: "capitalize",
                                                padding:'2px 8px',
                                                fontSize:13
                                            }}
                                            onClick={(event) => showContactPop(event,customer?._id)}
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div
                        style={{
                            margin: '20px auto 0px',
                            paddingBottom: 20
                        }}
                    >
                        <NewPagination
                            totalPage={myCustomers}
                            fetchData={fetchCustomers}
                        />
                    </div>
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewCustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    fetchCustomerRoleDB={fetchCustomerRoleDB}
                    fetchCustomers={fetchCustomers}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    preSalesUser={preSalesUser}
                    setPreSalesUser={setPreSalesUser}
                    area={area}
                    setArea={setArea}
                    city={city}
                    setCity={setCity}
                    email={email}
                    setEmail={setEmail}
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    modeOfCommunication={modeOfCommunication}
                    setModeOfCommunication={setModeOfCommunication}
                    popUpType='customerDashboard'
                    endpoint={`/user/admin/affiliates`}
                />
            </div>

            <div className={updateCustomerModal ? Classes.UpdateSalesDialog : undefined}>
                <CustomerUpdateModal 
                    updateCustomerModal={updateCustomerModal}
                    hideContactPop={hideContactPop}
                    editCustomer={editCustomer}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    lastName={lastName}
                    setDisplayName={setDisplayName}
                    displayName={displayName}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    setEmail={setEmail}
                    email={email}
                    setBusinessName={setBusinessName}
                    businessName={businessName}
                    fetchCustomers={fetchCustomers}
                    states={states}
                    setStates={setStates}
                    cities={cities}
                    setCities={setCities}
                    setArea={setArea}
                    area={area}
                    areas={areas}
                    setAreas={setAreas}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                    selectedCityName={selectedCityName}
                    setSelectedCityName={setSelectedCityName}
                    selectedStateName={selectedStateName}
                    setSelectedStateName={setSelectedStateName}
                />
            </div>

            <div className={open ? Classes.UpdateSalesDialog : undefined}>
                <MobileSorting 
                    showModal={open}
                    hideDialog={handleClose}
                    setOpen={setOpen}
                    setSort={setSort}
                    setCustomers={setCustomers}
                    setSelectedColumn={setSelectedColumn}
                    selectedColumn={selectedColumn}
                    setSelectedOrder={setSelectedOrder}
                    selectedOrder={selectedOrder}
                />
            </div>

            {loading ? <Spinner /> : null}

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default MyCustomers;
