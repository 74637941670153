import { useState, Fragment, useEffect } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Button, Typography, Box, Paper, InputAdornment, TextField } from "@mui/material";
import RiderPopup from './riderPopup';
import RiderUpdatePopup from './updateRider';
import AddIcon from '@mui/icons-material/Add';
import Classes from '../customers/css/customer.module.css';
import FormDialog from '../../../components/FormDialog/FormDialog';
import MobileResponsive from './mobileResponsive';
import { useSelector, useDispatch } from 'react-redux';
import { setRiders } from '../../../store/deliveryRidersSlice';
import SearchIcon from "@mui/icons-material/Search";
import NewPagination from '../../../components/newPagination';
import SnackBar from '../../../components/SnackBar';
import {
    DataGrid,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';

const DeliveryRiders = (props) => {
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [riderId, setRiderId] = useState(null);
    const [riderName, setRiderName] = useState(null);
    const [reRender, setReRender] = useState(false);
    const [locationCode, setLocationCode] = useState(null);
    const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [searchFor,setSearchFor] = useState("");

    const deliveryRiders = useSelector((state) => state?.deliveryRiders);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    const years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

    const handleCreatedAt = (createdAt) => {
        const dn = new Date();
        let ca = createdAt
            ? createdAt.getFullYear() === dn.getFullYear()
                ? createdAt.getMonth() === dn.getMonth()
                    ? createdAt.getDate() === dn.getDate()
                        ? `Today at ${createdAt.toLocaleTimeString()}`
                        : createdAt.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
                            : years[createdAt.getMonth()] +
                            ' ' +
                            createdAt.getDate() +
                            ' at ' +
                            createdAt.toLocaleTimeString()
                    : years[createdAt.getMonth()] +
                    ' ' +
                    createdAt.getDate() +
                    ' at ' +
                    createdAt.toLocaleTimeString()
                : years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
            : null
        return ca;
    }

    function SearchInput({ searchFor, handleSearch }) {
        return (
            <div style={{ width: '25%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ 
                        width: '100%',
                        "& label": { top: searchFor ? "0%" : "-18%" },
                        "& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
                     }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    function CustomToolbar({ searchFor, handleSearch }) {
        return (
            <GridToolbarContainer style={{paddingTop:8,justifyContent:'space-between'}}>
                <div>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </div>
                
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />
            </GridToolbarContainer>
        );
    }

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const showUpdatePopup = (id, name, locationCode) => {
        setRiderId(id)
        setRiderName(name)
        setLocationCode(locationCode)
        setUpdatePopup(true)
    }

    const hideUpdatePopup = () => {
        setUpdatePopup(false)
        setRiderId(null)
        setRiderName(null)
    }

    const columns = [
        {
            field: `deliveryBoyId`,
            headerName: `ID`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `deliveryRider`,
            headerName: `RIDER`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `locationCode`,
            headerName: `Location Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `createdAt`,
            headerName: `Created At`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: 'Update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Button variant='contained' size="small" onClick={(e) => showUpdatePopup(params?.row?.deliveryBoyId, params?.row?.deliveryRider, params?.row?.locationCode)}>Update</Button>
            )
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                // <Button variant='contained' size="small" color="error" onClick={(e) => handleDelete(params?.row?.deliveryBoyId)}>Delete</Button>
                <FormDialog
                    dialogTitle="Confirm Delivery Rider Deletion?"
                    dialogActionType="confirmation"
                    openDialogButtonName="Delete"
                    submitDialogButtonName="Delete"
                    onConfirmation={() => handleDelete(params?.row?.deliveryBoyId)}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        This action would permanently delete Delivery Rider?
                    </Typography>
                </FormDialog>
            )
        }
    ];

    const hidePopup = () => {
        setShowPopup(false);
    }

    const handleDelete = async (id) => {
        await axiosInstance
            .delete(`pgOrder/admin/deleteDeliveryRider/${id}`)
            .then((res) => {
                const successMessage = 'Delivery Rider Deleted Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                const urlParams = new URLSearchParams(window.location.search);
                const page = urlParams.get('page');
                fetchData(page);
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }
    
    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page, sort:JSON.stringify(sort) };
        await axiosInstance
            .get(`/pgOrder/admin/deliveryRiders`, {
                params
            })
            .then((res) => {
                dispatch(setRiders(res?.data?.data));
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Delivery Riders')) {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            fetchData(page ? page :1);
        }
    },[searchFor,sort]);


    return (
        <Fragment>
            <div style={{ display:'flex',width:'99%',justifyContent:'end',flexWrap:'wrap',marginTop:-25, marginBottom:10 }}>
                <Button startIcon={<AddIcon />} variant="contained" onClick={() => setShowPopup(true)}>Add</Button>
            </div>

            <div className={Classes.MuiTable}>
                <Box 
                    className="shadow"
                    component={Paper}
                    sx={{
                        '& .super-app-theme--header': {
                        backgroundColor: '#243750',
                        color: '#ffffff',
                        cursor: "default",
                        },
                    }}
                >
                    <DataGrid
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{ toolbar: { searchFor, handleSearch }}}
                        componentsProps={{
                        toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                        },
                        }}
                        rows={deliveryRiders?.fetchedRiders?.map((riders) => ({
                            id: riders?.deliveryBoyId,
                            deliveryBoyId: riders?.deliveryBoyId,
                            deliveryRider: riders?.deliveryRider,
                            locationCode: riders?.locationCode,
                            createdAt: handleCreatedAt(new Date(riders?.createdAt))
                        }))}
                        columns={columns}
                        filterMode="server"
                        sortingMode="server"
                        paginationMode="server"
                        disableRowSelectionOnClick
                        checkboxSelection={false}
                        autoHeight
                        autoPageSize
                        sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            ".PrivateSwitchBase-input": {
                                height: "23px",
                                margin: "10px 13px",
                                width: "20px"
                            },
                            cursor: "pointer",
                            ".MuiDataGrid-footerContainer": { display:"none" },
                        }}
                        initialState={{
                        sorting: {
                            sortModel: [{ field: 'createdAt', sort: 'desc' }],
                        },
                        }}
                        onSortModelChange={(sort) => setSort(sort[0])}
                        sortingOrder={['asc', 'desc']}
                    />
                </Box>

                <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

                {deliveryRiders?.fetchedRiders?.length > 0 ? (
                    <div>
                        <NewPagination totalPage={deliveryRiders} fetchData={fetchData} />
                    </div>
                ) : null}
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns} 
                    dataPosition={"fetchedRiders"} 
                    endpoint={'/pgOrder/admin/deliveryRiders'}
                    rowId={'deliveryBoyId'}
                    showUpdatePopup={showUpdatePopup}
                    handleDelete={handleDelete}
                />
            </div>

            <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                <RiderPopup
                    showPopup={showPopup}
                    hidePopup={hidePopup}
                    reRender={reRender}
                    setReRender={setReRender}
                />
            </div>

            <div className={updatePopup ? Classes.UpdateSalesDialog : undefined}>
                <RiderUpdatePopup
                    showPopup={updatePopup}
                    hidePopup={hideUpdatePopup}
                    reRender={reRender}
                    setReRender={setReRender}
                    riderId={riderId}
                    riderName={riderName}
                    setRiderName={setRiderName}
                    locationCode={locationCode}
                    setLocationCode={setLocationCode}
                />
           </div>

            {loading ? <Spinner /> : null}
        </Fragment>
    );
};

export default DeliveryRiders;