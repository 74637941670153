import {
    DataGrid,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { Fragment, forwardRef, useRef } from 'react';
import NewPagination from '../newPagination'
import { Box, Paper, InputAdornment, TextField, Autocomplete } from "@mui/material";
import axiosInstance from '../../utility/axios-instance';
import { useEffect, useState } from 'react';
import Classes from './css/table.module.css';
import SearchIcon from "@mui/icons-material/Search";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { CheckRoleBasedPermission, permission, services } from '../../utility/checkRoleBasedPermission';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCheckBox } from '../../store/storeSelectedCheckbox';
import Spinner from '../spinner';
const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function SearchInput({ searchFor, handleSearch }) {
    return (
        <div style={{ width: '100%' }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

const orderStatuses = ['Confirmed',
    'Part Allocated',
    'Part Packed',
    'Partially Dispatched',
    'Partially Delivered',
    'Part Delivered & Cancelled',
    'Partially Fulfilled',
    'Partially Return Requested',
    'Partially Returned',
    'Allocated',
    'Packed',
    'Dispatched',
    'Delivered',
    'Cancelled',
    'Return Requested',
    'Returned',
]

function StatusInput({ status, handleStatusFilter }) {
    return (
        <div style={{ width: '90%' }}>
            <Autocomplete
                multiple
                limitTags={1}
                size="small"
                disablePortal
                sx={{ width: '100%' }}
                options={orderStatuses}
                value={status}
                onChange={handleStatusFilter}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                    />
                )}
            />
        </div>
    )
}



function CustomToolbar({ searchFor, handleSearch, handleStatusFilter, dataPosition, extraToolBarButtons }) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            {extraToolBarButtons?.length > 0 ? extraToolBarButtons.map((button) => button) : null}
            <div
                className={Classes.SearchBox}
                style={{ border: 'none', marginLeft: 'auto' }}
            >
                {dataPosition === "orders" &&
                    <StatusInput handleStatusFilter={handleStatusFilter} />
                }
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />
            </div>
        </GridToolbarContainer>
    );
}

export default function Table({ 
    endpoint, dataPosition, navigateOnRowClickEndpoint, columns, checkBoxSelection, 
    rowSelectionEndpoint, rowId, reRender, version, columnVisibilityModel, 
    initialFilterState, service, searchData, setData, data, tabName, type, extraToolBarButtons, isMovementWithinVisible
}) {
    const defaultVersion = 'version-1'
    const versionMap = {
        "version-1": {
            initialSortState: { field: 'createdAt', sort: 'desc' }
        },
        "version-2": {
            initialSortState: { field: 'created_at', sort: 'desc' }
        },
    }
    versionMap.undefined = {
        initialSortState: versionMap[defaultVersion]?.initialSortState
    };
    const initialSortState = versionMap?.[version]?.initialSortState
    const [loading, setLoading] = useState(false)
    const [rowsData, setRowsData] = useState([])
    const [columnsData, setColumnsData] = useState([])
    const [sort, setSort] = useState(initialSortState)
    const [searchFor, setSearchFor] = useState("")
    const [filter, setFilter] = useState(initialFilterState?.items?.[0] || null);
    const [limit, setLimit] = useState(10)
    // const [paginationData, setPaginationData] = useState(null)
    const [status, setStatus] = useState([])
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const auth = useSelector((state) => state?.auth);
    const selectedBox = useSelector((state) => state.selectedCheckBox);
    const pinTab = useSelector((state) => state?.pinTab)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];
    const isFirstRender = useRef(true); // Ref to track the first render
    const dn = new Date();

    const createdAt = (createdAt) => {
        if (!createdAt) return null;

        const date = new Date(createdAt);
        let createdAtDate =
            date.getFullYear() === dn.getFullYear()
                ? date.getMonth() === dn.getMonth()
                    ? date.getDate() === dn.getDate()
                        ? `Today at ${date.toLocaleTimeString()}`
                        : date.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${date.toLocaleTimeString()}`
                            : years[date.getMonth()] +
                            ' ' +
                            date.getDate() +
                            ' at ' +
                            date.toLocaleTimeString()
                    : years[date.getMonth()] +
                    ' ' +
                    date.getDate() +
                    ' at ' +
                    date.toLocaleTimeString()
                : years[date.getMonth()] + ' ' + date.getFullYear();

        return createdAtDate;
    }

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    const handleStatusFilter = (e, newValue) => {
        setStatus(newValue);
    }

    const fetchData = async (page) => {
        setLoading(true)
        let statusQuery = status.join(',');

        const params = {
            sort,
            searchFor: searchFor ? searchFor : searchData ? searchData : '',
            filter: `${filter?.value}` ? filter : undefined,
            page,
            limit,
            status: statusQuery || undefined
        };
        console.log("Data Before ",data);
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data;
                dispatch(setData(responseData));
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const actionOnRowsSelected = async () => {
        setLoading(true)
        const payload = rowSelectionModel;
        await axiosInstance
            .post(`${rowSelectionEndpoint}`, {
                payload
            })
            .then((res) => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                fetchData();
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    };


    useEffect(() => {
        const key = Object.keys(pinTab)?.find((key) => pinTab[key]?.name === tabName);
        if (type && key && !(pinTab[key]?.multiTabName === type)) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                console.log('first render 1')
                fetchData(); // Fetch data immediately without delay
                return;
            }
            const delay = 550;
            const debounce = setTimeout(() => {
                endpoint && fetchData();
            }, delay);
            return () => clearTimeout(debounce);
        } else if (!(Object.keys(pinTab)?.some((key) => pinTab[key]?.name === tabName))){
            if (isFirstRender.current) {
                isFirstRender.current = false;
                console.log('first render')
                fetchData(); // Fetch data immediately without delay
                return;
            }
            const delay = 550;
            const debounce = setTimeout(() => {
                endpoint && fetchData();
            }, delay);
            return () => clearTimeout(debounce);
        }
    }, [sort, searchFor, filter, reRender, limit, status, isMovementWithinVisible])



    useEffect(() => {
        if (data?.[dataPosition]?.length >= 0) {
            let finalColumns = columns?.length > 0
                ? columns
                : Object.keys(data[dataPosition]?.[0]).map((field) => {
                    return {
                        field: `${field}`,
                        headerName: `${field}`,
                        flex: 1,
                        headerClassName: 'super-app-theme--header',
                        headerAlign: 'center',
                        align: 'center',
                        editable: false,
                        sortable: false,
                        filterable: false,
                    }
                });

            if (!finalColumns[0]?.checkBoxColumn && checkBoxSelection) {

                finalColumns.unshift({
                    flex: 0.5,
                    ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    headerClassName: 'super-app-theme--header',
                    checkBoxColumn: true,
                })
            }

            const formattedData = data?.[dataPosition]?.map((row) => {
                if (row?.created_at) {
                    return {
                        ...row,
                        created_at: createdAt(row?.created_at),
                        updated_at: createdAt(row?.updated_at),
                        cancelled_at: createdAt(row?.cancelled_at),
                    }
                } else {
                    return {
                        ...row,
                        createdAt: createdAt(row?.createdAt),
                        updatedAt: createdAt(row?.updatedAt),
                        cancelledAt: createdAt(row?.cancelledAt),
                    }
                }
            })

            setRowsData(formattedData)
            setColumnsData(finalColumns)
        }

    }, [data])

    useEffect(() => {
        dispatch(setSelectedCheckBox({ ...selectedBox, ids: rowSelectionModel, function: actionOnRowsSelected }));
    }, [rowSelectionModel])

    const handleRowSelection = (arrayOfRowsSelectedIds) => {
        setRowSelectionModel(arrayOfRowsSelectedIds)
    };

    return (
        <Fragment>
            <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
            <div className={Classes.Container} style={{ width: '100%' }} >

                <div style={{ width: '100%' }}>
                    {
                        rowsData &&
                        <Box className="shadow"
                            component={Paper}
                            sx={{
                                '& .super-app-theme--header': {
                                    backgroundColor: '#243750',
                                    color: '#ffffff',
                                    cursor: "default"
                                },
                            }}>
                            <DataGrid
                                rows={rowsData}
                                getRowId={(row) => row[rowId]}
                                columns={columnsData}
                                slots={{ toolbar: CustomToolbar }}
                                slotProps={{
                                    toolbar: {
                                        searchFor,
                                        handleSearch,
                                        handleStatusFilter,
                                        dataPosition,
                                        extraToolBarButtons
                                    }
                                }}
                                filterMode="server"
                                sortingMode="server"
                                paginationMode="server"
                                pageSizeOptions={[10, 25, 35, 50, 100]}
                                disableRowSelectionOnClick
                                checkboxSelection={checkBoxSelection}
                                autoHeight
                                onFilterModelChange={(filter) => (setFilter(filter.items[0]))}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    handleRowSelection(newRowSelectionModel)
                                }}
                                rowSelectionModel={rowSelectionModel}
                                getRowHeight={() => "auto"}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    "& .MuiTablePagination-displayedRows": { display: 'none' },
                                    "& .MuiTablePagination-actions": { display: 'none' },
                                    ".PrivateSwitchBase-input": {
                                        height: "23px",
                                        margin: "10px 13px",
                                        // width: "20px"
                                    },
                                    cursor: "pointer",
                                    '& .MuiDataGrid-cell': {
                                        py: '10px',
                                    },
                                }}
                                onRowClick={(params) => {
                                    const permissible = CheckRoleBasedPermission(auth?.user, services?.[service], permission?.read)
                                    if (permissible && navigateOnRowClickEndpoint) {
                                        navigate(`${navigateOnRowClickEndpoint}/${params?.id}`);
                                    }
                                }}
                                initialState={{
                                    columns: { columnVisibilityModel },
                                    pagination: {
                                        paginationModel: { pageSize: 10, page: 0 },
                                    },
                                }}
                                onSortModelChange={(sort) => setSort(sort[0])}
                                onPaginationModelChange={({ pageSize }) => {
                                    if (pageSize >= 10) {
                                        setLimit(pageSize)
                                    } else {
                                        setLimit(10)
                                    }
                                }
                                }
                            />
                        </Box>
                    }
                </div>

                {loading ? <Spinner /> : null}

                {data?.[dataPosition]?.length > 0 && (
                    <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                        <NewPagination totalPage={data} fetchData={fetchData} />
                    </div>
                )}
            </div>
        </Fragment >
    );
}