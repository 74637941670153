import React, {Fragment} from "react";
import axiosInstance from "../../../utility/axios-instance";
import { toTitleCase } from "../../../utility/textFormatting";
import { downloadCSV } from "../../../utility/downloadCSV";
import "./uploadImages.css"; 

const OthersFormSections = (props)=> {
    const { 
        setLoading,
        setProgress,
        generateAlert,
        setCorrections,
        modifyForAccordion,
        setStep,
        setUploadedImagesUrls,
        setError,
        templateDownloadEndpoint,
        endpoint,
        formName,
        title,
        exportEndpoint,
        handleSuccess,
        handleError
    } = props;

    
    const handleImport = (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append(
            "images",
            document.querySelector(`#${formName}`).files[0]
        );
        let progressBar;

        axiosInstance
            .post(`${endpoint}`, formData, {
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    const total = (100 * data.loaded) / data.total;
                    progressBar = parseInt(total)/2;
                    setProgress(progressBar)
                },
                onDownloadProgress: data => {
                    //Set the progress value to show the progress bar
                    const total = (100 * data.loaded) / data.total;
                    setProgress(total)
                }
            })
            .then((result) => {
                if (result.data.status === 'Server Busy') {
                    alert('Server Busy, Please Try Again Later')
                    setLoading(false);
                } else {
                    generateAlert("Zip File Uploaded Successfully", 4000);
                    setLoading(false);
                    setCorrections(modifyForAccordion(result.data.errorMessage));
                    if(modifyForAccordion(result.data.errorMessage).length===0){
                        setStep(3);
                        const data = result?.data?.data
                        const imagesUrls = data.map((sku)=>{
                        return sku.url;
                    })
                    setTimeout(() => {
                        setProgress()
                    },3000)  
                    setUploadedImagesUrls(imagesUrls)
                } 
                    else{
                        setStep(2);
                        setProgress(100)
                        setTimeout(() => {
                        setProgress()
                        },1500)
                    }
                }
            })
            .catch((error) => {
                console.error("error", error)
                setError(error?.response?.data)
            });
    };

    const handleExport = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`${exportEndpoint}`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
                downloadCSV(arrayOfRowObjects,`${formName} export - ${new Date()}`);
                handleSuccess(`${toTitleCase(formName)} file Exported Successfully`)
            })
            .catch((error) => {
                handleError(error?.response?.data?.message);
                // if (error?.response?.status === 401) {
                //     setTimeout(() => {
                //         resetState(dispatch);
                //     }, 1000);
                // }
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <Fragment>
            <div className='ImagesBox'>
                <p style={{marginLeft:16}}>{title}</p>
                <form className="file-input" style={{border:0,marginBottom:0}}>
                    <div className="d-flex flex-column justify-content-center">
                        <label
                            htmlFor="imagesUpload"
                            className="form-label text-center"
                        >
                        </label>
                        <div className="d-flex">
                            <input
                                className="form-control margin-right formInput"
                                id={formName}
                                type="file"
                                accept=".zip"
                                name="images"
                                required
                            />
                            <button
                                type="submit"
                                className="btn btn-primary buttonResponsive"
                                onClick={handleImport}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>

                <div style={{display:'flex',marginTop:15}} className="btnBetween">
                    <div>
                        <a href={templateDownloadEndpoint} target="_blank" rel="noopener noreferrer">
                            <button
                                className="btn btn-primary buttonResponsive"
                                style={{marginLeft:15}}
                            >
                                Open Zipped File Format
                            </button>
                        </a>
                    </div>

                    <div>
                        <button
                            className="btn btn-primary mb-2 buttonResponsive mgRight"
                            onClick={handleExport}
                            style={{marginLeft:15}}
                        >
                            Export Images
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}


export default OthersFormSections;