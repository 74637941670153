import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from '../draftOrder/css/draftOrderDetailPage.module.css';
import Aside from './aside/aside';
import axiosInstance from '../../../utility/axios-instance';
import { BiArrowBack } from 'react-icons/bi';
import TaxRatesPopup from '../ordersNew/TaxRates_popup';
import ConfirmPopup from '../createOrders/confirmPopup';
import DeleteOrderConfirmPopup from '../draftOrder/deleteOrderPopup';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';

const DraftOrderDetailPageNew = (props) => {
	const [error, setError] = useState(null);
	const [showTaxRate, setTaxRates] = useState(false);
	const [draftOrder, setDraftOrder] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [taxRates, setTaxRateArray] = useState([]);

	// store deliveryRemark & paymentRemark in State
	const [paymentRemark, setPaymentRemark] = useState('');
	const [deliveryRemark, setDeliveryRemark] = useState('');

	// sendExistOrder
	const [updateToggle, setUpdateToggle] = useState(true);
	const [completeExistOrder, setCompleteExistOrder] = useState([]);
	const [showConfirmPopup, setConfirmShowPopup] = useState(false);
	const [showDeleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [loading, setLoading] = useState(false);

	const auth = useSelector((state) => state.auth);
	const breadCrumb = useSelector((state) => state.breadcrumb);


	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { id } = useParams();

	// handle Discount
	const handleDiscount = (mrp, discountedPrice, value) => {
		const MRP = parseFloat(mrp);
		const DP = parseFloat(discountedPrice);
		const GST = parseInt(value);
		const result = (MRP - (DP * (1 + GST / 100))) / MRP;
		const TotalDiscount = result * 100;
		let countDecimal = countPlaces(TotalDiscount);
		let results = countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? TotalDiscount : Math.trunc(TotalDiscount * 100) / 100;
		return results;
	};


	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1] ? b[1].length : 0;
	}

	// Open Window Print
	const handleSendPI = (event) => {
		const customerBox = document.getElementById('customerBox');
		const customerBoxHeader = document.getElementById('customerHeader');
		const contactBox = document.getElementById('contactBox');
		const contactBoxHeader = document.getElementById('contactHeader');
		const hideButtons = document.getElementById('removeCreateOrderPrint');
		const hideHeader = document.getElementById('hideHeader');
		const hideProductHeader = document.getElementById('productHeading');
		// const taxRate = document.getElementById('taxRate');
		// const tax = document.getElementById('tax');
		// const taxRateAmount = document.getElementById('taxRateAmount');
		// const taxRatesPrint = document.getElementById('TaxRatesPrint');

		if (draftOrder?.metafields?.length !== 0) {
			contactBox.style.padding = '0.6rem 1.1rem';
			contactBoxHeader.style.marginBottom = '0.5rem';
			customerBox.style.padding = '0.6rem 1.1rem';
			customerBoxHeader.style.marginBottom = '0.5rem';
		}

		// taxRatesPrint.style.display = 'none';
		hideProductHeader.style.width = '60%';
		hideHeader.style.display = 'none';
		hideButtons.style.display = 'none';
		// taxRate.style.display = 'none';
		// tax.style.width = '50%';
		// taxRateAmount.style.width = '50%';

		if (draftOrder.length !== 0) {
			onbeforeprint = document.title = draftOrder?.draftOrderName ? draftOrder?.draftOrderName : draftOrder.draftOrder.name.slice(1);
		}

		window.print();
		// onafterprint = tax.style.width = '30%';
		onafterprint = document.title = 'DEPO24 PORTAL';
		// onafterprint = taxRateAmount.style.width = '40%';
		onafterprint = hideHeader.style.display = 'flex';
		onafterprint = hideProductHeader.style.width = '75%';
		onafterprint = hideButtons.style.display = 'block';
		// onafterprint = taxRate.style.display = 'block';
		// onafterprint = document.getElementById('proformaInvoice').style.display = 'none';
		// onafterprint = taxRatesPrint.style.display = 'block';
		onafterprint = customerBox.style.padding = '1.1rem';
		onafterprint = customerBoxHeader.style.marginBottom = '1.5rem';
		onafterprint = contactBox.style.padding = '1.1rem';
		onafterprint = contactBoxHeader.style.marginBottom = '1rem';
	}

	const handleTaxRates = () => {
		setTaxRates(true);
	};

	const hideTaxPopup = () => {
		setTaxRates(false);
	}

	// Complete Exist Order
	const handleCompleteOrder = (event) => {
		setLoading(true);
		const submitBtn = document.getElementById('completeOrder');
		submitBtn.disabled = true;
		submitBtn.innerHTML = 'Completing Order...'
		axiosInstance
			.post(`/pgOrder/admin/completeDraftOrder/${completeExistOrder[0]}`)
			.then((res) => {
				navigate(`/ordersNew`)
				setLoading(false);
			})
			.catch((err) => {
				alert("Error in Completing Order.. Please try again");
				submitBtn.innerHTML = 'Complete Order';
				submitBtn.disabled = false;
				setLoading(false);
			})
	}

	const handleDeleteOrder = (Id) => {
		setLoading(true);
		document.getElementById('deleteOrder').innerHTML = 'Delete Order....';

		axiosInstance
			.delete(`/pgOrder/admin/draftOrder/${Id}`)
			.then((res) => {
				document.getElementById('deleteOrder').innerHTML = 'Order Deleted';;
				navigate('/draftOrdersNew');
				setLoading(false);
			})
	}

	const handleShowPopup = () => {
		setConfirmShowPopup(true)
	}


	const hideConfirmPopup = (event) => {
		if (event.target.innerText === 'Yes') {
			handleCompleteOrder();
		}
		setConfirmShowPopup(false)
	}

	const handleShowDeleteOrderPopup = () => {
		setDeleteConfirmPopup(true);
	}


	const hideDeleteOrderConfirmPopup = (event, orderId) => {
		if (event.target.innerText === 'Yes') {
			handleDeleteOrder(orderId);
		}
		setDeleteConfirmPopup(false);
	}

	const handleCopyDraftOrder = (event) => {
		event.target.disabled = true;
		setLoading(true);
		axiosInstance
			.post(`/orders/admin/copyDraftOrder/${id}`)
			.then((res) => {
				setLoading(false);
				navigate('/draftOrder');
			})
			.catch((err) => {
				setLoading(false);
				setError("Error in Copying Draft Order.. Please try again")
			})
	}

	useEffect(() => {
		if (id) {
			setLoading(true);
			if (updateToggle) {
				axiosInstance
					.get(`/pgOrder/admin/draftOrder/${id}`)
					.then((response) => {
						console.log(response.data.data)
						setDraftOrder(response?.data?.data?.draftOrder);
						let ti = 0;
						for (
							let i = 0;
							i < response?.data?.data?.draftOrder?.DoLineItems?.length;
							i++
						) {
							ti += response?.data?.data?.draftOrder?.DoLineItems[i]?.quantity;
						}


						// if (response?.data?.data?.draftOrder?.invoiceType) {
						// 	if (response?.data?.data?.draftOrder?.invoiceType === 'bill') {
						// 		document.getElementById('bill').checked = true;
						// 		document.getElementById('billBox').style.marginRight = '10px';
						// 		document.getElementById('challanBox').style.display = 'none';
						// 	} else {
						// 		document.getElementById('challan').checked = true;
						// 		document.getElementById('challanBox').style.marginRight = '10px';
						// 		document.getElementById('billBox').style.display = 'none';
						// 	}
						// } else {
						// 	document.getElementById('invoiceTypes').style.display = 'none';
						// }

						setTotalItems(ti);
						setDeliveryRemark(response?.data?.data?.draftOrder?.deliveryRemark);
						setPaymentRemark(response?.data?.data?.draftOrder?.paymentRemark);
						setCompleteExistOrder([response?.data?.data?.draftOrder?.draftOrderName]);
						setLoading(false);
					})
					.catch((err) => {
						console.log(err)
						setLoading(false);
						setError('Error in Fetching Draft Order.. Please try again');
					});
			}
		}
	}, [id, updateToggle])

	const handleInclGstTotal = (amount, tax) => {
        const total = parseFloat(amount);
        const gst = parseInt(tax);
        const result = total * (1 + (gst / 100));
        const countDecimal = countPlaces(result)
        return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? parseFloat(result).toFixed(2) : parseFloat(Math.trunc(result * 100) / 100).toFixed(2)
    }

    const rateInclGST = (amount, tax) => {
        const total = parseFloat(amount);
        const gst = parseInt(tax);
        const result = total * (1 + (gst / 100));
        const countDecimal = countPlaces(result)
        return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? parseFloat(result).toFixed(2) : parseFloat(Math.trunc(result * 100) / 100).toFixed(2)
    }

	const handlePercentage = (discount, lineItems) => {
		let result = 0;
		for (let product of lineItems) {
			if (product.appliedDiscount) {
				result = result + product.appliedDiscount.value * product.quantity
			} else if (product.listingPrice) {
				result = result + product.listingPrice * product.quantity
			}
		}
		const totalAmount = (discount / 100 * result)
		const countDecimal = countPlaces(totalAmount);
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? totalAmount : Math.trunc(totalAmount * 100) / 100
	}

	return (
		<Fragment>
			{loading ? (
				<div className={Classes.Spinner}>
					<Spinner animation="border" variant="light" />
				</div>
			)
				: null}
			<div className={Classes.container} id="color">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between'
					}}
					id='hideHeader'
				>
					<div
						className="d-flex align-items-center"
						style={{ gap: '1rem' }}
					>
						<div>
							<button
								type="button"
								className="btn btn-light"
								onClick={() => {
									navigate(-1);
								}}
							>
								<BiArrowBack />
							</button>
						</div>

						<div>
							<h6 className={Classes.draftOrderDetail}>
								{
									draftOrder?.draftOrderName ?
										draftOrder?.draftOrderName :
										draftOrder?.draftOrder?.name
								}
							</h6>
							<h6 className={Classes.draftOrderDetail}>
								{createdAt(draftOrder?.createdAt)}
							</h6>
						</div>
					</div>
					<div style={{ display: 'flex', gap: '8px' }}>
						<Permission service={services?.draftOrder} permission="create">
							<div>
								{/* <button
									type='btn'
									className={[Classes.Btn, Classes.sendPIButton].join(' ')}
									style={{ backgroundColor: 'black', color: '#fff' }}
									id='deleteOrder'
									onClick={(e) => handleCopyDraftOrder(e)}
								>
									Copy Order
								</button> */}
							</div>
						</Permission>
						<Permission service={services?.draftOrder} permission="delete">
							<div>
								<button
									type='btn'
									className={[Classes.Btn, Classes.sendPIButton].join(' ')}
									id='deleteOrder'
									onClick={(e) => handleShowDeleteOrderPopup(e)}
								>
									Delete Order
								</button>
							</div>
						</Permission>
					</div>
				</div>

				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
						<Alert.Heading>{error}</Alert.Heading>
						<p>Try reloading!</p>
					</Alert>
				) : null}

				{draftOrder.length !== 0 && (
					<div className={Classes.draftOrderName} id='orderNamePrint'>
						<div>
							<p style={{ textAlign: 'center' }}>
								Order Summary
							</p>
						</div>
						<div style={{ display: 'flex' }}>
							<h6 style={{ marginRight: 5 }}>Order Name:-</h6>
							<p>{draftOrder?.draftOrder?.name ? draftOrder?.draftOrder?.name : draftOrder?.draftOrderName}</p>
						</div>
					</div>
				)}

				<div className={Classes.InvoiceType} id='invoiceTypes'>
					<div style={{ display: 'flex' }} id='billBox'>
						<input
							type="radio"
							name="choose"
							id="bill"
							value="bill"
						/>
						<p style={{ marginLeft: 5 }}>Send on Bill</p>
					</div>

					<div className={Classes.ChallanBox} id='challanBox'>
						<input
							type="radio"
							name="choose"
							id="challan"
							value="challan"
						/>
						<p style={{ marginLeft: 5 }}>Send on Challan</p>
					</div>
				</div>

				<div
					className={Classes.mainContainer}
					style={{ marginTop: 30 }}
					id='container'
				>
					<div
						className={Classes.containBoxes}
						id='productContainer'
					>
						<div className={[Classes.Box, 'box'].join(' ')}>
							{draftOrder.length !== 0 ? (
								<div style={{ fontSize: 14, padding: 0 }}>
									<div className={Classes.Thead}>
										<div
											style={{ padding: '10px 20px', fontWeight: 600 }}
											className={Classes.ProductHeading}
											id='productHeading'
										>
											<p>Product</p>
										</div>

										<div
											className={[Classes.hideResponsive, 'discount'].join(' ')}
											style={{ width: '15%', padding: '10px 0px' }}
										>
											<p style={{ fontWeight: 600 }}>
												Discount
											</p>
											<p
												style={{ fontSize: 13 }}
												id='hideInclGst'
											>
												(Incl. GST)
											</p>
										</div>

										<div
											id='quantity'
											className={Classes.hideResponsive}
											style={{ width: '10%', padding: '10px 0px' }}
										>
											<p style={{ fontWeight: 600 }}>
												Quantity
											</p>
										</div>

										<div
											className={Classes.hideResponsive}
											id='subtotalHeader'
										>
											<p style={{ fontWeight: 600, paddingLeft: 5 }}>
												Subtotal
											</p>
											<p>(Excl. GST)</p>
										</div>

										<div
											id='totalHeader'
											className={Classes.hideResponsive}
										>
											<p style={{ fontWeight: 600, paddingLeft: 5 }}>
												Total
											</p>
											<p>(Incl. GST)</p>
										</div>
									</div>

									<div id='productContainer'>
										{draftOrder?.DoLineItems?.map((item, index) => (
											<div
												className={[Classes.containProduct, 'variantProduct'].join(' ')}
												id={item?._id}
												key={index}
											>
												<div
													className={[Classes.productDetail, 'draftOrderProducts'].join(' ')}
													id={index}
												>
													<div>
														<img
															src={
																item?.image ?
																	item?.image
																	: process.env.REACT_APP_IMAGE_COMMING_SOON}
															alt="icon"
															className={Classes.productImage}
														/>
													</div>

													<div className={Classes.VariantDetail}>
														<div>
															<p style={{ color: '#0d6efd' }}>
																{item?.productTitle}
															</p>
														</div>

														<div style={{ color: '#6d7175' }}>
															<p>
																{item?.variable1Value}{' '}
																{item?.variable2Value && `/ ${item?.variable2Value}`}{' '}
																{item?.variable3Value && `/ ${item?.variable3Value}`}
															</p>

															<p>{item?.sku}</p>
														</div>

														<div
															style={{ display: 'flex' }}
															className={[Classes.rateFontSize, 'rateExcl'].join(' ')}
														>
															<p className={Classes.newRateExclGst}>
																Rate Excl. GST
															</p>

															<div className={Classes.DiscountedUnitPrice}>
																<p>&#8377;</p>
																<p>
																{item.discountValue !== "0.00" ? parseFloat(item.discountValue).toFixed(2) : parseFloat(item.originalUnitPrice).toFixed(2)}
																</p>
															</div>

															<div>
																<p className={Classes.newCompareAtPrice}>
																	&#8377;{item?.mrp}
																</p>
															</div>
														</div>

														<div
															style={{ display: 'flex' }}
															className={[Classes.rateFontSize, 'rateExcl'].join(' ')}
														>
															<p className={Classes.newRateExclGst}>
																Rate Incl. GST
															</p>

															<div className={Classes.DiscountedUnitPrice}>
																<p>&#8377;</p>
																<p>
																{item?.discountValue !== "0.00" ?
                                                                        rateInclGST(item?.discountValue, item?.gstPercentage) :
                                                                        rateInclGST(item.originalUnitPrice, item?.gstPercentage)}
																</p>
															</div>

															<div>
																<p className={Classes.newCompareAtPrice}>
																	&#8377;{item?.mrp}
																</p>
															</div>
														</div>

														{/* In screen is smaller than 650px then show this box */}
														<div className={Classes.ShowResponsive}>
															<div
																className='percent'
																style={{ fontSize: 14, display: 'flex' }}
															>
																<div>
																	<p className={Classes.Text}>
																		Discount Incl. GST:
																	</p>
																</div>

																<div style={{ display: 'flex' }}>
																	<p>
																		{handleDiscount(
																			item.mrp,
																			item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
																			item.gstPercentage === null ?
																				18 : item.gstPercentage
																		)}
																	</p>
																	<p>%</p>
																</div>

																{item.discountTitle !== null && (
																	<div
																		className={[
																			Classes.hideDiscountTitle,
																			'hideDiscountTitle'
																		].join(' ')}
																	>
																		{item.discountTitle}
																	</div>
																)}
															</div>

															<div
																className='qty'
																style={{ display: 'flex' }}
															>
																<p className={Classes.Text}>
																	Quantity:
																</p>
																<p>{item?.quantity}</p>
															</div>

															<div
																className='total'
																id='discTotalPrint'
																style={{ display: 'flex' }}
															>
																<p className={Classes.Text}>
																	Subtotal:
																</p>
																&#8377;{parseFloat(item?.subTotal).toFixed(2)}
															</div>


															<div
																className='total'
																id='discTotalPrint'
																style={{ display: 'flex' }}
															>
																<p className={Classes.Text}>
																	Total:
																</p>
																&#8377;
																{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
															</div>
														</div>
													</div>
												</div>

												<div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')}>
													<div style={{ display: 'flex', justifyContent: 'center' }}>
														<p>
															{handleDiscount(
																item.mrp,
																item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
																item.gstPercentage === null ?
																	18 : item.gstPercentage
															)}
														</p>
														<p>%</p>
													</div>

													{item.discountTitle !== null && (
														<div
															className={[
																Classes.hideDiscountTitle,
																'hideDiscountTitle'
															].join(' ')}
														>
															{item.discountTitle}
														</div>
													)}

													{item.discountTitle !== null && (
														<div
															className='exclGSTPrint'
															style={{ fontSize: 12, display: 'none' }}
														>
															<p>(Incl. GST)</p>
														</div>
													)}
												</div>

												<div className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}>
													<p>{item?.quantity}</p>
												</div>

												<div
													className={[Classes.percent, 'total'].join(' ')}
													style={{ padding: '10px 0px', width: 80 }}
													id='discTotalPrint'
												>
													&#8377;
													{parseFloat(item?.subTotal).toFixed(2)}
												</div>

												<div
													className={[Classes.percent, 'total'].join(' ')}
													style={{ padding: '10px 0px', width: 80 }}
													id='discTotalPrint'
												>
													<p>
														&#8377;
														{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>

						<div
							className={[Classes.Box, 'box'].join(' ')}
							id='paymentBox'
						>
							<div className={Classes.containerBox}>
								<div className="d-flex justify-content-between mb-3">
									<h6 style={{ margin: 'auto 0px' }}>
										Payment
									</h6>
								</div>

								<div className={Classes.fontSize}>
									<div className={Classes.PaymentBoxSubtotal}>
										<div style={{ width: '25%' }}>
											<p>Subtotal</p>
										</div>

										<div style={{ width: '50%', display: 'flex' }}>
											<p style={{ marginRight: 3 }}>
												{totalItems}
											</p>
											<p>Items</p>
										</div>

										{draftOrder?.length !== 0 && (
											<p className={Classes.widthTextAlign}>
												&#8377;{parseFloat(draftOrder?.DoPaymentDetail?.subTotalPrice).toFixed(2)}
											</p>
										)}
									</div>

									<div>
										{draftOrder?.length !== 0 && (
											<div style={{ marginBottom: '1rem' }}>
												{draftOrder?.appliedDiscount && (
													<div className={Classes.flexWidth}>
														<p style={{ width: '25%' }}>
															Discount
														</p>

														<div style={{ width: '50%', display: 'flex' }}>
															<p style={{ marginRight: 3 }}>
																{draftOrder.DoPaymentDetail?.overallDiscountTitle}
															</p>
														</div>

														<p className={Classes.widthTextAlign}>
															{draftOrder?.DoPaymentDetail?.overallDiscountType === 'PERCENTAGE' ? (
																<p style={{ width: '25%', textAlign: 'end' }}>
																	-{draftOrder?.DoPaymentDetail?.overallDiscountValue}%
																</p>
															) : (
																<p style={{ width: '25%', textAlign: 'end' }}>
																	-&#8377;{draftOrder?.DoPaymentDetail?.overallDiscountValue}
																</p>
															)}
														</p>
													</div>
												)}
											</div>
										)}

										<div style={{ marginBottom: '1rem' }}>
											{draftOrder?.length !== 0 && (
												<div>
													{draftOrder?.shippingCost !== 0 && (
														<div className={Classes.flexWidth}>
															<p style={{ width: '25%' }}>
																Shipping
															</p>

															<p style={{ width: '50%' }}>
																{/* {draftOrder?.shippingtitle} */}
															</p>

															<div className={Classes.shippingAmount}>
																<p className={Classes.widthColor}>
																	+&#8377;{parseFloat(draftOrder?.shippingCost).toFixed(2)}
																</p>
																{/* {draftOrder?.shippingLine?.taxAmount && draftOrder?.shippingLine?.taxAmount !== 0 && (
																	<p className={Classes.widthColor} style={{ marginLeft: 5 }}>
																		(GST: 18%)
																	</p>
																)} */}
															</div>
														</div>
													)}
												</div>
											)}
										</div>

										<div style={{ marginBottom: '1rem', display: 'flex' }}>
											<p style={{ width: '25%' }} id='tax'>
												Tax
											</p>

											{draftOrder?.TaxLines ? (
												<p
													id='taxRate'
													className={Classes.showTaxRates}
													onClick={handleTaxRates}
												>
													Show tax rates
												</p>
											) : (
												<p style={{ width: '50%' }}></p>
											)}

											{draftOrder?.length !== 0 && (
												<p
													id='taxRateAmount'
													style={{ color: '#000' }}
													className={Classes.widthTextAlign}
												>
													&#8377;{parseFloat(draftOrder?.DoPaymentDetail?.totalTaxPrice).toFixed(2)}
												</p>
											)}
										</div>
									</div>

									<div
										className="d-flex justify-content-between"
										style={{ fontSize: 14, fontWeight: 600 }}
									>
										<p>Total</p>
										<p>
											&#8377;
											{draftOrder?.length !== 0 ?
												Math.round(draftOrder?.DoPaymentDetail?.totalPrice) :
												0.00}
										</p>
									</div>
								</div>
							</div>

							<div
								className={Classes.containerFooter}
								id='removeCreateOrderPrint'
							>
								{draftOrder?.length !== 0 && (
									<div>
										<div className={Classes.flexBetween}>

											{
												draftOrder?.DoCustomerDetails.length !== 0 && draftOrder?.DoCustomerAddresses.length >= 2 &&
												(
													<Permission service={services?.draftOrder} permission="create">
														<div>
															<button
																className={[Classes.Btn, Classes.completeOrderButton].join(' ')}
																id='completeOrder'
																onClick={(e) => handleShowPopup(e)}
															>
																Complete Order
															</button>
														</div>
													</Permission>
												)
											}

											<div className={Classes.flexBetween}>
												<div style={{ marginRight: 10 }}>
													<button
														className={[Classes.Button, Classes.deleteOrderButton].join(' ')}
														style={{ backgroundColor: '#6c943eeb' }}
														id='submitBtn'
														onClick={(e) => handleSendPI(e)}
													>
														Send PI
													</button>
												</div>
												<Permission service={services?.draftOrder} permission="update">
													<div>
														<button
															type='btn'
															className={[Classes.Button, Classes.editOrderButton].join(' ')}
														>
															Edit Order
														</button>
													</div>
												</Permission>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						{draftOrder?.length !== 0 && (
							<div id='TaxRatesPrint'>
								<TaxRatesPopup
									showTaxRate={showTaxRate}
									setTaxRates={setTaxRates}
									handleTaxRates={handleTaxRates}
									hideTaxPopup={hideTaxPopup}
									draftOrder={draftOrder}
									type={"draftOrders"}
								/>
							</div>
						)}

						{/* {draftOrder?.length !== 0 && (
							<div
								id='TaxRatesPrint'
								className={Classes.TaxRatesPrint}
							>
								<TaxRatesPrint
									draftOrder={draftOrder}
									taxRates={taxRates}
								/>
							</div>
						)} */}
					</div>

					<div
						className={Classes.asideContainer}
						id='asideContainer'
					>
						<Aside
							draftOrder={draftOrder}
							deliveryRemark={deliveryRemark}
							paymentRemark={paymentRemark}
						/>
					</div>
				</div>
				<div>
					<ConfirmPopup
						showConfirmPopup={showConfirmPopup}
						hideConfirmPopup={hideConfirmPopup}
					/>
				</div>

				<div>
					<DeleteOrderConfirmPopup
						showDeleteConfirmPopup={showDeleteConfirmPopup}
						hideDeleteOrderConfirmPopup={hideDeleteOrderConfirmPopup}
						draftOrderId={draftOrder?.length !== 0 && draftOrder?.draftOrderId}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default DraftOrderDetailPageNew;
