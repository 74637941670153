// component
import SvgColor from "../../components/svg-color";
import {
  People,
  LocalShipping,
  AddBusiness,
  LocalMall,
  Diversity3,
  AddShoppingCart,
  ShoppingCart,
  ShoppingCartCheckout,
  ProductionQuantityLimits,
  KeyboardReturn,
  CurrencyExchange,
  PriceChange,
  ArrowCircleDown,
  Assessment,
  DriveFolderUpload,
  UploadFile,
  Book,
  QrCode,
  DeviceHub,
  PointOfSale,
  Inventory,
  Assignment,
  MoveDown,
  Kitchen,
  Publish,
  AirportShuttle,
  TableView,
  Person,
  Discount,
  LocationCity,
  CurrencyRupee,
  Warehouse,
  LocationOn,
  Storefront,
  NotificationAdd,
  ViewCarousel,
  Dashboard
} from "@mui/icons-material";
// ----------------------------------------------------------------------

const navConfig = [
  {
    serviceName: "dashboard",
    permission: "read",
    path: "dashboard",
    title: "Dashboard",
    icon: <Dashboard />,
  },
  {
    serviceName: "users",
    permission: "read",
    path: "users",
    title: "Users",
    icon: <People />,
  },
  {
    serviceName: "customers",
    path: "customers",
    permission: "read",
    title: "Customers",
    icon: <Diversity3 />,
  },
  {
    serviceName: "products",
    title: "Products",
    permission: "read",
    icon: <LocalMall />,
    childs: [
      {
        serviceName: "products",
        path: "products",
        permission: "read",
        title: "Products List",
        icon: <TableView />,
      },
      {
        serviceName: "updatePricing",
        path: "updatePricing",
        permission: "update",
        title: "Update Pricing",
        icon: <PriceChange />,
      },
      {
        serviceName: "uploadImages",
        path: "uploadImages",
        permission: "create",
        title: "Upload Images",
        icon: <DriveFolderUpload />,
      },
      {
        serviceName: "uploadOtherMaster",
        path: "uploadOthersMaster",
        permission: "create",
        title: "Upload Others Master",
        icon: <UploadFile />,
      },
      {
        serviceName: "skuToQRC",
        path: "skuToQRC",
        permission: "read",
        title: "Sku To QRC",
        icon: <QrCode />,
      },
    ],
  },
  {
    serviceName: "orders",
    title: "Orders",
    permission: "read",
    icon: <AddBusiness />,
    childs: [
      {
        serviceName: "createOrder",
        permission: "create",
        path: "createOrder",
        title: "Create Order",
        icon: <AddShoppingCart />,
      },
      {
        serviceName: "draftOrder",
        path: "draftOrder",
        permission: "read",
        title: "Draft Order",
        icon: <ShoppingCartCheckout />,
      },
      // {
      //   serviceName: "draftOrders",
      //   path: "draftOrdersNew",
      //   permission: "read",
      //   title: "Draft Order v2",
      //   icon: <ShoppingCartCheckout />,
      // },
      {
        serviceName: "orders",
        path: "ordersNew",
        permission: "read",
        title: "Orders List",
        icon: <ShoppingCart />,
      },
      {
        serviceName: "deliveryShipping",
        path: "deliveryShipping",
        permission: "read",
        title: "Delivery Shipping",
        icon: <LocalShipping />,
      },
      // {
      //   serviceName: "dispatch",
      //   path: "dispatch",
      //   permission: "read",
      //   title: "Dispatch",
      //   icon: <AirportShuttle />,
      // },
      {
        serviceName: "deliveryRiders",
        path: "deliveryRiders",
        permission: "read",
        title: "Delivery Riders",
        icon: <LocalShipping />,
      },
      {
        serviceName: "returnOrder",
        path: "returnsNew",
        permission: "read",
        title: "Returns",
        icon: <KeyboardReturn />,
      },
      {
        serviceName: "inwardReturns",
        path: "inwardReturns",
        permission: "read",
        title: "Inward Returns",
        icon: <LocalShipping />,
      },
      {
        serviceName: "cancelOrder",
        path: "cancelsNew",
        permission: "read",
        title: "Cancels",
        icon: <ProductionQuantityLimits />,
      },
      {
        serviceName: "refundOrder",
        path: "refundsNew",
        permission: "read",
        title: "Refunds",
        icon: <CurrencyExchange />,
      },
      // {
      //   serviceName: "deliveryBoy",
      //   path: "deliveryBoy",
      //   permission: "read",
      //   title: "Delivery",
      //   icon: <LocalShipping />,
      // },
    ],
  },
  {
    serviceName: "purchaseOrders",
    title: "Purchase Orders",
    permission: "read",
    icon: <AddBusiness />,
    childs: [
      {
        serviceName: "purchaseOrders",
        path: "createPurchaseOrder",
        permission: "create",
        title: "Create P.O",
        icon: <AddShoppingCart />,
      },
      {
        serviceName: "draftPurchaseOrders",
        path: "draftPurchaseOrders",
        permission: "read",
        title: "Draft P.O",
        icon: <ShoppingCartCheckout />,
      },
      {
        serviceName: "purchaseOrders",
        path: "purchaseOrders",
        permission: "read",
        title: "Purchase Orders",
        icon: <ShoppingCart />,
      },
      {
        serviceName: "inboundPurchaseOrders",
        path: "inboundPurchaseOrders",
        permission: "read",
        title: "Inbound P.Os",
        icon: <ArrowCircleDown />,
      },
      {
        serviceName: "inwardPurchaseOrders",
        path: "inwardPurchaseOrders",
        permission: "read",
        title: "Inward Po",
        icon: <LocalShipping />,
      },
    ],
  },
  {
    serviceName: "scheme",
    title: "Scheme",
    permission: "read",
    icon: <Discount />,
    childs: [
      {
        serviceName: "scheme",
        path: "schemeDashboard",
        permission: "read",
        title: "Dashboard",
        icon: <Assessment />,
      },
      {
        serviceName: "scheme",
        path: "schemes",
        permission: "read",
        title: "Schemes",
        icon: <AddBusiness />,
      },
    ],
  },
  {
    serviceName: "fieldSales",
    title: "Field Sales",
    permission: "read",
    icon: <PointOfSale />,
    childs: [
      {
        serviceName: "salesManager",
        path: "manager",
        title: "Manager",
        permission: "create",
        icon: <Person />,
      },
      {
        serviceName: "fieldSales",
        path: "myTasks",
        title: "My Tasks",
        permission: "create",
        icon: <Assignment />,
      },
      {
        serviceName: "fieldSales",
        path: "myCustomers",
        title: "My Customers",
        permission: "create",
        icon: <Diversity3 />,
      },
      {
        serviceName: "fieldSales",
        path: "reports",
        title: "Report Dashboard",
        permission: "create",
        icon: <Assessment />,
      },
      {
        serviceName: "RealtimeLocation",
        permission: "read",
        path: "realtimeLocation",
        title: "Agent Location",
        icon: <LocationOn />,
      },
    ],
  },
  {
    serviceName: "reportService",
    path: "productsCreated",
    permission: "read",
    title: "Report Service",
    icon: <Assessment />,
  },
  {
    serviceName: "inventory",
    title: "Inventory",
    permission: "read",
    icon: <Inventory />,
    childs: [
      {
        serviceName: "locations",
        path: "locations",
        permission: "read",
        title: "Locations",
        icon: <LocationCity />,
      },
      {
        serviceName: "interWHInventoryMove",
        path: "createInterWHInventoryMoves",
        permission: "create",
        title: "W/H Inventory Move",
        icon: <Warehouse/>,
      },
      // {
      //   serviceName: "inventory",
      //   path: "inventory",
      //   permission: "create",
      //   title: "Inventory Upload",
      //   icon: <Publish />,
      // },
      {
        serviceName: "inventoryView",
        path: "inventoryView",
        permission: "read",
        title: "Inventory View",
        icon: <Assignment />,
      },
      {
        serviceName: "inventoryMove",
        path: "inventoryMove",
        permission: "read",
        title: "Inventory Move",
        icon: <MoveDown />,
      },
      {
        serviceName: "inventoryAdjustments",
        path: "inventoryAdjustments",
        permission: "read",
        title: "Inventory Adjustments",
        icon: <MoveDown />,
      },
      {
        serviceName: "bins",
        path: "bins",
        permission: "read",
        title: "Bins",
        icon: <Kitchen />,
      },
      {
        serviceName: "skuTransactions",
        path: "skuTransactions",
        permission: "read",
        title: "SKU Transactions",
        icon: <MoveDown />,
      },
      {
        serviceName: "orderAllocations",
        path: "orderAllocations",
        permission: "read",
        title: "Order Allocations",
        icon: <ShoppingCartCheckout />,
      },
    ],
  },
  {
    serviceName: "blog",
    path: "blog",
    permission: "read",
    title: "Blog",
    icon: <Book />,
  },
  {
    serviceName: "pushNotifications",
    path: "pushNotifications",
    permission: "read",
    title: "Push Notifications",
    icon: <NotificationAdd />,
  },
  // {
  //   serviceName: "affiliates",
  //   path: "affiliates",
  //   permission: "read",
  //   title: "Affiliates",
  //   icon: <DeviceHub />,
  // },
  // {
  //   serviceName: "fieldSales",
  //   path: "fieldSales",
  //   permission: "read",
  //   title: "Field Sales",
  //   icon: <PointOfSale />,
  // }
  {
    serviceName: "affiliates",
    title: "Affiliates",
    permission: "read",
    icon: <DeviceHub />,
    childs: [
      {
        serviceName: "affiliates",
        path: "/affiliates",
        permission: "read",
        title: "Dashboard",
        icon: <DeviceHub />,
      },
      {
        serviceName: "commissions",
        path: "/commissions",
        permission: "read",
        title: "Commissions",
        icon: <CurrencyRupee />,
      },
      {
        serviceName: "createCommission",
        path: "/createCommission",
        permission: "create",
        title: "Create Commission",
        icon: <CurrencyRupee />,
      },
      {
        serviceName: "affiliateCommissions",
        permission: "read",
        path: "affiliateCommissions",
        title: "Affiliate Commissions",
        icon: <CurrencyRupee />,
      },
    ],
  },
  {
    serviceName: "estimates",
    permission: "read",
    path: "estimates",
    title: "Estimates",
    icon: <People />,
  },
  {
    serviceName: "dynamicBanners",
    permission: "read",
    path: "dynamicBanners",
    title: "Dynamic Banners",
    icon: <ViewCarousel />,
  },
];

export default navConfig;
