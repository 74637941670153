import React, {Fragment, useEffect, useState} from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import Spinner from '../../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../css/styles.css';
import { snakeToTitleCase } from '../../../../utility/textFormatting.js';

const CustomerModal = (props)=> {
    const [loading,setLoading] = useState(false);
    const [salesTeam,setSalesTeam] = useState([]);
    const [affiliateData,setAffiliateData] = useState([]);
    const [affiliate,setAffiliate] = useState('');
    const [salesUser,setSalesUser] = useState('');
    const [preSalesUser,setPreSalesUser] = useState('');
    const [customerType,setCustomerType] = useState('');
    const [customerRole,setCustomerRole] = useState('');
    const [email,setEmail] = useState('');
    const [states,setStates] = useState([]);
    const [customerRoles, setCustomerRoles] = useState([]);
    const [cities,setCities] = useState([]);
    const [areas,setAreas] = useState([]);

    const { 
        setDetail,
        fetchCustomerRoleDB,
        errorMessage,
        setErrorMessage,
        gstNO,
        setGstNO,
        num,
        setNum,
        displayName,
        setDisplayName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        businessName,
        setBusinessName,
        popUpType,
        fetchCustomers,
        endpoint,
        selectedState,
        setSelectedState,
        setCity,
        city,
        setArea,
        area
    } = props;

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleBusinessName = (event) => {
        setBusinessName(event.target.value)
    }

    useEffect(() => {
        const businessDetail = businessName !== '' ? businessName : `${firstName} ${lastName}`;
        setDisplayName(businessDetail)
    }, [firstName, lastName, businessName]);

    useEffect(() => {
        axiosInstance
            .get('/admin/salesTeam')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data?.users) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setSalesTeam(arr);
            })
    },[]);

    useEffect(() => {
        axiosInstance
            .get('/others/getAffiliateData')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data) {
                    arr.push({label:ele?.displayName, Id:ele?._id})
                }
                setAffiliateData(arr);
            })
    },[]);

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({id:stateName?._id,label:stateName?.state})
                }
                setStates(states)
            })
    },[])

    useEffect(() => {
        axiosInstance
            .get('/user/admin/customerRoles')
            .then((res) => {
                let roles = res?.data?.data
                roles = roles.map((role) => {
                    return role?.title
                })
                setCustomerRoles([...roles])
            })
    }, [])


    const handleGstField = (event) => {
        const result=  event.target.value.replace(/[^\w\s]/gi, "")
        setGstNO(result)
    }

    const handleNumChange = event => {
        const limit = 10;
        setNum(event.target.value.slice(0, limit));
    };

    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn')
        button.innerHTML = 'Save Customer';
        const roles = {}
        customerRoles?.length &&
            customerRoles.forEach((role) => {
                const roleInTitleCase = snakeToTitleCase(role)
                const roleInSnakeCase = role
                console.log({ roleInTitleCase, roleInSnakeCase })
                roles[roleInTitleCase] = roleInSnakeCase
            })
        const data = {
            email: event.target.email.value,
            phone:`+91${event.target.phone.value}`,
            firstName: event.target.first.value,
            lastName: event.target.last.value ? event.target.last.value : undefined,
            gstNumbers: event.target.gst.value ? event.target.gst.value : undefined,
            customerRole: document.querySelector("#customerRole").value ? roles[document.querySelector("#customerRole").value] : undefined,
            customerType: event.target.customerType.value ? event.target.customerType.value : undefined,
            businessName: event.target.businessName.value ? event.target.businessName.value : undefined,
            affiliateUser : affiliate ? affiliate : undefined,
            salesUser: salesUser ? salesUser : undefined,
            preSalesUser: preSalesUser ? preSalesUser: undefined,
            state: selectedState ? selectedState : undefined,
            city: city ? city : undefined,
            area: event.target.area.value ? event.target.area.value : undefined
        }

        axiosInstance
            .post(endpoint,{...data})
            .then((res) =>{
                const form = document.getElementById('form');
                form.reset();
                props.onHideModal();
                if (!popUpType) {
                    setDetail([res.data.data]);
                } else {
                    fetchCustomers();
                }
                setLoading(false);
                setErrorMessage('');
                setAffiliate('');
                setPreSalesUser('');
                setSalesUser('');
                setCustomerRole('');
                setCustomerType('');
                setEmail('');
                setSelectedState('');
                setCity('');
                setArea('');
                button.innerHTML = 'Customer Created';
            }).catch((err) => {
                button.innerHTML = 'Customer Create';
                setLoading(false);

                if(err.response.data.hasOwnProperty('keyPattern')){
                    const existData = Object.keys(err.response.data.keyPattern)
 
                    if(existData[0] === 'email'){
                        setErrorMessage('This email already exists, Please enter another email')
                    }else if( existData[0] === 'phone'){
                        setErrorMessage('This number already exists, Please enter another number')
                    }   
                }else{
                    if(err.response.data.hasOwnProperty('message')){
                        if(err.response.data.message === 'Error creating user on Shopify'){
                            setErrorMessage('Please try to provide different input, If Issue Still Persist Then Contact Tech Team')   
                        }else{
                            setErrorMessage(err.response.data.message)
                        }
                    }
                }
            })
    }


    const capitalName = (text) => {
        const result1 = text.replaceAll('_', ' ');
        const arr = result1.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
		return str2;
	}


    document.addEventListener('mouseup', function(e) {
        let container = document.getElementById('numberOutline');
        if(container !== null){
            if (!container.contains(e.target)) {
                container.style.border = '1px solid rgb(206, 212, 218)';
            }
        }
    });

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    const handleValidGst = (event) =>{
        let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if(event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    const arr = [];
    for (let ele of fetchCustomerRoleDB) {
        if (endpoint === '/user/admin/affiliates') {
            if (ele?.title !== 'endUser'){
                arr.push({label:capitalName(ele?.title)})
            }
        } else {
            arr.push({label:capitalName(ele?.title)})
        }
    }

    const handleEmail = (event) => {
        if (event.target.value) {
            setEmail(event.target.value)
        }
    }

    useEffect(()=> {
        if (selectedState) {
            axiosInstance
            .get(`/others/cities/${selectedState}`)
            .then((response) => {
                const cities = [];
                for (let cityName of response.data.data) {
                    cities.push({id:cityName?._id,label:cityName?.city})
                }
                setCities(cities)
            })
        }
    },[selectedState]);

    useEffect(()=> {
        if (city) {
            axiosInstance
            .get(`/others/areas/${city}`)
            .then((response) => {
                const areas = [];
                for (let areaName of response.data.data) {
                    areas.push({id:areaName?._id,label:areaName?.area})
                }
                setAreas(areas);
            })
        }
    },[city]);

    return (
        <Fragment>
            {props.onShowModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'8%',bottom:'11%',zIndex:'9999'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>{endpoint === '/user/admin/affiliates' ? "Create a New Affiliate": 'Create a new Customer'}</p>
                    </div>
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{
                            overflowY:'auto',
                            height:'72%'
                        }}
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>

                        <div style={{padding:20}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div style={{ width:'29%' }}>
                                    <TextField
                                        required
                                        id='firstName'
                                        name="first"
                                        label="First Name"
                                        placeholder='First Name'
                                        autoComplete='off'
                                        onChange={handleFirstNameChange}
                                        sx={{ width: '100%',"& label": {top:firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'29%' }}>
                                    <TextField
                                        id='lastName'
                                        name="last"
                                        label="Last Name"
                                        placeholder='Last Name'
                                        autoComplete='off'
                                        onChange={handleLastNameChange}
                                        sx={{ width: '100%',"& label": {top: lastName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'40%' }}>
                                    <TextField
                                        id='displayName'
                                        name="displayName"
                                        label="Display Name"
                                        placeholder='Display Name'
                                        value={businessName ? businessName :firstName ? firstName + " " + lastName: ''}
                                        autoComplete='off'
                                        disabled={true}
                                        sx={{ width: '100%',"& label": {top: firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{ width:'32%' }}>
                                    <TextField 
                                        required
                                        id='phoneNumber'
                                        name="phone"
                                        type="number"
                                        label="Phone Number"
                                        maxLength={10}
                                        onChange={handleNumChange}
                                        onBlur={(e) => handleMinLength(e)}
                                        placeholder='Enter Your 10 Digit Phone Number'
                                        value={num ? num : ''}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: num ? "0%" : "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <TextField 
                                        required
                                        id='email'
                                        name="email"
                                        label="Email"
                                        onBlur={handleEmail}
                                        placeholder='Enter Your Email'
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: email ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <div>
                                        <TextField 
                                            id='gstNumber'
                                            name="gst"
                                            label="GST Number"
                                            minLength={15}
                                            maxLength={15}
                                            inputProps={{ maxLength: 15 }}
                                            placeholder='Enter valid Gst Number'
                                            value={gstNO ? gstNO: ''}
                                            onChange={handleGstField}
                                            onBlur={handleValidGst}
                                            autoComplete='off'
                                            sx={{ width: '100%',"& label": {top: gstNO ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                        />
                                    </div>

                                    <div 
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>

                            <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                <p>Please enter a valid 10 digit mobile number</p>
                            </div>


                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{width:'32%'}}>
                                    <TextField
                                        id='businessName'
                                        name="businessName"
                                        label="Business Name"
                                        placeholder='Enter Your Business Name'
                                        onChange={handleBusinessName}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top:businessName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                { customerRoles.length && <div style={{width:'32%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="customerRole"
                                        options={[...customerRoles.map((role) => { return { "label": snakeToTitleCase(role) } })]}
                                        name='customerRole'
                                        sx={{width: '100%',"& label": {top: customerRole ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setCustomerRole(newValue.label);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Customer Roles"/>}
                                    />
                                </div>}

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        disablePortal
                                        id="customerType"
                                        name="customerType"
                                        options={[{label:'B2B'},{label:'B2C'}]}
                                        sx={{width: '100%',"& label": {top: customerType ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setCustomerType(newValue.label);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Customer Type" />}
                                    />
                                </div>
                            </div>

                            
                            <div className={Classes.ContentBox} style={{marginTop:25}}>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        name='state'
                                        options={states}
                                        ListboxProps={{sx: { fontSize: 14 }}}
                                        sx={{
                                            width: '100%',
                                            "& label": {top: selectedState ? "0%": "-16%",fontSize:14},
                                            "& .MuiInputBase-input": {height:'12px'},
                                            "& .MuiOutlinedInput-root":{padding:'6px'},
                                            "& .MuiAutocomplete-input" : {fontSize:14}
                                        }}
                                        onChange={(event, newValue) => {setSelectedState(newValue.id)}}
                                        renderInput={(params) => <TextField {...params} label="States"/>}
                                    />
                                </div>

                                <div style={{width:'32%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="city"
                                        name='city'
                                        options={cities}
                                        disabled={!selectedState ? true: false}
                                        sx={{width: '100%',"& label": {top: city ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setCity(newValue.id ? newValue.id : event.target.value);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="City"/>}
                                    />
                                </div>

                                <div style={{width:'33%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="area"
                                        name='area'
                                        options={areas}
                                        disabled={!city ? true: false}
                                        sx={{width: '100%',"& label": {top: area ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onBlur={(event, newValue) => {setArea(newValue ? newValue.id : event.target.value )}}
                                        renderInput={(params) => <TextField {...params} label="Area"/>}
                                    />
                                </div>
                            </div>

                            <div 
                                className={Classes.ContentBox} 
                                style={{marginTop:25,justifyContent: endpoint === '/user/admin/affiliates' ? 'flex-start': 'space-between'}}
                            >
                                {endpoint !== '/user/admin/affiliates' && (
                                    <div style={{width:'32%'}}>
                                        <Autocomplete
                                            disablePortal
                                            id="affiliate"
                                            name='affiliate'
                                            options={affiliateData}
                                            ListboxProps={{sx: { fontSize: 14 }}}
                                            sx={{
                                                width: '100%',
                                                "& label": {top: affiliate ? "0%": "-16%",fontSize:14},
                                                "& .MuiInputBase-input": {height:'12px'},
                                                "& .MuiOutlinedInput-root":{padding:'6px'},
                                                "& .MuiAutocomplete-input" : {fontSize:14}
                                            }}
                                            onChange={(event, newValue) => {
                                                setAffiliate(newValue.Id);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Select Affiliate"/>}
                                        />
                                    </div>
                                )}

                                {salesTeam.length !== 0 && (
                                    <div style={{width:'32%'}}>
                                        <Autocomplete
                                            disablePortal
                                            id="salesUser"
                                            options={salesTeam}
                                            sx={{width: '100%',"& label": {top: salesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                            onChange={(event, newValue) => {
                                                setSalesUser(newValue.Id);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Sales User"/>}
                                        />
                                    </div>
                                )}

                                <div 
                                    style={{
                                        width: endpoint === '/user/admin/affiliates' ? '32%' : '33%',
                                        marginLeft:endpoint === '/user/admin/affiliates' ? 10: 0
                                    }}
                                >
                                    <Autocomplete
                                        disablePortal
                                        id="preSalesUser"
                                        options={salesTeam}
                                        sx={{width: '100%',"& label": {top: preSalesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setPreSalesUser(newValue.Id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Pre Sales User"/>}
                                    />
                                </div>
                            </div>
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'11%',
                                position:'fixed',
                                boxShadow:'none',
                                borderTop: 0
                            }}
                        >
                            <button 
                                onClick={props.onHideModal}
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CREATE
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default CustomerModal;