import { useEffect, useState } from 'react';
import axiosInstance from '../../utility/axios-instance';
import SnackBar from '../SnackBar';
import Classes from './style.module.css';
import Button from '@mui/material/Button';
import Permission from '../../pages/auth/permissions';
import { services } from '../../utility/checkRoleBasedPermission';
import Spinner from '../spinner';
import NewPagination from '../newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Chip from '@mui/material/Chip';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const { dataPosition, endpoint, rowId, columns, handleClickOpen, buttonFields, showUploadDocPopup, reRender } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fieldValue = (row) => {
        let arr = []
        for (let field of buttonFields) { 
            arr.push(row[field]) 
        }
        return arr;
    }

    const ButtonComponent = ({ row, text }) => {
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform:'capitalize',fontSize:14,padding:'2px 10px' }}
                    onClick={(e) => handleClickOpen(fieldValue(row))}
                >{text ? text : "Generate"}</Button>
            </div>
        )
    }



    const fetchData = async (page) => {
        setLoading(true)
        // let statusQuery = status.join(',');
        const params = {
            // sort,
            searchFor,
            // filter: `${filter?.value}` ? filter : undefined,
            page,
            // limit,
            // status: statusQuery || undefined
        };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor, reRender]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            {column?.field !== 'binWiseAllocation' && (
                                <>
                                    <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                                    {column?.field === 'Picklist' || column?.field === 'QC'|| column?.field === 'Pack' || column?.field === 'Dispatch' || column?.field === 'Mark Delivered' || column?.field === 'Return On Hold' ? (
                                        <>
                                            {(column?.field === 'Dispatch' || column?.field === 'Mark Delivered' || column?.field === 'Return On Hold') ? (
                                                <>
                                                    {(column?.field === 'Dispatch' && row?.status === "Packed") || 
                                                    (column?.field === 'Mark Delivered' && row?.status !== 'Delivered') ||
                                                    (column?.field === 'Return On Hold' && row?.whStatus !== 'Put Away') ? (
                                                        <ButtonComponent row={row} text={column?.field}/>
                                                    ): (
                                                        <p>{row?.status}</p>
                                                    )}
                                                </>
                                            ):(
                                                <ButtonComponent row={row} text={column?.field}/>
                                            )}
                                        </>
                                    ): (
                                        <>
                                            {column?.field === 'documentsAttached' ? (
                                                <>
                                                    {row?.documentsAttached?.length > 0 ? (
                                                        <div style={{ display: 'flex', gap: "5px" }}>
                                                            {row?.documentsAttached?.slice(0, 2)?.map((doc) => (
                                                                <Chip size="small" icon={<FilePresentIcon />} clickable label={decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 6) + "..."} component="a" href={doc} />
                                                            ))}
                                                            {row?.documentsAttached?.length > 2 ? (
                                                                <Tooltip title={
                                                                    <div style={{ gap: "20px" }}>
                                                                        {row?.documentsAttached?.slice(2)?.map((doc, index) => (
                                                                            <Link color="inherit" href={doc}>{decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 8)} , </Link>
                                                                        ))}
                                                                    </div>
                                                                } arrow>
                                                                    <Chip size="small" label={`+ ${row?.documentsAttached?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                                                                </Tooltip>
                                                            ) : null}
                                                            <Permission service={services?.dispatch} permission="update">
                                                                {row?.documentsAttached?.length < 5 ? (
                                                                    <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showUploadDocPopup(e, row?.dispatchId)}>
                                                                        Upload
                                                                    </Button>
                                                                ) : null}
                                                            </Permission>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Permission service={services?.dispatch} permission="update">
                                                                <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showUploadDocPopup(e, row?.dispatchId)}>
                                                                    Upload
                                                                </Button>
                                                            </Permission>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {column?.field === 'doc' || column?.field === 'shipmentDoc' ? (
                                                        row?.doc?.length !== 0 &&  
                                                        <Chip size="small" icon={<FilePresentIcon />}  
                                                        clickable label={row?.[column?.field === 'doc' ? "picklist_number" : 'dispatchId']} 
                                                        component="a" href={row?.[column?.field === 'doc' ? "doc": 'shipmentDoc'][0]} target='_blank' />
                                                    ): (
                                                        <>
                                                            {column?.field === 'Box Dimension' ? (
                                                                <p>
                                                                    {row.length && `${row.length}${row.lengthUnit} x ${row.breadth}${row.lengthUnit} x ${row.height}${row.lengthUnit}`}
                                                                </p>
                                                            ) : (
                                                                <p>{row[column?.field]}</p>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;