import { Fragment, useState, useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';

const SalesTargetDialog = (props)=> {
    const [loading,setLoading] = useState(false);
    const [salesTeam,setSalesTeam] = useState([]);

    const {
        salesUser,
        setSalesUser,
        open,
        handleClose,
        target,
        setTarget
    } = props;


    useEffect(() => {
        axiosInstance
            .get('/fieldSalesVisit/allSalesUsers')
            .then((res) => {
                const arr = [];
                for (let ele of res.data.data) {
                    arr.push({label:ele?.name,Id:ele?._id})
                }
                setSalesTeam(arr);
            })
    },[]);


    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('updateCustomer')
        button.innerHTML = 'Updating..';

        axiosInstance
            .post(`/admin/${salesUser}`,{
                monthlyTarget: event.target.monthlyTarget.value ? parseInt(event.target.monthlyTarget.value) : undefined
            })
            .then((res) =>{
                handleClose();
                setLoading(false);
                setSalesUser('');
                button.innerHTML = 'UPDATE';
            }).catch((err) => {
                button.innerHTML = 'UPDATE';
                setLoading(false);
            })
    }

    const handleSalesUser = (customer) => {
        if (customer !== null) {
            setSalesUser(customer.Id);
        } else {
            setSalesUser('');
        }
    }

    return (
        <Fragment>
            {open && (
                <div 
                    className={Classes.modal} 
                    style={{top:'15%',bottom:'15%',left:'30%',right:'30%'}}
                >
                    <div className={Classes.BoxContent} style={{padding:'15px 25px'}}>
                        <h6 style={{fontSize:17}}>Sales User Target</h6>
                    </div>

                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form'
                    >
                        <div style={{padding:"10px 20px 0px"}}>
                            {salesTeam.length !== 0 && (
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="salesUser"
                                    options={salesTeam}
                                    ListboxProps={{sx: { fontSize: 14 }}}
                                    sx={{width: '100%',"& label": {top: salesUser ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                    onChange={(event,newValue) => handleSalesUser(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Sales User" required/>}
                                />
                            )}

                            <div style={{marginTop:20}}>
                                <TextField
                                    required
                                    id='monthlyTarget'
                                    name="monthlyTarget"
                                    type="number"
                                    label="Monthly Target"
                                    autoComplete='off'
                                    sx={{ width: '100%',"& label": {top: target ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    onChange={(event) => setTarget(event.target.value)}
                                />
                            </div>
                        </div>

                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'15%',
                                position:'fixed',
                                width:'40%',
                                boxShadow: "none",
                                border: 0
                            }}
                        >
                            <button 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleClose}
                            >
                                CANCEL
                            </button>


                            <button 
                                type='submit'
                                id='updateCustomer' 
                                className={Classes.UpdateSalesUserOfCustomer}
                                disabled={salesUser ? false : true}
                            >
                                UPDATE
                            </button>
                        </div>
                    </form>

                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}

export default SalesTargetDialog;