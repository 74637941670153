import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { InputAdornment, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axiosInstance from '../../../../utility/axios-instance';
import Chip from '@mui/material/Chip';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CategoryIcon from '@mui/icons-material/Category';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SchemePopup({ open, handleClose, name, setName, target, setTarget, unit, setUnit, skus, setSkus, fetchSchemes, operator, setOperator, value, setValue, setSnackBar, type, id }) {

    const handleSkuSearch = async (e) => {
        const params = {
            value: value,
            operator: operator
        }

        await axiosInstance
            .get(`/scheme/skusByFilter`, { params })
            .then((res) => {
                setSkus(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
                setSkus([]);
            });
    }

    const handleCreateScheme = async () => {
        let data = {
            scheme_name: name,
            unit: unit,
            minimum_target: parseFloat(target),
            sku: skus
        }

        await axiosInstance
            .post(`/scheme/createScheme`, data)
            .then((res) => {
                const successMessage = "Scheme Created Successfully";
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err);

            });
        handleClose();

    }

    const handleEditScheme = async () => {
        let data = {
            scheme_name: name,
            unit: unit,
            minimum_target: parseFloat(target),
            sku: skus
        }

        await axiosInstance
            .put(`/scheme/scheme/${id}`, data)
            .then((res) => {
                const successMessage = "Scheme Updated Successfully";
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err);
            });
        handleClose();
    }

    const handleDeleteScheme = async () => {

        await axiosInstance
            .delete(`/scheme/scheme/${id}`)
            .then((res) => {
                const successMessage = "Scheme Deleted Successfully";
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                console.log(err);
            });
        handleClose();
    }


    useEffect(() => {
        const delay = 600
        const debounce = setTimeout(() => {
            handleSkuSearch()
        }, delay);
        return () => clearTimeout(debounce)
    }, [value, operator])


    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                fullWidth={true}
            // maxWidth={maxWidth}
            >
                <div style={{ display: 'flex', justifyContent: 'end' }}>

                </div>
                <DialogTitle>{"Create Scheme"}</DialogTitle>
                <DialogContent>
                    <div>
                        <div style={{ margin: '15px 0px' }}>
                            <TextField
                                id="name"
                                type="text"
                                value={name}
                                label="Name"
                                onChange={(e) => setName(e.target.value)}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '15px 0px', gap: '8px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={unit}
                                    size='small'
                                    label="Unit"
                                    onChange={(e) => {
                                        setUnit(e.target.value);
                                    }}
                                    id="operator"
                                >
                                    <MenuItem value={"Quantity"}>Quantity</MenuItem>
                                    <MenuItem value={"Value"}>Value</MenuItem>

                                </Select>
                            </FormControl>
                            <TextField
                                id="target"
                                type="number"
                                label="Minimum Target"
                                value={target}
                                onChange={(e) => {
                                    setTarget(e.target.value);
                                }}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {unit === "Value" ? <CurrencyRupeeIcon /> : <CategoryIcon />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', margin: '15px 0px', gap: '8px' }}>
                            <TextField
                                id="search"
                                type="search"
                                label="Search Sku"
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                }}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '100%' }}

                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={operator}
                                    size='small'
                                    label="Condition"
                                    onChange={(e) => {
                                        setOperator(e.target.value);
                                    }}
                                    id="operator"
                                >
                                    <MenuItem value={"custom"}>Custom</MenuItem>
                                    <MenuItem value={"startWith"}>Starts With</MenuItem>
                                    <MenuItem value={"isEquals"}>Is Equal To</MenuItem>
                                    <MenuItem value={"brand_and_l1"}>Brand & L1</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {skus.length > 0 ? (
                            <div>
                                {skus.slice(0, 10).map((sku) => (
                                    <Chip
                                        label={sku}
                                        variant="outlined"
                                        sx={{ margin: '0.5rem' }}
                                    />
                                ))}
                                {skus?.length > 10 ? (
                                    <Chip size="small" label={`+ ${skus?.length - 10} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                    <div>
                        {type === "edit" ? (
                            <IconButton onClick={(e) => handleDeleteScheme(e)}>
                                <DeleteIcon />
                            </IconButton>
                        ) : null}
                    </div>
                    <div>
                        {type === "edit" ? (
                            <Button onClick={(e) => handleEditScheme(e)}>Update</Button>
                        ) : (
                            <Button onClick={(e) => handleCreateScheme(e)}>Create</Button>
                        )}
                        <Button style={{ color: "red" }} onClick={handleClose}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}