import React, {Fragment} from "react";
import Classes from './css/create.module.css';


const TaxRatesPrint = (props)=> {
    const { 
        taxRates,
        showProduct,
        showCustomerDetail,
        addressArray,
        customerIDAddress
    } = props;

    const handleGst = (gst) => {
        return gst/2
    }

    const handleTaxAmount = (amount) => {
        let splitAmount = (amount/2);
        let checkDecimal = countPlaces(splitAmount)
        return checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? splitAmount : Math.trunc(splitAmount*100)/100
    }

    const handleFirst2Decimal = (amount) => {
        let checkDecimal = countPlaces(amount)
        return checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? amount : Math.trunc(amount*100)/100
    }

    const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1]? b[1].length : 0;
	}

    return (
        <Fragment>
                <div className={[Classes.Box,'box'].join(' ')}>
                    <div style={{padding:'10px 20px',borderBottom:'1px solid lightgray'}}>
                        <h6>Tax rates</h6>
                    </div> 

                    {showProduct.length !== 0 &&  (
                        <div style={{padding:'10px 20px',fontSize:'13px'}}>
                            {taxRates?.map((taxes,index) => (
                                <div style={{padding:'5px'}} key={index}>
                                    <div>
                                        <div>
                                            <p style={{marginRight:5}}>
                                                HSN Code ({taxes?.hsnCode})
                                            </p>
                                        </div>
                                        <div>
                                            {showCustomerDetail.length !== 0  && addressArray.length !== 0 && customerIDAddress.length ? (
                                                <div>
                                                    {customerIDAddress[0].province === 'West Bengal' ? (
                                                        <>
                                                            <div className={Classes.TaxRatePrintFlexBetween}>
                                                                <p style={{padding:'0px 5px'}}>
                                                                    CGST({handleGst(taxes?.gst)}%)
                                                                </p>
                                                                <p>&#8377;{handleTaxAmount(taxes?.tax)}</p>
                                                            </div>

                                                            <div className={Classes.TaxRatePrintFlexBetween}>
                                                                <p style={{padding:'0px 5px'}}>
                                                                    SGST({handleGst(taxes?.gst)}%)
                                                                </p>
                                                                <p>&#8377;{handleTaxAmount(taxes?.tax)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ): (
                                                        <div className={Classes.TaxRatePrintFlexBetween}>
                                                            <p style={{padding:'0px 5px'}}>
                                                                IGST({taxes?.gst}%)
                                                            </p>
                                                            <p>&#8377;{handleFirst2Decimal(taxes?.tax)}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className={Classes.TaxRatePrintFlexBetween}>
                                                        <p style={{padding:'0px 5px'}}>
                                                            CGST({handleGst(taxes?.gst)}%)
                                                        </p>
                                                        <p>&#8377;{handleTaxAmount(taxes?.tax)}</p>
                                                    </div>

                                                    <div className={Classes.TaxRatePrintFlexBetween}>
                                                        <p style={{padding:'0px 5px'}}>
                                                            SGST({handleGst(taxes?.gst)}%)
                                                        </p>
                                                        <p>&#8377;{handleTaxAmount(taxes?.tax)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {showProduct?.shippingLine && (
                                <>
                                    {showProduct?.shippingLine?.taxAmount && showProduct?.shippingLine?.taxAmount !== 0 && (
                                        <div style={{padding:'0px 5px'}}>
                                            <div>
                                                <p>Shipping HSN Code(9965)</p>
                                            </div>
                                            <div className={Classes.TaxRatePrintFlexBetween}>
                                                <p style={{padding:'0px 15px'}}>GST(18%)</p>
                                                <p>&#8377;{showProduct?.shippingLine?.taxAmount}</p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            <div 
                                style={{padding:'5px',fontWeight:'600'}}
                                className={Classes.TaxRatePrintFlexBetween}
                            >
                                <div>
                                    <p>Total Tax</p>
                                </div>
                                <div>
                                    <p>&#8377;{showProduct?.TaxAmount}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
        </Fragment>
    );
}


export default TaxRatesPrint;