import React, { Fragment, useState, useEffect } from 'react';
import axiosInstance from '../../../../utility/axios-instance';
import SnackBar from '../../../../components/SnackBar';
import Classes from '../../../../components/mobileResponsive/style.module.css';
import Spinner from '../../../../components/spinner';
import NewPagination from '../../../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import Button from '@mui/material/Button';
import { Tooltip, Chip } from '@mui/material';
import { services } from '../../../../utility/checkRoleBasedPermission';
import Permission from '../../../auth/permissions';

const MobileResponsive = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const { dataPosition, endpoint, rowId, columns, handleClickOpen } = props;

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const SearchInput = ({ searchFor, handleSearch }) => {
        return (
            <div style={{ width: '100%' }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchFor}
                    // autoFocus={true}
                    onChange={(e) => handleSearch(e)}
                    autoComplete='off'
                    sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    const fetchData = async (page) => {
        setLoading(true)
        const params = { searchFor, page };
        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor]);

    return (
        <>
            <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
 
            <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

            {data?.length && data?.map((row) => (
                <div key={row[rowId]} className={Classes.Box}>
                    {columns?.length && columns?.map((column,index) => (
                        <div style={{display:'flex'}} key={index}>
                            <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                            <p>
                                {column?.field === "created_at" || column?.field === "createdAt" || 
                                column?.field === 'updatedAt' || column?.field === 'updated_at' ? 
                                moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') 
                                : column?.field === 'sku'  ? 
                                    row[column?.field] !== '' && (Array.isArray(row?.[column?.field]) && (
                                        <div>
                                            {row?.[column?.field]?.slice(0, 1)?.map((user, index) => (
                                                <Chip key={index} label={user} style={{ margin: '3px' }} />
                                            ))}
                                            {row?.[column?.field]?.length > 1 ? (
                                                <Tooltip title={
                                                    <div>
                                                        {row?.[column?.field]?.slice(1)?.map((service, index) => (
                                                            <p>{service}, </p>
                                                        ))}
                                                    </div>
                                                } arrow>
                                                    <Chip size="small" label={`+ ${row?.[column?.field]?.length - 1} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                    ))
                                : column?.field === 'minimum_target' ? 
                                    row.unit === "Value" ? (
                                        <div>₹{row?.minimum_target}</div>
                                    ) : (
                                        <div>{row?.minimum_target}</div>
                                    )
                                : column?.field === 'actions' ? 
                                    <Permission service={services?.scheme} permission={"update"}>
                                        <Button size='small' color='success' variant="contained" onClick={(e) => handleClickOpen(row)}>
                                            Update
                                        </Button>
                                    </Permission>
                                : row[column?.field]}
                            </p>
                        </div>
                    ))}
                </div>
            ))}

            <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                <NewPagination
                    totalPage={paginationData}
                    fetchData={fetchData}
                />
            </div>

            {loading && <Spinner />}
        </>
    )
};

export default MobileResponsive;