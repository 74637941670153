import { useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from '../draftOrder/css/draftOrder.module.css';
import NewPagination from '../../../components/newPagination';
import CreatedByModal from '../orders/createdByFilter';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { Paper, InputAdornment, TextField, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridToolbar } from '@mui/x-data-grid';
import { downloadCSV } from "../../../utility/downloadCSV";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Spinner from '../../../components/spinner';
import { useSelector, useDispatch } from 'react-redux';
import { setDraftOrders } from '../../../store/draftOrdersSlice';

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const DraftOrdersNew = () => {
	const [text, setText] = useState('');
	const [searchOrderPage, setOrderPage] = useState(1);
	const [createdBy, setCreatedBy] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
	const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });

	const draftOrders = useSelector((state) => state.draftOrders);
	const pinTab = useSelector((state) => state.pinTab);
	const dispatch = useDispatch();

	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}


	const handleUserSelect = async (selectedUsers) => {
		setLoading(true);
		const admins = selectedUsers.map(user => user.name);
		let usersQuery = admins.join(',');
		setCreatedBy(admins);

		await axiosInstance
			.get(`/pgOrder/admin/draftOrders?text=${text}&sort=${JSON.stringify(sort)}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setDraftOrders(res.data.data));
			});
		setLoading(false);
	};

	const fetchOrders = async (type) => {
		setLoading(true);
		let usersQuery = createdBy.join(',');
		const page = type;
		await axiosInstance
			.get(`/pgOrder/admin/draftOrders?page=${page}&text=${text}&createdBy=${usersQuery}&sort=${JSON.stringify(sort)}`)
			.then((res) => {
				dispatch(setDraftOrders(res.data.data));
				setLoading(false);
			});
	};


	useEffect(() => {
		if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Draft Order')) {
			const urlParams = new URLSearchParams(window.location.search);
			const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
			fetchOrders(page ? page : 1);
		}
	}, [sort])

	const fetchDraftOrder = (e) => {
		setText(e.target.value);
		let usersQuery = createdBy.join(',');
		axiosInstance
			.get(`/pgOrder/admin/draftOrders?page=${searchOrderPage}&sort=${JSON.stringify(sort)}&text=${e.target.value}&createdBy=${usersQuery}`)
			.then((res) => {
				dispatch(setDraftOrders(res.data.data));
			})
	}

	const columns = [
		{ field: 'draftOrderName', filterable: false, headerName: 'DRAFT ORDER', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'createdAt', filterable: false, headerName: 'DATE', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'displayName', filterable: false, headerName: 'CUSTOMER', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'totalPrice', filterable: false, valueFormatter: (params) => `₹${params.value}`, headerName: 'TOTAL', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
		{ field: 'createdBy', filterable: false, headerName: 'CREATED BY', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
	];

	if (document.querySelector('.MuiDataGrid-footerContainer')) {
		document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
	}

	const handleExport = (event) => {
		setLoading(true);
		event.preventDefault();

		axiosInstance
			.get('/exports/draftOrders')
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data?.exportedData
				downloadCSV(arrayOfRowObjects, `Orders export - ${new Date()}`);
				setLoading(false);
				const successMessage = "Draft Orders Exported Successfully!"
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
				});
			})
			.catch((error) => {
				const errorMessage = error?.response?.data?.message
				setSnackBar((prevSnackBar) => {
					return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
				});
				setLoading(false);
			})
	}

	const handleSnackBarClose = () => {
		setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: false }
		});
	};


	return (
		<Fragment>
			<div className="d-flex mb-3" style={{ width:"98%",margin:'0px auto',justifyContent:'flex-end' }}>
				<Button
					id='exportbtn'
					variant="contained" color="success"
					onClick={handleExport}
					startIcon={<FileDownloadIcon />}
				>
					Export
				</Button>
			</div>

			<Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
				<Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
					{snackBar?.message}
				</Alert>
			</Snackbar>

			<form style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '15px', width: '99.5%' }}>
				<div className={Classes.SearchDraftOrder}>
					<TextField
						id="search"
						type="search"
						label="Search"
						value={text}
						onChange={fetchDraftOrder}
						autoComplete='off'
						sx={{ width: '99%', "& label": { top: text ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>

				<div className={Classes.CreatedByFilter}>
					<CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />
				</div>
			</form>
			{draftOrders?.draftOrder?.length !== 0 && (
				<div className="d-none d-sm-block">
					<Box className="shadow"
						component={Paper}
						sx={{
							'& .super-app-theme--header': {
								backgroundColor: '#243750',
								color: '#ffffff',
								cursor: "default",
							},
							width: '99.5%',
						}}>
						<DataGrid
							disableColumnFilter
							slots={{ toolbar: GridToolbar }}
							componentsProps={{
								toolbar: {
									csvOptions: { disableToolbarButton: true },
									printOptions: { disableToolbarButton: true },
								},
							}}
							rows={draftOrders?.draftOrder?.map((order) => ({
								draftOrderName: order?.draftOrderName ? order?.draftOrderName : order?.draftOrder?.name,
								createdAt: createdAt(new Date(order?.createdAt)),
								displayName: order?.customerFullName ? order?.customerFullName : "N/A",
								createdBy: order?.createdBy,
								totalPrice: order?.total,
								id: order?.draftOrderName,
							}))}
							columns={columns}
							filterMode="server"
							sortingMode="server"
							paginationMode="server"
							disableRowSelectionOnClick
							loading={loading}
							autoHeight
							autoPageSize
							sx={{
								"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
									outline: "none !important",
								},
								cursor: "pointer",
							}}
							onRowClick={(params) => {
								navigate(`${params?.id}`);
							}}
							initialState={{
								sorting : {
									sortModel: [{ field: 'createdAt', sort: 'desc' }],
								},
							  }}
							onSortModelChange={(sort) => setSort(sort[0])}
						/>
					</Box>
				</div>
			)}

			<div className="d-block d-sm-none">
				<div
					style={{
						display: 'flex',
						padding: '8px 12px 5px',
						// borderBottom: '2px solid black',
						backgroundColor: '#243750',
						color: '#fff'
					}}
				>
					<h6 className={Classes.Column}>
						Draft Order
					</h6>
					<h6 className={Classes.DateColumn}>
						Date
					</h6>
					<h6 className={Classes.CustomerColumn}>
						Customer
					</h6>
					<h6 className={Classes.CreatedBy}>
						Created By
					</h6>
					<h6 style={{ marginLeft: 5 }}>
						Total
					</h6>
				</div>


				{draftOrders?.draftOrder?.length !== 0 ? (
					<div>
						<div style={{ fontSize: '14px' }}>
							{draftOrders?.draftOrder?.map((order, index) => (
								<div
									key={index}
									className={Classes.Row}
									onClick={() => navigate(order.draftOrderName)}
								>
									<div className={Classes.Column}>
										<h6>{order?.draftOrderName ? order?.draftOrderName : order?.draftOrder?.name}</h6>

										<div className={Classes.Responsive}>
											<p>
												{createdAt(order.createdAt)}
											</p>
											{order?.customerFullName ? (
											<p>
												{order?.customerFullName}
											</p>
										) : (
											<p>N/A</p>
										)}
										</div>
									</div>

									<p className={Classes.DateColumn}>
										{createdAt(order.createdAt)}
									</p>

									{order.createdBy ? (
										<p
											style={{ paddingLeft: 10 }}
											className={Classes.CreatedBy}>
											{order?.createdBy}
										</p>
									) :
										<p className={Classes.CreatedBy}></p>
									}

									<p className={Classes.TotalPrice}>
										&#8377;
										<>{order?.total ? Math.round(order?.total) : "N/A"}</>
									</p>
								</div>
							))}
						</div>
					</div>
				) : (
					<p
						style={{
							position: 'absolute',
							left: '41%',
							color: 'red',
							marginTop: '10%'
						}}
					>
						No Draft Orders Exist
					</p>
				)}
			</div>

			{loading && ( <Spinner /> )}

			{draftOrders?.draftOrder?.length > 0 && (
				<div style={{ margin: '10px auto 0px' }}>
					<NewPagination
						totalPage={draftOrders}
						fetchData={fetchOrders}
					/>
				</div>	
			)}

		</Fragment>
	);
};

export default DraftOrdersNew;
