import axiosInstance from '../../utility/axios-instance';

// show Modal popup
export const getModalHelper = (
    searchItem,setToggle,setSearchProduct,setShowModal
) => {
    setToggle(true);
    setSearchProduct(searchItem);
    setShowModal(true);
};

export const handleTaxAmountHelper = (amount) => {
    return Math.floor(amount * 100) / 100
}

export const hideConfirmPopupHelper = (event,setConfirmShowPopup) => {
    if (event.target.innerText === 'Yes') {
        // handleCreateOrder();
    }
    setConfirmShowPopup(false)
}

// AddProductButton&HideModal
export const addHideModalHelper = (
    setSearchProduct,setVariant,productIdArr,setShowModal,setAddToggle,
    productNew,productDetails,setProductId,setProductDetails,setProductNew
) => {
    setSearchProduct('');
    setVariant(0);
    productIdArr.splice(0, productIdArr.length);
    setShowModal(false);
    setAddToggle(true);

    if (productNew.length > 0) {
        handleEnableSelectHelper();
    }

    if (productDetails.length !== 0) {
        const newProducts = productNew;

        const prevProduct = productDetails;
        let arr = newProducts;

        for (let prev of prevProduct) {
            for (let newProd of newProducts) {
                if (newProd.variantId === prev.variantId) {
                    prev.quantity += 1
                    prev.PCS += 1
                    prev.locationCode = newProd?.locationCode;
                    const index = arr.findIndex(product => product.variantId === newProd.variantId);
                    arr.splice(index, 1);
                }
            }
        }

        let newArray = [];
        if (arr.length !== 0) {
            newArray.push(...prevProduct, ...arr);

            setProductId(newArray);
            setProductDetails(newArray);
            setProductNew([])
        } else {
            setProductId(prevProduct);
            setProductDetails([...prevProduct]);
            setProductNew([])
        }
    } else {
        setProductDetails(productNew);
        setProductNew([]);
    }
};

// hide modal popup
export const hideModalHelper = (
    setSearchProduct,productIdArr,setVariant,productId,setShowModal
) => {
    setSearchProduct('');
    productIdArr.splice(0, productIdArr.length);
    setVariant(0);
    productId.splice(0, productId.length);
    setShowModal(false);
};

// show TaxRate Popup
export const handleTaxRatesHelper = (setTaxRates) => {
    setTaxRates(true);
};

// hide TaxRate Popup
export const hideTaxPopupHelper = (setTaxRates) => {
    setTaxRates(false);
};

// show discount popup
export const showDiscountPopupHelper = (setDiscountModal) => {
    setDiscountModal(true);
};

// hide Discount popup
export const hideDiscountPopupHelper = (setDiscountModal) => {
    setDiscountModal(false);
};

export const shippingPopupHelper = (event,setShippingModal) => {
    setShippingModal(true);
};

export const hideShippingPopupHelper = (setPreviousTarget,setShippingModal) => {
    setPreviousTarget([])
    setShippingModal(false);
};

export const handleOutlineHelper = () => {
    document.getElementById('outline').style.border = '2px solid #458fff';
};

// remove product from array
export const updateArrHelper = (
    Id,productDetails,notExistProductShopifyId,setProductNotExistShopifyId,
    setProductNotExistShopify,setProductId,setProductDetails,setAddToggle,
    setVariantRole,variantRole,setProduct,setOverAllDiscount,setShippingPrice,
    setPreviousTarget
) => {
    let arr = productDetails;
    let notExistProductOnShopify = notExistProductShopifyId;
    let storeArray = notExistProductOnShopify.filter((ele) => ele.Id !== Id);
    setProductNotExistShopifyId(storeArray)
    if (storeArray.length === 0) {
        setProductNotExistShopify(false);
    }
    let store = arr.filter((ele) => ele.variantId !== Id);
    if (store.length !== 0) {
        setProductId(store);
        setProductDetails(store);
        setAddToggle(true)
        setVariantRole(variantRole.filter((role) => role.id !== Id));
    } else {
        setProductId(store);
        setProductDetails(store);
        setProduct(store);
        handleDisableSelectHelper();
        setOverAllDiscount([]);
        setShippingPrice([]);
        setPreviousTarget([]);
        setVariantRole(variantRole.filter((role) => role.id !== Id));
    }
};

// increase qty from product
export const incQtyHelper = (
    event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,
    setProductId,setProductDetails
) => {
    const draftOrder = document.getElementById("draftOrderBtn");
    if (draftOrder !== null) {
      draftOrder.disabled = false;
    }
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            let variant = document.getElementById(ele.variantId)
            variant.children[0].children[1].lastChild.style.display = 'none';
            if ((ele.PCS + 1) === parseInt(bulkPacking1)) {
                ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + 1 : 1;
                ele.PCS = 0;
            } else if ((ele.PCS + 1) === parseInt(bulkPacking2)) {
                ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 + 1 : 1;
                ele.PCS = 0;
            } else {
                ele.PCS = ele.PCS + 1;
            }   
            ele.quantity = ele.quantity + 1;
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

// decrease qty from product
export const decQtyHelper = (
    event, Id,bulkPacking1,bulkPacking2,productDetails,setAddToggle,setProductId,
    setProductDetails
) => {
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            if (ele.PCS !== 0) {
                // ele.quantity = ele.quantity - 1;
                // ele.PCS = ele.PCS - 1
                if ((ele.PCS - 1) === parseInt(bulkPacking1)) {
                    ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + 1 : 1;
                    ele.PCS = 0;
                } else if ((ele.PCS - 1) === parseInt(bulkPacking2)) {
                    ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 + 1 : 1;
                    ele.PCS = 0;
                } else {
                    ele.PCS = ele.PCS - 1;
                }   
                ele.quantity = ele.quantity - 1;
            }
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

// Handle Manually Percentage
export const handleEditPercentageHelper = (event) => {
    if (event.target.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
        return;
    }
};

// Handle Blur Percentage
export const handleBlurPercentageHelper = (
    event, Id, MRP, GST, originalUnitPrice, setStorePrevDiscountId,productDetails,
    updateDiscountPercent,setUpdateDiscountPercent,setAddToggle,setProductId,
    setProductDetails
) => {

    const value = parseInt(event.target.value, 10);
    // If the value is negative
    if (value < 0) {
        alert('Please enter valid percentage');
        event.target.value = ''
        return;
    }
    
    const limitDiscount = event.target.parentNode.parentNode.parentNode.lastChild;
    const storeClassName = limitDiscount.getAttribute('class').split(' ')[1] || null;
    if (event.target.value) {
        if (event.target.value <= 100) {
            // document.getElementById('discountPercentRestrict').style.display = 'none';
            setStorePrevDiscountId([storeClassName])
            const discountPercent = limitDiscount.lastChild.children;
            const percentage = parseFloat(event.target.value);
            if (parseFloat(discountPercent[1].innerText) <= percentage) {
                limitDiscount.style.display = 'none';
                const mrp = parseFloat(MRP);
                const gst = parseInt(GST);
                const discountAmount = -1 * (((percentage - 100) * mrp) / (100 * (1 + (gst / 100))))
                const checkDecimal = countPlacesHelper(discountAmount)
                const result = checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2 ? discountAmount : Math.trunc(discountAmount * 100) / 100;

                let discount = {
                    appliedDiscount: {
                        value: Number(event.target.value),
                        valueType: 'PERCENTAGE',
                        title: 'MANUAL',
                    }
                }

                const rateInclGST = result * (1 + (gst / 100))

                const checkDecimalPlaces = countPlacesHelper(rateInclGST);
                const InclGstPrice = checkDecimalPlaces === 1 || checkDecimalPlaces === 0 || checkDecimalPlaces === 2 ? rateInclGST : Math.trunc(rateInclGST * 100) / 100

                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.variantId === Id) {
                        ele.rateInclGST = InclGstPrice;
                        Object.assign(ele, discount);
                    }
                }

                if (updateDiscountPercent !== undefined) {
                    if (updateDiscountPercent.length !== 0) {
                        for (let element of updateDiscountPercent) {
                            if (element !== Id) {
                                const array = [element, Id]
                                setUpdateDiscountPercent([...array])
                            }
                        }
                    } else {
                        setUpdateDiscountPercent([Id]);
                    }
                }

                setTimeout(() => {
                    event.target.value = '';
                }, 1500)

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);

            } else {
                limitDiscount.style.display = 'block';
            }
        } else {
            // document.getElementById('discountPercentRestrict').style.display = 'block';
            document.getElementById(event.target.id).value ='';
            alert('Discount Must be Smaller Than 100%')
        }
    } else {
        limitDiscount.style.display = 'none';
    }

};

export const handleEditHelper = (event) => {
    if (document.activeElement.isContentEditable) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
            return;
        }
    }
};

// handle Blur Rate(Price)
export const handleBlurHelper = (
    event, Id, previousUnit, MRP,productDetails,setAddToggle,setProductId,
    setProductDetails
) => {
    const userAmount = JSON.parse(JSON.stringify(event.target.innerText));
    if (userAmount > MRP) {
        alert('Please enter valid price');
        event.target.innerText = previousUnit
        return;
    } 
    const discountAmount = MRP - userAmount;
    const discount = {
        appliedDiscount: {
            value: discountAmount,
            valueType: 'FIXED_AMOUNT',
            title: 'MANUAL'
        },
    };
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            Object.assign(ele, discount);
        }
    }
    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

// OnQty Blur Handled
export const handleKeyUpHelper = (event) => {
    //key code for enter
    if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
    }
}

export const handleBlurBulkPackagingQty1Helper = (
    event, Id, bulkPackagingValue, type, bulkPackagingValue2, restrictMsgId,
    productDetails, setAddToggle, setProductId, setProductDetails
) => {
    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = bulkPackagingValue2 !== undefined && ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPackagingValue2 : 0;
                ele.quantity = ele.PCS + 0 + packing1;
                ele.bulkPackingValue1 = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event) {
            if (event < 0) {
                document.getElementById(restrictMsgId).style.display = 'block';
                const createOrder = document.getElementById('createOrderBtn')
                if (createOrder !== null) {
                    createOrder.disabled = true;
                }
                const draftOrder = document.getElementById("draftOrderBtn");
                if (draftOrder !== null) {
                  draftOrder.disabled = true;
                }
            } else {
                document.getElementById(restrictMsgId).style.display = 'none';
                const pro = JSON.parse(event);
                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.variantId === Id) {
                        if (ele.bulkPackingValue1 !== pro) {
                            let packing2 = bulkPackagingValue2 !== undefined && ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPackagingValue2 : 0;
                            ele.quantity = ele.PCS + pro * bulkPackagingValue + packing2;
                            ele.bulkPackingValue1 = pro;
                            if (pro > 0) {
                                let variant = document.getElementById(ele.variantId)
                                variant.children[0].children[1].lastChild.style.display = 'none';
                                const createOrder = document.getElementById('createOrderBtn')
                                if (createOrder !== null) {
                                    createOrder.disabled = false;
                                }
                                const draftOrder = document.getElementById("draftOrderBtn");
                                if (draftOrder !== null) {
                                  draftOrder.disabled = false;
                                }
                            }
                        } 
                    }
                }

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);
            }
        }
    }
};

export const handleBlurBulkPackagingQty2Helper = (
    event, Id, bulkPackagingValue, type, bulkPackagingValue1, restrictMsgId,
    productDetails, setAddToggle, setProductId, setProductDetails
) => {
    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = bulkPackagingValue1 !== undefined && ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPackagingValue1 : 0;
                ele.quantity = ele.PCS + 0 + packing1;
                ele.bulkPackingValue2 = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event < 0) {
            document.getElementById(restrictMsgId).style.display = 'block';
            const createOrder = document.getElementById('createOrderBtn')
            if (createOrder !== null) {
                createOrder.disabled = true;
            }
            const draftOrder = document.getElementById("draftOrderBtn");
            if (draftOrder !== null) {
              draftOrder.disabled = true;
            }
        } else {
            document.getElementById(restrictMsgId).style.display = 'none';
            const pro = JSON.parse(event);
            let arr = productDetails;
            for (let ele of arr) {
                if (ele.variantId === Id) {
                    let packing1 = bulkPackagingValue1 !== undefined && ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPackagingValue1 : 0;
                    ele.quantity = ele.PCS + pro * bulkPackagingValue + packing1;
                    ele.bulkPackingValue2 = pro;
                    if (pro > 0) {
                        let variant = document.getElementById(ele.variantId)
                        variant.children[0].children[1].lastChild.style.display = 'none';
                        const createOrder = document.getElementById('createOrderBtn')
                        if (createOrder !== null) {
                            createOrder.disabled = false;
                        }

                        const draftOrder = document.getElementById("draftOrderBtn");
                        if (draftOrder !== null) {
                          draftOrder.disabled = false;
                        }
                    }
                }
            }

            setAddToggle(true);
            setProductId(arr);
            setProductDetails([...arr]);
        }
    }
};

// increase qty from product
export const incQtyBulkPackagingHelper = (
    event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
    setProductDetails
) => {
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            if (type === 'bulkPackingValue1') {
                ele.quantity = parseInt(ele.quantity) + parseInt(bulkPackagingValue);
                ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + 1 : 1;
            } else {
                ele.quantity = parseInt(ele.quantity) + parseInt(bulkPackagingValue);
                ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 + 1 : 1;
            }

            let variant = document.getElementById(ele.variantId)
            variant.children[0].children[1].lastChild.style.display = 'none';
            const draftOrder = document.getElementById('draftOrderBtn');
            if (draftOrder !== null) {
                draftOrder.disabled = false;
            }
            const createOrder = document.getElementById('createOrderBtn')
            if (createOrder !== null) {
                createOrder.disabled = false;
            }
        }
    }

    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

export const decQtyBulkPackagingHelper = (
    event, Id, bulkPackagingValue, type, productDetails, setAddToggle, setProductId,
    setProductDetails
) => {
    let arr = productDetails;
    for (let ele of arr) {
        if (ele.variantId === Id) {
            if (ele.quantity !== 1) {
                if (type === 'bulkPackingValue1') {
                    ele.quantity = ele.bulkPackingValue1 && ele.bulkPackingValue1 > 0 ? parseInt(ele.quantity) - parseInt(bulkPackagingValue) : ele.quantity;
                    ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 > 0 && ele.bulkPackingValue1 - 1 : 0;
                } else {
                    ele.quantity = ele.bulkPackingValue2 && ele.bulkPackingValue2 > 0 ? parseInt(ele.quantity) - parseInt(bulkPackagingValue) : ele.quantity;
                    ele.bulkPackingValue2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 > 0 && ele.bulkPackingValue2 - 1 : 0;
                }
            }
        }
    }

    setAddToggle(true)
    setProductId(arr);
    setProductDetails([...arr]);
};

// handle Blur Qty
export const handleBlurQtyHelper = (
    event, Id, bulkPacking1, bulkPacking2, restrictMsgId, productDetails, setAddToggle,
    setProductId, setProductDetails
) => {

    if (event === '' || event === '0') {
        document.getElementById(restrictMsgId).style.display = 'none';
        let arr = productDetails;
        for (let ele of arr) {
            if (ele.variantId === Id) {
                let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                ele.quantity = 0 + packing1 + packing2;
                ele.PCS = 0;
            }
        }

        setAddToggle(true)
        setProductId(arr);
        setProductDetails([...arr]);
    } else {
        if (event) {
            if (event < 0) {
                document.getElementById(restrictMsgId).style.display = 'block';
                const createOrder = document.getElementById('createOrderBtn')
                if (createOrder !== null) {
                    createOrder.disabled = true;
                }
                const draftOrder = document.getElementById("draftOrderBtn");
                if (draftOrder !== null) {
                  draftOrder.disabled = true;
                }
            } else {
                document.getElementById(restrictMsgId).style.display = 'none';
                const pro = JSON.parse(event);
                let arr = productDetails;
                for (let ele of arr) {
                    if (ele.variantId === Id) {
                        if (pro >= bulkPacking1) {
                            const bulkPacking = parseInt(pro/bulkPacking1);
                            let packing1 = ele.bulkPackingValue1 ? (ele.bulkPackingValue1 + bulkPacking) * bulkPacking1 : 
                            bulkPacking * bulkPacking1;
                            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                            ele.bulkPackingValue1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 + bulkPacking : bulkPacking;
                            ele.PCS = pro % bulkPacking1;
                            ele.quantity = ele.PCS + packing1 + packing2;
                        } else {
                            let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                            ele.quantity = pro + packing1 + packing2;
                            ele.PCS = pro;
                        }
                        if (pro > 0) {
                            let variant = document.getElementById(ele.variantId)
                            variant.children[0].children[1].lastChild.style.display = 'none';
                            // document.getElementById('draftOrderBtn').disabled = false;
                            const createOrder = document.getElementById('createOrderBtn')
                            if (createOrder !== null) {
                                createOrder.disabled = false;
                            }
                            const draftOrder = document.getElementById("draftOrderBtn");
                            if (draftOrder !== null) {
                              draftOrder.disabled = false;
                            }
                        }

                    }
                }

                setAddToggle(true);
                setProductId(arr);
                setProductDetails([...arr]);
            }
        }
    }
};

export const formatNumberHelper = (number) => {
    const formattedNumber = parseFloat(number).toFixed(2);
    return formattedNumber;
};

// showLimitDiscount
export const showLimitDiscountHelper = (mrp, originalUnitPrice, value) => {
    const MRP = parseInt(mrp);
    const DP = parseFloat(originalUnitPrice);
    const GST = parseInt(value);
    const result = (MRP - DP * (1 + GST / 100)) / MRP;
    const TotalDiscount = result * 100;
    const decimalPlaces = countPlacesHelper(TotalDiscount);
    let results = decimalPlaces === 1 || decimalPlaces === 0 || decimalPlaces === 2 ? TotalDiscount : Math.trunc(TotalDiscount * 100) / 100;
    return results;
};

export const handleDisableSelectHelper = () => {
    const $handleSelect = document.getElementById('selectToggle')
    if ($handleSelect) {
        $handleSelect.value = 'DEFAULT';
        $handleSelect.disabled = true;
    }
}

export const handleEnableSelectHelper = () => {
    const $handleSelect = document.getElementById('selectToggle')
    if ($handleSelect !== null) {
        $handleSelect.value = 'DEFAULT';
        $handleSelect.disabled = false;
    }
}

// Open Window Print
export const handleSendPIHelper = (event,overAllDiscount,sendPIDraftOrder) => {
    event.target.disabled = true;

    const discountHide = document.getElementById('noDiscountAvailPrint')
    if (overAllDiscount.length === 0) {
        discountHide.style.display = 'none';
    }

    if (sendPIDraftOrder.length !== 0) {
        onbeforeprint = document.title = sendPIDraftOrder.draftOrder.name;
    }
    // onbeforeprint = document.title ='Invoice';
    setTimeout(() => {
        window.print();
        onafterprint = document.title = 'DEPO24 PORTAL';
        onafterprint = event.target.disabled = false;
        onafterprint = discountHide.style.display = 'flex';
    }, 500);

}

// Create draftOrder(Save as Draft Order)
export const handleCreateDraftOrderHelper = (
    event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
    customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
    locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
    shippingPrice, setShippingDetails, shippingDetails, documentsAttached,
    locationContactDetails, vendorContactDetails, notes, navigate, setIsCreateOrderPage,
    setLoading
) => {
    for (let ele of productDetails) {
        if (ele?.quantity === 0) {
            let variant = document.getElementById(ele.variantId);
            variant.scrollIntoView();
            variant.children[0].children[1].lastChild.style.display = "block";
            event.target.disabled = true;
            return;
        }
    }
    setLoading(true);
    // event.target.disabled = true;
    event.target.innerHTML = 'Creating Draft Order...';
    const vendor = showCustomerDetail[0];
    const location = showLocationDetail[0];

    const taxLines = showProduct.taxLines;

    for (let product of productDetails) {
        const index = showProduct.lineItems.findIndex(variantIndex => variantIndex.productId === product.variantId);
        product.mrp = showProduct.lineItems[index].priceDataAffiliateData.mrp;
        product.unitOfMeasurement = showProduct.lineItems[index].basicCollectionDataBrandData.unit;
        product.subTotal = showProduct.lineItems[index].subTotal;
        product.discountedUnitPrice = showProduct.lineItems[index].discountedUnitPrice;
        product.discountedSubTotal = showProduct.lineItems[index].subTotal;
        product.discountedTotal = showProduct.lineItems[index].total;
        product.discountPercentage = showProduct.lineItems[index].discountPercentage;
    }

    const variantLineItems = productDetails.map(obj => {
        const { variantId, ...rest } = obj;
        return { productId: variantId, ...rest };
    });

    const vendorBillingAddressObject = {};
    const vendorAddressObject = {};
    let vendorDetails = null;

    if (vendor) {
        const vendorAddress = customerIDAddress[0];
        for (const key in vendorAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorAddressObject[key] = `${vendorAddress[key]}`));
            }
        }

        let vendorBillingAddress = BillingAddress.length === 0 ? customerIDAddress[0] : BillingAddress[0];

        for (const key in vendorBillingAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'updatedAt' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorBillingAddressObject[key] = `${vendorBillingAddress[key]}`));
            }
        }

        for (const key in vendor) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((vendorAddressObject[key] = `${vendor[key]}`));
                Object.assign((vendorBillingAddressObject[key] = `${vendor[key]}`));
            }
        }
        vendorBillingAddressObject['addressType'] = "Billing"
        vendorAddressObject['addressType'] = "Shipping"
        vendorAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorBillingAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorDetails = [vendorAddressObject, vendorBillingAddressObject]
    }

    const locationBillingAddressObject = {};
    const locationAddressObject = {};
    let locationDetails = null

    if (location) {
        const locationAddress = locationIDAddress[0];
        for (const key in locationAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'

            ) {
                Object.assign((locationAddressObject[key] = `${locationAddress[key]}`));
            }
        }

        let locationBilling = locationBillingAddress.length === 0 ? locationIDAddress[0] : locationBillingAddress[0];

        for (const key in locationBilling) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((locationBillingAddressObject[key] = `${locationBilling[key]}`));
            }
        }

        for (const key in location) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((locationAddressObject[key] = `${location[key]}`));
                Object.assign((locationBillingAddressObject[key] = `${location[key]}`));
            }
        }
        locationBillingAddressObject['addressType'] = "Billing"
        locationAddressObject['addressType'] = "Shipping"
        locationAddressObject['locationGstNumber'] = locationGstNumber
        locationBillingAddressObject['locationGstNumber'] = locationGstNumber
        locationDetails = [locationAddressObject, locationBillingAddressObject]
    }

    let paymentObject
    if (overAllDiscount.length !== 0) {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "overallDiscountType": overAllDiscount[0]?.valueType,
            "overallDiscountValue": overAllDiscount[0]?.value,
            "overallDiscountTitle": overAllDiscount[0]?.title,
        }
    } else {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
        }
    }

    if (shippingPrice.length !== 0) {
        setShippingDetails({
            ...shippingDetails,
            "freightCharges": shippingPrice[0]?.price,
            "transporterName": shippingPrice[0]?.title,

        })
    }

    const formData = new FormData()

    for (let i = 0; i < documentsAttached.length; i++) {
        formData.append("documentsAttached", documentsAttached[i]);
    }

    const data = {
        "DpoLineItems": variantLineItems,
        "DpoVendorDetails": vendorDetails,
        "DpoLocationDetails": locationDetails,
        "DpoShipmentDetails": shippingDetails,
        "DpoPaymentDetails": paymentObject,
        "DpoLocationContactDetails": locationContactDetails,
        "DpoVendorContactDetails": vendorContactDetails,
        "DpoTaxLines": taxLines,
        "notes": notes,
    }
    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null && value !== undefined && value !== "")
    );

    formData.append('data', JSON.stringify(filteredData));


    axiosInstance
        .post('/purchaseOrders/saveDraftPurchaseOrder', formData)
        .then((res) => {
            // setSendPIOpenOrder(true);
            // setSendPIDraftOrder(res.data.data);
            setLoading(false);
            event.target.innerHTML = 'Draft Order Created';
            setIsCreateOrderPage(false);
            navigate(`/draftPurchaseOrders/${res?.data?.data?.draftPurchaseOrderName}`);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            event.target.disabled = false;
            event.target.innerHTML = 'Save as Draft Order';
        });
}

// Update Exist Order
export const handleUpdateOrderHelper = (
    event, showCustomerDetail, showLocationDetail, showProduct, productDetails, 
    customerIDAddress, BillingAddress, vendorGstNumber, locationIDAddress,
    locationBillingAddress, locationGstNumber, overAllDiscount, paymentDetails,
    shippingDetails, documentsAttached, locationContactDetails, vendorContactDetails,
    notes, navigate, specificId, setVendorContactDetails, setLocationContactDetails,
    defaultFiles, draftOrder, updateDraftOrderId, setUpdateOrder, setCompleteOrder, 
    setIsCreateOrderPage
) => {
    for (let ele of productDetails) {
        if (ele?.quantity === 0) {
            let variant = document.getElementById(ele.variantId);
            variant.scrollIntoView();
            variant.children[0].children[1].lastChild.style.display = "block";
            event.target.disabled = true;
            return;
        }
    }
    event.target.innerHTML = 'Draft Order Updating...';
    const vendor = showCustomerDetail[0];
    const location = showLocationDetail[0];

    const taxLines = showProduct.taxLines;

    for (let product of productDetails) {
        const index = showProduct.lineItems.findIndex(variantIndex => variantIndex.productId === product.variantId);
        product.mrp = showProduct.lineItems[index].priceDataAffiliateData.mrp;
        product.unitOfMeasurement = showProduct.lineItems[index].basicCollectionDataBrandData.unit;
        product.subTotal = showProduct.lineItems[index].subTotal;
        product.discountedUnitPrice = showProduct.lineItems[index].discountedUnitPrice;
        product.discountedSubTotal = showProduct.lineItems[index].subTotal;
        product.discountedTotal = showProduct.lineItems[index].total;
        product.discountPercentage = showProduct.lineItems[index].discountPercentage;
    }

    const variantLineItems = productDetails.map(obj => {
        const { variantId, ...rest } = obj;
        return { productId: variantId, ...rest };
    });

    const vendorBillingAddressObject = {};
    const vendorAddressObject = {};
    let vendorDetails = [];

    if (vendor) {
        const vendorAddress = customerIDAddress[0];
        for (const key in vendorAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorAddressObject[key] = `${vendorAddress[key]}`));
            }
        }

        let vendorBillingAddress = BillingAddress.length === 0 ? customerIDAddress[0] : BillingAddress[0];

        for (const key in vendorBillingAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'updatedAt' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((vendorBillingAddressObject[key] = `${vendorBillingAddress[key]}`));
            }
        }

        for (const key in vendor) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((vendorAddressObject[key] = `${vendor[key]}`));
                Object.assign((vendorBillingAddressObject[key] = `${vendor[key]}`));
            }
        }
        vendorBillingAddressObject['addressType'] = "Billing"
        vendorAddressObject['addressType'] = "Shipping"
        vendorBillingAddressObject['dpoVendorDetailId'] = specificId.dpoVendorDetailIdBilling
        vendorBillingAddressObject['draftPurchaseOrderId'] = specificId.draftPurchaseOrderId
        vendorAddressObject['dpoVendorDetailId'] = specificId.dpoVendorDetailIdShipping
        vendorAddressObject['draftPurchaseOrderId'] = specificId.draftPurchaseOrderId
        vendorAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorBillingAddressObject['vendorGstNumber'] = vendorGstNumber
        vendorDetails = [vendorAddressObject, vendorBillingAddressObject]
        setVendorContactDetails({ ...vendorContactDetails, 'dpoVendorContactDetailId': specificId.dpoVendorContactDetailId ? specificId.dpoVendorContactDetailId : null })

    }
    const locationBillingAddressObject = {};
    const locationAddressObject = {};
    let locationDetails = []

    if (location) {
        const locationAddress = locationIDAddress[0];
        for (const key in locationAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'

            ) {
                Object.assign((locationAddressObject[key] = `${locationAddress[key]}`));
            }
        }

        let locationBilling = locationBillingAddress.length === 0 ? locationIDAddress[0] : locationBillingAddress[0];

        for (const key in locationBilling) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                Object.assign((locationBillingAddressObject[key] = `${locationBilling[key]}`));
            }
        }

        for (const key in location) {
            if (key !== 'status' || key !== 'active') {
                Object.assign((locationAddressObject[key] = `${location[key]}`));
                Object.assign((locationBillingAddressObject[key] = `${location[key]}`));
            }
        }
        locationBillingAddressObject['addressType'] = "Billing"
        locationAddressObject['addressType'] = "Shipping"
        locationBillingAddressObject['dpoLocationDetailId'] = specificId.dpoLocationDetailIdBilling
        locationAddressObject['dpoLocationDetailId'] = specificId.dpoLocationDetailIdShipping
        locationAddressObject['draftPurchaseOrderId'] = specificId.draftPurchaseOrderId
        locationBillingAddressObject['draftPurchaseOrderId'] = specificId.draftPurchaseOrderId
        locationAddressObject['locationGstNumber'] = locationGstNumber
        locationBillingAddressObject['locationGstNumber'] = locationGstNumber
        locationDetails = [locationAddressObject, locationBillingAddressObject]
        setLocationContactDetails({ ...locationContactDetails, 'dpoLocationContactDetailId': specificId.dpoLocationContactDetailId ? specificId.dpoLocationContactDetailId : null })
    }

    let paymentObject
    if (overAllDiscount.length !== 0) {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "dpoPaymentDetailId": specificId.dpoPaymentDetailId,
            "overallDiscountType": overAllDiscount[0]?.valueType,
            "overallDiscountValue": +overAllDiscount[0]?.value,
            "overallDiscountTitle": overAllDiscount[0]?.title,
        }
    } else {
        paymentObject = {
            "totalAmount": Math.round(showProduct?.total),
            "totalTaxAmount": Math.round(showProduct?.totalTaxAmount),
            "subTotalAmount": Math.round(showProduct?.subTotal),
            "paymentMode": paymentDetails?.paymentMode,
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "dpoPaymentDetailId": specificId.dpoPaymentDetailId

        }
    }

    const formData = new FormData()

    for (let i = 0; i < documentsAttached.length; i++) {
        formData.append("documentsAttached", documentsAttached[i]);
    }

    let dpoShippingDetails = { ...shippingDetails, dpoShipmentDetailId: specificId.dpoShipmentDetailId ? specificId.dpoShipmentDetailId : null }

    let data = {
        "documentsAttached": defaultFiles,
        "DpoLineItems": variantLineItems,
        "DpoVendorDetails": vendorDetails,
        "DpoLocationDetails": locationDetails,
        "DpoShipmentDetails": dpoShippingDetails,
        "DpoPaymentDetails": paymentObject,
        DpoTaxLines: taxLines,
        notes: notes,
    }

    if (draftOrder?.DpoVendorContactDetails !== null) {
        Object.assign(data, { DpoVendorContactDetails: { ...vendorContactDetails } })
    }

    if (draftOrder?.DpoLocationContactDetails !== null) {
        Object.assign(data, { DpoLocationContactDetails: { ...locationContactDetails } })
    }

    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null && value !== undefined && value !== "")
    );


    formData.append('data', JSON.stringify(filteredData));

    axiosInstance
        .put(`/purchaseOrders/draftPurchaseOrder/${updateDraftOrderId[0]}`, formData)
        .then((res) => {
            // setSendPIDraftOrder(res.data.data);
            setUpdateOrder(false);
            setCompleteOrder(true);
            event.target.innerHTML = 'Order Updated';
            setIsCreateOrderPage(false);
            navigate('/draftPurchaseOrders');
        });
}

export const countPlacesHelper = (num) => {
    var sep = String(23.32).match(/\D/)[0];
    var b = String(num).split(sep);
    return b[1] ? b[1].length : 0;
}

export const handleChangeHelper = (value, sku, lineItems, setLineItems) => {
    let lineItemsList = lineItems;

    lineItemsList = lineItemsList.map(lineItem => {
        if (lineItem.basicCollectionDataBrandData.sku === sku) {
            lineItem.PCS = parseInt(value);
        }
        return lineItem;
    });

    setLineItems(lineItemsList)
}

export const handleUpdateExistOrderHelper = (
    Id, setUpdateExistOrder, setDraftOrder, setCompleteExistOrder, setUpdateDraftOrderId, setShippingDetails,
    setPaymentDetails, setVendorContactDetails, setVendorGstNumber, setLocationContactDetails, setNotes,
    setLocationGstNumber, setSpecificId, setDefaultFiles, setUpdateDiscountPercent, setDiscountPercent,
    setOverAllDiscount, setShippingPrice, setProductId, setProductDetails, setProduct, setUpdateOrder,
    handleEnableSelect, setUpdateToggle, setLineItems, setCustomerDetail, setAddressArray, setCustomerIDAddress,
    setBillingAddress, setVendorContacts, setLocationDetail, setLocationAddressArray, setLocationIDAddress,
    setLocationBillingAddress, setLocationContacts
) => {
    axiosInstance
        .get(`/purchaseOrders/draftPurchaseOrder/${Id}`)
        .then((response) => {
            if (response.data.data === null) {
                return
            }

            if (response.data.data) {
                setUpdateExistOrder(true);
            }

            setDraftOrder(response?.data?.data);
            setCompleteExistOrder([response.data?.data?.draftPurchaseOrderName])
            setUpdateDraftOrderId([response.data?.data?.draftPurchaseOrderName])
            setShippingDetails(response.data?.data?.DpoShipmentDetails);
            setPaymentDetails(response.data?.data?.DpoPaymentDetails);
            setVendorContactDetails(response?.data?.data?.DpoVendorContactDetails)
            setVendorGstNumber(response?.data?.data?.DpoVendorDetails[0]?.vendorGstNumber)
            setLocationContactDetails(response?.data?.data?.DpoLocationContactDetails)
            setNotes(response?.data?.data?.notes);
            setLocationGstNumber(response?.data?.data?.DpoLocationDetails[0]?.locationGstNumber)
            const ids = {
                dpoLocationContactDetailId: response?.data?.data?.DpoLocationContactDetails?.dpoLocationContactDetailId,
                // dpoLocationDetailId: response.data?.data?.DpoLocationDetails[0]?.dpoLocationDetailId,
                dpoVendorContactDetailId: response?.data?.data?.DpoVendorContactDetails?.dpoVendorContactDetailId,
                // dpoVendorDetailId: response.data?.data?.DpoVendorDetails[0]?.dpoVendorDetailId,
                dpoPaymentDetailId: response?.data?.data?.DpoPaymentDetails?.dpoPaymentDetailId,
                dpoShipmentDetailId: response?.data?.data?.DpoShipmentDetails?.dpoShipmentDetailId,
                draftPurchaseOrderId: response?.data?.data?.draftPurchaseOrderId
            }
            for (let i = 0; i < response.data?.data?.DpoLocationDetails?.length; i++) {
                if (response.data?.data?.DpoLocationDetails[i]?.addressType === "Shipping") {
                    ids['dpoLocationDetailIdShipping'] = response.data?.data?.DpoLocationDetails[i]?.dpoLocationDetailId
                }
                else if (response.data?.data?.DpoLocationDetails[i]?.addressType === "Billing") {
                    ids['dpoLocationDetailIdBilling'] = response.data?.data?.DpoLocationDetails[i]?.dpoLocationDetailId
                }
            };
            for (let i = 0; i < response.data?.data?.DpoVendorDetails?.length; i++) {
                if (response.data?.data?.DpoVendorDetails[i]?.addressType === "Shipping") {
                    ids['dpoVendorDetailIdShipping'] = response.data?.data?.DpoVendorDetails[i]?.dpoVendorDetailId
                }
                else if (response.data?.data?.DpoVendorDetails[i]?.addressType === "Billing") {
                    ids['dpoVendorDetailIdBilling'] = response.data?.data?.DpoVendorDetails[i]?.dpoVendorDetailId
                }
            };

            setSpecificId(ids)

            let arrOfFiles = [];
            for (let i = 0; i < response.data.data.documentsAttached.length; i++) {
                arrOfFiles.push(response.data.data.documentsAttached[i])
            }
            setDefaultFiles(arrOfFiles)
            const product = response?.data?.data?.DpoLineItems;

            let arr = [];
            let array = [];
            for (let ele of product) {
                array.push(ele.productId)
                if (ele.discountValue) {
                    const appliedDiscount = {
                        title: ele.discountTitle,
                        value: Number(ele.discountValue),
                        valueType: ele.discountType
                    }
                    arr.push({
                        variantId: ele.productId,
                        quantity: ele.quantity,
                        appliedDiscount: appliedDiscount,
                        PCS: ele.PCS,
                        bulkPackingValue1: ele?.bulkPackingValue1,
                        bulkPackingValue2: ele?.bulkPackingValue2,
                        locationCode: ele.locationCodeForPrices,
                    })
                } else {
                    arr.push({
                        variantId: ele.productId,
                        quantity: ele.quantity,
                        PCS: ele.PCS,
                        bulkPackingValue1: ele?.bulkPackingValue1,
                        bulkPackingValue2: ele?.bulkPackingValue2,
                        locationCode: ele.locationCodeForPrices,
                    })
                }
            }
            setUpdateDiscountPercent(array)
            setDiscountPercent(array);

            const discountExist = response?.data?.data?.DpoPaymentDetails?.overallDiscountValue !== null;

            let overDiscount = {}
            if (discountExist) {
                overDiscount = {
                    title: response?.data?.data?.DpoPaymentDetails?.overallDiscountTitle,
                    value: response?.data?.data?.DpoPaymentDetails?.overallDiscountValue,
                    valueType: response?.data?.data?.DpoPaymentDetails?.overallDiscountType
                }
            }

            const variantLineItems = arr.map(obj => {
                const { variantId, ...rest } = obj;
                return { productId: variantId, ...rest };
            });

            let shipping = {}
            if (response.data?.data?.DpoShipmentDetails?.freightCharges) {
                shipping = {
                    price: response.data?.data?.DpoShipmentDetails?.freightCharges
                }
            }

            const data = {
                lineItems: variantLineItems,
                orderType: "purchase",
                shippingLine: shipping,
                overallDiscount: Object.keys(overDiscount).length !== 0 ? overDiscount : undefined,
            }

            if (Object.keys(overDiscount).length === 0) {
                setOverAllDiscount([])
            } else {
                setOverAllDiscount([overDiscount])
            }

            setShippingPrice([shipping]);

            axiosInstance
                .post(`/calculate/purchaseOrder`, { ...data })
                .then((res) => {
                    if (res.data !== undefined) {
                        setProductId(arr);
                        setProductDetails(arr);
                        setProduct(res.data);
                        setUpdateOrder(true);
                        setTimeout(() => {
                            handleEnableSelect();
                        }, 1500)
                        setUpdateToggle(false);
                        const lineItem = res.data.lineItems;
                        setLineItems([...lineItem]);

                        axiosInstance
                            .get(`/vendor/vendor/${response.data?.data?.DpoVendorDetails[0]?.vendorCode}`)
                            .then((respo) => {
                                if (respo.data.data !== undefined) {
                                    setCustomerDetail([respo?.data?.data])
                                    axiosInstance
                                        .get(`/vendor/vendorAddresses/${response.data?.data?.DpoVendorDetails[0]?.vendorCode}`)
                                        .then((resp) => {
                                            if (resp.data.data.length !== 0) {
                                                setAddressArray(resp.data.data)
                                                const addresses = resp.data.data;
                                                const shippingAddressArrays = [];
                                                const BillingAddressArrays = [];
                                                for (let address of addresses) {
                                                    if (address.addressType === 'Shipping') {
                                                        shippingAddressArrays.push(address);
                                                    } else {
                                                        BillingAddressArrays.push(address);
                                                    }
                                                }

                                                if (shippingAddressArrays.length !== 0) {
                                                    setCustomerIDAddress([...shippingAddressArrays]);
                                                }

                                                if (BillingAddressArrays.length !== 0) {
                                                    setBillingAddress([...BillingAddressArrays]);
                                                } else {
                                                    setBillingAddress([shippingAddressArrays[0]]);
                                                }
                                            }
                                            axiosInstance
                                                .get(`/vendor/vendorContactDetails/${response.data?.data?.DpoVendorDetails[0]?.vendorCode}`)
                                                .then((respon) => {
                                                    setVendorContacts(respon?.data?.data)
                                                })
                                        })
                                }

                            });
                        axiosInstance
                            .get(`/location/location/${response.data?.data?.DpoLocationDetails[0]?.locationCode}`)
                            .then((respo) => {
                                if (respo.data.data !== undefined) {
                                    setLocationDetail([respo?.data?.data])
                                    axiosInstance
                                        .get(`/location/locationAddresses/${response.data?.data?.DpoLocationDetails[0]?.locationCode}`)
                                        .then((resp) => {
                                            if (resp.data.data.length !== 0) {
                                                setLocationAddressArray(resp.data.data)
                                                const addresses = resp.data.data;
                                                const shippingAddressArrays = [];
                                                const BillingAddressArrays = [];
                                                for (let address of addresses) {
                                                    if (address.addressType === 'Shipping') {
                                                        shippingAddressArrays.push(address);
                                                    } else {
                                                        BillingAddressArrays.push(address);
                                                    }
                                                }

                                                if (shippingAddressArrays.length !== 0) {
                                                    setLocationIDAddress([...shippingAddressArrays]);
                                                }

                                                if (BillingAddressArrays.length !== 0) {
                                                    setLocationBillingAddress([...BillingAddressArrays]);
                                                } else {
                                                    setLocationBillingAddress([shippingAddressArrays[0]]);
                                                }
                                            }

                                            axiosInstance
                                                .get(`/location/locationContactDetails/${response.data?.data?.DpoLocationDetails[0]?.locationCode}`)
                                                .then((respon) => {
                                                    setLocationContacts(respon?.data?.data)
                                                })
                                        })
                                }

                            });
                    }
                });
        });
}

export const handleCalculationHelper = (
    lineItems, locationIDAddress, customerIDAddress, productDetails, overAllDiscount,
    shippingPrice, setAddToggle, updateDiscountPercent, setDiscountPercent, editDiscountPercent,
    setLineItems, setProduct
) => {
    lineItems.splice(0, lineItems.length);
    let shipFrom = ""
    let shipTo = ""
    if (locationIDAddress.length !== 0) {
        shipFrom = locationIDAddress[0].state
    }
    if (customerIDAddress.length !== 0) {
        shipTo = customerIDAddress[0].state
    }

    const variantLineItems = productDetails.map(obj => {
        const { variantId, ...rest } = obj;
        return {
            productId: variantId, ...rest
        };
    });
    const data = {
        lineItems: variantLineItems,
        shipToState: shipTo,
        shipFromState: shipFrom,
        orderType: "purchase",
        overallDiscount: overAllDiscount.length !== 0 ? overAllDiscount[0] : undefined,
        shippingLine: shippingPrice.length !== 0 ? shippingPrice[0] : undefined,
    }


    axiosInstance
        .post(`/calculate/purchaseOrder`, { ...data })
        .then((res) => {
            setAddToggle(false);
            if (updateDiscountPercent !== undefined) {
                if (updateDiscountPercent.length !== 0) {
                    setDiscountPercent(updateDiscountPercent)
                } else {
                    if (editDiscountPercent.length !== 0) {
                        setDiscountPercent([]);
                    }
                }
            }


            const obj = {...res.data};
            const lineItem = res.data.lineItems;
            setLineItems([...lineItem]);
            setProduct(obj)
        });
}

export const handleCreateDraftOrderWareHouseHelper = (
        event, sellerLocationDetail, customerLocationDetail, showProduct, productDetails, 
        customerLocationGstNumber, sellerLocationAddress,
        sellerLocationBillingAddress, sellerLocationGstNumber, overAllDiscount, paymentDetails,
        shippingPrice, setShippingDetails, shippingDetails, documentsAttached,
        sellerLocationContactDetails, customerLocationContactDetails, notes, navigate,
        setIsCreateOrderPage, customerLocationAddress, customerLocationBillingAddress, setLoading
) => {
    for (let ele of productDetails) {
        if (ele?.quantity === 0) {
            let variant = document.getElementById(ele.variantId);
            variant.scrollIntoView();
            variant.children[0].children[1].lastChild.style.display = "block";
            event.target.disabled = true;
            return;
        }
    }
    setLoading(true);
    event.target.disabled = true;
    event.target.innerHTML = 'WareHouse Inventory Move Creating...';
    let seller = sellerLocationDetail[0];
    let customer = customerLocationDetail[0];
    const taxLines = showProduct.taxLines;

    for (let product of productDetails) {
        const index = showProduct.lineItems.findIndex(variantIndex => variantIndex.productId === product.variantId);
        product.mrp = showProduct.lineItems[index].priceDataAffiliateData.mrp;
        product.unitOfMeasurement = showProduct.lineItems[index].basicCollectionDataBrandData.unit;
        product.subTotal = showProduct.lineItems[index].subTotal;
        product.discountedUnitPrice = showProduct.lineItems[index].discountedUnitPrice;
        product.discountedSubTotal = showProduct.lineItems[index].subTotal;
        product.discountedTotal = showProduct.lineItems[index].total;
        product.discountPercentage = showProduct.lineItems[index].discountPercentage;
    }

    const variantLineItems = productDetails?.map(obj => {
        const { variantId, ...rest } = obj;
        let exclGst = obj?.subTotal / obj?.quantity;
        let fetchDecimal = countPlacesHelper(exclGst);
        let rateExclGST = fetchDecimal === 1 || fetchDecimal === 0 || fetchDecimal === 2 ? exclGst : Math.trunc(exclGst * 100) / 100;
        if (!obj?.appliedDiscount) {
            return { 
                productId: variantId, 
                rateInclGST: obj?.mrp,
                rateExclGST: rateExclGST,
                overallDiscountedTotal: obj?.mrp * obj?.quantity,
                discountValue: 0,
                  ...rest };
        } else {
            return { 
                productId: variantId, 
                rateExclGST: rateExclGST, 
                ...rest 
            };
        }
    });

    const sellerLocationBillingAddressObject = {};
    const sellerLocationAddressObject = {};
    let sellerLocationDetails = null

    if (seller) {
        let obj = {}
        for (const key in seller) {
            if (key !== 'locationGstNumber') {
                Object.assign((obj[key] = `${seller[key]}`));
            } else {
                Object.assign(obj,{'locationGstNumber': sellerLocationGstNumber})
            }
        }

        seller = obj;
        const locationAddress = sellerLocationAddress[0];
        for (const key in locationAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'

            ) {
                if (key === 'locationId' || key === 'locationAddressesId') {
                    Object.assign((sellerLocationAddressObject[key] = locationAddress[key]));
                } else {
                    Object.assign((sellerLocationAddressObject[key] = `${locationAddress[key]}`));
                }
            }
        }

        let locationBilling = sellerLocationBillingAddress.length === 0 ? sellerLocationAddress[0] : sellerLocationBillingAddress[0];

        for (const key in locationBilling) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                if (key === 'locationId' || key === 'locationAddressesId') {
                    Object.assign((sellerLocationBillingAddressObject[key] = locationBilling[key]));
                } else {
                    Object.assign((sellerLocationBillingAddressObject[key] = `${locationBilling[key]}`));
                }
            }
        }

        sellerLocationBillingAddressObject['addressType'] = "Billing"
        sellerLocationAddressObject['addressType'] = "Shipping"
        sellerLocationAddressObject['locationGstNumber'] = sellerLocationGstNumber
        sellerLocationBillingAddressObject['locationGstNumber'] = sellerLocationGstNumber
        sellerLocationDetails = [sellerLocationAddressObject, sellerLocationBillingAddressObject]
    }

    const customerLocationBillingAddressObject = {};
    const customerLocationAddressObject = {};
    let customerLocationDetails = null;

    if (customer) {
        const locationAddress = customerLocationAddress[0];
        let obj = {}
        for (const key in customer) {
            if (key !== 'locationGstNumber') {
                Object.assign((obj[key] = `${customer[key]}`));
            } else {
                Object.assign(obj,{'locationGstNumber': customerLocationGstNumber})
            }
        }
        customer = obj;
        for (const key in locationAddress) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                if (key === 'locationId' || key === 'locationAddressesId') { 
                    Object.assign((customerLocationAddressObject[key] = locationAddress[key]));
                } else {
                    Object.assign((customerLocationAddressObject[key] = `${locationAddress[key]}`));
                }
            }
        }

        let locationBilling = customerLocationBillingAddress.length === 0 ? customerLocationAddress[0] : customerLocationBillingAddress[0];

        for (const key in locationBilling) {
            if (
                key !== 'productId' &&
                key !== '__v' &&
                key !== 'user' &&
                key !== 'default' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'vendorAddressesId' &&
                key !== 'vendorId' &&
                key !== 'active'
            ) {
                if (key === 'locationId' || key === 'locationAddressesId') { 
                    Object.assign((customerLocationBillingAddressObject[key] = locationBilling[key]));
                } else {
                    Object.assign((customerLocationBillingAddressObject[key] = `${locationBilling[key]}`));
                }
            }
        }

        customerLocationBillingAddressObject['addressType'] = "Billing"
        customerLocationAddressObject['addressType'] = "Shipping"
        customerLocationAddressObject['locationGstNumber'] = customerLocationGstNumber
        customerLocationBillingAddressObject['locationGstNumber'] = customerLocationGstNumber
        customerLocationDetails = [customerLocationAddressObject, customerLocationBillingAddressObject]
    }

    let paymentObject
    if (overAllDiscount.length !== 0) {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode ? 
            paymentDetails?.paymentMode === 'cod' ? 'COD': paymentDetails?.paymentMode : 'COD',
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
            "overallDiscountType": overAllDiscount[0]?.valueType,
            "overallDiscountValue": overAllDiscount[0]?.value,
            "overallDiscountTitle": overAllDiscount[0]?.title,
        }
    } else {
        paymentObject = {
            "totalAmount": showProduct?.total,
            "totalTaxAmount": showProduct?.totalTaxAmount,
            "subTotalAmount": showProduct?.subTotal,
            "paymentMode": paymentDetails?.paymentMode ? 
            paymentDetails?.paymentMode === 'cod' ? 'COD': paymentDetails?.paymentMode : 'COD',
            "invoiceNumber": paymentDetails?.invoiceNumber,
            "invoiceDate": paymentDetails?.invoiceDate,
        }
    }

    if (shippingPrice.length !== 0) {
        setShippingDetails({
            ...shippingDetails,
            "freightCharges": parseFloat(shippingPrice[0]?.price),
            "transporterName": shippingPrice[0]?.title,

        })
    }

    const formData = new FormData()

    for (let i = 0; i < documentsAttached.length; i++) {
        formData.append("documentsAttached", documentsAttached[i]);
    }

    const data = {
        "lineItems": variantLineItems,
        "sellerLocationDetails": {...sellerLocationContactDetails,...seller},
        "sellerLocationAddresses": sellerLocationDetails,
        "customerLocationDetails": {...customerLocationContactDetails,...customer},
        "customerLocationAddresses": customerLocationDetails,
        "shipmentDetail": shippingDetails,
        "paymentDetail": paymentObject,
        "taxLines": taxLines,
        "notes": notes,
        "type" : "B2B",
        "tags": ["Warehouse To WareHouse"]
    }

    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null && value !== undefined && value !== "")
    );

    formData.append('data', JSON.stringify(filteredData));

    axiosInstance
        .post('/interWarehouseInvMove/createInterWareHouseInventoryMove', formData)
        .then((res) => {
            setLoading(false);
            event.target.innerHTML = 'WareHouse Inventory Move  Created';
            setIsCreateOrderPage(false);
            navigate(`/purchaseOrders/${res?.data?.data?.completedPurchaseOrder?.purchaseOrderName}`);
        })
        .catch((err) => {
            setLoading(false);
            console.log(err);
            event.target.disabled = false;
            event.target.innerHTML = 'Create WareHouse Inventory Move';
        });
}