import { Fragment, useEffect, useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Classes from './css/product.module.css';
import axiosInstance from '../../../utility/axios-instance';
import ConfirmPopup from './confirmPopup';
import { useSelector, useDispatch } from 'react-redux';
import { downloadCSV } from "../../../utility/downloadCSV";
import Permission from '../../auth/permissions';
import NewPagination from '../../../components/newPagination';
import SettingsPopup from './settingsPopup';
import Spinner from '../../../components/spinner';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Chip from '@mui/material/Chip';
import { Paper, InputAdornment, TextField } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { GridToolbar } from '@mui/x-data-grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setProducts } from '../../../store/productsSlice';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Products = (props) => {
  // const [products, setProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState('');
  const [brands, setBrands] = useState([]);
  const [productId, setProductId] = useState([]);
  const [isDisabled, setDisabled] = useState(true);
  const [showConfirmPopup, setConfirmShowPopup] = useState(false);
  const [showErrorMsg, setErrorMsg] = useState('');
  const [skuName, setSkuName] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [fieldName, setFieldName] = useState([]);
  const [brand, setBrand] = useState('');
  const [searchBrand, setSearchBrand] = useState('');
  const [sorting, setSorting] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({ field: 'productName', sort: 'asc' });
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

  const products = useSelector((state) => state.products);
  const pinTab = useSelector((state) => state.pinTab);
  const dispatch = useDispatch();

  const columns = [
    {
      flex: 0.4,
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'image', filterable: false, headerName: 'IMAGE', flex: .5, headerClassName: 'super-app-theme--header', sortable: false,
      renderCell: (params) => (
        <img
          src={
            params.value !== undefined ? params.value : ''
          }
          alt='icon'
          className={Classes.ProductImages}
        />
      )
    },
    { field: 'productName', filterable: false, headerName: 'PRODUCT', flex: 2, headerClassName: 'super-app-theme--header', sortable: true },
    {
      field: 'status',
      headerName: 'STATUS',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: true,
      flex: 0.8,
      renderCell: (params) => (
        params.value ? (
          <Chip label="Active" sx={{ backgroundColor: 'darkcyan', color: 'white' }} />
        ) : (
          <Chip label="Draft" />
        )
      )
    },
    { field: 'sku', filterable: false, headerName: 'SKU', flex: 1.2, headerClassName: 'super-app-theme--header', sortable: true },
    { field: 'brandName', filterable: false, headerName: 'BRAND', flex: 0.8, headerClassName: 'super-app-theme--header', sortable: true },
  ];


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("importForm").reset();
    document.getElementById("updateForm").reset();
  };

  const navigate = useNavigate();

  document.addEventListener('mouseup', function (e) {
    let container = document.getElementById('modalOutline');
    if (container !== null) {
      if (!container.contains(e.target)) {
        container.style.border = '1px solid rgb(206, 212, 218)';
      }
    }
  });



  const handleExport = (event) => {
    event.preventDefault();
    setLoading(true);
    axiosInstance
      .get(`/exports/products`)
      .then((result) => {
        const arrayOfRowObjects = result?.data?.data
        downloadCSV(arrayOfRowObjects, `Products export - ${new Date()}`);
        const successMessage = "Products exported successfully!"
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error)
        const errorMessage = error?.response?.data?.message
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
        });
      })
  }

  const handleDeleteCustomer = () => {
    setLoading(true);
    axiosInstance
      .post('/product/deleteProductsInBulk/', {
        productsId: productId
      })
      .then((res) => {
        const arr = []
        setProductId(arr);
        document.getElementById('parentBox').checked = false;
        const successMessage = "Products deleted successfully!"
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
        });
        fetchProduct();
        setLoading(false);
      })
    setLoading(false);
  }

  const addProductId = (Id) => {
    if (productId.length === 0) {
      setProductId([Id]);
    } else {
      for (let ele of productId) {
        if (ele !== Id) {
          setProductId([...productId, Id]);
        }
      }
    }
  };

  const removeProductId = (Id) => {
    const array = productId.filter((product) => product !== Id)
    setProductId(array)

    if (array.length === 0) {
      setDisabled(true);
    }
  }

  const handleCheckBox = (event) => {
    const arr = [];
    if (event.target.checked) {
      const child = document.getElementsByClassName('childNode');
      for (let childNode of child) {
        const childCheckBox = childNode.firstChild.firstChild
        if (!childCheckBox.checked) {
          childCheckBox.checked = true;
          arr.push(childCheckBox.id)
        }
      }
      setDisabled(false);
      setProductId(arr);
    } else {
      const child = document.getElementsByClassName('childNode')
      for (let childNode of child) {
        const childCheckBox = childNode.firstChild.firstChild;
        if (childCheckBox.checked) {
          childCheckBox.checked = false;
        }
      }
      setDisabled(true);
      const arr = [];
      setProductId(arr);
    }
  }

  const handleChildNode = (event) => {
    // event.stopPropagation()
    const parent = document.getElementById('parentBox')
    if (event.target.checked) {
      if (!parent.checked) {
        parent.checked = true;
      }
      setDisabled(false);
      addProductId(event.target.id)
    } else {
      const child = document.getElementsByClassName('childNode')
      for (let childNode of child) {
        const childCheckBox = childNode.firstChild.firstChild.childNodes[1].firstChild;

        if (childCheckBox.checked) {
          removeProductId(event.target.id)
          parent.checked = true;
          return;
        }
      }
      removeProductId(event.target.id);
      parent.checked = false;
    }
  }


  const handleShowPopup = (event) => {
    event.preventDefault();
    setConfirmShowPopup(true)
  }

  const hideConfirmPopup = (event) => {
    if (event.target.innerText === 'Yes') {
      if (productId.length !== 0) {
        handleDeleteCustomer();
      }
    }
    setConfirmShowPopup(false)
  }

  const handleUpdateAvailableQty = async (event) => {
    setLoading(true);
    event.preventDefault();
    await axiosInstance
      .get(`/product/updateInventory`)
      .then((res) => {
        if (res.data.status === 'success') {
          const successMessage = "Inventory updated successfully!"
          setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
          });
          document.getElementById('errorPopup').style.display = 'none';
        }
        else {
          alert('Something went wrong')
        }
      });
    setLoading(false);
  }

  const handleUpdateIndexSortKey = async (event) => {
    setLoading(true);
    event.preventDefault();
    await axiosInstance
      .get(`/product/assignIndex`)
      .then((res) => {
        if (res.data.status === 'success') {
          const successMessage = "Index & Sort-key updated successfully!"
          setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
          });
          document.getElementById('errorPopup').style.display = 'none';
        }
        else {
          alert('Something went wrong')
        }
      });
    setLoading(false);

  }

  const handleProduct = async (event) => {
    const params = {
      page: 1,
      text: event.target.value,
      brand: brand,
      newSort: JSON.stringify(sort) ? JSON.stringify(sort) : null,
  };

    setSearchProduct(event.target.value);
    await axiosInstance
      .get(`/product`, {
        params,
    })
      .then((res) => {
        dispatch(setProducts(res.data.data));
      });
  }
  const handleBrand = async (event, value) => {
    setLoading(true);
    setBrand(value.brandAbr)
    setSearchProduct(value.brandAbr);
    setSearchBrand(value.brandAbr)
    let brand;
    if (value.brandAbr === 'N&H') {
      brand = `N%26H`
    } else {
      brand = `${value.brandAbr}`
    }

    await axiosInstance
      .get(`/product?page=${1}&brand=${brand}&sort=${sorting}`)
      .then((res) => {
        dispatch(setProducts(res.data.data));
      });
    setLoading(false);
  }

  const fetchProduct = async (type) => {
    setLoading(true);
    let brand;
    if (searchBrand === 'N&H') {
      brand = `N%26H`
    } else {
      brand = `${searchBrand}`
    }
    
    const params = {
      page: type,
      text: searchProduct,
      brand: brand,
      newSort: JSON.stringify(sort) ? JSON.stringify(sort) : null,
      sort: sorting
  };

    await axiosInstance
      .get(`/product`, {
        params,
      })
      .then((res) => {
        dispatch(setProducts(res.data.data));
      });
    setLoading(false);
  };

  const handleUpdate = (event) => {
    setLoading(true);
    event.preventDefault();
    var formData = new FormData();
    formData.append("products", document.getElementById('updateInput').files[0]);
    document.getElementById("updateForm").reset();

    axiosInstance
      .post('/product/updateProduct', formData)
      .then(result => {
        setLoading(false);
        if (result.data.status === 'Server Busy') {
          alert('Server Busy.. Try Again Later');
          event.target.innerHTML = 'Update Product';
        }
        if (result.data.notExistSku.length !== 0) {
          const successMessage = "File imported succesfully!"
          setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
          });
          setErrorMsg('This sku not exist in database');
          setSkuName(result.data.notExistSku);
          setTimeout(() => {
            document.getElementById('errorPopup').style.display = 'block';
          }, 10000)
        } else if (result.data.mandatoryFieldSku.length !== 0) {
          let text;
          if (result.data.mandatoryFieldSku.length === 1) {
            text = 'this'
          } else {
            text = 'these'
          }
          setErrorMsg(`Please must fill sku for updating ${text} product`);
          // setFieldName(["sku"]);
          setSkuName(result.data.mandatoryFieldSku)
          document.getElementById('errorPopup').style.display = 'block';
          setTimeout(() => {
            document.getElementById('errorPopup').style.display = "none"
          }, 10000);
        } else if (result.data.fieldName.fieldName.length !== 0) {
          setErrorMsg(`Please fill valid`);
          setErrorFields(result.data.fieldName.fieldName);
          setSkuName(result.data.fieldName.sku)
          document.getElementById('errorPopup').style.display = 'block';
          setTimeout(() => {
            document.getElementById('errorPopup').style.display = "none";
          }, 10000);
        } else {
          document.getElementById('errorPopup').style.display = 'none';
          const successMessage = "File updated succesfully!"
          setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
          });
        }
        fetchProduct();
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error)
        const errorMessage = error?.response?.data?.message
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
        });
      })
  }

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false }
    });
  };

  useEffect(() => {
    // fetch Product
    if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Products List')) {
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page');
      fetchProduct(page ? page : 1);
    }
  }, [sort]);


  useEffect(() => {
    axiosInstance
      .get('/others/adminBrands')
      .then((res) => {
        setBrands(res.data.data)
      })
  }, []);

  const handleSorting = async (event) => {
    setLoading(true);
    setSorting(event.target.value)
    setSearchProduct(searchProduct);
    setSearchBrand(searchBrand)
    setBrand(searchBrand)
    let brand;
    if (searchBrand === 'N&H') {
      brand = `N%26H`
    } else {
      brand = `${searchBrand}`
    }

    await axiosInstance
      .get(`/product?page=${1}&sort=${event.target.value}&brand=${brand}`)
      .then((res) => {
        dispatch(setProducts(res.data.data));
      });
    setLoading(false);
  }

  return (
    <Fragment>
      <div id="color">
        <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
          <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
            {snackBar?.message}
          </Alert>
        </Snackbar>

        <div
          className="alert alert-primary p-2"
          data-file-upload-alert role="alert"
          style={{ "display": "none" }}
          id='updateNotification'
        >
          File Successfully Updated
        </div>

        {/* <div className="d-flex flex-column flex-md-row justify-content-between mb-3"> */}
          <div 
            className={Classes.FileContainer} 
            style={{justifyContent:'end',marginTop:-25,marginBottom:10}}
          >
            <Permission service="products" permission="create">
              <div style={{marginRight:10,marginBottom:5}}>
                <Link to='/products/addProduct'>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AddIcon />}
                    style={{textTransform:'capitalize',padding:"4px 12px"}}
                    sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                  >
                    Create
                  </Button>
                </Link>
              </div>
            </Permission>

            <div style={{ display: 'flex', gap: '10px',marginRight:10,marginBottom:5 }}>
              <Permission service="products" permission="delete">
                <div >
                  <Button
                    disabled={productId.length === 0 ? true : false}
                    variant='contained'
                    color='error'
                    id='deleteProduct'
                    startIcon={<DeleteIcon />}
                    onClick={handleShowPopup}
                    style={{textTransform:'capitalize',padding:"4px 12px"}}
                    sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                  >
                    Delete
                  </Button>
                </div>
              </Permission>
              <Permission service="products" permission="update">
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleClickOpen}
                  style={{textTransform:'capitalize',padding:"4px 12px"}}
                  sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                  startIcon={<FileUploadIcon />}
                >
                  Import / Update
                </Button>
              </Permission>
            </div>
              
            <Permission service="products" permission="read">
              <Button
                onClick={handleExport}
                variant='contained'
                color='success'
                style={{textTransform:'capitalize',padding:"4px 12px"}}
                sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                startIcon={<FileDownloadIcon />}
              >
                Export
              </Button>
            </Permission>
          </div>
        {/* </div> */}

        <div id='errorPopup' className={Classes.showErrorMsg}>
          <div>
            <p>
              {showErrorMsg}
              {errorFields.length !== 0 && (
                <>
                  {errorFields.length === 1 ? (
                    <span style={{ padding: '0px 5px' }}>
                      {errorFields[0]}
                    </span>
                  ) : (
                    <>
                      {errorFields.map((field, index) => (
                        <span key={index} style={{ padding: '0px 5px' }}>
                          {field},
                        </span>
                      ))}
                    </>
                  )}

                  of {skuName.length !== 0 && (
                    <>
                      {skuName.length === 1 ? (
                        <span style={{ paddingRight: 5 }}>
                          this
                        </span>
                      ) : (
                        <span style={{ paddingRight: 5 }}>
                          these
                        </span>
                      )}
                    </>
                  )}
                  sku.
                </>
              )}
            </p>

            {fieldName.length !== 0 && (
              <div style={{ display: 'flex', padding: 0 }}>
                {fieldName.map((field, index) => (
                  <p style={{ marginRight: 10 }} key={index}>
                    &#x2022;{field}
                  </p>
                ))}
              </div>
            )}
            {skuName.length !== 0 && (
              <div>
                {skuName.map((sku, index) => (
                  <p key={index} style={{ fontSize: 13 }}>
                    &#x2022; {sku}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={Classes.Container}>
          <div className={Classes.ProductContainer}>
            <div style={{ display:'flex',padding:10,flexWrap:'wrap' }}>
              <div className={Classes.SearchDraftOrder}>
                <TextField
                  id="search"
                  type="search"
                  label="Search"
                  onChange={(e) => handleProduct(e)}
                  autoComplete='off'
                  sx={{ width: '100%', "& label": { top: "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={Classes.CreatedByFilter} style={{ display: 'flex' }}>
                <div className={Classes.BrandFilter}>
                  <Autocomplete
                    size="small"
                    disableClearable
                    disablePortal
                    id="brandSelect"
                    onChange={handleBrand}
                    options={brands}
                    getOptionLabel={(brand) => brand.title}
                    ListboxProps={{sx: { fontSize: 14 }}}
                    renderInput={(params) => <TextField  {...params} label="Brand" />}
                  />
                </div>
                <div className={Classes.SortingFilter}>
                  <FormControl fullWidth>
                    <InputLabel id="sortLable">Sort</InputLabel>
                    <Select
                      label="Sort"
                      sx={{ width: '100%' }}
                      labelId='sortLable'
                      id='sort'
                      size='small'
                      name='sort'
                      defaultValue={'DEFAULT'}
                      ListboxProps={{sx: { fontSize: 14 }}}
                      onChange={(e) => handleSorting(e)}
                    >
                      <MenuItem value='DEFAULT' disabled={true} style={{fontSize:14}}>
                        Sort
                      </MenuItem>
                      <MenuItem value='product_title_a-z' style={{fontSize:14}}>
                        Product title A-Z
                      </MenuItem>
                      <MenuItem value='product_title_z-a' style={{fontSize:14}}>
                        Product title Z-A
                      </MenuItem>
                      <MenuItem value='created_oldest_first' style={{fontSize:14}}>
                        Created (oldest first)
                      </MenuItem>
                      <MenuItem value='created_newest_first' style={{fontSize:14}}>
                        Created (newest first)
                      </MenuItem>
                      <MenuItem value='updated_oldest_first' style={{fontSize:14}}>
                        Updated (oldest first)
                      </MenuItem>
                      <MenuItem value='updated_newest_first' style={{fontSize:14}}>
                        Updated (newest first)
                      </MenuItem>
                      <MenuItem value='low_inventory' style={{fontSize:14}}>
                        Low inventory
                      </MenuItem>
                      <MenuItem value='high_inventory' style={{fontSize:14}}>
                        High inventory
                      </MenuItem>
                      <MenuItem value='brand_a-z' style={{fontSize:14}}>
                        Brand A-Z
                      </MenuItem>
                      <MenuItem value='brand_z-a' style={{fontSize:14}}>
                        Brand Z-A
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none">
              <div
                className={Classes.ProductHeader}
                style={{ backgroundColor: '#243750', borderRadius: '5px', color: '#fff', paddingTop: 6 }}
              >
                <div style={{ width: '6%', marginLeft: 15 }}>
                  <input
                    type='checkbox'
                    style={{ width: 17, height: 17 }}
                    id='parentBox'
                    className={Classes.checkBox}
                    onChange={handleCheckBox}
                  />
                </div>

                <div style={{ width: '54%', margin: 'auto 25px auto 15px' }}>
                  <p>PRODUCT</p>
                </div>

                <div style={{ width: '14%', margin: 'auto' }}>
                  <p>STATUS</p>
                </div>

                <div style={{ width: '14%', margin: 'auto' }}>
                  <p>SKU</p>
                </div>

                <div style={{ width: '10%', margin: 'auto' }}>
                  <p>BRAND</p>
                </div>
              </div>

              {products?.products?.length !== 0 && products?.products?.map((product) => (
                <div
                  key={product?._id}
                  className={[Classes.Box, 'childNode'].join(' ')}
                >
                  <div
                    className={Classes.Boxes}
                    style={{ cursor:'pointer',display:'flex',width:'100%' }}
                  >
                    <div className={Classes.Name}>
                      <div style={{display:'flex',width:'94%'}} onClick={() => navigate(product?._id)}>
                        <div className={Classes.ProductImages} style={{margin:0,marginRight:5,width:'12%'}}>
                          <img
                            src={
                              product.images && product?.images["400x400"]?.mainImageUrl ?
                              product?.images["400x400"]?.mainImageUrl :
                              "https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png"
                            }
                            alt='icon'
                            style={{width:'100%',height:"100%"}}
                          />
                        </div>

                        <div style={{width:'80%',marginRight:5}}>
                          <p className={Classes.ProductHeadText}>
                            {product.basicCollectionDataBrandData.productName}
                          </p>

                          <div className={['d-md-none',Classes.ProductDetails].join(' ')}>
                            <p className='text-muted' style={{marginRight:5}}>SKU:</p>
                            <p className='text-muted'>{product.basicCollectionDataBrandData.sku}</p>
                          </div>

                          <div className={['d-md-none',Classes.ProductVendorText].join(" ")}>
                            <p className='text-muted' style={{marginRight:5}}>Brand:</p>
                            <p className='text-muted'>{product.basicCollectionDataBrandData.brandName}</p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>Status:</p>
                            {product?.status?.isActive !== undefined ? (
                              <p style={{ backgroundColor:'darkcyan',color:'white',padding:'0px 10px',borderRadius:10 }} >Active</p>
                            ) : (
                              <p style={{ padding:'0px 10px',borderRadius:10 }}>Draft</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div style={{width:'6%'}}>
                        <input
                          type="checkbox"
                          className={['parentNode', Classes.checkBox].join(' ')}
                          style={{ width: 17, height: 17 }}
                          id={product._id}
                          onChange={handleChildNode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {products?.products?.length !== 0 && (
              <div className="d-none d-sm-block" style={{ display:'flex', justifyContent: "center", width: '100%', alignItems:"center" }} >
                <Box className="shadow"
                  component={Paper}
                  sx={{
                    '& .super-app-theme--header': {
                      backgroundColor: '#243750',
                      color: '#ffffff',
                      cursor: "default",
                    },
                  }}>
                  <DataGrid
                    disableColumnFilter
                    slots={{ toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                      },
                    }}
                    rows={products?.products?.map((product) => ({
                      image: product.images && product?.images["400x400"]?.mainImageUrl ?
                        product?.images["400x400"]?.mainImageUrl :
                        "https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png"
                      ,
                      productName: product?.basicCollectionDataBrandData?.productName,
                      id: product._id,
                      sku: product.basicCollectionDataBrandData.sku,
                      brandName: product.basicCollectionDataBrandData.brandName,
                      status: product?.status?.isActive,
                    }))}
                    columns={columns}
                    filterMode="server"
                    sortingMode="server"
                    paginationMode="server"
                    disableRowSelectionOnClick
                    checkboxSelection={true}
                    // loading={loading}
                    autoHeight
                    autoPageSize
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setProductId(newRowSelectionModel)
                    }}
                    sx={{
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      ".PrivateSwitchBase-input": {
                        height: "23px",
                        margin: "10px 13px",
                        width: "20px"
                      },
                      cursor: "pointer",
                      ".MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                    }}
                    onRowClick={(params) => {
                      navigate(`${params?.id}`);
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'productName', sort: 'asc' }],
                      },
                    }}
                    onSortModelChange={(sort) => setSort(sort[0])}
                  />
                </Box>
              </div>
            )}

            <div className={showConfirmPopup ? Classes.UpdateSalesDialog : undefined}>
              <ConfirmPopup
                showConfirmPopup={showConfirmPopup}
                hideConfirmPopup={hideConfirmPopup}
                productId={productId}
              />
            </div>
          </div>
        </div>
        {loading && <Spinner />}

        <SettingsPopup
          open={open}
          handleClose={handleClose}
          Classes={Classes}
          // handleImport={handleImport}
          fetchProduct={fetchProduct}
          setFieldName={setFieldName}
          setSkuName={setSkuName}
          setErrorMsg={setErrorMsg}
          setErrorFields={setErrorFields}
          // handleUpdate={handleUpdate}
          handleUpdateAvailableQty={handleUpdateAvailableQty}
          handleUpdateIndexSortKey={handleUpdateIndexSortKey}
        />

        <div
          style={{
            marginTop: 10,
            margin: '10px auto 0px'
          }}
        >
          {products?.products?.length !== 0 ? (
            <nav aria-label="Page navigation">
              <NewPagination
                totalPage={products}
                fetchData={fetchProduct}
              />
            </nav>
          ) : null}
        </div>
      </div>
    </Fragment>
  )
};


export default Products;