import axiosInstance from '../../../../utility/axios-instance';

export const handleGstRateFile = 
    (
        event, setDiscountArr,setListDiscount, setRetailerDiscount, setInteriorDiscount,
        setOemDiscount, setEndUserDiscount, setLimitPrice
    ) => {

    if (event.target.value) {
        if (document.getElementById('product_title').value) {
            if (document.getElementById('mrp').value) {
                if (document.getElementById('dsinCode').value) {
                    document.getElementById('database').checked = true;
                }
            }
        }
    }
    const MRP = parseFloat(document.getElementById('mrp').value);
    const listDiscount = parseInt(document.getElementById('listDiscount').value)
    const retailerDiscount = parseInt(document.getElementById('retailerDiscount').value)
    const interiorDiscount = parseInt(document.getElementById('interiorDiscount').value)
    const oemDiscount = parseInt(document.getElementById('oemDiscount').value);
    const endUser = parseInt(document.getElementById('endUser').value)
    let discountArr = []
    if(!isNaN(listDiscount)){
        const Discount = (MRP-(listDiscount * (1+(event.target.value/100))))/MRP;
        const amount = 	Discount * 100;
        const result = Math.round(amount * 100) / 100;
        const listAmount = MRP / (1+(event.target.value/100));
        if (Discount < 0) {
            setLimitPrice(Math.floor(listAmount * 100) / 100);
            document.getElementById('listDiscountErrorPopUp').style.display= 'block';
        } else {
            document.getElementById('listDiscountErrorPopUp').style.display= 'none';
            discountArr.push({'listDiscount': result});
            setListDiscount(result);
        }
        if(!isNaN(retailerDiscount)){
            const Discount = (MRP-(retailerDiscount * (1+(event.target.value/100))))/MRP	
            const amount = Discount * 100;
            const result = Math.floor(amount * 100) /100;
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('RetailerDiscountErrorPopUp').style.display = 'block';
            } else {
                document.getElementById('RetailerDiscountErrorPopUp').style.display = 'none';
                discountArr.push({'retailerDiscount': result});
                setRetailerDiscount(result);
            }
            if(!isNaN(interiorDiscount)){
                const Discount = (MRP-(interiorDiscount * (1+(event.target.value/100))))/MRP
                const amount = 	Discount * 100
                const result = Math.floor(amount * 100) / 100;
                if (Discount < 0) {
                    setLimitPrice(Math.floor(listAmount * 100) / 100);
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'block';
                } else {
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'none';
                    discountArr.push({'interiorDiscount': result});
                    setInteriorDiscount(result);
                }
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(event.target.value/100))))/MRP	
                    const amount = Discount * 100;
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP	
                        const amount = Discount * 100;
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP	
                        const amount = Discount * 100;
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }else{
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(event.target.value/100))))/MRP
                    const amount = 	Discount * 100
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP
                        const amount = Discount * 100	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP
                        const amount = Discount * 100
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }
        }else{
            if(!isNaN(interiorDiscount)){
                const Discount = (MRP-(interiorDiscount * (1+(event.target.value/100))))/MRP
                const amount = Discount * 100;	
                const result = Math.floor(amount * 100) / 100;
                if (Discount < 0) {
                    setLimitPrice(Math.floor(listAmount * 100) / 100);
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'block';
                } else {
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'none';
                    discountArr.push({'interiorDiscount': result});
                    setInteriorDiscount(result);
                }
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(event.target.value/100))))/MRP
                    const amount = Discount * 100;
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP
                        const amount = Discount * 100;	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP
                        const amount = Discount * 100;	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }else{
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(event.target.value/100))))/MRP	
                    const amount = Discount * 100;
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP
                        const amount = Discount * 100;	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(event.target.value/100))))/MRP	
                        const amount = Discount * 100;
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }
        }
        setDiscountArr(discountArr)
    }
}

export const checkGroupTitleFile = 
    (title, setPrevGroupName
    ) => {
    axiosInstance
        .get(`/product/checkExistProduct/${title}`)
        .then((res) => {
            if (res.data.exist) {
                setPrevGroupName(title)
                document.getElementById('GroupNameBox').style.display = 'block';
            } else {
                document.getElementById('GroupNameBox').style.display = 'none';
            }
        })
}

export const handleDiscountFile = 
    (
        event, Id, storeDiscountArr, setListDiscount, setDiscountArr, 
        setRetailerDiscount, setInteriorDiscount, setOemDiscount,
        setEndUserDiscount, setLimitPrice,
    ) => {
    if (event.target.value) {
        let discount = {}
        const MRP = parseFloat(document.getElementById('mrp').value);
        const DP = parseFloat(event.target.value)
        const GST = parseInt(document.getElementById('gstRate').value);
        const Discount = (MRP-(DP * (1+(GST/100))))/MRP
        const amount = Discount * 100;
        const result = Math.floor(amount * 100)/100;
        const listAmount = MRP / (1+(GST/100));
        if(event.target.id === 'listDiscount'){
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('listDiscountErrorPopUp').style.display= 'block';

                let discountArr = [];
                for(let discount of storeDiscountArr){
                    if(Object.keys(discount)[0] !== event.target.id){
                        discountArr.push(discount);
                    }
                }
                setDiscountArr(discountArr);
            }else {
                document.getElementById('listDiscountErrorPopUp').style.display= 'none';

                if(storeDiscountArr.length === 0){
                    discount = {'listDiscount': result}
                    setListDiscount(result)
                    setDiscountArr([discount]);
                }else{
                    for(let discountType of storeDiscountArr){
                        if(Object.keys(discountType)[0] === event.target.id){
                            discountType.listDiscount = result;
                            setDiscountArr([...storeDiscountArr]);
                            return;
                        }
                    }
                    discount = {'listDiscount': result}
                    setDiscountArr([...storeDiscountArr,discount]);
                }
            }
        }else if(event.target.id === 'retailerDiscount'){
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('RetailerDiscountErrorPopUp').style.display = 'block';

                let discountArr = [];
                for(let discount of storeDiscountArr){
                    if(Object.keys(discount)[0] !== event.target.id){
                        discountArr.push(discount);
                    }
                }
                setDiscountArr(discountArr);
            }else {
                document.getElementById('RetailerDiscountErrorPopUp').style.display = 'none';

                if(storeDiscountArr.length === 0){
                    discount = {'retailerDiscount': result}
                    setDiscountArr([discount]);
                }else{
                    for(let discountType of storeDiscountArr){
                        if(Object.keys(discountType)[0] === event.target.id){
                            discountType.retailerDiscount = result;
                            setDiscountArr([...storeDiscountArr]);
                            return;
                        }
                    }
                    discount = {'retailerDiscount': result}
                    setRetailerDiscount(result)
                    setDiscountArr([...storeDiscountArr,discount]);
                }
            }
        }else if(event.target.id === 'interiorDiscount'){
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('InteriorDiscountErrorPopUp').style.display = 'block';

                let discountArr = [];
                for(let discount of storeDiscountArr){
                    if(Object.keys(discount)[0] !== event.target.id){
                        discountArr.push(discount);
                    }
                }
                setDiscountArr(discountArr);
            } else {
                document.getElementById('InteriorDiscountErrorPopUp').style.display = 'none';

                if(storeDiscountArr.length === 0){
                    discount = {'interiorDiscount': result}
                    setDiscountArr([discount]);
                }else{
                    for(let discountType of storeDiscountArr){
                        if(Object.keys(discountType)[0] === event.target.id){
                            discountType.interiorDiscount = result;
                            setDiscountArr([...storeDiscountArr]);
                            return;
                        }
                    }
                    discount = {'interiorDiscount': result}
                    setInteriorDiscount(result)
                    setDiscountArr([...storeDiscountArr,discount]);
                }
            }
        }else if(event.target.id === 'oemDiscount'){
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('oemDiscountErrorPopUp').style.display = 'block';

                let discountArr = [];
                for(let discount of storeDiscountArr){
                    if(Object.keys(discount)[0] !== event.target.id){
                        discountArr.push(discount);
                    }
                }
                setDiscountArr(discountArr);
            } else {
                document.getElementById('oemDiscountErrorPopUp').style.display = 'none';

                if(storeDiscountArr.length === 0){
                    discount = {'oemDiscount': result}
                    setDiscountArr([discount]);
                }else{
                    for(let discountType of storeDiscountArr){
                        if(Object.keys(discountType)[0] === event.target.id){
                            discountType.oemDiscount = result;
                            setDiscountArr([...storeDiscountArr]);
                            return;
                        }
                    }
                    discount = {'oemDiscount': result}
                    setOemDiscount(result);
                    setDiscountArr([...storeDiscountArr,discount]);
                }
            }
        }else{
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';

                let discountArr = [];
                for(let discount of storeDiscountArr){
                    if(Object.keys(discount)[0] !== event.target.id){
                        discountArr.push(discount);
                    }
                }
                setDiscountArr(discountArr);
            } else {
                document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';

                if(storeDiscountArr.length === 0){
                    discount = {'endUser': result}
                    setDiscountArr([discount]);
                }else{
                    for(let discountType of storeDiscountArr){
                        if(Object.keys(discountType)[0] === event.target.id){
                            discountType.endUser = result;
                            setDiscountArr([...storeDiscountArr]);
                            return;
                        }
                    }
                    discount = {'endUser': result}
                    setEndUserDiscount(result);
                    setDiscountArr([...storeDiscountArr,discount]);
                }
            }
        }
    } else {
        document.getElementById(Id).style.display = 'none';
        let discountArr = [];
        for(let discount of storeDiscountArr){
            if(Object.keys(discount)[0] !== event.target.id){
                discountArr.push(discount);
            }
        }
        setDiscountArr(discountArr)
    }	
}

export const handleMRPFile = 
    (
        setDiscountArr,setListDiscount, setRetailerDiscount, 
        setInteriorDiscount,  setOemDiscount, setEndUserDiscount,
        setLimitPrice
    ) => {
    const MRP = parseFloat(document.getElementById('mrp').value);
    const listDiscount = parseFloat(document.getElementById('listDiscount').value)
    const retailerDiscount = parseFloat(document.getElementById('retailerDiscount').value)
    const interiorDiscount = parseFloat(document.getElementById('interiorDiscount').value)
    const oemDiscount = parseFloat(document.getElementById('oemDiscount').value);
    const endUser = parseFloat(document.getElementById('endUser').value);
    const GST = parseInt(document.getElementById('gstRate').value);
    let discountArr = []
    if(!isNaN(listDiscount)){
        const Discount = (MRP-(listDiscount * (1+(GST/100))))/MRP;
        const amount = 	Discount * 100;
        const result = Math.floor(amount * 100) / 100;
        const listAmount = MRP / (1+(GST/100));
        if (Discount < 0) {
            setLimitPrice(Math.floor(listAmount * 100) / 100);
            document.getElementById('listDiscountErrorPopUp').style.display= 'block';
        } else {
            document.getElementById('listDiscountErrorPopUp').style.display= 'none';
            discountArr.push({'listDiscount': result});
            setListDiscount(result);
        }
        if(!isNaN(retailerDiscount)){
            const Discount = (MRP-(retailerDiscount * (1+(GST/100))))/MRP	
            const amount = Discount * 100;
            const result = Math.floor(amount * 100) /100;
            if (Discount < 0) {
                setLimitPrice(Math.floor(listAmount * 100) / 100);
                document.getElementById('RetailerDiscountErrorPopUp').style.display = 'block';
            } else {
                document.getElementById('RetailerDiscountErrorPopUp').style.display = 'none';
                discountArr.push({'retailerDiscount': result});
                setRetailerDiscount(result);
            }
            if(!isNaN(interiorDiscount)){
                const Discount = (MRP-(interiorDiscount * (1+(GST/100))))/MRP
                const amount = 	Discount * 100
                const result = Math.floor(amount * 100) / 100;
                if (Discount < 0) {
                    setLimitPrice(Math.floor(listAmount * 100) / 100);
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'block';
                } else {
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'none';
                    discountArr.push({'interiorDiscount': result});
                    setInteriorDiscount(result);
                }
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(GST/100))))/MRP	
                    const amount = Discount * 100;
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP	
                        const amount = Discount * 100;
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP	
                        const amount = Discount * 100;
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }else{
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(GST/100))))/MRP
                    const amount = 	Discount * 100
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP
                        const amount = Discount * 100	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP
                        const amount = Discount * 100
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }
        }else{
            if(!isNaN(interiorDiscount)){
                const Discount = (MRP-(interiorDiscount * (1+(GST/100))))/MRP
                const amount = Discount * 100;	
                const result = Math.floor(amount * 100) / 100;
                if (Discount < 0) {
                    setLimitPrice(Math.floor(listAmount * 100) / 100);
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'block';
                } else {
                    document.getElementById('InteriorDiscountErrorPopUp').style.display = 'none';
                    discountArr.push({'interiorDiscount': result});
                    setInteriorDiscount(result);
                }
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(GST/100))))/MRP
                    const amount = Discount * 100;
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP
                        const amount = Discount * 100;	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP
                        const amount = Discount * 100;	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }else{
                if(!isNaN(oemDiscount)){
                    const Discount = (MRP-(oemDiscount * (1+(GST/100))))/MRP	
                    const amount = Discount * 100;
                    const result = Math.floor(amount * 100) / 100;
                    if (Discount < 0) {
                        setLimitPrice(Math.floor(listAmount * 100) / 100);
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'block';
                    } else {
                        document.getElementById('oemDiscountErrorPopUp').style.display = 'none';
                        discountArr.push({'oemDiscount': result});
                        setOemDiscount(result);
                    }
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP
                        const amount = Discount * 100;	
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }else{
                    if(!isNaN(endUser)){
                        const Discount = (MRP-(endUser * (1+(GST/100))))/MRP	
                        const amount = Discount * 100;
                        const result = Math.floor(amount * 100) / 100;
                        if (Discount < 0) {
                            setLimitPrice(Math.floor(listAmount * 100) / 100);
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'block';
                        } else {
                            document.getElementById('endUserDiscountErrorPopUp').style.display = 'none';
                            discountArr.push({'endUser': result});
                            setEndUserDiscount(result);
                        }
                    }
                }
            }
        }
        setDiscountArr(discountArr)
    }
}