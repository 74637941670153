import JsonCSVExporter from "./jsontocsv"

const downloadCSV = function (arrayOfRowObjects,downloadFileName) {
	//Convert JSON to CSV
	const csvRows = new JsonCSVExporter(arrayOfRowObjects)
	const finalCSVData = csvRows.convertToCSV()
	//Download final CSV Data	
	const blob = new Blob([finalCSVData], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob)
	const a = document.createElement('a')
	a.setAttribute('href', url)
	a.setAttribute('download', `${downloadFileName}.csv`);
	a.click()
}

export {downloadCSV}