import React, { Fragment, useState } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import Button from '@mui/material/Button';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Spinner from '../../../components/spinner';

const UploadDocDialog = (props) => {
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });

    const {
        showPopup,
        hideClosePopup,
        dispatchId,
        setReRender
    } = props;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(false);
        const file = event?.target?.file?.files[0];
        const formData = new FormData()
        formData.append("documentAttached", file);
        await axiosInstance
            .post(`/orderDispatches/attachDocumentDispatch/${dispatchId}`, formData)
            .then((res) => {
                setReRender((prev) => !prev);
                hideClosePopup();
                setLoading(false);
                const successMessage = `Document Successfully Uploaded`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                hideClosePopup();
                setLoading(false);
                setReRender((prev) => !prev);
            });
    }


    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom:'35%',top:'26%'}}>
                    <div className={Classes.BoxContent}>
                        <p>Upload Proof</p>
                    </div>
                    <form method='post' onSubmit={handleSubmit} >
                        <div className="input-group" style={{ padding:20 }}>
                            <input
                                required
                                className="form-control"
                                type="file"
                                id="formFile"
                                name="file"
                            ></input>

                            <Button
                                id='import'
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                UPLOAD
                            </Button>
                        </div>

                        <div className={Classes.DialogBtnBox} style={{bottom:'34%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideClosePopup}
                            >
                                CANCEL
                            </button>
                        </div>
                    </form>

                    {loading ? <Spinner /> : null}
                </div>
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default UploadDocDialog;