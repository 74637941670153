import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Alert, AlertTitle, Pagination, Box, Paper, Switch } from '@mui/material';
import { Add } from '@mui/icons-material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Spinner from "../../../components/spinner";

import Classes from './dynamicBanners.module.css';

import axiosInstance from "../../../utility/axios-instance";
import { handleCreatedAt } from "../../../utility/utilityFunctions";

const DynamicBanners = () => {
  const [data, setData] = useState({
    dynamicBanners: [],
    pagination: {
      page: 1,
      maxPage: 1,
    },
  });
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ field: "username", value: "" });
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ field: "name", sort: "asc" });

  const update = (id, active) => {
    setLoading(true);

    axiosInstance.post(`/adminRoutes/dynamicBanners/${id}`, {active}).then(res => {
      fetchData(data.pagination.page)
    }).catch(err => {}).catch((err) => {setLoading(false)});
  }

  const columns = [
    {
      field: "title",
      headerName: "TITLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
      sortable: true,
    },
    {
      field: "link",
      headerName: "LINK",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "active",
      headerName: "ACTIVE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        <Switch checked={params?.value?.active} onClick={(e) => {
          e.stopPropagation();
          update(params?.value?.id, !params?.value?.active);
        }} />,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

   const fetchData = (page) => {
    setLoading(true);
    setError(null);

    const params = {
      page,
      text: search ? search : "",
      filter: filter,
      sort,
    };

    axiosInstance
      .get("/adminRoutes/dynamicBanners", {
        params,
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    fetchData(page ? page : 1, search, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, sort]);

  return (
    <Fragment>
        <div className={Classes.UsersHead}>
            <p>
            {/* <strong style={{ fontSize: "1.2rem" }}>Dynamic Banners</strong> */}
            </p>

            <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<Add />}
            onClick={() => navigate("/dynamicBanners/new")}
            >
            Add New
            </Button>
      </div>

      {error ? (
        <Alert severity='error' className='mb-4'>
          <AlertTitle>{error}</AlertTitle>
          Try Reloading !
        </Alert>
      ) : null}

      <div className='' style={{ width: "100%" }}>
        <Box
          component={Paper}
          className='shadow'
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: "#243750",
              color: "#ffffff",
              cursor: "default",
            },
          }}
        >
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            filterMode='server'
            filterColumns={["title", "link", "createdAt", "active"]}
            sortingMode='server'
            paginationMode='server'
            rowCount={data.dynamicBanners.length}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            onSortModelChange={(sort) => setSort(sort[0])}
            loading={loading}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              boxShadow: 2,
              borderRadius: 2,
              cursor: "pointer",
            }}
            columns={columns}
            rows={data?.dynamicBanners?.map((dynamicBanner) => ({
              id: dynamicBanner?.id,
              title: dynamicBanner?.title,
              createdAt: handleCreatedAt(new Date(dynamicBanner?.createdAt)),
              link: dynamicBanner?.link,
              active: dynamicBanner,
            }))}
            onRowClick={(params) => {
              navigate(`/dynamicBanners/${params.id}`);
            }}
            sortingOrder={["asc", "desc"]}
            autoHeight
            autoPageSize
            onFilterModelChange={(filter) => {
              setFilter(filter.items[0]);
              setSearch(filter.quickFilterValues[0]);
            }}
          />
        </Box>
      </div>

      {data.pagination.maxPage > 1 ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem'
        }}>
          <Pagination
            count={data.pagination.maxPage}
            shape='rounded'
            page={data.pagination.page}
            boundaryCount={3}
            siblingCount={2}
            onChange={(e, p) => {
              fetchData(p);
            }}
          />
        </div>
      ) : null}

      {loading ? (
        <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }}>
          <Spinner />
        </div>
      ) : null}
    </Fragment>
  )
}

export default DynamicBanners