import { configureStore,  getDefaultMiddleware } from '@reduxjs/toolkit';

import ordersReducer from './ordersSlice';
import cancelsReducer from './cancelsSlice';
import returnsReducer from './returnsSlice';
import authReducer from './authSlice';
import usersReducer from './usersSlice';
import refundsReducer from './refundsSlice';
import draftOrdersReducer from './draftOrdersSlice';
import deliveryBoyReducer from './deliveryBoySlice';
import toggleNavReducer from './toggleNavSlice';
import breadcrumbReducer from './breadcrumbSlice';
import selectedCheckBoxReducer from './storeSelectedCheckbox';
import pinTabSlice from './pinTabSlice';
import customersReducer from './customersSlice';
import productsReducer from './productsSlice';
import updatePricingReducer from './updatePricingSlice';
import deliveryRidersReducers from './deliveryRidersSlice';
import deliveryShippingsReducers from './deliveryShippingsSlice';
import picklistsReducers from './picklistsSlice';
import qcReducers from './qcSlice';
import packReducers from './packsSlice';
import dispatchReducers from './dispatchSlice';
import onHoldReducers from './onHoldSlice';
import draftPurchaseOrdersReducers from './draftPurchaseOrdersSlice';
import purchaseOrdersReducers from './purchaseOrdersSlice';
import inboundPOReducers from './inboundPOSlice';
import schemesReducers from './schemesSlice';
import allSchemesReducers from './allSchemesSlice';
import myTasksReducers from './myTasksSlice';
import managerReducers from './managerSlice';
import myCustomerReducers from './myCustomersSlice';
import reportDashboardReducers from './reportDashboardSlice';
import reportServiceReducers from './reportServiceSlice';
import locationsReducers from './locationsSlice';
import inventoryMovesHistoryReducer from './inventoryMoveSlice';
import inventoryAdjustmentsReducer from './inventoryAdjustments';
import binsReducer from './binsSlice';
import skuTransactionsReducer from './skuTransactionsSlice';
import orderAllocationsReducer from './orderAllocationsSlice';
import blogsReducer from './blogsSlice';
import pushNotificationsReducer from './pushNotificationsSlice';
import inwardReturnsReducer from './inwardReturns';
import inboundOrdersReducer from './inboundOrders';
import qcOrderReducer from './qcOrders';
import putAwayOrdersReducer from './putAwayOrders';
import inwardPurchaseOrdersReducer from './inwardPoOrders';
import inboundPurchaseOrdersReducer from './inboundPoOrders';
import qcPurchaseOrdersReducer from './qcPurchaseOrders';
import putAwayPurchaseOrdersReducer from './putAwayPoOrders';
import inventoryViewReducer from './inventoryView';
import dashboardReducer from './dashboard';
import inventoryViewBySkuReducer from './inventoryViewBySku';
import customersUpdateHistoryReducer from './customerUpdateHistory';

export default configureStore({
	reducer: {
		auth: authReducer,
		orders: ordersReducer,
		cancels: cancelsReducer,
		returns: returnsReducer,
		users: usersReducer,
		refunds: refundsReducer,
		draftOrders: draftOrdersReducer,
		deliveryBoy: deliveryBoyReducer,
		toggleNav: toggleNavReducer,
		breadcrumb: breadcrumbReducer,
		selectedCheckBox: selectedCheckBoxReducer,
		pinTab: pinTabSlice,
		customers: customersReducer,
		products: productsReducer,
		productsPricing: updatePricingReducer,
		deliveryRiders: deliveryRidersReducers,
		deliveryShippings: deliveryShippingsReducers,
		picklists: picklistsReducers,
		qcOrders: qcReducers,
		packOrders: packReducers,
		dispatchOrders: dispatchReducers,
		onHoldOrders: onHoldReducers,
		draftPurchaseOrders: draftPurchaseOrdersReducers,
		purchaseOrders: purchaseOrdersReducers,
		inboundPurchaseOrders: inboundPOReducers,
		schemes: schemesReducers,
		allSchemes: allSchemesReducers,
		myTasks: myTasksReducers,
		managerDashboardData: managerReducers,
		myCustomers: myCustomerReducers,
		reportDashboardData: reportDashboardReducers,
		reportServiceData: reportServiceReducers,
		locations: locationsReducers,
		inventoryMovesHistory: inventoryMovesHistoryReducer,
		inventoryAdjustments: inventoryAdjustmentsReducer,
		bins: binsReducer,
		skuTransactions: skuTransactionsReducer,
		orderAllocations: orderAllocationsReducer,
		blogs: blogsReducer,
		pushNotifications: pushNotificationsReducer,
		inwardReturns: inwardReturnsReducer,
		inboundOrders: inboundOrdersReducer,
		qcOrder: qcOrderReducer,
		putAwayOrders: putAwayOrdersReducer,
		inwardPurchaseOrders: inwardPurchaseOrdersReducer,
		inboundPO: inboundPurchaseOrdersReducer,
		qcPurchaseOrders: qcPurchaseOrdersReducer,
		putAwayPurchaseOrders: putAwayPurchaseOrdersReducer,
		inventoryView: inventoryViewReducer,
		dashboard : dashboardReducer,
		inventoryViewBySku: inventoryViewBySkuReducer,
		customersUpdateHistory: customersUpdateHistoryReducer
	},
	middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck:false,
    }),
});
