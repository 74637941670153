import React, { Fragment, useState } from "react";
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';
import Classes from '../createOrders/css/Modal.module.css';
import Spinner from "../../../components/spinner";
import SnackBar from "../../../components/SnackBar";


const CreateBinPopUp = (props) => {
    const [locCode, setLocCode] = useState('');
    const [loading,setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })


    const {
        showPopup,
        hideCreatePopup,
        locations,
        setReRender,
        reRender
    } = props;

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();

        const data = {
            "code": event.target.binCode.value,
            // "qty": event.target.qty.value,
            "type": event.target.binType.value,
            "status": event.target.status.value,
            "default": event.target.default.value
        }

        axiosInstance
            .post(`/inventory/createBin?locationCode=${locCode}`, { ...data })
            .then((res) => {
                setLoading(false);
                if (setReRender) { setReRender(!reRender) }
                hideCreatePopup();
                const successMessage = `Bin Created Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                console.log(res.data);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong"
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    // const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            {showPopup && (
                <div className={[Classes.modal, Classes.contactPopUp].join(' ')} style={{top:'12%',bottom:"16%"}}>
                    <div className={Classes.BoxContent}>
                        <p>Create Bin</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{ padding: '30px 20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width:'49%' }}>
                                    <TextField
                                        required={true}
                                        id='binCode'
                                        name="binCode"
                                        label="Bin Code"
                                        placeholder='Enter Bin Code'
                                        autoComplete='off'
                                        sx={{width:'100%'}}
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        required
                                        freeSolo
                                        disablePortal
                                        id="status"
                                        name="status"
                                        defaultValue={"Active"}
                                        options={["Active", "In-Active", "Dis-Continued"]}
                                        renderInput={(params) => <TextField {...params} label="Status" required={true}/>}
                                    />
                                </div>

                            </div>

                            <div className={Classes.ContentBox}>
                                {/* <div style={{ width: '49%' }}>
                                    <TextField
                                        required={true}
                                        id='qty'
                                        name="qty"
                                        label="Quantity"
                                        type="number"
                                        placeholder='Enter Quantity'
                                        autoComplete='off'
                                        sx={{width:'100%'}}
                                        min={0}
                                        onKeyDown={preventNegativeValues}
                                    />
                                </div> */}
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="binType"
                                        name="binType"
                                        defaultValue={"Saleable"}
                                        options={["Saleable", "Rejected", "QC", "Packing", "Returned", "Staging","OnHold"]}
                                        renderInput={(params) => <TextField {...params} label="Bin Type" required={true}/>}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="location"
                                        name="location"
                                        defaultValue={locations?.[0]}
                                        options={locations}
                                        renderInput={(params) => <TextField {...params} label="Location" required={true} onBlur={(event) => { setLocCode(event.target.value) }}/>}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="default"
                                        name="default"
                                        defaultValue={"true"}
                                        options={['true', 'false']}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label={"Default"} />}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideCreatePopup}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CREATE
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {loading ? <Spinner /> : null}
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
}


export default CreateBinPopUp;