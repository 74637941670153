import { Fragment, useState } from "react";
import Classes from "../css/create.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import AsideModal from "./asideModal";
import ContactPopUp from '../../customers/aside/contactPopUp';
import ShippingPopUp from "./shippingAddressPopup";
import TagsPopup from "./tagsPopup";
import RemoveGstPopup from "./RemoveGstNoPopup";
import UpdateShippingPopUp from "./updateShippingAddress";
import DefaultAddressPopUp from "./defaultAddressPopUp";
import GSTNoPopup from "./newGstNo";
// import BillingAdressPopUp from './BillingAddressPopUp';
import UpdateBillingPopUp from "./updateBillingAddress";
import DefaultBillingAdressPopUp from "./defaultBillingAddressPopUp";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import NewSalesUser from "../../reportDashboard/newSalesUser";
// import SellerDetails from "../sellerDetails";
import Permission from "../../../auth/permissions";
import NewSellerDetails from '../addNewSellerDetails';
import { services } from "../../../../utility/checkRoleBasedPermission";

const Aside = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [toggleButton, setToggleButton] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [shippingModal, setShippingModal] = useState(false);
  const [tagsModal, setTagsModal] = useState(false);
  const [updateShippingModal, setUpdateShippingModal] = useState(false);
  const [defaultAddressModal, setDefaultAddressModal] = useState(false);
  const [shippingAddressArray, setShippingAddressArray] = useState([]);
  const [newGstNo, setNewGstNo] = useState(false);
  const [previousTarget, setPreviousTarget] = useState([]);
  const [addressType, setAddressType] = useState("");
  const [updateBillingAddressModal, setUpdateBillingAddressModal] =
    useState(false);
  const [defaultBillingAddressModal, setDefaultBillingAddressModal] =
    useState(false);
  const [BillingAddressArray, setBillingAddressArray] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [removeGstModal, setRemoveGstModal] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");

  // Create SalesUser
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [password, setPassword] = useState("");
  const [showSalesModal, setShowSalesModal] = useState(false);

  const hideSalesDialog = () => {
    setShowSalesModal(false);
  };

  const showSalesDialog = () => {
    setShowSalesModal(true);
  };

  // set asideModalToggle
  const [toggle, setToggle] = useState(true);

  // shippingAddressPopUp
  const [num, setNum] = useState("");
  const [pincode, setPincode] = useState("");

  const {
    estimateUserId,
    handleRole,
    showCustomerDetail,
    setCustomerDetail,
    customerIDAddress,
    setCustomerIDAddress,
    setGstNumber,
    setBillingAddress,
    BillingAddress,
    showSelectedTags,
    gstNumber,
    setSelectedTags,
    addressArray,
    setAddressArray,
    fetchCustomerRoleDB,
    updateExistOrder,
    setPaymentRemark,
    setDeliveryRemark,
    paymentRemark,
    deliveryRemark,
    affiliateUser,
    setAffiliateUser,
    setSalesUser,
    salesUser,
    setPreSalesUser,
    preSalesUser,
    affiliateList,
    salesList,
    preSalesList,
    handleSalesList,
    setSellerBillingAddress,
    setSeller,
    seller,
    sellerBillingAddress
  } = props;

  const showCustomerModal = (searchItem) => {
    setShowModal(true);
  };

  const hideCustomerModal = () => {
    setShowModal(false);
  };

  const removeElement = (Id) => {
    setAffiliateUser({});
    setSalesUser({});
    setPreSalesUser({});
    setToggle(true);
    setSearchCustomer("");
    setGstNumber('');
    setCustomerDetail((showCustomerDetail) =>
      showCustomerDetail.filter((ele) => ele._id !== Id)
    );
    if (customerIDAddress.length !== 0) {
      setCustomerIDAddress((customerIDAddress) =>
        customerIDAddress.filter((ele) => ele.user !== Id)
      );
    }

    if (BillingAddress.length !== 0) {
      setBillingAddress((BillingAddress) =>
        BillingAddress.filter((ele) => ele.user !== Id)
      );
    }

    if (shippingAddressArray.length !== 0) {
      setAddressArray((shippingAddressArray) =>
        shippingAddressArray.filter((ele) => ele.user !== Id)
      );
    }

    if (previousTarget.length !== 0) {
      setPreviousTarget(previousTarget.splice(0, previousTarget.length));
    }
  };

  const handleSearchCustomer = (customer) => {
    setToggleButton(true);
    setSearchCustomer(customer);
  };

  const showContactPop = (e) => {
    // setDisplayName('');
    setFirstName("");
    setLastName("");
    setContactModal(true);
  };

  const hideContactPop = () => {
    setContactModal(false);
  };

  const showShippingPop = (event) => {
    setAddressType(event.target.id);
    setShippingModal(true);
  };

  const hideShippingPop = () => {
    setPincode("");
    setNum("");
    setShippingModal(false);
  };

  const showUpdateShippingPop = (event) => {
    setAddressType(event.target.id);
    setUpdateShippingModal(true);
  };

  const hideUpdateShippingPop = () => {
    setUpdateShippingModal(false);
  };

  const showUpdateBillingPop = (event) => {
    setAddressType(event.target.id);
    setUpdateBillingAddressModal(true);
  };

  const hideUpdateBillingPop = () => {
    setUpdateBillingAddressModal(false);
  };

  const showdefaultBillingAddressPop = () => {
    if (BillingAddressArray.length === 0) {
      setBillingAddressArray(BillingAddress);
    } else {
      setBillingAddressArray(BillingAddressArray);
    }
    setDefaultBillingAddressModal(true);
  };

  const hideDefaultBillingAddressPop = () => {
    setPreviousTarget([]);
    setBillingAddressArray(BillingAddressArray);
    setDefaultBillingAddressModal(false);
  };

  const showDefaultAddressPopUp = () => {
    if (shippingAddressArray.length === 0) {
      setShippingAddressArray(customerIDAddress);
    } else {
      setShippingAddressArray(shippingAddressArray);
    }
    setDefaultAddressModal(true);
  };

  const hideDefaultAddressPopUp = () => {
    setPreviousTarget([]);
    setShippingAddressArray(shippingAddressArray);
    setDefaultAddressModal(false);
  };

  const showGstNoPopUp = () => {
    setNewGstNo(true);
  };

  const hideGstNoPopUp = () => {
    setNewGstNo(false);
  };

  const handleGst = (event) => {
    if (event.target.value === "None") {
      setGstNumber("remove");
    } else {
      setGstNumber(event.target.value);
    }
  };

  const showTagsPopup = () => {
    setTagsModal(true);
  };

  const showRemoveGstPopup = () => {
    setRemoveGstModal(true);
  };

  const hideRemoveGstPopup = () => {
    setRemoveGstModal(false);
  };

  const hideTagsPopup = () => {
    setTagsModal(false);
  };

  const handleAddTag = () => {
    if (tagInputValue !== "") {
      setSelectedTags([...showSelectedTags, tagInputValue]);
      setTagInputValue("");
    }
  };

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("contactOutline");
    if (container !== null) {
      if (!container.contains(e.target)) {
        container.style.border = "1px solid rgb(206, 212, 218)";
      }
    }
  });

  const handleOutline = () => {
    document.getElementById("contactOutline").style.border =
      "2px solid #458fff";
  };

  const capitalName = (text) => {
    const result1 = text.replaceAll("_", " ");
    const arr = result1.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  const capitalizeState = (text) => {
    const arr = text.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  const handleEdit = (event) => {
    if (document.activeElement.isContentEditable) {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
        return;
      }
    }
  };

  const handleBlur = (event) => {
    if(event.target.innerText.length <= 250 ){
      if (event.target.id === "paymentRemark") {
        setPaymentRemark(event.target.innerText);
      } else {
        setDeliveryRemark(event.target.innerText);
      }
    }
  };

  return (
    <Fragment>
      <div
        className={[Classes.Box, "box"].join(" ")}
        id='createOrderContactBox'
      >
        <>
          {showCustomerDetail?.length !== 0 ? (
            <>
              {showCustomerDetail?.map((item) => (
                <div key={item?._id} id={item?._id}>
                  <div style={{ borderBottom: "1px solid #DBDDE0" }}>
                    <div className={Classes.containerBox} id='customerBox'>
                      <div className={Classes.customerBox} id='customerHeader'>
                        <h6 style={{ margin: "auto 0px" }}>Customer</h6>
                        <div className='removeIcon'>
                          <FontAwesomeIcon
                            icon={faXmark}
                            className={Classes.xMarkIcon}
                            onClick={() => removeElement(item?._id)}
                          />
                        </div>
                      </div>

                      <div className={Classes.customerDetailBox}>
                        <div className={Classes.customerName}>
                          {item?.businessName ? (
                            <p>{item?.businessName}</p>
                          ) : (
                            <>
                              <p style={{ marginRight: "5px" }}>
                                {item?.firstName}
                              </p>
                              {item?.lastName && <p>{item?.lastName}</p>}
                            </>
                          )}
                        </div>

                        {item?.customerRole && (
                          <div className={Classes.customerRole}>
                            <p>{capitalName(item?.customerRole)}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={Classes.containerBox}
                      id='contactInformationBox'
                    >
                      <div
                        className={[
                          Classes.customerBox,
                          Classes.flexBetween,
                        ].join(" ")}
                        id='contactInformationHeader'
                      >
                        <p className={Classes.contactInformation}>
                          CONTACT INFORMATION
                        </p>
                        <p
                          className={[
                            "removeEdit",
                            Classes.contactEditBtn,
                          ].join(" ")}
                          onClick={(e) => showContactPop(e)}
                        >
                          Edit
                        </p>
                      </div>

                      <div className={Classes.colorFontSize}>
                        <p>{item?.email}</p>
                        <p style={{ marginTop: "5px" }}>{item?.phone}</p>
                      </div>
                    </div>
                  </div>

                  {gstNumber?.length !== 0 && (
                    <div
                      style={{ padding: "10px 1.1rem", margin: "0" }}
                      id='createOrderPrintGstBox'
                      className={Classes.printGstBox}
                    >
                      <h6
                        style={{ margin: "5px 0px" }}
                        className={Classes.contactInformation}
                      >
                        GST Number
                      </h6>
                      <p className={Classes.colorFontSize}>{gstNumber}</p>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className={Classes.containerBox} id='hidePrintCustomerBox'>
              <div className='mb-4'>
                <h6 style={{ margin: "auto 0px", padding: "6px" }}>Customer</h6>
              </div>
              <div>
                <div
                  onClick={handleOutline}
                  id='contactOutline'
                  className={Classes.contactInputBox}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className={Classes.contactSearchIcon}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      type='text'
                      id='search'
                      className={Classes.searchField}
                      placeholder='Search customers'
                      autoComplete='off'
                      onChange={(e) => handleSearchCustomer(e.target.value)}
                      onClick={() => showCustomerModal()}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div>
            <AsideModal
              estimateUserId={estimateUserId}
              showModal={showModal}
              onHideModal={hideCustomerModal}
              setDetail={setCustomerDetail}
              searchCustomer={searchCustomer}
              toggleButton={toggleButton}
              setToggleButton={setToggleButton}
              setCustomerIDAddress={setCustomerIDAddress}
              setPreviousTarget={setPreviousTarget}
              setBillingAddress={setBillingAddress}
              fetchCustomerRoleDB={fetchCustomerRoleDB}
              toggle={toggle}
              setToggle={setToggle}
              setAddressArray={setAddressArray}
              setAffiliateUser={setAffiliateUser}
              affiliateUser={affiliateUser}
              setSalesUser={setSalesUser}
              salesUser={salesUser}
              setPreSalesUser={setPreSalesUser}
              preSalesUser={preSalesUser}
              setGstNumber={setGstNumber}
            />
          </div>

          <div className={contactModal ? Classes.UpdateSalesDialog : undefined}>
						<ContactPopUp
							contactModal={contactModal}
							setContactModal={setContactModal}
							showContactPop={showContactPop}
							hideContactPop={hideContactPop}
							showCustomerProfile={showCustomerDetail?.length !== 0 && showCustomerDetail[0]}
							setCustomerProfile={setCustomerDetail}
							fetchCustomerRoleDB={fetchCustomerRoleDB}
							displayName={showCustomerDetail?.length !== 0 && showCustomerDetail[0]?.displayName}
							setDisplayName={setDisplayName}
							firstName={showCustomerDetail?.length !== 0 && showCustomerDetail[0]?.firstName}
							setFirstName={setFirstName}
							lastName={showCustomerDetail?.length !== 0 && showCustomerDetail[0]?.lastName}
							setLastName={setLastName}
							businessName={showCustomerDetail?.length !== 0 && showCustomerDetail[0]?.businessName}
							setBusinessName={setBusinessName}
						/>
					</div>

          <div className={shippingModal ? Classes.UpdateSalesDialog : undefined}>
            <ShippingPopUp
              shippingModal={shippingModal}
              setShippingModal={setShippingModal}
              showShippingPop={showShippingPop}
              hideShippingPop={hideShippingPop}
              pincode={pincode}
              setPincode={setPincode}
              num={num}
              setNum={setNum}
              showCustomerDetail={showCustomerDetail}
              setCustomerIDAddress={setCustomerIDAddress}
              addressArray={addressArray}
              setAddressArray={setAddressArray}
              setBillingAddress={setBillingAddress}
              addressType={addressType}
              setAddressType={setAddressType}
            />
          </div>

          <div className={updateShippingModal ? Classes.UpdateSalesDialog : undefined}>
            <UpdateShippingPopUp
              hideUpdateShippingPop={hideUpdateShippingPop}
              showUpdateShippingPop={showUpdateShippingPop}
              updateShippingModal={updateShippingModal}
              setUpdateShippingModal={setUpdateShippingModal}
              showCustomerDetail={showCustomerDetail}
              setCustomerIDAddress={setCustomerIDAddress}
              updateExistOrder={updateExistOrder}
              customerIDAddress={customerIDAddress}
              addressType={addressType}
              addressArray={addressArray}
              setAddressArray={setAddressArray}
            />
          </div>

          <div className={updateBillingAddressModal ? Classes.UpdateSalesDialog : undefined}>
            <UpdateBillingPopUp
              hideUpdateBillingPop={hideUpdateBillingPop}
              showUpdateBillingPop={showUpdateBillingPop}
              updateBillingModal={updateBillingAddressModal}
              setUpdateBillingModal={setUpdateBillingAddressModal}
              showCustomerDetail={showCustomerDetail}
              setBillingAddress={setBillingAddress}
              updateExistOrder={updateExistOrder}
              BillingAddress={BillingAddress}
              addressType={addressType}
              addressArray={addressArray}
              setAddressArray={setAddressArray}
            />
          </div>

          <div className={defaultAddressModal ? Classes.UpdateSalesDialog : undefined}>
            <DefaultAddressPopUp
              defaultAddressModal={defaultAddressModal}
              hideDefaultAddressPopUp={hideDefaultAddressPopUp}
              setCustomerIDAddress={setCustomerIDAddress}
              customerIDAddress={customerIDAddress}
              showCustomerDetail={showCustomerDetail}
              addressArray={addressArray}
              previousTarget={previousTarget}
              setPreviousTarget={setPreviousTarget}
              setAddressArray={setAddressArray}
            />
          </div>

          <div className={defaultBillingAddressModal ? Classes.UpdateSalesDialog : undefined}>
            <DefaultBillingAdressPopUp
              defaultBillingAddressModal={defaultBillingAddressModal}
              hideDefaultBillingAddressPop={hideDefaultBillingAddressPop}
              setBillingAddress={setBillingAddress}
              showCustomerDetail={showCustomerDetail}
              showdefaultBillingAddressPop={showdefaultBillingAddressPop}
              BillingAddress={BillingAddress}
              addressArray={addressArray}
              previousTarget={previousTarget}
              setPreviousTarget={setPreviousTarget}
              setAddressArray={setAddressArray}
            />
          </div>

          <div className={newGstNo ? Classes.UpdateSalesDialog : undefined}>
            <GSTNoPopup
              newGstNo={newGstNo}
              setNewGstNo={setNewGstNo}
              showGstNoPopUp={showGstNoPopUp}
              hideGstNoPopUp={hideGstNoPopUp}
              showCustomerDetail={showCustomerDetail}
              setCustomerDetail={setCustomerDetail}
              setGstNumber={setGstNumber}
            />
          </div>

          <div className={tagsModal ? Classes.UpdateSalesDialog : undefined}>
            <TagsPopup
              tagsModal={tagsModal}
              setTagsModal={setTagsModal}
              showTagsPopup={showTagsPopup}
              hideTagsPopup={hideTagsPopup}
              SelectedTags={showSelectedTags}
              setSelectedTag={setSelectedTags}
            />
          </div>

          <div className={removeGstModal ? Classes.UpdateSalesDialog : undefined}>
            <RemoveGstPopup
              removeGstModal={removeGstModal}
              setRemoveGstModal={setRemoveGstModal}
              showRemoveGstPopup={showRemoveGstPopup}
              hideRemoveGstPopup={hideRemoveGstPopup}
              showCustomerDetail={showCustomerDetail}
              setCustomerDetail={setCustomerDetail}
              gstNumber={gstNumber}
              setGstNumber={setGstNumber}
            />
          </div>
        </>
      </div>
      <Permission service={services?.sellers} permission={"read"}>
        <div id='removeSelectGst'>
          <NewSellerDetails setSellerBillingAddress={setSellerBillingAddress} setSeller={setSeller} seller={seller} sellerBillingAddress={sellerBillingAddress} />
        </div>
      </Permission>
      <div className={[Classes.Box, "box"].join(" ")} id='removeSelectGst'>
        <div className={Classes.containerBox}>
          <div className='d-flex justify-content-between mb-2'>
            <h6 style={{ margin: "auto 0px", marginLeft: 5 }}>Select GST No</h6>

            <div>
              <p onClick={showGstNoPopUp} className={Classes.addNewGstNo}>
                Add New GST
              </p>
            </div>
          </div>

          <div className={Classes.fontSize}>
            <select
              name='gst'
              key={
                showCustomerDetail?.length !== 0 && 
                showCustomerDetail[0]?.gstNumbers?.length !== 0 ? 
                showCustomerDetail[0]?.gstNumbers[0] : 'DEFAULT'
              }
              defaultValue={
                showCustomerDetail?.length !== 0 && 
                showCustomerDetail[0]?.gstNumbers?.length !== 0 ? 
                showCustomerDetail[0]?.gstNumbers[0] : 'DEFAULT'
              }
              id='gstNo'
              className={Classes.select}
              onChange={(e) => handleGst(e)}
            >
              <option value='DEFAULT' disabled={true}>
                Select GSTno
              </option>

              {showCustomerDetail?.length !== 0 &&
                showCustomerDetail[0]?.gstNumbers?.length !== 0 && (
                  <>
                    {showCustomerDetail[0]?.gstNumbers?.map((gst, index) => (
                      <option value={gst} key={index}>
                        {gst}
                      </option>
                    ))}
                  </>
                )}

              <option value='None'>None</option>
            </select>
          </div>

          {showCustomerDetail?.length !== 0 &&
            showCustomerDetail[0]?.gstNumbers?.length !== 0 && (
              <div>
                <p
                  onClick={showRemoveGstPopup}
                  className={Classes.addNewGstNo}
                  style={{ marginTop: 10, marginLeft: 5 }}
                >
                  Remove GST
                </p>
              </div>
            )}
        </div>
      </div>

      {showCustomerDetail?.length !== 0 && (
        <div
          className='gstNumber'
          style={{ display: "none" }}
          id='createOrderGstBox'
        >
          {gstNumber?.length !== 0 && (
            <div
              className={[Classes.Box, "box"].join(" ")}
              style={{
                padding: "10px 1.1rem",
                margin: "0",
              }}
            >
              <h6 style={{ margin: "5px 0px" }}>GST Number</h6>
              <p className={Classes.colorFontSize}>{gstNumber}</p>
            </div>
          )}
        </div>
      )}

      {showCustomerDetail?.length !== 0 && (
        <div className={Classes.Box} id='createOrderAddressBox'>
          {addressArray?.length !== 0 ? (
            <div
              style={{ borderBottom: "1px solid #DBDDE0" }}
              id='createOrderShippingAddressBox'
            >
              <div className={Classes.containerBox}>
                <div className={["mb-3", Classes.flexBetween].join(" ")}>
                  <p className={Classes.contactInformation}>SHIPPING ADDRESS</p>
                  {customerIDAddress?.length !== 0 && (
                    <p
                      className={[
                        "removeAddressOnPrint",
                        Classes.contactEditBtn,
                      ].join(" ")}
                      id='shipping'
                      onClick={(e) => showUpdateShippingPop(e)}
                    >
                      Edit address
                    </p>
                  )}
                </div>

                {customerIDAddress?.length !== 0 ? (
                  <div style={{ fontSize: "14px" }}>
                    <div style={{ display: "flex" }}>
                      <p>
                        {customerIDAddress[0]?.firstName +
                          " " +
                          (customerIDAddress[0]?.lastName === undefined
                            ? ""
                            : customerIDAddress[0]?.lastName)}
                      </p>
                    </div>

                    <div className={Classes.flexDirectionColumn}>
                      <p style={{ marginRight: "5px" }}>
                        {customerIDAddress[0]?.address1}
                      </p>
                      {customerIDAddress[0]?.address2 !== null ? (
                        <p>{customerIDAddress[0]?.address2}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "5px" }}>
                        {customerIDAddress[0]?.zip}
                      </p>
                      <p style={{ marginRight: "5px" }}>
                        {customerIDAddress[0]?.city},
                      </p>
                    </div>

                    <div>
                      <p>{capitalizeState(customerIDAddress[0]?.province)},</p>
                    </div>
                    <div>
                      <p>{customerIDAddress[0]?.country}</p>
                    </div>
                    <div>
                      <p>{customerIDAddress[0]?.phone}</p>
                    </div>
                  </div>
                ) : null}

                <div
                  className={[
                    "removeAddressOnPrint",
                    Classes.selectAddressBtn,
                  ].join(" ")}
                >
                  <p onClick={showDefaultAddressPopUp}>Select address</p>
                  <p id='shipping' onClick={showShippingPop}>
                    Add new address
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ borderBottom: "1px solid #DBDDE0" }}>
              <div style={{ padding: "1.1rem" }}>
                <p
                  className={["mb-2", Classes.shippingAddressHeader].join(" ")}
                >
                  SHIPPING ADDRESS
                </p>
                <p
                  id='shipping'
                  className={Classes.contactEditBtn}
                  onClick={(e) => showShippingPop(e)}
                >
                  Please enter shipping address
                </p>
              </div>
            </div>
          )}

          {addressArray?.length !== 0 ? (
            <div
              style={{ borderBottom: "1px solid #DBDDE0" }}
              id='createOrderBillingAddressBox'
            >
              <div className={Classes.containerBox}>
                <div className={["mb-3", Classes.flexBetween].join(" ")}>
                  <p className={Classes.contactInformation}>BILLING ADDRESS</p>
                  {BillingAddress?.length !== 0 && (
                    <p
                      className={[
                        "removeAddressOnPrint",
                        Classes.contactEditBtn,
                      ].join(" ")}
                      onClick={(e) => showUpdateBillingPop(e)}
                      id='billing'
                    >
                      Edit Address
                    </p>
                  )}
                </div>

                {BillingAddress?.length !== 0 ? (
                  <div style={{ fontSize: "14px" }}>
                    <div style={{ fontSize: "14px" }}>
                      <div style={{ display: "flex" }}>
                        <p style={{ marginRight: "5px" }}>
                          {BillingAddress[0]?.firstName +
                            " " +
                            (BillingAddress[0]?.lastName === undefined
                              ? ""
                              : BillingAddress[0]?.lastName)}
                        </p>
                      </div>

                      <div>
                        <p style={{ marginRight: "5px" }}>
                          {BillingAddress[0]?.address1}
                        </p>
                      </div>

                      <div>
                        {BillingAddress[0]?.address2 !== null ? (
                          <p>{BillingAddress[0]?.address2}</p>
                        ) : null}
                      </div>

                      <div style={{ display: "flex" }}>
                        <p style={{ marginRight: "5px" }}>
                          {BillingAddress[0]?.zip}
                        </p>
                        <p style={{ marginRight: "5px" }}>
                          {BillingAddress[0]?.city},
                        </p>
                      </div>

                      <div>
                        <p>{capitalizeState(BillingAddress[0]?.province)},</p>
                      </div>

                      <div>
                        <p>{BillingAddress[0]?.country}</p>
                      </div>
                      <div>
                        <p>{BillingAddress[0]?.phone}</p>
                      </div>

                      <div className={Classes.selectAddressBtn}>
                        <p
                          className='removeAddressOnPrint'
                          onClick={showdefaultBillingAddressPop}
                        >
                          Select Address
                        </p>
                        <p id='billing' onClick={showShippingPop}>
                          Add new address
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={[
                      "removeAddressOnPrint",
                      Classes.selectAddressBtn,
                    ].join(" ")}
                  >
                    <p onClick={showdefaultBillingAddressPop}>Select address</p>
                    <p id='billing' onClick={showShippingPop}>
                      Add new address
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ borderBottom: "1px solid #DBDDE0" }}>
              <div style={{ padding: "1.1rem" }}>
                <p
                  className={["mb-3", Classes.shippingAddressHeader].join(" ")}
                >
                  BILLING ADDRESS
                </p>
                {customerIDAddress?.length !== 0 ? (
                  <div>
                    <div>
                      <p style={{ color: "#6c757d" }}>
                        Same as shipping address
                      </p>
                    </div>

                    <div className={Classes.AddNewAddressBtn}>
                      <p id='billing' onClick={showShippingPop}>
                        Add new address
                      </p>
                    </div>
                  </div>
                ) : (
                  <p
                    id='billing'
                    className={Classes.contactEditBtn}
                    onClick={(e) => showShippingPop(e)}
                  >
                    Please enter billing address
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <div className={Classes.Box} id='removeRolePrint'>
        <div className={Classes.containerBox}>
          <div className='d-flex justify-content-between mb-2'>
            <h6 style={{ margin: "auto 0px" }}>Select Role</h6>
            <div style={{ fontSize: 12, fontWeight: 600 }}>
              <p>Apply Discount Based on Role</p>
            </div>
          </div>

          <div className={Classes.fontSize}>
            <select
              name='role'
              id='selectToggle'
              defaultValue={"DEFAULT"}
              className={Classes.select}
              onChange={(e) => handleRole(e)}
              disabled={true}
            >
              <option value='DEFAULT' disabled={true}>
                Select role
              </option>
              {fetchCustomerRoleDB?.map((role) => (
                <option key={role?._id} value={role?.title}>
                  {capitalName(role.title)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className={Classes.Box} id='removeNotesPrint'>
        <div className={Classes.containerBox}>
          <div className='mb-3'>
            <h6 className={Classes.remarkHeader}>Delivery Remark</h6>
          </div>

          <div id='deliveryRemarkContainer'>
            <div className={Classes.deliveryRemark}>
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                id='deliveryRemark'
                value={deliveryRemark}
                onKeyDown={(e) => handleEdit(e)}
                onBlur={(e) => handleBlur(e)}
                className={Classes.Span}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className={Classes.Box} id='removeNotesPrint'>
        <div className={Classes.containerBox}>
          <div className='mb-3'>
            <h6 className={Classes.remarkHeader}>Payment Remark</h6>
          </div>

          <div id='paymentRemarkContainer'>
            <div className={Classes.deliveryRemark}>
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                id='paymentRemark'
                value={paymentRemark}
                onKeyDown={(e) => handleEdit(e)}
                onBlur={(e) => handleBlur(e)}
                className={Classes.Span}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {showCustomerDetail?.length !== 0 && (
        <div
          className={Classes.Box}
          style={{ padding: "20px 18px" }}
          id='affiliateDetails'
        >
          <div
            className='mb-3'
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h6 className={Classes.remarkHeader}>Affiliate Details</h6>
            <div
              style={{
                color: "#2C6ECB",
                fontSize: "14px",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <p onClick={(e) => showSalesDialog(e)}>Create Sales User</p>
            </div>
          </div>
          <div style={{ display: "flex", margin: "8px 0px" }}>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={affiliateList}
              value={
                Object.keys(affiliateUser).length !== 0 ? affiliateUser : null
              }
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setAffiliateUser({});
                  return;
                }
                setAffiliateUser(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.Id === value.Id}
              renderInput={(params) => (
                <TextField {...params} label='Affilate' />
              )}
            />
          </div>

          <div style={{ display: "flex", margin: "8px 0px" }}>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={salesList}
              value={Object.keys(salesUser).length !== 0 ? salesUser : null}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setSalesUser({});
                  return;
                }
                setSalesUser(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.Id === value.Id}
              renderInput={(params) => (
                <TextField {...params} label='Sales User' />
              )}
            />
          </div>

          <div style={{ display: "flex", margin: "8px 0px" }}>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={salesList}
              value={
                Object.keys(preSalesUser).length !== 0 ? preSalesUser : null
              }
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setPreSalesUser({});
                  return;
                }
                setPreSalesUser(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.Id === value.Id}
              renderInput={(params) => (
                <TextField {...params} label='PreSale User' />
              )}
            />
          </div>
        </div>
      )}

      <div className={Classes.Box} id='removeTagsPrint'>
        <div className={Classes.containerBox}>
          <div className='d-flex justify-content-between mb-3'>
            <h6 className={Classes.remarkHeader}>Tags</h6>
            <div>
              {showSelectedTags ? (
                <p onClick={showTagsPopup} className={Classes.TagsBtn}>
                  Change Tags
                </p>
              ) : (
                <p onClick={showTagsPopup} className={Classes.TagsBtn}>
                  Add New Tags
                </p>
              )}
            </div>
          </div>

          <div>
            <div className={Classes.TagsInput}>
              <input
                type='text'
                id='search'
                className={Classes.searchField}
                placeholder='Find or create tags'
                value={tagInputValue}
                onChange={(e) => setTagInputValue(e.target.value)}
              />
            </div>
            <div className={Classes.addTagBox}>
              <div className={Classes.addTagButton} onClick={handleAddTag}>
                Add Tag
              </div>
            </div>
          </div>
          <div>
            {showSelectedTags && (
              <div className={Classes.selectedTagsBox}>
                {showSelectedTags?.map((tag, index) => (
                  <p key={index} className={Classes.selectedTags}>
                    {tag}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        
        <div className={showSalesModal ? Classes.UpdateSalesDialog : undefined}>
          <NewSalesUser
            showModal={showSalesModal}
            hideDialog={hideSalesDialog}
            userName={userName}
            setUserName={setUserName}
            name={name}
            setName={setName}
            phoneNo={phoneNo}
            setPhoneNo={setPhoneNo}
            customerType={customerType}
            setCustomerType={setCustomerType}
            password={password}
            setPassword={setPassword}
            fetchCustomers={handleSalesList}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Aside;
