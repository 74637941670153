import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

const Commission2Mobile = (props) => {
  const years = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const { commission } = props;

  const navigate = useNavigate();

  const dn = new Date();
  const createdAt = new Date(commission.createdAt);

  let ca = createdAt
    ? createdAt.getFullYear() === dn.getFullYear()
      ? createdAt.getMonth() === dn.getMonth()
        ? createdAt.getDate() === dn.getDate()
          ? `Today at ${createdAt.toLocaleTimeString()}`
          : createdAt.getDate() === dn.getDate() - 1
          ? `Yesterday at ${createdAt.toLocaleTimeString()}`
          : years[createdAt.getMonth()] +
            " " +
            createdAt.getDate() +
            " at " +
            createdAt.toLocaleTimeString()
        : years[createdAt.getMonth()] +
          " " +
          createdAt.getDate() +
          " at " +
          createdAt.toLocaleTimeString()
      : years[createdAt.getMonth()] + " " + createdAt.getFullYear()
    : null;
  return (
    <div>
      <div
        key={commission._id}
        className={""}
        style={{
          cursor: "pointer",
          margin: "20px 0px",
          padding: "8px 15px",
          border: "2px solid #798294 ",
        }}
        // onClick={() => {
        //   navigate(`${commission._id}`);
        // }}
      >
        <div className='d-flex my-2'>
          <FaUserCircle size='28px' color='grey' />
          <p className='mx-2 text-bold'>
            <strong>{commission.affiliateData.displayName}</strong>
          </p>
        </div>

        <div className='d-flex my-2'>
          <p className='text-muted'>Order Name:</p>
          <p className='mx-2'>{commission.orderName}</p>
        </div>

        <div className='d-flex my-2'>
          <p className='text-muted'>Phone:</p>
          <p className='mx-2'>{commission.affiliateData.phone}</p>
        </div>
        <div className='d-flex my-2'>
          <p className='text-muted'>Email:</p>
          <p className='mx-2'>{commission.affiliateData.email}</p>
        </div>
        <div className='d-flex my-2'>
          <p className='text-muted'>Commission:</p>
          <p className='mx-2'>{commission.commission?.toFixed(2)}</p>
        </div>
        <div className='d-flex my-2'>
          <p className='text-muted'>Created at:</p>
          <p className='mx-2'>{ca}</p>
        </div>
      </div>
    </div>
  );
};

export default Commission2Mobile;
