import { useEffect, useState, Fragment, forwardRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import Classes from './productCreated.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { downloadCSV } from "../../../utility/downloadCSV";
import Accordion from "../uploadImages/accordion";
import NewPagination from '../../../components/newPagination';
import { useSelector, useDispatch  } from 'react-redux';
import { SHOPIFY_UNPLUGGED_CHECK } from './SHOPIFY_UNPLUGGED_CHECK';
import { setBreadcrumb } from '../../../store/breadcrumbSlice';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Spinner from '../../../components/spinner';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProductsCreatedDetails = () => {
	const [loading,setLoading] = useState(false);
	const [productCreated, setProductCreated] = useState([]);
	const navigate = useNavigate();
	const [failedSkuBtn,setFailedSkuBtn] = useState(false);
	const [failedBrandBtn,setFailedBrandBtn] = useState(false);
	const [failedCategoryBtn,setFailedCategoryBtn] = useState(false)
	const [alignment, setAlignment] = useState('success');
	const [toggle,setToggle] = useState(true);

	useEffect(() => {
		if (productCreated.length !== 0 && productCreated?.product.length === 0) {
			if (toggle) {
				fetchProduct(1,'failed');
				setAlignment('failed');
				setToggle(false);
			}
		}
	},[productCreated])
	const auth = useSelector((state) => state.auth);

	const { Id } = useParams();
	const breadCrumb = useSelector((state) => state.breadcrumb);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [data,setData] = useState([]);

	const handleClickOpen = (data) => {
		setData(data);
	  	setOpen(true);
	};
  
	const handleClose = () => {
	  	setOpen(false);
	};

	const years = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const dn = new Date();

	const createdAt = (createdAt) => {
		const date = new Date(createdAt);
		let createdAtDate =
			date.getFullYear() === dn.getFullYear()
				? date.getMonth() === dn.getMonth()
					? date.getDate() === dn.getDate()
						? `Today at ${date.toLocaleTimeString()}`
						: date.getDate() === dn.getDate() - 1
							? `Yesterday at ${date.toLocaleTimeString()}`
							: years[date.getMonth()] +
							' ' +
							date.getDate() +
							' at ' +
							date.toLocaleTimeString()
					: years[date.getMonth()] +
					' ' +
					date.getDate() +
					' at ' +
					date.toLocaleTimeString()
				: years[date.getMonth()] + ' ' + date.getFullYear();

		return createdAtDate;
	}

	const fetchProduct = async (type,alignmentType) => {
		setLoading(true);
		const page = type;
		await axiosInstance
			.get(`/productCreated/group/${Id}?page=${page}&type=${alignmentType ? alignmentType : alignment}`)
			.then((res) => {
				setProductCreated(res.data.data);
				for (let ele of res?.data?.data?.product) {
					if (ele?.failedSku) {
						setFailedSkuBtn(true)
					}

					if (ele?.failedBrand) {
						setFailedBrandBtn(true)
					}

					if (ele?.failedCategory) {
						setFailedCategoryBtn(true)
					}
				}

				setLoading(false);
				const groupId = res?.data?.data?.product?.length ? res?.data?.data?.product[0]?.groupId?.groupId : res?.data?.data?.groupRecord?.groupId;
				dispatch(setBreadcrumb({ ...breadCrumb, groupId: groupId }));
			});
	};

	const handleExport = (event) => {
		event.preventDefault();

		axiosInstance
			.get(`/exports/products/${productCreated?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Products export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFailedSku = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/failedSku/${productCreated?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Failed SKU Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFile = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/fileData/${productCreated?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Uploaded File Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFailedBrand = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/failedBrand/${productCreated?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Failed Brand Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleExportFailedCategory = (event) => {
		event.preventDefault();
	
		axiosInstance
			.get(`/exports/reportService/failedCategory/${productCreated?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Failed Category Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}


	const handleExportImages = (event) => {
		event.preventDefault();

		axiosInstance
			.get(`/exports/products/Images/${productCreated?.product[0]?.groupId?._id}`)
			.then((result) => {
				const arrayOfRowObjects = result?.data?.data
				downloadCSV(arrayOfRowObjects, `Images Export - ${new Date()}`);
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const modifyForAccordion = (obj) => {
		let result = [];
		for (let key in obj) {
			if (obj[key].length !== 0) {
				result.push({
					title: key,
					content: obj[key],
				});
			}
		}
		return result;
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
		fetchProduct(page ? page : 1,alignment);
	}, []);

	const handleChange = (event) => {
        setAlignment(event.target.value);
		fetchProduct(1,event.target.value)
    };

	return (
		<Fragment>
			<div style={{ gap:'1rem',width:'99%' }}>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle style={{fontWeight:600}}>Reason</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							{data?.length !== 0 && (
								<div>
									{data?.database?.created === false && (
										<div style={{display:'flex',marginBottom:10}}>
											<p style={{fontWeight:600,marginRight:5,color:'#000'}}>Database:</p>
											<p style={{color:'#000',fontSize:15}}>{data?.database?.errorMessage}</p>
										</div>
									)}

									{data?.vinculum?.created === false && (
										<div style={{display:'flex'}}>
											<p style={{fontWeight:600,marginRight:5,color:'#000'}}>Vinculum:</p>
											<p style={{color:'#000',fontSize:15}}>{data?.vinculum?.errorMessage}</p>
										</div>
									)}
								</div>
							)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>CLOSE</Button>
					</DialogActions>
				</Dialog>

				{productCreated?.length !== 0 && (
					<div style={{ display:'flex',justifyContent:'flex-end',marginTop:-27 }}>
						{productCreated?.product?.length !== 0 && (
							<>
								{(auth?.user?.role === 'super-admin' || auth?.user?.role === 'admin') && ( 
									<div style={{marginRight:5}}>
										<button
											className="btn btn-primary"
											onClick={handleExportFile}
											style={{ padding:"4px 6px",fontSize:13 }}
										>
											Export File
										</button>
									</div>
								)}

								{failedSkuBtn && (
									<div style={{marginRight:5}}>
										<button 
											className="btn btn-primary" 
											onClick={handleExportFailedSku} 
											style={{ padding:"4px 6px",fontSize:13 }}
										>
											Export Failed Sku
										</button>
									</div>
								)}

								{failedBrandBtn && (
									<div style={{marginRight:5}}>
										<button
											className="btn btn-primary"
											onClick={handleExportFailedBrand}
											style={{ padding:"4px 6px",fontSize:13 }}
										>
											Export Failed Brands
										</button>
									</div>
								)}

								{failedCategoryBtn && (
									<div style={{marginRight:5}}>
										<button
											className="btn btn-primary"
											onClick={handleExportFailedCategory}
											style={{ padding:"4px 6px",fontSize:13 }}
										>
											Export Failed Category
										</button>
									</div>
								)}
								
								<div style={{marginRight:5}}>
									{productCreated?.product[0]?.requestType !== 'Delete Product' && productCreated?.product[0]?.requestType === 'Upload Images' && (
										<button
											className="btn btn-primary"
											onClick={handleExportImages}
											style={{ padding:"4px 6px",fontSize:13 }}
										>
											Exported Images
										</button>
									)}
								</div>
								<div>
									{productCreated?.product[0]?.requestType !== 'Delete Product' && (
										<button
											className="btn btn-primary"
											onClick={handleExport}
											style={{ padding:"4px 6px",fontSize:13 }}
										>
											Export Report
										</button>
									)}
								</div>
							</>
						)}
					</div>
				)}
			</div>

			{productCreated?.length !== 0 && !productCreated?.groupRecord && (
				<div style={{ display:'flex',justifyContent:'space-between',fontWeight:600,width:'99%',marginTop:5 }}>
					<div style={{ backgroundColor:'#9e9e9e1f',borderRadius:4,padding: 4 }}>
						<ToggleButtonGroup
							color="primary"
							value={alignment}
							exclusive
							onChange={handleChange}
							aria-label="Platform"
							sx={{
								"& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
								"& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
							}}
						>
							{productCreated?.product[0]?.groupId?.success && (
								<ToggleButton value="success">Success SKU</ToggleButton>
							)}
			
							{productCreated?.product[0]?.groupId?.failed && (
								<ToggleButton value="failed">Failed SKU</ToggleButton>
							)}
						</ToggleButtonGroup>
					</div>
					<div>
						Total: {productCreated.total}
					</div>
				</div>
			)}

			{productCreated?.length !== 0 && !productCreated?.groupRecord ? (
				<div className={Classes.Container} style={{ width:'99%',marginTop:10,borderRadius:4,padding:0 }}>
					<div className={Classes.GroupDetailsHeader}>
						<h6 className={Classes.Column} style={{ fontSize:15,width:'9%' }}>
							Request Id
						</h6>

						{productCreated?.product[0]?.brandName || productCreated?.product[0]?.category ? (
							<>
								{productCreated?.product[0]?.category ? (
									<>
										<h6 className={Classes.Column} style={{ fontSize:15,width:'12%' }}>
											Category
										</h6>
										<h6 className={Classes.ProductDetail} style={{ fontSize:15,width:'30%',marginLeft:25 }}>
											Category Name
										</h6>
									</>
								) : (
									<h6 className={Classes.ProductDetail} style={{ fontSize:15,width:'30%',marginLeft:25 }}>
										Brand Name
									</h6>
								)}
							</>
						) : (
							<>
								<h6 className={Classes.Column} style={{ fontSize:15,width:'19%' }}>
									SKU
								</h6>
								<h6 className={Classes.ProductDetail} style={{ fontSize:15,width:'34%',margin:'0px 0px 0px 25px' }}>
									Product
								</h6>
							</>
						)}

						<h6 className={Classes.CreatedAtHeader} style={{ marginLeft:20 }}>
							Created At
						</h6>
						<div className={Classes.Platforms}>
							<div className={Classes.PlatformsType} style={{ justifyContent: 'space-between' }}>
								<h6 style={{ fontSize:15,marginRight:15 }}>Database</h6>

								{productCreated?.product[0]?.brandName || productCreated?.product[0]?.category ?
									<h6 style={{fontSize:15}}>Reason</h6>
								: 
									<>
										{process.env.REACT_APP_VINCULUM_UNPLUGGED === "true" && (
											<h6 style={{fontSize:15}}>Vinculum</h6>
										)}
									</>
								}							
							</div>
						</div>
					</div>

					<div style={{ fontSize:13 }}>
						{productCreated?.length !== 0 && productCreated?.product?.map((created) => (
							<div
								key={created?._id}
								className={Classes.Row}
								onClick={
									!(productCreated?.product[0]?.brandName || productCreated?.product[0]?.category || created?.requestType === 'Delete Product') ?
									created?.failedSku ? () => handleClickOpen(created) : () => navigate(`/products/${created?.product}`)
									: false
								}

								style={{
									padding:12,
									cursor: !(productCreated?.product[0]?.brandName || productCreated?.product[0]?.category) ?
										'pointer' : 'auto'
								}}
							>
								<div className={Classes.RequestId}>
									<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>Request Id:</p>
									<p>{created?.requestId}</p>
								</div>

								{productCreated?.product[0]?.brandName || productCreated?.product[0]?.category ? (
									<>
										{productCreated?.product[0]?.category ? (
											<>
												<p className={Classes.Column} style={{ fontSize:15,width:'16%',paddingLeft:25 }}>
													{created?.category}
												</p>
												<p className={Classes.ProductDetail} style={{ fontSize:15,marginLeft:25 }}>
													{created?.title}
												</p>
											</>
										) : (
											<p className={Classes.ProductDetail} style={{ fontSize:15,marginLeft:25 }}>
												{created?.brandName}
											</p>
										)}
									</>
								) : (
									<>
										<div className={Classes.Sku}>
											<p className='d-flex d-md-none text-muted' style={{marginRight:10}}>SKU:</p>
											<p>{created?.sku}</p>
										</div>
										<div className={Classes.ProductDetail}>
											<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>
												Product:
											</p>
											<p>{created?.title}</p>
										</div>
									</>
								)}


								<div className={Classes.CreatedAt} style={{width:'17%'}}>
									<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600}}>
										Created At:
									</p>
									<p>{createdAt(created?.createdAt)}</p>
								</div>

								<div className={Classes.Platforms}>
									<div className={Classes.PlatformsType} style={{ justifyContent:'space-between' }}>
										<div className={Classes.Database}>
											<p className='d-flex d-md-none text-muted' style={{marginRight:10,fontWeight:600,marginTop:-5}}>
												Database:
											</p>
											{created?.database && (
												<>
													{Array.isArray(created?.database) && created?.database.length !== 0 ? (
														<FontAwesomeIcon
															icon={created?.database[0] === true ? faCircleCheck : faCircleXmark}
															style={{fontSize:15,color:created?.database[0] === true ? 'green': 'red'}}
														/>
													) : (
														<FontAwesomeIcon
															icon={created?.database?.created === true ? faCircleCheck : faCircleXmark}
															style={{fontSize:15,color:created?.database?.created === true ? 'green': 'red'}}
														/>
													)}
												</>
											)}
										</div>

										{process.env.REACT_APP_VINCULUM_UNPLUGGED === "true" && (
											<div>
												<p className='d-flex d-md-none text-muted'>Vinculum:</p>

												{created.vinculum && (
													<div style={{ width: '15%' }}>
														{Array.isArray(created?.vinculum) && created?.vinculum?.length !== 0 ? (
															<FontAwesomeIcon
																icon={created?.vinculum[0]?.created === true ? faCircleCheck : faCircleXmark}
																style={{fontSize:15,marginRight:20,color:created?.vinculum[0]?.created === true ? 'green': 'red'}}
															/>
														) : (
															<FontAwesomeIcon
																icon={created?.vinculum?.created === true ? faCircleCheck : faCircleXmark}
																style={{fontSize:15,marginRight:20,color:created?.vinculum?.created === true ? 'green': 'red'}}
															/>
														)}
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div style={{ margin: '30px 0px 0px 0px' }}>
					{productCreated?.length !== 0 && productCreated?.groupRecord?.errors?.brandNotFoundError?.length !== 0 && (
						<Accordion accordionData={modifyForAccordion(productCreated?.groupRecord?.errorObject)} />
					)}
				</div>
			)}

			<div style={{ margin: '10px auto 0px' }}>
				{productCreated?.length !== 0 ? (
					<NewPagination totalPage={productCreated} fetchData={fetchProduct} />
				) : null}
			</div>

			{loading ? <Spinner /> : null}
		</Fragment>
	);
};

export default ProductsCreatedDetails;
