import { Fragment } from 'react';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from './css/addProduct.module.css';


const  AllPopUp = (props) => {
    const {
        showErrorMsg,
        storePrevGroupName,
        handlePrevButton,
        handleChangeTitleName,
        handleChangeTitle,
        handleError,
        handleCreateProduct,
        showVinculumError,
    }= props;

	
	return (
		<Fragment>
            <div>
                <div className={Classes.GroupNameBox} id='GroupNameBox'>
                    <div className={ Classes.GroupNameContent }>
                        <p>&#x2022;</p>
                        <p className={Classes.GroupName}>
                            This Group Title already exist on Shopify
                            &nbsp;<span style={{color:'red'}}>{storePrevGroupName[0]}</span>
                        </p> 
                    </div>

                    <div className={Classes.GroupNameButtons}>
                        <div className={Classes.GroupNameCreateVariant}>
                            <p
                                className={Classes.CreateVariant}
                                onClick={(e) => handlePrevButton(e)}
                            >
                                Create Variant
                            </p>
                        </div>
                        <div style={{display:'flex'}}>
                            <div 
                                className={Classes.ChangeTitle} 
                                id='changeTitle'
                            >
                                <input 
                                    id='titleName'
                                    type="text" 
                                    className={Classes.GroupNameTitle}
                                />
                                <p
                                    className={Classes.GroupNameChangeTitle}
                                    onClick={(e) => handleChangeTitleName(e)}
                                >
                                    Change Title
                                </p>
                            </div>
                            <p
                                id='titleBtn'
                                className={Classes.GroupNameChangeTitle}
                                onClick={(e) => handleChangeTitle(e)}
                            >
                                Change Title
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className={Classes.SkuBox} id='SkuBox'>
                    &#x2022;<p className={Classes.Sku}>{skuExistDB.data}</p>
                </div> */}

                <div 
                    className={Classes.Platform} 
                    id='platforms'
                >
                    <h6 
                        style={{
                            padding: 10,
                            margin:0
                        }}
                    >
                        Product Created
                    </h6>
                    <div className={Classes.PlatformBox}>
                        <div style={{display:'flex'}}>
                            <div>
                                <FontAwesomeIcon 
                                    icon={faCircleCheck} 
                                    className={Classes.Icon}
                                />
                            </div>
                            <p>Database</p>
                        </div>
                    </div>

                    <div>
                        <div className={Classes.PlatformBox}>
                            <div style={{display:'flex'}}>
                                <div>
                                    <FontAwesomeIcon 
                                        icon={
                                            showErrorMsg.length !== 0 ? 
                                            faCircleXmark : 
                                            faCircleCheck
                                        } 
                                        className={Classes.Icon}
                                    />
                                </div>
                                <p>Shopify</p>
                            </div>

                            {showErrorMsg.length !== 0 && (
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faAngleDown} 
                                        className={Classes.AngleDown}
                                        id='shopify'
                                        data-toggle={false}
                                        onClick={(e) => handleError(e)}
                                    />
                                </div>
                            )}
                        </div>
                        
                        {showErrorMsg.length !== 0 && (
                            <div className={Classes.ErrorContent}>
                                <div className={Classes.ErrorContentBox1}>
                                    &#x2022; &nbsp;
                                    <div className={Classes.ErrorMessage}>
                                        <p>{showErrorMsg.platform} 
                                            <span style={{color: '#000'}}>{showErrorMsg.fieldName}. </span>
                                        </p>
                                        <p> Product not created on Shopify.</p>
                                    </div>
                                </div>
                                <div>
                                    <p>Create Product on Shopify</p>
                                    <div style={{textAlign:'center'}}>
                                        <p
                                            className={Classes.PlatformSpecificBtn}
                                            onClick={() => handleCreateProduct()}
                                        >Create</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div>
                        <div className={Classes.PlatformBox}>
                            <div style={{display:'flex'}}>
                                <div>
                                    <FontAwesomeIcon 
                                        icon={
                                            showVinculumError.length !== 0 ? 
                                            faCircleXmark: 
                                            faCircleCheck
                                        } 
                                        className={Classes.Icon}
                                    />
                                </div>
                                <p>Vinculum</p>
                            </div>

                            {showVinculumError.length !== 0 && (
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faAngleDown} 
                                        className={Classes.AngleDown}
                                        id='veniculum'
                                        data-toggle={false}
                                        onClick={(e) => handleError(e)}
                                    />
                                </div>
                            )}
                        </div>

                        {showVinculumError.length !== 0 && (
                            <div className={Classes.ErrorContent}>
                                <div className={Classes.ErrorContentBox1}>
                                    &#x2022; &nbsp;
                                    <div className={Classes.ErrorMessage}>
                                        <p>{showVinculumError.errorMsg}.</p>
                                        <p> Product not created on Vinculum.</p>
                                    </div>
                                </div>
                                {/* <div>
                                    <p>Create Product on Vinculum</p>
                                    <div style={{textAlign:'center'}}>
                                        <p
                                            className={Classes.PlatformSpecificBtn}
                                            onClick={() => handleCreateProductInVeniculum()}
                                        >Create</p>
                                    </div>
                                </div> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
		</Fragment>
	);
};

export default AllPopUp;
