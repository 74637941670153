import { Fragment, useState, useEffect } from "react";
import TableCSVExporter from "../../../utility/tabletocsv";
import { Link } from "react-router-dom";
import Spinner from '../../../components/spinner';
import axiosInstance from "../../../utility/axios-instance";
import Multiselect from "multiselect-react-dropdown";
import "./updatePricing.css";
import { downloadCSV } from "../../../utility/downloadCSV";
import { useSelector, useDispatch } from 'react-redux';
import { setProductsData } from '../../../store/updatePricingSlice';

export default function UpadatePricing() {
    const [loading,setLoading] = useState(false);
    const [retailer, setRetail] = useState(false);
    const [interior, setInterior] = useState(false);
    const [endUser, setEnduser] = useState(false);
    const [oem, setOem] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [queryParam, setQueryParam] = useState("");
    const [contentEditable, setContentEditable] = useState({});
    const [roles, setRoles] = useState([]);
    const [brands, setBrands] = useState([]);
    const [filter, setFilter] = useState("");

    const productsData = useSelector((state) => state.productsPricing);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    const fetchProducts = async (queryParam) => {
        await axiosInstance
            .get(`/updatePricing/pricing?${queryParam}`)
            .then((result) => {
                dispatch(setProductsData(result?.data?.data))
            });
    };

    useEffect(() => {
        axiosInstance
            .get("/user/admin/customerRoles")
            .then((result) => {
                const data = result.data.data.reduce(
                    (accumulator, currentValue, currentIndex) => {
                        accumulator.push({
                            role: currentValue.title,
                            id: currentIndex + 1,
                        });
                        return accumulator;
                    },
                    []
                );
                setOptions(data);
            })
            .catch((error) => console.log("error", error));
    }, []);

    const [options, setOptions] = useState([]);

    const productRowElements = productsData?.productsPricing?.map((product, productIndex) => {
        const {
            endUserPrice,
            interiorPrice,
            listingPrice,
            mrp,
            oemPrice,
            retailPrice,
            title,
            sku,
            productId,
            gst
        } = product
        return (
            <tr
                className={productIndex % 2 === 0 ? "table-active" : ""}
                key={productIndex + 1}
            >
                <td data-inside="true" data-product_title={`${title}`} data-product_id={`${productId}`} data-gst={`${gst}`}>
                    {title}
                </td>
                <td data-sku>{sku}</td>
                <td
                    {...contentEditable}
                    suppressContentEditableWarning={true}
                    data-mrp
                >
                    {mrp}
                </td>
                <td
                    {...contentEditable}
                    suppressContentEditableWarning={true}
                    data-listing-price
                >
                    {listingPrice}
                </td>
                {retailer && (
                    <td
                        {...contentEditable}
                        suppressContentEditableWarning={true}
                        data-retail-price
                    >
                        {retailPrice}
                    </td>
                )}
                {interior && (
                    <td
                        {...contentEditable}
                        suppressContentEditableWarning={true}
                        data-interior-price
                    >
                        {interiorPrice}
                    </td>
                )}
                {endUser && (
                    <td
                        {...contentEditable}
                        suppressContentEditableWarning={true}
                        data-end-user-price
                    >
                        {endUserPrice}
                    </td>
                )}
                {oem && (
                    <td
                        {...contentEditable}
                        suppressContentEditableWarning={true}
                        data-oem-price
                    >
                        {oemPrice}
                    </td>
                )}
            </tr>
        );
    });

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Update Pricing')) {
            fetchProducts("");
        }
    }, []);

    useEffect(() => {
        axiosInstance.get("/others/adminBrands").then((res) => {
            setBrands(res.data.data);
        });
    }, []);

    const handleNextClick = () => {
        const searchParams = new URLSearchParams(queryParam);
        if(productsData){
            if(searchParams.has("page")){
                searchParams.set("page", productsData?.currentPage+1)
            }else{
                searchParams.append("page", productsData?.currentPage+1)
            }
        }
        fetchProducts(searchParams.toString());
    };
    const handlePrevClick = () => {
        const searchParams = new URLSearchParams(queryParam);
        if(productsData?.currentPage){
            if(searchParams.has("page")){
                searchParams.set("page", productsData?.currentPage-1)
            }else{
                searchParams.append("page", productsData?.currentPage-1)
            }
        }
        if (productsData?.currentPage <= 1) {
            return;
        }
        fetchProducts(searchParams.toString());
    };

    const handleChange = async (event) => {
        event.preventDefault();
        const brand = event?.target?.value
        setFilter(brand)
        const searchParams = new URLSearchParams();
        if (searchInput && brand) {
            searchParams.append("brandName", brand)
            searchParams.append("searchPhrase", searchInput)
            searchParams.append("page", 1)
        } else if (searchInput) {
            searchParams.append("searchPhrase", searchInput)
            searchParams.append("page", 1)
        } else if (brand) {
            searchParams.append("brandName", brand)
            searchParams.append("page", 1)
        } else {
            searchParams.append("page", 1)
        }
        const queryParam = searchParams.toString()
        setQueryParam(queryParam)
        await fetchProducts(queryParam)
    };

    const handleProduct = async (event) => {
        event.preventDefault();
        const searchInput = event?.target?.value
        setSearchInput(searchInput)
        const isValidInput = (input) => {
            const pattern = /^(?:[a-zA-Z0-9-_ ]+)?$/;
            return pattern.test(input);
        };
        if(isValidInput(searchInput)){
            const searchParams = new URLSearchParams();
            if (searchInput && filter) {
                searchParams.append("brandName", filter)
                searchParams.append("searchPhrase", searchInput)
                searchParams.append("page", 1)
            }
            else if (searchInput) {
                searchParams.append("searchPhrase", searchInput)
                searchParams.append("page", 1)
            } else if (filter) {
                searchParams.append("page", 1)
            } else {
                searchParams.append("page", 1)
            }
            const queryParam = searchParams.toString()
            setQueryParam(queryParam)
            await fetchProducts(queryParam)
        }else{
            alert("Incorrect Search Input!")
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await axiosInstance
            .get(`/updatePricing/pricing?${queryParam}`)
            .then((result) => {
                dispatch(setProductsData(result?.data?.data))
            });
    };

    const handleExport = () => {
        axiosInstance
            .get(`/exports/pricing?${queryParam}`)
            .then((result) => {
                const productPrices = result?.data?.data?.productsPricing;
                if (productPrices.length > 0) {
                    const arrayOfRowObjects = productPrices
                    downloadCSV(arrayOfRowObjects, `Pricing Export - ${new Date()}`);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleImport = (event) => {
        event.preventDefault();
        var formdata = new FormData();
        formdata.append(
            "pricing",
            document.querySelectorAll('input[type="file"]')[0].files[0]
        );

        axiosInstance
            .post("/updatePricing/uploadFile", formdata)
            .then((result) => {
                document.querySelector("[data-file-upload-alert]").style.display =
                    "block";
                setTimeout(() => {
                    document.querySelector("[data-file-upload-alert]").style.display =
                        "none";
                }, 2000);
            })
            .catch((error) => console.log("error", error));
    };

    const handleSave = (event) => {
        setLoading(true);
        setContentEditable({});

        const csvData = new TableCSVExporter(
            document.getElementsByTagName("table")[0]
        ).convertToCSV();

        const csvBlob = new Blob([csvData], { type: "text/csv" });

        var formdataEdited = new FormData();

        formdataEdited.append("pricing", csvBlob);

        axiosInstance
            .post("/updatePricing/uploadFile", formdataEdited)
            .then((result) => {
                setLoading(false);
                document.querySelector("[data-price-edit-alert]").style.display =
                    "block";
                setTimeout(() => {
                    document.querySelector("[data-price-edit-alert]").style.display =
                        "none";
                }, 2000);
            })
            .catch((error) => console.log("error", error));
    };

    const handleEdit = (event) => {
        setContentEditable({ contentEditable: true });
    };

    const handleonSelect = (selectedList, selectedItem) => {
        if (selectedItem.role === "retailer") {
            setRetail(true);
        }
        if (selectedItem.role === "interior") {
            setInterior(true);
        }
        if (selectedItem.role === "end_user") {
            setEnduser(true);
        }
        if (selectedItem.role === "oem") {
            setOem(true);
        }
        const customerRoles = selectedList.reduce((accumulator, currentValue) => {
            accumulator.push(currentValue.role);
            return accumulator;
        }, []);
        setRoles(customerRoles);
    };
    const handleonRemove = (selectedList, removedItem) => {
        if (removedItem.role === "retailer") {
            setRetail(false);
        }
        if (removedItem.role === "interior") {
            setInterior(false);
        }
        if (removedItem.role === "end_user") {
            setEnduser(false);
        }
        if (removedItem.role === "oem") {
            setOem(false);
        }
        const customerRoles = selectedList.reduce((accumulator, currentValue) => {
            accumulator.push(currentValue.role);
            return accumulator;
        }, []);
        setRoles(customerRoles);
    };

    return (
        <>
            <div
                className="alert alert-primary p-2"
                data-file-upload-alert
                role="alert"
                style={{ display: "none" }}
            >
                File Successfully Imported
            </div>
            <div
                className="alert alert-primary p-2"
                data-price-edit-alert
                role="alert"
                style={{ display: "none" }}
            >
                Prices Successfully Edited
            </div>
            <div className="d-flex justify-content-between my-2 responsive">
                <form className="d-flex import">
                    <input
                        className="form-control me-2"
                        type="file"
                        id="formFile"
                        name="pricing"
                        accept=".csv"
                    ></input>
                    <button className="btn btn-primary" onClick={handleImport}>
                        Import
                    </button>
                </form>
                <div className="d-flex">
                    <Multiselect
                        options={options}
                        displayValue={"role"}
                        onSelect={handleonSelect}
                        onRemove={handleonRemove}
                    />
                    <button
                        className="btn btn-warning mx-2"
                        onClick={handleExport}
                        data-export
                    >
                        Export
                    </button>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-2 saveDiv">
                <div className="btn-grp" role="group" aria-label="Basic example">
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={handleEdit}
                    >
                        Edit
                    </button>
                    <button type="button" className="btn btn-danger" onClick={handleSave}>
                        Save
                    </button>
                </div>
                <form
                    onSubmit={handleSubmit}
                    className="product-pricing-form d-flex justify-content-between"
                >
                    <input
                        name="search"
                        onChange={(e) => handleProduct(e)}
                        type="text"
                        placeholder="Search Products Here"
                        className="search-products form-control mx-2"
                        autoComplete="off"
                    />
                    <select
                        style={{ marginTop: "0px" }}
                        className="form-select me-2"
                        onChange={(e) => handleChange(e)}
                        name="brand"
                    >
                        <option value="">Brands</option>

                        {brands.map((brand) => (
                            <option key={brand.brandAbr} value={brand.title}>
                                {brand.title}
                            </option>
                        ))}
                    </select>
                </form>
            </div>

            <div>
                <table className="table table-hover">
                    <thead>
                        <tr style={{ borderBottom: "2px solid black" }}>
                            <th
                                data-product_title="true"
                                data-product_id="true"
                                data-gst="true"
                                scope="col"
                            >
                                Product Title
                            </th>
                            <th data-sku="true" scope="col">
                                SKU
                            </th>
                            <th data-mrp="true" scope="col">
                                MRP
                            </th>
                            <th data-listing-price="true" scope="col">
                                List Price
                            </th>
                            {retailer && (
                                <th data-retail-price="true" scope="col">
                                    Retail Price
                                </th>
                            )}
                            {interior && (
                                <th data-interior-price="true" scope="col">
                                    Interior Price
                                </th>
                            )}
                            {endUser && (
                                <th data-end-user-price="true" scope="col">
                                    End User Price
                                </th>
                            )}
                            {oem && (
                                <th data-oem-price="true" scope="col">
                                    Oem Price
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>{productRowElements}</tbody>
                </table>
                <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                        <div style={{
                            "padding-right": "10px",
                        }}>
                            <p>Page {productsData?.currentPage} of {productsData?.maxPage}</p>
                        </div>
                        <li className={["page-item"].join(" ")}></li>
                        <Link
                            className="page-link"
                            to={"#"}
                            tabIndex="-1"
                            onClick={handlePrevClick}
                        >
                            Previous
                        </Link>
                        <li className={["page-item"].join(" ")}>
                            <Link
                                className="page-link"
                                to={"#"}
                                tabIndex="-1"
                                onClick={handleNextClick}
                            >
                                Next
                            </Link>
                        </li>
                    </ul>
                </nav>

                {loading && <Spinner/>}
            </div>
        </>
    );
}