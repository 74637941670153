import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import ConfirmPopup from '../customers/confirmPopup';
import NewPagination from '../../../components/newPagination';
import NewCustomerModal from '../createOrders/aside/newCustomerModal';
import { useSelector, useDispatch } from 'react-redux';
import { downloadCSV } from "../../../utility/downloadCSV";
import { CheckRoleBasedPermission, permission, services } from '../../../utility/checkRoleBasedPermission';
import CreatedByModal from '../orders/createdByFilter';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { GridToolbar } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputAdornment, TextField, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import Spinner from '../../../components/spinner';
import Permission from '../../auth/permissions';
import moment from 'moment';
import { setCustomers } from '../../../store/customersSlice';

const Customers = (props) => {
    const [error, setError] = useState(null);
    const [storeCustomerId, setCustomerId] = useState([]);
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);
    const [gstNO, setGstNO] = useState();
    const [showModal, setShowModal] = useState(false);
    const [num, setNum] = useState('');
    const [fetchCustomerRoleDB, setCustomerRoleDB] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [users, setUsers] = useState([]);
    const [createdBy, setCreatedBy] = useState([]);
    const [filter, setFilter] = useState(null);
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false);
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });

    const auth = useSelector((state) => state.auth);
    const customers = useSelector((state) => state.customers);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();

    const columns = [
        {
            flex: 0.5,
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            headerClassName: 'super-app-theme--header'
        },
        { field: 'displayName', headerName: 'NAME', flex: 1, headerClassName: 'super-app-theme--header', sortable: true, filterable: false },
        { field: 'email', headerName: 'EMAIL', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
        { field: 'phone', headerName: 'PHONE', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
        {
            field: 'customerType',
            headerName: 'TYPE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: true,
            flex: 1,
            renderCell: (params) => (
                params.value === "B2C" ? (

                    <Chip icon={<PersonIcon />} label={params.value} />
                ) : (
                    <Chip icon={<StoreIcon />} label={params.value} />
                )
            )
        },
        { field: 'createdAt', filterable: false, headerName: 'CREATED AT', flex: 1.5, headerClassName: 'super-app-theme--header', sortable: true },
        { field: 'createdFrom', headerName: 'FROM', flex: .5, headerClassName: 'super-app-theme--header', sortable: true },
        { field: 'customerCode', headerName: 'Customer Code', filterable: false, flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
        { field: 'createdBy', filterable: false, headerName: 'CREATED BY', flex: 1, headerClassName: 'super-app-theme--header', sortable: true },
    ];

    const navigate = useNavigate();
    const getModal = () => {
        setShowModal(true);
    };

    const hideModal = () => {
        setNum('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setBusinessName('');
        setGstNO('');
        setErrorMessage('');
        setShowModal(false);
        setSelectedState('');
        setCity('');
        setArea('');
    };

    if (document.querySelector('.MuiDataGrid-footerContainer')) {
        document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
    }

    const addCustomerId = (Id) => {
        if (storeCustomerId.length === 0) {
            document.getElementById('deleteBtn').style.backgroundColor = 'red';
            document.getElementById('deleteBtn').style.color = '#fff';
            setCustomerId([Id]);
        } else {
            for (let ele of storeCustomerId) {
                if (ele !== Id) {
                    setCustomerId([...storeCustomerId, Id]);
                }
            }
        }
    };

    const removeCustomerId = (Id) => {
        // setCustomerId(storeCustomerId.filter((customerId) => customerId !== Id));
        const array = storeCustomerId.filter((customerId) => customerId !== Id)
        setCustomerId(array)

        if (array.length === 0) {
            document.getElementById('deleteBtn').style.backgroundColor = 'transparent';
            document.getElementById('deleteBtn').style.color = '';
        }
    }

    const handleChildNode = (event) => {
        if (event.target.checked) {
            addCustomerId(event.target.id)
        } else {
            const child = document.getElementsByClassName('childNode')
            for (let childNode of child) {
                const childCheckBox = childNode.firstChild.firstChild;
                if (childCheckBox.checked) {
                    removeCustomerId(event.target.id)
                    return;
                }
            }
            removeCustomerId(event.target.id);
        }
    }

    const handleSearchCustomer = (event) => {
        setSearch(event.target.value);
    }

    const fetchCustomers = async (type, search) => {
        setLoading(true)
        let usersQuery = createdBy.join(',');
        const page = type;
        const params = {
            page,
            text: search ? search : '',
            filter: JSON.stringify(filter),
            createdBy: usersQuery,
            sort
        };
        await axiosInstance
            .get(`/user/admin`, {
                params,
            })
            .then((res) => {
                dispatch(setCustomers(res?.data?.data));
            });
        setLoading(false)
    };

    useEffect(() => {
        axiosInstance
            .get('/user/admin/customerRoles')
            .then((res) => {
                setCustomerRoleDB([...res.data.data])
            })
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Customers')) {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
            fetchCustomers(page ? page : 1, search);
        }
    }, [search, filter, sort])

    const handleDeleteCustomer = () => {
        axiosInstance
            .post('/user/admin/deleteCustomersInBulk/', {
                customersIds: storeCustomerId
            })
            .then((res) => {
                const arr = []
                setCustomerId(arr);
                fetchCustomers();
            })
    }

    const handleShowPopup = () => {
        setConfirmShowPopup(true)
    }


    const hideConfirmPopup = (event) => {
        if (event.target.innerText === 'DELETE' && storeCustomerId?.length !== 0) {
            handleDeleteCustomer();
        }
        setConfirmShowPopup(false)
    }

    const handleUserSelect = async (selectedUsers) => {
        setLoading(true)
        const admins = selectedUsers.map(user => user.name);
        let usersQuery = admins.join(',');
        setCreatedBy(admins);

        const page = 1;
        const params = {
            page,
            text: search ? search : '',
            filter: JSON.stringify(filter),
            createdBy: usersQuery
        };
        await axiosInstance
            .get(`/user/admin`, {
                params,
            })
            .then((res) => {
                setCustomers(res.data.data);
            });
        setLoading(false)
    };


    const handleExport = (event) => {
        event.preventDefault();
        document.getElementById("fileDownloading").style.display = "block";
        document.getElementById("exportbtn").disabled = true;

        axiosInstance
            .get('/exports/customers')
            .then((result) => {
                document.getElementById("fileDownloading").style.display = "none";
                document.getElementById("exportbtn").disabled = false;
                const arrayOfRowObjects = result?.data?.data?.users
                downloadCSV(arrayOfRowObjects, `Customers export - ${new Date()}`);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Fragment>
            <div id="color">
                <div
                    className="alert alert-primary p-2"
                    data-file-upload-alert role="alert"
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>

                <div className="d-flex  mb-3" style={{justifyContent:'flex-end'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: "10px", marginRight:10,marginTop:-25 }} >
                        <Permission service={services?.customers} permission="delete">
                            <div style={{}}>
                                <Button
                                    type="submit"
                                    id='deleteBtn'
                                    disabled={storeCustomerId.length === 0 ? true : false}
                                    onClick={handleShowPopup}
                                    variant="contained"
                                    color="error"
                                    size='small'
                                    style={{textTransform:'capitalize'}}
                                    sx={{"& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px"}}}
                                    startIcon={<DeleteIcon />}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Permission>

                        <Permission service={services?.customers} permission="create">
                            <div style={{}}>
                                <Button 
                                    id='createbtn' 
                                    size='small' 
                                    style={{textTransform:'capitalize'}} 
                                    startIcon={<Add />} 
                                    onClick={getModal} 
                                    variant="contained"
                                    sx={{"& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px"}}}
                                >Create</Button>
                            </div>
                        </Permission>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate('/customers/fetchCustomerUpdateHistory')}
                            style={{ marginLeft: '10px' }}
                            size='small'
                        >
                            Customer's Update History
                        </Button>

                        <Permission service={services?.customers} permission="read">
                            <div style={{}}>
                                <Button
                                    id='exportbtn'
                                    onClick={handleExport}
                                    size='small'
                                    variant="contained" color="success"
                                    style={{textTransform:'capitalize'}}
                                    sx={{"& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px"}}}
                                >
                                    Export
                                </Button>
                            </div>
                        </Permission>
                    </div>
                </div>
                {error ? (
                    <Alert
                        variant="danger"
                        onClose={() => setError(false)}
                        dismissible
                    >
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}

                <div className={Classes.Container} style={{ width: '99%' }} >
                    <div className={Classes.HeaderBox} style={{ paddingTop:10,paddingBottom:10 }}>
                        <div className={Classes.SearchBox} style={{ border:'none' }}>
                            <div style={{ width: '100%' }}>
                                <TextField
                                    id="search"
                                    type="search"
                                    label="Search"
                                    value={search}
                                    onChange={(e) => handleSearchCustomer(e)}
                                    autoComplete='off'
                                    sx={{ width: '100%', "& label": { top: search ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div className={Classes.CreatedByFilter}>
                            <CreatedByModal onSelect={handleUserSelect} users={users} setUsers={setUsers} />

                        </div>
                    </div>

                    <div className="d-none d-md-block">
                        <Box className="shadow"
                            component={Paper}
                            sx={{
                                '& .super-app-theme--header': {
                                    backgroundColor: '#243750',
                                    color: '#ffffff',
                                    cursor: "default"
                                },
                            }}>
                            <DataGrid
                                rows={customers?.users?.map((user) => ({
                                    displayName: user?.displayName,
                                    email: user?.email,
                                    phone: user?.phone,
                                    customerType: user?.customerType,
                                    createdAt: moment(user?.createdAt).format('MMM DD, YYYY h:mm:ss A'),
                                    createdBy: user?.createdBy?.name,
                                    id: user?._id,
                                    createdFrom: user?.createdFrom,
                                    customerCode : user?.customerCode
                                }))}
                                columns={columns}
                                slots={{ toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        csvOptions: { disableToolbarButton: true },
                                        printOptions: { disableToolbarButton: true },
                                    },
                                }}
                                filterMode="server"
                                sortingMode="server"
                                paginationMode="server"
                                disableRowSelectionOnClick
                                checkboxSelection={true}
                                autoHeight
                                autoPageSize
                                onFilterModelChange={(filter) => (setFilter(filter.items[0]), setSearch(filter.quickFilterValues[0]))}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setCustomerId(newRowSelectionModel)
                                }}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    ".PrivateSwitchBase-input": {
                                        height: "23px",
                                        margin: "10px 13px",
                                        width: "20px"
                                    },
                                    cursor: "pointer"
                                }}
                                onRowClick={(params) => {
                                    if (CheckRoleBasedPermission(auth?.user, services?.customers, permission?.read)) {
                                        navigate(`/customers/${params?.id}`);
                                    }
                                }}
                                initialState={{
                                    sorting : {
                                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                    },
                                    }}
                                onSortModelChange={(sort) => setSort(sort[0])}
                            />
                        </Box>
                    </div>

                    <div className="d-block d-md-none">
                        {customers?.users?.map((customer) => (
                            <div
                                className={[Classes.Customer,'childNode',Classes.Box].join(' ')}
                                key={customer?._id}
                            >
                                <Permission service={services?.customers} permission="delete">
                                    <div style={{ margin:'auto 0px' }}>
                                        <input
                                            type='checkbox'
                                            style={{ width: 16, height: 16 }}
                                            className={Classes.checkBox}
                                            id={customer?._id}
                                            onChange={handleChildNode}
                                        />
                                    </div>
                                </Permission>
                                <div
                                    className={Classes.CustomerDetail}
                                    onClick={
                                        CheckRoleBasedPermission(auth?.user, services?.customers, permission?.read) ?
                                            () => { navigate(`${customer?._id}`) }
                                            : null}
                                >
                                    <div className={Classes.CustomerName}>
                                        <div style={{ display:'flex',margin:'auto 0px' }}>
                                            <p>
                                                {customer?.displayName ? customer?.displayName :
                                                customer?.firstName + " " + customer?.lastName && customer?.lastName}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={Classes.Email}>
                                        <p className='text-muted d-block d-md-none'>Email:</p>
                                        <p>{customer?.email}</p>
                                    </div>

                                    <div className={Classes.Phone}>
                                        <p className='text-muted d-block d-md-none'>Phone:</p>
                                        <p style={{ margin: 'auto 0px' }}>{customer?.phone}</p>
                                    </div>

                                    <div style={{ margin:'auto 0px',display:'flex' }}>
                                        <p className='text-muted d-block d-md-none' style={{ paddingRight:5 }}>
                                            Customer Type:
                                        </p>
                                        <p
                                            className={Classes.customerType}
                                            style={{backgroundColor: customer?.customerType === 'B2C' ?'lightgreen' :'lightblue'}}
                                        >
                                            {customer?.customerType}
                                        </p>
                                    </div>

                                    <div className={Classes.CreatedAt} style={{display:'flex'}}>
                                        <p className='text-muted d-block d-md-none'>Created At:</p>
                                        <p style={{ paddingLeft:5 }}>{moment(customer?.createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
                                    </div>

                                    <div className={Classes.CreatedBy}>
                                        <p className='text-muted d-block d-md-none'>Created By:</p>
                                        {customer?.createdBy ? (
                                            <p style={{ paddingLeft:5 }}>{customer?.createdBy?.name}</p>
                                        ) : (
                                            <p style={{ paddingLeft:5 }}>
                                                {customer?.firstName} {""}
                                                {customer?.lastName !== 'undefined' && ( <>{customer?.lastName}</> )}
                                            </p>
                                        )}
                                    </div>
                                    <div className={Classes.CreatedBy}>
                                        <p className='text-muted d-block d-md-none'>Created From:</p>
                                            <p style={{ paddingLeft: 5 }}>
                                                {customer?.createdFrom ? customer?.createdFrom : "N/A"}
                                            </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {customers?.users?.length > 0 && (
                        <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                            <NewPagination totalPage={customers} fetchData={fetchCustomers} />
                        </div>
                    )}

                    <div className={showConfirmPopup ? Classes.UpdateSalesDialog: undefined}>
                        <ConfirmPopup
                            showConfirmPopup={showConfirmPopup}
                            hideConfirmPopup={hideConfirmPopup}
                            storeCustomerId={storeCustomerId}
                        />
                    </div>
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewCustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    fetchCustomerRoleDB={fetchCustomerRoleDB}
                    fetchCustomers={fetchCustomers}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    popUpType='customerDashboard'
                    endpoint={`/user/admin`}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    setCity={setCity}
                    city={city}
                    setArea={setArea}
                    area={area}
                />
            </div>
            {loading && <Spinner />}
        </Fragment>
    );
};

export default Customers;
