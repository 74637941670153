import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import { Paper, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import axiosInstance from '../../../utility/axios-instance';
import { setUsers } from '../../../store/usersSlice';
import Spinner from '../../../components/spinner';
import NewPagination from '../../../components/newPagination';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { GridToolbar } from '@mui/x-data-grid';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import MobileResponsive from './mobileResponsive';

const years = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const UsersNew = (props) => {
    const users = useSelector((state) => state.users);
    const pinTab = useSelector((state) => state.pinTab);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({ field: 'username', value: '' });
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ field: 'name', sort: 'asc' });
    const columns = [
        { 
            field: 'username', 
            headerName: 'USERNAME', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'createdAt', 
            headerName: 'CREATED AT', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            filterable: false, 
            sortable: true 
        },
        { 
            field: 'name', 
            headerName: 'NAME', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'role', 
            headerName: 'ROLE', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'defaultLocation', 
            headerName: 'LOCATION', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'active',
            headerName: 'ACTIVE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: true,
            flex: 1,
            renderCell: (params) => (
                params.value ? (
                    <BsCheckCircleFill color="green" />
                ) : (
                    <BsXCircleFill color="red" />
                )
            )
        },
        {
            field: 'services',
            headerName: 'SERVICES',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: true,
            flex: 2,
            renderCell: (params) => (
                ['super-admin', 'admin'].includes(params?.row?.role) ? (
                    <div>
                        <Chip label={"All"} style={{ margin: '3px' }} />
                    </div>
                ) : (
                    Array.isArray(params?.value) ? (
                        <div>
                            {params?.value?.slice(0,2)?.map((service, index) => (
                                <Chip key={index} label={service?.service} style={{ margin: '3px' }} />
                            ))}
                            {params?.value?.length > 2 ? (
                                <Tooltip title={
                                    <div>
                                        {params?.value?.slice(2)?.map((service, index) => (
                                            <p>{service?.service}, </p>
                                        ))}
                                    </div>
                                } arrow>
                                <Chip size="small" label={`+ ${params?.value?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                                </Tooltip>
                            ) : null}
                        </div>
                    ) : (
                        <Chip label={params?.value} style={{ margin: '3px' }} />
                    )
                )
            )
        }
    ];

    if (document.querySelector('.MuiDataGrid-footerContainer')) {
        document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
    }

    const handleCreatedAt = (createdAt) => {
        const dn = new Date();
        let ca = createdAt
            ? createdAt.getFullYear() === dn.getFullYear()
                ? createdAt.getMonth() === dn.getMonth()
                    ? createdAt.getDate() === dn.getDate()
                        ? `Today at ${createdAt.toLocaleTimeString()}`
                        : createdAt.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
                            : years[createdAt.getMonth()] +
                            ' ' +
                            createdAt.getDate() +
                            ' at ' +
                            createdAt.toLocaleTimeString()
                    : years[createdAt.getMonth()] +
                    ' ' +
                    createdAt.getDate() +
                    ' at ' +
                    createdAt.toLocaleTimeString()
                : years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
            : null
        return ca;
    }

    const fetchUsers = (page) => {
        setLoading(true);
        setError(null);

        const params = {
            page,
            text: search ? search : '',
            filter: JSON.stringify(filter),
            sort,
        };
        
        axiosInstance
            .get('/admin/', {
                params,
            })
            .then((response) => {
                dispatch(setUsers(response.data.data));
            })
            .catch((error) => {
                setError(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Users')) {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            fetchUsers(page ? page : 1, search, filter);
        }
    }, [search, filter, sort,pinTab]);


    return (
        <Fragment>
            <div style={{ display:'flex',justifyContent:'flex-end',marginTop:-25 }}>
                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    startIcon={<Add />}
                    onClick={() => navigate('/users/new')}
                    style={{ marginLeft: '10px' }}
                >
                    Add New
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ManageAccountsIcon />}
                    onClick={() => navigate('/users/roles')}
                    style={{ marginLeft: '10px' }}
                    size='small'
                >
                    Roles
                </Button>
            </div>

            {error ? (
                <Alert variant="danger" onClose={() => setError(false)} dismissible>
                    <Alert.Heading>{error}</Alert.Heading>
                    <p>Try reloading!</p>
                </Alert>
            ) : null}

            <div className="d-none d-md-block" style={{ width: '100%',marginTop:10 }} >
                <Box
                    component={Paper}
                    className="shadow"
                    sx={{'& .super-app-theme--header':{ backgroundColor:'#243750',color:'#ffffff',cursor:"default" }}}
                >
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        filterMode="server"
                        filterColumns={['name', 'role', 'username']}
                        sortingMode="server"
                        paginationMode="server"
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        initialState={{
                            sorting: {sortModel: [{ field: 'name', sort: 'asc' }]},
                        }}
                        onSortModelChange={(sort) => setSort(sort[0])}
                        sx={{
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "none !important",
							},
                            boxShadow: 2,
                            borderRadius: 2,
                            cursor: "pointer"
                        }}
                        columns={columns}
                        rows={users?.users?.map((user) => ({
                            id: user?._id,
                            username: user?.username,
                            createdAt: handleCreatedAt(new Date(user?.createdAt)),
                            name: user?.name,
                            role: user?.role,
                            services: user?.services,
                            active: user?.active,
                            defaultLocation: user?.defaultLocation
                        }))}
                        onRowClick={(params) => {
                            navigate(`/users/${params.id}`);
                        }}
                        sortingOrder={['asc', 'desc']}
                        autoHeight
                        autoPageSize
                        onFilterModelChange={(filter) => (setFilter(filter.items[0]), setSearch(filter.quickFilterValues[0]))}
                    />
                </Box>

                {users?.users?.length > 0 ? (
                    <nav aria-label="Page navigation">
                        <NewPagination
                            totalPage={users}
                            fetchData={fetchUsers}
                        />
                    </nav>
                ) : null}

                {loading ? <Spinner /> : null}
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    endpoint={`/admin`} 
                    rowId='_id'
                    dataPosition={"users"}
                />
			</div>
        </Fragment>
    );
};

export default UsersNew;