import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../createOrders/css/Modal.module.css";
import { TextField } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';

const RiderUpdatePopup = (props) => {
    const [locationCodes, setLocationCodes] = useState([]);
    
    const {
        showPopup,
        hidePopup,
        reRender,
        setReRender,
        riderId,
        riderName,
        setRiderName,
        locationCode,
        setLocationCode
    } = props;

    useEffect(() => {
        const fetchLocationCodes = async () => {
            try {
                const response = await axiosInstance.get(`/location/locations?text=`)
                
                let locations = response?.data?.data;
                let opts = locations?.map((location) => {
                    return location?.locationCode
                })
                setLocationCodes([...opts])
            } catch (error) {
                const errorMsg = error?.response?.data?.message;
                console.error(`Error: ${errorMsg}`);
            }
        };
        fetchLocationCodes();
    }, []);

    const handleRiderUpdate = async (e) => {
        if (riderName === '' || riderName === undefined || riderName === null) {
            alert('Please enter rider name')
            return
        }
        if (locationCode === '' || locationCode === undefined || locationCode === null) {
            alert('Please enter location code')
            return
        }
        e.preventDefault()
        const riderData = {
            deliveryRider: riderName,
            locationCode: locationCode,
        }

        await axiosInstance
            .put(`/pgOrder/admin/updateDeliveryRider/${riderId}`, riderData)
            .then((res) => {
                setReRender(!reRender)
                hidePopup()
            })
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <Fragment>
            {showPopup && (
                <div className={Classes.modal} style={{ bottom: '35%', top: '26%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Update Delivery Rider</p>
                    </div>

                    <div style={{ padding: '30px 20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <TextField
                                required
                                id='name'
                                type="text"
                                label="Rider Name"
                                defaultValue={riderName}
                                placeholder='Enter Rider Name'
                                onChange={(e) => setRiderName(e.target.value)}
                                style={{ width: '48%' }}
                            />
                            <TextField
                                required
                                id='locationCode'
                                select
                                label="Location Code"
                                value={locationCode}
                                onChange={(e) => setLocationCode(e.target.value)}
                                SelectProps={{
                                    native: true,
                                }}
                                style={{ width: '48%' }}
                            >
                                {locationCodes.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                    </div>

                    <div className={Classes.FooterBox} style={{ gap: '7px',borderTop:0 }}>
                        <button
                            type="submit"
                            className={Classes.ApplyButton}
                            style={{ background: '#fff', color: 'black' }}
                            onClick={hidePopup}
                        >
                            Close
                        </button>
                        <button
                            id='btn'
                            className={Classes.ApplyButton}
                            style={{ background: 'green', color: '#fff' }}
                            onClick={(e) => handleRiderUpdate(e)}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default RiderUpdatePopup;