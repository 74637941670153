import { Fragment, useEffect, useState } from 'react';
import { BiArrowBack} from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/addProduct.module.css';
import axiosInstance from '../../../../utility/axios-instance';
import Aside from './aside/aside';
import GroupTitle from './GroupTitle';
import Media from './MediaFile';
import Pricing from './Pricing';
import Inventory from './Inventory';
import Variants from './Variants';
import AllPopUp from './AllPopUp';
import './css/specialImg.css';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../../../store/breadcrumbSlice';
import axios from 'axios';
import QRCode from 'qrcode';
import MainQrCode from './MainQrCode';
import { 
	handleGstRateFile, 
	checkGroupTitleFile, 
	handleDiscountFile,
	handleMRPFile 
} from './AllFunctions';
import ConfirmPopup from './deleteConfirmPopup';
import Permission from '../../../auth/permissions';
import { services } from '../../../../utility/checkRoleBasedPermission';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import IconButton from '@mui/material/IconButton';
import MultiPricing from '../addProducts/MultiPricing';

const ProductDetails = (props) => {
	const [error, setError] = useState(null);
	const [previousId,setPreviousId] = useState('');
	const [listDiscount,setListDiscount] = useState('');
	const [retailerDiscount,setRetailerDiscount] = useState('');
	const [interiorDiscount,setInteriorDiscount] = useState('');
	const [oemDiscount,setOemDiscount] = useState('');
	const [endUserDiscount,setEndUserDiscount] = useState('');
	const [groupImgUrl,setGroupImageUrl] = useState([]);
	const [groupImageUrlDimension2,setGroupImageUrlDimension2] = useState([]);
	const [groupImageUrlDimension3,setGroupImageUrlDimension3] = useState([]);
	const [showErrorMsg,setErrorMsg] = useState([]);
	const [showVinculumError,setVinculumError] = useState([]);
	const [storePrevGroupName,setPrevGroupName] = useState([]);
	const [productDetails,setProductDetails] = useState([]);
	const [detailsToggle,setDetailsToggle] = useState(true);
	const [tags,setTags] = useState([]);
	const [updateSku,setSku] = useState('');
	const [limitPrice,setLimitPrice] = useState('');
	const [mainImageUrl,setMainImageUrl] = useState('');
	const [imageUrl2,setImageUrl2] = useState('');
	const [imageUrl3,setImageUrl3] = useState('');
	const [imageUrl4,setImageUrl4] = useState('');
	const [imageUrl5,setImageUrl5] = useState('');
	const [imageUrl6,setImageUrl6] = useState('');
	const [imageUrl7,setImageUrl7] = useState('');
	const [mainImageUrl1Dimension2,setMainImageUrlDimension2] = useState('');
	const [imageUrl2Dimension2,setImageUrl2Dimension2] = useState('');
	const [imageUrl3Dimension2,setImageUrl3Dimension2] = useState('');
	const [imageUrl4Dimension2,setImageUrl4Dimension2] = useState('');
	const [imageUrl5Dimension2,setImageUrl5Dimension2] = useState('');
	const [imageUrl6Dimension2,setImageUrl6Dimension2] = useState('');
	const [imageUrl7Dimension2,setImageUrl7Dimension2] = useState('');
	const [mainImageUrl1Dimension3,setMainImageUrlDimension3] = useState('');
	const [imageUrl2Dimension3,setImageUrl2Dimension3] = useState('');
	const [imageUrl3Dimension3,setImageUrl3Dimension3] = useState('');
	const [imageUrl4Dimension3,setImageUrl4Dimension3] = useState('');
	const [imageUrl5Dimension3,setImageUrl5Dimension3] = useState('');
	const [imageUrl6Dimension3,setImageUrl6Dimension3] = useState('');
	const [imageUrl7Dimension3,setImageUrl7Dimension3] = useState('');
	const [unitType,setUnitType] = useState('');
	const [multiPrice,setMultiPrice] = useState([]);
	const [locations,setLocations] = useState([]);
	const [mrp, setMrp] = useState('');
	const [gst, setGst] = useState('');
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();


	const [QRCimageData, setQRCImageData] = useState('');
	const [qrSKU , setQRSKU] = useState("");
	const [Ids , setIds] = useState("")

	// aside
	const [vendor,setVendor] = useState('');
	const [vendorCode,setVendorCode] = useState(1);
	const [brand,setBrand] = useState('');
	const [brandAbr,setBrandAbr] = useState('')
	const [storeCollections,setCollectionStore] = useState([]);
	const [storeDiscountArr,setDiscountArr] = useState([]);
	const [storeSalesChannel,setSalesChannel] = useState([]);
	const [hsnToggle,setHsnToggle] = useState(true);
	const [hsnCodes,setHsnCodes] = useState([]);
	const navigate = useNavigate();

	const [showSelectedTags,setSelectedTags] = useState([]);

	// deleteProduct
	const [deleteProductId,setDeleteProductId] = useState('');
	const [confirmPopupOpen,setConfirmPopUpOpen] = useState(false);

	const { Id } = useParams();
    const breadCrumb = useSelector((state) => state.breadcrumb);

	const handleDeleteProduct = (event,Id) => {
		event.preventDefault();
		setConfirmPopUpOpen(true);
		setDeleteProductId(Id);
	}

	const hideConfirmPopup = () => {
		setDeleteProductId('');
		setConfirmPopUpOpen(false);
	}

	const handleUpdateProduct = (event,sku,shopifyId) => {
		event.preventDefault();

		event.target.style.backgroundColor = '#008060';
		event.target.style.color = '#fff';
		event.target.style.border = 0;
		event.target.innerHTML = 'Update Product...';

		const groupTitles = document.getElementById('groupTitle').value;
		const descriptions = document.getElementById('description').value;
		const option1Name = document.getElementById('option1Name').value;
		const option1Value = document.getElementById('option1Value').value;
		const options2Name = document.getElementById('option2Name').value;
		const options2Value = document.getElementById('option2Value').value;
		const options3Name = document.getElementById('option3Name').value;
		const options3Value = document.getElementById('option3Value').value;
		const mrp = document.getElementById('mrp').value;
		const listPrice = document.getElementById('listDiscount').value;
		const retailerPrices = document.getElementById('retailerDiscount').value;
		const interiorPrices = document.getElementById('interiorDiscount').value;
		const oemPrices = document.getElementById('oemDiscount').value;
		const endUsers = document.getElementById('endUser').value;
		const costPerItems = document.getElementById('costPerItem').value;
		const hsnCode = document.getElementById('hsnCode').value;
		const gstRate = document.getElementById('gstRate').value;
		const modelNumber = document.getElementById('modelNumber').value;
		const dsinCode = document.getElementById('dsinCode').value;
		const trackQty = document.getElementById('trackQty').checked;
		const continueSelling = document.getElementById('continueSelling').checked;
		const productTitle = document.getElementById('product_title').value;
		const unit = document.getElementById('unit').value;
		const materialTypes = document.getElementById('materialType');
		const finish = document.getElementById('finish');
		const includedComponent = document.getElementById('includedComponents');
		const loadCapacity = document.getElementById('loadCapacity');
		const weights = document.getElementById('weight');
		const sample = document.getElementById('sample');
		const availableOnRequest = document.getElementById('availableOnRequest').value;
		const warranty = document.getElementById('warranty');
		const marketedBys = document.getElementById('marketedBy');
		const deliveryTimes = document.getElementById('delivery_time');
		const customerCare = document.getElementById('customerCare');
		const country = document.getElementById('country');
		const size = document.getElementById('size').value;
		const isActive = document.getElementById('isActive').value;
		const bulkPackingType1 = document.getElementById('bulkType1').value;
		const bulkPackingType2 = document.getElementById('bulkType2').value;
		const bulkPackingValue1 = document.getElementById('bulkVal1').value;
		const bulkPackingValue2 = document.getElementById('bulkVal2').value;
		const availableQuantity = document.getElementById('availableQuantity').value;
		const optionNameArr = [];
		const optionValueArr = [];
		if(option1Value){
			optionNameArr.push((option1Name).trim())
			optionValueArr.push((option1Value).trim())	
		}

		if(options2Name) {
			optionNameArr.push(options2Name.trim());
		}

		if(options2Value) {
			optionValueArr.push(options2Value.trim());
		}

		if(options3Name){
			optionNameArr.push(options3Name.trim());
		}

		if(options3Value){
			optionValueArr.push(options3Value.trim());
		}
	
		const MergeTwoArr = optionValueArr.concat(storeCollections)
		const TagArr = [...showSelectedTags]
		TagArr.push(...MergeTwoArr,gstRate);

		const chargeTax = document.getElementById('chargeTax').checked;

		const data = {
			shopifyId: shopifyId,
			groupName : groupTitles,
			// vendor: brand,
			productName: productTitle,
			unit: unit,
			l1: storeCollections[0],
			l2: storeCollections[1],
			l3: storeCollections[2],
			collections: storeCollections,
			sku: dsinCode,
			brandName: brand,
			brandAbr: brandAbr,
			modelNo: modelNumber,
			hsnCode: hsnCode,
			mrp: parseFloat(mrp),
			gst: parseInt(gstRate),
			baseCost: costPerItems ? parseFloat(costPerItems) : 0,
			listingPrice: parseFloat(listPrice),
			listingDiscount: parseFloat(listDiscount),
			retailPrice: retailerPrices ? parseFloat(retailerPrices) : "",
			retailDiscount: retailerDiscount ? parseFloat(retailerDiscount) : "",
			interiorPrice: interiorPrices ? parseFloat(interiorPrices) : "",
			interiorDiscount: interiorPrices ? parseFloat(interiorDiscount) : "",
			variable1Type: option1Name.trim(),
			variable1Value: option1Value.trim(),
			variable2Type: options2Name ? options2Name.trim() : '',
			variable2Value: options2Value ? options2Value.trim() : '',
			variable3Type: options3Name ? options3Name.trim() : '',
			variable3Value: options3Value ? options3Value.trim() : '',
			productDescription: descriptions ? descriptions.trim() : '',
			countryOfOrigin: country && country.value ? country.value.trim() : '',
			customerCareAddress: customerCare.value ? customerCare.value.trim() : '',
			marketedBy: marketedBys.value ? marketedBys.value.trim() : '',
			includedComponents: includedComponent.value ? includedComponent.value.trim() : '' ,
			warranty: warranty.value ? warranty.value.trim() : '',
			availableQuantity: availableQuantity ? parseInt(availableQuantity) : '',
			weight: weights.value ? parseFloat(weights.value): '0',
			materialType: materialTypes && materialTypes.value ? materialTypes.value : '',
			size: size ? size.trim() : size,
			finish: finish && finish.value ? finish.value.trim() : '',
			loadCapacity: loadCapacity.value ? loadCapacity.value.trim() : '',
			shipmentTime: deliveryTimes.value ? parseInt(deliveryTimes.value) : '',
			mainImageUrl400x400: mainImageUrl ? mainImageUrl : '',
			imageUrl2400x400: imageUrl2 ? imageUrl2 : '',
			imageUrl3400x400: imageUrl3 ? imageUrl3 : '',
			imageUrl4400x400: imageUrl4 ? imageUrl4 : '',
			imageUrl5400x400: imageUrl5 ? imageUrl5 : '',
			imageUrl6400x400: imageUrl6 ? imageUrl6 : '',
			imageUrl7400x400: imageUrl7 ? imageUrl7 : '',
			mainImageUrl1200x1200: mainImageUrl1Dimension2 ? mainImageUrl1Dimension2 : '',
			imageUrl21200x1200: imageUrl2Dimension2 ? imageUrl2Dimension2 : '',
			imageUrl31200x1200: imageUrl3Dimension2 ? imageUrl3Dimension2 : '',
			imageUrl41200x1200: imageUrl4Dimension2 ? imageUrl4Dimension2 : '',
			imageUrl51200x1200: imageUrl5Dimension2 ? imageUrl5Dimension2 : '',
			imageUrl61200x1200: imageUrl6Dimension2 ? imageUrl6Dimension2 : '',
			imageUrl71200x1200: imageUrl7Dimension2 ? imageUrl7Dimension2 : '',
			mainImageUrl150x150: mainImageUrl1Dimension3 ? mainImageUrl1Dimension3 : '',
			imageUrl2150x150: imageUrl2Dimension3 ? imageUrl2Dimension3 : '',
			imageUrl3150x150: imageUrl3Dimension3 ? imageUrl3Dimension3 : '',
			imageUrl4150x150: imageUrl4Dimension3 ? imageUrl4Dimension3 : '',
			imageUrl5150x150: imageUrl5Dimension3 ? imageUrl5Dimension3 : '',
			imageUrl6150x150: imageUrl6Dimension3 ? imageUrl6Dimension3 : '',
			imageUrl7150x150: imageUrl7Dimension3 ? imageUrl7Dimension3 : '',
			vendorCode: vendorCode,
			vendorName: vendor,
			isSampleAvailable: sample.checked ? 'yes' : 'no',
			isTracked: trackQty,
			isTaxable: chargeTax,
			isCustomOrder: availableOnRequest,
			backOrder: continueSelling ? 'CONTINUE' : 'DENY',
			isActive: isActive === 'Active' ? true : false,
			salesChannel: storeSalesChannel,
			tags: TagArr,
			bulkPackingType1: bulkPackingType1,
			bulkPackingValue1: bulkPackingValue1,
			bulkPackingType2: bulkPackingType2,
			bulkPackingValue2: bulkPackingValue2,
			// shopfiy field
			optionsName: optionNameArr,
			optionsValue: optionValueArr,
			oemPrice: oemPrices ? parseFloat(oemPrices) : "",
			oemDiscount: oemPrices ? parseFloat(oemDiscount) : "",
			endUserPrice: endUsers ? parseFloat(endUsers) : "",
			endUserDiscount: endUsers ? parseFloat(endUserDiscount) : "",
			updateSku: sku,
			existSku: updateSku,
			multiPriceData: multiPrice,
		}

		console.log(data)

		axiosInstance
			.post(`/product/update/${Id}`,{
				...data
			})
			.then((res) => {

				if (res.data.status === 'success') {
					event.target.innerHTML = 'Updated Product';
					event.target.disabled = true;
					navigate('/productsCreated');
				} else if (res.data.status === 'Server Busy') {
					alert('Server Busy.. Try Again Later');
					event.target.innerHTML = 'Update Product';
				} else {
					event.target.innerHTML = 'Update Product';
				}

				event.target.style.backgroundColor = 'transparent';
				event.target.style.color = 'black';
				event.target.style.border = '1px solid black'
			})
	}


	document.addEventListener('mouseup', function(e) {
        let container = document.getElementById(previousId);
        if(container !== null){
            if (!container.contains(e.target)) {
                document.getElementById(previousId).style.display= 'none';
            }
        }
    });


	const handleDiscount = (event,Id) => {
		handleDiscountFile(
			event,
			Id, 
			storeDiscountArr, 
			setListDiscount, 
			setDiscountArr, 
			setRetailerDiscount, 
			setInteriorDiscount,
			setOemDiscount,
			setEndUserDiscount,
			setLimitPrice
		)	
	}

	const handleGstRate = (event) => {
		handleGstRateFile(
			event, 
			setDiscountArr,
			setListDiscount, 
			setRetailerDiscount, 
			setInteriorDiscount,
			setOemDiscount,
			setEndUserDiscount,
			setLimitPrice
		);
		setGst(document.getElementById('gstRate').value)
	}

	const handleMRP = () => {
		handleMRPFile(
			setDiscountArr,
			setListDiscount, 
			setRetailerDiscount, 
			setInteriorDiscount,
			setOemDiscount,
			setEndUserDiscount,
			setLimitPrice
		)
		setMrp(document.getElementById('mrp').value);
	}

	const checkGroupTitle = (title) => {
		checkGroupTitleFile(
			title, 
			setPrevGroupName,
		);
	}

	const handleRemove = (index) => () => {
		setMultiPrice(multiPrice.filter((s, sindex) => index !== sindex));
	}
	const addMultiPricing = (e) => {
        setMultiPrice([...multiPrice, { locationCode: '', listingPrice: '', retailerPrice: '', interiorPrice: '', oemPrice: '', endUserPrice: '', listingDiscount: '', retailerDiscount: '', interiorDiscount: '', oemDiscount: '', endUserDiscount: '' }])
        console.log(multiPrice)
    }

	const  isImgUrl = async (url) => {
		const img = new Image();
		img.src = url;
		return new Promise((resolve) => {
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
		});
	}

	
	const handleImg = async (event) => {
		if(event.target.value){
			const res = await isImgUrl(event.target.value);
			if (!event.target.checkValidity()) {
				alert("Please enter valid Image URL");
				return
			} else {
				if (res) {
					if (groupImgUrl.length === 0) {
						setGroupImageUrl([event.target.value]);
						setMainImageUrl(event.target.value);
					} else {
						if (groupImgUrl.length === 1) {
							setImageUrl2(event.target.value);
							setGroupImageUrl([...groupImgUrl,event.target.value]);
						}
		
						if (groupImgUrl.length === 2) {
							setImageUrl3(event.target.value);
							setGroupImageUrl([...groupImgUrl,event.target.value]);
						}
		
						if (groupImgUrl.length === 3) {
							setImageUrl4(event.target.value);
							setGroupImageUrl([...groupImgUrl,event.target.value]);
						}
		
						if (groupImgUrl.length === 4) {
							setImageUrl5(event.target.value);
							setGroupImageUrl([...groupImgUrl,event.target.value]);
						}
		
						if (groupImgUrl.length === 5) {
							setImageUrl6(event.target.value);
							setGroupImageUrl([...groupImgUrl,event.target.value]);
						}
		
						if (groupImgUrl.length === 6) {
							setImageUrl7(event.target.value);
							setGroupImageUrl([...groupImgUrl,event.target.value]);
							document.getElementById('img').style.display = 'none';
						}
					}
					event.target.value = '';		
				} else {
					alert("Please enter valid Image URL");
					return;
				}
			}
		}
	}

	const handleImageDimension = async (event) => {
		if(event.target.value){
			const res = await isImgUrl(event.target.value);
			if (!event.target.checkValidity()) {
				alert("Please enter valid Image URL");
				return
			} else {
				if (res) {
					if (groupImageUrlDimension2.length === 0) {
						setGroupImageUrlDimension2([event.target.value]);
						setMainImageUrlDimension2(event.target.value);
					} else {
						if (groupImageUrlDimension2.length === 1) {
							setImageUrl2Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2,event.target.value]);
						}
		
						if (groupImageUrlDimension2.length === 2) {
							setImageUrl3Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2,event.target.value]);
						}
		
						if (groupImageUrlDimension2.length === 3) {
							setImageUrl4Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2,event.target.value]);
						}
		
						if (groupImageUrlDimension2.length === 4) {
							setImageUrl5Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2,event.target.value]);
						}
		
						if (groupImageUrlDimension2.length === 5) {
							setImageUrl6Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2,event.target.value]);
						}
		
						if (groupImageUrlDimension2.length === 6) {
							setImageUrl7Dimension2(event.target.value);
							setGroupImageUrlDimension2([...groupImageUrlDimension2,event.target.value]);
							document.getElementById('dimension2').style.display = 'none';
						}
					}
					event.target.value = '';
				} else {
					alert("Please enter valid Image URL");
					return;
				}
			}
		}
	} 

	const handleImageDimension2 = async (event) => {
		if(event.target.value){
			const res = await isImgUrl(event.target.value);
			if (!event.target.checkValidity()) {
				alert("Please enter valid Image URL");
				return
			} else {
				if (res) {
					if (groupImageUrlDimension3.length === 0) {
						setGroupImageUrlDimension3([event.target.value]);
						setMainImageUrlDimension3(event.target.value);
					} else {
						if (groupImageUrlDimension3.length === 1) {
							setImageUrl2Dimension3(event.target.value);
							setGroupImageUrlDimension3([...groupImageUrlDimension3,event.target.value]);
						}
		
						if (groupImageUrlDimension3.length === 2) {
							setImageUrl3Dimension3(event.target.value);
							setGroupImageUrlDimension3([...groupImageUrlDimension3,event.target.value]);
						}
		
						if (groupImageUrlDimension3.length === 3) {
							setImageUrl4Dimension3(event.target.value);
							setGroupImageUrlDimension3([...groupImageUrlDimension3,event.target.value]);
						}
		
						if (groupImageUrlDimension3.length === 4) {
							setImageUrl5Dimension3(event.target.value);
							setGroupImageUrlDimension3([...groupImageUrlDimension3,event.target.value]);
						}
		
						if (groupImageUrlDimension3.length === 5) {
							setImageUrl6Dimension3(event.target.value);
							setGroupImageUrlDimension3([...groupImageUrlDimension3,event.target.value]);
						}
		
						if (groupImageUrlDimension3.length === 6) {
							setImageUrl7Dimension3(event.target.value);
							setGroupImageUrlDimension3([...groupImageUrlDimension3,event.target.value]);
							document.getElementById('dimension3').style.display = 'none';
						}
					}
					event.target.value = '';
				} else {
					alert("Please enter valid Image URL");
					return;
				}
			}
		}
	}

	const handleGroupTitle = (event) => {
		if(event.target.value){
			document.getElementById('platforms').style.display = 'none';
			if(storePrevGroupName.length !== 0){
				if(storePrevGroupName[0] !== event.target.value){
					document.getElementById('GroupNameBox').style.display = 'none';
					checkGroupTitle(event.target.value);
				}else{
					document.getElementById('GroupNameBox').style.display = 'block';
				}
			}else{
				checkGroupTitle(event.target.value)
			}
		}
		document.getElementById('platforms').style.display = 'none';
	}

	const handleError = (event) => {
		const fetchPlatformId = document.getElementById(event.target.id);
		const attrVal = fetchPlatformId.getAttribute('data-toggle');
		const showError = fetchPlatformId.parentNode.parentNode.parentElement.children[1];
		if(attrVal === 'false'){
			showError.style.display = 'flex';
			fetchPlatformId.setAttribute('data-toggle',true);
		}else{
			showError.style.display = 'none';
			fetchPlatformId.setAttribute('data-toggle',false);
		}
	} 

	const handlePrevButton = (event) => {
		document.getElementById('GroupNameBox').style.display = 'none';
		document.getElementById('url').style.display = 'none';
		if(document.getElementById('showImages').style !== 'block'){
			document.getElementById('newImageBox').style.display = 'block';
			document.getElementById('showImages').style.display = 'block';
		}
		document.getElementById('url').style.display = 'none';
		document.getElementById('product').style.display = 'block';
	}

	const handleChangeTitle = (event) => {
		document.getElementById(event.target.id).style.display = 'none';
		document.getElementById('changeTitle').style.display = 'flex';
	}

	const handleChangeTitleName = (event) => {
		const TitleName = document.getElementById('titleName').value;
		if(TitleName){
			setPrevGroupName([])
			document.getElementById('changeTitle').style.display = 'none';
			document.getElementById('GroupNameBox').style.display = 'none';
			document.getElementById('groupTitle').value = TitleName;
		}
	}

	const handleVinculum = () => {
		
		setUnitType(document.getElementById('unit').value ? document.getElementById('unit').value : '');

		// if (
		// 	document.getElementById('listDiscount').value &&
		// 	document.getElementById('hsnCode') &&
		// 	document.getElementById('hsnCode').value &&
		// 	document.getElementById('vendors').value && 
		// 	document.getElementById('collection3').value
		// ) {
		// 	document.getElementById('vinculum').checked = true;
		// }
	}

	const handleDatabase = () => {
	// 	if (
	// 		document.getElementById('groupTitle').value &&
	// 		document.getElementById('mrp').value &&
	// 		document.getElementById('listDiscount').value &&
	// 		document.getElementById('gstRate').value &&
	// 		document.getElementById('dsinCode').value &&
	// 		document.getElementById('product_title').value &&
	// 		document.getElementById('option1Name').value &&
	// 		document.getElementById('option1Value').value
	// 	) {
	// 		document.getElementById('database').checked = true;
	// 	}

	// 	// if(
	// 	// 	document.getElementById('hsnCode').value &&
	// 	// 	document.getElementById('vendors').value &&
	// 	// 	document.getElementById('collection3').value
	// 	// ) {
	// 	// 	document.getElementById('vinculum').checked = true;	
	// 	// }

		handleMRP();
	}

	const handleHsnCode = (event) => {
		const hsnCodePercentage = event.target.options[event.target.selectedIndex].dataset.percentage;
		document.getElementById('gstRate').value = `${hsnCodePercentage}.0`;
		handleMRP();
		handleVinculum();
	}
	// generateQrc
	const getShopifyHandle = async (shopifyProductId)=>{
		
		try{
			var data = await JSON.stringify({ 
					query : `{
						product(id: "${shopifyProductId}") {
							handle
						}
					}`
				});
			
			var config = {
				method: 'post',
				url: process.env.REACT_APP_storefront_graphql_api_base_url,
				headers: {
					'X-Shopify-Storefront-Access-Token':
						process.env.REACT_APP_X_Shopify_Storefront_Access_Token,
					'Content-Type': 'application/json',
				},
				data: data,
				};

		
			let response =  await axios(config);
			let handle = response.data.data.product.handle;
			let url = `/others/generate-qrc/${handle}`
			
			let qrcode =await axiosInstance.post(url);
			setQRCImageData(qrcode.data);
		}
		catch(error) {
				console.log(error);
		};
	}

	const getApkQRCode = async (Id) =>{
			
			var opts = {
				errorCorrectionLevel: 'M',
				quality: 0.3,
				margin: 1,
			  }
			const URL = `https://depo24.page.link/?link=https://depo24.in/products/${Id}&apn=in.depo24.depo_24&ibi=in.depo24.depo24`;
			const qrCode = await QRCode.toDataURL(URL,opts)
			setQRCImageData(qrCode);
	}

	const handlePrint = async (e) => {
		e.preventDefault();
		const printPage = window.open("","_blank","")
		printPage.document.write(`
			<html>
				<head><title>Print Window</title></head>
				<body>
					<img alt="qrc" src=${QRCimageData} width="66" height="66" onload="window.print();window.close()"/>
					<p style="margin:2px 1px auto;font-size:10px;line-height:10px">${qrSKU}</p>
				</body>
			</html>
		`)
	}

	const handleDownload = (e)=>{
		e.preventDefault();
		const anchor = document.createElement('a');
		anchor.href = QRCimageData;
		anchor.download = 'QrCode.png';
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	}

	useEffect(() => {
        axiosInstance.get(`/location/locations?text=`)
            .then(res => {
                const locations = [];
                for (let location of res?.data?.data) {
                    locations.push(location?.locationCode)
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

	useEffect(() => {
		if(Id){
			if(detailsToggle){
				getApkQRCode(Id);
				setIds(Id)
				axiosInstance
					.get(`/product/${Id}`)
					.then((res) => {
						if(res.data.data.length !== 0){
							setSku(res.data.data.shopifyId);
							//QRcode of website product 
							// getShopifyHandle(res.data.data.shopifyProductId);
							setQRSKU(res.data.data.sku);
							dispatch(setBreadcrumb({...breadCrumb, product: res.data.data.sku}));
							// if (res.data.data.groupName) {
							// 	if(document.getElementById('groupTitle') !== null) {
							// 		document.getElementById('groupTitle').disabled = true;
							// 	}
							// }

							if (res.data.data.isTaxable) {
								document.getElementById('chargeTax').checked = true;
							} else {
								document.getElementById('chargeTax').checked = false;
							}

							if (res.data.data.isTracked) {
								document.getElementById('trackQty').checked = true;
							} else {
								document.getElementById('trackQty').checked = false;
							}

							if (res.data.data.backOrder === 'CONTINUE') {
								document.getElementById('continueSelling').checked = true;
							} else {
								document.getElementById('continueSelling').checked = false;
							}

							const ImageArray = [];
							const ImageArrayDimension2 = [];
							const ImageArrayDimension3 = [];
							if (res?.data?.data["400x400"]?.mainImageUrl) {
								ImageArray.push(res?.data?.data["400x400"]?.mainImageUrl);
								setMainImageUrl(res?.data?.data["400x400"]?.mainImageUrl);
							}

							if (res?.data?.data["1200x1200"]?.mainImageUrl) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.mainImageUrl);
								setMainImageUrlDimension2(res?.data?.data["1200x1200"]?.mainImageUrl);
							}

							if (res?.data?.data["150x150"]?.mainImageUrl) {
								ImageArrayDimension3.push(res?.data?.data["150x150"]?.mainImageUrl);
								setMainImageUrlDimension3(res?.data?.data["150x150"]?.mainImageUrl);
							}

							if (res?.data?.data["400x400"]?.imageUrl2) {
								ImageArray.push(res?.data?.data["400x400"]?.imageUrl2);
								setImageUrl2(res?.data?.data["400x400"]?.imageUrl2);
							}

							if (res?.data?.data["1200x1200"]?.imageUrl2) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.imageUrl2);
								setImageUrl2Dimension2(res?.data?.data["1200x1200"]?.imageUrl2);
							}

							if (res?.data?.data["150x150"]?.imageUrl2) {
								ImageArrayDimension3.push(res?.data?.data["150x150"]?.imageUrl2);
								setImageUrl2Dimension3(res?.data?.data["150x150"]?.imageUrl2);
							}

							if (res?.data?.data["400x400"]?.imageUrl3) {
								ImageArray.push(res?.data?.data["400x400"]?.imageUrl3);
								setImageUrl3(res?.data?.data["400x400"]?.imageUrl3);
							}

							if (res?.data?.data["1200x1200"]?.imageUrl3) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.imageUrl3);
								setImageUrl3Dimension2(res?.data?.data["1200x1200"]?.imageUrl3);
							}

							if (res?.data?.data["150x150"]?.imageUrl3) {
								ImageArrayDimension3.push(res?.data?.data["150x150"]?.imageUrl3);
								setImageUrl3Dimension3(res?.data?.data["150x150"]?.imageUrl3);
							}

							if (res?.data?.data["400x400"]?.imageUrl4) {
								ImageArray.push(res?.data?.data["400x400"]?.imageUrl4);
								setImageUrl4(res?.data?.data["400x400"]?.imageUrl4);
							}

							if (res?.data?.data["1200x1200"]?.imageUrl4) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.imageUrl4);
								setImageUrl4Dimension2(res?.data?.data["1200x1200"]?.imageUrl4);
							}

							if (res?.data?.data["150x150"]?.imageUrl4) {
								ImageArrayDimension3.push(res?.data?.data["150x150"].imageUrl4);
								setImageUrl4Dimension3(res?.data?.data["150x150"]?.imageUrl4);
							}

							if (res?.data?.data["400x400"]?.imageUrl5) {
								ImageArray.push(res?.data?.data["400x400"]?.imageUrl5);
								setImageUrl5(res?.data?.data["400x400"]?.imageUrl5);
							}

							if (res?.data?.data["1200x1200"]?.imageUrl5) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.imageUrl5);
								setImageUrl5Dimension2(res?.data?.data["1200x1200"]?.imageUrl5);
							}

							if (res?.data?.data["150x150"]?.imageUrl5) {
								ImageArrayDimension3.push(res?.data?.data["150x150"]?.imageUrl5);
								setImageUrl5Dimension3(res?.data?.data["150x150"]?.imageUrl5);
							}

							if (res?.data?.data["400x400"]?.imageUrl6) {
								ImageArray.push(res?.data?.data["400x400"]?.imageUrl6);
								setImageUrl6(res?.data?.data["400x400"]?.imageUrl6);
							}

							if (res?.data?.data["1200x1200"]?.imageUrl6) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.imageUrl6);
								setImageUrl6Dimension2(res?.data?.data["1200x1200"]?.imageUrl6);
							}

							if (res?.data?.data["150x150"]?.imageUrl6) {
								ImageArrayDimension3.push(res?.data?.data["150x150"]?.imageUrl6);
								setImageUrl6Dimension3(res?.data?.data["150x150"]?.imageUrl6);
							}

							if (res?.data?.data["400x400"]?.imageUrl7) {
								ImageArray.push(res?.data?.data["400x400"]?.imageUrl7);
								setImageUrl7(res?.data?.data["400x400"]?.imageUrl7);
							}

							if (res?.data?.data["1200x1200"]?.imageUrl7) {
								ImageArrayDimension2.push(res?.data?.data["1200x1200"]?.imageUrl7);
								setImageUrl7Dimension2(res?.data?.data["1200x1200"]?.imageUrl7);
							}

							if (res?.data?.data["150x150"]?.imageUrl7) {
								ImageArrayDimension3.push(res?.data?.data["150x150"]?.imageUrl7);
								setImageUrl7Dimension3(res?.data?.data["150x150"]?.imageUrl7);
							}
					

							setGroupImageUrlDimension3(ImageArrayDimension3);
							setGroupImageUrlDimension2(ImageArrayDimension2);
							setGroupImageUrl(ImageArray);
						
							if (ImageArray.length === 7) {
								const ImageUrl = document.getElementById('dimension1');
								if(ImageUrl !== null) {
									ImageUrl.style.display = 'none';
								}
							}

							if (ImageArrayDimension2.length === 7) {
								const ImageUrl = document.getElementById('dimension2');
								if(ImageUrl !== null) {
									ImageUrl.style.display = 'none';
								}
							}

							if (res.data.data.l3) {
								document.getElementById('collection3').value = res.data.data.l3;
							}

							setMultiPrice(res?.data?.data?.multiPriceData);

							setTags(res.data.data.tags);
							// setSelectedTags(res.data.data.tags);
							setSalesChannel(res.data.data.salesChannel);
							if (res.data.data.salesChannel) {
								if (res.data.data.salesChannel.length !== 0) {
									for (let channel of res.data.data.salesChannel) {
										if (channel === 'Website') {
											const website = document.getElementById('website');
											if(website !== null){
												website.checked = true;
											}
										}else if(channel === 'Portal'){
											const portal = document.getElementById('portal');
											if(portal !== null){
												portal.checked = true;
											}
										}else if(channel === 'App'){
											const app = document.getElementById('app');
											if(app !== null){
												app.checked = true;
											}
										}
									}
								}
							} 

							const isActive = document.getElementById('isActive');
							if (res.data.data.isActive){
								isActive.value = 'Active';
							} else {
								isActive.value = 'Draft';
							}

							const array = [];
							if (res.data.data.listingDiscount) {
								array.push({'listDiscount': res.data.data.listingDiscount});
								setListDiscount(res.data.data.listingDiscount);
							}

							if (res.data.data.retailDiscount) {
								setRetailerDiscount(res.data.data.retailDiscount);
								array.push({'retailerDiscount': res.data.data.retailDiscount});
							}

							if (res.data.data.interiorDiscount) {
								setInteriorDiscount(res.data.data.interiorDiscount);
								array.push({'interiorDiscount': res.data.data.interiorDiscount});
							}

							if (res.data.data.oemDiscount) {
								setOemDiscount(res.data.data.oemDiscount);
								array.push({'oemDiscount': res.data.data.oemDiscount});
							}

							if (res.data.data.oemDiscount) {
								setEndUserDiscount(res.data.data.endUserDiscount);
								array.push({'endUser': res.data.data.endUserDiscount});
							}
							setDiscountArr(array);
							setProductDetails([res.data.data]);

							if (res.data.data.brandName) {
								setBrand(res.data.data.brandName);
								setBrandAbr(res.data.data.brandAbr);
							}

							if (res.data.data.vendorName) {
								setVendor(res.data.data.vendorName);
								setVendorCode(res.data.data.vendorCode);
							}

							if (res.data.data.l3) {
								const array = [
									res.data.data.l1,
									res.data.data.l2,
									res.data.data.l3
								]
								setCollectionStore(array);
							}
							
							setListDiscount(res.data.data.listingDiscount);

							if (res.data.data.retailDiscount) {
								setRetailerDiscount(res.data.data.retailDiscount);
							}
							
							if (res.data.data.interiorDiscount) {
								setInteriorDiscount(res.data.data.interiorDiscount);
							}

							if (res.data.data.oemDiscount) {
								setOemDiscount(res.data.data.oemDiscount);
							}
							
							if (res.data.data.endUserDiscount) {
								setEndUserDiscount(res.data.data.endUserDiscount);
							}

							setTimeout(() => {
								handleDatabase();
								// handleShopify();
								handleVinculum();
							},1500)
							setDetailsToggle(false);
						}
						setDetailsToggle(false);
					})
			}
		}
	},[detailsToggle,Id]);

	useEffect(() => {
		if(hsnToggle){
			axiosInstance
				.get('/others/hsnCodes')
				.then((res) => {
					setHsnCodes(res.data.data);
					setHsnToggle(false);
				})
		}
	},[hsnToggle]);

	return (
		<Fragment>
			<div className={Classes.container} id="color">
				<form 
					method='post' 
					encType="multipart/form-data" 
					id='formData'
				>
					<div 
						className={Classes.AddProductHeader} 
						id='header' style={{flexWrap:'wrap'}}
					>
						<div className={Classes.AddProductHeaderContent}>
							<div>
								<button
									type="button"
									className="btn btn-light"
									onClick={() => {
										navigate(-1);
									}}
								>
									<BiArrowBack />
								</button>
							</div>

							<div className="mb-0">
								<strong className={Classes.AddProductBtn}>
									{productDetails?.length !== 0 ? (
										<p>
											{productDetails[0]?.groupName ? 
											productDetails[0]?.groupName :  
											productDetails[0]?.sku}
										</p>
									):(
										<p>Product</p>
									)}	
								</strong>
							</div>
						</div>

						{productDetails?.length !== 0 && (
							<div style={{ display: 'flex' }}>
								<Permission service={services?.products} permission="update">
									<div>
										<button
											style={{ marginRight: 10 }}
											className={Classes.UpdateBtn}
											onClick={
												(e) =>
													handleUpdateProduct(
														e,
														productDetails[0]?.sku !== 'undefined' &&
														productDetails[0]?.sku,
														productDetails[0]?.shopifyVariantId !== 'undefined' &&
														productDetails[0]?.shopifyVariantId,
													)}
										>
											Update Product
										</button>
									</div>
								</Permission>
								<Permission service={services?.products} permission="delete">
									<div>
										<button
											id='btn'
											style={{ marginRight: 10 }}
											className={Classes.DeleteBtn}
											onClick={(e) => handleDeleteProduct(e, productDetails[0]?._id)}
										>
											Delete Product
										</button>
									</div>
								</Permission>
							</div>
						)}
					</div>

					{error ? (
						<Alert variant="danger" onClose={() => setError(false)} dismissible>
							<Alert.Heading>{error}</Alert.Heading>
							<p>Try reloading!</p>
						</Alert>
					) : null}

					<AllPopUp 
						showErrorMsg={showErrorMsg}
						storePrevGroupName={storePrevGroupName}
						handlePrevButton={handlePrevButton}
						handleChangeTitleName={handleChangeTitleName}
						handleChangeTitle={handleChangeTitle}
						handleError={handleError}
						showVinculumError={showVinculumError}
					/>

					<div className={Classes.AddProductContainer}>
						<div className={Classes.containBoxes} >
							<div className={Classes.mainContainer}>
								<Pricing 
									showErrorMsg={showErrorMsg}
									handleDiscount={handleDiscount}
									storeDiscountArr={storeDiscountArr}
									productDetails={productDetails}
									limitPrice={limitPrice}
									handleMRP={handleMRP}
									handleDatabase={handleDatabase}
									hsnCodes={hsnCodes}
									handleGstRate={handleGstRate}
									handleVinculum={handleVinculum}
									handleHsnCode={handleHsnCode}
									unitType={unitType}
									handleGroupTitle={handleGroupTitle}
								/>
								<div>
									<div className={Classes.Pricing} style={{ display: 'flex', width:'100%', justifyContent: 'space-between', padding: '5px 12px' }}>
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: 500 }}>Location Wise Pricing</div>
										{mrp !== '' && (
											<IconButton onClick={(e) => addMultiPricing(e)}><ControlPointIcon /></IconButton>
										)}
									</div>
								</div>
								{multiPrice && multiPrice.map((item, index) => (
									<MultiPricing multiPriceArr={multiPrice} mrp={mrp} gst={gst} details={item} removeSection={handleRemove(index)} locations={locations} />
								))}

								<Variants
									showErrorMsg={showErrorMsg}
									productDetails={productDetails}
									handleDatabase={handleDatabase}

								/>

								<Inventory 
									showErrorMsg={showErrorMsg}
									hsnCodes={hsnCodes}
									handleGstRate={handleGstRate}
									productDetails={productDetails}
									handleDatabase={handleDatabase}
									handleVinculum={handleVinculum}
									handleHsnCode={handleHsnCode}
								/>

								<GroupTitle 
									showErrorMsg={showErrorMsg}
									productDetails={productDetails}
								/>

								<Media 
									showErrorMsg={showErrorMsg}
									handleImg={handleImg}
									handleImageDimension={handleImageDimension}
									productDetails={productDetails}
									groupImageUrl={groupImgUrl}
									setGroupImageUrl={setGroupImageUrl}
									setMainImageUrl={setMainImageUrl}
									setImageUrl2={setImageUrl2}
									setImageUrl3={setImageUrl3}
									setImageUrl4={setImageUrl4}
									setImageUrl5={setImageUrl5}
									setImageUrl6={setImageUrl6}
									setImageUrl7={setImageUrl7}
									groupImageUrlDimension2={groupImageUrlDimension2}
									setGroupImageUrlDimension2={setGroupImageUrlDimension2}
									setMainImageUrlDimension2={setMainImageUrlDimension2}
									setImageUrl2Dimension2={setImageUrl2Dimension2}
									setImageUrl3Dimension2={setImageUrl3Dimension2}
									setImageUrl4Dimension2={setImageUrl4Dimension2}
									setImageUrl5Dimension2={setImageUrl5Dimension2}
									setImageUrl6Dimension2={setImageUrl6Dimension2}
									setImageUrl7Dimension2={setImageUrl7Dimension2}
									groupImageUrlDimension3={groupImageUrlDimension3}
									setGroupImageUrlDimension3={setGroupImageUrlDimension3}
									setMainImageUrlDimension3={setMainImageUrlDimension3}
									setImageUrl2Dimension3={setImageUrl2Dimension3}
									setImageUrl3Dimension3={setImageUrl3Dimension3}
									setImageUrl4Dimension3={setImageUrl4Dimension3}
									setImageUrl5Dimension3={setImageUrl5Dimension3}
									setImageUrl6Dimension3={setImageUrl6Dimension3}
									setImageUrl7Dimension3={setImageUrl7Dimension3}
									handleImageDimension2={handleImageDimension2}
								/>
							</div>
						</div>

						<div className={Classes.asideContainer}>
							<Aside
								showSelectedTags={showSelectedTags}
								setSelectedTag={setSelectedTags}
								vendor={vendor}
								setVendor={setVendor}
								setVendorCode={setVendorCode}
								brand={brand}
								setBrand={setBrand}
								setBrandAbr={setBrandAbr}
								storeCollections={storeCollections}
								setCollectionStore={setCollectionStore}
								storeSalesChannel={storeSalesChannel}
								setSalesChannel={setSalesChannel}
								productDetails={productDetails}
								tags={tags}
								setTags={setTags}
								handleVinculum={handleVinculum}
							/>

							<MainQrCode Id = {Ids} qrSKU = {qrSKU}/>
						</div>
					</div>
				</form>
			</div>

			<div className={confirmPopupOpen ? Classes.UpdateSalesDialog : undefined}>
				<ConfirmPopup 
					showConfirmPopup={confirmPopupOpen}
					hideConfirmPopup={hideConfirmPopup}
					productId={deleteProductId}
				/>
			</div>
		</Fragment>
	);
};

export default ProductDetails;
