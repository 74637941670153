import { React, useState } from 'react'
import Classes from '../css/create.module.css';
import ContactModal from './newContact';
import UpdateContactModal from './updateVendorContact';


const ContactDetails = ({ contacts, setContacts, selectedContact, setSelectedContact, type, details }) => {
    const [showVendorModal, setVendorModal] = useState(false);
    const [showLocationModal, setLocationModal] = useState(false);
    const [updateVendorContactDialog,setVendorContactDetails] = useState(false);
    const [num, setNum] = useState('');

    const getVendorUpdateModal = () => {
        setVendorContactDetails(true);
    };

    const hideVendorUpdateModal = () => {
        setNum('');
        setVendorContactDetails(false);
    };

    const getModal = () => {
        setVendorModal(true);
    };

    const hideModal = () => {
        setNum('');
        setVendorModal(false);
    };
    const getModalLoc = () => {
        setLocationModal(true);
    };

    const hideModalLoc = () => {
        setNum('');
        setLocationModal(false);
    };

    const handleContactSelection = (event) => {
        const contactId = event.target.value;
        if (type === "Vendor") {
            const selected = contacts.find((contact) => contact.vendorContactDetailId === Number(contactId));
            setSelectedContact(selected);
        } else if (type === "Location") {
            const selected = contacts.find((contact) => contact.locationContactDetailId === Number(contactId));
            setSelectedContact(selected);

        }
    };

    return (
        <div className="">
            <div className="row">
                <div className="">
                    {type === "Vendor" && (<div className="form-group">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className={Classes.contactInformation}>VENDOR CONTACT</p>
                            <p 
                                className={['removeEdit',Classes.contactEditBtn].join(' ')}
                                onClick={() => getModal()}
                            >Create Contact</p>
                        </div>
                        <select className="form-control" value={selectedContact?.vendorContactDetailId} onChange={handleContactSelection}>
                            <option value="">--Select Contact--</option>
                            {contacts?.map((contact) => (
                                <option key={contact.vendorContactDetailId} value={contact.vendorContactDetailId}>
                                    {contact.firstName} {contact.lastName}
                                </option>
                            ))}
                        </select>
                    </div>)}
                    {type === "Location" && (<div className="form-group">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p
                                className={Classes.contactInformation}
                            >LOCATION CONTACT</p>
                            <p className={[
                                'removeEdit',
                                Classes.contactEditBtn
                            ].join(' ')
                            }
                                onClick={() => getModalLoc()}
                            >Create Contact</p>
                        </div>
                        <select className="form-control" value={selectedContact?.locationContactDetailId} onChange={handleContactSelection}>
                            <option value="">--Select Contact--</option>
                            {contacts.map((contact) => (
                                <option key={contact.locationContactDetailId} value={contact.locationContactDetailId}>
                                    {contact.firstName} {contact.lastName}
                                </option>
                            ))}
                        </select>
                    </div>)}
                </div>
            </div>

            {type === "Vendor" && (
                <>
                    <div className={showVendorModal ? Classes.UpdateSalesDialog : undefined}>
                        <ContactModal
                            onShowModal={showVendorModal}
                            setModal={setVendorModal}
                            onHideModal={hideModal}
                            contacts={contacts}
                            setContacts={setContacts}
                            num={num}
                            setNum={setNum}
                            details={details}
                            type={type}
                            selectedContact={selectedContact}
                            setSelectedContact={setSelectedContact}
                        />
                    </div>

                    <div className={updateVendorContactDialog ? Classes.UpdateSalesDialog : undefined}>
                        <UpdateContactModal 
                            onShowModal={updateVendorContactDialog}
                            setModal={setVendorModal}
                            onHideModal={hideVendorUpdateModal}
                            contacts={contacts}
                            setContacts={setContacts}
                            num={selectedContact?.phoneNumber}
                            setNum={setNum}
                            details={details}
                            type={type}
                            selectedContact={selectedContact}
                            setSelectedContact={setSelectedContact}
                        />
                    </div>
                </>
            )}

            {type === "Location" && (
                <>
                    <div className={showLocationModal ? Classes.UpdateSalesDialog : undefined}>
                        <ContactModal
                            onShowModal={showLocationModal}
                            setModal={setLocationModal}
                            onHideModal={hideModalLoc}
                            contacts={contacts}
                            setContacts={setContacts}
                            num={num}
                            setNum={setNum}
                            details={details}
                            type={type}
                            selectedContact={selectedContact}
                            setSelectedContact={setSelectedContact}
                        />
                    </div>
                
                    <div className={updateVendorContactDialog ? Classes.UpdateSalesDialog : undefined}>
                        <UpdateContactModal 
                            onShowModal={updateVendorContactDialog}
                            setModal={setVendorModal}
                            onHideModal={hideVendorUpdateModal}
                            contacts={contacts}
                            setContacts={setContacts}
                            num={selectedContact?.phoneNumber}
                            setNum={setNum}
                            details={details}
                            type={type}
                            selectedContact={selectedContact}
                            setSelectedContact={setSelectedContact}
                        />
                    </div>
                </>
            )}

            {selectedContact && (
                <div className="row mt-2">
                    <div className="">
                        <div className="card">
                            <div className="card-header" style={{display:'flex',justifyContent:'space-between'}}>
                                <p>Contact Details:</p>
                                <p 
                                    className={['removeEdit', Classes.contactEditBtn].join(' ')}
                                    onClick={() => getVendorUpdateModal()}
                                >Edit</p>
                            </div>
                            <div className="card-body">
                                <p className="card-text">Name: {selectedContact?.firstName} {selectedContact?.lastName && selectedContact?.lastName}</p>
                                {selectedContact?.role && (
                                    <p className="card-text">Role: {selectedContact?.role}</p>
                                )}
                                <p className="card-text">Phone Number: {selectedContact?.phoneNumber}</p>
                                {selectedContact.email && (
                                    <p className="card-text">Email: {selectedContact.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );

}

export default ContactDetails