import { useEffect, useState, Fragment, forwardRef, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utility/axios-instance';
import { downloadCSV } from "../../../utility/downloadCSV";
import { CheckRoleBasedPermission, permission, services } from '../../../utility/checkRoleBasedPermission';
import Classes from '../orders/css/order.module.css';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Spinner from '../../../components/spinner';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider,  DatePicker } from '@mui/x-date-pickers-pro';
import { Box, Paper, InputAdornment, TextField, Autocomplete } from "@mui/material";
import NewPagination from '../../../components/newPagination';
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from 'react-redux';
import { setOrders } from '../../../store/ordersSlice';
import {
    DataGrid,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StatusInput({ status, handleStatusFilter }) {
    return (
        <div style={{width:'30%'}}>
            <Autocomplete
                multiple
                limitTags={1}
                size="small"
                disablePortal
                sx={{ width: '100%',"& .MuiInputBase-sizeSmall": {paddingTop:'5px !important'} }}
                options={orderStatuses}
                value={status}
                onChange={handleStatusFilter}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                    />
                )}
            />
        </div>
    )
}

function DateRange ({startDate, setStartDate, endDate, setEndDate}) {
    return (
        <div style={{display:'flex'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                    label="Start Date" 
                    sx={{ 
                        "& .MuiOutlinedInput-input": {padding: "9.5px 6px",fontSize:'14px'},
                        "& .MuiSvgIcon-root": { fontSize:"18px" },
                        "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                        "& .Mui-error": { color:'#00000099 !important' }
                        // "& .MuiInputLabel-root": { top: endDate? "0%" :"-18%" }
                    }}
                    value={startDate || " "}
                    onChange={(newValue) => {
                        setStartDate(newValue);
                    }} 
                />
                <DatePicker 
                    label="End Date"
                    sx={{ 
                        "& .MuiOutlinedInput-input": {padding: "9.5px 6px",fontSize:'14px'},
                        "& .MuiSvgIcon-root": { fontSize:"18px" },
                        "& .MuiOutlinedInput-notchedOutline": {borderColor:'#0000003b !important'},
                        "& .Mui-error": { color:'#00000099 !important' }
                        // "& .MuiInputLabel-root": { top: endDate? "0%" :"-18%" }
                    }}
                    value={endDate || " "}
                    onChange={(newValue) => {
                        setEndDate(newValue);
                    }} 
                />
            </LocalizationProvider>
        </div>
    )
}

function SearchInput({ searchFor, handleSearch }) {
    return (
        <div style={{ width: '40%' }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ 
                    width: '100%',
                    "& label": { top: searchFor ? "0%" : "-18%" },
                    "& .MuiOutlinedInput-input": { padding: "8.5px 14px" },
                 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

const orderStatuses = [
    'Confirmed',
    'Part Allocated',
    'Part Packed',
    'Partially Dispatched',
    'Partially Delivered',
    'Part Delivered & Cancelled',
    'Partially Fulfilled',
    'Partially Return Requested',
    'Partially Returned',
    'Allocated',
    'Packed',
    'Dispatched',
    'Delivered',
    'Cancelled',
    'Return Requested',
    'Returned',
]

function CustomToolbar({ searchFor, handleSearch, handleStatusFilter, startDate, setStartDate, endDate, setEndDate }) {
    return (
        <GridToolbarContainer style={{paddingTop:8,display:'flex',justifyContent:'space-between'}}>
            <div style={{display:'flex'}}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </div>
            <div
                className={Classes.SearchBox}
                style={{ border:'none',display:'flex',width:'60%',justifyContent:'end' }}
            >
                <DateRange startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />
            </div>
        </GridToolbarContainer>
    );
}


const OrdersNew = (props) => {
    const [status, setStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [sort, setSort] = useState({ field: 'createdAt', sort: 'desc' });
    const [filter, setFilter] = useState(null);
    const [searchFor, setSearchFor] = useState("");
    
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    const pinTab = useSelector((state) => state?.pinTab)
    const orders = useSelector((state) => state?.orders);
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);

    const {id} = useParams();
    const columnVisibilityModel = {
        paymentStatus: false,
        locationCode: false,
        createdVia: false,
        cancelledAt: false,
    }
    const columns = [
        { 
            field: 'orderName', 
            headerName: 'ORDER', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
        },
        { 
            field: 'createdAt',
            headerName: 'DATE', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
        },
        { 
            field: 'createdVia',
            headerName: 'Platform', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
        },
        { 
            field: 'cancelledAt',
            headerName: 'Cancelled At', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
        },
        { 
            field: 'customerFullName', 
            headerName: 'CUSTOMER', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
            renderCell: (params) => ( params?.row?.customerFullName ),
        },
        {
            field: 'locationCodes',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => ( params?.row?.locationCodes.join(', ') )
        },
        {
            field: 'paymentStatus',
            headerName: 'PAY STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
                params.value === "Unpaid" ? (
                    <Chip icon={<CancelIcon color='disabled' />} sx={{ color: 'red' }} label={params.value} />
                ) : (
                    <Chip icon={<CheckCircleIcon color='disabled' />} sx={{ color: 'green' }} label={params.value} />
                )
            )
        },
        {
            field: 'status',
            headerName: 'STATUS',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            renderCell: (params) => (
                params.value === "Cancelled" ? (
                    <Chip label={params.value} />
                ) : (
                    <Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
                )
            )
        },
        { 
            field: 'total', 
            renderCell: (params) => `₹${params?.row?.total}`, 
            headerName: 'TOTAL', 
            flex: 1, 
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'orderType',
            headerName: 'TYPE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => (
                params.value === "B2C" ? (
                    <Chip icon={<PersonIcon color='disabled' />} label={params?.row?.orderType} />
                ) : (
                    <Chip icon={<StoreIcon color='disabled' />} label={params?.row?.orderType} />
                )
            )
        },
        { 
            field: 'createdBy', 
            headerName: 'CREATED BY', 
            flex: 1, headerClassName: 
            'super-app-theme--header'
        },
    ];

    const fetchOrders = async (type) => {
        setLoading(true);
        // let usersQuery = createdBy.join(',');
        const page = type ? type : 1;
        let statusQuery = status?.join(',');
        console.log("startDate In orders Page ",startDate, "EndDate ",endDate)
        const params = {
            page,
            startTime: startDate ? `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`: null,
            endTime: endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : null,
            searchFor: id ? id: searchFor ? searchFor : '',
            status: statusQuery || undefined,
            sort : JSON.stringify(sort),
            filter: `${filter?.value}` ? filter : undefined,
        };

        await axiosInstance
            .get(`/pgOrder/admin/orders`, { params })
            .then((res) => {
                dispatch(setOrders(res.data.data));
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
        setLoading(false);
    };

    if (document.querySelector('.MuiDataGrid-footerContainer')) {
        document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
    }

    const handleExport = (event) => {
        setLoading(true);
        event.preventDefault();

        axiosInstance
            .get('/exports/orders')
            .then((result) => {
                setLoading(false);
                const arrayOfRowObjects = result?.data?.data?.exportedData
                downloadCSV(arrayOfRowObjects, `Orders export - ${new Date()}`);
                const successMessage = "Orders Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setLoading(false);
            })
    }

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    useEffect(() => {

        if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Orders List')) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                console.log('first render')
                const urlParams = new URLSearchParams(window.location.search);
                const page = urlParams.get('page'); // This will give you the value of the 'page' parameter in the URL
                fetchOrders(page ? page : 1);
                return;
            }
            const delay = 550;
            const debounce = setTimeout(() => {
                fetchOrders();
            }, delay);
            return () => clearTimeout(debounce);
        }
    }, [status, sort, searchFor, filter, startDate, endDate]);

    const years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',];

    const handleCreatedAt = (createdAt) => {
        const dn = new Date();
        let ca = createdAt
            ? createdAt.getFullYear() === dn.getFullYear()
                ? createdAt.getMonth() === dn.getMonth()
                    ? createdAt.getDate() === dn.getDate()
                        ? `Today at ${createdAt.toLocaleTimeString()}`
                        : createdAt.getDate() === dn.getDate() - 1
                            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
                            : years[createdAt.getMonth()] +
                            ' ' +
                            createdAt.getDate() +
                            ' at ' +
                            createdAt.toLocaleTimeString()
                    : years[createdAt.getMonth()] +
                    ' ' +
                    createdAt.getDate() +
                    ' at ' +
                    createdAt.toLocaleTimeString()
                : years[createdAt.getMonth()] + ' ' + createdAt.getFullYear()
            : null
        return ca;
    }

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const handleStatusFilter = (e, newValue) => {
        setStatus(newValue);
    }

    return (
        <Fragment>
            <div className={Classes.headerButtons} style={{width:'100%'}}>
                <StatusInput status={status} handleStatusFilter={handleStatusFilter} />
                <Button
                    id='exportbtn'
                    variant="contained"
                    color="success"
                    startIcon={<FileDownloadIcon />}
                    onClick={handleExport}
                    style={{padding:'6px 12px',fontSize:12}}
                >
                    Export
                </Button>
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>

            {/* {orders?.orders?.length !== 0 && ( */}
                <div className="d-none d-sm-block" style={{ width: '100%' }} >
                    <Box
                        component={Paper}
                        className="shadow"
                        sx={{
                            '& .super-app-theme--header': {  
                                backgroundColor:'#243750',color:'#ffffff',cursor:"default" 
                        }}}
                    >
                        <DataGrid
                            slots={{ toolbar: CustomToolbar }}
                            filterMode="server"
                            sortingMode="server"
                            paginationMode="server"
                            slotProps={{
                                toolbar: {
                                    searchFor,
                                    handleSearch,
                                    handleStatusFilter,
                                    startDate, setStartDate, endDate, setEndDate
                                }
                            }}
                            initialState={{
                                columns: { columnVisibilityModel },
                                sorting: {
                                    sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                },
                            }}
                            onSortModelChange={(sort) => setSort(sort[0])}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                                ".MuiDataGrid-footerContainer": {
                                    display: "none !important",
                                },
                                boxShadow: 2,
                                borderRadius: 2,
                                cursor: "pointer"
                            }}
                            columns={columns}
                            rows={orders?.orders?.map((order) => {
                                let row = {
                                    id: order?.orderName,
                                    orderName: order?.orderName,
                                    createdAt: handleCreatedAt(new Date(order?.createdAt)),
                                    customerFullName: order?.customerFullName,
                                    locationCodes: order?.locationCodes,
                                    paymentStatus: order?.paymentStatus,
                                    status: order?.status,
                                    total: order?.total,
                                    orderType: order?.orderType,
                                    createdBy: order?.createdBy
                                }
                                return row;
                            })}
                            onRowClick={(params) => {
                                const permissible = CheckRoleBasedPermission(auth?.user, services?.orders, permission?.read)
                                if (permissible) {
                                    navigate(`/ordersNew/${params?.id}`);
                                }
                            }}
                            sortingOrder={['asc', 'desc']}
                            autoHeight
                            autoPageSize
                            onFilterModelChange={(filter) => (setFilter(filter.items[0]))}
                        />
                    </Box>

                     {orders?.orders?.length > 0 ? (
                        <div>
                            <NewPagination totalPage={orders} fetchData={fetchOrders} />
                        </div>
                    ) : null}
                </div>
            {/* )} */}

            <div className="d-block d-sm-none" style={{ marginTop: '-25px' }}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"orders"} 
                    endpoint={`/pgOrder/admin/orders`} 
                    rowId={'orderName'}
                    clickable={true}
                    tab={'/ordersNew'}
                />
            </div>

            {loading && <Spinner />}
        </Fragment>
    );
};

export default OrdersNew;