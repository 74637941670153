
import { Fragment, useEffect, useState } from 'react';
import Classes from './css/addProduct.module.css';
import axiosInstance from '../../../../utility/axios-instance';


const  Pricing = (props) => {
    const [units, setUnits] = useState([]);
    const [unitToggle,setUnitToggle] = useState(true);
    const {
        handleGroupTitle,
        handleDiscount,
        storeDiscountArr,
        limitPrice,
        productDetailsVariant,
        showErrorMsg,
        hsnCodes,
        handleGstRate,
        handleDatabase,
        handleHsnCode,
        handleVinculum,
        unitType,
    }= props;

    useEffect(() => {
		if(unitToggle){
			axiosInstance
				.get('/others/unit')
				.then((res) => {
					setUnits(res.data.data)
					setUnitToggle(false)
				})
		}
	},[unitToggle]);

	
	return (
		<Fragment>
            <div className={Classes.Pricing}>
                <div className={Classes.Boxes}>
                    <div style={{display:'flex'}}>
                        <h6>Product Details</h6>
                    </div>
                    <div>
            <div style={{}}>
            <div style={{display:'flex',marginTop:15}}>
                        <div style={{display:'flex'}}>
                            <p>Group Title (Shopify Listing Name)</p>
                            <p className={Classes.requiredField}>*</p>
                        </div>
                    </div>
                    <div>
                        <input
                            type="text"
                            id="groupTitle"
                            name='title'
                            required={true}
                            key={
                                productDetailsVariant.length !== 0 ?
                                productDetailsVariant[0].groupName :
                                ''
                            }
                            defaultValue={
                                productDetailsVariant.length !== 0 ?
                                productDetailsVariant[0].groupName :
                                ''
                            }
                            autoComplete='off'
                            className={[
                                Classes.searchField, 
                                Classes.InputField
                            ].join(' ')}
                            onBlur={(e) => handleGroupTitle(e)}
                        />
                    </div>

              <div style={{ display: "flex", marginTop:15 }}>
                <p>Product Title (System Listing Name)</p>
                <p className={Classes.requiredField}>*</p>
              </div>

              <div>
                <input
                  name="productName"
                  type="text"
                  id="product_title"
                  required={true}
                  autoComplete="off"
                  defaultValue={
                    productDetailsVariant.length !== 0
                      ? productDetailsVariant[0].productName
                      : ""
                  }
                  key={
                    productDetailsVariant.length !== 0
                      ? productDetailsVariant[0].productName
                      : ""
                  }
                  className={[
                    Classes.searchField,
                    Classes.InputField,
                    "productTitle",
                  ].join(" ")}
                  onBlur={(e) => handleDatabase(e)}
                  placeholder="Hinges"
                />
              </div>

              <div>
                {showErrorMsg.length !== 0 && (
                  <div>
                    {showErrorMsg.fieldName === "productName" && (
                      <p className={Classes.Msg}>{showErrorMsg.message}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{width:'48%'}}>
                            <div className={Classes.Field}>  
                                <div style={{marginTop:15,display:'flex'}}>
                                    <p>DSIN Code (SKU)</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>
                                <div>
                                    <input
                                        name='dsinCode'
                                        type="text"
                                        id="dsinCode"
                                        defaultValue={
                                            productDetailsVariant.length !== 0 ?
                                            productDetailsVariant[0].sku :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 ?
                                            productDetailsVariant[0].sku :
                                            ''
                                        }
                                        autoComplete='off'
                                        required={true}
                                        onBlur={(e) => handleDatabase(e)}
                                        className={[
                                            Classes.searchField,
                                            Classes.InputField
                                        ].join(' ')}
                                    />
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'sku' && (
                                                <p>{showErrorMsg.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{width:'48%'}}>
                            <div style={{marginTop: 15,display: 'flex'}}>
                                <p>HSN Code</p>
                                <p className={Classes.vinculumRequiredField}>*</p>
                            </div>

                            <div>
                                {hsnCodes.length !== 0 && (
                                    <select 
                                        name='hsnCode'
                                        id='hsnCode'
                                        defaultValue={
                                            productDetailsVariant.length !== 0 &&
                                            productDetailsVariant[0].hsnCode ?
                                            productDetailsVariant[0].hsnCode :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 &&
                                            productDetailsVariant[0].hsnCode ?
                                            productDetailsVariant[0].hsnCode :
                                            ''
                                        }
                                        onChange={handleHsnCode}
                                    >
                                        <option value='' disabled={true}>
                                            Select Hsn Code
                                        </option>
                                        {hsnCodes.map((hsnCode,index) => (
                                            <option 
                                                key={index} 
                                                value={hsnCode.HSNCode}
                                                data-percentage={hsnCode.Percentage}
                                            >
                                                {hsnCode.HSNCode}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'hsn_code' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div> 

                        
                    </div>

                    

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={Classes.PriceField}>  
                            <div style={{marginTop:15,display:'flex'}}>
                                <p>MRP</p>
                                <p className={Classes.requiredField}>*</p>
                            </div>
                            <div>
                                <input
                                    name='MRP'
                                    type="number"
                                    id="mrp"
                                    step="0.01"
                                    autoComplete='off'
                                    defaultValue={
                                        productDetailsVariant.length !== 0 ?
                                        productDetailsVariant[0].mrp :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 ?
                                        productDetailsVariant[0].mrp :
                                        ''
                                    }
                                    inputMode='numeric'
                                    required={true}
                                    // onBlur={(e) => handleDatabase(e)}
                                    onBlur={(e) => handleDatabase(e)}
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                />
                            </div>

                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'compareAtPrice' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={Classes.PriceField}>  
                            <div style={{marginTop:15,display:'flex'}}>
                                <p>LIST PRICE (Exclusive GST)</p>
                                <p className={Classes.requiredField}>*</p>
                            </div>
                            <div>
                                <input
                                    name='listingPrice'
                                    type="number"
                                    id="listDiscount"
                                    step="0.01"
                                    required={true}
                                    autoComplete='off'
                                    defaultValue={
                                        productDetailsVariant.length !== 0 ?
                                        productDetailsVariant[0].listingPrice :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 ?
                                        productDetailsVariant[0].listingPrice :
                                        ''
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    onBlur={
                                        (e) => handleDiscount
                                        (e,'listDiscountErrorPopUp')
                                    }
                                />
                            </div>

                            <div 
                                id='listDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.showLimitDiscountPop}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'price' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>

                            {storeDiscountArr.length !== 0 && (
                                <div>
                                    {storeDiscountArr.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'listDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType.listDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>RETAILER (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='retailer'
                                    type="number"
                                    step="0.01"
                                    id="retailerDiscount"
                                    autoComplete='off'
                                    defaultValue={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].retailPrice ?
                                        productDetailsVariant[0].retailPrice :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].retailPrice ?
                                        productDetailsVariant[0].retailPrice :
                                        ''
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    onBlur={
                                        (e) => handleDiscount(
                                            e,'RetailerDiscountErrorPopUp'
                                    )}
                                />
                            </div>

                            <div 
                                id='RetailerDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.showLimitDiscountPop}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {/* <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'retailPrice' && (
                                            <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                        )}
                                    </div>
                                )}
                            </div> */}

                            {storeDiscountArr.length !== 0 && (
                                <div>
                                    {storeDiscountArr.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'retailerDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType.retailerDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>INTERIOR (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='interior'
                                    type="number"
                                    id="interiorDiscount"
                                    defaultValue={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].interiorPrice ?
                                        productDetailsVariant[0].interiorPrice :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].interiorPrice ?
                                        productDetailsVariant[0].interiorPrice :
                                        ''
                                    }
                                    step="0.01"
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    onBlur={
                                        (e) => handleDiscount(
                                            e,'InteriorDiscountErrorPopUp'
                                    )}
                                />
                            </div>

                            <div 
                                id='InteriorDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.showLimitDiscountPop}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {/* <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'interiorPrice' && (
                                            <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                        )}
                                    </div>
                                )}
                            </div> */}

                            {storeDiscountArr.length !== 0 && (
                                <div>
                                    {storeDiscountArr.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'interiorDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType.interiorDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>OEM (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='oem'
                                    type="number"
                                    id="oemDiscount"
                                    step="0.01"
                                    defaultValue={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].oemPrice ?
                                        productDetailsVariant[0].oemPrice :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].oemPrice ?
                                        productDetailsVariant[0].oemPrice :
                                        ''
                                    }
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    onBlur={
                                        (e) => handleDiscount(
                                            e,'oemDiscountErrorPopUp'
                                        )}
                                />
                            </div>

                            <div 
                                id='oemDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.showLimitDiscountPop}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {/* <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'oemPrice' && (
                                            <p className={Classes.Msg}>{showErrorMsg.message}</p>
                                        )}
                                    </div>
                                )}
                            </div> */}
                            
                            {storeDiscountArr.length !== 0 && (
                                <div>
                                    {storeDiscountArr.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'oemDiscount' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType.oemDiscount}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className={Classes.PriceField}>  
                            <div className={Classes.CommonMarginTop}>
                                <p>END USER (Exclusive GST)</p>
                            </div>
                            <div>
                                <input
                                    name='endUser'
                                    type="number"
                                    id="endUser"
                                    step="0.01"
                                    defaultValue={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].endUserPrice ?
                                        productDetailsVariant[0].endUserPrice :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].endUserPrice ?
                                        productDetailsVariant[0].endUserPrice :
                                        ''
                                    }
                                    autoComplete='off'
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    placeholder='&#8377;  0.00'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    onBlur={
                                        (e) => handleDiscount(
                                            e,'endUserDiscountErrorPopUp'
                                        )}
                                />
                            </div>

                            <div 
                                id='endUserDiscountErrorPopUp'
                                className={Classes.hidePopUp}
                            >
                                <p
                                    className={Classes.showLimitDiscountPop}
                                >
                                    Please enter price less than {limitPrice}
                                </p>
                            </div>

                            {storeDiscountArr.length !== 0 && (
                                <div>
                                    {storeDiscountArr.map((discountType,index) => (
                                        <div key={index}>
                                            {Object.keys(discountType)[0] === 'endUser' && (
                                                <div className={Classes.DiscountBox}>
                                                    <p>Discount :- </p> 
                                                    <p className={Classes.Discount}>
                                                        {discountType.endUser}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{width:'48%'}}>
                            <div className={Classes.Field}>  
                                <div style={{display:'flex',marginTop: 15}}>
                                    <p>GST Rate</p>
                                    <p className={Classes.requiredField}>*</p>
                                </div>
                                <div>
                                    <select 
                                        name="gst_rate" 
                                        id="gstRate" 
                                        defaultValue={
                                            productDetailsVariant.length !== 0 ?
                                            `${productDetailsVariant[0].gst}.0` :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 ?
                                            `${productDetailsVariant[0].gst}.0` :
                                            ''
                                        }
                                        onChange={(e) => handleGstRate(e)} 
                                        required={true}
                                    >
                                        <option value="18.0">18.0</option>
                                        <option value="0.0">0.0</option>
                                        <option value="5.0">5.0</option>
                                        <option value="12.0">12.0</option>
                                        <option value="28.0">28.0</option>
                                    </select>
                                </div>

                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'gst_rate' && (
                                                <p>{showErrorMsg.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{width:'48%'}}>
                            <div className={Classes.Field}>  
                                <div style={{marginTop:15,display:'flex'}}>
                                    <p>Model Number</p>
                                </div>
                                <div>
                                    <input
                                        name='modelNumber'
                                        type="text"
                                        id="modelNumber"
                                        autoComplete='off'
                                        defaultValue={
                                            productDetailsVariant.length !== 0 &&
                                            productDetailsVariant[0].modelNo ?
                                            productDetailsVariant[0].modelNo :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 &&
                                            productDetailsVariant[0].modelNo ?
                                            productDetailsVariant[0].modelNo :
                                            ''
                                        }
                                        className={[
                                            Classes.searchField, 
                                            Classes.InputField
                                        ].join(' ')}
                                        // onBlur={handleShopify}
                                    />
                                </div>
                                <div>
                                    {showErrorMsg.length !== 0 && (
                                        <div>
                                            {showErrorMsg.fieldName === 'model_number' && (
                                                <p>{showErrorMsg.message}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        
                    </div>

                    <div 
                        className={Classes.CommonMarginTop} 
                        style={{display:'flex',justifyContent:'space-between'}}
                    >
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex'}}>
                                <p>Unit</p>
                                {/* <p className={Classes.vinculumRequiredField}>*</p> */}
                            </div>
                            
                            <div>
                                {units.length !== 0 && (
                                    <select 
                                        name='unit'
                                        id='unit'
                                        // defaultValue=''
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].unit ?
                                            productDetailsVariant[0].unit :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].unit ?
                                            productDetailsVariant[0].unit :
                                            ''
                                        }
                                        onChange={handleVinculum}
                                        style={{ marginTop:0,paddingTop:8 }}
                                    >
                                        <option value=''>
                                            Select Unit Type
                                        </option>

                                        {units.map((unit) => (
                                            <option key={unit._id} value={unit.unit}>
                                                {unit.unit}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'unit' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>


                        <div style={{width:'48%'}}>
                            <p>Delivery Time (Hour/s)</p>
                            <div>
                                <input
                                    name='deliveryTime'
                                    type="number"
                                    id="delivery_time"
                                    autoComplete='off'
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    defaultValue={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].shipmentTime ?
                                        productDetailsVariant[0].shipmentTime :
                                        ''
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].shipmentTime ?
                                        productDetailsVariant[0].shipmentTime :
                                        ''
                                    }
                                />
                            </div>

                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'delivery_time' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        
                    </div>
                    <div 
                        className={Classes.CommonMarginTop} 
                        style={{display:'flex',justifyContent:'space-between'}}
                    >
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex'}}>
                                <p>Bulk Packaging Type</p>
                                {/* <p className={Classes.vinculumRequiredField}>*</p> */}
                            </div>
                            
                            <div>
                                {units.length !== 0 && (
                                    <select 
                                        name='bulkType1'
                                        id='bulkType1'
                                        // defaultValue=''
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].unit ?
                                            productDetailsVariant[0].unit :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].unit ?
                                            productDetailsVariant[0].unit :
                                            ''
                                        }
                                        onChange={handleVinculum}
                                        style={{ marginTop:0,paddingTop:8 }}
                                    >
                                        <option value=''>
                                            Select Bulk Type 1
                                        </option>

                                        {units.map((unit) => (
                                            <option key={unit._id} value={unit.unit}>
                                                {unit.unit}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'bulkType1' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>


                        <div style={{display:'flex',width:'48%'}}>
                        <div>
                            <p>Quantity</p>
                            <div>
                                <input
                                    name='bulkVal1'
                                    type="number"
                                    id="bulkVal1"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    
                                    
                                    
                                />
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', padding:'10px',paddingTop:'25px'}}>
                            <p>{unitType}</p>
                            </div>
                            

                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'bulkVal1' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        
                    </div>
                    <div 
                        className={Classes.CommonMarginTop} 
                        style={{display:'flex',justifyContent:'space-between'}}
                    >
                        <div style={{width:'48%'}}>
                            <div style={{display:'flex'}}>
                                <p>Bulk Packaging Type 2</p>
                                {/* <p className={Classes.vinculumRequiredField}>*</p> */}
                            </div>
                            
                            <div>
                                {units.length !== 0 && (
                                    <select 
                                        name='bulkType2'
                                        id='bulkType2'
                                        // defaultValue=''
                                        defaultValue={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].unit ?
                                            productDetailsVariant[0].unit :
                                            ''
                                        }
                                        key={
                                            productDetailsVariant.length !== 0 && 
                                            productDetailsVariant[0].unit ?
                                            productDetailsVariant[0].unit :
                                            ''
                                        }
                                        onChange={handleVinculum}
                                        style={{ marginTop:0,paddingTop:8 }}
                                    >
                                        <option value=''>
                                            Select Bulk Type 2
                                        </option>

                                        {units.map((unit) => (
                                            <option key={unit._id} value={unit.unit}>
                                                {unit.unit}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            
                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'bulkType2' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>


                        <div style={{display:'flex',width:'48%'}}>
                        <div>
                            <p>Quantity</p>
                            <div>
                                <input
                                    name='bulkVal2'
                                    type="number"
                                    id="bulkVal2"
                                    onKeyDown={
                                        e => ( e.keyCode === 69) 
                                        && e.preventDefault()
                                    }
                                    className={[
                                        Classes.searchField, 
                                        Classes.InputField
                                    ].join(' ')}
                                    
                                    
                                />
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', padding:'10px', paddingTop:'25px'}}>
                            <p>{unitType}</p>
                            </div>
                            

                            <div>
                                {showErrorMsg.length !== 0 && (
                                    <div>
                                        {showErrorMsg.fieldName === 'bulkVal2' && (
                                            <p className={Classes.Msg}>
                                                {showErrorMsg.message}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        
                    </div>
                    

                    <div className={Classes.CommonMarginTop}>
                        <div style={{display:'flex'}}>
                            <div>
                                <input
                                    type='checkbox'	
                                    name='chargeTax'
                                    // defaultChecked={true}
                                    defaultChecked={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].isTaxable ?
                                        productDetailsVariant[0].isTaxable :
                                        true
                                    }
                                    key={
                                        productDetailsVariant.length !== 0 && 
                                        productDetailsVariant[0].isTaxable ?
                                        productDetailsVariant[0].isTaxable :
                                        true
                                    }
                                />
                            </div>
                            <div style={{padding: '3px 10px'}}>
                                <p>Charge tax on this product</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
		</Fragment>
	);
};


export default Pricing;
