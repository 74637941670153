import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import FullScreenDialog from './packedPopUp';
import SalesUser from './assignSalesUser';
import Classes from '../customers/css/customer.module.css';
import PackOrder from './packOrders';
import QCOrder from './qcOrders';
import ConfirmPopup from './confirmPopUp';
import Chip from '@mui/material/Chip';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import RidersDialog from './deliveryRider';
import { TextField, MenuItem } from '@mui/material';
import Permission from '../../auth/permissions';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadDocDialog from './uploadDocDispatch';
import ConfirmDelivered from './confirmDelivered';
import axiosInstance from '../../../utility/axios-instance';
import OnHoldPopup from './onHoldPopup';
import SnackBar from '../../../components/SnackBar';
import { services } from '../../../utility/checkRoleBasedPermission';
import MobileResponsive from '../../../components/mobileResponsive/mobileResponsiveTables';
import { useNavigate } from 'react-router-dom';
import { CheckRoleBasedPermission } from '../../../utility/checkRoleBasedPermission';
import CancelIcon from '@mui/icons-material/Cancel';
import { setDeliveryShippings } from '../../../store/deliveryShippingsSlice';
import { setPicklists } from '../../../store/picklistsSlice';
import { setQcOrders } from '../../../store/qcSlice';
import { setPackOrders } from '../../../store/packsSlice';
import { setDispatchOrders } from '../../../store/dispatchSlice';
import { setOnHoldOrders } from '../../../store/onHoldSlice';

const DeliveryShipping = ({ type }) => {
    const [types, setTypes] = useState(type ? type : 'new');
    const [alignment, setAlignment] = useState(type ? type : 'new');
    const [reRender, setReRender] = useState(false);
    const [open, setOpen] = useState(false);
    const [pickList, setPickList] = useState([]);
    const [pickListOpen, setPickListOpen] = useState(false);
    const [qc, setQC] = useState([]);
    const [openQC, setQCOpen] = useState(false);
    const [openPack, setPackOpen] = useState(false);
    const [pack, setPack] = useState([]);
    const [openDispatch, setDispatchOpen] = useState(false);
    const [dispatch, setDispatch] = useState([]);
    const [openConfirm, setConfirmOpen] = useState(false);
    const [openOnHold, setOnHoldOpen] = useState(false);
    const [onHoldOrderName, setOnHoldOrderName] = useState(null);
    const [onHoldProducts, setOnHoldProducts] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [newDimension, setNewDimension] = useState(false);

    // dispatchDoc Upload
    const [dispatchId, setDispatchId] = useState('');
    const [openDocUploadPopup, setOpenDocPopup] = useState(false);

    // delivered
    const [deliveredId, setDeliveredId] = useState('');
    const [openDelivered, setDeliveredOpen] = useState(false);

    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const deliveryShippings = useSelector((state) => state.deliveryShippings);
    const picklists = useSelector((state) => state.picklists);
    const qcOrders = useSelector((state) => state.qcOrders);
    const packOrders = useSelector((state) => state.packOrders);
    const dispatchOrders = useSelector((state) => state.dispatchOrders);
    const onHoldOrders = useSelector((state) => state.onHoldOrders);
    const user = auth?.user

    const handleDelivered = (dispatchId) => {
        if (Array.isArray(dispatchId)) {
            setDeliveredId(dispatchId[0]);
        } else {
            setDeliveredId(dispatchId);
        }
        setDeliveredOpen(true);
    }

    const hideDeliveredDialog = () => {
        setDeliveredId('');
        setDeliveredOpen(false);
    }

    const showUploadDocPopup = (e, id) => {
        setDispatchId(id);
        setOpenDocPopup(true);
    }

    const hideClosePopup = () => {
        setDispatchId('');
        setOpenDocPopup(false);
    }


    const handleDispatchOpen = (dispatchId) => {
        if (Array.isArray(dispatchId)) {
            setDispatch(dispatchId);
        }else {
            setDispatch([dispatchId]);
        }
        setDispatchOpen(true);
    };

    const handleDispatchClose = () => {
        setDispatchOpen(false);
    };

    const handlePickListConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleClickPickListOpen = (deliveryNo,locationCode) => {
        if (Array.isArray(deliveryNo)) {
            setPickList([{deliveryNo: deliveryNo[0], locationCode: deliveryNo[1]}]);
        } else {
            setPickList([{deliveryNo: deliveryNo, locationCode: locationCode}]);
        }
        setPickListOpen(true);
    };

    const handlePickListClose = () => {
        setPickListOpen(false);
    };

    const handleClickQCOpen = (pickListNo, deliveryNo, locationCode) => {
        if (Array.isArray(pickListNo)) {
            setQC([{ pickListNo: pickListNo[0], deliveryNo: pickListNo[1], locationCode:pickListNo[2] }]);
        } else {
            setQC([{ pickListNo: pickListNo, deliveryNo: deliveryNo, locationCode:locationCode }]);
        }
        setQCOpen(true);
    };

    const handleQCClose = () => {
        setQCOpen(false);
    };

    const handleClickPackOpen = (pickListNo, deliveryNo, locationCode) => {
        if (Array.isArray(pickListNo)) {
            setPack([{ pickListNo: pickListNo[0], deliveryNo: pickListNo[1], locationCode: pickListNo[2] }]);
        } else {
            setPack([{ pickListNo: pickListNo, deliveryNo: deliveryNo, locationCode: locationCode }]);
        }
        setPackOpen(true);
    };

    const handlePackClose = () => {
        setNewDimension(false);
        setPackOpen(false);
    };

    const handleOnHold = (orderName) => {
        setOnHoldOrderName(orderName);
        setOnHoldOpen(true);
    }

    const handleOnHoldClose = () => {
        setOnHoldOpen(false);
        setOnHoldOrderName(null);
        setOnHoldProducts([]);
    };

    const handleRemoveDispatchDocument = async (dispatchId, docKey) => {
        await axiosInstance
            .post(`/orderDispatches/removeDispatchDocument/${dispatchId}`, { docKey: docKey })
            .then((res) => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    }

    const columns = [
        {
            field: 'orderName',
            headerName: 'Order Number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'deliveryNumber',
            headerName: 'Delivery Number',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'orderType',
            headerName: 'Type',
            flex: 0.5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1.2,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Picklist',
            headerName: 'Picklist',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.picklists} permission={"create"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        // onClick={(e) => handlePicklistConfirmOpen(params?.row?.deliveryNumber)}
                        onClick={(e) => handleClickPickListOpen(params?.row?.deliveryNumber,params?.row?.locationCode)}
                    >Generate</Button>
                </Permission>
            ),
        },
    ];

    const pickListColumns = [
        {
            field: 'picklist_number',
            headerName: 'Picklist No.',
            // flex:0.7,
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'doc',
            headerName: 'Document',
            width: 100,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                params?.row?.doc?.length !== 0 &&  
                <Chip size="small" icon={<FilePresentIcon />}  clickable label={params?.row?.picklist_number} component="a" href={params?.row?.doc[0]} target='_blank' />
            )
        },
        {
            field: 'delivery_number',
            headerName: 'Delivery No.',
            width: 140,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'picker',
            headerName: 'Picker',
            width: 140,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'location_code',
            headerName: 'Location Code',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'binWiseAllocation',
            headerName: 'Sku | Bin | Qty',
            width: 200,
            padding: 5,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                params?.row?.binWiseAllocation?.length !== 0 && <TextField
                    id="skuBinQty"
                    select
                    renderValue={(p) => p}
                    SelectProps={{ renderValue: (p) => p }}
                    sx={{ width: '100%', padding: '5px', "& .MuiOutlinedInput-input": { padding: '7px', fontSize: 13 } }}
                    key={`${params?.row?.binWiseAllocation[0]?.sku} | ${params?.row?.binWiseAllocation[0]?.binCode} | ${params?.row?.binWiseAllocation[0]?.allocatedQty}`}
                    defaultValue={`${params?.row?.binWiseAllocation[0]?.sku} | ${params?.row?.binWiseAllocation[0]?.binCode} | ${params?.row?.binWiseAllocation[0]?.allocatedQty}`}
                >
                    {
                        params?.row?.binWiseAllocation.map((item, i) => (
                            <MenuItem
                                disabled={true}
                                sx={{'&.Mui-disabled': { color: '#000000',opacity:1}}}
                                style={{fontSize:13,padding:'4px 10px'}}
                                key={`${item?.sku} | ${item?.binCode} | ${item?.allocatedQty}`}
                                defaultValue={`${item?.sku} | ${item?.binCode} | ${item?.allocatedQty}`}
                            >{`${item?.sku} | ${item?.binCode} | ${item?.allocatedQty}`}</MenuItem>
                        ))
                    }
                </TextField>
            )
        },
        {
            field: 'picklist_qty',
            headerName: 'Quantity',
            type: 'number',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'QC',
            headerName: 'QC',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.picklists} permission={"update"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(e) => handleClickQCOpen(params?.row?.picklist_number,params?.row?.delivery_number,params?.row?.location_code)}
                    >QC</Button>
                </Permission>
            ),
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 180,
            padding: 5,
            headerClassName: 'super-app-theme--header',

        },
        {
            field: 'created_by',
            headerName: 'Created By',
            width: 140,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },

    ]

    const qcColumns = [
        {
            field: 'picklist_number',
            headerName: 'Picklist No.',
            flex: 0.8,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'delivery_number',
            headerName: 'Delivery No.',
            flex: 1,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'qc_person',
            headerName: 'QC Person',
            flex: 1,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'location_code',
            headerName: 'Location Code',
            flex: 0.7,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'picklist_qty',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.5,
            padding: 5,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            flex: 1,
            padding: 5,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            flex: 1,
            padding: 5,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Pack',
            headerName: 'Pack',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.packing} permission={"create"}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                    onClick={(e) => handleClickPackOpen(params?.row?.picklist_number, params?.row?.delivery_number, params?.row?.location_code)}
                >Pack</Button>
                </Permission>
            ),
        },
    ]

    const packColumns = [
        {
            field: 'dispatchId',
            headerName: 'Dispatch ID',
            // flex:1,
            width: 100,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'shipmentDoc',
            headerName: 'Document',
            headerClassName: 'super-app-theme--header',
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                params?.row?.shipmentDoc?.length !== 0 && <Chip size="small" icon={<FilePresentIcon />} clickable label={params?.row?.dispatchId} component="a" href={params?.row?.shipmentDoc[0]} target='_blank' />
            )
        },
        {
            field: 'deliveryNumber',
            headerName: 'Delivery No.',
            width: 160,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'packer',
            headerName: 'Packer',
            width: 150,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            width: 110,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'boxes',
            headerName: 'Boxes',
            width: 90,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 90,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                params.row.weight ? `${params.row.weight}${params.row.weightUnit}` : null
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 180,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Dispatch',
            headerName: 'Fulfillment Status',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                params?.row?.status === "Packed" ?
                <Permission service={services?.dispatch} permission={"update"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(e) => handleDispatchOpen(params?.row?.dispatchId)}
                    >Dispatch</Button></Permission>
                    :
                    params?.row?.status
            ),
        },
        {
            field: 'Box Dimension',
            headerName: 'Box Dimension (L.B.H)',
            width: 180,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                params.row.length && `${params.row.length}${params.row.lengthUnit} x ${params.row.breadth}${params.row.lengthUnit} x ${params.row.height}${params.row.lengthUnit}`
            )
        },
        {
            field: 'awbNumber',
            headerName: 'AWB No.',
            width: 180,
            headerClassName: 'super-app-theme--header'
        }
    ]

    const dispatchColumns = [
        {
            field: 'dispatchId',
            headerName: 'Dispatch ID',
            width: 110,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'deliveryNumber',
            headerName: 'Delivery No',
            width: 140,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            width: 110,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'documentsAttached',
            headerName: 'Document',
            width: 350,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                params?.row?.documentsAttached?.length > 0 ? (
                    <div style={{ display: 'flex', gap: "5px" }}>
                        {params?.row?.documentsAttached?.slice(0, 2)?.map((doc) => (
                            <div>
                                <Chip
                                    size="small"
                                    icon={<FilePresentIcon />}
                                    clickable
                                    label={decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 6) + "..."}
                                    component="a"
                                    href={doc}
                                    onDelete={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        const { row } = params;
                                        const { dispatchId } = row;
                                        const docKey = decodeURIComponent(doc?.substring(doc?.indexOf('.com/') + 5, doc?.lastIndexOf('?')));
                                        await handleRemoveDispatchDocument(dispatchId, docKey);
                                    }}
                                    deleteIcon={<CancelIcon />}
                                />
                            </div>
                        ))}
                        {params?.row?.documentsAttached?.length > 2 ? (
                            <Tooltip title={
                                <div style={{ gap: "20px" }}>
                                    {params?.row?.documentsAttached?.slice(2)?.map((doc, index) => (
                                        <Link color="inherit" href={doc}>{decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 8)} , </Link>
                                    ))}
                                </div>
                            } arrow>
                                <Chip size="small" label={`+ ${params?.row?.documentsAttached?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                            </Tooltip>
                        ) : null}
                        <Permission service={services?.deliveryShipping} permission="update">
                            {params?.row?.documentsAttached?.length < 5 ? (
                                <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showUploadDocPopup(e, params?.row?.dispatchId)}>
                                    Upload
                                </Button>
                            ) : null}
                        </Permission>
                    </div>
                ) : (
                    <div>
                        <Permission service={services?.dispatch} permission="update">
                            <Button size="small" startIcon={<FileUploadIcon />} variant="contained" onClick={(e) => showUploadDocPopup(e, params?.row?.dispatchId)}>
                                Upload
                            </Button>
                        </Permission>
                    </div>
                )
            )
        },
        {
            field: 'deliveryRider',
            headerName: 'Delivery Rider',
            width: 120,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            headerAlign: 'center',
            align: 'center',
            width: 90,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Mark Delivered',
            headerName: 'Mark Delivered',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            filterable: false,
            sortable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                (params?.row?.status !== 'Delivered') ?
                    <Permission service={services?.dispatch} permission={"update"}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                            onClick={(e) => handleDelivered(params?.row?.dispatchId)}
                        >Mark Delivered</Button>
                    </Permission>
                    : params?.row?.status
            ),
        },
        {
            field: 'orderName',
            headerName: 'Order Name',
            width: 140,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 180,
            headerClassName: 'super-app-theme--header',
        }
    ]

    const columnVisiblityModelOnHoldTable = {
        locationName: false
    }
    const onHoldOrdersColumn = [
        {
            field: 'orderName',
            headerName: 'Order Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'whStatus',
            headerName: 'WareHouse status',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderType',
            headerName: 'Order Type',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'customerFullName',
            headerName: 'Customer',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'cancelledAt',
            headerName: 'Cancelled At',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'cancelledBy',
            headerName: 'Cancelled By',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationName',
            headerName: 'Location Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Return On Hold',
            headerName: 'Return On Hold',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            filterable: false,
            sortable: false,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                (params?.row?.whStatus !== 'Put Away') ?
                    <Permission service={services?.returns} permission={"create"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(e) => handleOnHold(params?.row?.orderName)}
                    >Return</Button>
                    </Permission>
                    : <Button
                        variant='outlined'
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                    >{params?.row?.whStatus}</Button>
            ),
        }
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectedCheckBoxId = useSelector((state) => state.selectedCheckBox.ids);


    const handleChange = (event) => {
        setTypes(event.target.value);
        setAlignment(event.target.value);
        navigate(`/deliveryShipping${event.target.value === 'new' ? '' : `/${event.target.value}`}`)
    };

    const fetchOnHoldProducts = async () => {
        await axiosInstance
            .get(`/deliveryShipping/fetchSingleOnHoldOrder/${onHoldOrderName}`)
            .then((response) => {
                setOnHoldProducts(response?.data?.data);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(error);
            })
    }

    useEffect(() => {
        if (onHoldOrderName) {
            fetchOnHoldProducts();
        }
    }, [onHoldOrderName])


    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px' }}>
                <div style={{ backgroundColor: '#9e9e9e1f', borderRadius: 4, padding: 4 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{
                            "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                            "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                        }}
                    >
                            {CheckRoleBasedPermission(user, "deliveryShipping", "read") ?  <ToggleButton value="new">New</ToggleButton> : null }
                            {CheckRoleBasedPermission(user, "picklists", "read") ?  <ToggleButton value="pickList">PickList</ToggleButton> : null }
                            {CheckRoleBasedPermission(user, "picklists", "read") ?  <ToggleButton value="qc">QC</ToggleButton> : null }
                            {CheckRoleBasedPermission(user, "packing", "read") ? <ToggleButton value="pack">Pack</ToggleButton> : null }
                            {CheckRoleBasedPermission(user, "orderDispatches", "read") ? <ToggleButton value="dispatch">Dispatch</ToggleButton> : null }
                            {CheckRoleBasedPermission(user, "cancelOrder", "read") ? <ToggleButton color='error' value="onHold">On Hold</ToggleButton> : null }
                    </ToggleButtonGroup>
                </div>
            </div>

            {types === 'new' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={columns}
                            dataPosition={"fetchedDeliveryShippings"}
                            endpoint={`/deliveryShipping/fetchDeliveryShippings`}
                            rowId={'deliveryNumber'}
                            checkBoxSelection={false}
                            rowSelectionEndpoint={`/picklist/generate-picklist`}
                            version="version-1"
                            reRender={reRender}
                            service={'deliveryShipping'}
                            type={types}
                            tabName={'Delivery Shipping'}
                            setData={setDeliveryShippings}
                            data={deliveryShippings}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={columns}
                            dataPosition={"fetchedDeliveryShippings"}
                            endpoint={`/deliveryShipping/fetchDeliveryShippings`}
                            rowId={'deliveryNumber'}
                            reRender={reRender}
                            setReRender={setReRender}
                            handleClickOpen={handleClickPickListOpen}
                            buttonFields={['deliveryNumber', 'locationCode']}
                        />
                    </div>
                </div>
            )}

            {types === 'pickList' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={pickListColumns}
                            dataPosition={"fetchedPicklists"}
                            endpoint={`/picklist/fetchPicklists?tab="picklist"`}
                            rowId={'delivery_number'}
                            checkBoxSelection={false}
                            version="version-1"
                            rowSelectionEndpoint={`/packing/packingOrders`}
                            reRender={reRender}
                            service={'deliveryShipping'}
                            type={types}
                            tabName={'Delivery Shipping'}
                            setData={setPicklists}
                            data={picklists}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={pickListColumns}
                            dataPosition={"fetchedPicklists"}
                            endpoint={`/picklist/fetchPicklists?tab="picklist"`}
                            rowId={'delivery_number'}
                            handleClickOpen={handleClickQCOpen}
                            buttonFields={['picklist_number','delivery_number','location_code']}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'qc' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={qcColumns}
                            dataPosition={"fetchedPicklists"}
                            endpoint={`/picklist/fetchPicklists?tab="qc"`}
                            rowId={'delivery_number'}
                            checkBoxSelection={false}
                            version="version-1"
                            reRender={reRender}
                            service={'deliveryShipping'}
                            type={types}
                            tabName={'Delivery Shipping'}
                            setData={setQcOrders}
                            data={qcOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={qcColumns}
                            dataPosition={"fetchedPicklists"}
                            endpoint={`/picklist/fetchPicklists?tab="qc"`}
                            rowId={'delivery_number'}
                            handleClickOpen={handleClickPackOpen}
                            buttonFields={['picklist_number','delivery_number','location_code']}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'pack' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={packColumns}
                            dataPosition={"dispatches"}
                            endpoint={`/orderDispatches/orderDispatches`}
                            rowId={'dispatchId'}
                            checkBoxSelection={false}
                            version="version-1"
                            reRender={reRender}
                            service={'deliveryShipping'}
                            type={types}
                            tabName={'Delivery Shipping'}
                            setData={setPackOrders}
                            data={packOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={packColumns}
                            dataPosition={"dispatches"}
                            endpoint={`/orderDispatches/orderDispatches`}
                            rowId={'dispatchId'}
                            handleClickOpen={handleDispatchOpen}
                            buttonFields={['dispatchId']}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'dispatch' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={dispatchColumns}
                            dataPosition={"fetchedAllDispatches"}
                            endpoint={`/orderDispatches/fetchAllDispatches`}
                            rowId={'dispatchId'}
                            checkBoxSelection={false}
                            reRender={reRender}
                            service={'deliveryShipping'}
                            type={types}
                            tabName={'Delivery Shipping'}
                            setData={setDispatchOrders}
                            data={dispatchOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={dispatchColumns}
                            dataPosition={"fetchedAllDispatches"}
                            endpoint={`/orderDispatches/fetchAllDispatches`}
                            rowId={'dispatchId'}
                            handleClickOpen={handleDelivered}
                            showUploadDocPopup={showUploadDocPopup}
                            buttonFields={['dispatchId']}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'onHold' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={onHoldOrdersColumn}
                            dataPosition={"fetchedOnHoldsOrders"}
                            endpoint={`/deliveryShipping/onHoldOrders`}
                            rowId={'orderName'}
                            checkBoxSelection={false}
                            reRender={reRender}
                            columnVisibilityModel={columnVisiblityModelOnHoldTable}
                            service={'deliveryShipping'}
                            type={types}
                            tabName={'Delivery Shipping'}
                            setData={setOnHoldOrders}
                            data={onHoldOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={onHoldOrdersColumn}
                            dataPosition={"fetchedOnHoldsOrders"}
                            endpoint={`/deliveryShipping/onHoldOrders`}
                            rowId={'orderName'}
                            handleClickOpen={handleOnHold}
                            buttonFields={['orderName']}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            <div className={openConfirm ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmPopup
                    openConfirm={openConfirm}
                    handlePickListConfirmClose={handlePickListConfirmClose}
                    pickList={pickList}
                    setReRender={setReRender}
                />
            </div>

            <div className={pickListOpen ? Classes.UpdateSalesDialog : undefined}>
                <SalesUser
                    pickListOpen={pickListOpen}
                    handlePickListClose={handlePickListClose}
                    pickList={pickList}
                    setReRender={setReRender}
                />
            </div>

            <FullScreenDialog
                open={open}
                handleClose={handleClose}
                packedOrders={types === 'pickList' && selectedCheckBoxId?.length !== 0 && selectedCheckBoxId}
            />

            <div className={openQC ? Classes.UpdateSalesDialog : undefined}>
                <QCOrder
                    qc={qc}
                    openQC={openQC}
                    handleQCClose={handleQCClose}
                    setReRender={setReRender}
                />
            </div>

            <div className={openPack ? Classes.UpdateSalesDialog : undefined}>
                <PackOrder
                    openPack={openPack}
                    handlePackClose={handlePackClose}
                    pack={pack}
                    setReRender={setReRender}
                    newDimension={newDimension}
                    setNewDimension={setNewDimension}
                />
            </div>

            <div className={openDispatch ? Classes.UpdateSalesDialog : undefined}>
                <RidersDialog
                    openDispatch={openDispatch}
                    dispatch={dispatch}
                    handleDispatchClose={handleDispatchClose}
                    setReRender={setReRender}
                />
            </div>

            <div className={openDocUploadPopup ? Classes.UpdateSalesDialog : undefined}>
                <UploadDocDialog
                    showPopup={openDocUploadPopup}
                    hideClosePopup={hideClosePopup}
                    dispatchId={dispatchId}
                    setReRender={setReRender}
                />
            </div>

            <div className={openDelivered ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmDelivered
                    openDelivered={openDelivered}
                    hideDeliveredDialog={hideDeliveredDialog}
                    setReRender={setReRender}
                    deliveredId={deliveredId}
                />
            </div>

            {types === "onHold" && (
                <OnHoldPopup
                    onClose={handleOnHoldClose}
                    isOpen={openOnHold}
                    products={onHoldProducts}
                    order={onHoldOrderName}
                    setReRender={setReRender}
                    setSnackBar={setSnackBar}
                />
            )}

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}
export default DeliveryShipping;