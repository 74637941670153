import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import RefreshIcon from '@mui/icons-material/Refresh';
import SnackBar from '../../../components/SnackBar';
import axiosInstance from '../../../utility/axios-instance';
import Backdrop from '@mui/material/Backdrop';
import { downloadCSV } from "../../../utility/downloadCSV";
import CircularProgress from '@mui/material/CircularProgress';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SyncInventoryPopup({ open, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })


    const handleDetection = () => {
        setLoading(true);
        axiosInstance.get('/inventory/syncInventoryDetection')
            .then(response => {
                setLoading(false);
                if (response?.data?.errors?.length > 0) {
                    downloadCSV(response?.data?.errors, `Errors (SyncInventory) - ${new Date()}`);
                    setSnackBar({ display: true, type: "error", message: "Inventory is not synced!! Check downloaded file for errors" })
                } else {
                    setSnackBar({ display: true, type: "success", message: response?.data?.message })
                }
            })
            .catch(error => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: error.response.data.message })
            })
    }

    const handleCorrection = () => {
        setLoading(true);
        axiosInstance.get('/inventory/syncInventoryCorrection')
            .then(response => {
                setLoading(false);
                setSnackBar({ display: true, type: "success", message: response.data.message })
            })
            .catch(error => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: error.response.data.message })
            })
    }

    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            // maxWidth={maxWidth}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{"Sync Inventory Database"}</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <Button onClick={() => handleDetection()} startIcon={<RefreshIcon />} variant="contained" color="primary">
                            Sync Detection
                        </Button>
                        <Button onClick={() => handleCorrection()} startIcon={<RefreshIcon />} variant="contained" color="primary">
                            Sync Correction
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}