import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Classes from "../estimate/css/estimates.module.css";
import { Paper } from "@mui/material";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from "../../../components/spinner";
import NewPagination from "../../../components/newPagination";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Commission2Mobile from "./Commission2Mobile";

const years = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Commissions2 = (props) => {
  const [commissions, setCommissions] = useState({
    total: 0,
    page: 1,
    maxPage: 1,
    commissions: [],
  });
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ field: "name", value: "" });
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const columns = [
    {
      field: "orderName",
      headerName: "OrderName",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "commission",
      headerName: "COMMISSION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  const handleCreatedAt = (createdAt) => {
    const dn = new Date();
    let ca = createdAt
      ? createdAt.getFullYear() === dn.getFullYear()
        ? createdAt.getMonth() === dn.getMonth()
          ? createdAt.getDate() === dn.getDate()
            ? `Today at ${createdAt.toLocaleTimeString()}`
            : createdAt.getDate() === dn.getDate() - 1
            ? `Yesterday at ${createdAt.toLocaleTimeString()}`
            : years[createdAt.getMonth()] +
              " " +
              createdAt.getDate() +
              " at " +
              createdAt.toLocaleTimeString()
          : years[createdAt.getMonth()] +
            " " +
            createdAt.getDate() +
            " at " +
            createdAt.toLocaleTimeString()
        : years[createdAt.getMonth()] + " " + createdAt.getFullYear()
      : null;
    return ca;
  };

  const fetchData = (page) => {
    setLoading(true);
    setError(null);

    const params = {
      page,
      text: search ? search : "",
      filter: JSON.stringify(filter),
      sort,
    };

    axiosInstance
      .get("/others/commissions", {
        params,
      })
      .then((response) => {
        setCommissions(response.data.data);
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // console.log(sort);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, sort]);

  return (
    <Fragment>
      <div className={Classes.UsersHead}>
        <div className='px-3 py-1 text-center'>
          {/* <p className="mb-0">
                        <strong style={{ fontSize: '1.2rem' }}>Users</strong>
                    </p> */}
        </div>
      </div>
      {error ? (
        <Alert variant='danger' onClose={() => setError(false)} dismissible>
          <Alert.Heading>{error}</Alert.Heading>
          <p>Try reloading!</p>
        </Alert>
      ) : null}

      <div className='d-none d-md-block' style={{ width: "99%" }}>
        <Box
          component={Paper}
          className='shadow'
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: "#243750",
              color: "#ffffff",
              cursor: "default",
            },
          }}
        >
          <DataGrid
            // rowCount={estimates.estimates.length}
            rowSelection={false}
            slots={{ toolbar: GridToolbar }}
            filterMode='server'
            filterColumns={["name"]}
            sortingMode='server'
            paginationMode='server'
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            onSortModelChange={(sort) => setSort(sort[0])}
            loading={loading}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              boxShadow: 2,
              borderRadius: 2,
              cursor: "pointer",
            }}
            columns={columns}
            rows={commissions?.commissions?.map((commission) => ({
              id: commission?._id,
              orderName: commission?.orderName,
              name: commission?.affiliateData?.displayName,
              phone: commission?.affiliateData?.phone,
              email: commission?.affiliateData?.email,
              commission: commission?.commission?.toFixed(2),
              createdAt: handleCreatedAt(new Date(commission?.createdAt)),
            }))}
            onRowClick={(params) => {
              //   navigate(`/estimates/${params.id}`);
              // window.open(
              //   `${window.location.origin}/estimates/${params.id}`,
              //   "_blank"
              // );
            }}
            sortingOrder={["asc", "desc"]}
            autoHeight
            autoPageSize
            onFilterModelChange={(filter) => {
              setFilter(filter.items[0]);
              setSearch(filter.quickFilterValues[0]);
            }}
          />
        </Box>
      </div>

      <div className={Classes.MobileView}>
        <div>
          {commissions?.commissions.map((commission) => (
            <Commission2Mobile key={commission._id} commission={commission} />
          ))}
        </div>
      </div>

      {commissions?.commissions?.length > 0 ? (
        <nav aria-label='Page navigation'>
          <NewPagination totalPage={commissions} fetchData={fetchData} />
        </nav>
      ) : null}
      {loading ? <Spinner /> : null}
    </Fragment>
  );
};

export default Commissions2;
